import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles/index';
import { AppBar, Avatar, Badge, Button, Card, CardHeader, CardActions, CardContent, Collapse, Chip, Divider, Icon, IconButton, Menu, MenuItem, List, ListItem, ListItemText, Paper, Stepper, Step, StepButton, Tabs, Tab, TextField, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import * as DialerActions from 'main/dialer/store/actions';
import { openEditCustomerDialog } from '../customers/store/actions';
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions';
import { openEditWorkOrderDialog } from '../work-orders/store/actions';
import { openEditWorkOrderScopeDialog } from '../work-order-scopes/store/actions';
import { openEditTripDialog, openNewTripDialog } from '../trips/store/actions';
import { withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import { DateTimePicker } from "@material-ui/pickers";
import Autocomplete from '../../components/autocomplete/Autocomplete';
import moment from 'moment';
import _ from '@lodash';
import ChipInput from 'material-ui-chip-input'

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {

    },
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    avatarTech: {
        backgroundColor: theme.palette.primary[500],
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    card: {
        marginTop: 0,
        marginBottom: 8,
        cursor: 'pointer',
        width: '100%',
        border: '1px solid lightgrey',
    },
    addButton: {
        marginTop: 0,
        marginBottom: 8,
        cursor: 'pointer',
        width: '100%',
        border: 'none',
        boxShadow: 'none'
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    tabs: {
        width: '100%',
    },
    tab: {
        minWidth: '25%',
        maxWidth: '25%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    info: {
        padding: 8,
        paddingTop: 0,
        paddingBottom: 0 + '!important',
    },
    campaign: {
        width: 'auto',
        top: 0,
        right: -5,
        marginTop: 5,
        borderRadius: 4,
        padding: 10,
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
    },
    tripAdminTab: {
        minWidth: '20%',
        maxWidth: '20%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    container: {
        padding: 0,
    },
    trip: {
        minWidth: 300,
    },
    tripTabContent: {
        minHeight: 175,
        maxHeight: 175,
        overflow: 'auto',
    },
    formControl: {
        marginBottom: 12
    },
    footer: {
        top: 'auto',
        bottom: 0,
    },
    scopeBadge: {
        top: 5,
        right: 2,
    },
    tripBadge: {
        top: 35,
        right: 35,
        background: '#3f3f3f',
    },
    tripBadgeIcon: {
        fontSize: 14,
        marginTop: 1,
    },
    tripCountBadge: {
        top: 15,
        right: -7,
        height: 17,
        minWidth: 17,
        fontSize: '1rem',
        border: '2px solid #3f3f3f',
        paddingTop: 2,
    },
    input: {
        height: 36,
    },
    inputLabel: {
        fontSize: 12,
        '&[data-shrink="false"]': {
            marginTop: -8,
        },
        '&[data-shrink="true"]': {
            marginTop: 0
        }
    },
    cardContent: {
        padding: 12,
        borderTop: '1px solid lightgrey',
        paddingBottom: '4px !important',
    },
    addButtonContent: {
        padding: 12,
        border: '1px solid lightgrey',
        borderRadius: 4,
        paddingBottom: '4px !important',
    },
    updateButton: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});

const initialState = {
    ID: null,
    Co: null,
    Employee: null,
    Type: 'W',
    LaborType: null,
    WorkOrder: null,
    Scope: null,
    Description: null,
    Qty: null,
    HourlyRate: null,
    Total: null,
    Status: 0,
    Notes: null,
    Data: {
        ErrMsg: null,
    },
    open: false,
};

class TimecardLabor extends Component {
    state = initialState;

    componentDidMount = () => {
        const { data, trips } = this.props;
        if (!trips || trips.length < 1) {
            initialState.Type = 'I';
        }
        if (data && !_.isEqual({ ...initialState, ...data }, this.state)) {
            this.setState({ ...initialState, ...data });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, trips } = this.props;
        if (!_.isEqual(trips, prevProps.trips)) {
            if (!trips || trips.length < 1) {
                initialState.Type = 'I';
            } else {
                initialState.Type = 'W';
            }
        }
    }

    canBeSubmitted = () => {
        const { Co, Employee, Type, Qty, Description, LaborType, WorkOrder, Scope } = this.state;

        return Type && Qty && !isNaN(Qty) && ((WorkOrder && Scope) || Description) && LaborType;
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleOpen = () => {
        let { open } = this.state;
        open = !open;
        this.setState({ open });
    }

    handleQtyChange = (event) => {
        const Qty = event.target.value;
        const { HourlyRate } = this.state;
        this.setState({ ...this.state, Qty, Total: (Qty || 0) * (HourlyRate || 0) });
    }

    stop = (event) => {
        event.stopPropagation();
    }

    render() {
        const { classes, data, laborTypes, dialog, trips, submitted } = this.props;

        const { value, technician, trip, tripValue, open } = this.state;
        // filter to only WOs with started trips
        const started = _.filter(trips, (o) => { return o.Status > 3 });
        const workOrders = _.uniqBy(_.filter(started, (o) => { return o.Data && o.Data.WorkOrder && o.Data.WorkOrder.Data && o.Data.WorkOrder.Data.Scopes }), 'WorkOrder');
        const workOrder = started ? _.find(workOrders, (o) => { return o.WorkOrder === this.state.WorkOrder }) : null;
        console.warn(workOrders, workOrder);
        const scopes = workOrder ? workOrder.Data.WorkOrder.Data.Scopes : null;
        const scope = scopes ? _.find(scopes, { Scope: this.state.Scope }) : null;

        return (
            <Card onClick={this.stop} onMouseDown={this.stop} onMouseMove={this.stop} className={classNames(data ? classes.card : classes.addButton, "dispatch-work-order")}>
                <CardHeader onClick={this.handleOpen} className={classNames("p-0 pl-12 pr-12")}
                    classes={
                        { content: classes.content, action: classes.action, avatar: "mr-4", title: classes.title, subheader: classes.subheader, }
                    }
                    avatar={
                        !data ? <Icon className="text-20 mt-6" color="primary">add_circle_outline</Icon> : data.Type === 'W' ? <Icon className="text-20 mt-6" color="primary">assignment</Icon> : data.Type !== 'W' && <Icon className="text-20 mt-6" color="primary">assignment_ind</Icon>
                    }
                    action={
                        data &&
                        <Typography className="flex text-12 mt-4">
                            {Boolean(this.state.Qty) && <span className="mt-4 mr-4 font-bold">{data.Qty} Hours</span>}
                            <Icon color="action" style={{ marginTop: 2 }} className="text-24 mr-8 mt-4 ml-4">{!open ? "keyboard_arrow_down" : "keyboard_arrow_up"}</Icon>
                        </Typography>

                    }
                    title={
                        !data ?
                            <Typography className="flex text-12 font-bold">
                                <span style={{ marginTop: 2 }}>Add Labor</span>
                            </Typography> :
                            <Typography className="flex text-12 font-bold">
                                {data.Type === 'W' ? <span style={{ marginTop: 2 }}>{`Work Order #${data.WorkOrder} Scope #${data.Scope}`}</span> : <span style={{ marginTop: 2 }}>{data.Description}</span>}
                            </Typography>
                    }
                />
                {open &&
                    <FuseAnimate animation="transition.slideDownIn">
                        <CardContent classes={{ root: data ? classes.cardContent : classes.addButtonContent }}>

                            <div className="sm:flex">
                                <TextField
                                    className={classNames(classes.formControl, "sm:w-1/3")}
                                    label="Type"
                                    id="type"
                                    name="Type"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel
                                    }}
                                    value={this.state.Type}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    select
                                    disabled={this.state.Type === 'O' || (submitted === 'Y' && this.state.ApprovedYN !== 'N')}
                                    fullWidth
                                >
                                    <MenuItem disabled={!started || started.length < 1} value="W">
                                        Work Order
                                    </MenuItem>
                                    <MenuItem value="I">
                                        Indirect
                                    </MenuItem>
                                    <MenuItem disabled={true} value="O">
                                        Other
                                    </MenuItem>
                                </TextField>
                                <div className="hidden sm:block min-w-8 pt-20">
                                </div>
                                {this.state.Type === 'W' || this.state.Type === 'O' ?
                                    <div className="sm:flex w-full">
                                        <TextField
                                            className={classes.formControl}
                                            label="Work Order"
                                            id="workOrder"
                                            name="WorkOrder"
                                            InputProps={{
                                                className: classes.input,
                                                type: 'number',
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputLabel,
                                                shrink: Boolean(this.state.WorkOrder)
                                            }}
                                            value={this.state.WorkOrder}
                                            onChange={this.handleChange}
                                            disabled={this.state.Type === 'O' || (submitted === 'Y' && this.state.ApprovedYN !== 'N')}
                                            variant="outlined"
                                            select
                                            fullWidth
                                        >
                                            {workOrders && workOrders.length > 0 && (this.state.WorkOrder ? _.findIndex(workOrders, { WorkOrder: this.state.WorkOrder }) > -1 : 1 === 1) ? workOrders.map((value, index) =>
                                                <MenuItem key={index} value={value.WorkOrder}>
                                                    {`${value.WorkOrder} - ${value.Data.WorkOrder.Description.substr(0, 15)}...`}
                                                </MenuItem>
                                            ) :
                                                !this.state.WorkOrder ?
                                                    <MenuItem value={null}>
                                                        No Work Orders Available
                                                </MenuItem>
                                                    :
                                                    <MenuItem value={this.state.WorkOrder}>
                                                        {this.state.WorkOrder}
                                                    </MenuItem>
                                            }
                                        </TextField>
                                        <div className="hidden sm:block min-w-8 pt-20">
                                        </div>
                                        <TextField
                                            className={classNames(classes.formControl, "sm:w-1/2")}
                                            // className={classes.formControl}
                                            label="Scope"
                                            id="scope"
                                            name="Scope"
                                            InputProps={{
                                                className: classes.input,
                                                type: 'number',
                                            }}
                                            InputLabelProps={{
                                                className: classes.inputLabel,
                                                shrink: Boolean(this.state.Scope)
                                            }}
                                            value={this.state.Scope}
                                            onChange={this.handleChange}
                                            disabled={this.state.Type === 'O' || (submitted === 'Y' && this.state.ApprovedYN !== 'N')}
                                            variant="outlined"
                                            select
                                            fullWidth
                                        >
                                            {scopes ? scopes.map((value, index) => {
                                                const assigned = _.find(trips, (o) => { return _.findIndex(o.Data.Assignments, { WorkOrder: value.WorkOrder, Scope: value.Scope }) > -1 });
                                                if (assigned) {
                                                    return (
                                                        <MenuItem key={index} value={value.Scope}>
                                                            {`${value.Scope} - ${value.Description.substr(0, 8)}...`}
                                                        </MenuItem>
                                                    );
                                                }
                                            }) :
                                                !this.state.Scope ?
                                                    <MenuItem value={null}>
                                                        Please Select Work Order
                                            </MenuItem>
                                                    :
                                                    <MenuItem value={this.state.Scope}>
                                                        {this.state.Scope}
                                                    </MenuItem>
                                            }
                                        </TextField>
                                    </div> :
                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        InputLabelProps={{
                                            className: classes.inputLabel,
                                            shrink: Boolean(this.state.Description)
                                        }}
                                        value={this.state.Description}
                                        onChange={this.handleChange}
                                        disabled={this.state.Type === 'O' || (submitted === 'Y' && this.state.ApprovedYN !== 'N')}
                                        variant="outlined"
                                        fullWidth
                                    />
                                }
                            </div>
                            <div className="sm:flex">
                                <TextField
                                    className={classNames(classes.formControl, "sm:w-1/3")}
                                    // className={classes.formControl}
                                    label="Qty"
                                    id="qty"
                                    name="Qty"
                                    InputProps={{
                                        className: classes.input,
                                        type: 'number',
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel,
                                        shrink: Boolean(this.state.Qty)
                                    }}
                                    value={this.state.Qty}
                                    onChange={this.handleQtyChange}
                                    disabled={this.state.Type === 'O' || (submitted === 'Y' && this.state.ApprovedYN !== 'N')}
                                    variant="outlined"
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-8 pt-20">
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Labor Type"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel,
                                        shrink: Boolean(this.state.LaborType)
                                    }}
                                    options={
                                        dialog.data ?
                                            scope ?
                                                _.filter(laborTypes, { BusinessUnit: scope.BusinessUnit, Division: scope.Division, Type: this.state.Type })
                                                :
                                                _.filter(laborTypes, { BusinessUnit: dialog.data.DefaultBusinessUnit, Division: dialog.data.DefaultDivision, Type: this.state.Type })
                                            :
                                            []

                                    }
                                    menuItemComponent={(value, index) => {
                                        return (
                                            <MenuItem key={index} value={value.LaborType}>
                                                {value.Description}
                                            </MenuItem>
                                        );
                                    }}
                                    portal={true}
                                    value={this.state.LaborType}
                                    onSelect={(option) => this.setState({ ...this.state, LaborType: option.LaborType, HourlyRate: option.StandardUnitCost, Total: (this.state.Qty || 0) * option.StandardUnitCost })}
                                    required
                                />
                                {/* <TextField
                                    className={classes.formControl}
                                    label="Labor Type"
                                    id="laborType"
                                    name="LaborType"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    InputLabelProps={{
                                        className: classes.inputLabel,
                                        shrink: Boolean(this.state.LaborType)
                                    }}
                                    value={this.state.LaborType}
                                    onChange={this.handleChange}
                                    disabled={this.state.Type === 'O' || (submitted === 'Y' && this.state.ApprovedYN !== 'N')}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    {laborTypes.map((value, index) => {
                                        if (scope) {
                                            if (scope.BusinessUnit === value.BusinessUnit && scope.Division === value.Division && value.Type === this.state.Type) {
                                                return (
                                                    <MenuItem key={index} value={value.LaborType}>
                                                        {value.Description}
                                                    </MenuItem>
                                                );
                                            }
                                        } else {
                                            if (dialog.data && dialog.data.DefaultBusinessUnit && dialog.data.DefaultDivision && dialog.data.DefaultBusinessUnit === value.BusinessUnit && dialog.data.DefaultDivision === value.Division && value.Type === this.state.Type) {
                                                return (
                                                    <MenuItem key={index} value={value.LaborType}>
                                                        {value.Description}
                                                    </MenuItem>
                                                );
                                            }
                                        }
                                    }
                                    )}
                                </TextField> */}
                            </div>
                            {data ?
                                <div className="w-full flex">
                                    <Button
                                        className={classNames(classes.formControl, classes.updateButton, "w-full")}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.props.updateLabor(this.state);
                                            this.setState({ ...this.state, open: false });
                                        }}
                                        disabled={!this.canBeSubmitted() || this.state.Type === 'O' || (submitted === 'Y' && this.state.ApprovedYN !== 'N')}
                                    >
                                        Update
                                                    </Button>
                                    <Button
                                        className={classNames(classes.formControl, classes.deleteButton)}
                                        variant="contained"
                                        onClick={() => {
                                            this.props.removeLabor(this.state);
                                        }}
                                        disabled={submitted === 'Y' && this.state.ApprovedYN !== 'N'}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </div> :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        var rec = this.state;
                                        window["log"](rec);
                                        const dt = new Date(dialog.date).toLocaleDateString('en-US');
                                        const { Co, Employee } = dialog.data;
                                        this.props.addLabor({ ...rec, Co, Employee, Date: dt });
                                        this.setState({ ...initialState });
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                    className="mb-8 w-full"
                                >
                                    Add
                            </Button>
                            }
                        </CardContent>
                    </FuseAnimate>
                }
            </Card>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addLabor: Actions.addLabor,
        updateLabor: Actions.updateLabor,
        removeLabor: Actions.removeLabor,
    }, dispatch);
}

function mapStateToProps({ spReducers, dispatchBoardApp, techniciansApp }) {
    return {
        user: dispatchBoardApp.user,
        laborTypes: spReducers.laborTypes,
        dialog: techniciansApp.technicians.technicianDialog,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TimecardLabor)));
