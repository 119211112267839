import axios from 'axios/index';
import { getUserData } from 'main/content/apps/business-units/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_BUSINESS_UNITS = '[BUSINESS UNITS APP] GET BUSINESS UNITS';
export const SET_SEARCH_TEXT = '[BUSINESS UNITS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_BUSINESS_UNITS = '[BUSINESS UNITS APP] TOGGLE IN SELECTED BUSINESS UNITS';
export const SELECT_ALL_BUSINESS_UNITS = '[BUSINESS UNITS APP] SELECT ALL BUSINESS UNITS';
export const DESELECT_ALL_BUSINESS_UNITS = '[BUSINESS UNITS APP] DESELECT ALL BUSINESS UNITS';
export const OPEN_NEW_BUSINESS_UNIT_DIALOG = '[BUSINESS UNITS APP] OPEN NEW BUSINESS UNIT DIALOG';
export const CLOSE_NEW_BUSINESS_UNIT_DIALOG = '[BUSINESS UNITS APP] CLOSE NEW BUSINESS UNIT DIALOG';
export const OPEN_EDIT_BUSINESS_UNIT_DIALOG = '[BUSINESS UNITS APP] OPEN EDIT BUSINESS UNIT DIALOG';
export const CLOSE_EDIT_BUSINESS_UNIT_DIALOG = '[BUSINESS UNITS APP] CLOSE EDIT BUSINESS UNIT DIALOG';
export const ADD_BUSINESS_UNIT = '[BUSINESS UNITS APP] ADD BUSINESS UNIT';
export const UPDATE_BUSINESS_UNIT = '[BUSINESS UNITS APP] UPDATE BUSINESS UNIT';
export const REMOVE_BUSINESS_UNIT = '[BUSINESS UNITS APP] REMOVE BUSINESS UNIT';
export const REMOVE_BUSINESS_UNITS = '[BUSINESS UNITS APP] REMOVE BUSINESS UNITS';
export const TOGGLE_STARRED_BUSINESS_UNIT = '[BUSINESS UNITS APP] TOGGLE STARRED BUSINESS UNIT';
export const TOGGLE_STARRED_BUSINESS_UNITS = '[BUSINESS UNITS APP] TOGGLE STARRED BUSINESS UNITS';
export const SET_BUSINESS_UNITS_STARRED = '[BUSINESS UNITS APP] SET BUSINESS UNITS STARRED ';

export function getBusinessUnits(routeParams) {
    const request = axios.get('/api/businessUnits-app/businessUnits', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_BUSINESS_UNITS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedBusinessUnits(businessUnitId) {
    return {
        type: TOGGLE_IN_SELECTED_BUSINESS_UNITS,
        businessUnitId
    }
}


export function selectAllBusinessUnits() {
    return {
        type: SELECT_ALL_BUSINESS_UNITS
    }
}

export function deSelectAllBusinessUnits() {
    return {
        type: DESELECT_ALL_BUSINESS_UNITS
    }
}


export function openNewBusinessUnitDialog() {
    return {
        type: OPEN_NEW_BUSINESS_UNIT_DIALOG
    }
}

export function closeNewBusinessUnitDialog() {
    return {
        type: CLOSE_NEW_BUSINESS_UNIT_DIALOG
    }
}

export function openEditBusinessUnitDialog(data) {
    return {
        type: OPEN_EDIT_BUSINESS_UNIT_DIALOG,
        data
    }
}

export function openPreloadBusinessUnitDialog(data) {
    window["warn"]("openPreloadBusinessUnitDialog", data)
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_BUSINESS_UNIT_DIALOG,
            data: data,
        })
    }
}

export function closeEditBusinessUnitDialog() {
    return {
        type: CLOSE_EDIT_BUSINESS_UNIT_DIALOG
    }
}

export function addBusinessUnit(newBusinessUnit) {
    return (dispatch, getState) => {

        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.post(`${window["apiLocation"]}/api/BusinessUnit`,
            newBusinessUnit
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_BUSINESS_UNIT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Business Unit ${newBusinessUnit.BusinessUnit} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(getBusinessUnits(routeParams))
            })
        );
    };
}

export function updateBusinessUnit(businessUnit) {
    return (dispatch, getState) => {

        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.put(`${window["apiLocation"]}/api/BusinessUnit?Co=${encodeURIComponent(businessUnit.Co)}&BusinessUnit=${encodeURIComponent(businessUnit.BusinessUnit)}`,
            businessUnit
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_BUSINESS_UNIT
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Business Unit ${businessUnit.BusinessUnit} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(getBusinessUnits(routeParams))
            })
        );
    };
}

export function removeBusinessUnit(businessUnitId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.post('/api/businessUnits-app/remove-businessUnit', {
            businessUnitId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_BUSINESS_UNIT
                })
            ]).then(() => dispatch(getBusinessUnits(routeParams)))
        );
    };
}


export function removeBusinessUnits(businessUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.post('/api/businessUnits-app/remove-businessUnits', {
            businessUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_BUSINESS_UNITS
                }),
                dispatch({
                    type: DESELECT_ALL_BUSINESS_UNITS
                })
            ]).then(() => dispatch(getBusinessUnits(routeParams)))
        );
    };
}

export function toggleStarredBusinessUnit(businessUnitId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.post('/api/businessUnits-app/toggle-starred-businessUnit', {
            businessUnitId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_BUSINESS_UNIT
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getBusinessUnits(routeParams)))
        );
    };
}

export function toggleStarredBusinessUnits(businessUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.post('/api/businessUnits-app/toggle-starred-businessUnits', {
            businessUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_BUSINESS_UNITS
                }),
                dispatch({
                    type: DESELECT_ALL_BUSINESS_UNITS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getBusinessUnits(routeParams)))
        );
    };
}

export function setBusinessUnitsStarred(businessUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.post('/api/businessUnits-app/set-businessUnits-starred', {
            businessUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_BUSINESS_UNITS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_BUSINESS_UNITS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getBusinessUnits(routeParams)))
        );
    };
}

export function setBusinessUnitsUnstarred(businessUnitIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().businessUnitsApp.businessUnits;

        const request = axios.post('/api/businessUnits-app/set-businessUnits-unstarred', {
            businessUnitIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_BUSINESS_UNITS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_BUSINESS_UNITS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getBusinessUnits(routeParams)))
        );
    };
}
