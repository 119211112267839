import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    TextField, Badge, Button, Dialog, DialogActions, DialogContent, Drawer, FormControlLabel, Switch, List, ListItem, ListItemIcon, ListItemText, Divider, Icon, IconButton, Typography, Tooltip, Toolbar, AppBar, Avatar, Tabs, Tab, MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { showMessage } from 'store/actions';
import { connect } from 'react-redux';
import { FuseUtils, FuseAnimate } from '@fuse';
import { impress } from 'main/content/compression/impress';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import FormAssignmentList from './FormAssignmentList';
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
// import FormProfile from './FormProfile';
import _ from '@lodash';
import Media from 'react-media';
import axios from 'axios';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, paddingTop: props.paddingTop ? props.paddingTop : props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateSection: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteSection: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const newFormElementState = {
    ID: null,
    Co: null,
    Form: null,
    Section: null,
    Element: null,
    Type: null,
    Title: null,
    Description: null,
    PositionX: 0,
    PositionY: 0,
    Columns: 12,
    Rows: 1,
    StaticYN: null,
    Data: {
        ErrMsg: null,
    },
};

const FormMarker = ({ text }) => <Tooltip title={text} placement="top"><Icon color="action">gps_fixed</Icon></Tooltip>;

class FormElementDialog extends Component {
    state = { ...newFormElementState };


    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const data = this.props.formElementDialog.data;
        /**
         * After Dialog Open
         */
        newFormElementState.Co = this.props.Co;
        if (!prevProps.formElementDialog.props.open && this.props.formElementDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.formElementDialog.type === 'edit' && data &&
                !_.isEqual(data, prevState)) {
                this.setState({ ...data, value: 0 });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.formElementDialog.type === 'new' &&
                !_.isEqual({ ...newFormElementState, ...data }, prevState)) {
                this.setState({ ...newFormElementState, ...data, value: 0 });
            }
        }

        if (prevProps.formElementDialog.props.open && this.props.formElementDialog.props.open) {
            if (this.props.formElementDialog.type === 'data' && data &&
                !_.isEqual(data, prevState)) {
                this.props.formElementDialog.type = 'edit';
                this.setState({ ...data });
            }
        }
        if (prevProps.formElementDialog.props.open && this.props.formElementDialog.props.open) {
            if (this.props.formElementDialog.type === 'update' && data &&
                !_.isEqual(data, prevState)) {
                this.props.formElementDialog.type = 'edit';
                this.setState({ ...data });
            }
        }
    }

    closeComposeDialog = () => {
        this.props.formElementDialog.type === 'edit' ? this.props.closeEditFormElementDialog() : this.props.closeNewFormElementDialog();
    };

    canBeSubmitted() {
        const { Co, Title, Type } = this.state;
        return (
            Co && Title && Type && Co > 0 && Title.length > 0 && Type.length > 0
        );
    }

    fileSelectedHandler = (event) => {
        let { ID, Data } = this.state;
        const { formElementDialog } = this.props;
        impress(event.target.files[0], 1024, 50, true, (img) => {
            window["log"](img);
            const fd = new FormData();
            fd.append('image', img.file, img.fileName);
            axios.post(`${window["apiLocation"]}/api/Attachment/PostProfileImage?Co=${encodeURIComponent(this.state.Co)}&Type=Form&ID=${encodeURIComponent(this.state.Form)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
                if (Data) {
                    Data.Avatar = img.base64;
                } else {
                    Data = { Avatar: img.base64 };
                }
                this.setState({ Data });
            });
        });
    }

    render() {
        const { classes, securables, theme, users, technicians, formElementDialog, addFormElement, updateFormElement, removeFormElement, workOrders, employees, trips, removeForm, openNewWorkOrderDialog, googleMapsApiKey, businessUnits, divisions, departments } = this.props
        const { value, showTabs, } = this.state;
        const accessLevel = _.find(securables, { Securable: "forms" });
        const types = [
            {
                Type: "CB",
                Description: "Checkbox",
                Rows: 3,
                Enabled: true,
            },
            {
                Type: "DT",
                Description: "Date Picker",
                Rows: 4,
                Enabled: false,
            },
            {
                Type: "TDT",
                Description: "Date/Time Picker",
                Rows: 4,
                Enabled: false,
            },
            {
                Type: "IMG",
                Description: "Image",
                Rows: 4,
                Enabled: true,
            },
            {
                Type: "BNG",
                Description: "Indeterminate (Bad / Neutral / Good)",
                Rows: 4,
                Enabled: false,
            },
            {
                Type: "NNY",
                Description: "Indeterminate (No / N/A / YES)",
                Rows: 4,
                Enabled: false,
            },
            {
                Type: "IN",
                Description: "Input",
                Rows: 4,
                Enabled: true,
            },
            {
                Type: "NTS",
                Description: "Notes",
                Rows: 7,
                Enabled: true,
            },
            {
                Type: "NUM",
                Description: "Numeric",
                Rows: 4,
                Enabled: false,
            },
            {
                Type: "RTG",
                Description: "Rating",
                Rows: 4,
                Enabled: false,
            },
            {
                Type: "SIG",
                Description: "Signature Box",
                Rows: 10,
                Enabled: true,
            },
            {
                Type: "DIV",
                Description: "Spacer",
                Rows: 2,
                Enabled: true,
            },
            {
                Type: "TTL",
                Description: "Title",
                Rows: 3,
                Enabled: true,
            },
            {
                Type: "SUB",
                Description: "Sub-Title",
                Rows: 3,
                Enabled: true,
            },
        ];
        return (
            <Media query="(min-width: 512px) and (min-height: 512px)" >
                {matches =>
                    < Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? "m-24" : null
                        }}
                        className={classes.root}
                        {...formElementDialog.props}
                        open={Boolean(accessLevel) && formElementDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                        fullScreen={ !matches}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(formElementDialog.type) > -1 ? 'New Element' : `${this.state.Title}`
                                    }
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Type"
                                    options={_.filter(types, { Enabled: true })}
                                    menuItemComponent={(value) => {
                                        return <MenuItem value={value.Type}>
                                            {value.Description}
                                        </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.Type}
                                    onSelect={(option) => this.setState({ ...this.state, Type: option.Type, Rows: option.Rows })}
                                    required
                                />
                            </div>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Title"
                                    id="title"
                                    name="Title"
                                    value={this.state.Title || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    onFocus={() => this.setState({ ...this.state, lockEditor: false })}
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="desctription"
                                    name="Description"
                                    value={this.state.Description || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                {this.state.Type !== "CB" &&
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.RequiredYN === 'Y'}
                                                name="RequiredYN"
                                                onChange={this.handleChange}
                                                color="secondary"
                                                disabled={this.state.AddNotesYN === 'Y'}
                                            />
                                        }
                                        label="Required"
                                    />
                                }
                                {this.state.Type === "CB" &&
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.AddNotesYN === 'Y'}
                                                name="AddNotesYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Add Notes Field"
                                    />
                                }
                            </div>
                        </DialogContent>
                        {
                            accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {(['new', 'customer'].indexOf(formElementDialog.type) > -1) &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addFormElement(rec);
                                            this.setState({ ...this.state, Title: null, Description: null });
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                            </Button>
                                }
                                {(['new', 'customer'].indexOf(formElementDialog.type) < 0) &&
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                var rec = this.state;
                                                updateFormElement(rec);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                            </Button>
                                        <IconButton
                                            onClick={() => {
                                                removeFormElement(this.state);
                                                this.closeComposeDialog();
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </React.Fragment>
                                }
                            </DialogActions>
                        }
                    </Dialog >
                }
            </Media >
        );
    }
}
FormElementDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditFormElementDialog: Actions.closeEditFormElementDialog,
        closeNewFormElementDialog: Actions.closeNewFormElementDialog,
        showMessage: showMessage,
        addFormElement: Actions.addFormElement,
        updateFormElement: Actions.updateFormElement,
        removeFormElement: Actions.removeFormElement,
        addFormElement: Actions.addFormElement,
        updateFormElement: Actions.updateFormElement,
        removeFormElement: Actions.removeFormElement,
        impress: impress,
    }, dispatch);
}

function mapStateToProps({ auth, formsApp, spReducers, dispatchBoardApp }) {
    return {
        formElementDialog: formsApp.forms.formElementDialog,
        googleMapsApiKey: googleMapsApiKey,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        employees: spReducers.employees,
        forms: spReducers.forms,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        workOrders: spReducers.workOrders,
        trips: dispatchBoardApp.dispatchBoard.trips,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(FormElementDialog));
