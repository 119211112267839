import * as Actions from 'store/actions/spConnection';
import _ from '@lodash';
import { omitDates } from '../../../main/functions';

const initialState = {
    materials: [],
    lastUpdate: '1/1/1900',
}

const materials = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_MATERIAL_DATA:
            {
                return {
                    materials: omitDates(action.payload),
                    lastUpdate: new Date().toLocaleDateString('en-US'),
                }
            }
        case Actions.SET_MATERIAL_DATA:
            {
                return {
                    materials: omitDates(action.payload),
                    lastUpdate: new Date().toLocaleDateString('en-US'),
                }
            }
    }

    return state;
};

export default materials;