import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    Boards: [],
    Tags: [],
}

const boardsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_BOARDS:
            {

                return {
                    ...state,
                    Boards: action.payload,
                };
            }
        case Actions.NEW_BOARD:
            {
                return {
                    ...state,
                    Boards: [
                        ...state.Boards,
                        action.board
                    ],
                }
            }
        case Actions.RENAME_BOARD:
            {
                const index = state.Boards.findIndex((x) => x.ID === action.payload.ID);
                state.Boards[index] = action.payload;
                return {
                    ...state,
                }
            }
        case Actions.RESET_BOARDS:
            {
                return {
                    ...state,
                    Boards: [],
                }
            }
        case Actions.DELETE_BOARD:
            {
                return {
                    ...state,
                    Boards: _.reject(state.Boards, { ID: action.payload.ID }),
                }
            }
        case Actions.GET_BOARD_TAGS:
            {
                return {
                    ...state,
                    Tags: action.payload,
                }
            }
        case Actions.ADD_BOARD_TAG:
            {
                return {
                    ...state,
                    Tags: [
                        ...state.Tags,
                        action.tag
                    ],
                }
            }
        case Actions.UPDATE_BOARD_TAG:
            {
                const index = state.Tags.findIndex((x) => x.ID === action.tag.ID);
                return {
                    ...state,
                    Tags: _.setIn(state, `Tags[${index}]`, action.tag),
                }
            }
        case Actions.REMOVE_BOARD_TAG:
            {
                return {
                    ...state,
                    Tags: _.reject(state.Tags, { ID: action.tag.ID }),
                }
            }
        default:
            return state;
    }
};

export default boardsReducer;
