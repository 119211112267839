import axios from 'axios/index';
import { getUserData } from 'main/content/apps/service-unit-categories/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_SERVICE_UNIT_CATEGORIES = '[SERVICE UNIT CATEGORIES APP] GET SERVICE UNIT CATEGORIES';
export const SET_SEARCH_TEXT = '[SERVICE UNIT CATEGORIES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_SERVICE_UNIT_CATEGORIES = '[SERVICE UNIT CATEGORIES APP] TOGGLE IN SELECTED SERVICE UNIT CATEGORIES';
export const SELECT_ALL_SERVICE_UNIT_CATEGORIES = '[SERVICE UNIT CATEGORIES APP] SELECT ALL SERVICE UNIT CATEGORIES';
export const DESELECT_ALL_SERVICE_UNIT_CATEGORIES = '[SERVICE UNIT CATEGORIES APP] DESELECT ALL SERVICE UNIT CATEGORIES';
export const OPEN_NEW_SERVICE_UNIT_CATEGORY_DIALOG = '[SERVICE UNIT CATEGORIES APP] OPEN NEW SERVICE UNIT CATEGORY DIALOG';
export const CLOSE_NEW_SERVICE_UNIT_CATEGORY_DIALOG = '[SERVICE UNIT CATEGORIES APP] CLOSE NEW SERVICE UNIT CATEGORY DIALOG';
export const OPEN_EDIT_SERVICE_UNIT_CATEGORY_DIALOG = '[SERVICE UNIT CATEGORIES APP] OPEN EDIT SERVICE UNIT CATEGORY DIALOG';
export const UPDATE_EDIT_SERVICE_UNIT_CATEGORY_DIALOG = '[SERVICE UNIT CATEGORIES APP] UPDATE EDIT SERVICE UNIT CATEGORY DIALOG';
export const CLOSE_EDIT_SERVICE_UNIT_CATEGORY_DIALOG = '[SERVICE UNIT CATEGORIES APP] CLOSE EDIT SERVICE UNIT CATEGORY DIALOG';
export const ADD_SERVICE_UNIT_CATEGORY = '[SERVICE UNIT CATEGORIES APP] ADD SERVICE UNIT CATEGORY';
export const UPDATE_SERVICE_UNIT_CATEGORY = '[SERVICE UNIT CATEGORIES APP] UPDATE SERVICE UNIT CATEGORY';
export const REMOVE_SERVICE_UNIT_CATEGORY = '[SERVICE UNIT CATEGORIES APP] REMOVE SERVICE UNIT CATEGORY';
export const REMOVE_SERVICE_UNIT_CATEGORIES = '[SERVICE UNIT CATEGORIES APP] REMOVE SERVICE UNIT CATEGORIES';
export const TOGGLE_STARRED_SERVICE_UNIT_CATEGORY = '[SERVICE UNIT CATEGORIES APP] TOGGLE STARRED SERVICE UNIT CATEGORY';
export const TOGGLE_STARRED_SERVICE_UNIT_CATEGORIES = '[SERVICE UNIT CATEGORIES APP] TOGGLE STARRED SERVICE UNIT CATEGORIES';
export const SET_SERVICE_UNIT_CATEGORIES_STARRED = '[SERVICE UNIT CATEGORIES APP] SET SERVICE UNIT CATEGORIES STARRED ';

export function getServiceUnitCategories(routeParams) {
    const request = axios.get('/api/service-unit-categories-app/service-unit-categories', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_SERVICE_UNIT_CATEGORIES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedServiceUnitCategories(serviceUnitCategoryId) {
    return {
        type: TOGGLE_IN_SELECTED_SERVICE_UNIT_CATEGORIES,
        serviceUnitCategoryId
    }
}


export function selectAllServiceUnitCategories() {
    return {
        type: SELECT_ALL_SERVICE_UNIT_CATEGORIES
    }
}

export function deSelectAllServiceUnitCategories() {
    return {
        type: DESELECT_ALL_SERVICE_UNIT_CATEGORIES
    }
}


export function openNewServiceUnitCategoryDialog() {
    return {
        type: OPEN_NEW_SERVICE_UNIT_CATEGORY_DIALOG
    }
}

export function closeNewServiceUnitCategoryDialog() {
    return {
        type: CLOSE_NEW_SERVICE_UNIT_CATEGORY_DIALOG
    }
}

export function openEditServiceUnitCategoryDialog(data) {
    return {
        type: OPEN_EDIT_SERVICE_UNIT_CATEGORY_DIALOG,
        data
    }
}

export function closeEditServiceUnitCategoryDialog() {
    return {
        type: CLOSE_EDIT_SERVICE_UNIT_CATEGORY_DIALOG
    }
}

export function addServiceUnitCategory(newServiceUnitCategory) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.post(`${window["apiLocation"]}/api/ServiceUnitCategory`,
            newServiceUnitCategory
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_SERVICE_UNIT_CATEGORY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service Unit Category ${newServiceUnitCategory.UnitCat} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateServiceUnitCategory(serviceUnitCategory) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.put(`${window["apiLocation"]}/api/ServiceUnitCategory?Co=${encodeURIComponent(serviceUnitCategory.Co)}&Category=${encodeURIComponent(serviceUnitCategory.UnitCat)}`,
            serviceUnitCategory
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_SERVICE_UNIT_CATEGORY
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service Unit Category ${serviceUnitCategory.UnitCat} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeServiceUnitCategory(serviceUnitCategoryId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.post('/api/service-unit-categories-app/remove-serviceUnitCategory', {
            serviceUnitCategoryId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE_UNIT_CATEGORY
                })
            ]).then(() => dispatch(getServiceUnitCategories(routeParams)))
        );
    };
}


export function removeServiceUnitCategories(serviceUnitCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.post('/api/service-unit-categories-app/remove-serviceUnitCategories', {
            serviceUnitCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE_UNIT_CATEGORIES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_CATEGORIES
                })
            ]).then(() => dispatch(getServiceUnitCategories(routeParams)))
        );
    };
}

export function toggleStarredServiceUnitCategory(serviceUnitCategoryId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.post('/api/service-unit-categories-app/toggle-starred-serviceUnitCategory', {
            serviceUnitCategoryId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_UNIT_CATEGORY
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitCategories(routeParams)))
        );
    };
}

export function toggleStarredServiceUnitCategories(serviceUnitCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.post('/api/service-unit-categories-app/toggle-starred-serviceUnitCategories', {
            serviceUnitCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE_UNIT_CATEGORIES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitCategories(routeParams)))
        );
    };
}

export function setServiceUnitCategoriesStarred(serviceUnitCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.post('/api/service-unit-categories-app/set-serviceUnitCategories-starred', {
            serviceUnitCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_UNIT_CATEGORIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitCategories(routeParams)))
        );
    };
}

export function setServiceUnitCategoriesUnstarred(serviceUnitCategoryIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().serviceUnitCategoriesApp.serviceUnitCategories;

        const request = axios.post('/api/service-unit-categories-app/set-serviceUnitCategories-unstarred', {
            serviceUnitCategoryIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICE_UNIT_CATEGORIES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICE_UNIT_CATEGORIES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServiceUnitCategories(routeParams)))
        );
    };
}
