import * as Actions from 'store/actions/spConnection';

const initialState = {
    vpCompany: null,
    companies: [],
    departments: [],
    employees: [],
    materials: [],
    vendors: [],
    earnCodes: [],
    glAccounts: [],
    payTerms: [],
    taxCodes: [],
    recTypes: []
}

const vpSettings = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_VP_COMPANY:
            {
                return {
                    ...state,
                    vpCompany: action.payload
                };
            }
        case Actions.GET_VP_COMPANY_DATA:
            {
                return {
                    ...state,
                    companies: action.payload
                };
            }
        case Actions.SET_VP_COMPANY_DATA:
            {
                return {
                    ...state,
                    companies: action.payload
                };
            }
        case Actions.GET_VP_DEPARTMENT_DATA:
            {
                return {
                    ...state,
                    departments: action.payload
                };
            }
        case Actions.SET_VP_DEPARTMENT_DATA:
            {
                return {
                    ...state,
                    departments: action.payload
                };
            }
        case Actions.GET_VP_VENDOR_DATA:
            {
                return {
                    ...state,
                    vendors: action.payload
                };
            }
        case Actions.SET_VP_VENDOR_DATA:
            {
                return {
                    ...state,
                    vendors: action.payload
                };
            }
        case Actions.GET_VP_EMPLOYEE_DATA:
            {
                return {
                    ...state,
                    employees: action.payload
                };
            }
        case Actions.SET_VP_EMPLOYEE_DATA:
            {
                return {
                    ...state,
                    employees: action.payload
                };
            }
        case Actions.GET_VP_MATERIAL_DATA:
            {
                return {
                    ...state,
                    materials: action.payload
                };
            }
        case Actions.SET_VP_MATERIAL_DATA:
            {
                return {
                    ...state,
                    materials: action.payload
                };
            }
        case Actions.GET_VP_EARN_CODE_DATA:
            {
                return {
                    ...state,
                    earnCodes: action.payload
                };
            }
        case Actions.SET_VP_EARN_CODE_DATA:
            {
                return {
                    ...state,
                    earnCodes: action.payload
                };
            }
        case Actions.GET_VP_GL_ACCOUNT_DATA:
            {
                return {
                    ...state,
                    glAccounts: action.payload
                };
            }
        case Actions.SET_VP_GL_ACCOUNT_DATA:
            {
                return {
                    ...state,
                    glAccounts: action.payload
                };
            }
        case Actions.GET_VP_PAY_TERM_DATA:
            {
                return {
                    ...state,
                    payTerms: action.payload
                };
            }
        case Actions.SET_VP_PAY_TERM_DATA:
            {
                return {
                    ...state,
                    payTerms: action.payload
                };
            }
        case Actions.GET_VP_TAX_CODE_DATA:
            {
                return {
                    ...state,
                    taxCodes: action.payload
                };
            }
        case Actions.SET_VP_TAX_CODE_DATA:
            {
                return {
                    ...state,
                    taxCodes: action.payload
                };
            }
        case Actions.GET_VP_REC_TYPE_DATA:
            {
                return {
                    ...state,
                    recTypes: action.payload
                };
            }
        case Actions.SET_VP_REC_TYPE_DATA:
            {
                return {
                    ...state,
                    recTypes: action.payload
                };
            }
    }

    return state;
};

export default vpSettings;