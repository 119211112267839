import { FuseAnimate } from '@fuse'
import _ from '@lodash'
import {
  Badge,
  Button,
  Card,
  Chip,
  DialogActions,
  DialogContent,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import {
  Assignment,
  Build,
  List as ListIcon,
  Payment,
  Receipt,
} from '@material-ui/icons'
import classNames from 'classnames'
import EstimatesList from 'main/content/apps/estimates/EstimatesList'
import InvoicesList from 'main/content/apps/invoices/InvoicesList'
import PaymentsList from 'main/content/apps/invoices/PaymentsList'
import WorkOrdersList from 'main/content/apps/work-orders/WorkOrdersList'
import WorkOrderScopesList from 'main/content/apps/work-order-scopes/WorkOrderScopesList'
import ChipInput from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import {
  openEditCustomerDialog,
  getCustomerData,
} from '../customers/store/actions'
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog'
import ContactList from './ContactList'
import * as Actions from './store/actions'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import PhoneInput from '../../components/inputs/PhoneInput'
import DuplicateCustomerWarningDialog from '../customers/DuplicateCustomerWarningDialog'

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  histTable: {
    marginLeft: 56,
    '& .ReactTable': {
      borderTop: '1px solid #f1f1f1',
    },
    '& h6': {
      fontSize: '1.8rem',
      padding: 8,
      marginBottom: '0px !important',
      '& .material-icons': {
        marginRight: '6px !important',
        fontSize: '2.4rem !important',
      },
    },
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 56,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.up('sm')]: {
      width: 56,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  error: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  ok: {
    background: '#333',
    color: '#fff',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  greenText: {
    color: '#d1e751',
  },
})

class CustomersTab extends Component {
  state = {
    ..._.cloneDeepWith(this.props.callHandlerData.data.Customer),
    showHistory: false,
    histTab: 0,
    showEmailLogs: false,
    showConfirmDuplicateCustomerCreationDialog: false,
    duplicatedEntitiesList: [],
  }

  handleChange = event => {
    window['log'](this, event.target)
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleBlur = event => {
    this.props.setCallHandlerData({ ...this.state })
  }

  handleSelect = event => {}

  pacRef = React.createRef()

  componentDidMount() {
    var options = { types: ['address'] }

    const addressInputElement = this.pacRef.current
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete &&
      addressInputElement
    ) {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        addressInputElement,
        options,
      )

      this.autocomplete.setFields([
        'name',
        'formatted_address',
        'address_components',
        'geometry',
      ])

      this.autocomplete.addListener('place_changed', this.handleAddressSelect)
    }

    const { Co, callHandlerData } = this.props
    const Data = callHandlerData.data
    let { Customer, Site } = Data
    if (Customer.type == 'new' || Customer.type == 'saved') {
      Customer.type = 'draft'
      Customer.Co = Co
      this.setState({ ...Customer }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { Co, callHandlerData } = this.props
    const Data = callHandlerData.data
    let { googleLoaded } = this.state
    if (!googleLoaded) {
    }
    let { Customer, Site } = Data
    if (Customer.type == 'new' || Customer.type == 'saved') {
      Customer.type = 'draft'
      Customer.Co = Co
      this.setState({ ...Customer }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  canBeSubmitted() {
    const { FirstName, LastName, Name, Address1, City, State, Zip, Data } =
      this.state
    return (
      ((FirstName && LastName) || Name) &&
      Address1 &&
      City &&
      State &&
      Zip &&
      Data &&
      Data.Contacts &&
      Data.Contacts.length > 0 &&
      Address1.length > 0 &&
      City.length > 0 &&
      State.length > 0 &&
      Zip.length > 0
    )
  }

  getAddress = () => {
    const oldAddress = this.state.Address
    const { Address1, Zip } = this.state
    const Address =
      Address1 && Zip && Address1.length > 3 && Zip.length >= 3
        ? `${Address1.trim()} ${Zip.trim()}`
        : false
    if (!_.isEqual(oldAddress, Address)) {
      this.setState({ Address }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1 &&
      City &&
      State &&
      Zip &&
      Address1.length > 3 &&
      City.length > 2 &&
      State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  handleScriptLoad = () => {}

  handleAddressSelect = () => {
    const componentForm = {
      street_number: 'short_name',
      route: 'short_name',
      locality: 'short_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
    }

    const place = this.autocomplete.getPlace()
    if (place && place.address_components) {
      const address = place.address_components
      const location = place.geometry ? place.geometry.location : ''
      let addressObject = {}
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0]
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]]
          addressObject[addressType] = val
        }
      }
      if (address) {
        const address1 = `${addressObject.street_number} ${addressObject.route}`
        this.setState(
          {
            Address1: address1
              ? address1.normalize('NFD').replace(/([^0-9a-zA-Z\s])/g, '')
              : null,
            City: addressObject.locality
              ? addressObject.locality
                  .normalize('NFD')
                  .replace(/([^0-9a-zA-Z\s])/g, '')
              : null,
            State: addressObject.administrative_area_level_1,
            Country: addressObject.country,
            Zip: addressObject.postal_code,
            Latitude: location && location.lat ? location.lat() : null,
            Longitude: location && location.lng ? location.lng() : null,
          },
          this.getAddress,
          window['warn'](place, this.state),
        )
      }
    } else {
      this.pacRef.current.focus()
    }
  }

  getAddress = () => {
    const oldAddress = this.state.Address
    const { Address1, Zip } = this.state
    const Address =
      Address1 && Zip && Address1.length > 3 && Zip.length >= 3
        ? `${Address1.trim()} ${Zip.trim()}`
        : false
    if (!_.isEqual(oldAddress, Address)) {
      this.setState({ Address }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { Co, callHandlerData } = this.props
    const Data = callHandlerData.data
    // let { googleLoaded } = this.state;
    // if (!googleLoaded) {

    // }
    let { Customer, Site } = Data
    if (Customer.type == 'new' || Customer.type == 'saved') {
      Customer.type = 'draft'
      Customer.Co = Co
      this.setState({ ...Customer }, () => {
        this.props.setCallHandlerData({ ...this.state })
      })
    }
  }

  canBeSubmitted() {
    const { FirstName, LastName, Name, Address1, City, State, Zip, Data } =
      this.state
    window['warn'](
      '6667777wwww',
      FirstName,
      LastName,
      Name,
      Address1,
      City,
      State,
      Zip,
      Data.Contacts,
    )
    return (
      ((FirstName && LastName) || Name) &&
      Address1 &&
      City &&
      State &&
      Zip &&
      Data &&
      Data.Contacts &&
      Data.Contacts.length > 0 &&
      Address1.length > 0 &&
      City.length > 0 &&
      State.length > 0 &&
      Zip.length > 0
    )
  }

  buildAddress(Address1, City, State, Zip) {
    return Address1 &&
      City &&
      State &&
      Zip &&
      Address1.length > 3 &&
      City.length > 2 &&
      State.length > 1
      ? `${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`
      : false
  }

  handleScriptLoad = () => {}

  submitCustomer = async () => {
    const {
      Address1,
      Address2,
      City,
      State,
      Zip,
      Country,
      FirstName,
      LastName,
      Phone,
      Email,
    } = this.state
    const response = await this.props.createCustomer({
      ...this.state,
      BillAddress1: Address1,
      BillAddress2: Address2,
      BillCity: City,
      BillState: State,
      BillZip: Zip,
      BillCountry: Country,
      BillContact: `${FirstName} ${LastName}`,
      BillPhone: Phone,
      BillEmail: Email,
    })

    if (response.Data.DuplicatedEntities.length > 0) {
      this.setState({
        ...this.state,
        duplicatedEntitiesList: response.Data.DuplicatedEntities,
        showConfirmDuplicateCustomerCreationDialog: true,
      })
    }

    return response
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }

  handleAddTag = tag => {
    let { Co } = this.state
    if (this.state.Data.Tags) {
      let { Tags } = this.state.Data
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState(
        { ...this.state, Data: { ...this.state.Data, Tags } },
        () => {
          this.props.setCallHandlerData({ ...this.state })
        },
      )
    } else {
      let Tags = []
      Tags.push({
        ID: this.guid(),
        Co,
        Title: tag.toString(),
        AddedBy: this.props.user.username,
        AddedDate: new Date().toLocaleDateString('en-US'),
        ActiveYN: 'Y',
        Status: 'New',
      })
      this.setState(
        { ...this.state, Data: { ...this.state.Data, Tags } },
        () => {
          this.props.setCallHandlerData({ ...this.state })
        },
      )
    }
  }

  handleDeleteTag = tag => {
    let { Tags } = this.state.Data
    if (Tags == null) {
      Tags = []
    }
    for (var i = 0; i < Tags.length; i++) {
      if (Tags[i].ID == tag.ID) {
        if (isNaN(Tags[i].ID)) {
          Tags.splice(i, 1)
        } else {
          Tags[i].ActiveYN = 'N'
          Tags[i].Status = 'Update'
        }
      }
    }
    this.setState({ ...this.state, Data: { ...this.state.Data, Tags } }, () => {
      this.props.setCallHandlerData({ ...this.state })
    })
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  toggleHistory = () => {
    const { showHistory } = this.state
    this.setState({ ...this.state, showHistory: !showHistory })
  }

  render() {
    const {
      classes,
      securables,
      theme,
      googleMapsApiKey,
      openCustomer,
      createCustomer,
    } = this.props
    const {
      Co,
      Customer,
      Name,
      FirstName,
      LastName,
      Address1,
      Address2,
      City,
      State,
      Zip,
      Country,
      Email,
      Contact,
      Phone,
      value,
      histTab,
      Address,
      showHistory,
    } = this.state
    const histTabs = ['Work Order', 'Invoice', 'Payment', 'Estimate']
    const histIcons = [
      <Assignment className='text-24 ml-12 mr-12' />,
      <Receipt className='text-24 ml-12 mr-12' />,
      <Payment className='text-24 ml-12 mr-12' />,
      <ListIcon className='text-24 ml-12 mr-12' />,
    ]
    const disabled = Boolean(this.state.Customer)
    const accessLevel = _.find(securables, { Securable: 'call-handler' })
    const due =
      this.state.Data &&
      this.state.Data.VPARCM &&
      this.state.Data.VPARCM.Data &&
      this.state.Data.VPARCM.Data.VPARMTCustAmtDue
        ? this.state.Data.VPARCM.Data.VPARMTCustAmtDue.CustAmtDue
        : 0
    const paid =
      this.state.Data &&
      this.state.Data.VPARCM &&
      this.state.Data.VPARCM.Data &&
      this.state.Data.VPARCM.Data.VPARMT
        ? _.sumBy(this.state.Data.VPARCM.Data.VPARMT, o => {
            return o.Paid
          })
        : 0
    const invoiced =
      this.state.Data &&
      this.state.Data.VPARCM &&
      this.state.Data.VPARCM.Data &&
      this.state.Data.VPARCM.Data.VPARMT
        ? _.sumBy(this.state.Data.VPARCM.Data.VPARMT, o => {
            return o.Invoiced
          })
        : 0

    return (
      <FuseAnimate animation='transition.slideUpIn' delay={300}>
        <DialogContent classes={{ root: 'p-12 sm:p-24' }}>
          <Typography
            variant='h6'
            style={{
              alignItems: 'center',
              marginBottom: 12,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: 'calc(100% - 136px)',
            }}
            className='w-full sm:flex text-20'
          >
            <Icon color='primary' className='text-24 mr-8 align-middle'>
              account_circle
            </Icon>
            {this.state.Customer ? (
              <span>
                Customer #{this.state.Customer} Info
                <Tooltip placement='top' title='Edit Customer'>
                  <Icon
                    className='text-20 ml-8 mb-4 align-middle'
                    onClick={() => {
                      openCustomer(this.state)
                    }}
                  >
                    open_in_new
                  </Icon>
                </Tooltip>
              </span>
            ) : (
              <span>Customer Info</span>
            )}
            {this.state.ID && (
              <Button
                onClick={this.toggleHistory}
                color='primary'
                variant='contained'
                className={classNames(
                  classes.black,
                  'text-12 pin-r absolute mr-48 sm:mr-64 md:mr-24 capitalize mt-4 h-24',
                )}
              >
                <Icon className={classNames(classes.greenText, 'mr-4 text-16')}>
                  history
                </Icon>
                History
              </Button>
            )}
          </Typography>
          {showHistory && (
            <div className='w-full sm:flex'>
              <div className='hidden sm:block min-w-48 pt-20'>
                <Icon color='action'>history</Icon>
              </div>
              <Card classes={{ root: 'w-full mb-24' }}>
                <div className={classNames(classes.hist)}>
                  <Drawer
                    variant='permanent'
                    className={classNames(classes.drawer, {
                      [classes.drawerOpen]: this.state.open,
                      [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                      paper: classNames(classes.shelf, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                      }),
                    }}
                    open={this.state.open}
                  >
                    <List>
                      <ListItem
                        onClick={() => this.setState({ histTab: 0 })}
                        button
                        key='Work Orders'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Orders' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.WorkOrders || 0}
                              color='error'
                            >
                              <Assignment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 1 })}
                        button
                        key='Work Order Scopes'
                      >
                        <ListItemIcon>
                          <Tooltip title='Work Order Scopes' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Scopes || 0}
                              color='error'
                            >
                              <Build />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 2 })}
                        button
                        key='Invoices'
                      >
                        <ListItemIcon>
                          <Tooltip title='Invoices' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Invoices || 0}
                              color='error'
                            >
                              <Receipt />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 3 })}
                        button
                        key='Payments'
                      >
                        <ListItemIcon>
                          <Tooltip title='Payments' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Payments || 0}
                              color='error'
                            >
                              <Payment />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ histTab: 4 })}
                        button
                        key='Estimates'
                      >
                        <ListItemIcon>
                          <Tooltip title='Estimates' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={this.state.Estimates || 0}
                              color='error'
                            >
                              <ListIcon />
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                      <ListItem
                        onClick={() => this.setState({ showEmailLogs: true })}
                        button
                        key='Emails'
                      >
                        <ListItemIcon>
                          <Tooltip title='Email Log' placement='right'>
                            <Badge
                              max={999}
                              badgeContent={
                                this.state.Data && this.state.Data.EmailLogs
                                  ? this.state.Data.EmailLogs.length
                                  : 0
                              }
                              color='error'
                            >
                              <Icon>email</Icon>
                            </Badge>
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Drawer>
                  <EmailLogDialog
                    title={`Customer #${this.state.Customer} Email Log`}
                    type='CUSTOMER'
                    rec={this.state.ID}
                    open={this.state.showEmailLogs}
                    onClose={() => this.setState({ showEmailLogs: false })}
                  />
                  <div className={classes.content}>
                    <div className={classes.histTable}>
                      {histTab === 0 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrdersList
                            hist={{
                              PageCount: Math.ceil(this.state.WorkOrders / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.WorkOrders,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrders || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 1 && (
                        <TabContainer padding={'0px'}>
                          <WorkOrderScopesList
                            hist={{
                              PageCount: Math.ceil(this.state.Scopes / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Scopes,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            selectedWOData={[
                              ...(this.state.Data.WorkOrderScopes || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 2 && (
                        <TabContainer padding={'0px'}>
                          <InvoicesList
                            hist={{
                              PageCount: Math.ceil(this.state.Invoices / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Invoices,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            invoiceData={[...(this.state.Data.Invoices || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 3 && (
                        <TabContainer padding={'0px'}>
                          <PaymentsList
                            hist={{
                              PageCount: Math.ceil(this.state.Payments / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Payments,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            payments={[...(this.state.Data.Payments || [])]}
                          />
                        </TabContainer>
                      )}
                      {histTab === 4 && (
                        <TabContainer padding={'0px'}>
                          {/* <EstimateDialog /> */}
                          <EstimatesList
                            hist={{
                              PageCount: Math.ceil(this.state.Estimates / 10),
                              Results: [],
                              Page: 1,
                              PageSize: 10,
                              TotalCount: this.state.Estimates,
                            }}
                            customer={this.state.Customer}
                            site={this.state.Site}
                            estimateData={[
                              ...(this.state.Data.Estimates || []),
                            ]}
                          />
                        </TabContainer>
                      )}
                    </div>
                  </div>
                  <IconButton
                    className='pin-r absolute w-36 h-36 mt-4 mr-4'
                    onClick={this.toggleHistory}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </div>
              </Card>
            </div>
          )}
          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>account_box</Icon>
            </div>

            <TextField
              className={classes.formControl}
              label='First Name'
              id='firstName'
              name='FirstName'
              value={this.state.FirstName || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
            <div className='hidden sm:block min-w-12 pt-20'></div>
            <TextField
              className={classes.formControl}
              label='Last Name'
              id='lastName'
              name='LastName'
              value={this.state.LastName || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>domain</Icon>
            </div>
            <TextField
              className={classes.formControl}
              label='Company'
              id='company'
              name='Name'
              value={this.state.Name || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>home</Icon>
            </div>
            <TextField
              className={classes.formControl}
              label='Address'
              id='address1'
              inputRef={this.pacRef}
              name='Address1'
              value={this.state.Address1 || ''}
              onChange={this.handleChange}
              onBlur={this.getAddress}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'></div>
            <TextField
              className={classes.formControl}
              label="Address (cont'd)"
              id='address2'
              name='Address2'
              value={this.state.Address2 || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'></div>
            <TextField
              className={classes.formControl}
              label='City'
              id='city'
              name='City'
              value={this.state.City || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
            <div className='hidden sm:block min-w-12 pt-20'></div>
            <TextField
              className={classes.formControl}
              label='State'
              id='state'
              name='State'
              value={this.state.State || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
            <div className='hidden sm:block min-w-12 pt-20'></div>
            <TextField
              className={classes.formControl}
              label='Zip Code'
              id='zip'
              name='Zip'
              value={this.state.Zip || ''}
              onChange={this.handleChange}
              onBlur={this.getAddress}
              inputProps={{
                autoComplete: 'off',
              }}
              variant='outlined'
              required
              disabled={disabled}
              fullWidth
            />
          </div>

          {/*<div className="w-full sm:flex">
                        <div className="hidden sm:block min-w-48 pt-20">
                            <Icon color="action">phone</Icon>
                        </div>
                        {/* <TextField
                            className={classes.formControl}
                            label="Phone"
                            id="phone"
                            name="Phone"
                            value={this.state.Phone || ''}
                            onChange={this.handleChange}
                            inputProps={{
                                autoComplete: "off"
                            }}
                            variant="outlined"
                            required
                            disabled={disabled}
                            fullWidth
                        /> */}
          {/* <PhoneInput 
                            {...this.props}
                            className={classes.formControl}
                            label="Phone"
                            id="phone"
                            name="Phone"
                            value={this.state.Phone || ''} 
                            onChange={(value) => {
                                this.setState({...this.state, Phone: value});
                            }}
                            inputProps={{
                                autoComplete: "off"
                            }}
                            variant="outlined" 
                            required={true}
                            disabled={disabled}
                            fullWidth={true}
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <TextField
                            className={classes.formControl}
                            label="Email"
                            id="email"
                            name="Email"
                            value={this.state.Email || ''}
                            onChange={this.handleChange}
                            inputProps={{
                                autoComplete: "off"
                            }}
                            variant="outlined"
                            required
                            disabled={disabled}
                            fullWidth
                        /> */}
          {/*</div> */}

          <div className='w-full sm:flex mb-12' style={{ marginTop: -8 }}>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>contacts</Icon>
            </div>
            <ContactList
              label={'Contacts * '}
              onUpdate={Contacts =>
                this.setState(
                  { ...this.state, Data: { ...this.state.Data, Contacts } },
                  () => this.props.setCallHandlerData({ ...this.state }),
                )
              }
              editable={true}
              customer={{ ...this.state }}
              contact={
                /*(!this.state.ID && (!this.state.Data.Contacts || this.state.Data.Contacts.length < 1) && ((this.state.FirstName && this.state.LastName) || this.state.Name)) ? { Name: (this.state.FirstName && this.state.LastName) ? `${this.state.FirstName} ${this.state.LastName}` : this.state.Name, Phone: this.state.Phone && this.state.Phone.length >= 7 ? this.state.Phone : null, Email: this.state.Email && this.state.Email.length > 0 ? this.state.Email : null, Data: { ErrMsg: null } } :*/ null
              }
              details={this.state.Data.Contacts || []}
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>note</Icon>
            </div>
            <TextField
              className={classes.formControl}
              label='Notes'
              id='notes'
              name='Notes'
              value={this.state.Notes || ''}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              variant='outlined'
              inputProps={{
                autoComplete: 'off',
              }}
              multiline
              rows={5}
              disabled={disabled}
              fullWidth
            />
          </div>

          <div className='w-full sm:flex'>
            <div className='hidden sm:block min-w-48 pt-20'>
              <Icon color='action'>assistant</Icon>
            </div>
            <ChipInput
              blurBehavior='add'
              className={classes.formControl}
              variant='outlined'
              label='Tags'
              id='tags'
              name='Tags'
              value={this.state.Data.Tags}
              onAdd={this.handleAddTag}
              onDelete={this.handleDeleteTag}
              chipRenderer={({ value, handleDelete }, key) =>
                value.ActiveYN == 'Y' &&
                (disabled ? (
                  <Tooltip
                    title={`Added By ${value.AddedBy} on ${new Date(
                      value.AddedDate,
                    ).toLocaleDateString('en-US')}`}
                    placement='top'
                  >
                    <Chip
                      className='mb-8 mr-4'
                      color='primary'
                      icon={<Icon>account_circle</Icon>}
                      label={value.Title}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`Added By ${value.AddedBy} on ${new Date(
                      value.AddedDate,
                    ).toLocaleDateString('en-US')}`}
                    placement='top'
                  >
                    <Chip
                      className='mb-8 mr-4'
                      color='primary'
                      icon={<Icon>account_circle</Icon>}
                      onDelete={handleDelete}
                      label={value.Title}
                    />
                  </Tooltip>
                ))
              }
              fullWidth
              fullWidthInput
              disabled={disabled}
            />
          </div>
          {accessLevel && accessLevel.AccessLevel !== 'R' && (
            <DialogActions className='dialog-actions justify-end m-0'>
              {!Customer && (
                <DebounceButton
                  buttonText={'Create Customer'}
                  processingText={'Creating Customer'}
                  isDisabled={!this.canBeSubmitted()}
                  variant={'contained'}
                  color={'primary'}
                  clickFxn={this.submitCustomer}
                  debounceInterval={3000}
                />
              )}
            </DialogActions>
          )}
          {this.state.showConfirmDuplicateCustomerCreationDialog && (
            <DuplicateCustomerWarningDialog
              open={this.state.showConfirmDuplicateCustomerCreationDialog}
              isCreatingCustomer={true}
              duplicatedEntitiesList={this.state.duplicatedEntitiesList}
              onConfirm={async () => {
                this.setState(
                  {
                    ...this.state,
                    Data: {
                      ...this.state.Data,
                      IgnoreDuplicatedEntitiesCheck: true,
                    },
                    showConfirmDuplicateCustomerCreationDialog: false,
                  },
                  async () => await this.submitCustomer(),
                )
              }}
              onCancel={() =>
                this.setState({
                  ...this.state,
                  showConfirmDuplicateCustomerCreationDialog: false,
                })
              }
              onSelectExistingCustomer={async customer => {
                const response = await this.props.getCustomer(
                  customer.Co,
                  customer.Customer,
                )
                this.props.setCallHandlerData(response)
                this.setState({
                  ...this.state,
                  showConfirmDuplicateCustomerCreationDialog: false,
                })
              }}
            />
          )}
        </DialogContent>
      </FuseAnimate>
    )
  }
}
CustomersTab.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage: showMessage,
      setCallHandlerData: Actions.setCustomerData,
      openCustomer: openEditCustomerDialog,
      createCustomer: Actions.createCustomer,
      getCustomer: getCustomerData,
    },
    dispatch,
  )
}

function mapStateToProps({ callHandlerApp, spReducers }) {
  return {
    googleMapsApiKey: googleMapsApiKey,
    user: callHandlerApp.user,
    callHandlerData: callHandlerApp.callHandler,
    Co: spReducers.companies.Co,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CustomersTab),
)
