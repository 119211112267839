import InventoryLocationsApp from 'main/content/apps/inventory-locations/InventoryLocationsApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const InventoryLocationsAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/inventory-locations/:id',
            component: InventoryLocationsApp
        },
        {
            path     : '/apps/inventory-locations',
            component: () => <Redirect to="/apps/inventory-locations/all"/>
        }
    ]
};
