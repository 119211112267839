import axios from 'axios/index';
import { showMessage } from 'store/actions/fuse';
import { buildBoard } from './board.actions';
import _ from '@lodash';

export const OPEN_CARD_DIALOG = '[SCRUMBOARD APP] OPEN CARD DIALOG';
export const ADD_SUBTASK = '[SCRUMBOARD APP] ADD SUBTASK';
export const REMOVE_SUBTASK = '[SCRUMBOARD APP] REMOVE SUBTASK';
export const OPEN_SUBTASK = '[SCRUMBOARD APP] OPEN SUBTASK';
export const OPEN_BREADCRUMB_SUBTASK = '[SCRUMBOARD APP] OPEN BREADCRUMB SUBTASK';
export const CLOSE_CARD_DIALOG = '[SCRUMBOARD APP] CLOSE CARD DIALOG';
export const UPDATE_CARD = '[SCRUMBOARD APP] UPDATE CARD';
export const CHANGE_CARD = '[SCRUMBOARD APP] CHANGE CARD';
export const REMOVE_CARD = '[SCRUMBOARD APP] REMOVE CARD';
export const CHIP_CHANGE_CARD = '[SCRUMBOARD APP] CHIP CHANGED CARD';
export const NEW_CHIP_CARD = '[SCRUMBOARD APP] NEW CHIP CARD';
export const MAKE_COVER_CARD = '[SCRUMBOARD APP] MAKE COVER CARD';
export const REMOVE_COVER_CARD = '[SCRUMBOARD APP] REMOVE COVER CARD';
export const ADD_ATTACHMENT_CARD = '[SCRUMBOARD APP] ADD ATTACHMENT CARD';
export const REMOVE_ATTACHMENT_CARD = '[SCRUMBOARD APP] REMOVE ATTACHMENT CARD';
export const REMOVE_DUE_CARD = '[SCRUMBOARD APP] REMOVE DUE CARD';
export const TOGGLE_LABEL_CARD = '[SCRUMBOARD APP] TOGGLE LABEL CARD';
export const TOGGLE_MEMBER_CARD = '[SCRUMBOARD APP] TOGGLE MEMBER CARD';
export const ADD_CHECKLIST_CARD = '[SCRUMBOARD APP] ADD CHECKLIST CARD';
export const UPDATE_CHECKLIST_CARD = '[SCRUMBOARD APP] UPDATE CHECKLIST CARD';
export const REMOVE_CHECKLIST_CARD = '[SCRUMBOARD APP] REMOVE CHECKLIST CARD';
export const ADD_CHECKLIST_CARD_ITEM = '[SCRUMBOARD APP] ADD CHECKLIST CARD ITEM';
export const UPDATE_CHECKLIST_CARD_ITEM = '[SCRUMBOARD APP] UPDATE CHECKLIST CARD ITEM';
export const REMOVE_CHECKLIST_CARD_ITEM = '[SCRUMBOARD APP] REMOVE CHECKLIST CARD ITEM';
export const CHECKLIST_CHANGE_CARD = '[SCRUMBOARD APP] CHECKLIST CHANGE CARD';
export const CHECKLIST_REMOVE_CARD = '[SCRUMBOARD APP] CHECKLIST REMOVE CARD';
export const COMMENT_ADD_CARD = '[SCRUMBOARD APP] COMMENT ADD CARD';

export function openCardDialog(data, fetch) {
    if (!fetch) {
        return {
            type: OPEN_CARD_DIALOG,
            payload: data
        }
    } else {
        return (dispatch) => {
            const request = axios.get(`${window["apiLocation"]}/api/ScrumBoardCard?Card=${data.ID}`);

            return request.then((response) => {
                dispatch({
                    type: OPEN_CARD_DIALOG,
                    payload: response.data
                });
            });
        }
    }
}

export function openSubtask(data, fetch) {
    if (!fetch) {
        return {
            type: OPEN_SUBTASK,
            payload: data
        }
    } else {
        return (dispatch) => {
            const request = axios.get(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${data.Co}&Board=${data.BoardID}&Card=${data.ID}`);

            return request.then((response) => {
                dispatch({
                    type: OPEN_SUBTASK,
                    payload: response.data
                });
            });
        }
    }
}

export function openBreadcrumbSubtask(data, index) {
    return (dispatch) => {
        const request = axios.get(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${data.Co}&Board=${data.BoardID}&Card=${data.ID}`);

        return request.then((response) => {
            dispatch({
                type: OPEN_BREADCRUMB_SUBTASK,
                payload: response.data,
                index: index
            })
        })
    }
}

export function convertToSubtask(result) {
    return (dispatch, getState) => {

        const { board } = getState().scrumboardApp;
        const { Cards } = board.Data;

        const ParentID = result.combine ? result.combine.draggableId : null;
        const card = _.find(Cards, { ID: result.draggableId });
        if (!card) return;

        // Update Optimistically
        dispatch({
            type: UPDATE_CARD,
            payload: {
                card: { ...card, ParentID },
                clearList: true
            }
        });

        const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}&Card=${card.ID}`, { ...card, ParentID, ListID: null })

        return request.then(() => {
            dispatch(showMessage({
                message: 'Card Saved as Subtask',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));

        })
            .catch((err) => {
                // Roll back
                dispatch({
                    type: UPDATE_CARD,
                    payload: {
                        card: { ...card, ParentID: null },
                        clearList: false
                    }
                });

            })

    }
}

export function subtaskAdd(card, existing) {
    const newCard = { ...card, ListID: null };
    const request = existing ?
        axios.put(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}&Card=${card.ID}`, newCard)
        :
        axios.post(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}`, newCard)

    return (dispatch, getState) => {
        return request.then((response) => {
            dispatch({
                type: UPDATE_CARD,
                payload: {
                    card,
                    clearList: true
                }
            });

            dispatch({
                type: ADD_SUBTASK,
                payload: response.data
            });

            dispatch(showMessage({
                message: 'Subtask Added',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));

        });
    }
}

export function disassociateSubtask(card) {

    return (dispatch, getState) => {

        const { Cards } = getState().scrumboardApp.board.Data;

        let currCard = card;
        while (!currCard.ListID && currCard.ParentID) {
            currCard = _.find(Cards, { ID: currCard.ParentID });
        }

        const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}&Card=${card.ID}`,
            { ...card, ListID: currCard.ListID, ParentID: null }
        )

        return request.then((response) => {
            dispatch({
                type: UPDATE_CARD,
                payload: {
                    card: response.data
                }
            });

            dispatch({
                type: REMOVE_SUBTASK,
                payload: response.data
            });

            dispatch(showMessage({
                message: 'Subtask Disassociated',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));

        });
    }
}

export function closeCardDialog() {
    return {
        type: CLOSE_CARD_DIALOG
    }
}

export function updateCard(card, bool, stealth) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}&Card=${card.ID}&stealth=${Boolean(stealth)}`,
            card
        );

        return request.then((response) => {
            const state = getState();
            if (!bool) {
                dispatch(showMessage({
                    message: 'Card Saved',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));

                return dispatch({
                    type: UPDATE_CARD,
                    payload: { card }
                });
            }
            else if (bool) {
                dispatch(showMessage({
                    message: 'Card Order Changed',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }));

                return dispatch({
                    type: UPDATE_CARD,
                    payload: { card }
                });
            }

        });
    }
}

export function updateSubtask(card) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}&Card=${card.ID}`,
            card
        );

        return request.then((response) => {
            const state = getState();
            dispatch(showMessage({
                message: 'Task Successfully Updated',
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));

        });
    }
}

export function removeCard(card) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardCard?Co=${card.Co}&Board=${card.BoardID}&List=${card.ListID}&Card=${card.ID}`);

        return request.then((response) => {
            const { board } = _.cloneDeepWith(getState().scrumboardApp);
            const { Cards } = board.Data;
            function spliceCard(cd) {
                const index = _.findIndex(Cards, { ID: cd.ID });
                if (index > -1) {
                    Cards.splice(index, 1);
                }
                const subtasks = _.filter(Cards, { ParentID: cd.ID });
                subtasks.map((crd) => {
                    spliceCard(crd);
                })
            }
            spliceCard(card);
            dispatch(buildBoard(board));
            dispatch(showMessage({
                message: 'Card Removed',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }));
        }
        );
    };
}

export function handleChange(event) {
    return {
        type: CHANGE_CARD,
        target: event.target,
    };
}

export function chipChange(name, value) {
    return {
        type: CHIP_CHANGE_CARD,
        name,
        value
    };
}

export function addNewChip(name, value) {
    return {
        type: NEW_CHIP_CARD,
        name,
        value
    };
}

export function makeCover(attachmentId) {
    return {
        type: MAKE_COVER_CARD,
        attachmentId
    };
}

export function removeCover() {
    return {
        type: REMOVE_COVER_CARD
    };
}

export function addCardAttachment(attachment) {
    return {
        type: ADD_ATTACHMENT_CARD,
        attachment
    }
}

export function removeAttachment(attachment) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/Attachment/DeleteAttachment?Co=${encodeURIComponent(attachment.Co)}&Attachment=${encodeURIComponent(attachment.Attachment)}`);
        return request.then((response) => {
            return dispatch({
                type: REMOVE_ATTACHMENT_CARD,
                attachment
            })
        })
    }
}

export function removeDue() {
    return {
        type: REMOVE_DUE_CARD
    };
}

export function toggleLabel(label) {
    return (dispatch, getState) => {
        const state = getState();
        const card = state.scrumboardApp.card;
        const AddedBy = state.spReducers.userProfiles.User.UserName;
        const tag = _.find(card.Data.Tags, { TagID: label.TagID });
        const request = !tag ? axios.post(`${window["apiLocation"]}/api/ScrumBoardCardTag`, { ...label, AddedBy, AddedDate: new Date(), Data: { ErrMsg: null } }) : axios.delete(`${window["apiLocation"]}/api/ScrumBoardCardTag?Co=${label.Co}&Board=${label.BoardID}&List=${label.ListID}&Card=${label.CardID}&Tag=${label.TagID}`);
        return request.then((response) => {
            return dispatch({
                type: TOGGLE_LABEL_CARD,
                label
            })
        })
    }
}

export function toggleMember(member, method) {
    return (dispatch, getState) => {
        const request = method == 'ADD' ?
            axios.post(`${window["apiLocation"]}/api/ScrumBoardCardMember`, member) :
            axios.delete(`${window["apiLocation"]}/api/ScrumBoardCardMember?Co=${member.Co}&Board=${member.BoardID}&List=${member.ListID}&Card=${member.CardID}&UserName=${encodeURIComponent(member.UserName)}`);
        return request.then((response) => {
            return dispatch({
                type: TOGGLE_MEMBER_CARD,
                member,
                method,
            });
        });
    };
}

export function addChecklist(list) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/ScrumBoardCardChecklist`, list);
        return request.then((response) => {
            return dispatch({
                type: ADD_CHECKLIST_CARD,
                list: response.data
            });
        });
    };
}

export function updateChecklist(list) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardCardChecklist?Co=${list.Co}&Board=${list.BoardID}&List=${list.ListID}&Card=${list.CardID}&Checklist=${list.ID}`, list);
        return request.then((response) => {
            return dispatch({
                type: UPDATE_CHECKLIST_CARD,
                list: response.data
            });
        });
    };
}

export function removeChecklist(list) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardCardChecklist?Co=${list.Co}&Board=${list.BoardID}&List=${list.ListID}&Card=${list.CardID}&Checklist=${list.ID}`);
        return request.then((response) => {
            return dispatch({
                type: REMOVE_CHECKLIST_CARD,
                list
            });
        });
    };
}

export function addChecklistItem(item) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/ScrumBoardCardChecklistItem`, item);
        return request.then((response) => {
            return dispatch({
                type: ADD_CHECKLIST_CARD_ITEM,
                item: response.data
            });
        });
    };
}

export function updateChecklistItem(item) {
    return (dispatch, getState) => {
        const request = axios.put(`${window["apiLocation"]}/api/ScrumBoardCardChecklistItem?Co=${item.Co}&Board=${item.BoardID}&List=${item.ListID}&Card=${item.CardID}&Checklist=${item.ChecklistID}&Item=${item.ID}`, item);
        return request.then((response) => {
            return dispatch({
                type: UPDATE_CHECKLIST_CARD_ITEM,
                item: response.data
            });
        });
    };
}

export function removeChecklistItem(item) {
    return (dispatch, getState) => {
        const request = axios.delete(`${window["apiLocation"]}/api/ScrumBoardCardChecklistItem?Co=${item.Co}&Board=${item.BoardID}&List=${item.ListID}&Card=${item.CardID}&Checklist=${item.ChecklistID}&Item=${item.ID}`);
        return request.then((response) => {
            return dispatch({
                type: REMOVE_CHECKLIST_CARD_ITEM,
                item
            });
        });
    };
}

export function commentAdd(comment) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/ScrumBoardCardComment`, comment);
        return request.then((response) => {
            return dispatch({
                type: COMMENT_ADD_CARD,
                comment
            });
        });
    };
}
