import {combineReducers} from 'redux';
import serviceTypes from './service-types.reducer';
import user from './user.reducer';

const serviceTypesAppReducers = combineReducers({
    serviceTypes,
    user
});

export default serviceTypesAppReducers;
