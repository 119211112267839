import {combineReducers} from 'redux';
import invoices from './invoices.reducer';
import user from './user.reducer';

const invoicesAppReducers = combineReducers({
    invoices,
    user
});

export default invoicesAppReducers;
