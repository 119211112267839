import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, CircularProgress, Divider, Icon, List, ListItem, ListItemText, TextField, MenuItem, Paper, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import _ from '@lodash';
import { FuseAnimate } from '@fuse';
import * as Actions from './store/actions';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    }
});


const initialState = {
    Estimate: {
        BusinessUnit: null,
        Division: null,
    },
    Status: null,
}

class EstimatesSidebarContent extends Component {

    state = {
        ...initialState
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? 'Y' : 'N' : event.target.value));
    };

    componentDidMount() {
        const { filters, loading } = this.props;
        if (filters) {
            this.setState(filters ? { ...filters, loading } : { ...initialState, loading });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { filters, loading } = this.props;
        console.warn(filters, prevProps.filters, this.state);
        if (!_.isEqual(filters, prevProps.filters) || !_.isEqual(loading, prevProps.loading)) {
            this.setState(filters ? { ...filters, loading } : { ...initialState, loading });
        }
    }

    setFilters = () => {
        const filters = this.buildFilters(this.state);
        this.props.setEstimateFilters(filters);
    }

    buildFilters = (data) => {

        const filters = {
            Estimate: {},
        };
        Object.keys(data.Estimate).map((key) => {
            const value = data.Estimate[key];
            if (value) {
                filters.Estimate[key] = value;
            }
        });
        Object.keys(data).map((key) => {
            const value = data[key];
            if (key !== 'Estimate') {
                if (value) {
                    filters[key] = value;
                }
            }
        });
        return filters;
    }

    clearFilters = () => {
        this.setState({ ...initialState }, () => this.props.setEstimateFilters(null));
    }
    render() {
        const { classes, user, Co, businessUnits, divisions, filters } = this.props;
        const { loading } = this.state;
        const currentFilters = this.buildFilters(this.state);
        return (
            <div onClick={(e) => e.stopPropagation()} className="p-12 lg:pl-20">
                <FuseAnimate animation="transition.slideLeftIn" delay={200}>
                    <Paper elevation={1} className="rounded-8 p-12 relative">
                        <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 8, }} className="flex"><Icon color="primary" className="text-32 mr-12">search</Icon>Search</Typography>
                        {filters &&
                            <Icon onClick={this.clearFilters} className="pin-t pin-r absolute mt-16 mr-12 cursor-pointer" color="action">close</Icon>
                        }
                        <Autocomplete
                            className="mb-12"
                            title="Business Unit"
                            options={businessUnits ? businessUnits : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.BusinessUnit}>
                                    {value.Description}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.Estimate.BusinessUnit || ''}
                            onSelect={(option) => this.setState({ ...this.state, Estimate: { ...this.state.Estimate, BusinessUnit: option.BusinessUnit, Division: null, EstimateType: null, }, Task: null })}
                        />
                        <Autocomplete
                            className="mb-12"
                            title="Division"
                            options={divisions ? _.filter(divisions, { BusinessUnit: this.state.Estimate.BusinessUnit }) : []}
                            menuItemComponent={(value) => {
                                return <MenuItem value={value.Division}>
                                    {value.Description}
                                </MenuItem>
                            }}
                            portal={true}
                            value={this.state.Estimate.Division || ''}
                            onSelect={(option) => this.setState({ ...this.state, Estimate: { ...this.state.Estimate, Division: option.Division, EstimateType: null, }, Task: null })}
                        />
                        <TextField
                            className="mb-12"
                            label="Status"
                            id="status"
                            name="Status"
                            value={this.state.Status !== null && this.state.Status != undefined ? this.state.Status : ''}
                            onChange={(e) => this.setState({ ...this.state, Status: e.target.value })}
                            variant="outlined"
                            select
                            fullWidth
                        >
                            <MenuItem value={null}>
                                Any
                            </MenuItem>
                            <MenuItem value={1}>
                                Accepted
                            </MenuItem>
                            <MenuItem value={2}>
                                Scheduled
                            </MenuItem>
                            <MenuItem value={3}>
                                Completed
                            </MenuItem>
                            <MenuItem value={0}>
                                Open
                            </MenuItem>
                            <MenuItem value={4}>
                                Expired
                            </MenuItem>
                        </TextField>
                        {
                            filters && _.isEqual(filters, currentFilters) ?
                                <Button
                                    variant="contained"
                                    fullWidth
                                    className={classes.clear}
                                    onClick={this.clearFilters}
                                >
                                    Clear Filters
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => this.props.getEstimates(Co, { ...this.state })}
                                    disabled={loading}
                                >
                                    {this.state.loading &&
                                        <CircularProgress color="primary" style={{ width: 16, height: 16, marginRight: 4 }} />
                                    }
                                    {this.state.loading ? 'Searching' : 'Search'}
                                </Button>
                        }
                    </Paper>
                </FuseAnimate>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setEstimateFilters: Actions.setEstimateFilters,
        getEstimates: Actions.getEstimates
    }, dispatch);
}

function mapStateToProps({ spReducers, estimatesApp }) {
    return {
        Co: spReducers.companies.Co,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        estimateTypes: spReducers.estimateTypes,
        filters: estimatesApp.estimates.filters,
        loading: estimatesApp.estimates.loading,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesSidebarContent)));
