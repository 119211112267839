import { FuseAnimate, FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  AppBar,
  Tabs,
  Tab,
  Avatar,
  Badge,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  Fab,
  Grow,
  Icon,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Parser } from 'json2csv'
import moment from 'moment'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import { bindActionCreators } from 'redux'
import { updateTableSettings } from '../users/store/actions'
import * as WorkOrderActions from '../work-orders/store/actions'
import { openEditCustomerDialog } from '../customers/store/actions'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import { openEditWorkOrderDialog } from '../work-orders/store/actions'
import axios from 'axios'
import getProfileImage from '../../../functions/getProfileImageUrl'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function TabContainer(props) {
  return <Typography component='div'>{props.children}</Typography>
}

function LoadingDiv() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .65)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        className='loader stretchBar'
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
        }}
      >
        <div className='rect1'></div>
        <div className='rect2'></div>
        <div className='rect3'></div>
        <div className='rect4'></div>
        <div className='rect5'></div>
        <br />
        <img
          style={{ width: 80, marginTop: 15 }}
          src='assets/images/splash/SPLoading.png'
        />
      </div>
    </div>
  )
}

function NoData() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .25)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
          paddingTop: 75,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        No Records Found
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    maxHeight: '70vh',
  },
  dialogRoot: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
    overflowX: 'hidden',
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailList: {
    padding: 0,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  labels: {},
  techAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  callAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  doneAvatar: {
    backgroundColor: '#5f5f5f',
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  actions: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
  },
  tab: {
    minWidth: '25%',
    maxWidth: '25%',
    // paddingTop: 12,
    // paddingBottom: 12,
  },
})

class CallsList extends Component {
  state = {
    selectedCallsMenu: null,
    date: new Date(),
    loading: false,
    tab: 0,
  }

  componentDidMount() {
    let { date, loading } = this.props
    if (date) {
      this.setState({ ...this.state, date, loading })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { date, loading } = this.props
    if (
      !_.isEqual(date, prevProps.date) ||
      !_.isEqual(loading, prevProps.loading)
    ) {
      this.setState({ ...this.state, date, loading })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      window['log']('Filtered Array: ', arr)
      return arr
    }
    window['log']('Filtered Array: ', arr, searchText)
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  formatDateInput(dt) {
    return dt.toLocaleDateString('en-US').replace(/\//g, '-')
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  openDatePicker = e => {
    this.refs.datePicker.open(e)
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer mr-8'>
      {this.formatDateText(this.state.date)}
    </span>
  )

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  handleRowCount = count => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'calls' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'calls',
        Preferences: {
          RowCount: count,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.RowCount = count
    }
    this.props.updateTableSettings(prefs)
  }

  handleSortOrder = sort => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'calls' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'calls',
        Preferences: {
          SortOrder: sort,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.SortOrder = sort
    }
    this.props.updateTableSettings(prefs)
  }

  export = () => {
    window['warn']('CSV DATA!:', this.props.selectedCallData)
    const data = this.props.selectedCallData
    const csvData = new Parser()
    const Data = csvData.parse(data)
    var encoded = encodeURIComponent(Data)
    var csv = `data:text/csv;charset=utf-8, ${encoded}`
    var link = document.createElement('a')
    link.setAttribute('href', csv)
    link.setAttribute('download', 'Call Log.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  formatPhone(phone) {
    return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`
  }

  getCall = call => {
    const request = axios.get(
      `${window['apiLocation']}/api/Call?Co=${
        call.Co
      }&CallSid=${encodeURIComponent(call.CallSid)}`,
    )
    request.then(response => {
      window['warn']('Call Info: ', response.data)
      this.setState({ recording: response.data })
    })
  }

  handleTabChange = (event, tab) => {
    if (this.state.tab == tab) {
      this.setState({ tab: -1 })
    } else {
      this.setState({ tab })
    }
  }

  render() {
    const {
      Co,
      fixed,
      header,
      classes,
      securables,
      users,
      audit,
      loading2,
      callQueues,
      phoneNumbers,
      openCustomer,
      openSite,
      openWorkOrder,
      workOrders,
      user,
      searchText,
      openNewWorkOrderDialog,
      selectedWorkOrderIds,
      selectAllCalls,
      deSelectAllCalls,
      toggleInSelectedCalls,
      openEditWorkOrderDialog,
      removeCalls,
      removeWorkOrder,
      toggleStarredWorkOrder,
      setCallsUnstarred,
      setCallsStarred,
      openNewWorkOrderScopeDialog,
    } = this.props
    const data = loading
      ? []
      : !this.props.selectedCallData
        ? this.getFilteredArray(
            !this.props.selectedCallData
              ? !this.props.workOrderData
                ? []
                : this.props.workOrderData
              : this.props.selectedCallData,
            searchText,
          )
        : this.props.selectedCallData
    const { loading, recording, tab } = this.state
    const { TableSettings } = user.Data
    const prefs = _.find(TableSettings, { Table: 'calls' })
    const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
    const userAvatar = '../../assets/images/avatars/profile.jpg'
    const call = recording
    const queue = call
      ? _.find(callQueues, { Co: call.Co, Queue: call.Queue })
      : null
    const phone = call
      ? _.find(phoneNumbers, { Co: call.Co, Phone: call.To })
      : null
    const { DefaultBusinessUnit, DefaultDivision, DefaultDepartment } = queue
      ? queue
      : {}
    const dbu = DefaultBusinessUnit
    const ddv = DefaultDivision
    const ddp = DefaultDepartment
    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <FuseAnimate animation={'transition.fadeIn'} delay={300}>
            <div>
              {audit && (
                <Fab
                  color='primary'
                  aria-label='add'
                  className={classes.addButton}
                  onClick={this.export}
                  disabled={
                    this.props.selectedCallData.length <= 0 ? true : false
                  }
                  // onClick={openNewUserDialog}
                >
                  <Icon className='text-16'>save_alt</Icon>
                </Fab>
              )}
              {header ? (
                header
              ) : (
                <div className='sm:flex p-12 sm:p-0'>
                  <Typography
                    variant='h6'
                    style={{ alignItems: 'center', marginBottom: 12 }}
                    className='flex'
                  >
                    <Icon color='primary' className='text-32 mr-12'>
                      phone
                    </Icon>
                    <span>Call Log</span>
                  </Typography>
                </div>
              )}
              {
                recording && (
                  <Dialog
                    TransitionComponent={!matches ? SlideUp : Grow}
                    classes={{
                      root: classes.dialogRoot,
                      paper: matches ? classes.paper : null,
                    }}
                    open={recording}
                    onClose={() => this.setState({ recording: null })}
                    maxWidth='sm'
                    fullWidth
                    fullScreen={!matches}
                  >
                    <Card
                      key={recording.CallSid}
                      className='w-full relative'
                      style={{ boxShadow: 'none' }}
                    >
                      <CardHeader
                        className='p-12 highlight'
                        classes={{ subheader: 'text-11', title: 'font-bold' }}
                        avatar={
                          <Avatar
                            onClick={e => {
                              e.stopPropagation()
                            }}
                            className={
                              ['completed'].indexOf(recording.Status) > -1
                                ? classes.doneAvatar
                                : classes.callAvatar
                            }
                          >
                            <Icon
                              color={
                                recording.Data.Recordings.length > 0
                                  ? 'secondary'
                                  : recording.Status === 'completed' &&
                                      !recording.HandledBy
                                    ? 'error'
                                    : undefined
                              }
                            >
                              {recording.Data.Recordings.length > 0
                                ? 'voicemail'
                                : recording.Status === 'answered'
                                  ? 'phone_in_talk'
                                  : recording.Action === 'Forward'
                                    ? 'phone_forwarded'
                                    : recording.Action === 'Enqueue'
                                      ? recording.HandledBy
                                        ? 'phone_callback'
                                        : 'phone_missed'
                                      : 'call_end'}
                            </Icon>
                          </Avatar>
                        }
                        title={`${this.formatPhone(
                          recording.From.replace('+1', ''),
                        )}`}
                        subheader={`${moment(recording.ReceivedDate).format(
                          'h:mm A',
                        )} on ${moment(recording.CreatedDate).format(
                          'MM/DD/YYYY',
                        )}`}
                        action={
                          <IconButton
                            style={{ marginTop: 8 }}
                            onClick={e => {
                              this.setState({ recording: null })
                            }}
                            aria-owns={undefined}
                          >
                            <Icon color='action'>close</Icon>
                          </IconButton>
                        }
                      />
                      {call.Data && call.Data.ContactData && (
                        <AppBar
                          color='primary'
                          className={classNames(classes.actions)}
                        >
                          <Tabs
                            value={tab}
                            className={classNames(classes.tabs)}
                            onChange={this.handleTabChange}
                          >
                            <Tooltip title='Recordings' placement='top'>
                              <Tab
                                disableRipple
                                className={classes.tab}
                                icon={
                                  <Badge
                                    color='error'
                                    badgeContent={call.Data.Recordings.length}
                                  >
                                    <Icon>voicemail</Icon>
                                  </Badge>
                                }
                              />
                            </Tooltip>
                            <Tooltip title='Customers' placement='top'>
                              <Tab
                                disableRipple
                                className={classes.tab}
                                icon={
                                  <Badge
                                    color='error'
                                    badgeContent={
                                      call.Data.ContactData.Customers.length
                                    }
                                  >
                                    <Icon>account_circle</Icon>
                                  </Badge>
                                }
                              />
                            </Tooltip>
                            <Tooltip title='Service Sites' placement='top'>
                              <Tab
                                disableRipple
                                className={classes.tab}
                                icon={
                                  <Badge
                                    color='error'
                                    badgeContent={
                                      call.Data.ContactData.Sites.length
                                    }
                                  >
                                    <Icon>location_on</Icon>
                                  </Badge>
                                }
                              />
                            </Tooltip>
                            <Tooltip
                              title='Upcoming Work Orders'
                              placement='top'
                            >
                              <Tab
                                disableRipple
                                className={classes.tab}
                                icon={
                                  <Badge
                                    color='error'
                                    badgeContent={
                                      call.Data.ContactData.WorkOrders.length
                                    }
                                  >
                                    <Icon>assignment</Icon>
                                  </Badge>
                                }
                              />
                            </Tooltip>
                          </Tabs>
                        </AppBar>
                      )}
                      {tab === 0 && (
                        <CardContent
                          className={classNames(
                            'w-full p-8',
                            call.Data.CallData && 'pt-16',
                          )}
                        >
                          {call.Data.Recordings.map(callRecording => {
                            // const callRecording = (recording && recording.Data && recording.Data.Recordings && recording.Data.Recordings.length > 0) ? recording.Data.Recordings[0] : null;
                            return (
                              <audio
                                className='mb-12'
                                controls
                                style={{ width: '100%' }}
                              >
                                <source
                                  src={`https://${callRecording.RecordingURL}`}
                                  type='audio/x-wav'
                                />
                              </audio>
                            )
                          })}
                          {recording.HandledBy && (
                            <div className='px-12 w-full'>
                              <TextField
                                className={classNames('mb-12')}
                                label='Handled By'
                                id='handledBy'
                                name='recording.HandledBy'
                                value={this.state.recording.HandledBy}
                                variant='outlined'
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                select
                                disabled={true}
                              >
                                {users &&
                                  users.map((value, index) => (
                                    <MenuItem
                                      key={index}
                                      value={value.UserName}
                                    >
                                      <div className='flex'>
                                        <Avatar
                                          style={{ marginRight: 8 }}
                                          classes={{ root: classes.avatarRoot }}
                                          className={classes.userAvatar}
                                          alt={value.Technician}
                                          src={
                                            value.Data && value.Data.Avatar
                                              ? getProfileImage(
                                                  `Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                                )
                                              : userAvatar
                                          }
                                        />
                                        {`${value.FirstName} ${value.LastName}`}
                                      </div>
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </div>
                          )}
                        </CardContent>
                      )}
                      {call &&
                        call.Data &&
                        call.Data.ContactData &&
                        (call.Data.ContactData.Customers.length > 0 ||
                          call.Data.ContactData.Sites.length > 0 ||
                          call.Data.ContactData.WorkOrders.length > 0) && (
                          <div>
                            {tab == 1 && (
                              <TabContainer>
                                <CardContent className={classes.info}>
                                  {call.Data.ContactData.Customers.map(
                                    (value, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          borderTop:
                                            index > 0
                                              ? '1px dashed lightgrey'
                                              : 'none',
                                        }}
                                        onClick={() => openCustomer(value)}
                                        className='w-full p-8 highlight'
                                      >
                                        <Typography
                                          fullWidth
                                          style={{
                                            alignItems: 'center',
                                            marginBottom: 0,
                                          }}
                                          className='flex'
                                        >
                                          <Icon
                                            color='primary'
                                            className='text-24 mr-4'
                                          >
                                            account_circle
                                          </Icon>
                                          <strong>
                                            Customer #{value.Customer}
                                          </strong>
                                        </Typography>
                                        <span className='text-xs'>
                                          {value.Name && value.Name.length > 0
                                            ? value.Name
                                            : `${value.FirstName} ${value.LastName}`}
                                          <br />
                                          {`${value.Address1}`}
                                          <br />
                                          {`${value.City}, ${value.State} ${value.Zip}`}
                                        </span>
                                      </div>
                                    ),
                                  )}
                                </CardContent>
                              </TabContainer>
                            )}
                            {tab == 2 && (
                              <TabContainer>
                                <CardContent className={classes.info}>
                                  {call.Data.ContactData.Sites.map(
                                    (value, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          borderTop:
                                            index > 0
                                              ? '1px dashed lightgrey'
                                              : 'none',
                                        }}
                                        onClick={() => openSite(value)}
                                        className='w-full p-8 highlight'
                                      >
                                        <Typography
                                          fullWidth
                                          style={{
                                            alignItems: 'center',
                                            marginBottom: 0,
                                          }}
                                          className='flex'
                                        >
                                          <Icon
                                            color='primary'
                                            className='text-24 mr-4'
                                          >
                                            location_on
                                          </Icon>
                                          <strong>Site #{value.Site}</strong>
                                        </Typography>
                                        <span className='text-xs'>
                                          {`${value.Description}`}
                                          <br />
                                          {`${value.Address1}`}
                                          <br />
                                          {`${value.City}, ${value.State} ${value.Zip}`}
                                        </span>
                                      </div>
                                    ),
                                  )}
                                </CardContent>
                              </TabContainer>
                            )}
                            {tab == 3 && (
                              <TabContainer>
                                <CardContent className={classes.info}>
                                  {call.Data.ContactData.WorkOrders.map(
                                    (value, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          borderTop:
                                            index > 0
                                              ? '1px dashed lightgrey'
                                              : 'none',
                                        }}
                                        onClick={() => openWorkOrder(value)}
                                        className='w-full p-8 highlight'
                                      >
                                        <Typography
                                          fullWidth
                                          style={{
                                            alignItems: 'center',
                                            marginBottom: 0,
                                          }}
                                          className='flex'
                                        >
                                          <Icon
                                            color='primary'
                                            className='text-24 mr-4'
                                          >
                                            assignment
                                          </Icon>
                                          <strong>
                                            Work Order #{value.WorkOrder}
                                          </strong>
                                        </Typography>
                                        <span className='text-xs'>
                                          {`${value.Description}`}
                                        </span>
                                      </div>
                                    ),
                                  )}
                                </CardContent>
                              </TabContainer>
                            )}
                          </div>
                        )}
                    </Card>
                  </Dialog>
                )
                // <Dialog
                //     classes={{
                //         root: "w-full",
                //         paper: "w-full",
                //     }}
                //     open={recording && recording.RecordingURL}
                //     onClose={() => this.setState({ recording: null })}
                //     maxWidth="sm"
                // >
                //     <audio controls style={{ width: '100%' }}>
                //         <source src={`https://${recording.RecordingURL}`} type="audio/x-wav" />
                //     </audio>
                // </Dialog>
              }
              <ReactTableFixedColumns
                className={classNames(
                  classes.root,
                  '-striped -highlight border-0 rounded',
                )}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    className: 'cursor-pointer',
                    onClick: (e, handleOriginal) => {
                      if (rowInfo) {
                        this.getCall(rowInfo.original)
                      }
                    },
                  }
                }}
                getTfootTrProps={() => {
                  return {
                    className: 'font-bold text-center',
                  }
                }}
                NoDataComponent={
                  loading2
                    ? LoadingDiv
                    : !audit && loading
                      ? LoadingDiv
                      : NoData
                }
                data={data}
                columns={[
                  {
                    Header: <Icon color='action'>voicemail</Icon>,
                    accessor: 'Recording',
                    width: 64,
                    className: 'justify-center',
                    Cell: row => {
                      if (row.original.Recording) {
                        return <Icon color='primary'>voicemail</Icon>
                      } else {
                        if (row.original.Status === 'queued') {
                          return <Icon color='action'>phone_callback</Icon>
                        } else {
                          if (row.original.HandledBy) {
                            if (row.original.Status === 'answered') {
                              return (
                                <Icon color='secondary'>phone_in_talk</Icon>
                              )
                            } else {
                              if (row.original.Action === 'Forward') {
                                return (
                                  <Icon color='action'>phone_forwarded</Icon>
                                )
                              } else {
                                return <Icon color='action'>call_end</Icon>
                              }
                            }
                          } else {
                            if (row.original.Action === 'Forward') {
                              return <Icon color='action'>phone_forwarded</Icon>
                            } else {
                              if (!row.original.HandledBy) {
                                return <Icon color='error'>phone_missed</Icon>
                              }
                            }
                          }
                        }
                      }
                    },
                  },
                  {
                    Header: 'From',
                    accessor: 'From',
                    Cell: row => {
                      return this.formatPhone(
                        row.original.From.replace('+1', ''),
                      )
                    },
                    width: 128,
                    className: 'font-bold justify-center',
                    Footer: audit ? 'Total' : null,
                  },
                  {
                    Header: 'Handled By',
                    accessor: 'HandledBy',
                    minWidth: 208,
                    className: 'justify-center',
                    Cell: row => {
                      return (
                        row.original.HandledBy &&
                        users &&
                        users.map(
                          (value, index) =>
                            value.UserName === row.original.HandledBy && (
                              <React.Fragment>
                                <Avatar
                                  style={{ marginRight: 8, marginLeft: 8 }}
                                  classes={{ root: classes.avatarRoot }}
                                  className={classes.techAvatar}
                                  alt={value.Technician}
                                  src={
                                    value.Data && value.Data.Avatar
                                      ? getProfileImage(
                                          `Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                        )
                                      : avatar
                                  }
                                />
                                {`${value.FirstName} ${value.LastName}`}
                              </React.Fragment>
                            ),
                        )
                      )
                    },
                    Footer: () => {
                      if (audit) {
                        const handled = _.filter(data, o => {
                          return o.HandledBy ? true : false
                        })
                        return handled.length
                      } else {
                        return null
                      }
                    },
                  },
                  {
                    Header: 'Queue',
                    accessor: 'Queue',
                    width: 96,
                    className: 'justify-center',
                  },
                  {
                    Header: 'Line Name',
                    accessor: 'Name',
                    width: 208,
                    className: 'font-bold justify-center',
                  },
                  {
                    Header: 'Line #',
                    accessor: 'To',
                    width: 128,
                    Cell: row => {
                      return this.formatPhone(row.original.To.replace('+1', ''))
                    },
                    className: 'font-bold justify-center',
                    Footer: () => {
                      if (audit) {
                        return data.length
                      } else {
                        return null
                      }
                    },
                  },
                  {
                    Header: 'Start Time',
                    accessor: 'CreatedDate',
                    width: 192,
                    className: 'justify-center',
                    Cell: row => {
                      return `${moment(moment.utc(row.original.CreatedDate))
                        .local()
                        .toDate()
                        .toLocaleTimeString('en-US')} on ${moment(
                        moment.utc(row.original.CreatedDate),
                      )
                        .local()
                        .toDate()
                        .toLocaleDateString('en-US')}`
                    },
                  },
                  {
                    Header: 'Status',
                    accessor: 'Status',
                    width: 96,
                    className: 'justify-center',
                  },
                  {
                    Header: 'Wait Duration',
                    id: 'waitDuration',
                    width: 160,
                    className: 'justify-center',
                    accessor: row => {
                      if (row.HandledDate) {
                        var dur = row.TotalDuration - row.HandledDuration
                        return dur
                      } else {
                        return 0
                      }
                    },
                    Cell: row => {
                      if (
                        row.original.TotalDuration &&
                        row.original.Action === 'Enqueue'
                      ) {
                        var dur =
                          row.original.TotalDuration -
                          (row.original.HandledDuration || 0)
                        var mins = Math.floor(dur / 60)
                        var secs = dur - mins * 60
                        return `${mins} Minute${
                          mins !== 1 ? 's' : ''
                        } ${secs} Second${secs !== 1 ? 's' : ''}`
                      }
                    },
                    Footer: () => {
                      if (audit && data.length > 0) {
                        var handled = _.filter(data, o => {
                          return o.TotalDuration && o.Action === 'Enqueue'
                        })
                        if (handled.length > 0) {
                          var dur =
                            _.sumBy(handled, o => {
                              return o.TotalDuration - (o.HandledDuration || 0)
                            }) / handled.length
                          var mins = Math.floor(dur / 60)
                          var secs = Math.floor(dur - mins * 60)
                          return `${mins} Minute${
                            mins !== 1 ? 's' : ''
                          } ${secs} Second${secs !== 1 ? 's' : ''}`
                        } else {
                          return null
                        }
                      } else {
                        return null
                      }
                    },
                  },
                  {
                    Header: 'Handled Duration',
                    accessor: 'HandledDuration',
                    width: 160,
                    className: 'justify-center',
                    Cell: row => {
                      if (row.original.HandledDuration) {
                        var dur = row.original.HandledDuration
                        var mins = Math.floor(dur / 60)
                        var secs = dur - mins * 60
                        return `${mins} Minute${
                          mins !== 1 ? 's' : ''
                        } ${secs} Second${secs !== 1 ? 's' : ''}`
                      }
                    },
                    Footer: () => {
                      if (audit && data.length > 0) {
                        var handled = _.filter(data, o => {
                          return o.HandledDuration ? true : false
                        })
                        if (handled.length > 0) {
                          var dur =
                            _.sumBy(handled, 'HandledDuration') / data.length
                          var mins = Math.floor(dur / 60)
                          var secs = Math.floor(dur - mins * 60)
                          return `${mins} Minute${
                            mins !== 1 ? 's' : ''
                          } ${secs} Second${secs !== 1 ? 's' : ''}`
                        } else {
                          return null
                        }
                      } else {
                        return null
                      }
                    },
                  },
                  {
                    Header: 'Total Duration',
                    accessor: 'TotalDuration',
                    width: 160,
                    className: 'justify-center',
                    Cell: row => {
                      if (row.original.TotalDuration) {
                        var dur = row.original.TotalDuration
                        var mins = Math.floor(dur / 60)
                        var secs = dur - mins * 60
                        return `${mins} Minute${
                          mins !== 1 ? 's' : ''
                        } ${secs} Second${secs !== 1 ? 's' : ''}`
                      }
                    },
                    Footer: () => {
                      if (audit && data.length > 0) {
                        var dur = _.sumBy(data, 'TotalDuration') / data.length
                        var mins = Math.floor(dur / 60)
                        var secs = Math.floor(dur - mins * 60)
                        return `${mins} Minute${
                          mins !== 1 ? 's' : ''
                        } ${secs} Second${secs !== 1 ? 's' : ''}`
                      } else {
                        return null
                      }
                    },
                  },
                  {
                    Header: 'Handled Time',
                    accessor: 'HandledDate',
                    width: 192,
                    className: 'justify-center',
                    Cell: row => {
                      if (row.original.HandledDate) {
                        return `${moment(moment.utc(row.original.HandledDate))
                          .local()
                          .toDate()
                          .toLocaleTimeString('en-US')} on ${moment(
                          moment.utc(row.original.HandledDate),
                        )
                          .local()
                          .toDate()
                          .toLocaleDateString('en-US')}`
                      }
                    },
                  },
                  {
                    Header: 'Completed Time',
                    accessor: 'CompletedDate',
                    width: 192,
                    className: 'justify-center',
                    Cell: row => {
                      if (row.original.CompletedDate) {
                        return `${moment(moment.utc(row.original.CompletedDate))
                          .local()
                          .toDate()
                          .toLocaleTimeString('en-US')} on ${moment(
                          moment.utc(row.original.CompletedDate),
                        )
                          .local()
                          .toDate()
                          .toLocaleDateString('en-US')}`
                      }
                    },
                  },
                  {
                    Header: 'Work Order',
                    accessor: 'WorkOrder',
                    width: 128,
                    className: 'justify-center',
                    Cell: row => {
                      if (row.original.WorkOrder) {
                        return (
                          <div
                            onClick={e => {
                              e.stopPropagation()
                              openEditWorkOrderDialog({ ...row.original })
                            }}
                          >
                            {row.original.WorkOrder}
                            <Icon
                              className='text-14 font-bold ml-4'
                              style={{ paddingTop: 2 }}
                            >
                              open_in_new
                            </Icon>
                          </div>
                        )
                      }
                    },
                  },
                  {
                    Header: 'Action',
                    accessor: 'Action',
                    width: 128,
                    className: 'justify-center',
                  },
                  {
                    Header: 'Forward',
                    accessor: 'Forward',
                    width: 128,
                    className: 'justify-center',
                  },
                  {
                    Header: 'Call ID',
                    accessor: 'CallSid',
                    width: 320,
                    className: 'justify-center',
                  },
                ]}
                defaultSorted={
                  audit && prefs ? prefs.Preferences.SortOrder || [] : []
                }
                defaultPageSize={
                  audit && prefs ? prefs.Preferences.RowCount || 100 : 100
                }
                onPageSizeChange={this.handleRowCount}
                onSortedChange={this.handleSortOrder}
                noDataText='No Calls found'
              />
            </div>
          </FuseAnimate>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
      updateTableSettings,
      openCustomer: openEditCustomerDialog,
      openSite: openEditCustomerSiteDialog,
      openWorkOrder: openEditWorkOrderDialog,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers }) {
  return {
    user: spReducers.userProfiles.User,
    users: spReducers.userProfiles.Users,
    Co: spReducers.companies.Co,
    callQueues: spReducers.callQueues,
    phoneNumbers: spReducers.phoneNumbers,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CallsList)),
)
