import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from '../catalog/store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CatalogsList extends Component {

    state = {
        catalogs: [],
        savedCatalogs: [],
        fetching: [],
    };

    componentDidMount() {
        const { catalogs, savedCatalogs } = this.props;
        this.setState({ catalogs, savedCatalogs, })
    }

    componentDidUpdate(prevProps, prevState) {
        const { catalogs, savedCatalogs } = this.props;
        if (!_.isEqual(catalogs, prevProps.catalogs) || !_.isEqual(savedCatalogs, prevProps.savedCatalogs)) {

            this.setState({ catalogs, savedCatalogs, })
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCatalogMenu = (event) => {
        this.setState({ selectedCatalogsMenu: event.currentTarget });
    };

    closeSelectedCatalogsMenu = () => {
        this.setState({ selectedCatalogsMenu: null });
    };

    downloadCatalog = (catalog) => {
        const { fetching } = this.state;
        const loading = _.find(fetching, { Co: catalog.Co, BusinessUnit: catalog.BusinessUnit, Division: catalog.Division });
        if (!loading) {
            fetching.push(catalog);
            this.setState({ ...this.state, fetching }, () => {
                Promise.all([
                    this.props.downloadCatalog(catalog, true)
                ]).then(() => {
                    const { fetching } = this.state;
                    const index = _.findIndex(fetching, { Co: catalog.Co, BusinessUnit: catalog.BusinessUnit, Division: catalog.Division });
                    if (index > -1) {
                        fetching.splice(index, 1);
                    }
                    this.setState({ ...this.state, fetching });
                })
            });
        }
    }

    openCatalog = (catalog) => {
        const { fetching } = this.state;
        const loading = _.find(fetching, { Co: catalog.Co, BusinessUnit: catalog.BusinessUnit, Division: catalog.Division });
        if (!loading) {
            fetching.push(catalog);
            this.setState({ ...this.state, fetching }, () => {
                Promise.all([
                    this.props.openEditCatalogDialog(catalog)
                ]).then(() => {
                    const { fetching } = this.state;
                    const index = _.findIndex(fetching, { Co: catalog.Co, BusinessUnit: catalog.BusinessUnit, Division: catalog.Division });
                    if (index > -1) {
                        fetching.splice(index, 1);
                    }
                    this.setState({ ...this.state, fetching });
                })
            });
        }
    }

    render() {
        const { classes, securables, searchText, openNewCatalogDialog, openEditCatalogDialog, downloadCatalog } = this.props;
        const { catalogs, savedCatalogs, fetching } = this.state;
        [...catalogs].map((catalog) => {
            const { Co, BusinessUnit, Division } = catalog;
            catalog.saved = _.findIndex([...savedCatalogs], { Co, BusinessUnit, Division }) > -1;
        })
        const data = this.getFilteredArray([...catalogs], searchText);
        const accessLevel = _.find(securables, { Securable: "catalogs" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {/* {(accessLevel && accessLevel.AccessLevel !== "R") && */}
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewCatalogDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    {/* } */}
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">chrome_reader_mode</Icon>Price Books</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo) {
                                        this.openCatalog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "",
                                id: "catalog",
                                accessor: row => {
                                    const loading = _.find(fetching, { Co: row.Co, BusinessUnit: row.BusinessUnit, Division: row.Division });
                                    return (
                                        <Icon onClick={(e) => { e.stopPropagation(); this.downloadCatalog(row); }} className={loading ? "spin" : ""} color={row.saved && !loading ? "secondary" : "action"}>{loading ? "refresh" : row.saved ? "cloud_done" : "cloud_download"}</Icon>
                                    );
                                },
                                className: "font-bold justify-center",
                                width: 48,
                            },
                            {
                                Header: "Business Unit",
                                accessor: "BusinessUnit",
                                className: "font-bold justify-center",
                                width: 128,
                            },
                            {
                                Header: "Division",
                                accessor: "Division",
                                className: "font-bold justify-center",
                                width: 128,
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "font-bold justify-center",
                                width: 192,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 384,
                            },

                        ]}
                        defaultPageSize={10}
                        noDataText="No Catalogs found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditCatalogDialog: Actions.openEditCatalogDialog,
        openNewCatalogDialog: Actions.openNewCatalogDialog,
        downloadCatalog: Actions.downloadCatalog
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        searchText: catalogApp.searchText,
        catalogs: spReducers.catalog.catalogs,
        savedCatalogs: spReducers.catalog.savedCatalogs,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogsList)));
