import axios from 'axios/index';

export const SET_TEXT_GATHER_DATA = '[SPCONNECTION] SET TEXT GATHER DATA';
export const GET_TEXT_GATHER_DATA = '[SPCONNECTION] GET TEXT GATHER DATA';
export const SUBSCRIBE_TEXT_GATHER_LIST = '[SPCONNECTION] SUBSCRIBE TEXT GATHER LIST';

export function getInitialTextGatherData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/TextGather`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getTextGatherData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/TextGather`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_TEXT_GATHER_DATA,
                payload: response.data
            })
        });
    }
}

export function setTextGatherData(data) {
    return {
        type: SET_TEXT_GATHER_DATA,
        payload: data
    };
}