import _ from '@lodash'
import { FuseAnimate, FuseUtils } from '@fuse'
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { impress } from 'main/content/compression/impress'
import { SPRoundIcon } from 'main/icons'
import React, { Component } from 'react'
import Media from 'react-media'
import 'react-quill/dist/quill.snow.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import EstimateExclusions from '../estimates/EstimateExclusions'
import CatalogAssemblyList from './CatalogAssemblyList'
import CatalogOptionBoardList from './CatalogOptionBoardList'
import ImageDialog from './ImageDialog'
import * as Actions from './store/actions'
import RateUpdatesList from './RateUpdatesList'
import axios from 'axios'
import moment from 'moment'
import ReactTable from 'react-table'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 108px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  paper: {
    margin: 12,
    height: '100%',
    minHeight: 'calc(100% - 64px)',
    overflowX: 'hidden',
  },
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  header: {},
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  profile: {
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: 'rgba(0,0,0,.75)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: 5,
    paddingTop: 64,
    marginBottom: 16,
  },
  updateMember: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteMember: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  error: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[400],
      color: '#fff',
    },
    '&:active': {
      backgroundColor: theme.palette.error[600],
      color: '#fff',
    },
  },
  drawer: {
    width: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 300,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  drawerBadge: {
    height: 16,
    minWidth: 16,
    fontSize: '1rem',
    top: 4,
    right: -2,
  },
})
const newCatalogState = {
  ID: null,
  Co: null,
  BusinessUnit: null,
  Division: null,
  Name: null,
  Description: null,
  DefaultEstimateType: 'Q',
  DefaultEstimatePrefix: 'Q',
  DefaultComponentDetail: 'S',
  DefaultPaymentDue: 'C',
  DefaultExpirationDays: 30,
  DefaultContractVerbiage: null,
  DefaultDisclaimer: null,
  EstimateHeaderImg: null,
  EstimateFooterImg: null,
  DefaultRateTemplate: null,
  DefaultRateTemplateDivision: null,
  IncludeTaxYN: null,
  MemberLaborDiscount: 0,
  MemberMaterialDiscount: 0,
  PremiumLaborMarkup: 0,
  PremiumMaterialMarkup: 0,
  EnableTMEstimatesYN: null,
  AutoAddOnYN: null,
  PRSyncYN: null,
  value: 0,
  exclusion: null,
  Data: {
    ErrMsg: null,
    Categories: [],
    Assemblies: [],
    Assignments: [],
    Exclusions: [],
    SyncHistory: [],
  },
  PRSync: {
    u: null,
    p: null,
    RateTemplate: null,
    LaborType: null,
    AssistantLaborType: null,
  },
  showTabs: true,
  saving: false,
  imgDialog: false,
  isUpdating: false,
}

class CatalogDialog extends Component {
  state = { ..._.cloneDeepWith(newCatalogState) }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleEditorChange = slide => {
    this.setState({ ...this.state, slide })
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  componentDidMount() {
    newCatalogState.Co = this.props.Co
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    let initialState = _.cloneDeepWith(newCatalogState)
    initialState.Co = this.props.Co
    if (
      !prevProps.catalogDialog.props.open &&
      this.props.catalogDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.catalogDialog.type === 'edit' &&
        this.props.catalogDialog.data &&
        !_.isEqual(this.props.catalogDialog.data, prevState)
      ) {
        const data = this.props.catalogDialog.data
        this.setState({
          ...initialState,
          ...this.props.catalogDialog.data,
          value: 0,
        })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.catalogDialog.type === 'new' &&
        !_.isEqual(initialState, prevState)
      ) {
        this.setState({ ...initialState, value: 0 })
      }
    }
    // window["log"](
    //   "STATE COMPARISON",
    //   this.props.catalogDialog,
    //   prevProps,
    //   prevState
    // );
    if (
      prevProps.catalogDialog.props.open &&
      this.props.catalogDialog.props.open
    ) {
      if (
        this.props.catalogDialog.type === 'data' &&
        this.props.catalogDialog.data &&
        !_.isEqual(this.props.catalogDialog.data, prevState)
      ) {
        const data = this.props.catalogDialog.data
        this.props.catalogDialog.type = 'edit'
        this.setState({ ...initialState, ...this.props.catalogDialog.data })
      }
    }
    if (
      prevProps.catalogDialog.props.open &&
      this.props.catalogDialog.props.open
    ) {
      if (
        this.props.catalogDialog.type === 'add' &&
        this.props.catalogDialog.data &&
        !_.isEqual(this.props.catalogDialog.data, prevState)
      ) {
        const data = this.props.catalogDialog.data
        this.props.catalogDialog.type = 'edit'
        this.setState({ ...initialState, ...this.props.catalogDialog.data })
      }
    }
    if (
      prevProps.catalogDialog.props.open &&
      this.props.catalogDialog.props.open
    ) {
      if (
        this.props.catalogDialog.type === 'update' &&
        this.props.catalogDialog.data &&
        !_.isEqual(this.props.catalogDialog.data, prevState)
      ) {
        const data = this.props.catalogDialog.data
        this.props.catalogDialog.type = 'edit'
        this.setState({
          ...initialState,
          ...this.props.catalogDialog.data,
          value: this.state.value,
        })
      }
    }
  }

  closeComposeDialog = () => {
    this.props.catalogDialog.type === 'edit'
      ? this.props.closeEditCatalogDialog()
      : this.props.closeNewCatalogDialog()
  }

  canBeSubmitted() {
    const {
      Co,
      Name,
      Description,
      BusinessUnit,
      Division,
      DefaultEstimateType,
      DefaultEstimatePrefix,
      DefaultComponentDetail,
      DefaultPaymentDue,
    } = this.state
    return (
      Co &&
      Name &&
      Description &&
      BusinessUnit &&
      Division &&
      DefaultEstimateType &&
      DefaultEstimatePrefix &&
      DefaultComponentDetail &&
      DefaultPaymentDue &&
      Co > 0 &&
      Name.length > 0 &&
      Description.length > 0
    )
  }

  toggleTabs = () => {
    const { showTabs } = this.state
    this.setState({ ...this.state, showTabs: !showTabs })
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  openHeaderImgDialog = () => {
    this.setState({
      ...this.state,
      imgDialog: true,
      onImgChange: img =>
        this.setState({ ...this.state, EstimateHeaderImg: img }),
    })
  }

  openFooterImgDialog = () => {
    this.setState({
      ...this.state,
      imgDialog: true,
      onImgChange: img =>
        this.setState({ ...this.state, EstimateFooterImg: img }),
    })
  }

  syncPriceBook = () => {
    const { Co, BusinessUnit, Division, PRSync, Name } = this.state
    const { u, p, RateTemplate, LaborType, AssistantLaborType } = PRSync
    const body = {
      u,
      p,
      Co,
      BusinessUnit,
      Division,
      RateTemplate,
      LaborType,
      AssistantLaborType,
      FetchUpdates: true,
      ApplyUpdates: true,
    }
    // window["warn"](body);
    this.setState({ isUpdating: true }, () => {
      this.props.showMessage({
        message: `Update initiated - check back later for update status.`,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'success',
      })
    })
    const req = axios.put(
      `${
        window['apiLocation']
      }/api/PriceBookSync?Co=${Co}&BusinessUnit=${encodeURIComponent(
        BusinessUnit,
      )}&Division=${encodeURIComponent(Division)}`,
      body,
    )
    req.then(response => {
      if (response.data.Status) {
        this.setState(
          {
            isUpdating: false,
          },
          () =>
            this.props.downloadCatalog(
              { Co, BusinessUnit, Division, Name },
              true,
            ),
        )
      } else {
        this.setState(
          {
            isUpdating: false,
          },
          () =>
            this.props.showMessage({
              message: `Unable to initiate update - ${response.data.Error}`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
        )
      }
    })
  }

  isUpdating = () => {
    const { SyncHistory } = this.state.Data
    return Boolean(
      _.find(SyncHistory || [], o => {
        const stat = o.Status ? JSON.parse(o.Status) : null
        // window["warn"](stat);
        return Boolean(!stat || (stat && !stat.Error && !stat.Status))
      }),
    )
  }

  render() {
    const {
      classes,
      catalogDialog,
      businessUnits,
      divisions,
      addCatalog,
      updateCatalog,
      rateTemplates,
      laborTypes,
      users,
      securables,
    } = this.props
    const {
      Co,
      BusinessUnit,
      Division,
      value,
      showTabs,
      saving,
      imgDialog,
      onImgChange,
      fullScreen,
    } = this.state
    let isUpdating = this.state.isUpdating || this.isUpdating()
    const syncAccess = _.find(securables, { Securable: 'sync-price-book' })

    // window["warn"]('Price Book State: ', this.state);
    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <Dialog
            TransitionComponent={!matches ? SlideUp : Grow}
            classes={{
              root: classes.root,
              paper:
                matches && !fullScreen ? classes.paper : 'full-screen-dialog',
            }}
            className={classes.root}
            {...catalogDialog.props}
            onClose={this.closeComposeDialog}
            fullWidth
            maxWidth='md'
            fullScreen={!matches || fullScreen}
          >
            <AppBar position='static' elevation={1} className='dialog-header'>
              <Toolbar className='flex w-full'>
                <Typography
                  variant='subtitle1'
                  color='inherit'
                  className='flex truncate'
                  style={{ maxWidth: 'calc(100% - 48px)' }}
                >
                  <SPRoundIcon className='text-32 align-middle mr-8' />
                  {['new', 'customer'].indexOf(catalogDialog.type) > -1 ? (
                    'New Price Book'
                  ) : (
                    <div style={{ marginTop: 2 }}>{this.state.Name}</div>
                  )}
                </Typography>
              </Toolbar>
              {matches && (
                <IconButton
                  style={{
                    position: 'absolute',
                    right: 56,
                    top: matches ? 6 : 4,
                    color: 'white',
                  }}
                  onClick={e => {
                    this.setState({ fullScreen: !fullScreen })
                  }}
                  className='dialog-header-icon'
                >
                  <Icon>{fullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
                </IconButton>
              )}
              <IconButton
                style={{
                  position: 'absolute',
                  right: 8,
                  top: matches ? 6 : 4,
                  color: 'white',
                }}
                onClick={() => {
                  this.closeComposeDialog()
                }}
                className='dialog-header-icon'
              >
                <Icon>close</Icon>
              </IconButton>
              <Tabs
                value={value}
                onChange={this.handleTabChange}
                variant='scrollable'
                scrollButtons='on'
              >
                <Tab
                  disableRipple
                  icon={<Icon>assignment</Icon>}
                  label='Info'
                />
                <Tab
                  disableRipple
                  icon={<Icon>ballot</Icon>}
                  label='Rate Settings'
                />
                <Tab
                  disableRipple
                  icon={<Icon>tune</Icon>}
                  label='Form Settings'
                />
                <Tab
                  disableRipple
                  icon={<Icon>chrome_reader_mode</Icon>}
                  label='Price Book'
                />
                <Tab
                  disableRipple
                  icon={<Icon>table_chart</Icon>}
                  disabled={this.state.EnableSavedOptionBoardsYN !== 'Y'}
                  label='Option Boards'
                />
                <Tab
                  disableRipple
                  icon={<Icon>swap_vertical_circle</Icon>}
                  label='Update Pricing'
                />
                {Boolean(this.state.ID) &&
                  this.state.PRSyncYN === 'Y' &&
                  syncAccess &&
                  syncAccess.AccessLevel === 'F' && (
                    <Tab
                      disableRipple
                      icon={<Icon>cloud_download</Icon>}
                      label='Update from Cloud'
                    />
                  )}
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer padding='24px 24px 64px 24px'>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>business</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Business Unit'
                    select
                    id='businessUnit'
                    name='BusinessUnit'
                    value={this.state.BusinessUnit || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                    disabled={catalogDialog.type === 'new' ? false : true}
                  >
                    {businessUnits && businessUnits.length > 0
                      ? businessUnits.map(value => (
                          <MenuItem
                            key={value.BusinessUnit}
                            value={value.BusinessUnit}
                          >
                            {value.Description}
                          </MenuItem>
                        ))
                      : ''}
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Division'
                    id='division'
                    select
                    name='Division'
                    value={this.state.Division || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                    disabled={catalogDialog.type === 'new' ? false : true}
                  >
                    {/* {(this.state.BusinessUnit && this.state.BusinessUnit.length > 0) &&
                                            <MenuItem value="*">
                                                All Divisions
                                            </MenuItem>
                                        } */}
                    {this.state.BusinessUnit &&
                    this.state.BusinessUnit.length > 0 ? (
                      divisions && divisions.length > 0 ? (
                        divisions.map(value =>
                          this.state.BusinessUnit == value.BusinessUnit ? (
                            <MenuItem value={value.Division}>
                              {value.Description}
                            </MenuItem>
                          ) : (
                            ''
                          ),
                        )
                      ) : (
                        ''
                      )
                    ) : (
                      <MenuItem value=''>
                        Please Select a Business Unit
                      </MenuItem>
                    )}
                  </TextField>
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>chrome_reader_mode</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Name'
                    id='name'
                    name='Name'
                    value={this.state.Name || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>message</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Description'
                    id='description'
                    name='Description'
                    value={this.state.Description || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                {(!this.state.ID ||
                  (Boolean(this.state.ID) && this.state.PRSyncYN === 'Y')) && (
                  <div className='w-full justify-end'>
                    <FormControlLabel
                      className='float-right mr-0'
                      control={
                        <Switch
                          checked={this.state.PRSyncYN === 'Y'}
                          name='PRSyncYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Linked to PR'
                    />
                  </div>
                )}
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer padding='24px 24px 64px 24px'>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>ballot</Icon>
                  </div>
                  <Autocomplete
                    className={classes.formControl}
                    title='Default Rate Template'
                    options={rateTemplates}
                    menuItemComponent={value => {
                      if (
                        value.BusinessUnit === this.state.BusinessUnit &&
                        value.Division ===
                          this.state
                            .Division /* || this.state.Division === '*'*/
                      ) {
                        return (
                          <MenuItem value={value.RateTemplate}>
                            {`${value.Description}`}
                          </MenuItem>
                        )
                      }
                    }}
                    portal={true}
                    value={this.state.DefaultRateTemplate}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        DefaultRateTemplate: option.RateTemplate,
                        DefaultRateTemplateDivision: option.Division,
                      })
                    }
                  />
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>build</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    type='number'
                    label='Member Labor Discount'
                    id='memberLaborDiscount'
                    name='MemberLaborDiscount'
                    value={this.state.MemberLaborDiscount || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    type='number'
                    label='Premium Labor Markup'
                    id='premiumLaborMarkup'
                    name='PremiumLaborMarkup'
                    value={this.state.PremiumLaborMarkup || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>style</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    type='number'
                    label='Member Material Discount'
                    id='memberMaterialDiscount'
                    name='MemberMaterialDiscount'
                    value={this.state.MemberMaterialDiscount || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    type='number'
                    label='Premium Material Markup'
                    id='premiumMaterialMarkup'
                    name='PremiumMaterialMarkup'
                    value={this.state.PremiumMaterialMarkup || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                </div>
                <div className='w-full justify-end'>
                  <FormControlLabel
                    className='float-right mr-0'
                    control={
                      <Switch
                        checked={this.state.EnableTMEstimatesYN === 'Y'}
                        name='EnableTMEstimatesYN'
                        onChange={this.handleChange}
                        color='primary'
                      />
                    }
                    label='Enable T&M Estimates'
                  />
                </div>
                <div className='w-full justify-end'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.AutoAddOnYN === 'Y'}
                        name='AutoAddOnYN'
                        onChange={this.handleChange}
                        color='primary'
                      />
                    }
                    label=' Enable Auto Add on'
                  />
                </div>
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer padding='24px 24px 64px 24px'>
                <ImageDialog
                  category='assets/images/forms/'
                  onChange={onImgChange}
                  open={imgDialog}
                  onClose={() =>
                    this.setState({ ...this.state, imgDialog: false })
                  }
                />
                <div className='w-full'>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>image</Icon>
                    </div>
                    {!this.state.EstimateHeaderImg ? (
                      <div
                        onClick={this.openHeaderImgDialog}
                        className='w-full sm:w-full-48 h-128 mb-24 relative cursor-pointer rounded-6'
                        style={{ border: '2px dotted lightgrey' }}
                      >
                        <Icon
                          className='pin-l pin-r pin-t pin-b absolute m-auto'
                          color='primary'
                        >
                          cloud_upload
                        </Icon>
                      </div>
                    ) : (
                      <img
                        onClick={this.openHeaderImgDialog}
                        className='w-auto h-96 mb-24 cursor-pointer'
                        src={this.state.EstimateHeaderImg}
                      />
                    )}
                  </div>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>format_list_numbered</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Default Estimate Type'
                      select
                      id='defaultEstimateType'
                      name='DefaultEstimateType'
                      value={this.state.DefaultEstimateType}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      fullWidth
                    >
                      <MenuItem value='E'>Estimate</MenuItem>
                      <MenuItem value='Q'>Quote</MenuItem>
                      <MenuItem value='P'>Proposal</MenuItem>
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classNames(
                        classes.formControl,
                        'w-full sm:w-288 mb-12',
                      )}
                      label='Default Prefix'
                      id='defaultEstimatePrefix'
                      name='DefaultEstimatePrefix'
                      value={this.state.DefaultEstimatePrefix}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      helperText={`* Ex: ${this.state.DefaultEstimatePrefix}1234-1 *`}
                      FormHelperTextProps={{
                        classes: { root: 'text-10 text-center mt-4' },
                      }}
                    />
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Default Task/Assembly Detail'
                      select
                      id='defaultComponentDetail'
                      name='DefaultComponentDetail'
                      value={this.state.DefaultComponentDetail}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      fullWidth
                    >
                      <MenuItem value='B'>Basic</MenuItem>
                      <MenuItem value='S'>Showcase</MenuItem>
                      <MenuItem value='F'>Full Details</MenuItem>
                      <MenuItem value='C'>Custom</MenuItem>
                    </TextField>
                  </div>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>today</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Default Payment Due'
                      select
                      id='defaultPaymentDue'
                      name='DefaultPaymentDue'
                      value={this.state.DefaultPaymentDue}
                      onChange={this.handleChange}
                      variant='outlined'
                      required
                      fullWidth
                    >
                      <MenuItem value='C'>On Completion</MenuItem>
                      <MenuItem value='P'>Prior to Start</MenuItem>
                    </TextField>
                    <div className='hidden sm:block min-w-12 pt-20'></div>
                    <TextField
                      className={classes.formControl}
                      label='Default Expiration Days'
                      id='defaultExpirationDays'
                      name='DefaultExpirationDays'
                      value={this.state.DefaultExpirationDays}
                      onChange={this.handleChange}
                      inputProps={{
                        type: 'number',
                      }}
                      variant='outlined'
                      fullWidth
                    />
                  </div>
                  <div className='sm:flex' style={{ marginTop: -10 }}>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>cancel</Icon>
                    </div>
                    <div
                      className='w-full mb-12 xs:min-w-full'
                      style={{ maxWidth: 'calc(100% - 48px)' }}
                    >
                      <label
                        className='text-12 p-4 px-6 ml-8'
                        style={{ background: '#fff', color: 'grey' }}
                      >
                        Standard Estimate Exclusions:
                      </label>
                      <div
                        className='rounded-4 p-12 mb-12'
                        style={{
                          border: '1px solid rgba(0, 0, 0, .25)',
                          marginTop: -11,
                        }}
                      >
                        <ul
                          className={
                            this.state.Data.Exclusions.length > 0 ? 'mb-8' : ''
                          }
                        >
                          <EstimateExclusions
                            editable={true}
                            estimate={{ ...this.state }}
                            details={this.state.Data.Exclusions}
                          />
                          {this.state.exclusion && (
                            <li key={this.state.exclusion.Exclusion}>
                              <div className='flex clear'>
                                <TextField
                                  className={classNames('mr-8')}
                                  label='New Exclusion'
                                  id='exclusionDescription'
                                  name='exclusion.Description'
                                  value={this.state.exclusion.Description || ''}
                                  onChange={this.handleChange}
                                  autoFocus
                                  margin='dense'
                                  onBlur={() => {
                                    if (!this.state.exclusion.Description) {
                                      this.setState({
                                        ...this.state,
                                        exclusion: null,
                                      })
                                    }
                                  }}
                                  // onChange={(e) => this.setState({ ...this.state, exclusion: { ...this.state.exclusion, Description: e.target.value } })}
                                  variant='outlined'
                                  fullWidth
                                />
                                <Button
                                  variant='contained'
                                  className='w-72 mt-8 mb-4'
                                  color='primary'
                                  onClick={() => {
                                    // this.closeComposeDialog();
                                    const {
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      Estimate,
                                      Data,
                                      exclusion,
                                    } = this.state
                                    // const { Description } = this.state.exclusion;
                                    const { Exclusions } = Data
                                    Exclusions.push(exclusion)
                                    const newExclusion = {
                                      ID: null,
                                      Co,
                                      BusinessUnit,
                                      Division,
                                      Estimate,
                                      Exclusion:
                                        (Exclusions.length > 0
                                          ? _.maxBy(Exclusions, 'Exclusion')
                                              .Exclusion
                                          : 0) + 1,
                                      Description: null,
                                      Data: {
                                        Exclusions: [],
                                      },
                                    }
                                    this.setState(
                                      {
                                        ...this.state,
                                        exclusion: newExclusion,
                                        Data: {
                                          ...this.state.Data,
                                          Exclusions,
                                        },
                                      },
                                      //   window["warn"](newExclusion, Exclusions)
                                    )
                                  }}
                                  disabled={!this.state.exclusion.Description}
                                >
                                  Add
                                </Button>
                              </div>
                            </li>
                          )}
                        </ul>
                        {!this.state.exclusion && (
                          <Typography
                            variant='subtitle2'
                            style={{
                              alignItems: 'center',
                              borderTop:
                                this.state.Data.Exclusions.length > 0
                                  ? '1px solid #f1f1f1'
                                  : 'none',
                            }}
                            className={classNames(
                              'text-12 flex cursor-pointer justify-end',
                              this.state.Data.Exclusions.length > 0
                                ? 'pt-8'
                                : '',
                            )}
                            onClick={() => {
                              const {
                                Co,
                                BusinessUnit,
                                Division,
                                Estimate,
                                Data,
                              } = this.state
                              const { Exclusions } = Data
                              const exclusion = {
                                ID: null,
                                Co,
                                BusinessUnit,
                                Division,
                                Estimate,
                                Exclusion:
                                  (Exclusions.length > 0
                                    ? _.maxBy(Exclusions, 'Exclusion').Exclusion
                                    : 0) + 1,
                                Description: null,
                                Data: {
                                  Exclusions: [],
                                },
                              }
                              this.setState({ ...this.state, exclusion })
                            }}
                          >
                            <Icon
                              color='primary'
                              className='text-18 mr-4'
                              style={{ marginBottom: 2 }}
                            >
                              add
                            </Icon>
                            New Exclusion
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>assignment</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Default Terms of Acceptance'
                      id='contractVerbiage'
                      name='DefaultContractVerbiage'
                      value={this.state.DefaultContractVerbiage}
                      onChange={this.handleChange}
                      variant='outlined'
                      multiline
                      rows={5}
                      // autoFocus
                      fullWidth
                    />
                  </div>

                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>announcement</Icon>
                    </div>
                    <TextField
                      className={classes.formControl}
                      label='Default Disclaimer'
                      id='disclaimer'
                      name='DefaultDisclaimer'
                      value={this.state.DefaultDisclaimer}
                      onChange={this.handleChange}
                      variant='outlined'
                      multiline
                      rows={5}
                      // autoFocus
                      fullWidth
                    />
                  </div>

                  <div className='sm:flex justify-end'>
                    <FormControlLabel
                      classes={{ label: 'font-bold' }}
                      control={
                        <Checkbox
                          checked={this.state.IncludeTaxYN === 'Y'}
                          onChange={() =>
                            this.setState({
                              ...this.state,
                              IncludeTaxYN:
                                this.state.IncludeTaxYN === 'Y' ? 'N' : 'Y',
                            })
                          }
                        />
                      }
                      label='Include Tax?'
                    />
                  </div>

                  {(this.state.DefaultDisclaimer ||
                    this.state.DefaultContractVerbiage ||
                    this.state.Data.Exclusions.length > 0) && (
                    <div className='sm:flex'>
                      <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                      <div className='w-full mb-24'>
                        <Typography className='font-bold text-14 mb-4'>
                          Preview:
                        </Typography>
                        {this.state.Data.Exclusions.length > 0 && (
                          <div
                            className='mb-12 rounded-6 p-8'
                            style={{ background: '#f1f1f1' }}
                          >
                            <div className='text-10 w-full'>
                              <b>Exclusions:</b>
                            </div>
                            <ul>
                              <EstimateExclusions
                                estimate={{ ...this.state }}
                                details={this.state.Data.Exclusions}
                              />
                            </ul>
                          </div>
                        )}
                        {this.state.DefaultContractVerbiage && (
                          <div
                            className='mb-12 rounded-6 p-8'
                            style={{ background: '#f1f1f1' }}
                          >
                            <div className='text-10 w-full'>
                              <b>Terms of Acceptance:</b>
                              <br />
                              {this.state.DefaultContractVerbiage}
                            </div>
                          </div>
                        )}
                        {this.state.DefaultDisclaimer && (
                          <div
                            className={
                              this.state.DefaultContractVerbiage ? 'pt-12' : ''
                            }
                            style={{
                              borderTop: this.state.DefaultContractVerbiage
                                ? '1px solid #f1f1f1'
                                : 'none',
                            }}
                          >
                            <div className='font-bold text-red text-10 text-center w-full'>
                              * {this.state.DefaultDisclaimer} *
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className='sm:flex'>
                    <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                      <Icon color='action'>image</Icon>
                    </div>
                    {!this.state.EstimateFooterImg ? (
                      <div
                        onClick={this.openFooterImgDialog}
                        className='w-full sm:w-full-48 h-128 mb-24 relative cursor-pointer rounded-6'
                        style={{ border: '2px dotted lightgrey' }}
                      >
                        <Icon
                          className='pin-l pin-r pin-t pin-b absolute m-auto'
                          color='primary'
                        >
                          cloud_upload
                        </Icon>
                      </div>
                    ) : (
                      <img
                        onClick={this.openFooterImgDialog}
                        className='w-full sm:w-full-48 h-auto cursor-pointer'
                        src={this.state.EstimateFooterImg}
                      />
                    )}
                  </div>
                </div>
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer padding='12px'>
                <CatalogAssemblyList
                  fullScreen={fullScreen}
                  onCategoryChange={category =>
                    this.props.setCategory(category)
                  }
                  dialog={true}
                  catalog={{ ...this.state }}
                />
              </TabContainer>
            )}
            {value === 4 && (
              <TabContainer padding='12px'>
                <CatalogOptionBoardList
                  fullScreen={fullScreen}
                  dialog={true}
                  catalog={{ ...this.state }}
                />
              </TabContainer>
            )}
            {value === 5 && (
              <TabContainer padding='12px'>
                <RateUpdatesList catalog={{ ...this.state }} />
              </TabContainer>
            )}
            {value === 6 &&
              this.state.PRSyncYN === 'Y' &&
              Boolean(this.state.ID) &&
              syncAccess &&
              syncAccess.AccessLevel === 'F' && (
                <TabContainer padding='12px'>
                  <FuseAnimate animation='transition.slideUpIn' delay={300}>
                    <div className='p-12'>
                      <Typography
                        variant='h6'
                        style={{ alignItems: 'center' }}
                        className='flex truncate text-16 sm:text-20 mb-6 mb-16'
                      >
                        <Icon color='primary' className='text-32 mr-12'>
                          cloud_download
                        </Icon>
                        Update from Cloud
                      </Typography>
                      <div className='sm:flex'>
                        <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                          <Icon color='action'>account_circle</Icon>
                        </div>
                        <TextField
                          className={classes.formControl}
                          label='PR Username'
                          id='PRSync-u'
                          name='PRSync.u'
                          value={this.state.PRSync.u || ''}
                          onChange={this.handleChange}
                          variant='outlined'
                          required
                          fullWidth
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <TextField
                          className={classes.formControl}
                          label='PR Password'
                          id='PRSync-p'
                          name='PRSync.p'
                          value={this.state.PRSync.p || ''}
                          onChange={this.handleChange}
                          variant='outlined'
                          required
                          fullWidth
                          type='password'
                        />
                      </div>
                      <div className='sm:flex'>
                        <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                          <Icon color='action'>ballot</Icon>
                        </div>
                        <Autocomplete
                          className={classes.formControl}
                          title='Default Rate Template'
                          options={_.filter(rateTemplates, {
                            Co,
                            BusinessUnit,
                            Division,
                          })}
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.RateTemplate}>
                                {`${value.Description}`}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          required
                          value={this.state.PRSync.RateTemplate}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              PRSync: {
                                ...this.state.PRSync,
                                RateTemplate: option.RateTemplate,
                              },
                            })
                          }
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <Autocomplete
                          className={classes.formControl}
                          title='Default Labor Type'
                          options={_.filter(laborTypes, {
                            Co,
                            BusinessUnit,
                            Division,
                          })}
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.LaborType}>
                                {`${value.Description}`}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          required
                          value={this.state.PRSync.LaborType}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              PRSync: {
                                ...this.state.PRSync,
                                LaborType: option.LaborType,
                              },
                            })
                          }
                        />
                        <div className='hidden sm:block min-w-12 pt-20'></div>
                        <Autocomplete
                          className={classes.formControl}
                          title='Default Assistant Labor Type'
                          options={_.filter(laborTypes, {
                            Co,
                            BusinessUnit,
                            Division,
                          })}
                          menuItemComponent={value => {
                            return (
                              <MenuItem value={value.LaborType}>
                                {`${value.Description}`}
                              </MenuItem>
                            )
                          }}
                          portal={true}
                          required
                          value={this.state.PRSync.AssistantLaborType}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              PRSync: {
                                ...this.state.PRSync,
                                AssistantLaborType: option.LaborType,
                              },
                            })
                          }
                        />
                      </div>
                      <div className='w-full flex'>
                        <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={this.syncPriceBook}
                          className='w-full'
                          disabled={
                            isUpdating ||
                            !this.state.PRSync.u ||
                            !this.state.PRSync.p ||
                            !this.state.PRSync.RateTemplate ||
                            !this.state.PRSync.LaborType ||
                            !this.state.PRSync.AssistantLaborType
                          }
                        >
                          {!isUpdating ? (
                            <Icon className='mr-6'>cloud_download</Icon>
                          ) : (
                            <CircularProgress className='mr-8' size={16} />
                          )}
                          Updat{isUpdating ? 'ing' : 'e'} Price Book
                        </Button>
                      </div>
                      <Typography
                        variant='h6'
                        style={{ alignItems: 'center' }}
                        className='flex truncate text-16 sm:text-20 mb-6 my-16'
                      >
                        <Icon color='primary' className='text-32 mr-12'>
                          history
                        </Icon>
                        Update History
                      </Typography>
                      <ReactTable
                        className={classNames(
                          classes.root,
                          '-striped -highlight border-0',
                        )}
                        getTrProps={(state, rowInfo, column) => {
                          return {
                            className: 'cursor-pointer',
                            onClick: (e, handleOriginal) => {},
                          }
                        }}
                        data={this.state.Data.SyncHistory || []}
                        columns={[
                          {
                            Header: 'Triggered By',
                            className: 'justify-center w-128',
                            id: 'TriggeredBy',
                            accessor: row => {
                              const val = _.find(users, {
                                UserName: row.TriggeredBy,
                              })
                              return val
                                ? `${val.FirstName} ${val.LastName}`
                                : ''
                            },
                          },
                          {
                            Header: 'Date',
                            accessor: 'TriggeredDate',
                            className: 'justify-center w-96',
                            Cell: r => {
                              return r.value
                                ? moment(r.value).format('M/D/YYYY hh:mm A')
                                : ''
                            },
                          },
                          {
                            Header: 'Applied',
                            accessor: 'AppliedDate',
                            className: 'justify-center',
                            Cell: r => {
                              return r.value ? 'Y' : 'N'
                            },
                          },
                          {
                            Header: 'Message',
                            id: 'Status',
                            className: 'w-512',
                            accessor: r => {
                              let msg = ''
                              if (r.Status) {
                                const stat = JSON.parse(r.Status)
                                // window["log"](stat);
                                if (stat.Message) {
                                  msg = stat.Message
                                }
                              }
                              return msg
                            },
                          },
                        ]}
                        pageSize={10}
                        noDataText='No updates found'
                      />
                    </div>
                  </FuseAnimate>
                </TabContainer>
              )}

            {value < 3 && !this.state.ID && (
              <DialogActions className='dialog-actions justify-between pl-8 pr-8 pin-l pin-r pin-b absolute h-64 m-0 bg-white'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    this.setState({ ...this.state, saving: true }, () => {
                      var rec = this.state
                      addCatalog(rec)
                      this.setState({ ...this.state, value: 2, saving: false })
                    })
                  }}
                  disabled={!this.canBeSubmitted() || saving}
                >
                  {saving ? (
                    <CircularProgress
                      color='primary'
                      className='mr-12'
                      style={{ width: 20, height: 20 }}
                    />
                  ) : (
                    <Icon className='mr-8'>save</Icon>
                  )}
                  {saving ? 'Adding' : `Add`}
                </Button>
              </DialogActions>
            )}
            {value < 3 && this.state.ID && (
              <DialogActions className='dialog-actions justify-between pl-8 pr-8 pin-l pin-r pin-b absolute h-64 m-0 bg-white'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    this.setState({ ...this.state, saving: true }, () => {
                      var rec = this.state
                      updateCatalog(rec)
                      // this.setState({ ...this.state, saving: false })
                    })
                  }}
                  disabled={!this.canBeSubmitted() || saving}
                >
                  {saving ? (
                    <CircularProgress
                      color='primary'
                      className='mr-12'
                      style={{ width: 20, height: 20 }}
                    />
                  ) : (
                    <Icon className='mr-8'>save</Icon>
                  )}
                  {saving ? 'Saving' : `Save`}
                </Button>
              </DialogActions>
            )}
          </Dialog>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditCatalogDialog: Actions.closeEditCatalogDialog,
      closeNewCatalogDialog: Actions.closeNewCatalogDialog,
      addCatalog: Actions.addCatalog,
      updateCatalog: Actions.updateCatalog,
      setCategory: Actions.setCategory,
      downloadCatalog: Actions.downloadCatalog,
      // removeCatalog: Actions.removeCatalog,
      showMessage: showMessage,
      impress,
    },
    dispatch,
  )
}

function mapStateToProps({ auth, catalogApp, spReducers, dispatchBoardApp }) {
  return {
    catalogDialog: catalogApp.catalogDialog,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    employees: spReducers.employees,
    catalog: spReducers.catalog,
    users: spReducers.userProfiles.Users,
    user: spReducers.userProfiles.User.UserName,
    technicians: spReducers.technicians,
    Co: spReducers.companies.Co,
    rateTemplates: spReducers.rateTemplates,
    laborTypes: spReducers.laborTypes,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(CatalogDialog),
)
