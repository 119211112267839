import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedGLAccountIds: [],
    routeParams       : {},
    glAccountDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const glAccountsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_GL_ACCOUNTS:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_GL_ACCOUNTS:
        {

            const glAccountId = action.glAccountId;

            let selectedGLAccountIds = [...state.selectedGLAccountIds];

            if ( selectedGLAccountIds.find(id => id === glAccountId) !== undefined )
            {
                selectedGLAccountIds = selectedGLAccountIds.filter(id => id !== glAccountId);
            }
            else
            {
                selectedGLAccountIds = [...selectedGLAccountIds, glAccountId];
            }

            return {
                ...state,
                selectedGLAccountIds: selectedGLAccountIds
            };
        }
        case Actions.SELECT_ALL_GL_ACCOUNTS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedGLAccountIds = arr.map(glAccounts => glAccounts.id);

            return {
                ...state,
                selectedGLAccountIds: selectedGLAccountIds
            };
        }
        case Actions.DESELECT_ALL_GL_ACCOUNTS:
        {
            return {
                ...state,
                selectedGLAccountIds: []
            };
        }
        case Actions.OPEN_NEW_GL_ACCOUNT_DIALOG:
        {
            return {
                ...state,
                glAccountDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_GL_ACCOUNT_DIALOG:
        {
            return {
                ...state,
                glAccountDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_GL_ACCOUNT_DIALOG:
        {
            return {
                ...state,
                glAccountDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_GL_ACCOUNT_DIALOG:
        {
            return {
                ...state,
                glAccountDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default glAccountsReducer;
