import React, { Component } from 'react';
import {
    TextField, Badge, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem, Tabs, Tab, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import InventoryLocationsList from '../inventory-locations/InventoryLocationsList';
import InventoryLocationDialog from '../inventory-locations/InventoryLocationDialog';
import _ from '@lodash';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newInventoryLocationGroupState = {
    ID: null,
    Co: null,
    LocationGroup: null,
    Description: null,
    DefaultPriceMethod: 'M',
    DefaultMarkupPercent: 10.00,
    Notes: '',
    Data: {
        InventoryLocations: [],
    },
    ActiveYN: "Y",
    value: 0,
};

class InventoryLocationGroupDialog extends Component {
    state = { ...newInventoryLocationGroupState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newInventoryLocationGroupState.Co = this.props.Co;
        if (!prevProps.inventoryLocationGroupDialog.props.open && this.props.inventoryLocationGroupDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.inventoryLocationGroupDialog.type === 'edit' &&
                this.props.inventoryLocationGroupDialog.data &&
                !_.isEqual(this.props.inventoryLocationGroupDialog.data, prevState)) {
                this.setState({ ...this.props.inventoryLocationGroupDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.inventoryLocationGroupDialog.type === 'new' &&
                !_.isEqual(newInventoryLocationGroupState, prevState)) {
                this.setState({ ...newInventoryLocationGroupState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.inventoryLocationGroupDialog.type === 'edit' ? this.props.closeEditInventoryLocationGroupDialog() : this.props.closeNewInventoryLocationGroupDialog();
    };

    canBeSubmitted() {
        const { Description, } = this.state;
        return (
            Description &&
            Description.length > 0
        );
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes, securables, inventoryLocationGroupDialog, addInventoryLocationGroup, updateInventoryLocationGroup, removeInventoryLocationGroup, businessUnits, divisions, inventoryLocationGroups, glAccounts } = this.props;
        const { value } = this.state;
        const accessLevel = _.find(securables, { Securable: "inventory-location-groups" });

        return (
            <Media query={value === 1 ? "(min-width: 768px) and (min-height: 769px)" : "(min-width: 512px) and (min-height: 512px)"}>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...inventoryLocationGroupDialog.props}
                        open={Boolean(accessLevel) && inventoryLocationGroupDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={value === 1 ? "lg" : "xs"}
                    >
                        {/* <InventoryLocationDialog /> */}
                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {inventoryLocationGroupDialog.type === 'new' ? 'New Inventory Location Group' : `Inventory Location Group #${this.state.LocationGroup}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                            >
                                <Tab disableRipple icon={<Icon>account_circle</Icon>} label="Info" />
                                <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.InventoryLocations ? this.state.Data.InventoryLocations.length : 0} color="error"><Icon>store</Icon></Badge>} label="Locations" disabled={this.props.inventoryLocationGroupDialog.type === 'new'} />
                            </Tabs>
                        </AppBar>
                        {value === 0 &&
                            <TabContainer>
                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="LocationGroup"

                                        id="category"
                                        name="LocationGroup"
                                        value={this.state.LocationGroup}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        disabled={inventoryLocationGroupDialog.type !== 'new'}
                                    />
                                </div>

                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>

                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="DefaultPriceMethod"
                                        id="defaultPriceMethod"
                                        name="DefaultPriceMethod"
                                        value={this.state.DefaultPriceMethod}
                                        onChange={this.handleChange}
                                        InputLabelProps={{ shrink: this.state.DefaultPriceMethod ? true : false }}
                                        variant="outlined"
                                        required
                                        select
                                        fullWidth
                                    >
                                        <MenuItem value="M">
                                            Markup %
                                    </MenuItem>
                                        <MenuItem value="F">
                                            Fixed Price
                                    </MenuItem>
                                    </TextField>
                                </div>

                                <div className="flex">

                                    <TextField
                                        className={classes.formControl}
                                        label="DefaultMarkupPercent"
                                        id="defaultMarkupPercent"
                                        name="DefaultMarkupPercent"
                                        value={this.state.DefaultMarkupPercent}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            endAdornment: this.state.DefaultMarkupPercent && <InputAdornment position="end">%</InputAdornment>,
                                            type: "number"
                                        }}
                                        InputLabelProps={{ shrink: this.state.DefaultMarkupPercent ? true : false }}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </div>

                                <div className="flex">
                                    <TextField
                                        className={classes.formControl}
                                        label="Notes"
                                        id="notes"
                                        name="Notes"
                                        value={this.state.Notes}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                                <div className="flex">
                                    <div className="w-1/2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                    </div>
                                </div>
                            </TabContainer>
                        }
                        {value === 1 &&
                            <TabContainer>
                                <InventoryLocationsList selectedInventoryLocationData={[...this.state.Data.InventoryLocations]} />
                            </TabContainer>}

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {this.props.inventoryLocationGroupDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addInventoryLocationGroup(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                            </Button>
                                ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                updateInventoryLocationGroup(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                            </Button>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditInventoryLocationGroupDialog: Actions.closeEditInventoryLocationGroupDialog,
        closeNewInventoryLocationGroupDialog: Actions.closeNewInventoryLocationGroupDialog,
        addInventoryLocationGroup: Actions.addInventoryLocationGroup,
        updateInventoryLocationGroup: Actions.updateInventoryLocationGroup,
        removeInventoryLocationGroup: Actions.removeInventoryLocationGroup
    }, dispatch);
}

function mapStateToProps({ inventoryLocationGroupsApp, spReducers }) {
    return {
        inventoryLocationGroupDialog: inventoryLocationGroupsApp.inventoryLocationGroups.inventoryLocationGroupDialog,
        Co: spReducers.companies.Co,
        inventoryLocationGroups: spReducers.inventoryLocationGroups,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(InventoryLocationGroupDialog));
