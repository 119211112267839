import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Badge, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Drawer, Fab, FormControlLabel, GridListTileBar, Grow, Icon, IconButton, Input, InputAdornment, Menu, MenuItem, Slide, Switch, Tab, Tabs, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { impress } from 'main/content/compression/impress';
import { SPRoundIcon } from 'main/icons';
import React, { Component } from 'react';
import Media from 'react-media';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import EstimateComponentsList from '../estimates/EstimateComponentsList';
import CatalogAssemblyComponentsList from './CatalogAssemblyComponentsList';
import CatalogAssemblyImageDialog from './CatalogAssemblyImageDialog';
import * as Actions from './store/actions';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 108px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const drawerWidth = 240;

const styles = theme => ({
    root: {
        // overflow: 'hidden',
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    backdrop: {
        zIndex: 9998,
        backgroundColor: 'rgba(0,0,0,.5)'
    },
    recommendations: {
        zIndex: 9999
    }
});

const newCatalogItemState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    Name: null,
    Description: null,
    Assembly: null,
    Code: null,
    Type: 'T',
    PriceMethod: 'T',
    RateTemplate: null,
    RateTemplateDivision: null,
    Department: null,
    ServiceType: null,
    Service: null,
    Detail: null,
    Price: 0,
    MemberPrice: 0,
    AddOnPrice: 0,
    AddOnMemberPrice: 0,
    PremiumPrice: 0,
    PremiumAddOnPrice: 0,
    PremiumYN: 'N',
    AddonYN: null,
    TaxableYN: 'Y',
    DisplayYN: null,
    Notes: null,
    ActiveYN: 'Y',
    PRCode: null,
    EditFromFieldYN: null,
    CustomPrice: '',
    CustomMemberPrice: '',
    EnteredBy: null,
    UpdatedBy: null,
    EnteredDate: null,
    UpdatedDate: null,
    Data: {
        ErrMsg: null,
        Components: [],
        Assignments: [],
        Images: [],
        Recommendations: [],
        Upgrades: []
    },
    Category: null,
    Qty: 1,
    showTabs: false,
    assembliesList: false,
    imageDialog: false,
    selectedImage: null,
    value: 0,
    saving: false,
    copying: false,
    RecommendedAssembly: null,
    UpgradedAssembly: null,
    showRecommendations: false,
    estimate: null,
    option: null,
    anchorEl: null
};

class CatalogItemDialog extends Component {

    state = { ..._.cloneDeepWith(newCatalogItemState) };

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props.catalogItemDialog;

        let initialState = _.cloneDeepWith(newCatalogItemState);
        let { component } = this.props;
        let { estimate } = data || {};
        if (!component && estimate) {
            if (estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = data || this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }

        if (this.props.catalogItemDialog.type === 'edit' && data &&
            !_.isEqual(data, prevProps.catalogItemDialog.data)) {
            this.setState({ ...initialState, ...data, showTabs: !data.estimate, Name: (component ? component.Name || data.Name : data.Name), Description: (component ? component.Description || data.Description : data.Description), Qty: (component ? component.Qty : this.state.Qty), CustomPrice: (component ? component.CustomPrice : data.CustomPrice), CustomMemberPrice: (component ? component.CustomMemberPrice : data.CustomMemberPrice), Detail: (component ? component.Detail : data.Detail) });
        }
        if (this.props.catalogItemDialog.type === 'update' &&
            this.props.catalogItemDialog.data &&
            !_.isEqual({ ...initialState, ...data }, prevProps.catalogItemDialog.data)) {
            // window["warn"]('Data Updated: ', data);
            this.props.catalogItemDialog.type = 'edit';
            this.setState({ ...initialState, ...data, showTabs: this.state.showTabs, value: this.state.value });
        }
        if (!prevProps.catalogItemDialog.props.open && this.props.catalogItemDialog.props.open) {


            if (this.props.catalogItemDialog.type === 'new' && data &&
                !_.isEqual({ ...initialState, ...data }, prevProps.catalogItemDialog.data)) {
                this.setState({ ...initialState, ...data, showTabs: true, });
            }
        } else {
        }
    }

    handleChange = (event) => {
        let { name, value } = event.target;

        if (name === 'Qty') {
            value = value.replace(/^1/, 0)
        }

        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.catalogItemDialog.type === 'edit' ? this.props.closeEditCatalogItemDialog() : this.props.closeNewCatalogItemDialog();
    };

    canBeSubmitted() {
        const { Co, Name, Description } = this.state;
        return (
            Co && Name && Description && Co > 0 && Name.length > 0 && Description.length > 0
        );
    }

    fileSelectedHandler = (event) => {
        impress(event.target.files[0], 1024, 50, true, () => {
            // window["log"](img);
            // const fd = new FormData();
            // fd.append('image', img.file, img.fileName);
            // axios.post(`${window["apiLocation"]}/api/Attachment/PostProfileImage?Co=${encodeURIComponent(this.state.Co)}&Type=Catalog&ID=${encodeURIComponent(this.state.Catalog)}&User=${encodeURIComponent(this.props.user)}`, fd).then((res) => {
            //     if (Data) {
            //         Data.Avatar = img.base64;
            //     } else {
            //         Data = { Avatar: img.base64 };
            //     }
            //     this.setState({ Data });
            // });
        });
    }

    toggleTabs = () => {
        const { showTabs } = this.state;
        this.setState({ ...this.state, showTabs: !showTabs });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    increaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate && estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }
        Qty = Number(Qty || (component ? component.Qty : 1)) + 1;
        this.setState({ ...this.state, Qty });
    }

    decreaseQty = (e) => {
        e.stopPropagation();
        let { Qty } = this.state;
        let { component, estimate } = this.props;
        if (!component) {
            if (estimate && estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }

        Qty = Math.max(Number(Qty || (component ? component.Qty : 0)) - 1, 0); 

        Qty = Qty === 0 ? 1 : Qty;

        this.setState({ ...this.state, Qty });
    }

    handleBlur = (event) => {
        let { name, value } = event.target; 

        if (name === 'Qty' && value.trim() === '' || !value) { 
            this.setState({...this.state, Qty: 1}) 

        }
    } 

    toggleAssemblies = (open, showRecommendations) => {
        this.setState({
            ...this.state,
            assembliesList: open,
            showRecommendations
        });
    };

    toggleImageDialog = (open) => {
        this.setState({ ...this.state, imageDialog: open });
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    handleEditorChange = (Description) => {
        this.setState({ ...this.state, Description })
    }

    handleCustomMemberPriceChange = (e) => {
        const { catalog } = this.props;
        const { estimate } = this.state;
        const { Co, BusinessUnit, Division } = estimate || {};
        const cat = _.find(catalog.catalogs, { Co, BusinessUnit, Division }) || {};
        this.setState({ CustomMemberPrice: e.target.value, CustomPrice: cat.MemberLaborDiscount ? ((e.target.value / (100 - cat.MemberLaborDiscount)) * 100).toFixed(2) : this.state.CustomPrice });
    }

    handleCustomPriceChange = (e) => {
        const { catalog } = this.props;
        const { estimate } = this.state;
        const { Co, BusinessUnit, Division } = estimate || {};
        const cat = _.find(catalog.catalogs, { Co, BusinessUnit, Division }) || {};
        this.setState({ CustomPrice: e.target.value, CustomMemberPrice: cat.MemberLaborDiscount ? e.target.value * ((100 - cat.MemberLaborDiscount) / 100).toFixed(2) : this.state.CustomMemberPrice });
    }

    copyTask = () => {
        this.setState({ ...this.state, copying: true, anchorEl: null }, () => {
            var rec = _.cloneDeepWith(this.state);
            const { Components, Images, Assignments, Recommendations, Upgrades } = rec.Data;
            rec.Assembly = null;
            rec.ID = null;
            rec.UpdatedBy = null;
            rec.UpdatedDate = null;
            rec.EnteredBy = null;
            rec.EnteredDate = null;
            rec.Data = {
                Components,
                Images,
                Assignments,
                Recommendations,
                Upgrades
            }
            rec.Data.Components.map((comp) => {
                comp.ID = null;
                comp.AssemblyID = null;
                comp.comp = null;
                comp.UpdatedBy = null;
                comp.UpdatedDate = null;
                comp.EnteredBy = null;
                comp.EnteredDate = null;
                comp.Data = null;
            });
            rec.Data.Assignments.map((assn) => {
                assn.ID = null;
                assn.Assembly = null;
                assn.UpdatedBy = null;
                assn.UpdatedDate = null;
                assn.EnteredBy = null;
                assn.EnteredDate = null;
                assn.Data = null;
            });
            rec.Data.Upgrades.map((upg) => {
                upg.ID = null;
                upg.Assembly = null;
                upg.UpdatedBy = null;
                upg.UpdatedDate = null;
                upg.EnteredBy = null;
                upg.EnteredDate = null;
                upg.Data = null;
            });
            rec.Data.Recommendations.map((rcmd) => {
                rcmd.ID = null;
                rcmd.Assembly = null;
                rcmd.UpdatedBy = null;
                rcmd.UpdatedDate = null;
                rcmd.EnteredBy = null;
                rcmd.EnteredDate = null;
                rcmd.Data = null;
            });
            rec.Data.Images.map((img) => {
                img.ID = null;
                img.Assembly = null;
                img.UpdatedBy = null;
                img.UpdatedDate = null;
                img.EnteredBy = null;
                img.EnteredDate = null;
                img.Data = null;
            });

            Promise.all([
                this.props.addAssembly(rec, true)
            ]).then(() => this.setState({ ...this.state, copying: false }));
        })
    }

    render() {
        // window["warn"](this.state);
        const { classes, theme, catalogItemDialog, addAssembly, rateTemplates, updateAssembly, departments, serviceTypes, services, catalog } = this.props;
        let { component } = this.props;
        let { estimate } = this.state;
        if (!component && estimate) {
            if (estimate.Data && estimate.Data.Components) {
                const { Co, BusinessUnit, Division, Assembly } = this.state;
                component = _.find(estimate.Data.Components, { Co, BusinessUnit, Division, Assembly });
            }
        }
        const { Co, BusinessUnit, Division, value, showTabs, assembliesList, selectedImage, saving, RecommendedAssembly, UpgradedAssembly, showRecommendations, copying } = this.state;
        if (this.state.Data && this.state.Data.Images && (!this.state.Data.Image || this.state.Data.Image.DeleteYN === "Y")) {
            this.state.Data.Image = _.find(this.state.Data.Images, (o) => (o.PrimaryYN === 'Y' && o.DeleteYN !== "Y"));
            if (!this.state.Data.Image) {
                this.state.Data.Image = _.filter(this.state.Data.Images, (o) => o.DeleteYN !== "Y")[0];
            }
        } else {
            const Images = _.cloneDeepWith(this.state.Data.Images);
            const imgIndex = _.findIndex(Images, { PrimaryYN: 'Y' });
            if (imgIndex > 0) {
                Images.splice(0, 0, Images.splice(imgIndex, 1)[0]);
                this.setState({ ...this.state, Data: { ...this.state.Data, Images } });
            }


        }

        // window["warn"]("catalog item component123", this.props.component)

        const selectedCatalog = _.find(catalog.savedCatalogs, { Co, BusinessUnit, Division });
        const tasks = selectedCatalog ? selectedCatalog.Data.Assemblies : [];
        const categories = selectedCatalog ? selectedCatalog.Data.Categories : [];

        const activeAgreements = estimate && estimate.Data && estimate.Data.Site && estimate.Data.Site.Data && estimate.Data.Site.Data.Agreements ? _.filter(estimate.Data.Site.Data.Agreements, (o) => o.ActiveYN !== 'N' && new Date(o.StartDate) <= new Date() && new Date(o.EndDate) >= new Date()) : null;
        const isMember = activeAgreements && activeAgreements.length > 0;

        const toolbarOptions = [
            [{ size: ['small', false, 'large', 'huge'] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],

            ['link', 'image', 'video'],

            ['clean']                                       // remove formatting button
        ];

        const formats = [
            'size', 'bold', 'italic', 'underline', 'strike', 'color', 'background',
            'list', 'bullet', 'indent', 'align',
            'link'
        ]

        const history = {
            delay: 2000,
            maxStack: 500,
            userOnly: true
        }


        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"
                        }}
                        className={classes.root}
                        {...catalogItemDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                        onClick={assembliesList ? () => this.toggleAssemblies(false) : undefined}
                    >

                        <AppBar position="static" elevation={1} className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography onClick={this.toggleTabs} variant="subtitle1" color="inherit" className="flex max-w-1/2">
                                    <SPRoundIcon className="text-32 align-middle mr-8" />
                                    {['new', 'customer'].indexOf(catalogItemDialog.type) > -1 ?
                                        <div style={{ marginTop: 2 }}>
                                            New Assembly
                                        </div> :
                                        <div style={{ marginTop: 2 }} className="w-full truncate">
                                            {this.state.Name} {` - #${this.state.Assembly}`}
                                        </div>
                                    }
                                </Typography>
                                <Icon onClick={() => this.setState({ showTabs: !showTabs })} className="text-24 ml-8 align-middle mt-6 cursor-pointer">{showTabs ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                            </Toolbar>
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    right: estimate ? 104 : 56,
                                    top: matches ? 6 : 4,
                                    color: 'white'
                                }}
                                onClick={(e) => {
                                    this.setState({ anchorEl: e.target });
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>more_vert</Icon>
                            </IconButton>
                            {estimate &&
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 56,
                                    top: matches ? 6 : 4,
                                    color: 'white'
                                }}
                                    onClick={() => {
                                        this.toggleAssemblies(!this.state.assembliesList);
                                    }}
                                    className="dialog-header-icon"
                                >
                                    <Icon>format_list_numbered</Icon>
                                </IconButton>
                            }
                            <IconButton style={{
                                position: 'absolute',
                                right: 8,
                                top: matches ? 6 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>

                            <Menu id="copy-task-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={() => this.setState({ anchorEl: null })}>
                                <MenuItem
                                    onClick={(e) => this.copyTask(e)}
                                >
                                    <Icon className="mr-8">file_copy</Icon>
                                    Copy Assembly
                                </MenuItem>
                            </Menu>

                            {showTabs &&
                                <Tabs
                                    value={value}
                                    onChange={this.handleTabChange}
                                    variant="scrollable"
                                    scrollButtons="on"
                                >
                                    <Tab disableRipple icon={<Icon>chrome_reader_mode</Icon>} label="Info" />
                                    <Tab disableRipple disabled={!this.state.ID} icon={<Badge color="error" badgeContent={(this.state.Data.Components || []).length}><Icon>format_list_numbered</Icon></Badge>} label="Components" />
                                    <Tab disableRipple disabled={!selectedCatalog} icon={<Badge color="error" badgeContent={(this.state.Data.Recommendations || []).length}><Icon>style</Icon></Badge>} label="Recommendations" />
                                    <Tab disableRipple disabled={!selectedCatalog} icon={<Badge color="error" badgeContent={(this.state.Data.Upgrades || []).length}><Icon>loyalty</Icon></Badge>} label="Alternatives" />
                                    {/* <Tab disableRipple icon={<Icon>chrome_reader_mode</Icon>} label="Price Book" /> */}
                                </Tabs>
                            }
                        </AppBar>
                        {estimate &&
                            <Drawer
                                anchor="right"
                                variant="permanent"
                                className={clsx(classNames(classes.drawer, "h-full pin-r pin-t absolute"), {
                                    [classes.drawerOpen]: this.state.assembliesList,
                                    [classes.drawerClose]: !this.state.assembliesList
                                })}
                                classes={{
                                    paper: clsx("relative border-none h-full", {
                                        [classes.drawerOpen]: this.state.assembliesList,
                                        [classes.drawerClose]: !this.state.assembliesList
                                    })
                                }}
                                open={this.state.assembliesList}
                                onClose={() => this.toggleAssemblies(false)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div
                                    tabIndex={0}
                                    role="button"
                                >
                                    <div className="w-full p-12 pt-16 sm:pt-20 relative">
                                        <Typography className="font-bold w-full truncate">
                                            <SPRoundIcon className="mr-6 align-middle mb-4" />
                                            {estimate.Option ? `"${estimate.Name}"` : `Estimate #${estimate.EstimateNumber}`}
                                        </Typography>
                                        <IconButton
                                            className="pin-r pin-t absolute mt-8"
                                            onClick={() => this.toggleAssemblies(false)}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                    {this.state.assembliesList && estimate.Data && estimate.Data.Components &&
                                        <EstimateComponentsList estimate={{ ...estimate }} components={[...estimate.Data.Components]} />
                                    }
                                    <Button
                                        variant="contained"
                                        className={classNames(classes.black, "pin-b pin-r absolute m-12")}
                                        color="primary"
                                        onClick={() => {
                                            this.closeComposeDialog();
                                        }}
                                    >
                                        Done
                                    </Button>
                                </div>
                            </Drawer>
                        }
                        {/* <TabContainer padding={estimate ? "16px 16px 64px 16px" : "24px 24px 64px 24px"}> */}
                        {value === 0 &&
                            <DialogContent className={!estimate ? "p-24 pb-48" : "p-12 px-24 pb-48"}>
                                <div>
                                    {estimate && this.state.EditFromFieldYN !== "Y" ?
                                        <Typography variant="h5" className="text-20 font-bold">
                                            {this.state.Name}
                                        </Typography>
                                        :
                                        <div className="w-full sm:flex">
                                            {!estimate &&
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">chrome_reader_mode</Icon>
                                                </div>
                                            }
                                            <TextField
                                                className="w-full m-0"
                                                label="Title"
                                                id="name"
                                                name="Name"
                                                value={this.state.Name || ''}
                                                margin="dense"
                                                onChange={this.handleChange}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    // disableUnderline: true,
                                                    // className: "p-0"
                                                }}
                                                inputProps={{
                                                    className: "text-20 font-bold"
                                                }}
                                            />
                                        </div>
                                    }
                                    <div className="sm:flex my-24 mb-0">
                                        <CatalogAssemblyImageDialog onClose={() => this.toggleImageDialog(false)} onChange={(Images) => this.setState({ ...this.state, Data: { ...this.state.Data, Images } })} open={this.state.imageDialog} assembly={this.state} images={this.state.Data.Images || []} videos={this.state.Data.videos || []} />
                                        {!estimate &&
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                <Icon color="action">local_mall</Icon>
                                            </div>
                                        }
                                        <div className="w-full sm:max-w-1/2-12">
                                            {this.state.Data && this.state.Data.Image ?
                                                <div className="w-full overflow-hidden mb-0 relative min-h-320 rounded max-h-512" style={{ border: '1px solid #f1f1f1' }}>
                                                    <div className={classNames(classes.blurBG, "pin-l pin-r pin-t pin-b m-auto absolute")} style={{ backgroundImage: `url(${this.state.Data.Image.ImgUrl})` }}></div>

                                                    <div className={classNames(classes.fitBG, "pin-l pin-r pin-t pin-b m-auto absolute cursor-pointer")} style={{ backgroundImage: `url(${this.state.Data.Image.ImgUrl})` }} onClick={() => { const selectedImage = _.findIndex(this.state.Data.Images, { ImgUrl: this.state.Data.Image.ImgUrl }); if (selectedImage > -1) { this.setState({ ...this.state, selectedImage }) } }}></div>

                                                    {/* <img className="w-full h-auto rounded cursor-pointer" style={{ border: '1px solid #f1f1f1' }} src={{ ...this.state }.Data.Image.ImgUrl} /> */}
                                                    {this.state.Data.Images && this.state.Data.Images[selectedImage] &&
                                                        <Dialog
                                                            open={this.state.Data.Images && this.state.Data.Images[selectedImage]}
                                                            onClose={() => this.setState({ ...this.state, selectedImage: null })}
                                                            fullWidth
                                                            maxWidth="sm"
                                                        >
                                                            <IconButton style={{
                                                                position: 'absolute',
                                                                right: 10,
                                                                top: 10,
                                                                zIndex: 3,
                                                                color: 'white'
                                                            }}
                                                                className={classes.closeIcon}
                                                                onClick={() => this.setState({ ...this.state, selectedImage: null })}
                                                            >
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                            {selectedImage > 0 &&
                                                                <IconButton style={{
                                                                    position: 'absolute',
                                                                    left: 10,
                                                                    top: 'calc(50% - 12px)',
                                                                    zIndex: 3,
                                                                    color: 'white'
                                                                }}
                                                                    className={classes.closeIcon}
                                                                    onClick={() => this.setState({ ...this.state, selectedImage: selectedImage - 1 })}
                                                                >
                                                                    <Icon className="text-32 text-grey-lighter">chevron_left</Icon>
                                                                </IconButton>
                                                            }
                                                            {(selectedImage < (this.state.Data.Images.length - 1) && selectedImage < this.state.Data.Images.length - 1) &&
                                                                <IconButton style={{
                                                                    position: 'absolute',
                                                                    right: 10,
                                                                    top: 'calc(50% - 12px)',
                                                                    zIndex: 3,
                                                                    color: 'white'
                                                                }}
                                                                    className={classes.closeIcon}
                                                                    onClick={() => this.setState({ ...this.state, selectedImage: selectedImage + 1 })}
                                                                >
                                                                    <Icon className="text-32 text-grey-lighter">chevron_right</Icon>
                                                                </IconButton>
                                                            }
                                                            <img className="w-full" src={this.state.Data.Images[selectedImage].ImgUrl} />
                                                            <GridListTileBar
                                                                // title={this.state.Data.Images[selectedImage].ID}
                                                                titlePosition="top"
                                                                actionIcon={!estimate &&
                                                                    <IconButton onClick={() => {
                                                                        const { Images } = this.state.Data;
                                                                        Images.map((Img) => {
                                                                            if (Img.ID == this.state.Data.Images[selectedImage].ID) {
                                                                                Img.PrimaryYN = 'Y';
                                                                            } else {
                                                                                Img.PrimaryYN = 'N';
                                                                            }
                                                                        });
                                                                        this.setState({ ...this.state, Data: { ...this.state.Data, Images }, selectedImage: 0 });
                                                                    }} className={classes.doneIcon}>
                                                                        <Icon className={this.state.Data.Images[selectedImage].PrimaryYN === 'Y' ? " text-yellow" : "text-white"}>{this.state.Data.Images[selectedImage].PrimaryYN === 'Y' ? 'star' : 'star_border-outline'}</Icon>
                                                                    </IconButton>
                                                                }
                                                                actionPosition="left"
                                                                className={classNames(classes.titleBar, "pt-12")}
                                                            />
                                                        </Dialog>
                                                    }
                                                </div> :
                                                <div className="w-full sm:mr-16 mb-0 cursor-pointer max-h-512">
                                                    <div onClick={() => this.toggleImageDialog(true)} className="w-full h-full rounded min-h-256 relative" style={{ border: '1px solid lightgrey' }}>
                                                        <Icon color="action" className="text-64 absolute pin-l pin-r pin-t pin-b m-auto">add_photo_alternate</Icon>
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="sm:hidden w-full mt-8 overflow-auto">
                                                <div className="flex pb-8">
                                                    {this.state.Data && this.state.Data.Images && this.state.Data.Images.map((value) => {
                                                        return (
                                                            <div key={value.ID || value.UploadID} onClick={() => this.setState({ ...this.state, Data: { ...this.state.Data, Image: value } })} className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 cursor-pointer" style={{ backgroundImage: `url(${value.ImgUrl})`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                        );
                                                    })}
                                                    {!estimate &&
                                                        <div className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 relative rounded" >
                                                            <Fab onClick={() => this.toggleImageDialog(true)} className="w-36 h-36 pin-l pin-r pin-t pin-b m-auto absolute" color="primary"><Icon className="text-20">add_photo_alternate</Icon></Fab>
                                                        </div>
                                                    }
                                                </div>
                                            </div> */}
                                            {this.state.Data && this.state.Data.Images &&
                                                <div className="mb-12 sm:mb-24">
                                                    <div className="w-full mt-8 overflow-auto">
                                                        <div className="flex pb-8">
                                                            {this.state.Data && this.state.Data.Images && _.filter(this.state.Data.Images, (o) => o.DeleteYN !== "Y").map((value) => {
                                                                return (
                                                                    <div key={value.ID || value.UploadID} onClick={() => this.setState({ ...this.state, Data: { ...this.state.Data, Image: value } })} className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 cursor-pointer" style={{ backgroundImage: `url(${value.ImgUrl})`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                                );
                                                            })}
                                                            {!estimate &&
                                                                <div className="min-w-48 max-w-48 min-h-48 max-h-48 mr-16 relative rounded" >
                                                                    <Fab onClick={() => this.toggleImageDialog(true)} className="w-36 h-36 pin-l pin-r pin-t pin-b m-auto absolute" color="primary"><Icon className="text-20">add_photo_alternate</Icon></Fab>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <div className="w-full sm:pt-0">
                                            {estimate &&
                                                <React.Fragment>
                                                    <div className="">
                                                        <Typography className="text-16 font-bold mb-4">Quantity</Typography>
                                                        <div className="flex mb-16">
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                onClick={this.decreaseQty}
                                                                className={classNames(classes.error, "p-0")}
                                                                style={{ minWidth: 38, minHeight: 'unset', height: 38 }}
                                                            >
                                                                <Icon>remove</Icon>
                                                            </Button>
                                                            <TextField
                                                                className="w-96 m-0 ml-8 mr-8"
                                                                id="qty"
                                                                name="Qty"
                                                                value={this.state.Qty || (component ? component.Qty : 1)}
                                                                margin="dense"
                                                                onChange={this.handleChange}
                                                                onBlur={this.handleBlur}
                                                                variant="outlined"
                                                                InputProps={{ type: 'number' }}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={this.increaseQty}
                                                                className="p-0"
                                                                style={{ minWidth: 38, minHeight: 'unset', height: 38 }}
                                                            >
                                                                <Icon>add</Icon>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <Divider className="mb-8" />
                                                </React.Fragment>
                                            }
                                            {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                <div className="flex">
                                                    <div className="mr-32">
                                                        <div>
                                                            <Typography className="text-16 font-bold mb-4" style={{ color: theme.palette.primary.main }}>Member Price</Typography>
                                                            {this.state.AddonYN !== "Y" ?
                                                                <Typography className="text-20 mb-16">{this.formatDollars(this.state.MemberPrice || 0.00)}</Typography>
                                                                :
                                                                <Typography className="text-20 mb-16">{this.formatDollars(this.state.AddOnMemberPrice || 0.00)}</Typography>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {estimate &&
                                                            <div>
                                                                <Typography className="text-16 font-bold mb-4">Price</Typography>
                                                                {this.state.AddonYN !== "Y" ?
                                                                    <Typography className="text-20 mb-16">{this.formatDollars(this.state.Price || 0.00)}</Typography>
                                                                    :
                                                                    <Typography className="text-20 mb-16">{this.formatDollars(this.state.AddOnPrice || 0.00)}</Typography>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            }
                                            {estimate && this.state.EditFromFieldYN === "Y" &&
                                                <div className="flex">
                                                    <div className="mr-16">
                                                        <div>
                                                            <Typography className="text-16 font-bold mb-4" style={{ color: theme.palette.primary.main }}>Member Price</Typography>
                                                            <TextField className="text-20 mb-16" onBlur={() => this.setState({ CustomMemberPrice: Number(this.state.CustomMemberPrice).toFixed(2) })} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} margin="dense" variant="outlined" value={this.state.CustomMemberPrice || ''} name="CustomMemberPrice" onChange={this.handleCustomMemberPriceChange} />
                                                        </div>
                                                    </div>
                                                    <div className="ml-16">
                                                        <div>
                                                            <Typography className="text-16 font-bold mb-4">Price</Typography>
                                                            <TextField className="text-20 mb-16" onBlur={() => this.setState({ CustomPrice: Number(this.state.CustomPrice).toFixed(2) })} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} margin="dense" variant="outlined" value={this.state.CustomPrice || ''} name="CustomPrice" onChange={this.handleCustomPriceChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                            }

                                            {!estimate &&
                                                <div className="w-full flex mb-12">
                                                    <TextField
                                                        className="w-full m-0 mr-12"
                                                        label={!estimate ? "Price" : undefined}
                                                        id="price"
                                                        name="Price"
                                                        value={this.state.Price || 0.00}
                                                        margin="dense"
                                                        onChange={this.handleChange}
                                                        variant={!estimate ? "outlined" : undefined}
                                                        required
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        helperText={this.state.Data.Calculation ? `Recommended Price: ${this.formatDollars(this.state.Data.Calculation.NewPrice)}` : undefined}
                                                        FormHelperTextProps={{
                                                            classes: {
                                                                root: "text-right text-10 mx-0 mt-2"
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        className="w-full m-0"
                                                        label={!estimate ? "Add-On Price" : undefined}
                                                        id="addOnPrice"
                                                        name="AddOnPrice"
                                                        value={this.state.AddOnPrice || 0.00}
                                                        margin="dense"
                                                        onChange={this.handleChange}
                                                        variant={!estimate ? "outlined" : undefined}
                                                        required
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        helperText={this.state.Data.Calculation ? `Recommended Price: ${this.formatDollars(this.state.Data.Calculation.NewAddOnPrice)}` : undefined}
                                                        FormHelperTextProps={{
                                                            classes: {
                                                                root: "text-right text-10 mx-0 mt-2"
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {!estimate &&
                                                <div className="w-full flex mb-12">
                                                    <TextField
                                                        className="w-full m-0 mr-12"
                                                        label={!estimate ? "Member Price" : undefined}
                                                        id="memberPrice"
                                                        name="MemberPrice"
                                                        value={this.state.MemberPrice || 0.00}
                                                        margin="dense"
                                                        onChange={this.handleChange}
                                                        variant={!estimate ? "outlined" : undefined}
                                                        required
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        helperText={this.state.Data.Calculation ? `Recommended Price: ${this.formatDollars(this.state.Data.Calculation.NewMemberPrice)}` : undefined}
                                                        FormHelperTextProps={{
                                                            classes: {
                                                                root: "text-right text-10 mx-0 mt-2"
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        className="w-full m-0"
                                                        label={!estimate ? "Member Add-On Price" : undefined}
                                                        id="memberAddOnPrice"
                                                        name="AddOnMemberPrice"
                                                        value={this.state.AddOnMemberPrice || 0.00}
                                                        margin="dense"
                                                        onChange={this.handleChange}
                                                        variant={!estimate ? "outlined" : undefined}
                                                        required
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        helperText={this.state.Data.Calculation ? `Recommended Price: ${this.formatDollars(this.state.Data.Calculation.NewMemberAddOnPrice)}` : undefined}
                                                        FormHelperTextProps={{
                                                            classes: {
                                                                root: "text-right text-10 mx-0 mt-2"
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {!estimate &&
                                                <div className="w-full flex mb-12">
                                                    <TextField
                                                        className="w-full m-0 mr-12"
                                                        label={!estimate ? "Premium Price" : undefined}
                                                        id="premiumPrice"
                                                        name="PremiumPrice"
                                                        value={this.state.PremiumPrice || 0.00}
                                                        margin="dense"
                                                        onChange={this.handleChange}
                                                        variant={!estimate ? "outlined" : undefined}
                                                        required
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        helperText={this.state.Data.Calculation ? `Recommended Price: ${this.formatDollars(this.state.Data.Calculation.NewPremiumPrice)}` : undefined}
                                                        FormHelperTextProps={{
                                                            classes: {
                                                                root: "text-right text-10 mx-0 mt-2"
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        className="w-full m-0"
                                                        label={!estimate ? "Premium Add-On Price" : undefined}
                                                        id="premiumAddOnPrice"
                                                        name="PremiumAddOnPrice"
                                                        value={this.state.PremiumAddOnPrice || 0.00}
                                                        margin="dense"
                                                        onChange={this.handleChange}
                                                        variant={!estimate ? "outlined" : undefined}
                                                        required
                                                        InputProps={{
                                                            type: 'number',
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        helperText={this.state.Data.Calculation ? `Recommended Price: ${this.formatDollars(this.state.Data.Calculation.NewPremiumAddOnPrice)}` : undefined}
                                                        FormHelperTextProps={{
                                                            classes: {
                                                                root: "text-right text-10 mx-0 mt-2"
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {!estimate && this.state.Data.Calculation &&
                                                <Button
                                                    className="w-full mb-24"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        const { NewPrice, NewAddOnPrice, NewMemberPrice, NewMemberAddOnPrice, NewPremiumPrice, NewPremiumAddOnPrice } = this.state.Data.Calculation;
                                                        this.setState({
                                                            ...this.state,
                                                            Price: Math.round(NewPrice),
                                                            AddOnPrice: Math.round(NewAddOnPrice),
                                                            MemberPrice: Math.round(NewMemberPrice),
                                                            AddOnMemberPrice: Math.round(NewMemberAddOnPrice),
                                                            PremiumPrice: Math.round(NewPremiumPrice),
                                                            PremiumAddOnPrice: Math.round(NewPremiumAddOnPrice),
                                                        });
                                                    }}
                                                >
                                                    <Icon className="mr-8">swap_vertical_circle</Icon>Apply Recommended Pricing
                                                </Button>
                                            }
                                            {estimate &&
                                                <Divider className="mb-8" />
                                            }

                                            {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                <div className="flex">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.AddonYN === 'Y'}
                                                                name="AddonYN"
                                                                onChange={this.handleChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Add-On Item"
                                                    />
                                                </div>
                                            }
                                            {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                <Divider className="mt-8 mb-8" />
                                            }
                                            {estimate && this.state.EditFromFieldYN !== "Y" &&
                                                <div>
                                                    <Typography className={classNames("text-16 font-bold", estimate ? "mb-16" : "mb-0")}>Description</Typography>
                                                    <Typography className="text-14">{parse(this.state.Description || '')}</Typography>
                                                </div>
                                            }
                                            {estimate && this.state.EditFromFieldYN === "Y" &&
                                                <div>
                                                    <Typography className={classNames("text-16 font-bold", estimate ? "mb-16" : "mb-0")}>Description</Typography>

                                                    <ReactQuill
                                                        ref={(el) => { this.quillRef = el; }}
                                                        theme="snow"
                                                        className="w-full mb-24"
                                                        value={this.state.Description || ''}
                                                        name="Description"
                                                        // readOnly={lockEditor}
                                                        modules={
                                                            {
                                                                toolbar: toolbarOptions,
                                                                history: history,
                                                                imageDrop: true,
                                                                imageResize: {
                                                                    parchment: Quill.import('parchment'),
                                                                    modules: ['Resize', 'DisplaySize', 'Toolbar']
                                                                }
                                                            }
                                                        }
                                                        formats={formats}
                                                        onChange={this.handleEditorChange}
                                                    />
                                                    {/* <TextField className="text-14 w-full" placeholder="Description" multiline rows={5} name="Description" value={this.state.Description || ''} variant="outlined" margin="dense" onChange={this.handleChange} /> */}
                                                </div>
                                            }
                                            {this.state.Data.Recommendations.length > 0 && estimate &&
                                                <Button
                                                    className={classNames("text-12 sm:text-14 font-bold w-full mt-24")}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => this.setState({ showRecommendations: true })}
                                                >
                                                    <Icon className="mr-8">style</Icon>View Recommendations
                                                </Button>
                                            }
                                            {!estimate &&
                                                <React.Fragment>
                                                    <div style={{ marginTop: 0, marginBottom: -11 }}>
                                                        <label class="text-grey-dark text-12 ml-12 px-4 bg-white">Description *</label>
                                                    </div>
                                                    <ReactQuill
                                                        ref={(el) => { this.quillRef = el; }}
                                                        theme="snow"
                                                        className="w-full mb-16"
                                                        value={this.state.Description || ''}
                                                        name="Description"
                                                        // readOnly={lockEditor}
                                                        modules={
                                                            {
                                                                toolbar: toolbarOptions,
                                                                history: history,
                                                                imageDrop: true,
                                                                imageResize: {
                                                                    parchment: Quill.import('parchment'),
                                                                    modules: ['Resize', 'DisplaySize', 'Toolbar']
                                                                }
                                                            }
                                                        }
                                                        formats={formats}
                                                        onChange={this.handleEditorChange}
                                                    />
                                                </React.Fragment>
                                                // <TextField
                                                //     label="Description"
                                                //     className="w-full mt-0 mb-0"
                                                //     id="description"
                                                //     name="Description"
                                                //     value={this.state.Description || ''}
                                                //     onChange={this.handleChange}
                                                //     margin="dense"
                                                //     variant="outlined"
                                                //     InputProps={{
                                                //         // disableUnderline: true,
                                                //         // className: "p-0"
                                                //     }}
                                                //     inputProps={{
                                                //         className: "text-14"
                                                //     }}
                                                //     multiline
                                                //     rows={8}
                                                // />

                                            }
                                            {!estimate &&
                                                <TextField
                                                    className="w-full mb-24"
                                                    label="Assembly Code"
                                                    id="assemblyCode"
                                                    name="Code"
                                                    value={this.state.Code || ''}
                                                    margin="dense"
                                                    onChange={this.handleChange}
                                                    variant={!estimate ? "outlined" : undefined}
                                                />
                                            }
                                        </div>
                                    </div>
                                    {estimate && _.filter(this.state.Data.Upgrades, (o) => { return o.DeleteYN !== 'Y' }).length > 0 &&
                                        <div className="w-full mt-24">
                                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 mb-6 sm:mb-12 relative"><Icon color="primary" className="text-24 mr-8">loyalty</Icon>Alternative Tasks</Typography>
                                            <div className="w-full mb-24 rounded-4 p-8 min-h-48" style={{ border: '1px dotted lightgrey', background: '#f1f1f1' }}>
                                                <div className="w-full flex overflow-x-auto p-12">
                                                    {this.state.Data.Upgrades && _.filter(this.state.Data.Upgrades, (o) => { return o.DeleteYN !== 'Y' }).map((value, index) => {
                                                        const { Co, BusinessUnit, Division, Assembly, UpgradedAssembly } = value;
                                                        const cat = _.find(tasks, { Co, BusinessUnit, Division, Assembly: UpgradedAssembly });
                                                        if (cat) {
                                                            if (cat && cat.Data && cat.Data.Images && cat.Data.Images.length > 0) {
                                                                cat.Data.Image = _.find(cat.Data.Images, { PrimaryYN: 'Y' });
                                                                if (!cat.Data.Image) {
                                                                    cat.Data.Image = cat.Data.Images[0];
                                                                }
                                                            }
                                                            return (
                                                                <Card onClick={() => this.props.openEditCatalogItemDialog({ ...cat }, estimate, this.state.option)} key={UpgradedAssembly} className={index < (this.state.Data.Upgrades.length - 1) ? "mr-24 max-w-160 min-w-160 cursor-pointer relative rounded-lg" : "max-w-160 min-w-160 cursor-pointer relative rounded-lg"}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="auto"
                                                                        image={cat.Data.Image.ImgUrl}
                                                                        alt={cat.Name}
                                                                    />
                                                                    <CardContent className="mb-48">
                                                                        <Typography className="font-bold mb-8 text-12">
                                                                            {cat.Name}
                                                                        </Typography>
                                                                        {/* <Typography variant="caption" className="max-h-96 overflow-auto mb-8">
                                                                            {parse(cat.Description)}
                                                                        </Typography> */}
                                                                        {/* <label className="text-10">Task #{cat.PRCode || `${cat.Assembly}`}</label> */}
                                                                    </CardContent>
                                                                    <CardActions className="justify-between px-4 py-0 pin-b pin-l pin-r absolute" style={{ borderTop: '1px solid lightgrey' }}>
                                                                        <div className="font-bold text-14 ml-16">{this.formatDollars(cat.Price)}</div>
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                var rec = cat;
                                                                                const {
                                                                                    Co,
                                                                                    BusinessUnit,
                                                                                    Division,
                                                                                    Assembly,
                                                                                    TaxableYN,
                                                                                    AddonYN
                                                                                } = rec;
                                                                                const {
                                                                                    Estimate,
                                                                                    OptionBoard,
                                                                                    Option
                                                                                } = estimate;
                                                                                estimate.Data.Components.push({
                                                                                    Co,
                                                                                    BusinessUnit,
                                                                                    Division,
                                                                                    Estimate,
                                                                                    OptionBoard,
                                                                                    Option,
                                                                                    Component: estimate.Data.Components.length + 1,
                                                                                    Type: 0,
                                                                                    Qty: 1,
                                                                                    UOM: 'EA',
                                                                                    Assembly,
                                                                                    Price: Number(cat.Price) || 0,
                                                                                    PriceMethod: 'F',
                                                                                    TaxableYN,
                                                                                    AddonYN,
                                                                                    Data: {
                                                                                        Assembly: rec,
                                                                                    }
                                                                                });
                                                                                // window["warn"](estimate);
                                                                                this.toggleAssemblies(true, true);
                                                                                // updateCatalogItem(rec);
                                                                            }} aria-label="add to estimate">
                                                                            <Icon color="primary">add_circle_outline</Icon>
                                                                        </IconButton>
                                                                    </CardActions>
                                                                </Card>
                                                            );
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* <Divider className="mb-24 mt-8" /> */}
                                    {!estimate &&
                                        <div>
                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">notes</Icon>
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Task Detail"
                                                    id="detail"
                                                    name="Detail"
                                                    value={this.state.Detail}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                >
                                                </TextField>
                                            </div>
                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">build</Icon>
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Department"
                                                    options={departments ? _.filter(departments, { BusinessUnit: this.state.BusinessUnit, Division: this.state.Division }) : []}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.Department}>
                                                            {value.Description}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.Department || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, Department: option.Department })}
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Service Type"
                                                    options={serviceTypes}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.ServiceType}>
                                                            {`${value.Description}`}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.ServiceType || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, ServiceType: option.ServiceType })}
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Service"
                                                    options={services}
                                                    menuItemComponent={(value) => {
                                                        return value.ActiveYN === "Y" &&
                                                            <MenuItem value={value.Service}>
                                                                {`${value.Service} - ${value.Description}`}
                                                            </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.Service || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, Service: option.Service })}
                                                />
                                            </div>
                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">payment</Icon>
                                                </div>

                                                {/* <Autocomplete
                                                className={classes.formControl}
                                                title="Price Method"
                                                options={[{ value: 'T', name: 'Rate Template' }, { value: 'F', name: 'Flat Price' }]}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.value}>
                                                        {`${value.name}`}
                                                    </MenuItem>
                                                }}
                                                // selectOnly={true}
                                                portal={true}
                                                value={this.state.PriceMethod}
                                                onSelect={(option) => this.setState({ ...this.state, PriceMethod: option.value })}
                                                required
                                            /> */}
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Component Price Method"
                                                    id="priceMethod"
                                                    name="PriceMethod"
                                                    value={this.state.PriceMethod || 'T'}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    select
                                                    fullWidth
                                                >
                                                    <MenuItem value="T">
                                                        Rate Template
                                                    </MenuItem>
                                                    <MenuItem value="F">
                                                        Flat Price
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Component Rate Template"
                                                    options={rateTemplates ? [{ RateTemplate: null, Description: "Default", ActiveYN: 'Y' }, ..._.filter(rateTemplates, (o) => o.BusinessUnit === this.state.BusinessUnit && (this.state.Division === '*' || o.Division === this.state.Division))] : [{ RateTemplate: null, Description: "Default", ActiveYN: 'Y' }]}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.RateTemplate}>
                                                            {`${value.Description}`}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.RateTemplate}
                                                    onSelect={(option) => this.setState({ ...this.state, RateTemplate: option.RateTemplate, RateTemplateDivision: option.Division })}
                                                />
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                {/* <Autocomplete
                                                className={classes.formControl}
                                                title="Taxable?"
                                                options={[{ value: 'N', name: 'No' }, { value: 'Y', name: 'Yes' }]}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.value}>
                                                        {`${value.name}`}
                                                    </MenuItem>
                                                }}
                                                // selectOnly={true}
                                                portal={true}
                                                value={this.state.TaxableYN}
                                                onSelect={(option) => this.setState({ ...this.state, TaxableYN: option.value })}
                                                required
                                            /> */}
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Components Taxable"
                                                    id="taxableYN"
                                                    name="TaxableYN"
                                                    value={this.state.TaxableYN || 'Y'}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    select
                                                >
                                                    <MenuItem value="N">
                                                        No
                                                    </MenuItem>
                                                    <MenuItem value="Y">
                                                        Yes
                                                    </MenuItem>
                                                </TextField>
                                            </div>
                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">apps</Icon>
                                                </div>
                                                <Autocomplete
                                                    className={classNames(classes.formControl, "mb-4 sm:mb-12")}
                                                    title="Assigned Categories"
                                                    options={_.filter(categories, { Co: this.state.Co, BusinessUnit: this.state.BusinessUnit, Division: this.state.Division, Type: 'T' })}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.Category}>
                                                            {`${value.Name}`}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.Category || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, Category: option.Category })}
                                                />
                                                {this.state.Category && this.state.Data.Assignments && _.findIndex(this.state.Data.Assignments, { Co: this.state.Co, BusinessUnit: this.state.BusinessUnit, Division: this.state.Division, Category: this.state.Category }) > -1 ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classNames(classes.error, "w-full sm:w-128 sm:ml-12 mb-12")}
                                                        disabled={!this.state.Category}
                                                        onClick={() => {
                                                            const { Co, BusinessUnit, Division, Category, Data } = this.state;
                                                            let { Assignments } = Data;
                                                            if (Assignments) {
                                                                const assignment = _.find(Assignments, { Co, BusinessUnit, Division, Category });
                                                                const index = _.findIndex(Assignments, { ID: null, Co, BusinessUnit, Division, Category });
                                                                if (index > -1) {
                                                                    Assignments.splice(index, 1);
                                                                } else {
                                                                    if (assignment) {
                                                                        assignment.DeleteYN = 'Y';
                                                                    }
                                                                }
                                                                this.setState({ ...this.state, Category: null, Data: { ...Data, Assignments } });
                                                            }
                                                        }}
                                                    >
                                                        Remove
                                                    </Button> :
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className="w-full sm:w-128 sm:ml-12 mb-12"
                                                        disabled={!this.state.Category}
                                                        onClick={() => {
                                                            const { Co, BusinessUnit, Division, Category, Data } = this.state;
                                                            let { Assignments } = Data;
                                                            if (!Assignments) {
                                                                Assignments = [];
                                                            }
                                                            if (Category) {
                                                                Assignments.push({
                                                                    Co,
                                                                    BusinessUnit,
                                                                    Division,
                                                                    Category
                                                                });
                                                            }
                                                            this.setState({ ...this.state, Category: null, Data: { ...Data, Assignments } });
                                                        }}
                                                    >
                                                        Add
                                                    </Button>

                                                }
                                            </div>
                                            <div className="sm:ml-48 flex">
                                                <div className="w-full mb-24 rounded-4 p-8 min-h-48" style={{ border: '1px dotted lightgrey', background: '#f1f1f1' }}>
                                                    {this.state.Data.Assignments && _.filter(this.state.Data.Assignments, (o) => { return o.DeleteYN !== 'Y' }).map((value) => {
                                                        const { Co, BusinessUnit, Division, Category } = value;
                                                        const cat = _.find(categories, { Co, BusinessUnit, Division, Category });
                                                        if (cat) {
                                                            return (
                                                                <Chip key={Category} onClick={() => this.setState({ ...this.state, Category })} className="mr-8 my-4" color="primary" icon={<Icon>apps</Icon>} label={cat.Name} />
                                                            );
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </div>

                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                                    <Icon color="action">note</Icon>
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Notes"
                                                    id="notes"
                                                    name="Notes"
                                                    value={this.state.Notes}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="justify-start">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.ActiveYN === 'Y'}
                                                                name="ActiveYN"
                                                                onChange={this.handleChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Active"
                                                    />
                                                </div>
                                                <div className="sm:justify-end">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.EditFromFieldYN === 'Y'}
                                                                name="EditFromFieldYN"
                                                                onChange={this.handleChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Customizable"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </DialogContent>
                        }

                        {value === 1 &&
                            <DialogContent className={!estimate ? "p-24 pb-48" : "p-12 pb-48"}>
                                <CatalogAssemblyComponentsList components={this.state.Data.Components || []} assembly={{ ...this.state }} />
                            </DialogContent>
                        }

                        {value === 2 &&
                            <DialogContent className={!estimate ? "p-24 pb-48" : "p-12 px-24 pb-48"}>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 sm:text-20 mb-6 sm:mb-12 relative"><Icon color="primary" className="text-32 mr-12">style</Icon>Recommended Tasks</Typography>
                                <div className="sm:flex">
                                    <Autocomplete
                                        className={classNames(classes.formControl, "mb-4 sm:mb-12")}
                                        title="Recommended Task"
                                        options={_.filter(_.filter(tasks, { Co: this.state.Co, BusinessUnit: this.state.BusinessUnit, Division: this.state.Division }), (o) => { const { Assembly, Name, PRCode } = o; if (Assembly !== this.state.Assembly) return { Assembly, Name, PRCode } })}
                                        menuItemComponent={(value) => {
                                            return <MenuItem disabled={_.findIndex(this.state.Data.Recommendations, { RecommendedAssembly: value.Assembly }) > -1} value={value.Assembly}>
                                                {`${value.PRCode || value.Assembly} - ${value.Name}`}
                                            </MenuItem>
                                        }}
                                        portal={true}
                                        minSearch={3}
                                        value={this.state.RecommendedAssembly || ''}
                                        onSelect={(option) => this.setState({ ...this.state, RecommendedAssembly: option.Assembly })}
                                    />
                                    {this.state.RecommendedAssembly && this.state.Data.Recommendations && _.findIndex(this.state.Data.Recommendations, { Co: this.state.Co, BusinessUnit: this.state.BusinessUnit, Division: this.state.Division, RecommendedAssembly: this.state.RecommendedAssembly }) > -1 ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classNames(classes.error, "w-full sm:w-128 sm:ml-12 mb-12")}
                                            disabled={!this.state.RecommendedAssembly}
                                            onClick={() => {
                                                const { Co, BusinessUnit, Division, Assembly, Data } = this.state;
                                                let { Recommendations } = Data;
                                                if (Recommendations) {
                                                    const recommendation = _.find(Recommendations, { Co, BusinessUnit, Division, RecommendedAssembly: RecommendedAssembly });
                                                    const index = _.findIndex(Recommendations, { ID: null, Co, BusinessUnit, Division, RecommendedAssembly: RecommendedAssembly });
                                                    if (index > -1) {
                                                        Recommendations.splice(index, 1);
                                                    } else {
                                                        if (recommendation) {
                                                            recommendation.DeleteYN = 'Y';
                                                        }
                                                    }
                                                    this.setState({ ...this.state, RecommendedAssembly: null, Data: { ...Data, Recommendations } });
                                                }
                                            }}
                                        >
                                            Remove
                                        </Button> :
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="w-full sm:w-128 sm:ml-12 mb-12"
                                            disabled={!this.state.RecommendedAssembly}
                                            onClick={() => {
                                                const { Co, BusinessUnit, Division, Assembly, RecommendedAssembly, Data } = this.state;
                                                let { Recommendations } = Data;
                                                if (!Recommendations) {
                                                    Recommendations = [];
                                                }
                                                if (Assembly) {
                                                    Recommendations.push({
                                                        Co,
                                                        BusinessUnit,
                                                        Division,
                                                        Assembly,
                                                        RecommendedAssembly
                                                    });
                                                }
                                                this.setState({ ...this.state, RecommendedAssembly: null, Data: { ...Data, Recommendations } });
                                            }}
                                        >
                                            Add
                                        </Button>

                                    }
                                </div>
                                <div className="flex">
                                    <div className="w-full mb-24 rounded-4 p-8 min-h-48" style={{ border: '1px dotted lightgrey', background: '#f1f1f1' }}>
                                        {this.state.Data.Recommendations && _.filter(this.state.Data.Recommendations, (o) => { return o.DeleteYN !== 'Y' }).map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, RecommendedAssembly } = value;
                                            const cat = _.find(tasks, { Co, BusinessUnit, Division, Assembly: RecommendedAssembly });
                                            if (cat) {
                                                if (cat && cat.Data && cat.Data.Images && cat.Data.Images.length > 0) {
                                                    cat.Data.Image = _.find(cat.Data.Images, { PrimaryYN: 'Y' });
                                                    if (!cat.Data.Image) {
                                                        cat.Data.Image = cat.Data.Images[0];
                                                    }
                                                }
                                                return (
                                                    <Card onClick={() => this.setState({ ...this.state, RecommendedAssembly })} key={RecommendedAssembly} className={index < (this.state.Data.Recommendations.length - 1) && "mb-12"}>
                                                        <CardHeader
                                                            classes={{ action: "mr-12 mt-16 font-bold", root: "p-8 pl-16 cursor-pointer", avatar: "mr-12", title: "leading-none", subheader: "leading-none" }}
                                                            avatar={
                                                                cat.Data.Image &&
                                                                <img src={cat.Data.Image.ImgUrl} className="w-48 h-48 rounded" />
                                                            }
                                                            title={<label className="text-12 font-bold">{cat.Name}</label>}
                                                            subheader={<label className="text-10">Task #{cat.PRCode || `${cat.Assembly}`}</label>}
                                                            action={
                                                                `${this.formatDollars(cat.Price)}`
                                                            }
                                                        />
                                                    </Card>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                            </DialogContent>
                        }

                        {value === 3 &&
                            <DialogContent className={!estimate ? "p-24 pb-48" : "p-12 px-24 pb-48"}>
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 sm:text-20 mb-6 sm:mb-12 relative"><Icon color="primary" className="text-32 mr-12">loyalty</Icon>Alternative Tasks</Typography>
                                <div className="sm:flex">
                                    <Autocomplete
                                        className={classNames(classes.formControl, "mb-4 sm:mb-12")}
                                        title="Alternative Task"
                                        options={_.filter(_.filter(tasks, { Co: this.state.Co, BusinessUnit: this.state.BusinessUnit, Division: this.state.Division }), (o) => { const { Assembly, Name, PRCode } = o; if (Assembly !== this.state.Assembly) return { Assembly, Name, PRCode } })}
                                        menuItemComponent={(value) => {
                                            return <MenuItem disabled={_.findIndex(this.state.Data.Upgrades, { UpgradedAssembly: value.Assembly }) > -1} value={value.Assembly}>
                                                {`${value.PRCode || value.Assembly} - ${value.Name}`}
                                            </MenuItem>
                                        }}
                                        portal={true}
                                        minSearch={3}
                                        value={this.state.UpgradedAssembly || ''}
                                        onSelect={(option) => this.setState({ ...this.state, UpgradedAssembly: option.Assembly })}
                                    />
                                    {this.state.UpgradedAssembly && this.state.Data.Upgrades && _.findIndex(this.state.Data.Upgrades, { Co: this.state.Co, BusinessUnit: this.state.BusinessUnit, Division: this.state.Division, UpgradedAssembly: this.state.UpgradedAssembly }) > -1 ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classNames(classes.error, "w-full sm:w-128 sm:ml-12 mb-12")}
                                            disabled={!this.state.Assembly}
                                            onClick={() => {
                                                const { Co, BusinessUnit, Division, Assembly, Data } = this.state;
                                                let { Upgrades } = Data;
                                                if (Upgrades) {
                                                    const recommendation = _.find(Upgrades, { Co, BusinessUnit, Division, UpgradedAssembly: UpgradedAssembly });
                                                    const index = _.findIndex(Upgrades, { ID: null, Co, BusinessUnit, Division, UpgradedAssembly: UpgradedAssembly });
                                                    if (index > -1) {
                                                        Upgrades.splice(index, 1);
                                                    } else {
                                                        if (recommendation) {
                                                            recommendation.DeleteYN = 'Y';
                                                        }
                                                    }
                                                    this.setState({ ...this.state, UpgradedAssembly: null, Data: { ...Data, Upgrades } });
                                                }
                                            }}
                                        >
                                            Remove
                                        </Button> :
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="w-full sm:w-128 sm:ml-12 mb-12"
                                            disabled={!this.state.UpgradedAssembly}
                                            onClick={() => {
                                                const { Co, BusinessUnit, Division, Assembly, UpgradedAssembly, Data } = this.state;
                                                let { Upgrades } = Data;
                                                if (!Upgrades) {
                                                    Upgrades = [];
                                                }
                                                if (Assembly) {
                                                    Upgrades.push({
                                                        Co,
                                                        BusinessUnit,
                                                        Division,
                                                        Assembly,
                                                        UpgradedAssembly
                                                    });
                                                }
                                                this.setState({ ...this.state, UpgradedAssembly: null, Data: { ...Data, Upgrades } });
                                            }}
                                        >
                                            Add
                                        </Button>

                                    }
                                </div>
                                <div className="flex">
                                    <div className="w-full mb-24 rounded-4 p-8 min-h-48" style={{ border: '1px dotted lightgrey', background: '#f1f1f1' }}>
                                        {this.state.Data.Upgrades && _.filter(this.state.Data.Upgrades, (o) => { return o.DeleteYN !== 'Y' }).map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, UpgradedAssembly } = value;
                                            const cat = _.find(tasks, { Co, BusinessUnit, Division, Assembly: UpgradedAssembly });
                                            if (cat) {
                                                if (cat && cat.Data && cat.Data.Images && cat.Data.Images.length > 0) {
                                                    cat.Data.Image = _.find(cat.Data.Images, { PrimaryYN: 'Y' });
                                                    if (!cat.Data.Image) {
                                                        cat.Data.Image = cat.Data.Images[0];
                                                    }
                                                }
                                                return (
                                                    <Card onClick={() => this.setState({ ...this.state, UpgradedAssembly })} key={UpgradedAssembly} className={index < (this.state.Data.Upgrades.length - 1) && "mb-12"}>
                                                        <CardHeader
                                                            classes={{ action: "mr-12 mt-16 font-bold", root: "p-8 pl-16 cursor-pointer", avatar: "mr-12", title: "leading-none", subheader: "leading-none" }}
                                                            avatar={
                                                                cat.Data.Image &&
                                                                <img src={cat.Data.Image.ImgUrl} className="w-48 h-48 rounded" />
                                                            }
                                                            title={<label className="text-12 font-bold">{cat.Name}</label>}
                                                            subheader={<label className="text-10">Task #{cat.PRCode || `${cat.Assembly}`}</label>}
                                                            action={
                                                                `${this.formatDollars(cat.Price)}`
                                                            }
                                                        />
                                                    </Card>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                            </DialogContent>
                        }
                        {(value === 0 && !{ ...this.state }.ID) &&
                            <DialogActions className="dialog-actions justify-between pl-8 pr-8 pin-l pin-r pin-b absolute h-48 m-0 bg-white z-10">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.setState({ ...this.state, saving: true }, () => {
                                            var rec = this.state;
                                            Promise.all([
                                                addAssembly(rec)
                                            ]).then(() => this.setState({ ...this.state, saving: false }, () => this.closeComposeDialog()));

                                        })
                                    }}
                                    disabled={!this.canBeSubmitted() || saving}
                                >
                                    <React.Fragment>
                                        {saving ? <CircularProgress color="primary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8">cloud_upload</Icon>}
                                        {saving ? 'Adding' : `Add`}
                                    </React.Fragment>
                                </Button>
                            </DialogActions>
                        }
                        {(value === 0 && ['new', 'customer'].indexOf(catalogItemDialog.type) < 0) && estimate && estimate.Data && estimate.Data.Components && this.state.ActiveYN !== 'N' &&
                            <DialogActions className="dialog-actions justify-between pl-8 pr-8 pin-l pin-r pin-b absolute h-48 m-0 bg-white z-10">
                                <div className="flex">
                                    <Typography color="secondary" className="text-10 sm:text-16 font-bold mr-8">Item Subtotal:</Typography>
                                    <Typography className="text-10 sm:text-16 font-bold">{this.formatDollars(!component ? (Number(this.state.Qty || 1) * Number(this.state.AddonYN === 'Y' ? isMember ? this.state.AddOnMemberPrice : this.state.AddOnPrice : isMember ? this.state.EditFromFieldYN === "Y" ? this.state.CustomMemberPrice : this.state.MemberPrice : this.state.EditFromFieldYN === "Y" ? this.state.CustomPrice : this.state.Price) || 0) : !this.state.Qty ? component.Price : (Number(this.state.Qty) * Number(this.state.AddonYN === 'Y' ? isMember ? this.state.AddOnMemberPrice : this.state.AddOnPrice : isMember ? this.state.EditFromFieldYN === "Y" ? this.state.CustomMemberPrice : this.state.MemberPrice : this.state.EditFromFieldYN === "Y" ? this.state.CustomPrice : this.state.Price)))}</Typography>
                                </div>
                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        var rec = this.state;
                                        updateCatalogItem(rec);
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                        </Button> */}
                                {!component && this.state.ActiveYN !== 'N' &&
                                    <Button
                                        className={classNames(classes.black, "text-12 sm:text-14 font-bold")}
                                        style={{ borderRadius: 24, paddingLeft: 8 }}
                                        variant="contained"
                                        // color="primary"
                                        onClick={() => {
                                            var rec = _.cloneDeepWith(this.state);
                                            delete rec.Data.Upgrades;
                                            delete rec.Data.Recommendations;
                                            delete rec.Data.Components;
                                            delete rec.Data.Calculation;
                                            // delete rec.Data.Images;
                                            delete rec.Data.Assignments;
                                            const { Components } = _.cloneDeepWith(estimate.Data);
                                            const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Qty,
                                                Name,
                                                Description,
                                                Assembly,
                                                TaxableYN,
                                                AddonYN
                                            } = rec;
                                            const {
                                                Estimate,
                                                OptionBoard,
                                                Option
                                            } = estimate;
                                            Components.push({
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Estimate,
                                                OptionBoard,
                                                Option,
                                                Component: Components.length + 1,
                                                Type: 0,
                                                Qty: Qty || 1,
                                                UOM: 'EA',
                                                Assembly,
                                                Name,
                                                Description,
                                                Price: Number(this.state.Qty || 1) * Number(this.state.EditFromFieldYN === "Y" ? (isMember ? this.state.CustomMemberPrice : this.state.CustomPrice) : this.state.AddonYN === "Y" ? (isMember ? this.state.AddOnMemberPrice : this.state.AddOnPrice) : this.state.Price) || 0,
                                                CustomPrice: this.state.CustomPrice,
                                                CustomMemberPrice: this.state.CustomMemberPrice,
                                                PriceMethod: 'F',
                                                TaxableYN,
                                                AddonYN,
                                                Data: {
                                                    Assembly: rec,
                                                }
                                            });
                                            if (this.props.onUpdated) {
                                                this.props.onUpdated(Components);
                                            }
                                            // window["warn"](estimate);
                                            this.toggleAssemblies(true, true);
                                            // updateCatalogItem(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        <SPRoundIcon className={classNames(classes.colorSecondary, "text-24 mr-8")} /> Add {estimate.Option ? 'Component' : 'to Estimate'}
                                    </Button>
                                }
                                {component && this.state.ActiveYN !== 'N' &&
                                    <div className="flex">
                                        <Button
                                            className={classNames(classes.black, "text-12 sm:text-14 font-bold m-0")}
                                            style={{ borderRadius: 24, paddingLeft: 8, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                            variant="contained"
                                            // color="primary"
                                            onClick={() => {
                                                var rec = _.cloneDeepWith(this.state);
                                                delete rec.Data.Upgrades;
                                                delete rec.Data.Recommendations;
                                                delete rec.Data.Components;
                                                delete rec.Data.Calculation;
                                                // delete rec.Data.Images;
                                                delete rec.Data.Assignments;
                                                const {
                                                    Qty,
                                                    TaxableYN,
                                                    Name,
                                                    Description,
                                                    AddonYN
                                                } = rec;
                                                component = {
                                                    ...component,
                                                    Qty,
                                                    Name,
                                                    Description,
                                                    Price: Number(this.state.Qty || (component ? component.Qty : 1)) * Number(this.state.EditFromFieldYN === "Y" ? (isMember ? this.state.CustomMemberPrice : this.state.CustomPrice) : this.state.AddonYN === "Y" ? (isMember ? this.state.AddOnMemberPrice : this.state.AddOnPrice) : this.state.Price) || 0,
                                                    // Price: Number(this.state.Qty || (component ? component.Qty : 1)) * Number(this.state.AddonYN === 'Y' ? this.state.AddOnPrice : isMember ? this.state.EditFromFieldYN === "Y" ? this.state.CustomMemberPrice : this.state.MemberPrice : this.state.Price) || 0,
                                                    CustomPrice: this.state.CustomPrice,
                                                    CustomMemberPrice: this.state.CustomMemberPrice,
                                                    PriceMethod: 'F',
                                                    TaxableYN,
                                                    AddonYN,
                                                }
                                                const { Components } = _.cloneDeepWith(estimate.Data);
                                                const index = _.findIndex(Components, { Component: component.Component });
                                                Components.splice(index, 1, component);
                                                if (this.props.onUpdated) {
                                                    this.props.onUpdated(Components);
                                                }
                                                // window["warn"](estimate);
                                                this.toggleAssemblies(true);
                                                // updateCatalogItem(rec);
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            <SPRoundIcon className={classNames(classes.colorSecondary, "text-24 mr-8")} /> Update {estimate.Option ? 'Component' : 'Estimate'}
                                        </Button>
                                        <Button
                                            className={classNames(classes.error, "text-12 sm:text-14 font-bold m-0")}
                                            style={{ minWidth: 36, width: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                            variant="contained"
                                            // color="primary"
                                            onClick={() => {
                                                const { Components } = _.cloneDeepWith(estimate.Data);
                                                const index = _.findIndex(Components, { Component: component.Component });
                                                if (index > -1) {
                                                    const comp = _.find(Components, { Component: component.Component });
                                                    if (comp.ID) {
                                                        comp.DeleteYN = 'Y';
                                                        Components.splice(index, 1, comp);
                                                    } else {
                                                        Components.splice(index, 1);
                                                    }
                                                    if (this.props.onUpdated) {
                                                        this.props.onUpdated(Components);
                                                    }
                                                }

                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            <Icon className={classNames("text-24")}>delete</Icon>
                                        </Button>
                                    </div>
                                }
                            </DialogActions>
                        }
                        {{ ...this.state }.ID && !estimate &&
                            <DialogActions className="dialog-actions justify-between pl-8 pr-8 pin-l pin-r pin-b absolute h-48 m-0 bg-white z-10">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.setState({ ...this.state, saving: true }, () => {
                                            Promise.all([
                                                updateAssembly(this.state)
                                            ]).then(() => this.setState({ ...this.state, saving: false }));
                                        })
                                    }}
                                    disabled={!this.canBeSubmitted() || saving}
                                >
                                    <React.Fragment>
                                        {saving ? <CircularProgress color="primary" className="mr-12" style={{ width: 20, height: 20 }} /> : <Icon className="mr-8">cloud_upload</Icon>}
                                        {saving ? 'Updating' : `Update`}
                                    </React.Fragment>
                                </Button>
                            </DialogActions>
                        }
                        {showRecommendations && estimate && _.filter(this.state.Data.Recommendations, (o) => { return o.DeleteYN !== 'Y' }).length > 0 &&
                            <React.Fragment>
                                <FuseAnimate animation="transition.fadeIn" delay={300}>
                                    <div onClick={(e) => { e.stopPropagation(); this.setState({ showRecommendations: false }); }} className={classNames("pin-b pin-t pin-l pin-r absolute", classes.backdrop)}></div>
                                </FuseAnimate>
                                <FuseAnimate animation="transition.slideUpBigIn" delay={300}>
                                    <div className={classNames("pin-b pin-l pin-r absolute m-8", classes.recommendations)}>
                                        <div onClick={(e) => e.stopPropagation()} className="w-full mt-24 bg-white rounded-lg p-12 px-24" style={{ border: '1px solid lightgrey', boxShadow: '1px 2px 4px 2px rgb(0,0,0,.25)' }}>
                                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex text-16 mb-6 sm:mb-12 relative"><Icon color="primary" className="text-24 mr-8">style</Icon>Also Recommended
                                                <IconButton
                                                    className="pin-r absolute"
                                                    style={{ marginRight: -8 }}
                                                    aria-label="close"
                                                    onClick={(e) => { e.stopPropagation(); this.setState({ showRecommendations: false }); }}
                                                >
                                                    <Icon color="action">close</Icon>
                                                </IconButton>
                                            </Typography>
                                            <div className="w-full rounded-lg p-8 min-h-48" style={{ border: '1px dotted lightgrey', background: '#f1f1f1' }}>
                                                <div className="w-full flex overflow-x-auto p-8">
                                                    {this.state.Data.Recommendations && _.filter(this.state.Data.Recommendations, (o) => { return o.DeleteYN !== 'Y' }).map((value, index) => {
                                                        const { Co, BusinessUnit, Division, Assembly, RecommendedAssembly } = value;
                                                        const cat = _.find(tasks, { Co, BusinessUnit, Division, Assembly: RecommendedAssembly });
                                                        const added = _.findIndex(estimate.Data.Components, { Assembly: cat.Assembly });
                                                        if (cat) {
                                                            if (cat && cat.Data && cat.Data.Images && cat.Data.Images.length > 0) {
                                                                cat.Data.Image = _.find(cat.Data.Images, { PrimaryYN: 'Y' });
                                                                if (!cat.Data.Image) {
                                                                    cat.Data.Image = cat.Data.Images[0];
                                                                }
                                                            }
                                                            return (
                                                                <Card key={RecommendedAssembly} className={index < (this.state.Data.Recommendations.length - 1) ? "mr-24 max-w-160 min-w-160 cursor-pointer relative rounded-lg" : "max-w-160 min-w-160 cursor-pointer relative rounded-lg"}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="auto"
                                                                        image={cat.Data.Image.ImgUrl}
                                                                        alt={cat.Name}
                                                                        onClick={() => this.props.openEditCatalogItemDialog({ ...cat }, estimate, this.state.option)}
                                                                    />
                                                                    <CardContent onClick={() => this.props.openEditCatalogItemDialog({ ...cat }, estimate, this.state.option)} className="mb-48 px-12 py-4">
                                                                        <Typography className="font-bold mb-8 text-12">
                                                                            {cat.Name}
                                                                        </Typography>
                                                                        {/* <Typography variant="caption" className="max-h-96 overflow-auto mb-8">
                                                                {parse(cat.Description)}
                                                            </Typography> */}
                                                                        {/* <label className="text-10">Task #{cat.PRCode || `${cat.Assembly}`}</label> */}
                                                                    </CardContent>
                                                                    <CardActions className="justify-between px-4 py-0 pin-b pin-l pin-r absolute" style={{ borderTop: '1px solid lightgrey' }}>
                                                                        <div className="font-bold text-14 ml-16">{this.formatDollars(cat.Price)}</div>
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                if (added > -1) {
                                                                                    const comp = estimate.Data.Components[added];
                                                                                    if (comp.ID) {
                                                                                        comp.DeleteYN = 'Y';
                                                                                        estimate.Data.Components.splice(added, 1, comp);
                                                                                    } else {
                                                                                        estimate.Data.Components.splice(added, 1);
                                                                                    }
                                                                                } else {
                                                                                    var rec = cat;
                                                                                    const {
                                                                                        Co,
                                                                                        BusinessUnit,
                                                                                        Division,
                                                                                        Assembly,
                                                                                        TaxableYN,
                                                                                        AddonYN
                                                                                    } = rec;
                                                                                    const {
                                                                                        Estimate,
                                                                                        OptionBoard,
                                                                                        Option
                                                                                    } = estimate;
                                                                                    estimate.Data.Components.push({
                                                                                        Co,
                                                                                        BusinessUnit,
                                                                                        Division,
                                                                                        Estimate,
                                                                                        OptionBoard,
                                                                                        Option,
                                                                                        Component: estimate.Data.Components.length + 1,
                                                                                        Type: 0,
                                                                                        Qty: 1,
                                                                                        UOM: 'EA',
                                                                                        Assembly,
                                                                                        Price: Number(catalog.Price) || 0,
                                                                                        PriceMethod: 'F',
                                                                                        TaxableYN,
                                                                                        AddonYN,
                                                                                        Data: {
                                                                                            Assembly: rec,
                                                                                        }
                                                                                    });
                                                                                    // window["warn"](estimate);
                                                                                }
                                                                                this.toggleAssemblies(estimate.Data.Components.length, true);
                                                                                // updateCatalogItem(rec);
                                                                            }}
                                                                            aria-label="add to estimate">
                                                                            <Icon color={added > -1 ? "secondary" : "primary"}>{added > -1 ? "check_circle_outline" : "add_circle_outline"}</Icon>
                                                                        </IconButton>
                                                                    </CardActions>
                                                                </Card>
                                                            );
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FuseAnimate>
                            </React.Fragment>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditCatalogItemDialog: Actions.openEditCatalogItemDialog,
        closeEditCatalogItemDialog: Actions.closeEditCatalogItemDialog,
        closeNewCatalogItemDialog: Actions.closeNewCatalogItemDialog,
        addAssembly: Actions.addAssembly,
        updateAssembly: Actions.updateAssembly,
        showMessage: showMessage,
        impress,
    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        catalogItemDialog: catalogApp.catalogItemDialog,
        rateTemplates: spReducers.rateTemplates,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        serviceTypes: spReducers.serviceTypes,
        services: spReducers.services,
        employees: spReducers.employees,
        catalog: spReducers.catalog,
        // categories: spReducers.catalog.categories,
        users: spReducers.userProfiles.Users,
        user: spReducers.userProfiles.User.UserName,
        technicians: spReducers.technicians,
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogItemDialog));
