import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { AppBar, Avatar, Badge, Button, Card, CardHeader, CardActions, CardContent, Collapse, Chip, Divider, Icon, IconButton, Menu, MenuItem, List, ListItem, ListItemText, Paper, Stepper, Step, StepButton, Tabs, Tab, TextField, Tooltip, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import { openEditCustomerDialog } from '../customers/store/actions';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import _ from '@lodash';

const styles = theme => ({
    root: {
        height: 78,
        padding: 8,
        border: '1px dotted lightgrey',
        borderRadius: 4,
        margin: 5,
        boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, .35)',
        background: '#fff',
        maxWidth: 375,
        float: 'left',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    card: {
        marginBottom: 12,
        cursor: 'pointer',
        width: '100%',
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
    info: {
        padding: 8,
        paddingTop: 0,
        paddingBottom: 0 + '!important',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    customerInfo: {
        maxWidth: 215,
    },
    customerText: {
        fontSize: 12,
        alignItems: 'center',
        margin: 8,
        lineHeight: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    badgeRoot: {
        display: 'inherit',
    },
    badge: {
        marginTop: 12,
        marginRight: 15,
    }
});

class InvoiceCustomer extends Component {
    state = {}

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        const { classes, openWorkOrder, customerData, invoiceData, scopeData, onClick, selected } = this.props;

        window["warn"](scopeData);
        return (
            <MenuItem onClick={onClick} className={classes.root} component="div">
                <div className="flex flex-1">
                    <div className="w-64">
                        <Icon color={selected ? "secondary" : "primary"} className="text-36 mt-8 mb-0 w-full text-center">account_circle</Icon>
                        <Typography style={{ alignItems: 'center', margin: 0, lineHeight: 1, fontWeight: 'bold' }} className="w-full text-center">{`#${customerData.Customer}`}</Typography>
                    </div>
                    <div className={classes.customerInfo}>
                        <Typography className={classes.customerText}>{customerData.Name && customerData.Name.length > 0 ? `${customerData.Name}` : `${customerData.FirstName} ${customerData.LastName}`}</Typography>
                        <Typography className={classes.customerText}>{`${customerData.BillAddress1} ${customerData.BillCity}, ${customerData.BillState} ${customerData.BillZip}`}</Typography>
                        {/* <Typography className={classes.customerText}>{`${customerData.BillPhone}`}</Typography> */}
                        {/*
                            customerData.UpdatedDate ?
                                <Typography fullWidth style={{ alignItems: 'center', margin: 8, lineHeight: 1, fontSize: 'xx-small', fontWeight: 'bold' }} className="w-full">{`Updated: ${moment(moment(customerData.UpdatedDate)).local().toDate().toLocaleTimeString('en-US')} on ${moment(moment(customerData.UpdatedDate)).local().toDate().toLocaleDateString('en-US')}`}</Typography>
                                :
                                customerData.AddedDate &&
                                <Typography fullWidth style={{ alignItems: 'center', margin: 8, lineHeight: 1, fontSize: 'xx-small', fontWeight: 'bold' }} className="w-full">{`Added: ${moment(moment(customerData.AddedDate)).local().toDate().toLocaleTimeString('en-US')} on ${moment(moment(customerData.AddedDate)).local().toDate().toLocaleDateString('en-US')}`}</Typography>
                        */}
                    </div>
                    <div className="w-64">
                        <Badge color="error" badgeContent={invoiceData.length} classes={{ root: classes.badgeRoot, badge: classes.badge }}>
                            <Icon className="text-36 mt-8 mb-0 w-full text-center">receipt</Icon>
                        </Badge>
                        <Typography style={{ alignItems: 'center', margin: 0, lineHeight: 1, fontWeight: 'bold' }} className="w-full text-center">{`$${(_.sumBy(scopeData, function (o) { return _.sumBy(o.Data.Usage, function (u) { return u.Status === 'NEW' ? Number(u.PriceTotal) : 0.00 }) }) + _.sumBy(scopeData, function (o) { return o.PriceMethod === 'F' && !o.Invoice ? Number(o.Price) : 0.00 })).toFixed(2)}`}</Typography>
                    </div>
                </div>
            </MenuItem >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openCustomer: openEditCustomerDialog,
        addInvoice: Actions.addInvoice,
    }, dispatch);
}

function mapStateToProps({ spReducers, invoicesApp }) {
    return {
        user: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceCustomer)));
