import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Button, Drawer, Icon, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import clsx from 'clsx';
import Autocomplete from 'main/content/components/autocomplete/Autocomplete';
import { DatePicker } from "@material-ui/pickers";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PivotTable from './PivotTable';
import * as Actions from './store/actions';



const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    drawer: {
        width: 0,
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },

});

class ReportParameters extends Component {

    state = {
        ReportData: null
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        const { Co, component } = this.props;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let co = _.find(Report.Data.Parameters, {ListValue: 'Co'});
        let param = _.find(Parameters, { Parameter: '@Co' });
        if (!param && co) {
            param = {
                Parameter: '@Co',
                Value: Co
            };
            Parameters.push(param);
            this.setState({ ...this.state, ReportData: Report });
        }

    }
    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    getColumns(data) {
        if (data && data.length > 0) {
            return Object.keys(data[0]).map(key => {
                return {
                    Header: key,
                    headerStyle: {
                        fontWeight: 'bold'
                    },
                    accessor: key,
                    id: key,
                    dataType: !isNaN(data[0][key]) ? 'number' : 'text'
                };
            });
        }
    }

    setReportParameter = (option, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = option[value.ListValue];
        } else {
            param = {
                Parameter: value.Parameter,
                Value: option[value.ListValue]
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }

    setReportParameterValue = (e, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = e.target.value;
        } else {
            param = {
                Parameter: value.Parameter,
                Value: e.target.value
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }

    setReportParameterDate = (event, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = new Date(event._d).toLocaleDateString('en-US');
        } else {
            param = {
                Parameter: value.Parameter,
                Value: new Date(event._d).toLocaleDateString('en-US')
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }
    getReportParameter = (name) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: name });
        return param && param.Value ? param.Value : null;
    }

    getData = () => {
        const { dataSources, component } = this.props;
        const { Category, DataSource } = this.state;
        const Report = component.Data.DataSource;
        window["warn"]('Component Info: ', component, Report);
        this.setState({ ...this.state }, () => { this.props.getData(Category, Report); this.props.onClose(); });
    }

    canGetData = () => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component.Data.DataSource;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let canRun = true;
        Report.Data.Parameters.map((param) => {
            if (param.RequiredYN === 'Y') {
                const has = _.find(Parameters, { Parameter: param.Parameter });
                canRun = has;
            }
        })
        return canRun;
    }

    render() {
        const { searchText, classes, master, results, component, open } = this.props;
        const Report = component && component.Data ? component.Data.DataSource : null;
        const drawerOpen = open;

        return (
            <Drawer
                anchor="right"
                variant="permanent"
                className={clsx(classNames(classes.drawer, "pin-r pin-t pin-b absolute"), {
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen
                })}
                classes={{
                    paper: clsx("relative border-none h-full", {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen
                    })
                }}
                open={drawerOpen}
                onClick={(e) => e.stopPropagation()}
            >
                <div tabIndex={0}>
                    {drawerOpen &&
                        <div className="w-full p-12 relative">
                            <IconButton style={{
                                position: 'absolute',
                                right: 8,
                                top: 6,
                            }}
                                onClick={this.props.onClose}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">tune</Icon>Parameters</Typography>
                            {Report && Report.Data && Report.Data.Parameters && Report.Data.Parameters.map((value) => {

                                const paramVal = this.getReportParameter(value.Parameter);
                                value.text = value.ListValueText ? JSON.parse(value.ListValueText) : []
                                switch (value.Type) {
                                    case 'text':
                                        {
                                            if (value.List) {
                                                const options = _.get(master, value.List) || [];
                                                return (
                                                    <Autocomplete
                                                        className="mb-12 w-full"
                                                        title={value.Description}
                                                        options={options}
                                                        menuItemComponent={(option) => {
                                                            let displayText;
                                                            if (value.text && value.text.length > 0) {
                                                                value.text.map((name) => {
                                                                    const text = _.get(option, name);
                                                                    if (text) {
                                                                        if (!displayText) {
                                                                            displayText = '';
                                                                        }
                                                                        displayText += `${text} `;
                                                                    }
                                                                })
                                                            }
                                                            return (
                                                                <MenuItem value={option ? option[value.ListValue] : null}>
                                                                    <div className="flex">
                                                                        {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                    </div>
                                                                </MenuItem>
                                                            );
                                                        }}
                                                        portal={true}
                                                        value={paramVal}
                                                        onSelect={(option) => this.setReportParameter(option, value)}
                                                        required={value.RequiredYN === 'Y'}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <TextField
                                                        className="mb-12 w-full"
                                                        label={value.Description}
                                                        id={`param${value.ID}`}
                                                        value={paramVal}
                                                        onChange={(e) => this.setReportParameterValue(e, value)}
                                                        variant="outlined"
                                                        inputProps={{
                                                            autoComplete: "off"
                                                        }}
                                                        fullWidth
                                                    />
                                                );
                                            }
                                        }
                                        break;
                                    case 'number':
                                        {
                                            if (value.List) {
                                                const options = _.get(master, value.List) || [];
                                                return (
                                                    <Autocomplete
                                                        className="mb-12 w-full"
                                                        title={value.Description}
                                                        options={options}
                                                        menuItemComponent={(option) => {
                                                            let displayText;
                                                            if (value.text && value.text.length > 0) {
                                                                value.text.map((name) => {
                                                                    const text = _.get(option, name);
                                                                    if (text) {
                                                                        if (!displayText) {
                                                                            displayText = '';
                                                                        }
                                                                        displayText += `${text} `;
                                                                    }
                                                                })
                                                            }
                                                            return (
                                                                <MenuItem value={option ? option[value.ListValue] : null}>
                                                                    <div className="flex">
                                                                        {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                    </div>
                                                                </MenuItem>
                                                            );
                                                        }}
                                                        portal={true}
                                                        value={paramVal}
                                                        disabled={value.Parameter === "@Co"}
                                                        onSelect={(option) => this.setReportParameter(option, value)}
                                                        required={value.RequiredYN === 'Y'}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <TextField
                                                        className="mb-12 w-full"
                                                        label={value.Description}
                                                        id={`param${value.ID}`}
                                                        value={paramVal}
                                                        onChange={(e) => this.setReportParameterValue(e, value)}
                                                        variant="outlined"
                                                        inputProps={{
                                                            autoComplete: "off"
                                                        }}
                                                        InputProps={{
                                                            type: 'number'
                                                        }}
                                                        fullWidth
                                                    />
                                                );
                                            }
                                        }
                                        break;
                                    case 'date':
                                        {
                                            return (
                                                <DatePicker variant="inline" inputVariant="outlined"
                                                    className="mb-12 w-full"
                                                    label={value.Description}
                                                    format="MM/DD/YYYY"
                                                    value={paramVal}
                                                    onChange={() => { }}
                                                    variant="outlined"
                                                    showTodayButton
                                                    onChange={(e) => this.setReportParameterDate(e, value)}
                                                    required={value.RequiredYN === 'Y'}
                                                    autoOk={true}
                                                />
                                            );
                                        }
                                        break;
                                }
                            })}
                            <Button
                                variant="contained"
                                className="mb-12"
                                fullWidth
                                color="primary"
                                onClick={this.getData}
                                disabled={!Report || !this.canGetData()}
                            >
                                Run Report
                            </Button>
                        </div>
                    }
                </div>
            </Drawer>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        getData: Actions.getData,
        setData: Actions.setData,
    }, dispatch);
}

function mapStateToProps({ reportingApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        component: reportingApp.reporting.selectedReport,
        master: spReducers,
        user: reportingApp.user,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportParameters)));
