import {combineReducers} from 'redux';
import inventoryLocationMaterials from './inventory-location-materials.reducer';
import user from './user.reducer';

const inventoryLocationMaterialsAppReducers = combineReducers({
    inventoryLocationMaterials,
    user
});

export default inventoryLocationMaterialsAppReducers;
