import _ from '@lodash';
import { Fab, Icon, ListItem, TextField } from '@material-ui/core';
import React, { Component } from 'react';

class CardAddChecklistItem extends Component {
    state = {
        Name: ''
    };

    handleChange = (event) => {
        this.setState(_.setIn(this.state, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    submit = (ev) => {
        ev.preventDefault();
        const { Name } = this.state;
        if (Name === '') {
            return;
        }
        this.props.onAddChecklistItem(Name);
        this.setState({ Name: '' });
    };

    render() {
        return (
            <form onSubmit={this.submit}>
                <ListItem
                    className="pr-0 pl-56"
                    dense
                >
                    <TextField
                        className="flex flex-1"
                        name="Name"
                        value={this.state.Name}
                        onChange={this.handleChange}
                        variant="outlined"
                        placeholder="Add an item"
                    // autoFocus={true}
                    />
                    <Fab
                        className="ml-16"
                        aria-label="Add"
                        mini
                        color="secondary"
                        type="submit"
                    >
                        <Icon>add</Icon>
                    </Fab>
                </ListItem>
            </form>
        );
    }
}

export default CardAddChecklistItem;
