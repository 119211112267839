import axios from 'axios';
import { showMessage } from 'store/actions';
import _ from '@lodash';
import {
    SUBSCRIBE_LIST, UNSUBSCRIBE_LIST
} from 'store/actions';

export const GET_TEXT_MESSAGES = '[TEXT MESSAGES APP] GET TEXT MESSAGES';
export const SET_TEXT_MESSAGES = '[TEXT MESSAGES APP] GET TEXT MESSAGES';
export const SET_SEARCH_TEXT = '[TEXT MESSAGES APP] SET SEARCH TEXT';
export const ADD_TEXT_MESSAGE = '[TEXT MESSAGES APP] ADD TEXT MESSAGE';
export const UPDATE_TEXT_MESSAGE = '[TEXT MESSAGES APP] UPDATE TEXT MESSAGE';
export const REMOVE_TEXT_MESSAGE = '[TEXT MESSAGES APP] REMOVE TEXT MESSAGE';

export function getConversation(Co, conversation) {
    const request = axios.get(`${window["apiLocation"]}/api/Text?Co=${Co}&Conversation=${encodeURIComponent(conversation)}`);

    return (dispatch, getState) => {
        const state = getState();
        const { conversations } = state.messagingApp.textMessages;
        request.then((response) => {
            response.data.Messages.map((message) => {
                if (message.MediaJSON) {
                    message.MediaJSON = JSON.parse(message.MediaJSON);
                }
            })
            const index = _.findIndex(conversations, { Conversation: conversation });
            if (index > -1) {
                conversations.splice(index, 1, response.data);
            } else {
                conversations.push(response.data);
            }
            Promise.all([
                dispatch({
                    type: UNSUBSCRIBE_LIST,
                    List: `${Co}_${conversation}_TextConversation`
                }),
                dispatch({
                    type: GET_TEXT_MESSAGES,
                    payload: [...conversations],
                })
            ]).then(() => {
                dispatch({
                    type: SUBSCRIBE_LIST,
                    List: `${Co}_${conversation}_TextConversation`
                });
            });
        }
        );
    }
}


export function setConversation(message) {

    return (dispatch, getState) => {
        const state = getState();
        const { conversations } = state.messagingApp.textMessages;
        const index = _.findIndex(conversations, { Conversation: message.ConversationID });
        if (index > -1) {
            const conversation = conversations[index];
            conversation.Messages.push(message);
            conversations.splice(index, 1, conversation);
        }
        dispatch({
            type: SET_TEXT_MESSAGES,
            payload: [...conversations],
        });
    }
}

export function closeConversation(conversationID) {

    return (dispatch, getState) => {
        const state = getState();
        const { Co } = state.spReducers.companies;
        const { conversations } = state.messagingApp.textMessages;
        const index = _.findIndex(conversations, { Conversation: conversationID });
        if (index > -1) {
            conversations.splice(index, 1);
        }
        dispatch({
            type: UNSUBSCRIBE_LIST,
            List: `${Co}_${conversationID}_TextConversation`
        });
        dispatch({
            type: SET_TEXT_MESSAGES,
            payload: [...conversations],
        });
    }
}

export function updateConversation(conversation) {
    const { Conversation } = conversation;
    conversation.Messages.map((message) => {
        if (message.MediaJSON) {
            message.MediaJSON = JSON.parse(message.MediaJSON);
        }
    })
    return (dispatch, getState) => {
        const state = getState();
        const { conversations } = state.messagingApp.textMessages;
        const index = _.findIndex(conversations, { Conversation });
        if (index > -1) {
            conversations.splice(index, 1, conversation);
        } else {
            conversations.push(conversation);
        }
        dispatch({
            type: SET_TEXT_MESSAGES,
            payload: [...conversations],
        });
    }
}

export function setConversations(conversations) {
    return {
        type: SET_TEXT_MESSAGES,
        payload: [...conversations],
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function addTextMessage(newTextMessage) {

    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/Text`,
            newTextMessage
        );

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: ADD_TEXT_MESSAGE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(setConversation(response.data));
                }
            })
        });
    };
}

export function endConversation(textMessage) {

    return (dispatch, getState) => {

        Promise.all([
            dispatch({
                type: UNSUBSCRIBE_LIST,
                List: `${textMessage.Co}_${textMessage.ConversationID}_TextConversation`
            })
        ]).then(() => {
            const request = axios.put(`${window["apiLocation"]}/api/Text?Co=${textMessage.Co}&Sid=${encodeURIComponent(textMessage.MessageSid)}`,
                textMessage
            );
    
            return request.then((response) => {
                Promise.all([
                    dispatch({
                        type: UPDATE_TEXT_MESSAGE
                    })
                ]).then(() => {
                    if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                        dispatch(showMessage({
                            message: `Error: ${response.data.Data.ErrMsg}`,
                            autoHideDuration: 30000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                    } else {
                        dispatch(closeConversation(response.data.ConversationID));
                    }
                })
            });
        })
    };
}
// export function updateTextMessage(textMessage) {
//     return (dispatch, getState) => {

//         const { routeParams } = getState().messagingApp.textMessages;

//         const request = axios.put(`${window["apiLocation"]}/api/TextMessage?Co=${encodeURIComponent(textMessage.Co)}&TextMessage=${encodeURIComponent(textMessage.TextMessage)}`,
//             textMessage
//         );

//         return request.then((response) =>
//             Promise.all([
//                 dispatch({
//                     type: UPDATE_TEXT_MESSAGE
//                 })
//             ]).then(() => {
//                 if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
//                     dispatch(showMessage({
//                         message: `Error: ${response.data.Data.ErrMsg}`,
//                         autoHideDuration: 30000,
//                         anchorOrigin: {
//                             vertical: 'top',
//                             horizontal: 'right'
//                         },
//                         variant: 'error'
//                     }));
//                 }
//             })
//         );
//     };
// }

// export function removeTextMessage(textMessageId) {
//     return (dispatch, getState) => {

//         const { routeParams } = getState().messagingApp.textMessages;

//         const request = axios.post('/api/textMessages-app/remove-textMessage', {
//             textMessageId
//         });

//         return request.then((response) =>
//             Promise.all([
//                 dispatch({
//                     type: REMOVE_TEXT_MESSAGE
//                 })
//             ])
//         );
//     };
// }