import React, { Component } from 'react';
import { TextField, Button, Avatar, Icon, Typography, List } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import SupportRequestActivity from './activity/SupportRequestActivity';
import SupportRequestComment from './comment/SupportRequestComment';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../store/actions';
import { showMessage } from 'store/actions';
import axios from 'axios';
import _ from '@lodash';

class Comments extends Component {
    state = {
        message: ''
    };

    updateComment = (comment) => {
        const { card, updateSupportRequest } = this.props;
        const request = axios.put(`${window["apiLocation"]}/api/SupportRequestComment?ID=${comment.ID}`, comment);
        request.then((response) => {
            const index = _.findIndex(card.Data.Comments, { ID: comment.ID });
            window["warn"]('Updated Comment: ', comment, index, card);
            if (index > -1) {
                card.Data.Comments.splice(index, 1, response.data);
                updateSupportRequest(card, false);
            }
        }).catch((err) => {
            this.props.showMessage({
                message: `Error: ${err.message}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                variant: 'error'
            });
        })
    }

    render() {
        const { members, Co, user, commentAdd, board, card, users, isSupportProvider } = this.props;

        return (
            <div className="mb-24">
                <div className="flex items-center mt-16 mb-12">
                    <Icon className="text-20 mr-8" color="inherit">comment</Icon>
                    <Typography className="font-600 text-16">Comments</Typography>
                </div>
                <SupportRequestComment
                    onCommentAdd={commentAdd}
                    isSupportProvider={isSupportProvider}
                />

                <div className="flex items-center mt-16 mb-12">
                    <Icon className="text-20 mr-8" color="inherit">list</Icon>
                    <Typography className="font-600 text-16">Activity</Typography>
                </div>
                {card.Data.Comments && card.Data.Comments.length > 0 && (
                    <div className="mb-24">
                        <List className="">
                            {_.filter(card.Data.Comments, (o) => (isSupportProvider || o.InternalYN !== "Y")).map(item => (
                                <SupportRequestActivity
                                    item={item}
                                    key={item.ID}
                                    members={users}
                                    isSupportProvider={isSupportProvider}
                                    onUpdate={isSupportProvider && this.props.user.UserName == item.UserName ? (comment) => this.updateComment(comment) : undefined}
                                    userName={this.props.user.UserName}
                                />
                            )
                            )}
                        </List>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSupportRequest: Actions.openSupportRequestDialog,
        showMessage
    }, dispatch);
}

function mapStateToProps({ auth, supportApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        card: supportApp.request
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
