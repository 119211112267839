import axios from 'axios/index';

export const SET_AGREEMENT_DATA = '[SPCONNECTION] SET AGREEMENT DATA';
export const GET_AGREEMENT_DATA = '[SPCONNECTION] GET AGREEMENT DATA';
export const SUBSCRIBE_AGREEMENT_LIST = '[SPCONNECTION] SUBSCRIBE AGREEMENT LIST';

export function getInitialAgreementData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Agreement`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getAgreementData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Agreement`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_AGREEMENT_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_AGREEMENT_LIST,
            Co
        }));
    }
}

export function setAgreementData(data) {
    return {
        type: SET_AGREEMENT_DATA,
        payload: data
    };
}