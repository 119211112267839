import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedHuntGroupIds: [],
    routeParams: {},
    huntGroupDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    searchResults: [],
};

const huntGroupsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_RESULTS:
            {
                return {
                    ...state,
                    searchResults: action.payload,
                }
            }
        case Actions.GET_HUNT_GROUPS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_HUNT_GROUPS:
            {

                const huntGroupId = action.huntGroupId;

                let selectedHuntGroupIds = [...state.selectedHuntGroupIds];

                if (selectedHuntGroupIds.find(id => id === huntGroupId) !== undefined) {
                    selectedHuntGroupIds = selectedHuntGroupIds.filter(id => id !== huntGroupId);
                }
                else {
                    selectedHuntGroupIds = [...selectedHuntGroupIds, huntGroupId];
                }

                return {
                    ...state,
                    selectedHuntGroupIds: selectedHuntGroupIds
                };
            }
        case Actions.SELECT_ALL_HUNT_GROUPS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedHuntGroupIds = arr.map(huntGroups => huntGroups.id);

                return {
                    ...state,
                    selectedHuntGroupIds: selectedHuntGroupIds
                };
            }
        case Actions.DESELECT_ALL_HUNT_GROUPS:
            {
                return {
                    ...state,
                    selectedHuntGroupIds: []
                };
            }
        case Actions.OPEN_NEW_HUNT_GROUP_DIALOG:
            {
                return {
                    ...state,
                    huntGroupDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_HUNT_GROUP_DIALOG:
            {
                return {
                    ...state,
                    huntGroupDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_HUNT_GROUP_DIALOG:
            {
                return {
                    ...state,
                    huntGroupDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
            case Actions.UPDATE_EDIT_HUNT_GROUP_DIALOG:
                {
                    return {
                        ...state,
                        huntGroupDialog: {
                            type: 'update',
                            props: {
                                open: true
                            },
                            data: action.data
                        }
                    };
                }
        case Actions.CLOSE_EDIT_HUNT_GROUP_DIALOG:
            {
                return {
                    ...state,
                    huntGroupDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default huntGroupsReducer;
