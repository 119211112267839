import _ from '@lodash';
import { Grow, Slide, Button, Divider, Drawer, Dialog, DialogContent, Icon, IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import classNames from 'classnames';
import * as Actions from './store/actions';
import React, { Component } from 'react';
import { DatePicker } from "@material-ui/pickers";
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Media from 'react-media';
import Autocomplete from 'main/content/components/autocomplete/Autocomplete';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import ReportingSidebarContent from './ReportingSidebarContent';
import PivotTable from './PivotTable';
import axios from 'axios';
import ReportCategoryCard from './ReportCategoryCard';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
    },
    component: {
        cursor: 'pointer',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        background: '#fff',
        color: '#333',
        '&:hover': {
            boxShadow: theme.shadows[6]
        }
    },
    newComponent: {
        borderWidth: 2,
        borderStyle: 'dashed',
        background: 'transparent',
        color: theme.palette.primary.contrastText,
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
        '&:hover': {
            borderColor: fade(theme.palette.primary.contrastText, 0.8)
        }
    },
    dialogRoot: {

    },
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100% - 64px)',
        top: 64,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    drawer: {
        width: 0,
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
});

const newComponentState = {
    ID: null,
    Co: null,
    Report: null,
    Category: null,
    Name: null,
    Type: null,
    DataSource: null,
    Options: null,
    Notes: null,
    AddedBy: null,
    AddedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    edit: true,
    drawerOpen: false,
}

class ReportDialog extends Component {

    state = {
        ...newComponentState
    };

    componentDidMount() {
        const { component } = this.props;
        this.setState({ ...newComponentState, ...(component || {}) });
    }

    componentDidUpdate(prevProps, prevState) {
        const { component, edit } = this.props;
        if (!_.isEqual(component, prevProps.component)) {
            this.setState({ ...newComponentState, ...(component || {}), edit });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped nowhere
        if (!destination) {
            return;
        }

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // // reordering list
        // if (result.type === 'list') {
        //     this.props.reorderList(result);
        // }

        // // reordering card
        // if (result.type === 'card') {
        //     this.props.reorderCard(result);
        // }
    };

    toggleSettingsDrawer = (state) => {
        state = (state === undefined) ? !this.state.settingsDrawerOpen : state;

        this.setState({
            settingsDrawerOpen: state
        });
    };

    setEditor = (editor) => {
        if (editor) {
            this.setState({ ...this.state, editor });
        }
    }

    updateDashboard = () => {
        const { updateDashboard } = this.props;
        updateDashboard(this.state);
        this.toggleMenu();
    }

    toggleMenu = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({ ...this.state, menuOpen: !this.state.menuOpen });
    }

    closeDialog = () => {
        const { onClose } = this.props;
        this.setState({ ...newComponentState }, () => { onClose(); });
    }

    toggleEdit = () => {
        this.setState({ ...this.state, edit: !this.state.edit })
    }

    addReport = (Options) => {
        const { Co, category } = this.props;
        window["warn"](Options);
        this.setState({
            ...this.state, Co, Options, Data: {
                ErrMsg: null,
                Categories: [{
                    Co,
                    Category: category.Category
                }]
            },
            ReportData: null,
        }, () => this.props.addReport({ ...this.state }));
    }

    updateReport = (Options) => {
        const { Co, category } = this.props;
        window["warn"](Options);
        this.setState({ ...this.state, Options, ReportData: null }, () => this.props.updateReport({ ...this.state }));
    }

    deleteReport = () => {
        const { component } = this.props;
        this.props.onDelete(component);
        this.closeDialog();
    }

    setCategory = (e) => {
        const { value } = e.target;
        this.setState({ ...this.state, Category: value }, () => this.getReportList())
    }

    getReportList = () => {
        const { Category } = this.state;
        if (Category) {
            const request = axios.get(`${window["apiLocation"]}/api/CustomReport?Category=${encodeURIComponent(Category)}`);
            request.then((response) => {
                this.props.setDataSources(Category, response.data);
            });
        }
    }

    setReportParameter = (option, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component && component.Data ? component.Data.DataSource : DataSource ? _.find(dataSources, { Report: DataSource }) : null;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = option[value.ListValue];
        } else {
            param = {
                Parameter: value.Parameter,
                Value: option[value.ListValue]
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }

    setReportParameterValue = (e, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component && component.Data ? component.Data.DataSource : DataSource ? _.find(dataSources, { Report: DataSource }) : null;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = e.target.value;
        } else {
            param = {
                Parameter: value.Parameter,
                Value: e.target.value
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }

    setReportParameterDate = (event, value) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component && component.Data ? component.Data.DataSource : DataSource ? _.find(dataSources, { Report: DataSource }) : null;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: value.Parameter });
        if (param) {
            param.Value = event.format("MM/DD/YYYY");
        } else {
            param = {
                Parameter: value.Parameter,
                Value: event.format("MM/DD/YYYY")
            };
            Parameters.push(param);
        }
        this.setState({ ...this.state, ReportData: Report });
    }
    getReportParameter = (name) => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component && component.Data ? component.Data.DataSource : DataSource ? _.find(dataSources, { Report: DataSource }) : null;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let param = _.find(Parameters, { Parameter: name });
        return param && param.Value ? param.Value : null;
    }

    getData = () => {
        const { dataSources, component } = this.props;
        const { Category, DataSource } = this.state;
        const Report = component && component.Data ? component.Data.DataSource : DataSource ? _.find(dataSources, { Report: DataSource }) : null;
        window["warn"]('Component Info: ', component, Report);
        this.setState({ ...this.state, drawerOpen: false }, () => this.props.getData(Category, Report));
    }

    canGetData = () => {
        const { dataSources, component } = this.props;
        const { DataSource } = this.state;
        const Report = component && component.Data ? component.Data.DataSource : DataSource ? _.find(dataSources, { Report: DataSource }) : null;
        if (!Report.Parameters) {
            Report.Parameters = [];
        }
        const { Parameters } = Report;
        let canRun = true;
        Report.Data.Parameters.map((param) => {
            if (param.RequiredYN === 'Y') {
                const has = _.find(Parameters, { Parameter: param.Parameter });
                canRun = has;
            }
        })
        return canRun;
    }

    confirmDeleteReport = () => {
        this.setState({ ...this.state, confirmDelete: true });
    }

    render() {
        const { classes, securables, securablesList, dataSources, reports, master, results, component } = this.props;
        const { Co, Name, Type, Options, Notes, edit, Category, drawerOpen, DataSource } = this.state;
        window["warn"]('Component: ', component);
        const Report = component && component.Data ? component.Data.DataSource : DataSource ? _.find(dataSources, { Report: DataSource }) : null;
        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.dialogRoot,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.dialogRoot}
                        open={this.props.open}
                        onClose={this.closeDialog}
                        fullWidth
                        maxWidth="lg"
                        fullScreen={!matches}
                    >
                        <ConfirmationDialog
                            open={this.state.confirmDelete}
                            title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Delete Report?</div>}
                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this report? This action cannot be undone.`}</div>}
                            confirmText="Delete"
                            cancelText="Never Mind"
                            onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                            onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => { this.deleteReport(); })}
                        />
                        <DialogContent className="relative dialog-header">
                            <Drawer
                                anchor="right"
                                variant="permanent"
                                className={clsx(classNames(classes.drawer, "pin-r pin-t pin-b absolute"), {
                                    [classes.drawerOpen]: drawerOpen,
                                    [classes.drawerClose]: !drawerOpen
                                })}
                                classes={{
                                    paper: clsx("relative border-none h-full", {
                                        [classes.drawerOpen]: drawerOpen,
                                        [classes.drawerClose]: !drawerOpen
                                    })
                                }}
                                open={drawerOpen}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div tabIndex={0}>
                                    {drawerOpen &&
                                        <div className="w-full p-12 relative">
                                            <IconButton style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 6,
                                            }}
                                                onClick={() => {
                                                    this.setState({ ...this.state, drawerOpen: false });
                                                }}
                                                className="dialog-header-icon"/*if fullScreen*/
                                            >
                                                <Icon>close</Icon>
                                            </IconButton>
                                            <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">tune</Icon>Parameters</Typography>
                                            {Report && Report.Data && Report.Data.Parameters && Report.Data.Parameters.map((value) => {

                                                const paramVal = this.getReportParameter(value.Parameter);
                                                value.text = value.ListValueText ? JSON.parse(value.ListValueText) : []
                                                switch (value.Type) {
                                                    case 'text':
                                                        {
                                                            if (value.List) {
                                                                const options = _.get(master, value.List) || [];
                                                                return (
                                                                    <Autocomplete
                                                                        className="mb-12 w-full"
                                                                        title={value.Description}
                                                                        options={options}
                                                                        menuItemComponent={(option) => {
                                                                            let displayText;
                                                                            if (value.text && value.text.length > 0) {
                                                                                value.text.map((name) => {
                                                                                    const text = _.get(option, name);
                                                                                    if (text) {
                                                                                        if (!displayText) {
                                                                                            displayText = '';
                                                                                        }
                                                                                        displayText += `${text} `;
                                                                                    }
                                                                                })
                                                                            }
                                                                            return (
                                                                                <MenuItem value={option ? option[value.ListValue] : null}>
                                                                                    <div className="flex">
                                                                                        {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                                    </div>
                                                                                </MenuItem>
                                                                            );
                                                                        }}
                                                                        portal={true}
                                                                        value={paramVal}
                                                                        onSelect={(option) => this.setReportParameter(option, value)}
                                                                        required={value.RequiredYN === 'Y'}
                                                                    />
                                                                );
                                                            } else {
                                                                return (
                                                                    <TextField
                                                                        className="mb-12 w-full"
                                                                        label={value.Description}
                                                                        id={`param${value.ID}`}
                                                                        value={paramVal}
                                                                        onChange={(e) => this.setReportParameterValue(e, value)}
                                                                        variant="outlined"
                                                                        inputProps={{
                                                                            autoComplete: "off"
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                );
                                                            }
                                                        }
                                                        break;
                                                    case 'number':
                                                        {
                                                            if (value.List) {
                                                                const options = _.get(master, value.List) || [];
                                                                return (
                                                                    <Autocomplete
                                                                        className="mb-12 w-full"
                                                                        title={value.Description}
                                                                        options={options}
                                                                        menuItemComponent={(option) => {
                                                                            let displayText;
                                                                            if (value.text && value.text.length > 0) {
                                                                                value.text.map((name) => {
                                                                                    const text = _.get(option, name);
                                                                                    if (text) {
                                                                                        if (!displayText) {
                                                                                            displayText = '';
                                                                                        }
                                                                                        displayText += `${text} `;
                                                                                    }
                                                                                })
                                                                            }
                                                                            return (
                                                                                <MenuItem value={option ? option[value.ListValue] : null}>
                                                                                    <div className="flex">
                                                                                        {`${option ? displayText || option[value.ListValue] : 'N/A'}`}
                                                                                    </div>
                                                                                </MenuItem>
                                                                            );
                                                                        }}
                                                                        portal={true}
                                                                        value={paramVal}
                                                                        onSelect={(option) => this.setReportParameter(option, value)}
                                                                        required={value.RequiredYN === 'Y'}
                                                                    />
                                                                );
                                                            } else {
                                                                return (
                                                                    <TextField
                                                                        className="mb-12 w-full"
                                                                        label={value.Description}
                                                                        id={`param${value.ID}`}
                                                                        value={paramVal}
                                                                        onChange={(e) => this.setReportParameterValue(e, value)}
                                                                        variant="outlined"
                                                                        inputProps={{
                                                                            autoComplete: "off"
                                                                        }}
                                                                        InputProps={{
                                                                            type: 'number'
                                                                        }}
                                                                        fullWidth
                                                                    />
                                                                );
                                                            }
                                                        }
                                                        break;
                                                    case 'date':
                                                        {
                                                            return (
                                                                <DatePicker variant="inline" inputVariant="outlined"
                                                                    className="mb-12 w-full"
                                                                    label={value.Description}
                                                                    format="MM/DD/YYYY"
                                                                    value={paramVal}
                                                                    showTodayButton
                                                                    onChange={(e) => this.setReportParameterDate(e, value)}
                                                                    required={value.RequiredYN === 'Y'}
                                                                    autoOk={true}
                                                                />
                                                            );
                                                        }
                                                        break;
                                                }
                                            })}
                                            <Button
                                                variant="contained"
                                                className="mb-12"
                                                fullWidth
                                                color="primary"
                                                onClick={this.getData}
                                                disabled={!Report || !this.canGetData()}
                                            >
                                                Run Report
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </Drawer>
                            <Typography className="w-full px-4 font-bold text-14 cursor-pointer mb-16 relative">
                                <Icon color="primary" className="mr-4 align-middle mb-4">insert_chart</Icon>
                                {Name ? Name : 'New Component'}
                                <IconButton
                                    className="m-0 p-0 ml-12"
                                    onClick={() => {
                                        this.toggleEdit();
                                    }}
                                >
                                    <Icon className="align-middle">{edit ? "expand_less" : "expand_more"}</Icon>
                                </IconButton>
                            </Typography>
                            <IconButton style={{
                                position: 'absolute',
                                right: 6,
                                top: matches ? 0 : -12,
                            }}
                                onClick={() => {
                                    this.closeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            {this.state.DataSource &&
                                <IconButton style={{
                                    position: 'absolute',
                                    right: 56,
                                    top: matches ? 0 : -12,
                                }}
                                    onClick={() => {
                                        this.setState({ ...this.state, drawerOpen: true });
                                    }}
                                    className="dialog-header-icon"/*if fullScreen*/
                                >
                                    <Icon>tune</Icon>
                                </IconButton>
                            }
                            <Divider className="mb-24" />
                            {edit &&
                                <div className="w-full">
                                    <div className="w-full sm:flex">
                                        <TextField
                                            className={classes.formControl}
                                            label="Name"
                                            id="name"
                                            name="Name"
                                            value={Name || ''}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            inputProps={{
                                                autoComplete: "off"
                                            }}
                                            required
                                            fullWidth
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className="mb-12"
                                            label="Data Category"
                                            id="category"
                                            name="Category"
                                            value={Category || ''}
                                            select
                                            onChange={this.setCategory}
                                            variant="outlined"
                                            fullWidth
                                            required
                                        >
                                            <MenuItem value="P">
                                                Production
                                            </MenuItem>
                                            <MenuItem value="C">
                                                Call Center
                                            </MenuItem>
                                            <MenuItem value="E">
                                                Employee
                                            </MenuItem>
                                            <MenuItem value="M">
                                                Marketing
                                            </MenuItem>
                                            <MenuItem value="A">
                                                Agreements
                                            </MenuItem>
                                        </TextField>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <Autocomplete
                                            className="mb-12"
                                            title="Data Source"
                                            options={[...dataSources, { Name: 'Refresh List', Report: 'Refresh', Icon: 'refresh' }]}
                                            menuItemComponent={(value) => {
                                                return (
                                                    <MenuItem value={value.Report}>
                                                        <div className="flex">
                                                            {value.Icon &&
                                                                <Icon className="mr-4">{value.Icon}</Icon>
                                                            }
                                                            {`${value.Name}`}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            }}
                                            value={this.state.DataSource || ''}
                                            portal={true}
                                            onSelect={(option) => { if (option.Report === 'Refresh') { this.getReportList(); } else { this.setState({ ...this.state, DataSource: option.Report, drawerOpen: true }) } }}
                                            required
                                        />
                                    </div>
                                    <TextField
                                        className="mb-12"
                                        label="Description"
                                        id="description"
                                        name="Description"
                                        value={this.state.Description || ''}
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                    />
                                </div>
                            }
                            {/* {Report && results && results.length > 0 && */}
                            <PivotTable hidden={!Report || !results || results.length < 1} edit={edit} dialog={true} results={[...results]} options={this.props.component} onAdded={this.addReport} onUpdated={this.updateReport} onDelete={this.confirmDeleteReport} />
                            {/* } */}
                        </DialogContent>
                    </Dialog>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setDataSources: Actions.setDataSources,
        getData: Actions.getData,
        setData: Actions.setData,
        addReport: Actions.addReport,
        updateReport: Actions.updateReport,
        removeReport: Actions.removeReport,
    }, dispatch);
}

function mapStateToProps({ reportingApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        Category: reportingApp.reporting.category,
        Report: reportingApp.reporting.report,
        dataSources: reportingApp.reporting.dataSources,
        master: spReducers,
        securables: spReducers.userProfiles.User.Data.Securables,
        results: reportingApp.reporting.entities,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDialog)));
