import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    root: {
        maxHeight: '70vh',
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class TeamsList extends Component {

    state = {
        selectedTeamsMenu: null
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {

            return arr;
        }

        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedTeamMenu = (event) => {
        this.setState({ selectedTeamsMenu: event.currentTarget });
    };

    closeSelectedTeamsMenu = () => {
        this.setState({ selectedTeamsMenu: null });
    };

    render() {
        const { classes, securables, teams, technicians, users, searchText, openNewTeamDialog, openEditTeamDialog, Co } = this.props;
        const data = this.getFilteredArray((!this.props.selectedTeamData ? (!this.props.teamData ? [] : this.props.teamData) : this.props.selectedTeamData), searchText);
        const { selectedTeamsMenu } = this.state;

        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "teams" });
        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewTeamDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">supervised_user_circle</Icon>Team List</Typography>
                    <ReactTable
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTeamDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={
                            [
                                {
                                    Header: "",
                                    fixed: "left",
                                    accessor: "",
                                    Cell: row => (
                                        <Avatar className="mr-8" alt={row.original.name} src={(row.original.Data && row.original.Data.Avatar ? getProfileImage(`Co=${row.original.Co}&ID=${row.original.Data.Avatar}&Thumb=true`) : avatar)} />
                                    ),
                                    className: "justify-center",
                                    width: 64,
                                    sortable: false
                                },
                                {
                                    Header: "Team",
                                    accessor: "Team",
                                    width: 64,
                                    className: "font-bold justify-center",
                                },
                                {
                                    Header: "Name",
                                    accessor: "Name",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Description",
                                    accessor: "Description",
                                    minWidth: 192,
                                },
                                {
                                    Header: "Team Lead",
                                    accessor: "TeamLead",
                                    minWidth: 192,
                                    className: "justify-center",
                                    Cell: row => {
                                        return technicians.map((value, index) => (
                                            value.Technician === row.original.TeamLead &&
                                            <React.Fragment>
                                                <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                {`${value.FirstName} ${value.LastName}`}
                                            </React.Fragment>
                                        ));
                                    }
                                },
                                {
                                    Header: "Business Unit",
                                    accessor: "DefaultBusinessUnit",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Division",
                                    accessor: "DefaultDivision",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Department",
                                    accessor: "DefaultDepartment",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                                {
                                    Header: "Coordinator",
                                    accessor: "Coordinator",
                                    minWidth: 192,
                                    className: "justify-center",
                                    Cell: row => {
                                        return users.map((value, index) => (
                                            value.UserName === row.original.Coordinator &&
                                            <React.Fragment>
                                                <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                {`${value.FirstName} ${value.LastName}`}
                                            </React.Fragment>
                                        ));
                                    }
                                },
                                {
                                    Header: "Active",
                                    accessor: "ActiveYN",
                                    className: "justify-center",
                                    //filterable: true,
                                },
                            ]
                        }
                        defaultPageSize={100}
                        noDataText="No Teams found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTeams: Actions.getTeams,
        getUserData: Actions.getUserData,
        toggleInSelectedTeams: Actions.toggleInSelectedTeams,
        selectAllTeams: Actions.selectAllTeams,
        deSelectAllTeams: Actions.deSelectAllTeams,
        openEditTeamDialog: Actions.openEditTeamDialog,
        removeTeams: Actions.removeTeams,
        removeTeam: Actions.removeTeam,
        toggleStarredTeam: Actions.toggleStarredTeam,
        toggleStarredTeams: Actions.toggleStarredTeams,
        setTeamsStarred: Actions.setTeamsStarred,
        setTeamsUnstarred: Actions.setTeamsUnstarred,
        openNewTeamDialog: Actions.openNewTeamDialog
    }, dispatch);
}

function mapStateToProps({ spReducers, teamsApp }) {
    return {
        Co: spReducers.companies.Co,
        teams: teamsApp.teams.entities,
        selectedTeamIds: teamsApp.teams.selectedTeamIds,
        searchText: teamsApp.teams.searchText,
        user: teamsApp.user,
        teamData: spReducers.teams,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamsList)));
