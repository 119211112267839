import axios from 'axios/index';
import { setBoardTechnicians } from 'main/content/apps/dispatch-board/store/actions/dispatch-board.actions';

export const SET_TECHNICIAN_DATA = '[SPCONNECTION] SET TECHNICIAN DATA';
export const GET_TECHNICIAN_DATA = '[SPCONNECTION] GET TECHNICIAN DATA';

export function getTechnicianData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Technician`, {
        params: {
            Co
        }
    });

    return (dispatch, getState) => {
        request.then((response) => {
            dispatch({
                type: GET_TECHNICIAN_DATA,
                payload: response.data
            });
            dispatch(setBoardTechnicians(response.data));
        }).catch(() => {
            const state = getState();
            const { technicians } = state.spReducers;
            if (technicians) {
                dispatch(setBoardTechnicians(technicians));
            }
        });
    }
}

export function setTechnicianData(data) {
    return {
        type: SET_TECHNICIAN_DATA,
        payload: data
    };
}