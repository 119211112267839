// import FormProfile from './FormProfile';
import _ from '@lodash'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  FormControlLabel,
  Icon,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import axios from 'axios'
import classNames from 'classnames'
import { impress } from 'main/content/compression/impress'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import FormSectionBuilder from './FormSectionBuilder'
import * as Actions from './store/actions'

function TabContainer(props) {
  return (
    <Typography
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        paddingTop: props.paddingTop
          ? props.paddingTop
          : props.padding
            ? props.padding
            : 8 * 3,
        maxHeight: props.maxHeight ? props.maxHeight : 'calc(100% - 136px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  header: {},
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  profile: {
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: 'rgba(0,0,0,.75)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: 5,
    paddingTop: 64,
    marginBottom: 16,
  },
  updateSection: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: 80,
  },
  deleteSection: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.error[500],
    color: '#fff',
    minWidth: 32,
  },
})
const newFormSectionState = {
  ID: null,
  Co: null,
  Form: null,
  Section: null,
  Name: null,
  Description: null,
  RowIndex: null,
  HeaderYN: null,
  FooterYN: null,
  Data: {
    Elements: [],
    ErrMsg: null,
  },
  input: {
    ID: null,
    Co: null,
    Form: null,
    Section: null,
    Input: null,
    Type: null,
    Title: null,
    Description: null,
    PositionX: 0,
    PositionY: 0,
    Columns: 12,
    Rows: 1,
    StaticYN: null,
    Data: {
      ErrMsg: null,
    },
  },
  needsUpdate: [],
  showTabs: true,
  lockEditor: true,
  slide: null,
  scrollLock: true,
  value: 0,
}

const FormMarker = ({ text }) => (
  <Tooltip title={text} placement='top'>
    <Icon color='action'>gps_fixed</Icon>
  </Tooltip>
)

class FormSectionDialog extends Component {
  state = { ...newFormSectionState }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleEditorChange = slide => {
    this.setState({ ...this.state, slide })
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const data = this.props.formSectionDialog.data
    /**
     * After Dialog Open
     */
    newFormSectionState.Co = this.props.Co
    if (
      !prevProps.formSectionDialog.props.open &&
      this.props.formSectionDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.formSectionDialog.type === 'edit' &&
        data &&
        !_.isEqual(data, prevState)
      ) {
        this.setState({ ...data, value: 0 })
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.formSectionDialog.type === 'new' &&
        !_.isEqual({ ...newFormSectionState, ...data }, prevState)
      ) {
        this.setState({ ...newFormSectionState, ...data, value: 0 })
      }
    }

    if (
      prevProps.formSectionDialog.props.open &&
      this.props.formSectionDialog.props.open
    ) {
      if (
        this.props.formSectionDialog.type === 'data' &&
        data &&
        !_.isEqual(data, prevState)
      ) {
        this.props.formSectionDialog.type = 'edit'
        this.setState({ ...data })
      }
    }
    if (
      prevProps.formSectionDialog.props.open &&
      this.props.formSectionDialog.props.open
    ) {
      if (
        this.props.formSectionDialog.type === 'update' &&
        data &&
        !_.isEqual(data, prevState)
      ) {
        this.props.formSectionDialog.type = 'edit'
        this.setState({ ...data })
      }
    }
  }

  closeComposeDialog = () => {
    this.props.formSectionDialog.type === 'edit'
      ? this.props.closeEditFormSectionDialog()
      : this.props.closeNewFormSectionDialog()
  }

  canBeSubmitted() {
    const { Co, Name } = this.state
    return Co && Name && Co > 0 && Name.length > 0
  }

  fileSelectedHandler = event => {
    let { ID, Data } = this.state
    const { formSectionDialog } = this.props
    impress(event.target.files[0], 1024, 50, true, img => {
      window['log'](img)
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      axios
        .post(
          `${
            window['apiLocation']
          }/api/Attachment/PostProfileImage?Co=${encodeURIComponent(
            this.state.Co,
          )}&Type=Form&ID=${encodeURIComponent(
            this.state.Form,
          )}&User=${encodeURIComponent(this.props.user)}`,
          fd,
        )
        .then(res => {
          if (Data) {
            Data.Avatar = img.base64
          } else {
            Data = { Avatar: img.base64 }
          }
          this.setState({ Data })
        })
    })
  }

  toggleTabs = () => {
    const { showTabs } = this.state
    this.setState({ ...this.state, showTabs: !showTabs })
  }

  setSection = section => {
    this.setState({ ...this.state, section })
  }

  setInput = input => {
    this.setState({ ...this.state, input })
  }

  handleStartDateChange = event => {
    let { StartDate } = this.state.section
    if (event) {
      StartDate = event._d.toLocaleDateString('en-US')
      window['warn'](event, StartDate)
    } else {
      StartDate = null
    }
    this.setState({
      ...this.state,
      section: { ...this.state.section, StartDate },
    })
  }

  handleEndDateChange = event => {
    let { EndDate } = this.state.section
    if (event) {
      EndDate = event._d.toLocaleDateString('en-US')
      window['warn'](event, EndDate)
    } else {
      EndDate = null
    }
    this.setState({
      ...this.state,
      section: {
        ...this.state.section,
        EndDate,
        ActiveYN: !EndDate ? 'Y' : 'N',
      },
    })
  }

  handleInputStartDateChange = event => {
    let { StartDate } = this.state.input
    if (event) {
      StartDate = event._d.toLocaleDateString('en-US')
      window['warn'](event, StartDate)
    } else {
      StartDate = null
    }
    this.setState({ ...this.state, input: { ...this.state.input, StartDate } })
  }

  handleInputEndDateChange = event => {
    let { EndDate } = this.state.input
    if (event) {
      EndDate = event._d.toLocaleDateString('en-US')
      window['warn'](event, EndDate)
    } else {
      EndDate = null
    }
    this.setState({
      ...this.state,
      input: { ...this.state.input, EndDate, ActiveYN: 'N' },
    })
  }

  handleNeedsUpdate = needsUpdate => {
    this.setState({ ...this.state, needsUpdate }, () =>
      window['warn'](needsUpdate),
    )
  }

  render() {
    const {
      classes,
      securables,
      theme,
      users,
      technicians,
      formSectionDialog,
      addFormSection,
      updateFormSection,
      removeFormSection,
      workOrders,
      employees,
      trips,
      removeForm,
      openNewWorkOrderDialog,
      googleMapsApiKey,
      businessUnits,
      divisions,
      departments,
    } = this.props
    const { Co, Form, value, histTab, showTabs, scrollLock } = this.state
    const accessLevel = _.find(securables, { Securable: 'forms' })
    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <Dialog
            classes={{
              root: classes.root,
              paper: matches
                ? classes.paper
                : 'full-screen-dialog' /*if fullScreen*/,
            }}
            className={classes.root}
            {...formSectionDialog.props}
            open={Boolean(accessLevel) && formSectionDialog.props.open}
            onClose={this.closeComposeDialog}
            fullWidth
            maxWidth='md'
            fullScreen={!matches}
          >
            <AppBar
              position='static'
              elevation={1}
              className='dialog-header' /*if fullScreen*/
            >
              <Toolbar className='flex w-full'>
                <Typography variant='subtitle1' color='inherit'>
                  {['new', 'customer'].indexOf(formSectionDialog.type) > -1
                    ? 'New Section'
                    : `${this.state.Name}`}
                </Typography>
                <IconButton
                  style={{
                    color: 'white',
                  }}
                  onClick={() => {
                    this.toggleTabs()
                  }}
                >
                  {showTabs ? (
                    <Icon>expand_less</Icon>
                  ) : (
                    <Icon>expand_more</Icon>
                  )}
                </IconButton>
              </Toolbar>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 10,
                  top: matches ? 10 : 4 /*if fullScreen*/,
                  color: 'white',
                }}
                onClick={() => {
                  this.closeComposeDialog()
                }}
                className='dialog-header-icon' /*if fullScreen*/
              >
                <Icon>close</Icon>
              </IconButton>
              {showTabs && (
                <Tabs
                  value={value}
                  onChange={this.handleTabChange}
                  variant='scrollable'
                  scrollButtons='on'
                >
                  {/* <Tab disableRipple icon={<Icon>assignment_ind</Icon>} label="Profile" /> */}
                  <Tab
                    disableRipple
                    icon={<Icon>ballot</Icon>}
                    label='Section'
                  />
                  <Tab
                    disableRipple
                    icon={<Icon>art_track</Icon>}
                    disabled={!this.state.ID}
                    label='Elements'
                  />
                  {/* <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Attachments ? this.state.Data.Attachments.length : 0} color="error"><AttachFile /></Badge>} disabled={!this.state.ID} label="Attachments" /> */}
                </Tabs>
              )}
            </AppBar>
            {value === 0 && (
              <TabContainer
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
              >
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>ballot</Icon>
                  </div>
                  <TextField
                    className={classNames(classes.formControl, 'sm:w-1/4')}
                    label='Section'
                    id='section'
                    name='Section'
                    value={this.state.Section || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                    disabled={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Name'
                    id='name'
                    name='Name'
                    value={this.state.Name || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    onFocus={() =>
                      this.setState({ ...this.state, lockEditor: false })
                    }
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>message</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Description'
                    id='desctription'
                    name='Description'
                    value={this.state.Description || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>note</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Notes'
                    id='notes'
                    name='Notes'
                    value={this.state.Notes || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div className='flex'>
                  <div className='w-1/2'>
                    <FormControlLabel
                      style={{ float: 'left' }}
                      control={
                        <Switch
                          checked={this.state.HeaderYN === 'Y'}
                          name='HeaderYN'
                          onChange={this.handleChange}
                          color='secondary'
                          disabled={this.state.FooterYN === 'Y'}
                        />
                      }
                      label='Header'
                    />
                  </div>
                  <div className='w-1/2'>
                    <FormControlLabel
                      style={{ float: 'right' }}
                      control={
                        <Switch
                          checked={this.state.FooterYN === 'Y'}
                          name='FooterYN'
                          onChange={this.handleChange}
                          color='primary'
                          disabled={this.state.HeaderYN === 'Y'}
                        />
                      }
                      label='Footer'
                    />
                  </div>
                </div>
              </TabContainer>
            )}

            {value === 1 && (
              <TabContainer
                maxHeight={
                  showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'
                }
                paddingTop={16}
              >
                <FormSectionBuilder
                  scrollLock={scrollLock}
                  onNeedsUpdate={needsUpdate =>
                    this.handleNeedsUpdate(needsUpdate)
                  }
                  form={{ ...this.state }}
                />
              </TabContainer>
            )}
            {/* {value === 2 && formSectionDialog.type !== 'new' && <TabContainer maxHeight={showTabs ? 'calc(100% - 136px)' : 'calc(100% - 66px)'}><FileList attachments={this.state.Data.Attachments} /></TabContainer>} */}

            {accessLevel && accessLevel.AccessLevel !== 'R' && (
              <DialogActions className='dialog-actions justify-between m-0 p-8 pl-16 pin-b bg-white w-full absolute'>
                {value === 0 &&
                  ['new', 'customer'].indexOf(formSectionDialog.type) > -1 && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        var rec = this.state
                        addFormSection(rec)
                        //this.closeComposeDialog();
                      }}
                      disabled={!this.canBeSubmitted()}
                    >
                      Add
                    </Button>
                  )}
                {value === 0 &&
                  ['new', 'customer'].indexOf(formSectionDialog.type) < 0 && (
                    <React.Fragment>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          var rec = this.state
                          updateFormSection(rec)
                        }}
                        disabled={!this.canBeSubmitted()}
                      >
                        Save
                      </Button>
                      <IconButton
                        onClick={() => {
                          removeFormSection(this.state)
                          this.closeComposeDialog()
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </React.Fragment>
                  )}
                {value === 1 &&
                  ['new', 'customer'].indexOf(formSectionDialog.type) < 0 && (
                    <React.Fragment>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          const { needsUpdate } = this.state
                          needsUpdate.map(element => {
                            this.props.updateFormElement({
                              ...element,
                              Data: { ErrMsg: null },
                            })
                          })
                          this.setState({
                            ...this.state,
                            needsUpdate: [],
                            scrollLock: true,
                          })
                        }}
                        disabled={this.state.needsUpdate.length < 1}
                      >
                        Save Changes
                      </Button>
                      <IconButton
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            scrollLock: !scrollLock,
                          })
                        }}
                      >
                        <Icon color={scrollLock ? 'error' : ''}>
                          {scrollLock ? 'lock' : 'lock_open'}
                        </Icon>
                      </IconButton>
                    </React.Fragment>
                  )}
              </DialogActions>
            )}
          </Dialog>
        )}
      </Media>
    )
  }
}
FormSectionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditFormSectionDialog: Actions.closeEditFormSectionDialog,
      closeNewFormSectionDialog: Actions.closeNewFormSectionDialog,
      showMessage: showMessage,
      addFormSection: Actions.addFormSection,
      updateFormSection: Actions.updateFormSection,
      removeFormSection: Actions.removeFormSection,
      addFormElement: Actions.addFormElement,
      updateFormElement: Actions.updateFormElement,
      removeFormElement: Actions.removeFormElement,
      impress: impress,
    },
    dispatch,
  )
}

function mapStateToProps({ auth, formsApp, spReducers, dispatchBoardApp }) {
  return {
    formSectionDialog: formsApp.forms.formSectionDialog,
    googleMapsApiKey: googleMapsApiKey,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    employees: spReducers.employees,
    forms: spReducers.forms,
    users: spReducers.userProfiles.Users,
    user: spReducers.userProfiles.User.UserName,
    workOrders: spReducers.workOrders,
    trips: dispatchBoardApp.dispatchBoard.trips,
    technicians: spReducers.technicians,
    Co: spReducers.companies.Co,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(FormSectionDialog),
)
