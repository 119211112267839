import { FuseAnimate, FusePageCarded } from '@fuse';
import _ from '@lodash';
import { Fab, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import EstimateHeader from 'main/content/apps/estimates/EstimatesHeader';
import EstimatesLeftSidebarHeader from 'main/content/apps/estimates/EstimatesLeftSidebarHeader';
import EstimateTrackingList from 'main/content/apps/estimates/EstimateTrackingList';
import EstimateSidebarContent from 'main/content/apps/estimates/EstimatesSidebarContent';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class EstimatesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getEstimates(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewEstimateDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <EstimateHeader pageLayout={() => this.pageLayout} />
                            }
                            content={
                                <EstimateTrackingList />
                            }
                            leftSidebarHeader={
                                <EstimatesLeftSidebarHeader />
                            }
                            leftSidebarContent={
                                <EstimateSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getEstimates: Actions.getEstimates,
        getUserData: Actions.getUserData,
        openNewEstimateDialog: Actions.openNewEstimateDialog
    }, dispatch);
}

function mapStateToProps({ estimatesApp }) {
    return {
        estimates: estimatesApp.estimates.entities,
        selectedEstimateIds: estimatesApp.estimates.selectedEstimateIds,
        searchText: estimatesApp.estimates.searchText,
        user: estimatesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesApp)));
