import axios from 'axios';
import { getUserData } from 'main/content/apps/reporting/store/actions/user.actions';
import { showMessage, SET_REPORT_CATEGORY_DATA } from 'store/actions';
import _ from '@lodash';

export const SET_SEARCH_TEXT = '[REPORTING APP] SET SEARCH TEXT';
export const SET_REPORT_DATA = '[REPORTING APP] SET REPORT DATA';
export const SET_SELECTED_REPORT = '[REPORTING APP] SET SELECTED REPORT';
export const SET_REPORT_LIST = '[REPORTING APP] SET REPORT LIST';
export const SET_DATA_SOURCES = '[REPORTING APP] SET DATA SOURCES';
export const SET_REPORTING_CATEGORY = '[REPORTING APP] SET REPORTING CATEGORY';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function setDataSources(category, dataSources) {
    return {
        type: SET_DATA_SOURCES,
        category,
        dataSources
    }
}


export function setCategory(category) {
    return (dispatch, getState) => {
        const { Co } = getState().spReducers.companies;
        if (category) {
            const request = axios.get(`${window["apiLocation"]}/api/ReportCategory?Co=${Co}&Category=${category.Category}`);
            request.then((response) => {
                dispatch({
                    type: SET_REPORTING_CATEGORY,
                    category: response.data,
                });
            }).catch((error) => {
                dispatch(showMessage({
                    message: `Error: ${error.message}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            });
        } else {
            dispatch({
                type: SET_REPORTING_CATEGORY,
                category: null,
            });
        }
    }
}

export function setCategories(categories) {
    return {
        type: SET_REPORT_CATEGORY_DATA,
        payload: categories,
    };
}

export function getData(category, report) {
    return (dispatch, getState) => {
        Promise.all([
            dispatch({
                type: SET_REPORT_DATA,
                category,
                report,
                data: [],
                loading: true,
            })
        ]).then(() => {
            const { Report, Parameters } = report;
            const request = axios.put(`${window["apiLocation"]}/api/Report?r=${Report}`, Parameters);
            request.then((response) => {
                Promise.all([
                    dispatch({
                        type: SET_REPORT_DATA,
                        category,
                        report,
                        data: response.data,
                        loading: false,
                    })
                ])
            });
        })
    }
}

export function setReport(report, data) {
    return {
        type: SET_SELECTED_REPORT,
        report,
        data: data || []
    }
}

export function setData(data) {
    return {
        type: SET_REPORT_DATA,
        data
    }
}

export function addCategory(newCategory) {
    return (dispatch, getState) => {

        const state = getState();
        const { categories } = state.spReducers.reporting;

        const request = axios.post(`${window["apiLocation"]}/api/ReportCategory`,
            newCategory
        );

        return request.then((response) => {
            categories.push(response.data)
            Promise.all([
                dispatch({
                    type: SET_REPORT_CATEGORY_DATA,
                    payload: categories,
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateCategory(category) {
    return (dispatch, getState) => {

        const state = getState();
        const { categories } = state.spReducers.reporting;
        const index = _.findIndex(categories, { Category: category.Category });

        const request = axios.put(`${window["apiLocation"]}/api/ReportCategory?Co=${encodeURIComponent(category.Co)}&Category=${encodeURIComponent(category.Category)}`,
            category
        );

        return request.then((response) => {
            categories.splice(index, 1, response.data);
            Promise.all([
                dispatch({
                    type: SET_REPORT_CATEGORY_DATA,
                    payload: categories,
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Category #${response.data.Category} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeCategory(category) {
    return (dispatch, getState) => {

        const state = getState();
        const { categories } = state.spReducers.reporting;
        const index = _.findIndex(categories, { Category: category.Category });

        const request = axios.delete(`${window["apiLocation"]}/api/ReportCategory?Co=${encodeURIComponent(category.Co)}&Category=${encodeURIComponent(category.Category)}`,
            category
        );

        return request.then((response) => {
            categories.splice(index, 1);
            Promise.all([
                dispatch({
                    type: SET_REPORT_CATEGORY_DATA,
                    payload: categories,
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Category #${category.Category} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}



export function addReport(newReport) {
    return (dispatch, getState) => {

        const state = getState();
        const reportCategory = _.cloneDeepWith(state.reportingApp.reporting.reportCategory);

        const request = axios.post(`${window["apiLocation"]}/api/CustomReport`,
            newReport
        );

        return request.then((response) => {
            reportCategory.Data.Reports.push(response.data)
            Promise.all([
                dispatch({
                    type: SET_REPORTING_CATEGORY,
                    category: reportCategory,
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Report #${response.data.Report} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateReport(report) {
    return (dispatch, getState) => {

        const state = getState();
        const { Co } = state.spReducers.companies;
        const reportCategory = _.cloneDeepWith(state.reportingApp.reporting.reportCategory);
        const { selectedReport, entities } = state.reportingApp.reporting;
        const index = _.findIndex(reportCategory.Data.Reports, { Report: report.Report });
        const {about} = state.version;

        if (!report.Co && report.Report < 1000 && about.InstanceType === 'D') {
            report.StandardYN = 'Y'
        }

        const request = axios.put(`${window["apiLocation"]}/api/CustomReport?Co=${encodeURIComponent(report.Co || Co)}&Report=${encodeURIComponent(report.Report)}`,
            report
        );

        return request.then((response) => {
            reportCategory.Data.Reports.splice(index, 1, report);
            Promise.all([
                dispatch(setReport(report, entities)),
                dispatch({
                    type: SET_REPORTING_CATEGORY,
                    category: reportCategory,
                }),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Report #${response.data.Report} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function removeReport(report) {
    return (dispatch, getState) => {

        const state = getState();
        const reportCategory = _.cloneDeepWith(state.reportingApp.reporting.reportCategory);
        const index = _.findIndex(reportCategory.Data.Reports, { Report: report.Report });

        const request = axios.delete(`${window["apiLocation"]}/api/CustomReport?Co=${encodeURIComponent(report.Co)}&Report=${encodeURIComponent(report.Report)}`);

        return request.then((response) => {
            reportCategory.Data.Reports.splice(index, 1);
            Promise.all([
                dispatch(setReport(null)),
                dispatch({
                    type: SET_REPORTING_CATEGORY,
                    category: reportCategory,
                }),
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Report #${report.Report} has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}