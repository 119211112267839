import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class GLAccountsList extends Component {

    state = {
        selectedGLAccountsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedGLAccountMenu = (event) => {
        this.setState({ selectedGLAccountsMenu: event.currentTarget });
    };

    closeSelectedGLAccountsMenu = () => {
        this.setState({ selectedGLAccountsMenu: null });
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    render() {
        const { classes, securables, glAccounts, user, searchText, openNewGLAccountDialog, selectedGLAccountIds, selectAllGLAccounts, deSelectAllGLAccounts, toggleInSelectedGLAccounts, openEditGLAccountDialog, removeGLAccounts, removeGLAccount, toggleStarredGLAccount, setGLAccountsUnstarred, setGLAccountsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.glAccountData ? [] : this.props.glAccountData), searchText);
        const { selectedGLAccountsMenu } = this.state;
        const accessLevel = _.find(securables, { Securable: "gl-accounts" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel !== "R" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={openNewGLAccountDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_bulleted</Icon>GL Accounts</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditGLAccountDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "GLAccount",
                                accessor: "GLAccount",
                                className: "font-bold justify-center",
                                width: 256
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 512
                            },
                            {
                                Header: "Balance Type",
                                id: "balanceType",
                                className: "justify-center",
                                accessor: d => d.BalanceType === "D" ? "Debit" : "Credit"
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No GL Accounts found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGLAccounts: Actions.getGLAccounts,
        getUserData: Actions.getUserData,
        toggleInSelectedGLAccounts: Actions.toggleInSelectedGLAccounts,
        selectAllGLAccounts: Actions.selectAllGLAccounts,
        deSelectAllGLAccounts: Actions.deSelectAllGLAccounts,
        openEditGLAccountDialog: Actions.openEditGLAccountDialog,
        removeGLAccounts: Actions.removeGLAccounts,
        removeGLAccount: Actions.removeGLAccount,
        toggleStarredGLAccount: Actions.toggleStarredGLAccount,
        toggleStarredGLAccounts: Actions.toggleStarredGLAccounts,
        setGLAccountsStarred: Actions.setGLAccountsStarred,
        setGLAccountsUnstarred: Actions.setGLAccountsUnstarred,
        openNewGLAccountDialog: Actions.openNewGLAccountDialog
    }, dispatch);
}

function mapStateToProps({ glAccountsApp, spReducers }) {
    return {
        glAccounts: glAccountsApp.glAccounts.entities,
        selectedGLAccountIds: glAccountsApp.glAccounts.selectedGLAccountIds,
        searchText: glAccountsApp.glAccounts.searchText,
        user: glAccountsApp.user,
        glAccountData: spReducers.glAccounts,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(GLAccountsList)));
