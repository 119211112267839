import { FuseAnimate, FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  Avatar,
  Badge,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import axios from 'axios'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import DispatchBoardFolderContent from './DispatchBoardFolderContent'
import DispatchFolderDialog from './DispatchFolderDialog'
import * as Actions from './store/actions'

const styles = theme => ({
  listItem: {
    color: 'inherit!important',
    textDecoration: 'none!important',
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingLeft: 24,
    paddingRight: 12,
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + '!important',
      pointerEvents: 'none',
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
  },
  avatar: {
    backgroundColor: theme.palette.error[500],
    height: 18,
    minWidth: 18,
    fontSize: 13,
    borderRadius: 9,
    padding: 4,
    marginRight: 8,
    width: 'auto',
  },
  avatarGrey: {
    backgroundColor: '#5f5f5f',
    height: 18,
    minWidth: 18,
    fontSize: 13,
    borderRadius: 9,
    padding: 4,
    marginRight: 8,
    width: 'auto',
  },
  card: {
    marginTop: 12,
    cursor: 'pointer',
    width: '100%',
  },
  actions: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  info: {
    padding: 8,
    paddingTop: 0,
    paddingBottom: 0 + '!important',
  },
  campaign: {
    width: 'auto',
    top: 0,
    right: -5,
    marginTop: 5,
    borderRadius: 4,
    padding: 10,
  },
  content: {
    overflow: 'hidden',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  badge: {
    top: 6,
    right: 4,
    minWidth: 16,
    minHeight: 16,
    height: 16,
    fontSize: 11,
  },
  folderBadge: {
    top: 12,
    right: 'unset',
    left: 'calc(108% - 12px)',
    minWidth: 16,
    minHeight: 16,
    height: 16,
    fontSize: 11,
  },
  moveToUnassignedDiv: {
    width: '100%',
    textAlign: 'center',
    margin: '10px 0',
  },
  moveToUnassigned: {
    width: '90%',
    fontSize: '12px',
  },
  tentativeMenu: {
    marginTop: '-21px',
  },
})

const criteriaLists = {
  businessUnits: {
    key: 'BusinessUnit',
    format: ['Description'],
    label: 'Business Unit',
    listName: 'Business Units',
    icon: 'business_center',
  },
  divisions: {
    key: 'Division',
    format: ['Description'],
    label: 'Division',
    listName: 'Divisions',
    icon: 'location_city',
  },
  departments: {
    key: 'Department',
    format: ['Description'],
    label: 'Department',
    listName: 'Departments',
    icon: 'business',
  },
  serviceTypes: {
    key: 'ServiceType',
    format: ['Description'],
    label: 'Service Type',
    listName: 'Service Types',
    icon: 'settings',
  },
  services: {
    key: 'Service',
    format: ['Service', ' - ', 'Description'],
    label: 'Service',
    listName: 'Services',
    icon: 'build',
  },
  priorities: {
    key: 'Priority',
    format: ['Description'],
    label: 'Priority',
    listName: 'Priorities',
    icon: 'flag',
  },
  arrivalWindows: {
    key: 'ArrivalWindow',
    format: ['Description'],
    label: 'Arrival Window',
    listName: 'Arrival Windows',
    icon: 'access_time',
  },
}

class DispatchBoardLeftSidebarContent extends Component {
  state = {
    WorkOrders: [],
    workOrders: {},
    loading: false,
    expanded: [],
    menuOpen: false,
    searchText: '',
    data: [],
    agreements: [],
    unassigned: [],
    tentative: [],
    ongoing: [],
    onHold: [],
    assigned: [],
    canceled: [],
    dateException: [],
    selectedFolders: [],
    tentativeMenuEl: null,
    scrolling: {},
    visible: {},
    currentFolder: null,
    date: this.props.date,
  }

  componentDidMount() {
    const { WorkOrders, workOrders, workOrderList, loading } = this.props
    if (!_.isEqual(WorkOrders, workOrderList)) {
      if (
        WorkOrders &&
        WorkOrders != null &&
        !_.isEqual(this.props.WorkOrders, this.state.WorkOrders)
      ) {
        this.props.setWorkOrders(WorkOrders)
      }
    } else {
      if (
        (workOrders &&
          workOrders != null &&
          !_.isEqual(this.props.workOrders, this.state.workOrders)) ||
        loading !== this.state.loading
      ) {
        this.setState(
          { workOrders, WorkOrders: workOrderList, loading },
          this.getWOData,
        )
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      WorkOrders,
      workOrders,
      workOrderList,
      selectedBoard,
      loading,
      customBoards,
      customFolders,
    } = this.props
    const boardChanged = selectedBoard !== prevProps.selectedBoard
    if (!_.isEqual(WorkOrders, workOrderList)) {
      if (
        WorkOrders &&
        WorkOrders != null &&
        !_.isEqual(this.props.WorkOrders, this.state.WorkOrders)
      ) {
        this.props.setWorkOrders(WorkOrders)
      } else {
        if (loading !== prevProps.loading) {
          this.setState({ ...this.state, loading }, this.getWOData)
        }
      }
    } else {
      if (
        loading !== prevProps.loading ||
        (workOrders &&
          workOrders != null &&
          !_.isEqual(this.props.workOrders, this.state.workOrders))
      ) {
        this.setState(
          { workOrders, WorkOrders: workOrderList, loading },
          this.getWOData,
        )
      } else {
        if (loading !== prevProps.loading) {
          this.setState({ ...this.state, loading }, this.getWOData)
        } else {
          if (
            boardChanged ||
            !_.isEqual(customFolders, prevProps.customFolders) ||
            !_.isEqual(customBoards, prevProps.customBoards)
          ) {
            this.getWOData(boardChanged)
          }
        }
      }
    }
  }

  handleRefresh = () => {
    let { date } = this.state
    const old = this.formatDateInput(date);
    const oldDate = this.formatDateInput(this.props.date);
    this.props.setBoardDate(this.props.Co, date, old);
    this.updateDate(this.props.Co, oldDate, oldDate);
  }

  updateDate = _.debounce((Co, date, oldDate) => {
    this.props.getTripData(Co, date, oldDate)
    this.props.getWOData(Co, date, oldDate)
    if (
      moment(date).format('M/D/YYYY') !== moment(oldDate).format('M/D/YYYY')
    ) {
      if (
        moment().format('M/D/YYYY') === moment(date).format('M/D/YYYY') &&
        document.getElementsByClassName('rt-table')
      ) {
        document.getElementsByClassName('rt-table')[0].scrollLeft =
          new Date().getHours() * 192
      } else {
        document.getElementsByClassName('rt-table')[0].scrollLeft = 8 * 192
      }
    }
  }, 1000)

  formatDateInput(dt) {
    return dt.toLocaleDateString('en-US').replace(/\//g, '-')
  }

  handleExpandClick() {
    //const { expanded } = this.state;
    //const draft = (key !== expanded ? key : null);
    //this.setState({ ...this.state, expanded: draft }, () => { window["warn"](this.state) });
  }

  filterWOs = e => {
    const searchText = e.target.value
    this.setState({ searchText }, this.getWOData)
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    const data = FuseUtils.filterArrayByString(arr, searchText, [
      'FirstName',
      'LastName',
      'Address1',
      'Address2',
      'City',
      'State',
      'Zip',
      'Description',
      'WorkOrder',
      'Contact',
      'Service',
      'ServiceType',
      'Phone',
      'ContactPhone',
    ])
    return data
  }

  toggleList = (e, name) => {
    e.stopPropagation()
    const { currentFolder } = this.state
    // const { expanded } = this.state;
    // const index = expanded.indexOf(name);
    // if (index > -1) {
    //     expanded.splice(index, 1);
    // } else {
    //     expanded.push(name);
    // }
    // this.setState({ ...this.state, expanded });
    this.setState({ currentFolder: name === currentFolder ? null : name })
  }

  getWOData = boardChanged => {
    const {
      Co,
      date,
      trips,
      user,
      customBoards,
      customFolders,
      selectedBoard,
    } = this.props
    const { workOrders, loading } = this.state

    const data = loading
      ? []
      : this.getFilteredArray(
          workOrders ? workOrders : {},
          this.state.searchText,
        )

    let agreements = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            s.Type === 'A' &&
            s.Status !== 4 &&
            /*new Date(s.DueBy).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') &&*/ s
              .Data.Assignments.length < 1
          )
        }).length > 0
      )
    })
    let unassigned = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            !s.LeadTechnician &&
            s.Status !== 4 &&
            new Date(s.DueBy).toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') &&
            s.Data.Assignments.length < 1
          )
        }).length > 0
      )
    })
    let tentative = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            Boolean(s.LeadTechnician) &&
            s.Status !== 4 &&
            new Date(s.DueBy).toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') &&
            s.Data.Assignments.length < 1
          )
        }).length > 0
      )
    })
    const ongoing = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            s.Type !== 'A' &&
            !s.DueBy &&
            s.Data.Assignments.length > 0 &&
            s.Status < 4
          )
        }).length > 0
      )
    })
    const onHold = _.filter(data, o => {
      return (
        _.filter(o.Data.Trips, t => {
          return (
            t.Status === 7 &&
            _.filter(
              o.Data.Trips,
              h =>
                h.Trip !== t.Trip &&
                new Date(
                  h.InProgressDate ||
                    h.ArrivedDate ||
                    h.EnRouteDate ||
                    h.ScheduledDate,
                ) > new Date(t.OnHoldDate || t.ScheduledDate),
            ).length < 1
          )
        }).length > 0
      )
    })
    const assigned = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return s.Status !== 4 && s.Data.Assignments.length > 0
        }).length > 0 && _.filter(trips, { WorkOrder: o.WorkOrder }).length > 0
      )
    })
    const canceled = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            new Date(s.DueBy || '1/1/1900').toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') && s.Status === 4
          )
        }).length > 0
      )
    })
    const dateException = _.filter(data, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            Boolean(s.DueBy) &&
            moment(s.DueBy).format('M/D/YYYY') ===
              moment(date).format('M/D/YYYY') &&
            s.Type !== 'A' &&
            s.Status !== 4 &&
            s.Data.Assignments.length > 0
          )
        }).length > 0 && _.filter(trips, { WorkOrder: o.WorkOrder }).length < 1
      )
    })

    agreements.forEach((value, index) => {
      let newValue = _.cloneDeep(value)

      newValue.scopes = _.filter(value.Data.Scopes, s => {
        return s.Data.Assignments.length < 1 && s.Status < 4
      })

      agreements[index] = newValue
    })
    unassigned.forEach((value, index) => {
      let newValue = _.cloneDeep(value)

      newValue.scopes = _.filter(value.Data.Scopes, s => {
        return (
          !s.LeadTechnician &&
          s.Status !== 4 &&
          new Date(s.DueBy).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US') &&
          s.Data.Assignments.length < 1
        )
      })

      unassigned[index] = newValue
    })

    tentative.forEach((value, index) => {
      let newValue = _.cloneDeep(value)

      newValue.scopes = _.filter(value.Data.Scopes, s => {
        return (
          Boolean(s.LeadTechnician) &&
          s.Status !== 4 &&
          new Date(s.DueBy).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US') &&
          s.Data.Assignments.length < 1
        )
      })

      tentative[index] = newValue
    })

    ongoing.forEach((value, index) => {
      let newValue = _.cloneDeep(value)

      newValue.scopes = _.filter(value.Data.Scopes, s => {
        return s.Data.Assignments.length > 0 && s.Status < 4
      })

      ongoing[index] = newValue
    })

    onHold.forEach((value, index) => {
      let newValue = _.cloneDeep(value)
      newValue.hold = value.Data.Trips
        ? _.findLast(value.Data.Trips, t => {
            return (
              t.Status === 7 &&
              _.filter(
                value.Data.Trips,
                h =>
                  h.Trip !== t.Trip &&
                  new Date(
                    h.InProgressDate ||
                      h.ArrivedDate ||
                      h.EnRouteDate ||
                      h.ScheduledDate,
                  ) > new Date(t.OnHoldDate || t.ScheduledDate),
              ).length < 1
            )
          })
        : null

      newValue.scopes = _.filter(newValue.Data.Scopes, s => {
        return (
          _.findIndex(newValue.hold.Data.Assignments, { Scope: s.Scope }) >
            -1 && s.Status < 4
        )
      })

      onHold[index] = newValue
    })

    assigned.forEach((value, index) => {
      let newValue = _.cloneDeep(value)

      newValue.scopes = _.filter(newValue.Data.Scopes, s => {
        return s.Data.Assignments.length > 0 && s.Status < 4
      })

      assigned[index] = newValue
    })
    canceled.forEach((value, index) => {
      let newValue = _.cloneDeep(value)

      newValue.scopes = _.filter(value.Data.Scopes, s => {
        return (
          new Date(s.DueBy).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US') && s.Status === 4
        )
      })

      canceled[index] = newValue
    })

    dateException.forEach((value, index) => {
      let newValue = _.cloneDeep(value)

      newValue.scopes = _.filter(value.Data.Scopes, s => {
        return s.Data.Assignments.length > 0 && s.Status < 4
      })

      dateException[index] = newValue
    })

    let boards =
      user && user.Data && user.Data.DispatchBoards
        ? _.filter(user.Data.DispatchBoards, o => o.SharedYN !== 'Y')
        : []
    boards = [...customBoards, ...boards]

    let folders =
      user && user.Data && user.Data.DispatchFolders
        ? _.filter(user.Data.DispatchFolders, o => o.SharedYN !== 'Y')
        : []
    folders = [...customFolders, ...folders]

    const board = _.find(boards, { Co, Board: selectedBoard })
    let options
    let selectedFolders = []

    if (board) {
      options = JSON.parse(board.Options)
      Object.keys(options.criteria).map(paramKey => {
        const params = options.criteria[paramKey]
        if (params.length > 0) {
          const list = criteriaLists[paramKey]
          if (list) {
            agreements = _.filter(agreements, o => {
              return _.filter(
                o.Data.Scopes,
                s =>
                  params.indexOf(s[list.key]) > -1 &&
                  s.Status !== 4 &&
                  s.Data.Assignments.length < 1,
              ).length
            })
            unassigned = _.filter(unassigned, o => {
              return _.filter(
                o.Data.Scopes,
                s =>
                  params.indexOf(s[list.key]) > -1 &&
                  s.Status !== 4 &&
                  new Date(s.DueBy).toLocaleDateString('en-US') ===
                    new Date(date).toLocaleDateString('en-US') &&
                  s.Data.Assignments.length < 1,
              ).length
            })
            tentative = _.filter(tentative, o => {
              return _.filter(
                o.Data.Scopes,
                s =>
                  params.indexOf(s[list.key]) > -1 &&
                  s.Status !== 4 &&
                  new Date(s.DueBy).toLocaleDateString('en-US') ===
                    new Date(date).toLocaleDateString('en-US') &&
                  s.Data.Assignments.length < 1,
              ).length
            })
          }
        }
      })
      options.folders.map(Folder => {
        const folder = _.find(folders, { Co, Folder })
        if (folder) {
          let filtered = unassigned
          const criteria = JSON.parse(folder.Options)
          Object.keys(criteria).map(paramKey => {
            const params = criteria[paramKey]
            if (params.length > 0) {
              const list = criteriaLists[paramKey]
              filtered = _.filter(filtered, o => {
                return _.filter(
                  o.Data.Scopes,
                  s =>
                    params.indexOf(s[list.key]) > -1 &&
                    s.Status !== 4 &&
                    new Date(s.DueBy).toLocaleDateString('en-US') ===
                      new Date(date).toLocaleDateString('en-US') &&
                    s.Data.Assignments.length < 1,
                ).length
              })
            }
          })
          folder.contents = filtered
          selectedFolders.push(folder)
        }
      })
    } else {
      selectedFolders = [...folders]
      folders.map(folder => {
        let filtered = unassigned
        const criteria = JSON.parse(folder.Options)
        Object.keys(criteria).map(paramKey => {
          const params = criteria[paramKey]
          if (params.length > 0) {
            const list = criteriaLists[paramKey]
            filtered = _.filter(filtered, o => {
              return _.filter(
                o.Data.Scopes,
                s =>
                  params.indexOf(s[list.key]) > -1 &&
                  s.Status !== 4 &&
                  new Date(s.DueBy).toLocaleDateString('en-US') ===
                    new Date(date).toLocaleDateString('en-US') &&
                  s.Data.Assignments.length < 1,
              ).length
            })
          }
        })
        folder.contents = filtered
      })
    }

    this.setState({
      data,
      agreements,
      unassigned,
      tentative,
      ongoing,
      onHold,
      assigned,
      canceled,
      dateException,
      selectedFolders,
      currentFolder: boardChanged ? null : this.state.currentFolder,
    })
  }

  moveAllTentativesToUnassigned = data => {
    const { showMessage, date, Co } = this.props

    let dueBy = moment(date).format('M-D-YYYY')

    // window["warn"]("moveAllTentativesToUnassigned clicked!!", data)

    const request = axios.delete(
      `${window['apiLocation']}/api/Tentative?Co=${Co}&Date=${dueBy}`,
      { data },
    )

    return request
      .then(response => {
        // window["warn"]("Successful moveAllTentativesToUnassigned request", response)

        showMessage({
          message: `All Tentative trips have been moved to Unassigned.`,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        })
        this.setState(prevState => ({ ...prevState }));
        this.handleRefresh();
        return true
      })
      .catch(err => {
        // window["warn"]("Unsuccessful moveAllTentativesToUnassigned request", err)
        return err
      })
  }

  render() {
    const { classes, user, date, securables, pageLayout } = this.props
    const {
      loading,
      menuOpen,
      selectedFolders,
      agreements,
      unassigned,
      tentative,
      ongoing,
      onHold,
      assigned,
      canceled,
      dateException,
      tentativeMenuEl,
      currentFolder,
    } = this.state;

    const accessLevel = _.find(securables, {
      Securable: 'custom-dispatch-folders',
    })

    const woCount = tentative.length + unassigned.length + agreements.length

    let unassignedLength = unassigned.map(value => {
      const scopes = _.filter(value.Data.Scopes, s => {
        return (
          !s.LeadTechnician &&
          s.Type !== 'A' &&
          s.Status !== 4 &&
          new Date(s.DueBy).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US') &&
          s.Data.Assignments.length < 1
        )
      })
      if (scopes.length > 0) {
        return scopes[0]
      }
    })

    return (
      <React.Fragment>
        <div
          className='p-12 pl-16 lg:pl-20'
          onClick={e => e.stopPropagation()}
          onDragLeave={() => pageLayout().closeLeftSidebar()}
        >
          <DispatchFolderDialog />
          <Typography
            variant='h6'
            style={{ alignItems: 'center', marginBottom: 12 }}
            className='flex relative'
          >
            {woCount > 0 ? (
              <Badge
                classes={{ badge: classes.badge }}
                color={woCount > 0 ? 'error' : 'primary'}
                className='mr-12'
                badgeContent={woCount}
              >
                <Icon color='primary' className='text-32'>
                  assignment
                </Icon>
              </Badge>
            ) : (
              <Icon color='primary' className='text-32 mr-12'>
                assignment
              </Icon>
            )}
            Work Orders
            <IconButton
              style={{
                position: 'absolute',
                right: -5,
                top: -5,
              }}
              onClick={() =>
                this.setState(
                  { menuOpen: !this.state.menuOpen, searchText: '' },
                  this.getWOData,
                )
              }
            >
              <Icon>{menuOpen ? 'close' : 'search'}</Icon>
            </IconButton>
          </Typography>
          {menuOpen && (
            <FuseAnimate animation='transition.slideDownIn'>
              <div className='mt-12'>
                <TextField
                  placeholder='Search Work Orders'
                  disableUnderline
                  fullWidth
                  value={this.state.searchText || ''}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icon>search</Icon>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={this.filterWOs}
                />
              </div>
            </FuseAnimate>
          )}

          <Divider />
          {(!currentFolder || currentFolder === 'Unassigned') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'Unassigned')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'Unassigned' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  Unassigned
                  {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                  {(loading || unassigned.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8'>
                      refresh
                    </Icon>
                  ) : unassigned.length > 0 ? (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatar,
                            'pin-r pin-t mt-4 absolute',
                          )}
                        >
                          {unassignedLength.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  ) : (
                    <Icon
                      className='text-20 pin-r absolute mr-8'
                      color='secondary'
                    >
                      check_circle_outline
                    </Icon>
                  )}
                  {unassigned.length > 0 && currentFolder !== 'Unassigned' && (
                    <Icon
                      className='mr-40 pin-r absolute'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
              </div>
              {currentFolder === 'Unassigned' && (
                <DispatchBoardFolderContent
                  unassigned={true}
                  data={unassigned}
                  loading={loading}
                  byScope={true}
                />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {((!currentFolder && agreements.length > 0) ||
            currentFolder === 'Agreements') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'Agreements')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'Agreements' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  <div className='truncate w-2/3'>Unassigned Agreements</div>
                  {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                  {(loading || agreements.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8'>
                      refresh
                    </Icon>
                  ) : agreements.length > 0 ? (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatar,
                            'pin-r pin-t mt-4 absolute',
                          )}
                        >
                          {agreements.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  ) : (
                    <Icon
                      className='text-20 pin-r absolute mr-8'
                      color='secondary'
                    >
                      check_circle_outline
                    </Icon>
                  )}
                  {agreements.length > 0 && currentFolder !== 'Agreements' && (
                    <Icon
                      className='mr-40 pin-r absolute'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
              </div>
              {currentFolder === 'Agreements' && (
                <DispatchBoardFolderContent
                  unassigned={true}
                  data={agreements}
                  loading={loading}
                  byScope={true}
                />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {((!currentFolder && tentative.length > 0) ||
            currentFolder === 'Tentative') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'Tentative')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'Tentative' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  Tentative
                  <Tooltip
                    placement='top'
                    className={classNames(classes.tentativeMenu, '')}
                    title='Tentative Actions'
                  >
                    <Icon
                      onClick={e => {
                        e.stopPropagation()
                        this.setState({
                          ...this.state,
                          tentativeMenuEl: e.target,
                        })
                      }}
                      className='text-16 ml-4 cursor-pointer align-middle mt-2'
                    >
                      more_vert
                    </Icon>
                  </Tooltip>
                  {(loading || tentative.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8'>
                      refresh
                    </Icon>
                  ) : tentative.length > 0 ? (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatar,
                            'pin-r pin-t mt-4 absolute',
                          )}
                        >
                          {tentative.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  ) : (
                    <Icon
                      className='text-20 pin-r absolute mr-8'
                      color='secondary'
                    >
                      check_circle_outline
                    </Icon>
                  )}
                  {tentative.length > 0 && currentFolder !== 'Tentative' && (
                    <Icon
                      className='mr-40 pin-r absolute'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
                {tentativeMenuEl && (
                  <Menu
                    id='tentative-menu'
                    anchorEl={tentativeMenuEl}
                    open={Boolean(tentativeMenuEl)}
                    onClose={() => this.setState({ tentativeMenuEl: null })}
                  >
                    <MenuItem
                      onClick={async () => {
                        this.setState({ tentativeMenuEl: null })
                        let scopes = []
                        tentative.map(val => {
                          scopes = [
                            ...scopes,
                            ..._.filter(val.Data.Scopes, s => {
                              return (
                                s.Data.Assignments.length < 1 && s.Status < 4
                              )
                            }).map(s => {
                              return {
                                Co: val.Co,
                                WorkOrder: val.WorkOrder,
                                Scope: s.Scope,
                              }
                            }),
                          ]
                        })
                        await this.moveAllTentativesToUnassigned(scopes)
                      }}
                    >
                      <Icon className='mr-8'>send</Icon>Move All to Unassigned
                    </MenuItem>
                  </Menu>
                )}
              </div>
              {currentFolder === 'Tentative' && (
                <DispatchBoardFolderContent
                  data={tentative}
                  loading={loading}
                  byScope={true}
                />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {selectedFolders &&
            selectedFolders.map((folder, index) => {
              if (
                !currentFolder ||
                currentFolder === `folder-${folder.Folder}`
              ) {
                return (
                  <div key={index}>
                    <div className='w-full'>
                      <Typography
                        onClick={e =>
                          this.toggleList(e, `folder-${folder.Folder}`)
                        }
                        variant='h6'
                        style={{ alignItems: 'center' }}
                        className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                      >
                        {currentFolder === `folder-${folder.Folder}` && (
                          <Icon
                            className='align-middle mb-2'
                            style={{ marginTop: 2 }}
                          >
                            keyboard_arrow_left
                          </Icon>
                        )}
                        <div className='truncate w-2/3'>{folder.Name}</div>
                        {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                        {(loading || folder.contents.length > 0) && loading ? (
                          <Icon className='text-20 spin pin-r absolute mr-8'>
                            refresh
                          </Icon>
                        ) : folder.contents.length > 0 ? (
                          <FuseAnimate
                            animation='transition.expandIn'
                            delay={300}
                          >
                            {
                              <Avatar
                                className={classNames(
                                  classes.avatar,
                                  'pin-r pin-t mt-4 absolute',
                                )}
                              >
                                {folder.contents.length}
                              </Avatar>
                            }
                          </FuseAnimate>
                        ) : (
                          <Icon
                            className='text-20 pin-r absolute mr-8'
                            color='secondary'
                          >
                            check_circle_outline
                          </Icon>
                        )}
                        {folder.contents.length > 0 &&
                          currentFolder !== `folder-${folder.Folder}` && (
                            <Icon
                              className='mr-40 pin-r absolute'
                              style={{ marginTop: 2 }}
                            >
                              keyboard_arrow_right
                            </Icon>
                          )}
                      </Typography>
                    </div>
                    {currentFolder === `folder-${folder.Folder}` && (
                      <DispatchBoardFolderContent
                        data={folder.contents}
                        folder={folder}
                        openFolder={this.props.openEditDispatchFolderDialog}
                        canEdit={
                          accessLevel &&
                          accessLevel.AccessLevel !== 'R' &&
                          (accessLevel.AccessLevel === 'F' ||
                            folder.AddedBy === user.UserName)
                        }
                        loading={loading}
                        byScope={true}
                      />
                    )}
                    <Divider className='mt-12' />
                  </div>
                )
              }
            })}
          {((!currentFolder && onHold.length > 0) ||
            currentFolder === 'OnHold') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'OnHold')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'OnHold' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  On Hold
                  {(loading || onHold.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8 pin-t mt-4'>
                      refresh
                    </Icon>
                  ) : onHold.length > 0 ? (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatarGrey,
                            'pin-r absolute pin-t mt-4',
                          )}
                        >
                          {onHold.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  ) : (
                    <Icon
                      className='text-20 pin-r absolute mr-8'
                      color='secondary'
                    >
                      check_circle_outline
                    </Icon>
                  )}
                  {onHold.length > 0 && currentFolder !== 'OnHold' && (
                    <Icon
                      className='mr-40 pin-r absolute'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
              </div>
              {currentFolder === 'OnHold' && (
                <DispatchBoardFolderContent
                  showHold={true}
                  data={onHold}
                  loading={loading}
                  byScope={true}
                />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {(!currentFolder || currentFolder === 'Scheduled') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'Scheduled')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'Scheduled' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  Scheduled
                  {(loading || assigned.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8 pin-t mt-4'>
                      refresh
                    </Icon>
                  ) : (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatarGrey,
                            'pin-r absolute pin-t mt-4',
                          )}
                        >
                          {assigned.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  )}
                  {assigned.length > 0 && currentFolder !== 'Scheduled' && (
                    <Icon
                      className='mr-40 pin-r absolute'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
              </div>
              {currentFolder === 'Scheduled' && (
                <DispatchBoardFolderContent data={assigned} loading={loading} />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {((!currentFolder && ongoing.length > 0) ||
            currentFolder === 'Ongoing') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'Ongoing')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'Ongoing' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  Ongoing
                  {(loading || ongoing.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8 pin-t mt-4'>
                      refresh
                    </Icon>
                  ) : ongoing.length > 0 ? (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatarGrey,
                            'pin-r absolute pin-t mt-4',
                          )}
                        >
                          {ongoing.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  ) : (
                    <Icon
                      className='text-20 pin-r absolute mr-8'
                      color='secondary'
                    >
                      check_circle_outline
                    </Icon>
                  )}
                  {ongoing.length > 0 && currentFolder !== 'Ongoing' && (
                    <Icon
                      className='mr-40 pin-r absolute'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
              </div>
              {currentFolder === 'Ongoing' && (
                <DispatchBoardFolderContent
                  data={ongoing}
                  loading={loading}
                  byScope={true}
                />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {((!currentFolder && dateException.length > 0) ||
            currentFolder === 'DateException') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'DateException')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'DateException' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  Date Exception
                  {(loading || dateException.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8 pin-t mt-4'>
                      refresh
                    </Icon>
                  ) : dateException.length > 0 ? (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatarGrey,
                            'pin-r absolute pin-t mt-4',
                          )}
                        >
                          {dateException.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  ) : (
                    <Icon
                      className='text-20 pin-r absolute mr-8'
                      color='secondary'
                    >
                      check_circle_outline
                    </Icon>
                  )}
                  {dateException.length > 0 &&
                    currentFolder !== 'DateException' && (
                      <Icon
                        className='mr-40 pin-r absolute'
                        style={{ marginTop: 2 }}
                      >
                        keyboard_arrow_right
                      </Icon>
                    )}
                </Typography>
              </div>
              {currentFolder === 'DateException' && (
                <DispatchBoardFolderContent
                  data={dateException}
                  loading={loading}
                  byScope={true}
                />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {((!currentFolder && canceled.length > 0) ||
            currentFolder === 'Canceled') && (
            <div>
              <div className='w-full'>
                <Typography
                  onClick={e => this.toggleList(e, 'Canceled')}
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex text-16 mt-12 ml-8 cursor-pointer relative'
                >
                  {currentFolder === 'Canceled' && (
                    <Icon
                      className='align-middle mb-2'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_left
                    </Icon>
                  )}
                  Canceled
                  {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                  {(loading || canceled.length > 0) && loading ? (
                    <Icon className='text-20 spin pin-r absolute mr-8'>
                      refresh
                    </Icon>
                  ) : canceled.length > 0 ? (
                    <FuseAnimate animation='transition.expandIn' delay={300}>
                      {
                        <Avatar
                          className={classNames(
                            classes.avatarGrey,
                            'pin-r pin-t mt-4 absolute',
                          )}
                        >
                          {canceled.length}
                        </Avatar>
                      }
                    </FuseAnimate>
                  ) : (
                    <Icon
                      className='text-20 pin-r absolute mr-8'
                      color='secondary'
                    >
                      check_circle_outline
                    </Icon>
                  )}
                  {currentFolder !== 'Canceled' && canceled.length > 0 && (
                    <Icon
                      className='mr-40 pin-r absolute'
                      style={{ marginTop: 2 }}
                    >
                      keyboard_arrow_right
                    </Icon>
                  )}
                </Typography>
              </div>
              {currentFolder === 'Canceled' && (
                <DispatchBoardFolderContent data={canceled} loading={loading} />
              )}
              <Divider className='mt-12' />
            </div>
          )}
          {!currentFolder && accessLevel && accessLevel.AccessLevel !== 'R' && (
            <Typography
              onClick={this.props.openNewDispatchFolderDialog}
              variant='h6'
              className='text-12 w-full text-right mt-16 pr-12 cursor-pointer'
            >
              <Icon className='align-middle text-16 mr-6 mb-4' color='primary'>
                add_circle_outline
              </Icon>
              New Folder
            </Typography>
          )}
        </div>
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setWorkOrders: Actions.setWorkOrders,
      openNewDispatchFolderDialog: Actions.openNewDispatchFolderDialog,
      openEditDispatchFolderDialog: Actions.openEditDispatchFolderDialog,
      setBoardDate: Actions.setBoardDate,
      getWOData: Actions.getWOData,
      getTripData: Actions.getTripData,
      showMessage,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, dispatchBoardApp }) {
  return {
    Co: spReducers.companies.Co,
    user: spReducers.userProfiles.User,
    date: dispatchBoardApp.dispatchBoard.date,
    //searchText: dispatchBoardApp.dispatchBoard.searchText,
    WorkOrders: spReducers.workOrders,
    workOrderList: dispatchBoardApp.dispatchBoard.workOrderList,
    workOrders: dispatchBoardApp.dispatchBoard.workOrders,
    loading: dispatchBoardApp.dispatchBoard.loading,
    selectedBoard: dispatchBoardApp.dispatchBoard.selectedBoard,
    securables: spReducers.userProfiles.User.Data.Securables,
    customBoards: spReducers.boards,
    customFolders: spReducers.folders,
    trips: dispatchBoardApp.dispatchBoard.trips,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(DispatchBoardLeftSidebarContent),
  ),
)
