import React, { Component } from 'react';
import {
    TextField, Button, CircularProgress, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { connect } from 'react-redux';
import Media from 'react-media';
import axios from 'axios';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newLaborTypeState = {
    Co: null,
    BusinessUnit: '',
    Division: '',
    LaborType: 0,
    Name: '',
    Description: '',
    ActiveYN: 'Y',
    Notes: '',
    Type: 'W',
    StandardUnitCost: 0.00,
    Data: {

    },
    VPCompany: null,
    VPEarnCode: null,
    QBOCompany: null,
    QBOService: null,
    isSearching: false,
    searchDescription: null,
    vpEarnCodes: [],
};

class LaborTypeDialog extends Component {
    state = { ...newLaborTypeState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { vpCompany } = this.props;
        /**
         * After Dialog Open
         */
        const initialState = _.cloneDeepWith(newLaborTypeState);
        initialState.Co = this.props.Co;
        if (!prevProps.laborTypeDialog.props.open && this.props.laborTypeDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.laborTypeDialog.type === 'edit' &&
                this.props.laborTypeDialog.data &&
                !_.isEqual({ ...initialState, ...this.props.laborTypeDialog.data }, prevState)) {
                this.setState({ ...initialState, ...this.props.laborTypeDialog.data }, () => {
                    if (vpCompany && this.state.VPEarnCode) {
                        this.handleEarnCodeSearch();
                    }
                });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.laborTypeDialog.type === 'new' &&
                !_.isEqual(initialState, prevState)) {
                this.setState({ ...initialState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.laborTypeDialog.type === 'edit' ? this.props.closeEditLaborTypeDialog() : this.props.closeNewLaborTypeDialog();
    };

    canBeSubmitted() {
        const { Name, Description, BusinessUnit, Division } = this.state;
        return (
            Name.length > 0 && Description.length > 0 && BusinessUnit.length > 0 && Division.length > 0
        );
    }

    handleEarnCodeSearch = _.debounce(() => {
        const { vpCompany } = this.props;
        const { HQCo1 } = (vpCompany || {});
        const { searchDescription, VPEarnCode } = this.state;
        if (VPEarnCode || (searchDescription && searchDescription.length > 0)) {
            const inputValue = (searchDescription || VPEarnCode.toString()).trim().toLowerCase();
            this.setState({ isSearching: true }, () => {
                axios.get(`${window["apiLocation"]}/api/VPSettings/EarnCodes?Co=${HQCo1}&search=${encodeURIComponent(inputValue)}`).then((response) => {
                    this.setState({ vpEarnCodes: response.data, isSearching: false });
                }).catch(() => this.setState({ isSearching: false, vpEarnCodes: [] }));
            })
        } else {
            this.setState({ vpEarnCodes: [] });
        }
    }, 750);

    render() {
        const { classes, securables, laborTypeDialog, addLaborType, updateLaborType, removeLaborType, businessUnits, divisions, vpCompany, earnCodes, qboCompany, qboServices } = this.props;
        const { searchDescription, vpEarnCodes, isSearching } = this.state;
        const accessLevel = _.find(securables, { Securable: "labor-types" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...laborTypeDialog.props}
                        open={Boolean(accessLevel) && laborTypeDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {laborTypeDialog.type === 'new' ? 'New Labor Type' : `Labor Type #${this.state.LaborType}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Business Unit"

                                    select
                                    id="businessUnit"
                                    name="BusinessUnit"
                                    value={this.state.BusinessUnit}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(laborTypeDialog.type === 'new' ? false : true)}
                                >
                                    {(businessUnits && businessUnits.length > 0 ? businessUnits.map((value) => (
                                        <MenuItem value={value.BusinessUnit}>
                                            {value.Description}
                                        </MenuItem>
                                    )) : '')}
                                </TextField>
                            </div>

                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Division"
                                    id="division"
                                    select
                                    name="Division"
                                    value={this.state.Division}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(laborTypeDialog.type === 'new' ? false : true)}
                                >
                                    {(this.state.BusinessUnit && this.state.BusinessUnit.length > 0 ?
                                        (divisions && divisions.length > 0 ? divisions.map((value) => (
                                            (this.state.BusinessUnit == value.BusinessUnit ?
                                                <MenuItem value={value.Division}>
                                                    {value.Description}
                                                </MenuItem> : '')
                                        )) : '')
                                        :
                                        <MenuItem value="">
                                            Please Select a Business Unit
                                        </MenuItem>
                                    )
                                    }
                                </TextField>
                            </div>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Name"

                                    id="name"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Category"
                                    id="type"
                                    name="Type"
                                    value={this.state.Type}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    select
                                >
                                    <MenuItem value="W">
                                        Work Order
                                    </MenuItem>
                                    <MenuItem value="I">
                                        Indirect
                                    </MenuItem>
                                    <MenuItem value="O">
                                        Other
                                    </MenuItem>
                                </TextField>
                            </div>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Standard Unit Cost"
                                    id="unitCost"
                                    name="StandardUnitCost"
                                    value={this.state.StandardUnitCost}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        type: "number"
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            {vpCompany &&
                                <div className="flex relative">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="VP Earn Code"
                                        options={vpEarnCodes}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.EarnCode}>
                                                {`${value.EarnCode} - ${value.Description}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        debounce={750}
                                        onChange={(text) => this.setState({ ...this.state, searchDescription: text }, this.handleEarnCodeSearch)}
                                        value={this.state.VPEarnCode || ''}
                                        onSelect={(option) => this.setState({ ...this.state, VPCompany: option.PRCo, VPEarnCode: option.EarnCode })}
                                        required
                                    />
                                    {isSearching &&
                                        <CircularProgress color="primary" className="absolute pin-r pin-t m-auto" style={{ width: 20, height: 20, marginRight: 9, marginTop: 18 }} />
                                    }
                                </div>
                            }
                            {qboCompany &&
                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="QBO Service"
                                        options={qboServices}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.Id}>
                                                {`${value.Name}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        value={this.state.QBOService || ''}
                                        onSelect={(option) => { if (laborTypeDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOService: option.Id }) } else { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOService: option.Id }) } }}
                                    // required
                                    />
                                </div>
                            }

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {laborTypeDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addLaborType(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updateLaborType(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditLaborTypeDialog: Actions.closeEditLaborTypeDialog,
        closeNewLaborTypeDialog: Actions.closeNewLaborTypeDialog,
        addLaborType: Actions.addLaborType,
        updateLaborType: Actions.updateLaborType,
        removeLaborType: Actions.removeLaborType
    }, dispatch);
}

function mapStateToProps({ laborTypesApp, spReducers }) {
    return {
        laborTypeDialog: laborTypesApp.laborTypes.laborTypeDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
        vpCompany: spReducers.vpSettings.vpCompany,
        earnCodes: spReducers.vpSettings.earnCodes,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboServices: spReducers.qboSettings.services,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(LaborTypeDialog));
