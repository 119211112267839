import axios from 'axios/index';
import { updateInList } from 'main/functions';

export const SET_AGENT_STATUS_DATA = '[SPCONNECTION] SET AGENT STATUS DATA';
export const SET_AGENT_STATUSES_DATA = '[SPCONNECTION] SET AGENT STATUSES DATA';
export const GET_AGENT_STATUS_DATA = '[SPCONNECTION] GET AGENT STATUS DATA';
export const GET_AGENT_STATUSES_DATA = '[SPCONNECTION] GET AGENT STATUSES DATA';
export const SET_AGENT_STATUS_LIST_DATA = '[SPCONNECTION] SET AGENT STATUS LIST DATA';
export const GET_AGENT_STATUS_LIST_DATA = '[SPCONNECTION] GET AGENT STATUS LIST DATA';
export const SUBSCRIBE_AGENT_STATUS_LIST = '[SPCONNECTION] SUBSCRIBE AGENT STATUS LIST';

export function getAgentStatusListData() {
    const request = axios.get(`${window["apiLocation"]}/api/AgentStatusList`);

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_AGENT_STATUS_LIST_DATA,
                payload: response.data
            })
        });
    }
}

export function getAgentStatusesData() {
    const request = axios.get(`${window["apiLocation"]}/api/AgentStatus`);

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_AGENT_STATUSES_DATA,
                payload: response.data,
            })
        });
    }
}

export function updateAgentStatus(obj, method, type) {

    return (dispatch, getState) => {
        const state = getState();
        const { Agents } = state.spReducers.agentStatus;
        const { UserName } = obj;

        const payload = updateInList(Agents, obj, method, { UserName })

        dispatch({ type, payload });


    }
}

export function getAgentStatusData(UserName) {
    const request = axios.get(`${window["apiLocation"]}/api/AgentStatus`, {
        params: {
            UserName
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_AGENT_STATUS_DATA,
                payload: response.data,
            })
        });
    }
}

export function setAgentStatusData(data) {
    return {
        type: SET_AGENT_STATUS_DATA,
        payload: data
    };
}

export function setAgentStatusesData(data) {
    return {
        type: SET_AGENT_STATUSES_DATA,
        payload: data
    };
}