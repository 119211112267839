import { Dialog, Grow, Slide, Icon, IconButton, AppBar, Toolbar, Typography, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SPRoundIcon } from 'main/icons';
import CatalogOptionBoardOption from './CatalogOptionBoardOption';
import _ from 'lodash';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {

    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
});

class OptionBoardOptionDialog extends Component {

    state = {
        data: null,
        catalog: null,
        open: false,
    }

    componentDidMount() {
        const { open, data, catalog } = this.props;
        this.setState({ ...this.state, data, catalog, open });
    }

    componentDidUpdate(prevProps) {
        const { open, data, catalog } = this.props;
        if (!_.isEqual(data, prevProps.data) || !_.isEqual(catalog, prevProps.catalog) || !_.isEqual(open, prevProps.open)) {
            this.setState({ ...this.state, data, catalog, open });
        }
    }

    closeDialog = () => {
        this.props.onClose();
    };

    render() {
        const { classes, selected, optionBoard, isMember, SelectedPricing } = this.props;
        const { data, catalog, open } = this.state;

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        className={classes.root}
                        open={open}
                        onClose={this.closeDialog}
                        fullWidth
                        maxWidth="md"
                        fullScreen={!matches}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    <SPRoundIcon className="text-32 align-middle mr-8" />
                                    {data && `${data.Name}`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'white'
                            }}
                                onClick={this.closeDialog}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        <DialogContent className={"p-12 px-24 pb-48"}>
                            <div className="w-full py-12">
                                {data &&
                                    <CatalogOptionBoardOption source="dialog" Discount IncludeTaxYN={this.props.includeTaxYN} selected={selected} isMember={isMember} optionBoard={{ ...optionBoard }} data={{ ...data, ComponentDetail: 'S' }} SelectedPricing={SelectedPricing} />
                                }
                            </div>
                        </DialogContent>
                    </Dialog>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ catalogApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(OptionBoardOptionDialog));
