import axios from 'axios/index';

export const SET_VENDOR_DATA = '[SPCONNECTION] SET VENDOR DATA';
export const GET_VENDOR_DATA = '[SPCONNECTION] GET VENDOR DATA';
export const SUBSCRIBE_VENDOR_LIST = '[SPCONNECTION] SUBSCRIBE VENDOR LIST';

export function getInitialVendorData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Vendor`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getVendorData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/Vendor`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VENDOR_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_VENDOR_LIST,
            Co
        }));
    }
}

export function setVendorData(data) {
    return {
        type: SET_VENDOR_DATA,
        payload: data
    };
}