import _ from '@lodash';
import { Button, Card, CardContent, Chip, DialogActions, Divider, Drawer, FormControlLabel, Icon, IconButton, MenuItem, Switch, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import clsx from 'clsx';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import * as rounded from 'highcharts-rounded-corners/rounded-corners';
import drilldown from 'highcharts/modules/drilldown';
import moment from 'moment';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { guid, showMessage } from 'store/actions';
import format from 'string-template';
import Autocomplete from '../../../components/autocomplete/Autocomplete';
import { openEditCustomerSiteDialog } from '../../customer-sites/store/actions';
import { openEditCustomerDialog } from '../../customers/store/actions';
import { openEditTripDialog } from '../../trips/store/actions';
import { openEditWorkOrderScopeDialog } from '../../work-order-scopes/store/actions';
import { openEditWorkOrderDialog } from '../../work-orders/store/actions';
import * as Actions from '../store/actions/dashboard.actions';

// const ReactHighcharts = require('react-highcharts');

const loose = (obj) => {
    return Function('"use strict";return (' + obj + ')')();
}

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
    },
    dialogRoot: {

    },
    profileAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100% - 64px)',
        top: 64,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    columnButton: {
        '& .edit': {
            display: 'none',
        },
        '&:hover .edit': {
            display: 'block',
        }
    },
    chart: {
        '& .cell': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
        '& .cell .add-icon': {
            fontSize: 36,
            color: '#f1f1f1',
        },
        '& .cell:hover .add-icon': {
            color: theme.palette.secondary.main
        }
    },
    drawer: {
        width: 0,
        height: 510,
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
});

const newChartState = {
    ID: guid(),
    type: null,
    title: null,
    pointFormat: null,
    results: [],
    columns: [],
    groupBy: [],
    calculations: [],
    aggregations: [],
    categories: [],
    calculation: {},
    category: null,
    aggregation: null,
    drawerOpen: false,
    height: undefined,
    width: undefined,
    chart: null,
    isRounded: false,
    polar: false,
    filters: {},
    drilled: {},
}

class Chart extends Component {

    state = {
        ..._.cloneDeepWith(newChartState)
    };

    componentDidMount() {
        HighchartsMore(Highcharts);
        rounded(Highcharts);
        drilldown(Highcharts);
        let { results, options, height, width } = this.props;
        if (options && results) {
            const { Options, ID } = options;
            const chart = this.buildChart(results, Options);
            this.setState({ ..._.cloneDeepWith(newChartState), ID, results, height, width, ...chart }, this.redrawChart);
        } else {
            if (results) {
                const columns = this.getColumns(results);
                this.setState({ ..._.cloneDeepWith(newChartState), height, width, results, columns }, this.redrawChart);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { results, options, height, width } = this.props;
        if (!_.isEqual(results, prevProps.results) || !_.isEqual(options, prevProps.options) || !_.isEqual(height, prevProps.height) || !_.isEqual(width, prevProps.width)) {
            if (options && results) {
                const { Options, ID } = options;
                const chart = this.buildChart(results, Options);
                window["warn"]('Results have been reset: ', results, chart);
                this.setState({ ..._.cloneDeepWith(newChartState), ...this.state, ID, results, height, width, ...chart }, this.redrawChart);
            } else {
                if (results) {
                    const columns = this.getColumns(results);
                    this.setState({ ..._.cloneDeepWith(newChartState), ...this.state, results, height, width, columns }, this.redrawChart);
                }
            }
        }
    }

    redrawChart = () => {
        const { chart } = _.cloneDeepWith(this.state);
        if (chart) {
            chart.update(this.getConfig());
        }
    }

    componentWillUnmount() {
        const { options, drilled } = this.props;
        const { ID } = _.cloneDeepWith(this.state);
        if (drilled[`Component${ID}`]) {
            delete drilled[`Component${ID}`];
            this.props.setDashboardDrilled(drilled);
        }
        this.setState({ ..._.cloneDeepWith(newChartState) });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    buildChart = (data, options) => {
        const Options = JSON.parse(options);
        const { type, title, aggregations, categories, calculations, pointFormat, isRounded, polar } = Options;
        const columns = this.getColumns(data);
        return {
            columns,
            type,
            title,
            pointFormat,
            aggregations: aggregations || [],
            categories: categories || [],
            calculations: calculations || [],
            isRounded,
            polar
        }
    }

    getValue = (aggregation) => {
        const { results } = _.cloneDeepWith(this.state);
        const value = _[aggregation.aggregation](results, aggregation.column);
        return value;

    }

    getValues(results, aggregations, calculations) {
        const values = {};
        aggregations.map((aggregation) => {
            const { id } = aggregation;
            aggregation.value = _[aggregation.aggregation](results, aggregation.column);
            values[id] = aggregation.value;
        });
        calculations.map((calculation) => {
            const { id } = calculation;
            calculation.value = loose(`${format(calculation.expression, values)}`);
            values[id] = calculation.value;
        });
        return (aggregations, calculations, values);
    }

    saveChart = () => {
        const { type, title, aggregations, categories, calculations, pointFormat, isRounded, polar } = _.cloneDeepWith(this.state);

        const options = {
            type,
            title,
            pointFormat,
            aggregations,
            categories,
            calculations,
            isRounded,
            polar
        };

        if (this.props.onAdded) {
            if (!this.props.options || !this.props.options.ID || !this.props.options.Component) {
                this.props.onAdded(JSON.stringify(options));
            }
        }

        if (this.props.onUpdated) {
            if (this.props.options && this.props.options.ID && this.props.options.Component) {
                this.props.onUpdated(JSON.stringify(options));
            }
        }
    }

    getColumns(data) {
        const columns = Object.keys(data[0]).map(key => {
            return {
                Header: key,
                headerClassName: 'font-bold text-12',
                accessor: key,
                id: key,
                dataType: !isNaN(data[0][key]) ? 'number' : key.indexOf('Date') > -1 ? 'date' : 'text'
            };
        });
        return columns;
    }

    formatNumber = (num) => {
        return Intl.NumberFormat('en-US').format(num);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    formatPercentage = (num) => {
        if (!isNaN(num)) {
            return `${Number(num).toFixed(2)}%`
        } else {
            return num;
        }
    }

    drillDown = e => {
        const { categories, results, ID, drilled, filters } = _.cloneDeepWith(this.state);
        if (!drilled[`Component${ID}`]) {
            drilled[`Component${ID}`] = [];
        }
        const drilling = drilled[`Component${ID}`];
        const index = drilling.length;
        const category = categories[index];
        const value = e.point.category;
        if (index < categories.length - 1) {
            drilling.push({ ...category, value });
            filters[category.column] = value;
            // window["warn"](e, category, value, drilling, categories, index);
            // this.props.setDashboardDrilled(drilled);
            // this.props.setDashboardFilters(filters);
            this.setState({ ...this.state, drilled, filters });
        } else {
            // switch (category.column) {
            //     case 'WorkOrder': {
            //         const { Co } = this.props;
            //         const { WorkOrder } = e.point.originals[0];
            //         if (!isNaN(WorkOrder)) {
            //             this.props.openEditWorkOrderDialog({ Co, WorkOrder });
            //         }
            //     }
            //         break;
            // }
        }
    }
    drillUp = () => {
        const { ID, drilled, filters } = _.cloneDeepWith(this.state);
        // const { drilled, filters } = this.props;
        if (!drilled[`Component${ID}`]) {
            drilled[`Component${ID}`] = [];
        }
        const drilling = drilled[`Component${ID}`];
        if (drilling.length > 0) {
            let current = drilling[drilling.length - 1];
            delete filters[current.column];
            drilling.splice(drilling.length - 1, 1);
            // this.props.setDashboardDrilled(drilled);
            // this.props.setDashboardFilters(filters);
            this.setState({ ...this.state, drilled, filters })
        }
    }

    getConfig = () => {
        const colors = ['#4dbce9', '#d1e751', '#3f3f3f', '#87efff', '#ffff83', '#5f5f5f', '#4dbce9', '#9db516', '#7f7f7f'];
        const { title, type, results, categories, isRounded, polar, aggregations, height, columns, pointFormat, ID, drilled, filters } = _.cloneDeepWith(this.state);
        // const { drilled, filters } = this.props;
        const drilling = drilled[`Component${ID}`] || [];
        const series = [];
        const index = drilling.length;
        const category = categories[index];
        if (results && category && category.column) {

            const entries = _.filter(results, { ...filters }).map((result) => { return result[category.column] });
            const cats = _.uniq(entries);
            const dataSets = {};

            cats.map((cat, catIndex) => {
                let filtered = _.filter(results, { ...filters });
                filtered = _.filter(filtered, (o) => { return o[category.column] === cat });

                aggregations.map((data) => {
                    const { title, column, aggregation } = data;
                    if (!dataSets[column]) {
                        dataSets[column] = {
                            name: title,
                            aggregation,
                            data: [],
                            type: data.type || type,
                            maxPointWidth: 24,
                            point: {
                                events: {
                                    click: this.drillDown
                                },
                            },

                        };
                    }
                    const y = _[aggregation](filtered, (o) => o[column]);
                    dataSets[column].data.push({ x: catIndex, y, name: cat });
                });
            });

            Object.keys(dataSets).map((seriesData) => {
                series.push(dataSets[seriesData]);
            });

            const column = _.find(columns, { id: category.column });
            const ifRounded = isRounded ? {
                borderRadiusTopLeft: '50%',
                borderRadiusTopRight: '50%',
                borderRadiusBottomLeft: '50%',
                borderRadiusBottomRight: '50%'
            } : {};
            const ifPolar = polar ? {
                series: {
                    pointStart: 0,
                    pointInterval: 45
                },
                column: {
                    pointPadding: 0,
                    groupPadding: 0
                }
            } : {
                series: {},
                column: {}
            }
            const zoom = cats.length > 8 ? {
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        x: -10,
                        y: 10
                    },
                    relativeTo: 'chart',
                    theme: {
                        fill: '#3f3f3f',
                        stroke: 'silver',
                        style: {
                            color: '#fff',
                            borderRadius: '6px',
                        },
                        states: {
                            hover: {
                                fill: '#4f4f4f'
                            }
                        }
                    }
                }
            } : {};
            const config = {
                chart: {
                    type,
                    polar,
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height,
                    ...zoom
                },
                colors,
                title: {
                    text: title,
                },
                subtitle: {
                    text: category.title,
                },
                plotOptions: {
                    series: {
                        borderRadiusTopLeft: '10%',
                        borderRadiusTopRight: '10%',
                        borderRadiusBottomLeft: '10%',
                        borderRadiusBottomRight: '10%',
                        ...ifRounded,
                        ...ifPolar.series
                    },
                    column: {
                        ...ifPolar.column
                    }
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: pointFormat === 'D' ? '{series.name}: ${point.y:,.2f}' : '{series.name}: {point.y:,.2f}'
                },
                xAxis: {
                    categories: cats,
                    labels: {
                        formatter: (e) => {
                            if (column.dataType === 'date') {
                                return column.dataType === 'date' ? moment(e.value).format('M/D/YYYY') : e.value;
                            } else {
                                if (['Technician', 'TeamLead', 'TeamMember'].indexOf(column.id) > -1) {
                                    return this.getTechnician(e.value);
                                } else {
                                    return e.value;
                                }
                            }
                        },
                        rotation: !polar ? -45 : 0
                    }
                },
                yAxis: {
                    gridLineColor: '#f1f1f1',
                    title: {
                        text: null
                    }
                },
                series,
                credits: {
                    enabled: false
                },
                isPureConfig: true,
            }
            window["warn"](config);
            return config;
        }
    }

    getTechnician = (Technician) => {
        const value = _.find(this.props.technicians, { Co: this.props.Co, Technician });
        if (value) {
            return `${value.FirstName} ${value.LastName.substr(0, 1)}`
        } else {
            return Technician;
        }
    }

    getCategories = (key) => {
        const { results } = _.cloneDeepWith(this.state);
        const entries = results.map((result) => { return result[key] });
        return _.uniq(entries);
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    addUpdateCalculation = (calculation) => {
        const { calculations } = _.cloneDeepWith(this.state);
        calculation.id = calculation.id || guid();
        const { id } = calculation;
        const index = _.findIndex(calculations, { id });
        if (index > -1) {
            calculations.splice(index, 1, calculation);
        } else {
            calculations.push(calculation);
        }
        this.setState({ ...this.state, calculations, calculation: {} });
    }

    addUpdateCategory = (category) => {
        const { categories } = _.cloneDeepWith(this.state);
        const { column } = category;
        if (!category.id) {
            category.id = `${column}${category.category ? category.category.replace('By', '').toUpperCase() : ''}`;
        }
        const { id } = category;
        const index = _.findIndex(categories, { id });
        if (index > -1) {
            categories.splice(index, 1, category);
        } else {
            categories.push(category);
        }
        this.setState({ ...this.state, categories, category: null });
    }

    removeCategory = (category) => {
        const { categories } = _.cloneDeepWith(this.state);
        const { id } = category;
        const index = _.findIndex(categories, { id });
        if (index > -1) {
            categories.splice(index, 1);
            this.setState({ ...this.state, categories });
        }
    }

    addUpdateAggregation = (aggregation) => {
        const { aggregations } = _.cloneDeepWith(this.state);
        const { column } = aggregation;
        if (!aggregation.id) {
            aggregation.id = `${column}${aggregation.aggregation ? aggregation.aggregation.replace('By', '').toUpperCase() : ''}`;
        }
        const { id } = aggregation;
        const index = _.findIndex(aggregations, { id });
        if (index > -1) {
            aggregations.splice(index, 1, aggregation);
        } else {
            aggregations.push(aggregation);
        }
        this.setState({ ...this.state, aggregations, aggregation: null });
    }

    removeAggregation = (aggregation) => {
        const { aggregations } = _.cloneDeepWith(this.state);
        const { id } = aggregation;
        const index = _.findIndex(aggregations, { id });
        if (index > -1) {
            aggregations.splice(index, 1);
            this.setState({ ...this.state, aggregations });
        }
    }

    removeCalculation = (calculation) => {
        const { calculations } = _.cloneDeepWith(this.state);
        const { id } = calculation;
        const index = _.findIndex(calculations, { id });
        if (index > -1) {
            calculations.splice(index, 1);
            this.setState({ ...this.state, calculations });
        }
    }

    getChart = () => {
        const { height } = _.cloneDeepWith(this.state);
        const config = this.getConfig();
        if (config && config.chart && config.chart.type && config.xAxis && config.xAxis.categories && config.series) {
            return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={config}
                    containerProps={{
                        style: {
                            height
                        }
                    }}
                    callback={(chart) => this.setState({ ...this.state, chart })}
                />
            );
        }

    }

    render() {
        const { classes, edit } = this.props;
        const { columns, type, title, category, categories, aggregations, aggregation, drawerOpen, pointFormat, isRounded, polar, ID } = this.state;
        const drilled = this.state.drilled[`Component${ID}`] || [];
        const charts = [{ type: 'column', name: 'Column Chart' }, { type: 'bar', name: 'Bar Chart' }, { type: 'line', name: 'Line Chart' }, { type: 'area', name: 'Area Chart' }, { type: 'spline', name: 'Spline Chart' }, { type: 'areaspline', name: 'Area Spline Chart' }, { type: 'pie', name: 'Pie Chart' }]
        const seriesCharts = [{ type: 'column', name: 'Column Chart' }, { type: 'bar', name: 'Bar Chart' }, { type: 'line', name: 'Line Chart' }, { type: 'area', name: 'Area Chart' }, { type: 'spline', name: 'Spline Chart' }, { type: 'areaspline', name: 'Area Spline Chart' }]
        return (
            <div id={`Chart${ID}`}>
                {edit &&
                    <div className="w-full">
                        <div className={classNames(classes.chart, "w-full relative h-512 rounded-6 overflow-auto p-24")} style={{ border: '1px solid lightgrey', backgroundColor: '#f1f1f1' }} onClick={() => this.setState({ ...this.state, drawerOpen: false })}>
                            <Drawer
                                anchor="right"
                                variant="permanent"
                                className={clsx(classNames(classes.drawer, "pin-r absolute"), {
                                    [classes.drawerOpen]: drawerOpen,
                                    [classes.drawerClose]: !drawerOpen
                                })}
                                classes={{
                                    paper: clsx("relative border-none h-full", {
                                        [classes.drawerOpen]: drawerOpen,
                                        [classes.drawerClose]: !drawerOpen
                                    })
                                }}
                                open={drawerOpen}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div tabIndex={0}>
                                    {drawerOpen &&
                                        <div className="p-12 relative">
                                            <Typography className="mb-4 w-full font-bold text-12">
                                                <Icon color="primary" className="mr-4 align-middle mb-6 text-16">insert_chart</Icon>{`Chart Settings`}
                                            </Typography>
                                            <IconButton onClick={() => this.setState({ ...this.state, drawerOpen: false })} className="pin-r pin-t absolute mt-4 mr-8 p-4">
                                                <Icon>close</Icon>
                                            </IconButton>
                                            <div className="w-full">
                                                <Autocomplete
                                                    className={`w-full`}
                                                    title="Chart Type"
                                                    options={[...charts]}
                                                    menuItemComponent={(option) => {
                                                        return (
                                                            <MenuItem value={option.type}>
                                                                <div className="flex">
                                                                    {option.name}
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    }}
                                                    portal={true}
                                                    value={type || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, type: option.type })}
                                                />
                                                {['bar', 'column'].indexOf(type) > -1 &&
                                                    <FormControlLabel
                                                        style={{ float: 'left', }}
                                                        control={
                                                            <Switch
                                                                checked={isRounded}
                                                                name="isRounded"
                                                                onChange={() => this.setState({ ...this.state, isRounded: !isRounded })}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Rounded"
                                                    />
                                                }
                                                <FormControlLabel
                                                    style={{ float: 'right', }}
                                                    control={
                                                        <Switch
                                                            checked={polar}
                                                            name="polar"
                                                            onChange={() => this.setState({ ...this.state, polar: !polar })}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Radar"
                                                />
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Title"
                                                    id="title"
                                                    name="title"
                                                    value={title || ''}
                                                    onChange={this.handleChange}
                                                    inputProps={{
                                                        autoComplete: "off"
                                                    }}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Point Format"
                                                    id="format"
                                                    name="pointFormat"
                                                    value={pointFormat || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    select
                                                >
                                                    <MenuItem value={null}>
                                                        Default
                                                    </MenuItem>
                                                    <MenuItem value="P">
                                                        Percentage
                                                    </MenuItem>
                                                    <MenuItem value="D">
                                                        Dollars
                                                    </MenuItem>
                                                </TextField>
                                            </div>
                                            <Divider className="mb-12" />
                                            <Typography className="mb-4 w-full font-bold text-12">
                                                <Icon color="primary" className="mr-4 align-middle mb-6 text-16">import_export</Icon>Categories
                                            </Typography>
                                            <div className="w-full">
                                                <Autocomplete
                                                    className="w-full mb-12"
                                                    title="Group By"
                                                    options={columns}
                                                    menuItemComponent={(option) => {
                                                        return (
                                                            <MenuItem value={option.id}>
                                                                <div className="flex">
                                                                    {option.Header}
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    }}
                                                    portal={true}
                                                    value={category ? category.column : null}
                                                    onSelect={(option) => {
                                                        if (!category) {
                                                            this.addUpdateCategory({ column: option.id, title: option.Header });
                                                        } else {
                                                            category.column = option.id;
                                                            this.setState({ ...this.state, category });
                                                        }
                                                    }}
                                                />
                                                {
                                                    category &&
                                                    <React.Fragment>
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Title"
                                                            id="title"
                                                            name="category.title"
                                                            value={category.title || ''}
                                                            onChange={this.handleChange}
                                                            inputProps={{
                                                                autoComplete: "off"
                                                            }}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                        {_.findIndex(categories, { id: category.id }) > -1 ?
                                                            <Button
                                                                variant="contained"
                                                                className={classNames(classes.black, "mb-12")}
                                                                color="primary"
                                                                onClick={() => this.addUpdateCategory(category)}
                                                                fullWidth
                                                                disabled={!category.column || !category.title}
                                                            >
                                                                Update
                                                            </Button>
                                                            :
                                                            <Button
                                                                variant="contained"
                                                                className={classNames(classes.black, "mb-12")}
                                                                color="primary"
                                                                onClick={() => this.addUpdateCategory(category)}
                                                                fullWidth
                                                                disabled={!category.column || !category.title}
                                                            >
                                                                Add
                                                            </Button>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {categories.map((value) => {
                                                    return (
                                                        <Chip
                                                            deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>}
                                                            onDelete={() => this.removeCategory(value)}
                                                            onClick={() => {
                                                                this.setState({ ...this.state, category: { ...value } })
                                                            }}
                                                            className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")}
                                                            icon={<Icon className={classNames(classes.colorSecondary, "text-16")}>import_export</Icon>}
                                                            label={<span>{value.title}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            <Divider className="mb-12" />
                                            <Typography className="mb-4 w-full font-bold text-12">
                                                <Icon color="primary" className="mr-4 align-middle mb-6 text-16">import_export</Icon>Series
                                            </Typography>
                                            <div className="w-full">
                                                <Autocomplete
                                                    className="w-full mb-12"
                                                    title="Column"
                                                    options={columns}
                                                    menuItemComponent={(option) => {
                                                        return (
                                                            <MenuItem value={option.id}>
                                                                <div className="flex">
                                                                    {option.Header}
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    }}
                                                    portal={true}
                                                    value={aggregation ? aggregation.column : null}
                                                    onSelect={(option) => {
                                                        if (!aggregation) {
                                                            this.addUpdateAggregation({ column: option.id, title: option.Header, aggregation: 'sumBy' });
                                                        } else {
                                                            aggregation.column = option.id;
                                                            this.setState({ ...this.state, aggregation });
                                                        }
                                                    }}
                                                />
                                                {
                                                    aggregation &&
                                                    <React.Fragment>
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Title"
                                                            id="title"
                                                            name="aggregation.title"
                                                            value={aggregation.title || ''}
                                                            inputProps={{
                                                                autoComplete: "off"
                                                            }}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                        <Autocomplete
                                                            className={`w-full ${['bar', 'column'].indexOf(aggregation.type) < 0 ? 'mb-12' : ''}`}
                                                            title="Series Type"
                                                            options={[{ type: null, name: 'Default' }, ...seriesCharts]}
                                                            menuItemComponent={(option) => {
                                                                return (
                                                                    <MenuItem value={option.type}>
                                                                        <div className="flex">
                                                                            {option.name}
                                                                        </div>
                                                                    </MenuItem>
                                                                );
                                                            }}
                                                            portal={true}
                                                            value={aggregation.type || type || ''}
                                                            onSelect={(option) => this.setState({ ...this.state, aggregation: { ...aggregation, type: option.type } })}
                                                        />
                                                        {['bar', 'column'].indexOf(aggregation.type) > -1 &&
                                                            <FormControlLabel
                                                                // style={{ float: 'right', }}
                                                                control={
                                                                    <Switch
                                                                        checked={isRounded}
                                                                        name="isRounded"
                                                                        onChange={() => this.setState({ ...this.state, isRounded: !isRounded })}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Rounded"
                                                            />
                                                        }
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Aggregation Type"
                                                            id="aggregation"
                                                            name="aggregation.aggregation"
                                                            value={aggregation.aggregation || ''}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            select
                                                        >
                                                            <MenuItem value="countBy">
                                                                Count
                                                            </MenuItem>
                                                            <MenuItem value="sumBy">
                                                                Sum
                                                            </MenuItem>
                                                            <MenuItem value="meanBy">
                                                                Average
                                                            </MenuItem>
                                                            <MenuItem value="minBy">
                                                                Minimum
                                                            </MenuItem>
                                                            <MenuItem value="maxBy">
                                                                Maximum
                                                            </MenuItem>
                                                        </TextField>
                                                        {_.findIndex(aggregations, { id: aggregation.id }) > -1 ?
                                                            <Button
                                                                variant="contained"
                                                                className={classNames(classes.black, "mb-12")}
                                                                color="primary"
                                                                onClick={() => this.addUpdateAggregation(aggregation)}
                                                                fullWidth
                                                                disabled={!aggregation.column || !aggregation.title || !aggregation.aggregation}
                                                            >
                                                                Update
                                                            </Button>
                                                            :
                                                            <Button
                                                                variant="contained"
                                                                className={classNames(classes.black, "mb-12")}
                                                                color="primary"
                                                                onClick={() => this.addUpdateAggregation(aggregation)}
                                                                fullWidth
                                                                disabled={!aggregation.column || !aggregation.title || !aggregation.aggregation}
                                                            >
                                                                Add
                                                            </Button>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {aggregations.map((value) => {
                                                    return (
                                                        <Chip
                                                            deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>}
                                                            onDelete={() => this.removeAggregation(value)}
                                                            onClick={() => {
                                                                this.setState({ ...this.state, aggregation: { ...value } })
                                                            }}
                                                            className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")}
                                                            icon={<Icon className={classNames(classes.colorSecondary, "text-16")}>import_export</Icon>}
                                                            label={<span>{value.title}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            {/* <Divider className="mb-12" />
                                            <Typography className="mb-4 w-full font-bold text-12">
                                                <Icon color="primary" className="mr-4 align-middle mb-6 text-16">functions</Icon>Calculations
                                            </Typography>
                                            <div className="w-full">
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Title"
                                                    id="title"
                                                    name="calculation.title"
                                                    value={calculation.title || ''}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                                {calculation.title && calculation.title.length > 0 &&
                                                    <React.Fragment>
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Expression"
                                                            id="expression"
                                                            name="calculation.expression"
                                                            value={calculation.expression || ''}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">=</InputAdornment>,
                                                            }}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            multiline
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Format"
                                                            id="format"
                                                            name="calculation.format"
                                                            value={calculation.format || ''}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            select
                                                        >
                                                            <MenuItem value={null}>
                                                                None
                                                            </MenuItem>
                                                            <MenuItem value="P">
                                                                Percentage
                                                            </MenuItem>
                                                            <MenuItem value="D">
                                                                Dollars
                                                            </MenuItem>
                                                        </TextField>
                                                        {!calculation.id ?
                                                            <Button
                                                                variant="contained"
                                                                className={classNames(classes.black, "mb-12")}
                                                                color="primary"
                                                                onClick={() => this.addUpdateCalculation(calculation)}
                                                                fullWidth
                                                                disabled={!calculation.expression || !calculation.title}
                                                            >
                                                                Add
                                                            </Button> :
                                                            <Button
                                                                variant="contained"
                                                                className={classNames(classes.black, "mb-12")}
                                                                color="primary"
                                                                onClick={() => this.addUpdateCalculation(calculation)}
                                                                fullWidth
                                                                disabled={!calculation.expression || !calculation.title}
                                                            >
                                                                Update
                                                            </Button>
                                                        }

                                                    </React.Fragment>
                                                }
                                                {calculations.map((value) => {
                                                    return (
                                                        <Tooltip key={value.id} placement="top" title={`Value=${value.value}`}>
                                                            <Chip deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>} onDelete={() => this.removeCalculation(value)} onClick={() => this.setState({ ...this.state, calculation: { ...value } })} className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")} icon={<Icon className={classNames(classes.colorSecondary, "text-16")}>functions</Icon>} label={<span>{value.title}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>} />
                                                        </Tooltip>
                                                    );
                                                })}
                                            </div> */}
                                        </div>
                                    }
                                </div>
                            </Drawer>
                            <Card
                                onClick={(e) => e.stopPropagation()}
                                className="pin-l pin-r pin-t pin-b absolute m-auto rounded-6 h-full w-full">
                                {this.props.title &&
                                    <React.Fragment>
                                        <Typography className="w-full font-bold text-12 cursor-pointer p-6 pb-0 truncate pr-36" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                            <Icon onClick={this.drillUp} color="secondary" className="mr-4 align-middle mb-4">{drilled.length > 0 ? 'keyboard_arrow_left' : 'insert_chart'}</Icon>
                                            {this.props.title}
                                        </Typography>
                                        <IconButton
                                            style={{
                                                position: 'absolute',
                                                right: 7,
                                                top: 7,
                                            }}
                                            className="p-0"
                                            onClick={() => this.setState({ ...this.state, drawerOpen: true })}
                                        >
                                            <Icon>more_vert</Icon>
                                        </IconButton>
                                    </React.Fragment>
                                }
                                <CardContent classes={{ root: "relative w-full h-full" }}>
                                    <div className="pin-t pin-b pin-l pin-r absolute p-0 m-auto">
                                        {this.getChart()}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                }
                {edit &&
                    <DialogActions className="dialog-actions justify-start p-0 m-0 mt-12">
                        {!this.props.options || !this.props.options.ID || !this.props.options.Component ?
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.saveChart}
                            >
                                Add
                            </Button> :
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.saveChart}
                            >
                                Update
                            </Button>
                        }
                    </DialogActions>
                }
                {!edit &&
                    <div>
                        <Typography className="w-full font-bold text-12 cursor-pointer m-6 mb-0 truncate pr-36" style={{ borderBottom: '1px solid #f1f1f1' }}>
                            <Icon onClick={this.drillUp} color="secondary" className="mr-4 align-middle mb-4">{drilled.length > 0 ? 'keyboard_arrow_left' : 'insert_chart'}</Icon>
                            {this.props.title}
                        </Typography>
                        <div className="">
                            {this.getChart()}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage,
        openEditCustomerDialog,
        openEditCustomerSiteDialog,
        openEditWorkOrderDialog,
        openEditWorkOrderScopeDialog,
        openEditTripDialog,
        setDashboardDrilled: Actions.setDashboardDrilled,
        setDashboardFilters: Actions.setDashboardFilters
    }, dispatch);
}

function mapStateToProps({ spReducers, dashboardBuilderApp }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
        employees: spReducers.employees,
        drilled: dashboardBuilderApp.dashboard.drilled,
        filters: dashboardBuilderApp.dashboard.filters
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Chart)));
