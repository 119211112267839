import * as Actions from 'store/actions/spConnection';
import CacheManager from 'store/middlewares/cache';
import _ from '@lodash';

const offline = new CacheManager();

const initialState = {
    date: new Date(),
    technician: null,
    trips: [],
    allTrips: [],
    performance: [],
    timecard: null,
    timecards: [],
    estimates: {
        Page: 1,
        PageCount: 1,
        Results: [],
        TotalCount: 0,
        PageSize: 25,
    },
    loading: true,
}

const technician = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_TECHNICIAN:
            {
                return {
                    ...state,
                    technician: action.payload
                };
            }
        case Actions.SET_TECHNICIAN_LOADING:
            {
                const { loading } = action;
                return {
                    ...state,
                    loading,
                };
            }
        case Actions.SET_TECHNICIAN_ESTIMATES:
            {
                return {
                    ...state,
                    estimates: action.payload
                };
            }
        case Actions.GET_TECHNICIAN_SCHEDULE:
            {
                const { payload, raw, start } = action;
                return {
                    ...state,
                    trips: payload,
                    allTrips: raw,
                    start,
                    loading: false,
                };
            }
        case Actions.SET_TECHNICIAN_SCHEDULE:
            {
                const { payload, raw, persist } = action;
                return {
                    ...state,
                    trips: payload,
                    allTrips: raw,
                };
            }
        case Actions.GET_TECHNICIAN_TIMECARD:
            {
                return {
                    ...state,
                    timecard: action.payload,
                };
            }
        case Actions.SET_TECHNICIAN_TIMECARD:
            {
                return {
                    ...state,
                    timecard: action.payload
                };
            }
        case Actions.GET_TECHNICIAN_TIMECARDS:
            {
                return {
                    ...state,
                    timecards: action.payload,
                };
            }
        case Actions.SET_TECHNICIAN_TIMECARDS:
            {
                return {
                    ...state,
                    timecards: action.payload
                };
            }
        case Actions.GET_TECHNICIAN_PERFORMANCE:
            {
                return {
                    ...state,
                    performance: action.payload,
                };
            }
        case Actions.SET_TECHNICIAN_PERFORMANCE:
            {
                return {
                    ...state,
                    performance: action.payload
                };
            }
        case Actions.SET_TECHNICIAN_DATE:
            {
                return {
                    ...state,
                    date: action.date ? action.date : state.date,
                };
            }
    }

    return state;
};

export default technician;