import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FusePageCarded, FuseAnimate } from "@fuse";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Actions from "./store/actions";
import VirtualizedDispatchBoard from "main/content/apps/dispatch-board/VirtualizedDispatchBoard";
import DispatchBoard from "main/content/apps/dispatch-board/DispatchBoard";
import DispatchBoardHeader from "main/content/apps/dispatch-board/DispatchBoardHeader";
import DispatchBoardLeftSidebarHeader from "main/content/apps/dispatch-board/DispatchBoardLeftSidebarHeader";
import DispatchBoardLeftSidebarContent from "main/content/apps/dispatch-board/DispatchBoardLeftSidebarContent";
import _ from "@lodash";
import Media from "react-media";
import classNames from "classnames";
import Draggable from "react-draggable";
import { Badge, Button, Fab, Icon, Tooltip } from "@material-ui/core";

const styles = (theme) => ({
  addButton: {
    position: "absolute",
    right: 16,
    bottom: 12,
    zIndex: 99,
    "&:hover": {
      zIndex: 998,
    },
  },
  padButton: {
    position: "absolute",
    right: 16,
    bottom: 68,
    zIndex: 99,
    "&:hover": {
      zIndex: 998,
    },
  },
  badge: {
    top: -8,
    right: -8,
  },
});

class DispatchBoardApp extends Component {
  componentDidMount() {
    // this.props.getUserData();
  }

  componentDidUpdate(prevProps, prevState) {
    //if (!_.isEqual(this.props.location, prevProps.location)) {
    //this.props.getDispatchBoard(this.props.match.params);
    // }
  }

  render() {
    const { classes, calls, location } = this.props;

    const showOld = location.pathname.includes("old");

    return (
      <Media query="(min-width: 1024px)">
        {(matches) => (
          <React.Fragment>
            <FusePageCarded
              classes={{
                root: "w-full",
                content: "flex flex-col",
                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136",
              }}
              header={
                <DispatchBoardHeader pageLayout={() => this.pageLayout} />
              }
              leftSidebarHeader={<DispatchBoardLeftSidebarHeader />}
              content={
                <>
                  {showOld ? <DispatchBoard /> : <VirtualizedDispatchBoard />}
                </>
              }
              leftSidebarContent={
                <DispatchBoardLeftSidebarContent
                  pageLayout={() => this.pageLayout}
                />
              }
              sidebarInner
              onRef={(instance) => {
                this.pageLayout = instance;
              }}
              innerScroll={true}
            />
            {matches && (
              <FuseAnimate animation="transition.expandIn" delay={300}>
                <Draggable disabled={matches}>
                  <Tooltip title="View Call Handler" placement="top">
                    <NavLink
                      button
                      to="/apps/call-handler"
                      className={classNames(
                        classes.addButton,
                        !matches ? classes.padButton : "",
                        !matches ? "fixed" : ""
                      )}
                    >
                      <Fab color="secondary" aria-label="add">
                        <Badge
                          classes={{ badge: classes.badge }}
                          color="error"
                          badgeContent={calls ? Object.keys(calls).length : 0}
                        >
                          <Icon>phone</Icon>
                        </Badge>
                      </Fab>
                    </NavLink>
                  </Tooltip>
                </Draggable>
              </FuseAnimate>
            )}
          </React.Fragment>
        )}
      </Media>
    );
  }
}

DispatchBoardApp.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserData: Actions.getUserData,
    },
    dispatch
  );
}

function mapStateToProps({ dispatchBoardApp, spReducers }) {
  return {
    user: dispatchBoardApp.user,
    calls: spReducers.callHandler.Calls,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DispatchBoardApp))
);
