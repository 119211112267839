import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography, TextField } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from 'lodash';
import moment from 'moment';
import { updateMaterialToTransfer } from './store/actions/gps-inventory-transfers.actions'

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {}

});

class TransferMaterialsList extends Component {

    state = {
        selectedTeamMembersMenu: null
    };

    render() {
        const { Co, classes, material, materials} = this.props;
        let data = [...material.Data.Lines];
        const avatar = 'assets/images/avatars/profile.jpg';
        const types = { 'I': 'Inventory', 'W': 'Work Order' };

        window["warn"]("ffgghh", material, data)

        let { Status } = material;

        //let Status = 3

        let deleteIndex = _.findIndex(data, (o) => o.DeleteYN === "Y");
        let newDialogList = [];
        if (deleteIndex > -1) {
            newDialogList = _.pull(data, data[deleteIndex])
        }

        return (
            <div>
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">send</Icon>{`Materials to Transfer`}</Typography>
                <ReactTableFixedColumns
                    className={classNames(classes.root, "-striped -highlight border-0 rounded")}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className: "cursor-pointer",
                            onClick: (e, handleOriginal) => {
                                if (rowInfo) {
                                    this.props.onSelectedMaterial({ ...rowInfo.original });
                                }
                            }
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            Header: "Part #",
                            accessor: "Material",
                            minWidth: 150,
                            className: "font-bold justify-center",
                            Cell: ({ original }) => original.Material,
                        },
                        {
                            Header: "Description",
                            accessor: "Description",
                            className: "justify-center",
                            minWidth: 300,
                            Cell: row => {
                                const mat = _.find(materials, { Material: row.original.Material });
                                if (mat) {
                                    return (
                                        mat.Description
                                    )
                                }
                            },
                            
                        },
                        {
                            Header: "Qty",
                            accessor: "Qty",
                            minWidth: 100,
                            className: "font-bold justify-center",
                            Cell: ({ original }) => original.Qty,
                        },
                        {
                            Header: "Approved Qty",
                            accessor: "ApprovedQty",
                            minWidth: 100,
                            className: "font-bold justify-center",
                            show: Boolean(Status >= 1),
                            Cell: row => {
                                if(Status === 1){
                                    let val = row.original.ApprovedQty === undefined && row.original.ApprovedQty !== 0 ? row.original.Qty : row.original.ApprovedQty;
                                    return (
                                        <TextField
                                            className={classes.formControl}
                                            label=""
                                            id="ApprovedQty"
                                            name="ApprovedQty"
                                            value={val}
                                            onClick={(e) => {e.stopPropagation()}}
                                            onChange={(e) => {
                                                let data = {...row.original}
                                                data.ApprovedQty = parseInt(e.target.value)
                                                data.Status = 2;
                                                this.props.updateMaterialToTransfer(data)
                                            }}
                                            type="number"
                                            inputProps={{ max: row.original.Qty }}
                                            fullWidth
                                            variant="outlined"
                                            disabled={row.original.Status > 2}
                                            required
                                        />
                                    )
                                } else {
                                    return row.original.ApprovedQty;
                                }
                            },
                        },
                        {
                            Header: "Accepted Qty",
                            accessor: "AcceptedQty",
                            minWidth: 100,
                            className: "font-bold justify-center",
                            show: Boolean(Status >= 2),
                            Cell: row => {
                                if(Status === 2){
                                    let val = row.original.AcceptedQty === undefined && row.original.AcceptedQty !== 0 ? row.original.ApprovedQty : row.original.AcceptedQty;
                                    return (
                                        <TextField
                                            className={classes.formControl}
                                            label=""
                                            id="AcceptedQty"
                                            name="AcceptedQty"
                                            value={val}
                                            onClick={(e) => {e.stopPropagation()}}
                                            onChange={(e) => {
                                                let data = {...row.original}
                                                data.AcceptedQty = parseInt(e.target.value)
                                                data.Status = 3;
                                                this.props.updateMaterialToTransfer(data)
                                            }}
                                            type="number"
                                            inputProps={{ max: row.original.ApprovedQty }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    )
                                } else {
                                    return row.original.AcceptedQty;
                                }
                            },
                        },      
                    ]}
                    defaultPageSize={5}
                    noDataText="No Materials"
                />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        updateMaterialToTransfer,
    }, dispatch);
}

function mapStateToProps({ teamsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        teamData: spReducers.teams,
        searchText: teamsApp.teams.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
        materials: spReducers.materials.materials,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TransferMaterialsList)));
