import WorkOrdersApp from 'main/content/apps/work-orders/WorkOrdersApp';
import React from 'react';
import {Redirect} from 'react-router-dom';

export const WorkOrdersAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/work-orders/:Co/:WorkOrder/:Trip?',
            component: WorkOrdersApp
        },
        {
            path     : '/apps/work-orders/:id',
            component: WorkOrdersApp
        },
        {
            path     : '/apps/work-orders',
            component: () => <Redirect to="/apps/work-orders/all"/>
        }
    ]
};
