import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedTechnicianIds: [],
    routeParams: {},
    technicianDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            ID: null,
            avatar: 'assets/images/avatars/profile.jpg',
            Co: null,
            Technician: null,
            Employee: null,
            FirstName: null,
            LastName: null,
            MiddleInitial: null,
            TeamCategory: null,
            TeamRole: null,
            Phone: null,
            Email: null,
            Bio: null,
            ExemptYN: 'N',
            Notes: null,
            ActiveYN: 'Y',
            DefaultBusinessUnit: null,
            DefaultDivision: null,
            DefaultDepartment: null,
            value: 1,
            histTab: 0,
            Data: {
                WorkOrders: [],
                Trips: [],
                Timecard: null,
                searchResults: null,
            },
            showTabs: false,
        },
        performance: [],
        loading: false,
        date: new Date(),
    }
};

const techniciansReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_TECHNICIANS:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_TECHNICIAN_SEARCH_RESULTS:
            {
                const { searchResults, loading } = action;
                return {
                    ...state,
                    technicianDialog: {
                        ...state.technicianDialog,
                        data: {
                            ...state.technicianDialog.data,
                            Data: {
                                ...state.technicianDialog.data.Data,
                                searchResults
                            }
                        },
                        loading
                    }
                }
            }
        //case Actions.LOAD_WORK_ORDERS
        //    {
        //        return {
        //            ...state,
        //            workOrders: action.workOrders
        //        };
        //    }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_TECHNICIANS:
            {

                const technicianId = action.technicianId;

                let selectedTechnicianIds = [...state.selectedTechnicianIds];

                if (selectedTechnicianIds.find(id => id === technicianId) !== undefined) {
                    selectedTechnicianIds = selectedTechnicianIds.filter(id => id !== technicianId);
                }
                else {
                    selectedTechnicianIds = [...selectedTechnicianIds, technicianId];
                }

                return {
                    ...state,
                    selectedTechnicianIds: selectedTechnicianIds
                };
            }
        case Actions.SELECT_ALL_TECHNICIANS:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedTechnicianIds = arr.map(technician => technician.id);

                return {
                    ...state,
                    selectedTechnicianIds: selectedTechnicianIds
                };
            }
        case Actions.DESELECT_ALL_TECHNICIANS:
            {
                return {
                    ...state,
                    selectedTechnicianIds: []
                };
            }
        case Actions.OPEN_NEW_TECHNICIAN_DIALOG:
            {
                window["log"]('CUSTOMER DATA FOR NEW SITE: ', action.data, (action.data.Customer ? true : false));
                return {
                    ...state,
                    technicianDialog: {
                        type: (action.data.Customer ? 'customer' : 'new'),
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null),
                    },
                    performance: [],
                };
            }
        case Actions.CLOSE_NEW_TECHNICIAN_DIALOG:
            {
                return {
                    ...state,
                    technicianDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null,
                    },
                    performance: [],
                };
            }
        case Actions.OPEN_EDIT_TECHNICIAN_DIALOG:
            {
                return {
                    ...state,
                    technicianDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data,
                        loading: true,
                    },
                    performance: [],
                };
            }
        case Actions.DATA_EDIT_TECHNICIAN_DIALOG:
            {
                return {
                    ...state,
                    technicianDialog: {
                        type: 'data',
                        props: {
                            ...state.technicianDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_TECHNICIAN_DIALOG:
            {
                return {
                    ...state,
                    technicianDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data,
                    },
                    performance: [],
                };
            }
        case Actions.UPDATE_EDIT_TECHNICIAN_DIALOG:
            {
                return {
                    ...state,
                    technicianDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_TECHNICIAN_DIALOG:
            {
                return {
                    ...state,
                    technicianDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    },
                    performance: [],
                };
            }
        case Actions.SET_TECHNICIAN_SCHEDULE:
            {
                const { date, data, loading } = action;
                return {
                    ...state,
                    technicianDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        date,
                        data,
                        loading,
                    }
                };
            }
        case Actions.GET_TECHNICIAN_PERFORMANCE:
            {
                return {
                    ...state,
                    performance: action.payload,
                };
            }
        case Actions.SET_TECHNICIAN_PERFORMANCE:
            {
                return {
                    ...state,
                    performance: action.payload
                };
            }
        default:
            {
                return state;
            }
    }
};

export default techniciansReducer;
