import {combineReducers} from 'redux';
import voiceMailboxes from './voice-mailboxes.reducer';
import user from './user.reducer';

const voiceMailboxesAppReducers = combineReducers({
    voiceMailboxes,
    user
});

export default voiceMailboxesAppReducers;
