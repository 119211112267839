import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Button, ButtonGroup, Divider, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { guid, setPreferredView } from 'store/actions';
import CatalogAssemblyCard from './CatalogAssemblyCard';
import CatalogAssemblyListItem from './CatalogAssemblyListItem';
import CatalogDialogSearch from './CatalogDialogSearch';
import * as Actions from './store/actions';

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    break: {
        flexBasis: '100%',
        height: 0,
        backgroundColor: 'transparent',
        display: 'none',
        '@media screen and (max-width: 480px)': {
            display: 'block',
        }
    },
    buttonGroup: {
        marginLeft: 'auto',
        '@media screen and (max-width: 480px)': {
            marginLeft: 0,
        }
    }
});

class CatalogAssemblyList extends Component {

    state = {
        Co: null,
        BusinessUnit: null,
        Division: null,
        Data: {
            Categories: [],
            Assemblies: [],
        },
        category: null,
        activeYN: true,
        preferredViewOpen: true,
        reorder: false,
        sortedData: [],
        sortedAssemblies: []
    }

    componentDidMount() {
        const { catalog, category } = this.props;
        const { Co, BusinessUnit, Division, Category } = category || {};
        this.setState({ ...catalog, category: catalog && catalog.Data && catalog.Data.Categories ? _.find(catalog.Data.Categories, { Co, BusinessUnit, Division, Category }) : null }, () => this.sortDataAndAssemblies());
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { catalog, category } = this.props;
        const { Co, BusinessUnit, Division, Category } = category || {};
        if (!_.isEqual(catalog, prevProps.catalog) || !_.isEqual(category, prevProps.category)) {
            this.setState({ ...this.state, ...catalog, category: catalog && catalog.Data && catalog.Data.Categories ? _.find(catalog.Data.Categories, { Co, BusinessUnit, Division, Category }) : null }, () => this.sortDataAndAssemblies());
        }
        if (this.state.activeYN !== prevState.activeYN) {
            this.sortDataAndAssemblies();
        }
    }

    sortDataAndAssemblies = () => {
        const { category, Data, activeYN, reorder } = this.state;
        const { Categories, Assemblies } = Data;
        const categories = _.filter(Categories, (o) => { return !category ? !o.ParentCategory : category.Category === o.ParentCategory });
        const contentOrder = category && category.Options ? JSON.parse(category.Options).ContentOrder || [] : [];

        let reorderedCategories = [];
        const copyCat = _.cloneDeepWith(categories);
        _.filter(contentOrder, { type: 'C' }).forEach((item) => {
            const catIndex = _.findIndex(copyCat, { ID: item.id });
            if (catIndex > -1) {
                reorderedCategories.push(_.cloneDeepWith(copyCat[catIndex]));
                copyCat.splice(catIndex, 1);
            }
        });
        reorderedCategories = [...reorderedCategories, ...copyCat];

        let reorderedAssemblies = [];
        if (category && category.Type == "T" && category.Data && category.Data.Assemblies) {
            const copyTasks = _.cloneDeepWith(category.Data.Assemblies);
            _.filter(contentOrder, { type: 'T' }).forEach((item) => {
                const taskIndex = _.findIndex(copyTasks, { Assembly: item.id });
                if (taskIndex > -1) {
                    reorderedAssemblies.push(_.cloneDeepWith(copyTasks[taskIndex]));
                    copyTasks.splice(taskIndex, 1);
                }
            });
            reorderedAssemblies = [...reorderedAssemblies, ...copyTasks].map((value) => {
                const { Co, BusinessUnit, Division, Assembly } = value;
                const assembly = _.find(Assemblies, { Co, BusinessUnit, Division, Assembly, });
                return assembly;
            });;
        }

        let sortedData = _.filter([...reorderedCategories], (o) => (!reorder && !activeYN) || o.ActiveYN !== 'N');

        let sortedAssemblies = _.filter([...reorderedAssemblies], (o) => (!reorder && !activeYN) || o.ActiveYN !== 'N');

        this.setState({ ...this.state, sortedData, sortedAssemblies })
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            window["log"]('Filtered Array: ', arr);
            return arr;
        }
        window["log"]('Filtered Array: ', arr, searchText);
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    addAssembly = () => {

        const { Co, BusinessUnit, Division, category } = this.state;
        const { catalog } = this.props;
        const { Category } = category || {};
        const newAssembly = {
            ID: null,
            Co,
            BusinessUnit,
            Division,
            // RateTemplate: catalog.DefaultRateTemplate,
            Data: {
                ErrMsg: null,
                Components: [],
                Assignments: [{ Co, BusinessUnit, Division, Category }],
                Images: [],
                Recommendations: [],
                Upgrades: [],
            },
        };
        this.props.openNewCatalogItemDialog(newAssembly);
    }

    addCategory = () => {
        const { Co, BusinessUnit, Division, category, Data } = this.state;
        const { Categories } = Data;
        const { Category } = category || {};
        const newCategory = {
            Co,
            BusinessUnit,
            Division,
            Category: guid(),
            ParentCategory: Category,
            Name: 'New Category',
            Description: '',
            Type: 'C',
            ActiveYN: 'Y',
            editing: true,
            Data: {
                Assemblies: [],
            }
        };
        Categories.push(newCategory);
        this.setState({ ...this.state, Data: { ...Data, Categories } });

    }

    removeCategory = (category) => {
        const { Co, BusinessUnit, Division, Data } = this.state;
        const { Categories } = Data;
        const { Category } = category || {};
        const index = _.findIndex(Categories, { Co, BusinessUnit, Division, Category });
        if (index > -1) {
            Categories.splice(index, 1);
        }
        this.setState({ ...this.state, Data: { ...Data, Categories } });
    }

    handleAgreement = (agreement) => {
        const { estimate, info } = this.props;
        const data = {};
        Object.keys(agreement).map((key) => {
            if (key.substr(0, 1) === key.substr(0, 1).toUpperCase()) {
                data[key] = agreement[key];
            }
        })
        delete data.Data.Site;
        delete data.Data.Customer;
        const { Co, BusinessUnit, Division, Data } = estimate;
        const { Address1, City, State, Zip } = info.Data.Site;
        const { Components } = Data;
        const last = _.maxBy(Components, 'Component');
        const component = {
            Co,
            BusinessUnit,
            Division,
            Component: (last ? last.Component : 0) + 1,
            Type: 4,
            Qty: 1,
            UOM: 'EA',
            Price: agreement.TotalPrice,
            PriceMethod: 'F',
            CustomPrice: agreement.TotalPrice,
            CustomMemberPrice: agreement.TotalPrice,
            AgreementData: JSON.stringify(data),
            Name: agreement.Data.AgreementType.Name,
            Description: agreement.Data.AgreementType.Description,
            CustomImgURL: `https://maps.googleapis.com/maps/api/streetview?key=${googleMapsApiKey}&size=200x140&location=${encodeURIComponent(`${Address1} ${City}, ${State} ${Zip}`)}`,
            Data: {
                ErrMsg: null,
            }
        };
        if (this.props.onAddComponent) {
            Components.push(component);
            this.props.onAddComponent(Components);
        }
        window["warn"]('New Agreement Info: ', data, JSON.stringify(data));
    }
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    dragEnd = (data) => {
        const { category, Data, activeYN } = this.state;
        const { Categories, Assemblies } = Data;
        const categories = _.filter(Categories, (o) => { return !category ? !o.ParentCategory : category.Category === o.ParentCategory });
        const contentOrder = category && category.Options ? JSON.parse(category.Options).ContentOrder || [] : [];

        const {reorderedCategories} = this.reorderCategory(Categories, category)

        let reorderedAssemblies = [];
        if (category && category.Type == "T" && category.Data && category.Data.Assemblies) {
            const copyTasks = _.cloneDeepWith(category.Data.Assemblies);
            _.filter(contentOrder, { type: 'T' }).forEach((item) => {
                const taskIndex = _.findIndex(copyTasks, { Assembly: item.id });
                if (taskIndex > -1) {
                    reorderedAssemblies.push(_.cloneDeepWith(copyTasks[taskIndex]));
                    copyTasks.splice(taskIndex, 1);
                }
            });
            reorderedAssemblies = [...reorderedAssemblies, ...copyTasks].map((value) => {
                const { Co, BusinessUnit, Division, Assembly } = value;
                const assembly = _.find(Assemblies, { Co, BusinessUnit, Division, Assembly, });
                return assembly;
            });;
        }

        let sortedData = _.filter([...reorderedCategories], (o) => o.ActiveYN !== 'N');

        let sortedAssemblies = _.filter([...reorderedAssemblies], (o) => o.ActiveYN !== 'N');

        const result = _.cloneDeep(data);
        const { source, destination } = result;
        result.draggableId = Number(result.draggableId)
        // dropped nowhere
        if (!destination) {
            return;
        }
        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        const ordered = this.reorder(
            category.Type === 'T' ? sortedAssemblies : sortedData,
            result.source.index,
            result.destination.index
        ).map((item) => { return { type: category.Type, id: category.Type === 'T' ? item.Assembly : item.ID } });

        this.setState({ category: { ...category, Options: JSON.stringify({ ContentOrder: ordered }) }, sortedData, sortedAssemblies }, () => this.sortDataAndAssemblies());
    };

    reorderCategory = (Categories, category) => {
        const categories = _.filter(Categories, (o) => { return !category ? !o.ParentCategory : category.Category === o.ParentCategory });
        const contentOrder = category && category.Options ? JSON.parse(category.Options).ContentOrder || [] : [];

        let reorderedCategories = [];
        const copyCat = _.cloneDeepWith(categories);
        _.filter(contentOrder, { type: 'C' }).forEach((item) => {
            const catIndex = _.findIndex(copyCat, { ID: item.id });
            if (catIndex > -1) {
                reorderedCategories.push(_.cloneDeepWith(copyCat[catIndex]));
                copyCat.splice(catIndex, 1);
            }
        });
        reorderedCategories = [...reorderedCategories, ...copyCat];
        return {reorderedCategories}
    }

    reorderAssembly = (category, Assemblies, contentOrder) => {
        let reorderedAssemblies = [];
        if (category && category.Type == "T" && category.Data && category.Data.Assemblies) {
            const copyTasks = _.cloneDeepWith(category.Data.Assemblies);
            _.filter(contentOrder, { type: 'T' }).forEach((item) => {
                const taskIndex = _.findIndex(copyTasks, { Assembly: item.id });
                if (taskIndex > -1) {
                    reorderedAssemblies.push(_.cloneDeepWith(copyTasks[taskIndex]));
                    copyTasks.splice(taskIndex, 1);
                }
            });
            reorderedAssemblies = [...reorderedAssemblies, ...copyTasks].map((value) => {
                const { Co, BusinessUnit, Division, Assembly } = value;
                const assembly = _.find(Assemblies, { Co, BusinessUnit, Division, Assembly, });
                return assembly;
            });;
        }
        return {reorderedAssemblies}
    }

    render() {
        const { searchText, securables, dialog, onAddComponent, onCategoryChange, isMember, onSelected, enterFrom, info, catalog, fullScreen, classes, preferredView, sortCatalog, SelectedPricing } = this.props;
        const { category, Data, activeYN, preferredViewOpen, reorder, sortedData, sortedAssemblies } = this.state;
        const { Categories, Assemblies } = Data;
        const catNum = category ? category.Category : false;
        const parentCategory = category && category.ParentCategory ? _.find([...Categories], { Category: category.ParentCategory }) : null;
        const accessLevel = _.find(securables, { Securable: "catalog" });
        const categories = _.filter(Categories, (o) => { return !category ? !o.ParentCategory : category.Category === o.ParentCategory });
        const contentOrder = category && category.Options ? JSON.parse(category.Options).ContentOrder || [] : [];

        let {reorderedCategories} = this.reorderCategory(Categories, category)
        let {reorderedAssemblies} = this.reorderAssembly(category, Assemblies, contentOrder)
        let sortedAssemblyItems = _.filter([...reorderedAssemblies], (o) => o.ActiveYN !== 'N');


        return (
            <div className="h-full" style={{ transform: 'none !important' }}>
                {/* <CatalogItemDialog catalog={this.props.catalog} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} /> */}
                <div className='flex' style={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" onClick={() => this.setState({ ...this.state, category: parentCategory }, () => this.sortDataAndAssemblies())} className="text-32 mr-8 cursor-pointer">{catNum ? "keyboard_arrow_left" : "apps"}</Icon>{category ? `${category.Category} - ${category.Name}` : catalog ? catalog.Name : "Categories"}
                        {dialog && <div className="pin-r absolute">
                            <CatalogDialogSearch info={info} onReorder={() => this.setState({ reorder: true })} category={catNum} onSelected={this.props.onSelected} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} catalog={this.props.catalog} onTMItem={this.props.onSelectedItem} onAddAgreement={(agreement) => this.handleAgreement(agreement)} activeYN={activeYN} onActive={(activeYN) => this.setState({ ...this.state, activeYN })} onCategory={(value) => this.setState({ ...this.state, category: value }, onCategoryChange ? () => onCategoryChange(value) : undefined)} setStateFxn={this.setState.bind(this)} parentState={this.state} />
                        </div>}
                    </Typography>

                    <div className={classNames(classes.break)}></div>

                    {!reorder && preferredViewOpen &&
                        <ButtonGroup color='primary' className={classNames(classes.buttonGroup, 'mb-6 sm:mb-12')} style={{ marginRight: 'calc(5% + 16px)' }}>
                            <Button variant={preferredView === 'grid' ? 'contained' : 'outlined'} onClick={() => this.props.setPreferredView('grid')}>Grid</Button>
                            <Button variant={preferredView === 'list' ? 'contained' : 'outlined'} onClick={() => this.props.setPreferredView('list')}>List</Button>
                        </ButtonGroup>
                    }
                    {reorder &&
                        <ButtonGroup color='primary' className={classNames(classes.buttonGroup, 'mb-6 sm:mb-12')} style={{ marginRight: 'calc(5% + 16px)' }}>
                            <Button variant="contained" onClick={() => this.setState({ reorder: false }, () => this.props.updateCategory(category))}>Update</Button>
                        </ButtonGroup>
                    }
                </div>

                <Divider />
                {preferredView === 'grid' && !reorder ?
                    <div className="flex flex-wrap xs:pb-48 overflow-y-auto" style={{ maxHeight: 'calc(100% - 54px)' }}>
                        {/* {!category &&
                                <CatalogAssemblyCard estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} catalog={this.props.catalog} dialog={dialog} onClick={() => this.setState({ ...this.state, category: "T&M" }, onCategoryChange ? (value) => onCategoryChange(value) : undefined)} onDelete={(value) => this.removeCategory(value)} value={value} key={value.Category} />
                            } */}
                        {(!category || category.Type === 'C') &&
                            [...sortedData].map((value, index) => {
                                return (
                                    <CatalogAssemblyCard fullScreen={fullScreen} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} catalog={this.props.catalog} dialog={dialog} SelectedPricing={SelectedPricing} onClick={() => this.setState({ ...this.state, category: value }, () => { this.sortDataAndAssemblies(); if (onCategoryChange) { onCategoryChange(value) } })} onDelete={() => this.removeCategory(value)} value={value} key={value.ID} />
                                );
                            })
                        }
                        {category && category.Type == "T" && (sortedAssemblies && [...sortedAssemblyItems].map((value) => {
                                if (value) {
                                    return (
                                        <CatalogAssemblyCard fullScreen={fullScreen} onSelected={this.props.onSelected ? (value) => { if (this.props.onSelected) { this.props.onSelected(value) } } : undefined} isMember={isMember || (this.props.estimate && this.props.estimate.Data && _.findIndex(this.props.estimate.Data.Components, (o) => o.Type === 4 && o.DeleteYN !== 'Y') > -1)} catalog={this.props.catalog} dialog={dialog} SelectedPricing={SelectedPricing} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} onAddComponent={onAddComponent || undefined} onDelete={() => this.removeAssembly(value)} onUpdated={this.props.onUpdated} value={value} key={value.ID} />
                                    );
                                }
                            }) || sortedAssemblyItems && [...sortedAssemblyItems].map((value) => {
                                if (value) {
                                    return (
                                        <CatalogAssemblyCard fullScreen={fullScreen} onSelected={this.props.onSelected ? (value) => { if (this.props.onSelected) { this.props.onSelected(value) } } : undefined} isMember={isMember || (this.props.estimate && this.props.estimate.Data && _.findIndex(this.props.estimate.Data.Components, (o) => o.Type === 4 && o.DeleteYN !== 'Y') > -1)} catalog={this.props.catalog} dialog={dialog} SelectedPricing={SelectedPricing} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} onAddComponent={onAddComponent || undefined} onDelete={() => this.removeAssembly(value)} onUpdated={this.props.onUpdated} value={value} key={value.ID} />
                                    );
                                }
                            }))
                        }
                        {/* {!category && this.props.catalog && this.props.catalog.EnableTMEstimatesYN && this.props.estimate && this.props.onSelectedItem &&
                                <React.Fragment>
                                    <Typography onClick={this.props.onSelectedItem ? () => this.props.onSelectedItem() : undefined} variant="subtitle2" style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer">{<Icon color="primary" className="text-24 mr-6 ml-16">add</Icon>}New T&M Item</Typography>
                                </React.Fragment>
                            } */}

                        {(accessLevel && accessLevel.AccessLevel !== "R") && !this.props.estimate && !this.props.option &&
                            <Typography onClick={category && category.Type === 'T' ? this.addAssembly : this.addCategory} variant="subtitle2" style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer">{<Icon color="primary" className="text-24 mr-6 ml-16">add</Icon>}New {category && category.Type === 'T' ? 'Assembly' : 'Category'}</Typography>
                        }
                    </div> : reorder ?

                        <div className="block overflow-y-auto relative" style={{ maxHeight: 'calc(100% - 54px)' }}>
                            <DragDropContext onDragEnd={(e) => this.dragEnd(e)}>
                                <Droppable
                                    droppableId={`reorder-items-${catNum}`}
                                    direction="vertical"
                                >
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {(!category || category.Type === 'C') &&
                                                [...sortedData].map((value, index) => {
                                                    return (
                                                        <Draggable draggableId={String(value.ID)} index={index} key={String(value.ID)}>
                                                            {(provided2) => (
                                                                <div
                                                                    ref={provided2.innerRef}
                                                                    {...provided2.draggableProps}
                                                                    {...provided2.dragHandleProps}
                                                                >
                                                                    <CatalogAssemblyListItem reorder={true} fullScreen={fullScreen} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} catalog={this.props.catalog} dialog={dialog} SelectedPricing={SelectedPricing} onClick={() => this.setState({ ...this.state, category: value }, () => { this.sortDataAndAssemblies(); if (onCategoryChange) { onCategoryChange(value) } })} onDelete={() => this.removeCategory(value)} value={value} onUpdated={this.props.onUpdated} />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })
                                            }
                                            {category && category.Type == "T" && (sortedAssemblies || sortedAssemblyItems) &&
                                                [...sortedAssemblies].map((value, index) => {
                                                    if (value) {
                                                        return (
                                                            <Draggable draggableId={String(value.ID)} index={index} key={String(value.ID)}>
                                                                {(provided2) => (
                                                                    <div
                                                                        ref={provided2.innerRef}
                                                                        {...provided2.draggableProps}
                                                                        {...provided2.dragHandleProps}
                                                                    >
                                                                        <CatalogAssemblyListItem reorder={true} fullScreen={fullScreen} onSelected={this.props.onSelected ? (value) => { if (this.props.onSelected) { this.props.onSelected(value) } } : undefined} isMember={isMember || (this.props.estimate && this.props.estimate.Data && _.findIndex(this.props.estimate.Data.Components, (o) => o.Type === 4 && o.DeleteYN !== 'Y') > -1)} catalog={this.props.catalog} dialog={dialog} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} SelectedPricing={SelectedPricing} onAddComponent={onAddComponent || undefined} onDelete={() => this.removeAssembly(value)} onUpdated={this.props.onUpdated} value={value} />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                })
                                            }
                                            {provided.placeholder}
                                            {!reorder && (accessLevel && accessLevel.AccessLevel !== "R") && !this.props.estimate && !this.props.option &&
                                                <Typography onClick={category && category.Type === 'T' ? this.addAssembly : this.addCategory} variant="subtitle2" style={{ position: category && category.Type === 'T' ? 'absolute' : 'inherit', right: category && category.Type === 'T' ? 0 : null, marginRight: category && category.Type === 'T' ? '12px' : null, alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer">{<Icon color="primary" className="text-24 mr-6 ml-16">add</Icon>}New {category && category.Type === 'T' ? 'Assembly' : 'Category'}</Typography>
                                            }
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div> :
                        <div className="block overflow-y-auto relative" style={{ maxHeight: 'calc(100% - 54px)' }}>
                            {(!category || category.Type === 'C') &&
                                [...sortedData].map((value, index) => {
                                    return (
                                        <CatalogAssemblyListItem fullScreen={fullScreen} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} catalog={this.props.catalog} dialog={dialog} SelectedPricing={SelectedPricing} onClick={() => this.setState({ ...this.state, category: value }, () => { this.sortDataAndAssemblies(); if (onCategoryChange) { onCategoryChange(value) } })} onDelete={() => this.removeCategory(value)} value={value} key={value.Category} onUpdated={this.props.onUpdated} />
                                    );
                                })
                            }
                            {category && category.Type == "T" && sortedAssemblies &&
                                [...sortedAssemblies].map((value) => {
                                    if (value) {
                                        return (
                                            <CatalogAssemblyListItem fullScreen={fullScreen} onSelected={this.props.onSelected ? (value) => { if (this.props.onSelected) { this.props.onSelected(value) } } : undefined} isMember={isMember || (this.props.estimate && this.props.estimate.Data && _.findIndex(this.props.estimate.Data.Components, (o) => o.Type === 4 && o.DeleteYN !== 'Y') > -1)} catalog={this.props.catalog} dialog={dialog} SelectedPricing={SelectedPricing} estimate={this.props.estimate ? { ...this.props.estimate } : this.props.option ? { ...this.props.option } : null} onAddComponent={onAddComponent || undefined} onDelete={() => this.removeAssembly(value)} onUpdated={this.props.onUpdated} value={value} key={value.ID} />
                                        );
                                    }
                                })
                            }
                            {(accessLevel && accessLevel.AccessLevel !== "R") && !this.props.estimate && !this.props.option &&
                                <Typography onClick={category && category.Type === 'T' ? this.addAssembly : this.addCategory} variant="subtitle2" style={{ position: category && category.Type === 'T' ? 'absolute' : 'inherit', right: category && category.Type === 'T' ? 0 : null, marginRight: category && category.Type === 'T' ? '12px' : null, alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer">{<Icon color="primary" className="text-24 mr-6 ml-16">add</Icon>}New {category && category.Type === 'T' ? 'Assembly' : 'Category'}</Typography>
                            }
                        </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAssemblies: Actions.setAssemblies,
        // setCategory: Actions.setCategory,
        openNewCatalogItemDialog: Actions.openNewCatalogItemDialog,
        updateCategory: Actions.updateAssemblyCategory,
        setPreferredView,
    }, dispatch);
}

function mapStateToProps({ contactsApp, spReducers, catalogApp }) {
    return {
        Co: spReducers.companies.Co,
        user: contactsApp.user,
        classList: spReducers.classes.classes,
        category: catalogApp.category,
        // categories: spReducers.catalog.categories,
        // assemblies: spReducers.catalog.assemblies,
        // category: catalogApp.category,
        searchText: catalogApp.searchText,
        securables: spReducers.userProfiles.User.Data.Securables,
        preferredView: spReducers.catalog.preferredView,
        sortCatalog: spReducers.catalog.sortCatalog,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogAssemblyList)));
