import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, IconButton, AppBar, Toolbar, Drawer, Hidden, Tooltip } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as Actions from 'main/content/apps/email-templates/store/actions';
import connect from 'react-redux/es/connect/connect';
import EmailTemplateBuilder from '../EmailTemplateBuilder';
import { SPRoundIcon } from 'main/icons';
import TemplateTitle from './TemplateTitle';
import _ from '@lodash';

const styles = theme => ({
    root: {
        backgroundColor: '#3f3f3f',
        // marginLeft: 8,
        // marginRight: 8,
        // width: 'calc(100% - 8px)',
        width: '100%',
        // padding: 24,
        // overflow: 'auto',
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },

});

class Template extends Component {

    state = {
        settingsDrawerOpen: false,
        editor: null,
    };

    componentDidMount() {
        this.props.getTemplate(this.props.match.params.Co, this.props.match.params.templateId);
    }

    componentWillUnmount() {
        this.props.resetTemplate();
    }

    onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped nowhere
        if (!destination) {
            return;
        }

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // // reordering list
        // if (result.type === 'list') {
        //     this.props.reorderList(result);
        // }

        // // reordering card
        // if (result.type === 'card') {
        //     this.props.reorderCard(result);
        // }
    };

    toggleSettingsDrawer = (state) => {
        state = (state === undefined) ? !this.state.settingsDrawerOpen : state;

        this.setState({
            settingsDrawerOpen: state
        });
    };

    setEditor = (editor) => {
        if (editor) {
            this.setState({ ...this.state, editor });
        }
    }

    updateTemplate = () => {
        const { template, updateTemplate } = this.props;
        const { editor } = this.state;
        if (template && editor) {
            const data = {
                ...template,
                Markup: editor.runCommand('gjs-get-inlined-html').toString()
            };
            updateTemplate(data);
        }
    }

    render() {
        const { classes, template, deleteTemplate, username, securables } = this.props;
        const accessLevel = _.find(securables, { Securable: 'email-templates' });
        if (!template) {
            return (<div className={classNames(classes.root, "flex flex-1 flex-col h-full relative")}></div>);
        }
        return (
            <div
                className={classNames(classes.root, "flex flex-1 flex-col h-full relative lg:pl-8")}
                ref={(root) => {
                    this.root = root;
                }}
            >
                <AppBar position="static" color="primary" className={classNames(classes.dark, "pt-4 sm:pt-0 h-64 sm:h-inherit")}>
                    <Toolbar className="flex items-center justify-between px-4 sm:px-24 h-48">
                        <div className="w-1/6">
                            <Hidden mdDown>
                                <Button to="/apps/email-templates/templates/" component={Link} variant="contained">
                                    <SPRoundIcon className="align-middle text-28 mr-6 mb-4" />
                                    Template Studio
                                </Button>
                            </Hidden>

                            <Hidden lgUp>
                                <IconButton color="inherit" to="/apps/email-templates/templates/" component={Link}>
                                    <SPRoundIcon className="align-middle text-28 my-4" />
                                </IconButton>
                            </Hidden>
                        </div>

                        <div className="flex flex-1 justify-center items-center w-2/3">
                            <TemplateTitle />
                        </div>
                        <div className="w-1/6 text-right">
                            {accessLevel && accessLevel.AccessLevel !== 'R' &&
                                <Tooltip placement="left" title="Update Template">
                                    <IconButton color="inherit" onClick={() => this.updateTemplate(template)}>
                                        <Icon>save</Icon>
                                    </IconButton>
                                </Tooltip>
                            }
                            {(accessLevel && (accessLevel.AccessLevel === 'F' || (accessLevel.AccessLevel === 'E' && username === template.AddedBy))) &&
                                <Tooltip placement="left" title="Delete Template">
                                    <IconButton color="inherit" onClick={() => deleteTemplate(template)}>
                                        <Icon>delete_sweep</Icon>
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                <EmailTemplateBuilder onLoaded={this.setEditor} components={template.Markup} template={template} style="" height="calc(100vh - 160px)" />


            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTemplate: Actions.getTemplate,
        resetTemplate: Actions.resetTemplate,
        deleteTemplate: Actions.deleteTemplate,
        updateTemplate: Actions.updateTemplate,
    }, dispatch);
}

function mapStateToProps({ emailTemplateApp, spReducers }) {
    return {
        template: emailTemplateApp.template,
        Co: spReducers.companies.Co,
        username: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Template)));
