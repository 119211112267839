import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    type: 'draft',
    drafts: {},
    searchText: '',
    routeParams: {},
    activeStep: 0,
    anchorEl: null,
    completed: {},
    id: null,
    call: {
        CallSid: null,
    },
    schedule: [],
    data: {
        Customer: {
            ID: null,
            Co: null,
            Customer: null,
            Name: null,
            FirstName: null,
            LastName: null,
            Contact: null,
            Phone: null,
            Email: null,
            Address1: null,
            Address2: null,
            City: null,
            State: null,
            Zip: null,
            Country: 'US',
            CallSid: null,
            Notes: null,
            Status: 'A',
            PaymentsCustomer: null,
            RefuseServiceYN: null,
            RefusalReason: null,
            RefusalDate: null,
            ApprovedAccountYN: null,
            InvoiceReviewYN: null,
            MailInvoiceYN: null,
            Latitude: null,
            Longitude: null,
            PORequiredYN: null,
            ImportedID: null,
            Data: {
                Tags: [],
                Sites: [],
                Contacts: [],
                Estimates: [],
                Redirect: null,
            },
            value: 0,
            histTab: 0,
            Address: false,
            type: 'new'
        },
        Site: {
            ID: null,
            Co: null,
            Customer: null,
            Site: null,
            BillToCustomer: null,
            Description: null,
            Contact: null,
            Phone: null,
            Email: null,
            Address1: null,
            Address2: null,
            City: null,
            State: null,
            Zip: null,
            Country: 'US',
            Latitude: null,
            Longitude: null,
            CallSid: null,
            Notes: null,
            Status: 'A',
            CrossStreets: null,
            ImportedID: null,
            Data: {
                Tags: [],
                Contacts: [],
                Estimates: [],
                Units: []
            },
            value: 0,
            histTab: 0,
            streetView: false,
            Address: false,
            type: 'new'
        },
        WorkOrder: {
            ID: null,
            Co: null,
            WorkOrder: null,
            Customer: null,
            BillToCustomer: null,
            Site: null,
            Contact: null,
            ContactPhone: null,
            BusinessUnit: null,
            Division: null,
            Department: null,
            WorkOrder: null,
            Scope: 1,
            ServiceType: null,
            Service: null,
            Description: null,
            Originator: null,
            Requestor: null,
            DueBy: null,
            ArrivalWindow: null,
            ExactTime: null,
            Priority: 3,
            CustomerPO: null,
            NotToExceed: null,
            LeadTechnician: null,
            PriceMethod: 'T',
            Price: 0.00,
            RateTemplate: null,
            FormOfPayment: null,
            NonBillableYN: 'N',
            TaxCode: null,
            TaxableYN: 'Y',
            EnteredBy: null,
            EnteredDate: new Date(),
            CallSid: null,
            Status: 0,
            Notes: null,
            SiteContact: null,
            RequestedByContact: null,
            Data: {
                Tags: [],
                Contacts: [],
                ServiceUnits: [],
            },
            value: 0,
            streetView: false,
            type: 'new',
            addContact: false,
            advanced: 'N',
            contacts: null,
            IsWarrantyYN: 'N',
            WarrantyTechnician: null,
        },
        map: null,
    },
};

const callHandlerReducer = function (state = _.cloneDeepWith(initialState), action) {
    const initialData = _.cloneDeepWith(initialState.data);
    switch (action.type) {
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.SET_CUSTOMER_DATA:
            {
                return {
                    ...state,
                    type: 'draft',
                    data: {
                        ...state.data,
                        Customer: { ...initialData.Customer, ...action.data }
                    }
                };
            }
        case Actions.SET_CALL:
            {
                const { call } = action;
                return {
                    ...state,
                    call,
                }
            }
        case Actions.GET_SCHEDULE:
            {
                const { schedule } = action;
                return {
                    ...state,
                    schedule
                }
            }
        case Actions.CREATE_CUSTOMER:
            {
                const { Co, Customer, Name, FirstName, LastName, Address1, Address2, City, State, Zip, Country, Phone, Email, Data } = action.data;
                return {
                    ...state,
                    type: 'draft',
                    data: {
                        ...state.data,
                        Customer: {
                            ...action.data,
                            Address: (Address1.length > 3 && Zip.length >= 5 ? `${Address1.trim()} ${Zip.trim()}` : false),
                            type: 'saved',
                        },
                        Site: {
                            ...state.data.Site,
                            Co,
                            Customer,
                            Description: Name && Name.length > 0 ? `${Name} ${Address1}` : `${FirstName} ${LastName} ${Address1}`,
                            Address1,
                            Address2,
                            City,
                            State,
                            Zip,
                            Country,
                            Contact: `${FirstName} ${LastName}`,
                            Phone,
                            Email,
                            Data: {
                                ...state.data.Site.Data,
                                Contacts: Data.Contacts,
                                Tags: [],
                            }
                        },
                    },
                    activeStep: 1,
                };
            }
        case Actions.SET_SITE_DATA:
            {
                return {
                    ...state,
                    type: 'draft',
                    data: {
                        ...state.data,
                        Site: { ...initialData.Site, ...action.data },
                    }
                };
            }
        case Actions.CREATE_CUSTOMER_SITE:
            {
                const { Co, Customer, Site, Contact, Address1, Address2, City, State, Zip, Country, Phone, Email } = action.data;
                return {
                    ...state,
                    type: 'draft',
                    data: {
                        ...state.data,
                        Site: {
                            ...action.data,
                            Address: (Address1.length > 3 && Zip.length >= 5 ? `${Address1.trim()} ${Zip.trim()}` : false),
                            type: 'saved',
                        },
                        WorkOrder: {
                            ...state.data.WorkOrder,
                            Co,
                            Customer,
                            Site,
                            Contact,
                            ContactPhone: Phone,
                        },
                    },
                    activeStep: 2,
                };

            }
        case Actions.GET_CUSTOMER_DATA:
            {
                const { clear, options } = action;
                const { Co, Customer, Name, FirstName, LastName, Address1, Address2, City, State, Zip, Country, Phone, Email, Data } = action.data;
                const siteExists = _.findIndex(Data.Sites, { Address1, City, State, Zip }) > -1;
                return {
                    ...(clear ? initialState : state),
                    type: clear ? 'new' : 'draft',
                    data: {
                        ...initialData,
                        Customer: {
                            ...action.data,
                            Address: (Address1.length > 3 && Zip.length >= 5 ? `${Address1.trim()} ${Zip.trim()}` : false),
                            type: 'saved',
                            Data: {
                                ...Data,
                                Contacts: Data.Contacts || []
                            }
                        },
                        Site: !siteExists ? {
                            ...initialData.Site,
                            Co,
                            Customer,
                            Description: Name && Name.length > 0 ? `${Name} ${Address1}` : `${FirstName} ${LastName} ${Address1}`,
                            Address1,
                            Address2,
                            City,
                            State,
                            Zip,
                            Country,
                            Contact: `${FirstName} ${LastName}`,
                            Phone,
                            Email,
                            Data: {
                                ...initialData.Site.Data,
                                Contacts: Data.Contacts || []
                            }
                        } : {
                            ...initialData.Site,
                            Co,
                            Customer,
                            Data: {
                                ...initialData.Site.Data,
                                Contacts: Data.Contacts || []
                            }
                        },
                        WorkOrder: {
                            ...initialData.WorkOrder,
                            ...options,
                        }
                    },
                    activeStep: 1,
                };
            }
        case Actions.GET_SITE_DATA:
            {
                const { clear, options } = action;
                const { Co, Customer, Site, Contact, Address1, Address2, City, State, Zip, Country, Phone, Email } = action.data;

                return {
                    ...(clear ? initialState : state),
                    type: clear ? 'new' : 'draft',
                    data: {
                        ...initialData,
                        Customer: {
                            ...action.data.Data.Customer,
                            type: 'saved',
                            Tags: action.data.Data.Customer.Tags ? JSON.parse(action.data.Data.Customer.Tags) : [],
                            Data: {
                                ...action.data.Data.Customer.Data,
                                Tags: action.data.Data.Customer.Data.Tags ? action.data.Data.Customer.Data.Tags : [],
                            }
                        },
                        Site: {
                            ...action.data,
                            Address: (Address1.length > 3 && Zip.length >= 5 ? `${Address1.trim()} ${Zip.trim()}` : false),
                            type: 'saved',
                            Data: {
                                ...action.data.Data,
                                Tags: action.data.Data.Tags ? action.data.Data.Tags : [],
                            }
                        },
                        WorkOrder: {
                            ...initialData.WorkOrder,
                            Co,
                            Customer,
                            Site,
                            Contact,
                            ContactPhone: Phone,
                            ...options,
                        },
                    },
                    activeStep: 2,
                };
            }
        case Actions.SET_WORK_ORDER_DATA:
            {
                return {
                    ...state,
                    type: 'draft',
                    data: {
                        ...state.data,
                        WorkOrder: action.data,
                    }
                }
            }
        case Actions.GET_WORK_ORDER_DATA:
            {
                return {
                    ...state,
                    type: 'saved',
                    data: {
                        ...state.data,
                        WorkOrder:
                        {
                            ...action.data,
                            type: 'saved',
                            Data: {
                                ...action.data.Data,
                                Tags: action.data.Data.Tags ? action.data.Data.Tags : [],
                            }
                        }
                    }
                }
            }
        case Actions.SET_CALL_HANDLER_STEP:
            {
                return {
                    ...state,
                    activeStep: action.activeStep
                }
            }
        case Actions.DELETE_CURRENT_CALL_HANDLER_DRAFT:
            {
                let { drafts, id } = state;
                delete drafts[id];
                id = null;
                return {
                    ...state,
                    drafts,
                    id,
                }
            }
        case Actions.SAVE_CALL_HANDLER_DRAFT:
            {
                const { BusinessUnit, Division, ServiceType } = state.data.WorkOrder;
                return {
                    ...state.map,
                    ...initialState,
                    data: {
                        ...initialData,
                        Customer: {
                            ...initialData.Customer,
                            Data: {
                                ...initialData.Customer.Data,
                                Tags: [],
                            },
                            type: 'new',
                        },
                        Site: {
                            ...initialData.Site,
                            Data: {
                                ...initialData.Site.Data,
                                Tags: [],
                            },
                            type: 'new',
                        },
                        WorkOrder: {
                            ...initialData.WorkOrder,
                            Data: {
                                ...initialData.WorkOrder.Data,
                                Tags: [],
                            },
                            type: 'new',
                            EnteredDate: new Date(),
                        },
                    },
                    type: 'new',
                    drafts: action.data
                }
            }
        case Actions.OPEN_CALL_HANDLER_DRAFT: {
            return {
                ...state,
                id: action.id,
                data: {
                    ...action.data,
                    Customer: {
                        ...action.data.Customer,
                        type: 'saved',
                    },
                    Site: {
                        ...action.data.Site,
                        type: 'saved',
                    },
                    WorkOrder: {
                        ...action.data.WorkOrder,
                        type: 'saved',
                    },
                },
                activeStep: 0,
                type: 'new',
            }
        }
        case Actions.CLEAR_CALL_HANDLER_DATA:
            {
                return {
                    ...state.map,
                    ...initialState,
                    drafts: state.drafts,
                    data: {
                        ...initialData,
                        Customer: {
                            ...initialData.Customer,
                            Data: {
                                ...initialData.Customer.Data,
                                Tags: [],
                            },
                            type: 'new',
                        },
                        Site: {
                            ...initialData.Site,
                            Data: {
                                ...initialData.Site.Data,
                                Tags: [],
                            },
                            type: 'new',
                        },
                        WorkOrder: {
                            ...initialData.WorkOrder,
                            Data: {
                                ...initialData.WorkOrder.Data,
                                Tags: [],
                            },
                            type: 'new',
                            EnteredDate: new Date(),
                        },
                    },
                    type: 'new',
                };
            }
        case Actions.CLEAR_WORK_ORDER_DATA:
            {
                return {
                    ...state.map,
                    ...initialState,
                    drafts: state.drafts,
                    activeStep: 2,
                    data: {
                        ...state.data,
                        WorkOrder: {
                            ...initialData.WorkOrder,
                            Data: {
                                ...initialData.WorkOrder.Data,
                                Tags: [],
                            },
                            type: 'new',
                            EnteredDate: new Date(),
                        },
                    },
                    type: 'new',
                };
            }
        case Actions.NEW_CUSTOMER_CALL:
            {
                const { Data, From, Sid } = action.call;
                const { DefaultBusinessUnit, DefaultDivision, DefaultDepartment } = Data.Queue;
                return {
                    ...state.map,
                    ...initialState,
                    data: {
                        ...initialData,
                        Customer: {
                            ...initialData.Customer,
                            Phone: From.replace('+1', ''),
                            Data: {
                                ...initialData.Customer.Data,
                                Tags: [],
                            },
                            type: 'new',
                        },
                        Site: {
                            ...initialData.Site,
                            Data: {
                                ...initialData.Site.Data,
                                Tags: [],
                            },
                            type: 'new',
                        },
                        WorkOrder: {
                            ...initialData.WorkOrder,
                            Data: {
                                ...initialData.WorkOrder.Data,
                                Tags: [],
                            },
                            BusinessUnit: DefaultBusinessUnit,
                            Division: DefaultDivision,
                            Department: DefaultDepartment,
                            CallSid: Sid,
                            type: 'new',
                            EnteredDate: new Date(),
                        },
                    },
                    type: 'new',
                };
            }
        default:
            {
                return state;
            }
    }
};

export default callHandlerReducer;
