import _ from '@lodash';
import { Button, Icon, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


const styles = theme => ({
    root: {

    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 24,
        marginTop: 24,
    },
    customerSelect: {
        padding: 2,
        minHeight: 512,
        minWidth: '25%',
        border: '1px solid lightgrey',
        marginRight: 12,
        marginBottom: 24,
        borderRadius: 5,
        background: 'rgba(0, 0, 0, .05)',
    },
    customerCard: {
        borderRadius: 5,
        boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, .5)',
    },
    customerAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    chip: {
        color: '#fff',
        backgroundColor: '#3f3f3f',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        marginBottom: 12,
        marginLeft: 8,
        height: 24,
    },
    chipLabel: {
        paddingLeft: 8,
        fontWeight: 'bold',
        color: '#fff',
        paddingBottom: 2,
    },
    chipAvatar: {
        marginLeft: -12,
        color: '#3f3f3f',
        backgroundColor: '#3f3f3f',
        border: '3px solid #3f3f3f',
    },
    chipAvatarIcon: {
        color: '#3f3f3f',
        backgroundColor: '#fff',
    },
    signature: {
        border: '1px dotted #3f3f3f',
        borderRadius: 5,
    },
    signaturePlaaceholder: {

    },
    signatureImg: {

    },
    clearBtn: {
        backgroundColor: '#d60202',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8c0202'
        },
        '&:active': {
            backgroundColor: '#790101'
        },
        '&:focus': {
            backgroundColor: '#8c0202'
        },
    },
    okBtn: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#111111'
        },
        '&:active': {
            backgroundColor: 'black'
        },
        '&:focus': {
            backgroundColor: '#111111'
        },
    },
    signaturePaper: {
        padding: 16,
        marginLeft: 8,
        marginRight: 8,
    },
    postedMatch: {
        color: theme.palette.secondary.dark
    },
    postedNoMatch: {
        color: theme.palette.error[500]
    },
    notPosted: {
        color: '#5f5f5f'
    },
    postedBatch: {
        color: theme.palette.primary.main
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
});
const initialState = {
    details: [],
    exclusion: null,
};

class EstimateExclusions extends Component {
    state = { ...initialState };

    componentDidMount() {
        const { details } = this.props;
        this.setState({ ...this.state, details });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { details } = this.props;
        if (!_.isEqual(prevProps.details, details)) {
            this.setState({ ...this.state, details });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    render() {
        const { estimate, parent, editable } = this.props;
        let { details } = this.state;
        if (details) {
            details = _.filter([...details], (o) => { return o.DeleteYN !== 'Y' });
        }
        return (
            <React.Fragment>
                {[...details].map((value) => {
                    const { Co, BusinessUnit, Division, Estimate, Exclusion, ParentExclusion } = value;
                    const subExclusions = _.filter(estimate.Data.Exclusions, { ParentExclusion: Exclusion })
                    if (parent === ParentExclusion)
                        return (
                            <li key={value.Exclusion} className={editable ? "mb-6" : ""}>
                                {editable && (value.edit || value.add) ?
                                    <div className="flex clear">
                                        <TextField
                                            className={classNames("mr-8")}
                                            label="Edit Exclusion"
                                            id="valueDescription"
                                            value={value.Description || ''}
                                            autoFocus
                                            margin="dense"
                                            onChange={(e) => {
                                                const exclusion = _.find(details, { Exclusion: value.Exclusion });
                                                if (exclusion) {
                                                    exclusion.Description = e.target.value;
                                                }
                                                this.setState({ ...this.state, details });
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <Button
                                            variant="contained"
                                            className="w-72 mt-8 mb-4"
                                            color="primary"
                                            onClick={() => {
                                                value.edit = false;
                                                value.add = false;

                                            }}
                                            disabled={!value.Description}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                    :
                                    <div className={editable ? "text-14 flex" : "text-12"}>
                                        <div className={editable ? "float-left truncate w-full" : "w-full"}>
                                            {value.Description}
                                        </div>
                                        {editable &&
                                            <div className="float-right text-right w-full sm:w-128">
                                                <Icon onClick={() => { value.edit = true; }} className="align-middle ml-12 text-16 cursor-pointer" color="action">edit</Icon>
                                                <Icon onClick={() => {
                                                    const { Data } = estimate;
                                                    const { Exclusions } = Data;
                                                    const exclusion = {
                                                        ID: null,
                                                        Co,
                                                        BusinessUnit,
                                                        Division,
                                                        Estimate,
                                                        Exclusion: (Exclusions.length > 0 ? _.maxBy(Exclusions, 'Exclusion').Exclusion : 0) + 1,
                                                        ParentExclusion: Exclusion,
                                                        Description: null,
                                                        Data: {
                                                            Exclusions: [],
                                                        },
                                                    }
                                                    this.setState({ ...this.state, exclusion }, () => window["warn"](exclusion, this.state.exclusion));
                                                }}
                                                    className="align-middle ml-12 text-16 cursor-pointer"
                                                    color="primary">add_circle_outline</Icon>
                                                <Icon onClick={() => {
                                                    const { Data } = estimate;
                                                    const { Exclusions } = Data;
                                                    const index = _.findIndex(Exclusions, { Exclusion: value.Exclusion, ID: null });
                                                    const exclusion = _.find(Exclusions, { Exclusion: value.Exclusion });
                                                    if (index > -1) {
                                                        Exclusions.splice(index, 1);
                                                    } else {
                                                        if (exclusion) {
                                                            exclusion.DeleteYN = 'Y';
                                                        }
                                                    }
                                                    // this.setState({ ...this.state, Exclusions });
                                                }} className="align-middle ml-12 text-16 cursor-pointer"
                                                    color="error">delete</Icon>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    (subExclusions.length > 0 || this.state.exclusion) &&
                                    <ul>
                                        <EstimateExclusions parent={Exclusion} editable={editable} estimate={{ ...estimate }} details={[...subExclusions]} />
                                        {this.state.exclusion && this.state.exclusion.ParentExclusion === Exclusion &&
                                            <li key={this.state.exclusion.Exclusion}>
                                                <div className="flex clear">
                                                    <TextField
                                                        className={classNames("mr-8")}
                                                        label="New Exclusion"
                                                        id="exclusionDescription"
                                                        name="exclusion.Description"
                                                        value={this.state.exclusion.Description || ''}
                                                        onChange={this.handleChange}
                                                        autoFocus
                                                        margin="dense"
                                                        // onBlur={() => { if (!this.state.exclusion.Description) { this.setState({ ...this.state, exclusion: null }) } }}
                                                        // onChange={(e) => this.setState({ ...this.state, exclusion: { ...this.state.exclusion, Description: e.target.value } })}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        className="w-72 mt-8 mb-4"
                                                        color="primary"
                                                        onClick={() => {
                                                            const { exclusion } = this.state;
                                                            const { Data } = estimate;
                                                            const { Exclusions } = Data;
                                                            Exclusions.push(exclusion);
                                                            const newExclusion = {
                                                                ID: null,
                                                                Co,
                                                                BusinessUnit,
                                                                Division,
                                                                Estimate,
                                                                Exclusion: (Exclusions.length > 0 ? _.maxBy(Exclusions, 'Exclusion').Exclusion : 0) + 1,
                                                                ParentExclusion: Exclusion,
                                                                Description: null,
                                                                Data: {
                                                                    Exclusions: [],
                                                                }
                                                            };
                                                            this.setState({ ...this.state, exclusion: null }, window["warn"](newExclusion, Exclusions));

                                                        }}
                                                        disabled={!this.state.exclusion.Description}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                }
                            </li>
                        );
                })
                }
            </React.Fragment>

        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(EstimateExclusions));
