import * as Actions from 'store/actions/spConnection';

const initialState = {
    holdMusic: [],
    audioClips: [],
};

const audioFiles = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_AUDIO_CLIPS:
            {
                return {
                    ...state,
                    audioClips: action.payload,
                };
            }
        case Actions.SET_AUDIO_CLIPS:
            {
                return {
                    ...state,
                    audioClips: action.payload,
                };
            }
        case Actions.GET_HOLD_MUSIC:
            {
                return {
                    ...state,
                    holdMusic: action.payload,
                };
            }
        case Actions.SET_HOLD_MUSIC:
            {
                return {
                    ...state,
                    holdMusic: action.payload,
                };
            }
    }

    return state;
};

export default audioFiles;