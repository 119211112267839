import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { AppBar, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, MenuItem, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { AttachFile, Dashboard } from '@material-ui/icons';
import axios from 'axios';
import classNames from 'classnames';
import * as GatherActions from 'main/content/apps/call-gather-keys/store/actions';
import AnalyticsDashboardApp from 'main/content/apps/dashboards/analytics/AnalyticsDashboardApp';
import FileList from 'main/content/apps/file-manager/FileList';
import { setAwaitingCallFrom } from 'main/dialer/store/actions/dialer.actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import Recorder from 'recorder-js';
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import PhoneInput from '../../components/inputs/PhoneInput';
import * as FileActions from '../file-manager/store/actions';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
});
const newPhoneNumberState = {
    ID: '',
    Co: null,
    Phone: '',
    Name: '',
    Action: 'Enqueue',
    DefaultQueue: '',
    DefaultGather: null,
    DefaultDial: null,
    DefaultHuntGroup: null,
    DefaultVoiceMailbox: null,
    RecordDefaultDialYN: null,
    Greeting: '',
    GreetingVoice: '',
    GreetingType: 'text',
    Description: '',
    Notes: '',
    ActiveYN: 'Y',
    value: 0,
    GreetingClip: '',
    VocalPitch: 'medium',
    RateOfSpeech: 'medium',
    Volume: 'medium',
    GreetingFileID: null,
    hasRecordedAudio: false,
    isRecordingAudio: false,
    recordedAudioFileName: '',
    Data: {
        Tags: [],
        AudioClips: [],
    },
    TextEnabled: null,
    TextGreeting: null,
    TextAction: 'Enqueue',
    TextGather: null,
    TextQueue: null,

};

const rates = { "x-slow": .35, "slow": .45, "medium": .55, "fast": .6, "x-fast": .65 };
const pitches = { "x-low": .43, "low": .47, "medium": .5, "high": 0.53, "x-high": 0.56 };
const volumes = { "x-soft": .25, "soft": .5, "medium": .75, "loud": .9, "'x-loud": 1 };

const API_KEY = process.env.REACT_APP_MAP_KEY;
const GOOGLE_TEXT_TO_SPEECH_URL = 'https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=' + API_KEY
const SIP_ENDPOINT = '';

class PhoneNumbersDialog extends Component {
    state = { ..._.cloneDeepWith(newPhoneNumberState) };

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newPhoneNumberState.Co = this.props.Co;
        if (!prevProps.phoneNumberDialog.props.open && this.props.phoneNumberDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.phoneNumberDialog.type === 'edit' &&
                this.props.phoneNumberDialog.data &&
                !_.isEqual(this.props.phoneNumberDialog.data, prevState)) {
                const data = this.props.phoneNumberDialog.data;
                this.setState({ ..._.cloneDeepWith(newPhoneNumberState), ...this.props.phoneNumberDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.phoneNumberDialog.type === 'new' &&
                !_.isEqual(newPhoneNumberState, prevState)) {
                this.setState({ ..._.cloneDeepWith(newPhoneNumberState) });
            }
        }
        //window["log"]('STATE COMPARISON', this.props.phoneNumberDialog, prevProps, prevState);
        if (prevProps.phoneNumberDialog.props.open && this.props.phoneNumberDialog.props.open) {
            if (this.props.phoneNumberDialog.type === 'data' &&
                this.props.phoneNumberDialog.data &&
                !_.isEqual(this.props.phoneNumberDialog.data, prevState)) {
                const data = this.props.phoneNumberDialog.data;
                this.props.phoneNumberDialog.type = 'edit';
                this.setState({ ...this.props.phoneNumberDialog.data });
            }
        }
        if (prevProps.phoneNumberDialog.props.open && this.props.phoneNumberDialog.props.open) {
            if (this.props.phoneNumberDialog.type === 'add' &&
                this.props.phoneNumberDialog.data &&
                !_.isEqual(this.props.phoneNumberDialog.data, prevState)) {
                const data = this.props.phoneNumberDialog.data;
                this.props.showMessage({
                    message: `PhoneNumber ${this.props.phoneNumberDialog.data.PhoneNumber} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.phoneNumberDialog.type = 'edit';
                this.setState({ ...this.props.phoneNumberDialog.data });
            }
        }
        if (prevProps.phoneNumberDialog.props.open && this.props.phoneNumberDialog.props.open) {
            if (this.props.phoneNumberDialog.type === 'update' &&
                this.props.phoneNumberDialog.data &&
                !_.isEqual(this.props.phoneNumberDialog.data, prevState)) {
                const data = this.props.phoneNumberDialog.data;
                this.props.showMessage({
                    message: `PhoneNumber ${this.props.phoneNumberDialog.data.PhoneNumber} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                })
                this.props.phoneNumberDialog.type = 'edit';
                this.setState({ ...this.props.phoneNumberDialog.data });
            }
        }
    }


    closeComposeDialog = () => {
        this.props.phoneNumberDialog.type === 'edit' ? this.props.closeEditPhoneNumberDialog() : this.props.closeNewPhoneNumberDialog();
    };

    canBeSubmitted() {
        const { Phone } = this.state;
        return (
            Phone.length > 0
        );
    }

    parseSSML = () => {
        const { Greeting } = this.state;
        const ssml = Greeting;
        var tags = [];
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        for (var i = 0; i < nodes.length; i++) {
            var tag = {};
            var node = nodes[i];
            tag.Say = node.childNodes[0].nodeValue;
            tag.Attributes = '';
            for (var a = 0; a < node.attributes.length; a++) {
                tag[node.attributes[a].name] = node.attributes[a].value;
                tag.Attributes += ` ${node.attributes[a].name}=${node.attributes[a].value} `;
            }
            tags.push(tag);
        }
        window["warn"](tags, ssml, nodes, SSML);
        return tags;
    }

    buildSSML = (tags) => {
        let Greeting = '';
        for (var i = 0; i < tags.length; i++) {
            const tag = tags[i];
            Greeting += `<prosody pitch="${tag.pitch ? tag.pitch : 'medium'}" rate="${tag.rate ? tag.rate : 'medium'}" volume="${tag.volume ? tag.volume : 'medium'}">${tag.Say}</prosody>`;
        }
        return Greeting;
    }

    speak = async () => {
        const { Greeting, Phone, } = this.state;
        const { user } = this.props;

        var value = {
            UserName: user.UserName,
            SSML: Greeting,
            Voice: 'man',
            Data: {
                ErrMsg: '',
            }
        };

        const request = axios.put(`${window["apiLocation"]}/api/Speech?Phone=${encodeURIComponent(Phone)}`, value);
        request.then((r) => { window["warn"](r); this.props.setAwaitingCallFrom(Phone); });
    }

    addClip = () => {
        let { Greeting, GreetingClip, RateOfSpeech, VocalPitch, Volume } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: GreetingClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.push(tag);
        const newGreeting = this.buildSSML(tags);
        this.setState({ ...this.state, Greeting: newGreeting, GreetingClip: '', });
    }

    saveClip = () => {
        let { Greeting, GreetingClip, RateOfSpeech, VocalPitch, Volume, EditClip } = this.state;
        const tags = this.parseSSML();
        let tag = { Say: GreetingClip, rate: RateOfSpeech, pitch: VocalPitch, volume: Volume };
        tags.splice(EditClip, 1, tag);
        const newGreeting = this.buildSSML(tags);
        EditClip = null;
        this.setState({ ...this.state, Greeting: newGreeting, GreetingClip: '', EditClip });
    }

    removeClip = (key) => {
        const tags = this.parseSSML();
        tags.splice(key, 1);
        const newGreeting = this.buildSSML(tags);
        this.setState({ ...this.state, Greeting: newGreeting });
    }

    editClip = (key) => {
        const tags = this.parseSSML();
        const tag = tags[key];
        this.setState({ ...this.state, GreetingClip: tag.Say, RateOfSpeech: tag.rate ? tag.rate : 'medium', VocalPitch: tag.pitch ? tag.pitch : 'medium', Volume: tag.volume ? tag.volume : 'medium', EditClip: key })
    }

    clipFileSelectedHandler = (event) => {
        const { Data } = this.state;
        const fd = new FormData();
        const fn = event.target.files[0].name;
        const file = new Blob([event.target.files[0]], { type: event.target.files[0].type });
        fd.append('file', file, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipFileUploadProgress.innerHTML = percentCompleted === 100 ? 'Upload Clip' : `${percentCompleted}%`;
            }
        };
        axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            if (res.data.Attachment) {
                this.addAudioClip(res.data.Attachment);
                this.setState({ ...this.state, GreetingFileID: res.data.Attachment.Attachment });
            }
        });
    }

    addAudioClip = (att) => {
        const { Data } = this.state;
        let { AudioClips } = Data;
        AudioClips.push(att);
        this.setState({ ...this.state, Data: { ...Data, AudioClips } });
    }

    greetingAudioPaused = () => {
        this.setState({ ...this.state, greetingAudioPlaying: false });
    }

    greetingAudioPlaying = () => {
        this.setState({ ...this.state, greetingAudioPlaying: true });
    }

    startRecordingAudio = () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            this.handleStream(stream);
        })
    }

    handleStream = (stream) => {

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        const recorder = new Recorder(audioContext);
        recorder.init(stream);
        this.setState({ ...this.state, isRecordingAudio: recorder, audioStream: stream, hasRecordedAudio: false }, () => { recorder.start(); });
    }

    stopRecordingAudio = () => {
        const { isRecordingAudio, audioStream } = this.state;
        isRecordingAudio.stop().then(({ blob, buffer }) => {
            this.recordedAudioPlayback.src = URL.createObjectURL(blob);
            audioStream.getTracks()[0].stop();
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: blob, audioStream: null });
        });
    }

    deleteRecordedAudio = () => {
        this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false });
    }

    uploadRecordedAudio = () => {
        const { hasRecordedAudio, recordedAudioFileName } = this.state;
        const fd = new FormData();
        const fn = `${recordedAudioFileName}.wav`;
        fd.append('file', hasRecordedAudio, fn);
        var config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.clipUploadProgress.innerHTML = percentCompleted === 100 ? '' : `${percentCompleted}%`;
            }
        };
        const request = axios.post(`${window["apiLocation"]}/api/Attachment/PostAudioClip?Co=${encodeURIComponent(this.state.Co)}`, fd, config).then((res) => {
            if (res.data.Attachment) {
                this.addAudioClip(res.data.Attachment);
            }
            this.setState({ ...this.state, isRecordingAudio: false, hasRecordedAudio: false, GreetingFileID: res.data.Attachment.Attachment });
        });
    }

    render() {
        const { classes, theme, phoneNumberDialog, addPhoneNumber, textGathers, updatePhoneNumber, voiceMailboxes, removePhoneNumber, businessUnits, divisions, departments, queues, awaitingCallFrom, setAwaitingCallFrom, AudioClips, gathers, huntGroups } = this.props
        const { Co, BusinessUnit, Phone, value, Greeting, EditClip, Data, Action } = this.state;
        const SSMLTags = this.parseSSML();

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        {...phoneNumberDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="md"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {['new', 'customer'].indexOf(phoneNumberDialog.type) > -1 ? 'New Phone Number' : `${this.state.Phone} Overview`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                <Tab disableRipple icon={<Icon>phone_callback</Icon>} label="Info" />
                                <Tab disableRipple icon={<AttachFile />} label="Recordings" />
                                <Tab disableRipple icon={<Dashboard />} label="Dashboard" />
                            </Tabs>
                        </AppBar>
                        {value === 0 && <TabContainer>
                            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                <DialogContent classes={{ root: "p-0 sm:p-24" }}>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">phone_callback</Icon>
                                        </div>
                                        {/* <TextField
                                            className={classes.formControl}
                                            label="Phone Number"
                                            id="phone"
                                            name="Phone"
                                            value={this.state.Phone}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                        /> */}
                                        <TextField
                                            className={classes.formControl}
                                            label="Phone Number"
                                            id="phone"
                                            name="Phone"
                                            value={this.state.Phone}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Name"
                                            id="name"
                                            name="Name"
                                            value={this.state.Name}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Action"
                                            id="action"
                                            name="Action"
                                            value={this.state.Action}
                                            onChange={this.handleChange}
                                            InputLabelProps={{ shrink: this.state.Action ? true : false }}
                                            variant="outlined"
                                            fullWidth
                                            select
                                        >
                                            <MenuItem value="Enqueue">
                                                Enqueue
                                            </MenuItem>
                                            <MenuItem value="Gather">
                                                Gather
                                            </MenuItem>
                                            <MenuItem value="Dial">
                                                Transfer
                                            </MenuItem>
                                            <MenuItem value="Hunt">
                                                Hunt Group
                                            </MenuItem>
                                            <MenuItem value="Voicemail">
                                                Voicemail
                                            </MenuItem>
                                        </TextField>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        {
                                            Action === "Enqueue" &&
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Queue"
                                                options={queues}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.Queue}>
                                                        {value.Queue}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.DefaultQueue}
                                                onSelect={(option) => this.setState({ ...this.state, DefaultQueue: option.Queue })}
                                                required
                                            />
                                        }
                                        {
                                            Action === "Gather" &&
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Gather"
                                                options={gathers}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.ID}>
                                                        {value.ID} - {value.Name}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.DefaultGather}
                                                onSelect={(option) => this.setState({ ...this.state, DefaultGather: option.ID })}
                                                required
                                            />
                                        }
                                        {
                                            Action === "Dial" &&
                                            <TextField
                                                className={classes.formControl}
                                                label="Transfer To"
                                                id="defaultDial"
                                                name="DefaultDial"
                                                value={this.state.DefaultDial}
                                                onChange={this.handleChange}
                                                InputLabelProps={{ shrink: this.state.DefaultDial ? true : false }}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        }
                                        {
                                            Action === "Hunt" &&
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Hunt Group"
                                                options={huntGroups}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.ID}>
                                                        {value.Name}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.DefaultHuntGroup}
                                                onSelect={(option) => this.setState({ ...this.state, DefaultHuntGroup: option.ID })}
                                                required
                                            />
                                        }
                                        {
                                            Action === "Voicemail" &&
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="Voice Mailbox"
                                                options={voiceMailboxes}
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.VoiceMailbox}>
                                                        {`${value.VoiceMailbox} - ${value.Name}`}
                                                    </MenuItem>
                                                }}
                                                portal={true}
                                                value={this.state.DefaultVoiceMailbox}
                                                onSelect={(option) => this.setState({ ...this.state, DefaultVoiceMailbox: option.VoiceMailbox })}
                                                required
                                            />
                                        }
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">chat_bubble_outline</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Description"
                                            id="description"
                                            name="Description"
                                            value={this.state.Description}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="sm:flex">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">record_voice_over</Icon>
                                        </div>

                                        {
                                            this.state.GreetingType == 'voice' &&
                                            <div className="w-full sm:flex">
                                                <div className="w-full flex">
                                                    <div className="w-full">
                                                        <div className="sm:flex w-full">
                                                            <TextField
                                                                className={classNames(classes.formControl, "w-full")}
                                                                label="Greeting Type"
                                                                id="greetingType"
                                                                select
                                                                name="GreetingType"
                                                                value={this.state.GreetingType}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                // style={{ minWidth: 200 }}
                                                                placeholder="Greeting Type"
                                                            >
                                                                <MenuItem value="voice">
                                                                    Audio Recording
                                                                </MenuItem>
                                                                <MenuItem value="text">
                                                                    Text-to-Speech
                                                                </MenuItem>
                                                            </TextField>
                                                            <div className="hidden sm:block min-w-12 pt-20">
                                                            </div>
                                                            {(!this.state.isRecordingAudio && !this.state.hasRecordedAudio) ?
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classNames(classes.formControl, "w-full text-12")}
                                                                    // style={{ minWidth: 100 }}
                                                                    onClick={this.startRecordingAudio}
                                                                >
                                                                    <Icon className="mr-4">mic_none</Icon> Record Clip
                                                                </Button>
                                                                :
                                                                !this.state.hasRecordedAudio ?
                                                                    <Button
                                                                        variant="contained"
                                                                        color="error"
                                                                        className={classNames(classes.formControl, "w-full text-12")}
                                                                        // style={{ minWidth: 100 }}
                                                                        onClick={this.stopRecordingAudio}
                                                                    >
                                                                        <Icon className="mr-4">stop</Icon> Stop Recording
                                                                    </Button>
                                                                    :
                                                                    <div className="flex w-full">
                                                                        <Tooltip title="Upload Audio Clip">
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                className={classNames(classes.formControl, "w-full text-12")}
                                                                                onClick={this.uploadRecordedAudio}
                                                                            >
                                                                                <Icon className="mr-4">cloud_upload</Icon> <span ref={clipUploadProgress => this.clipUploadProgress = clipUploadProgress}></span>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <div className="hidden sm:block min-w-12 pt-20">
                                                                        </div>
                                                                        <Tooltip title="Delete Audio Clip">
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                className={classNames(classes.formControl, "w-full text-12")}
                                                                                onClick={this.deleteRecordedAudio}
                                                                            >
                                                                                <Icon className="mr-4">delete</Icon>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </div>
                                                            }
                                                        </div>
                                                        {
                                                            (this.state.isRecordingAudio || this.state.hasRecordedAudio) &&
                                                            <React.Fragment>
                                                                <TextField
                                                                    className={classNames(classes.formControl, this.state.isRecordingAudio && "hidden")}
                                                                    label="Clip Name"
                                                                    id="clipName"
                                                                    name="recordedAudioFileName"
                                                                    value={this.state.recordedAudioFileName}
                                                                    onChange={this.handleChange}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    disabled={Boolean(this.state.isRecordingAudio)}
                                                                />
                                                                <audio className={classNames(classes.formControl, this.state.isRecordingAudio && "hidden")} controls style={{ width: '100%' }} ref={(ref) => { this.recordedAudioPlayback = ref }} />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <div className="w-full sm:flex">
                                                    <div className="w-full">
                                                        <TextField
                                                            className={classes.formControl}
                                                            label="Greeting Audio"
                                                            id="greetingFileID"
                                                            select
                                                            name="GreetingFileID"
                                                            value={this.state.GreetingFileID}
                                                            onChange={this.handleChange}
                                                            variant="outlined"
                                                            fullWidth
                                                            placeholder="Select Greeting Audio"
                                                            InputLabelProps={{ shrink: this.state.GreetingFileID ? true : false }}
                                                            disabled={this.state.greetingAudioPlaying}
                                                        >
                                                            <MenuItem value={null}>
                                                                None
                                                            </MenuItem>
                                                            {(AudioClips && AudioClips.length > 0 ? AudioClips.map((value) => (
                                                                <MenuItem value={value.Attachment}>
                                                                    {value.FileName}
                                                                </MenuItem>
                                                            )) : '')}
                                                        </TextField>
                                                        {
                                                            this.state.GreetingFileID &&
                                                            <audio className={classes.formControl} controls style={{ width: '100%' }} ref="greetingAudioPlay" src={`${window["apiLocation"]}/api/AudioFile/${this.state.GreetingFileID}.wav`} onPause={this.greetingAudioPaused} onPlay={this.greetingAudioPlaying} />
                                                        }
                                                    </div>
                                                    <div className="hidden sm:block min-w-12 pt-20">
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classNames(classes.formControl, "w-full text-12")}
                                                        onClick={() => this.clipFileInput.click()}
                                                    >
                                                        <Icon className="mr-4">cloud_upload</Icon> <span ref={clipFileUploadProgress => this.clipFileUploadProgress = clipFileUploadProgress}>Upload Clip</span>
                                                    </Button>
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={this.clipFileSelectedHandler}
                                                        ref={clipFileInput => this.clipFileInput = clipFileInput}
                                                        accept=".mp3,.wav"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.GreetingType == 'text' &&
                                            <React.Fragment>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Greeting Type"
                                                    id="GreetingType"
                                                    select
                                                    name="GreetingType"
                                                    value={this.state.GreetingType}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Greeting Type"
                                                >
                                                    <MenuItem value="voice">
                                                        Audio Recording
                                                    </MenuItem>
                                                    <MenuItem value="text">
                                                        Text-to-Speech
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Rate"
                                                    id="rate"
                                                    select
                                                    name="RateOfSpeech"
                                                    value={this.state.RateOfSpeech}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Rate of Speech"
                                                >
                                                    <MenuItem value="x-slow">
                                                        X-Slow
                                                    </MenuItem>
                                                    <MenuItem value="slow">
                                                        Slow
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="fast">
                                                        Fast
                                                    </MenuItem>
                                                    <MenuItem value="x-fast">
                                                        X-Fast
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Pitch"
                                                    id="vocalPitch"
                                                    select
                                                    name="VocalPitch"
                                                    value={this.state.VocalPitch}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Vocal Pitch"
                                                >
                                                    <MenuItem value="x-low">
                                                        X-Low
                                                    </MenuItem>
                                                    <MenuItem value="low">
                                                        Low
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="high">
                                                        High
                                                    </MenuItem>
                                                    <MenuItem value="x-high">
                                                        X-High
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Volume"
                                                    id="volume"
                                                    select
                                                    name="Volume"
                                                    value={this.state.Volume}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Volume"
                                                >
                                                    <MenuItem value="x-soft">
                                                        X-Soft
                                                    </MenuItem>
                                                    <MenuItem value="soft">
                                                        Soft
                                                    </MenuItem>
                                                    <MenuItem value="medium">
                                                        Normal
                                                    </MenuItem>
                                                    <MenuItem value="loud">
                                                        Loud
                                                    </MenuItem>
                                                    <MenuItem value="x-loud">
                                                        X-Loud
                                                    </MenuItem>
                                                </TextField>
                                            </React.Fragment>
                                        }
                                    </div>
                                    {
                                        this.state.GreetingType == 'text' &&
                                        <div className="sm:flex">
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    className={"mb-12"}
                                                    label="Greeting"
                                                    id="Greeting"
                                                    name="GreetingClip"
                                                    value={this.state.GreetingClip}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    multiline
                                                    rows={3}
                                                    fullWidth
                                                />
                                                {(!EditClip && EditClip !== 0) ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={"mb-12"}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.addClip}
                                                        disabled={this.state.GreetingClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">add_circle_outline</Icon> Add
                                                    </Button>
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={"mb-12"}
                                                        style={{ minWidth: 100 }}
                                                        onClick={this.saveClip}
                                                        disabled={this.state.GreetingClip.trim().length < 1}
                                                    >
                                                        <Icon className="mr-4">save</Icon> Save
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.GreetingType == 'text' && SSMLTags.length > 0 &&
                                        <div className="sm:flex">
                                            <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            </div>
                                            <div className="w-full max-w-full overflow-x-auto mb-12 mt-0">
                                                {
                                                    SSMLTags.map((value, key) =>
                                                        <Tooltip title={value.Attributes} placement="top">
                                                            <Chip key={key} className="mb-8 mr-4 truncate" color="primary" icon={<Icon>record_voice_over</Icon>} label={value.Say} onClick={() => { this.editClip(key); }} onDelete={() => { this.removeClip(key); }} />
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className="sm:flex mt-12">
                                        <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                            <Icon color="action">note</Icon>
                                        </div>
                                        <TextField
                                            className={classes.formControl}
                                            label="Notes"
                                            id="notes"
                                            name="Notes"
                                            value={this.state.Notes}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="sm:flex justify-between">
                                        <FormControlLabel
                                            classes={{ root: "mb-24" }}
                                            control={
                                                <Switch
                                                    checked={this.state.TextEnabledYN === 'Y'}
                                                    name="TextEnabledYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Text Enabled?"
                                        />
                                        {
                                            Action === "Dial" &&
                                            <FormControlLabel
                                                classes={{ root: "mb-24" }}
                                                control={
                                                    <Switch
                                                        checked={this.state.RecordDefaultDialYN === 'Y'}
                                                        name="RecordDefaultDialYN"
                                                        onChange={this.handleChange}
                                                        color="primary"
                                                    />
                                                }
                                                label="Record Transferred Calls"
                                            />
                                        }
                                    </div>

                                    {this.state.TextEnabledYN === 'Y' &&
                                        <React.Fragment>
                                            {/* <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                                    <Icon color="action">textsms</Icon>
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Text Greeting"
                                                    id="textGreeting"
                                                    name="TextGreeting"
                                                    value={this.state.TextGreeting}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                    fullWidth
                                                />
                                            </div> */}

                                            <div className="sm:flex">
                                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                                    <Icon color="action">textsms</Icon>
                                                </div>
                                                <TextField
                                                    className={classes.formControl}
                                                    label="Text Action"
                                                    id="textAction"
                                                    name="TextAction"
                                                    value={this.state.TextAction}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{ shrink: this.state.TextAction ? true : false }}
                                                    variant="outlined"
                                                    fullWidth
                                                    select
                                                >
                                                    <MenuItem value="Enqueue">
                                                        Enqueue
                                                    </MenuItem>
                                                    <MenuItem value="Gather">
                                                        Gather
                                                    </MenuItem>
                                                    <MenuItem value="Forward">
                                                        Forward
                                                    </MenuItem>
                                                </TextField>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                {
                                                    this.state.TextAction === "Enqueue" &&
                                                    <Autocomplete
                                                        className={classes.formControl}
                                                        title="Text Queue"
                                                        options={_.filter(queues, { TextEnabledYN: 'Y' })}
                                                        menuItemComponent={(value) => {
                                                            return <MenuItem value={value.Queue}>
                                                                {value.Queue}
                                                            </MenuItem>
                                                        }}
                                                        portal={true}
                                                        value={this.state.TextQueue}
                                                        onSelect={(option) => this.setState({ ...this.state, TextQueue: option.Queue })}
                                                        required
                                                    />
                                                }
                                                {
                                                    this.state.TextAction === "Gather" &&
                                                    <Autocomplete
                                                        className={classes.formControl}
                                                        title="Gather"
                                                        options={textGathers}
                                                        menuItemComponent={(value) => {
                                                            return <MenuItem value={value.ID}>
                                                                {value.ID} - {value.Name}
                                                            </MenuItem>
                                                        }}
                                                        portal={true}
                                                        value={this.state.TextGather}
                                                        onSelect={(option) => this.setState({ ...this.state, TextGather: option.ID })}
                                                        required
                                                    />
                                                }
                                                {
                                                    this.state.TextAction === "Forward" &&
                                                    <TextField
                                                        className={classes.formControl}
                                                        label="Forward To"
                                                        id="textForward"
                                                        name="TextForward"
                                                        value={this.state.TextForward}
                                                        onChange={this.handleChange}
                                                        InputLabelProps={{ shrink: this.state.TextForward ? true : false }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </DialogContent>
                            </FuseAnimate>
                        </TabContainer>}
                        {value === 1 && phoneNumberDialog.type == 'edit' && <TabContainer><FileList /></TabContainer>}
                        {value === 2 && phoneNumberDialog.type == 'edit' && <TabContainer></TabContainer>}

                        <DialogActions className="dialog-actions justify-between pl-16">
                            {['new', 'customer'].indexOf(phoneNumberDialog.type) > -1 ? (
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addPhoneNumber(rec);
                                            //this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                    <div className="w-1/2 text-right">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            updatePhoneNumber(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                    <div className="w-1/2 text-center">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.ActiveYN === 'Y'}
                                                    name="ActiveYN"
                                                    onChange={this.handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Active?"
                                        />
                                    </div>
                                    <IconButton
                                        onClick={() => {
                                            this.props.removePhoneNumber(this.state);
                                            this.closeComposeDialog();
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </React.Fragment>
                            )}
                        </DialogActions>
                    </Dialog>
                }
            </Media>
        );
    }
}
PhoneNumbersDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditPhoneNumberDialog: Actions.closeEditPhoneNumberDialog,
        closeNewPhoneNumberDialog: Actions.closeNewPhoneNumberDialog,
        addPhoneNumber: Actions.addPhoneNumber,
        updatePhoneNumber: Actions.updatePhoneNumber,
        removePhoneNumber: Actions.removePhoneNumber,
        getFiles: FileActions.getFiles,
        showMessage: showMessage,
        setAwaitingCallFrom: setAwaitingCallFrom,
        openNewCallGatherKeysDialog: GatherActions.openNewCallGatherKeysDialog
    }, dispatch);
}

function mapStateToProps({ callQueuesApp, phoneNumbersApp, fileManagerApp, spReducers, dialer }) {
    return {
        phoneNumberDialog: phoneNumbersApp.phoneNumbers.phoneNumberDialog,
        businessUnits: spReducers.businessUnits,
        divisions: spReducers.divisions,
        departments: spReducers.departments,
        queues: spReducers.callQueues,
        gathers: spReducers.callGathers,
        textGathers: spReducers.textGathers,
        files: fileManagerApp.files,
        selectedItem: fileManagerApp.selectedItem,
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        awaitingCallFrom: dialer.awaitingCallFrom,
        AudioClips: spReducers.audioFiles.audioClips,
        huntGroups: spReducers.huntGroups,
        voiceMailboxes: spReducers.voiceMailboxes,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PhoneNumbersDialog));
