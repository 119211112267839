import * as Actions from '../actions/splitscreen-dialogs.actions';
import CacheManager from 'store/middlewares/cache';

import _ from '@lodash';
import { data } from 'jquery';

const offline = new CacheManager();

const initialState = {
    data: [],
    show: null,
    splitPosition: null,
    height: null,
    cornered: 1,
    snapPosition: null,
    dragging: null,
    layout: null,
};


const splitscreenDialogReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.ADD_SPLITSCREEN_DIALOG:
            {
                return {
                    ...state,
                    data: action.data,
                };
            }
        case Actions.SHOW_SPLITSCREEN_DIALOG:
            {
                return {
                    ...state,
                    show: action.payload
                };
            }
        case Actions.SET_SPLITSCREEN_DIALOG:
            {
                if(action.data){
                    window["warn"]("setSplitscreenDialog 1", action.show)
                    return {
                        ...state,
                        data: action.data,
                        show: action.show,
                        splitPosition: action.splitPosition,
                        height: action.height,
                        cornered: action.cornered,
                        snapPosition: null,
                        dragging: null,
                        layout: action.layout,
                    };
                } else {
                    window["warn"]("setSplitscreenDialog 2", action.show)
                    return {
                        ...state,
                        show: action.show,
                        splitPosition: action.splitPosition,
                        height: action.height,
                        cornered: action.cornered,
                        snapPosition: null,
                        dragging: null,
                        layout: action.layout,
                    };
                }

            }
        default:
            {
                return state;
            }
    }
};

export default splitscreenDialogReducer;