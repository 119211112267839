import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    recording: null,
    selectedVoiceMailboxIds: [],
    routeParams: {},
    voiceMailboxDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    searchResults: [],
};

const voiceMailboxesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_RESULTS:
            {
                return {
                    ...state,
                    searchResults: action.payload,
                }
            }
        case Actions.SET_RECORDING: {
            const { recording } = action;
            return {
                ...state,
                recording
            }
        }
        case Actions.GET_VOICE_MAILBOXES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_VOICE_MAILBOXES:
            {

                const voiceMailboxId = action.voiceMailboxId;

                let selectedVoiceMailboxIds = [...state.selectedVoiceMailboxIds];

                if (selectedVoiceMailboxIds.find(id => id === voiceMailboxId) !== undefined) {
                    selectedVoiceMailboxIds = selectedVoiceMailboxIds.filter(id => id !== voiceMailboxId);
                }
                else {
                    selectedVoiceMailboxIds = [...selectedVoiceMailboxIds, voiceMailboxId];
                }

                return {
                    ...state,
                    selectedVoiceMailboxIds: selectedVoiceMailboxIds
                };
            }
        case Actions.SELECT_ALL_VOICE_MAILBOXES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedVoiceMailboxIds = arr.map(voiceMailboxes => voiceMailboxes.id);

                return {
                    ...state,
                    selectedVoiceMailboxIds: selectedVoiceMailboxIds
                };
            }
        case Actions.DESELECT_ALL_VOICE_MAILBOXES:
            {
                return {
                    ...state,
                    selectedVoiceMailboxIds: []
                };
            }
        case Actions.OPEN_NEW_VOICE_MAILBOX_DIALOG:
            {
                return {
                    ...state,
                    voiceMailboxDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_VOICE_MAILBOX_DIALOG:
            {
                return {
                    ...state,
                    voiceMailboxDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_VOICE_MAILBOX_DIALOG:
            {
                return {
                    ...state,
                    voiceMailboxDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_VOICE_MAILBOX_DIALOG:
            {
                return {
                    ...state,
                    voiceMailboxDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default voiceMailboxesReducer;
