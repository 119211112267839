import axios from 'axios';
import { getUserData } from 'main/content/apps/work-order-usages/store/actions/user.actions';
import { showMessage, setOfflineData, guid } from 'store/actions';

export const GET_WORK_ORDER_USAGES = '[WORK ORDER USAGES APP] GET WORK ORDER USAGES';
export const SET_SEARCH_TEXT = '[WORK ORDER USAGES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_WORK_ORDER_USAGES = '[WORK ORDER USAGES APP] TOGGLE IN SELECTED WORK ORDER USAGES';
export const SELECT_ALL_WORK_ORDER_USAGES = '[WORK ORDER USAGES APP] SELECT ALL WORK ORDER USAGES';
export const DESELECT_ALL_WORK_ORDER_USAGES = '[WORK ORDER USAGES APP] DESELECT ALL WORK ORDER USAGES';
export const OPEN_NEW_WORK_ORDER_USAGE_DIALOG = '[WORK ORDER USAGES APP] OPEN NEW WORK ORDER USAGE DIALOG';
export const CLOSE_NEW_WORK_ORDER_USAGE_DIALOG = '[WORK ORDER USAGES APP] CLOSE NEW WORK ORDER USAGE DIALOG';
export const OPEN_EDIT_WORK_ORDER_USAGE_DIALOG = '[WORK ORDER USAGES APP] OPEN EDIT WORK ORDER USAGE DIALOG';
export const UPDATE_EDIT_WORK_ORDER_USAGE_DIALOG = '[WORK ORDER USAGES APP] UPDATE EDIT WORK ORDER USAGE DIALOG';
export const CLOSE_EDIT_WORK_ORDER_USAGE_DIALOG = '[WORK ORDER USAGES APP] CLOSE EDIT WORK ORDER USAGE DIALOG';
export const ADD_WORK_ORDER_USAGE = '[WORK ORDER USAGES APP] ADD WORK ORDER USAGE';
export const UPDATE_WORK_ORDER_USAGE = '[WORK ORDER USAGES APP] UPDATE WORK ORDER USAGE';
export const REMOVE_WORK_ORDER_USAGE = '[WORK ORDER USAGES APP] REMOVE WORK ORDER USAGE';
export const REMOVE_WORK_ORDER_USAGES = '[WORK ORDER USAGES APP] REMOVE WORK ORDER USAGES';
export const TOGGLE_STARRED_WORK_ORDER_USAGE = '[WORK ORDER USAGES APP] TOGGLE STARRED WORK ORDER USAGE';
export const TOGGLE_STARRED_WORK_ORDER_USAGES = '[WORK ORDER USAGES APP] TOGGLE STARRED WORK ORDER USAGES';
export const SET_WORK_ORDER_USAGES_STARRED = '[WORK ORDER USAGES APP] SET WORK ORDER USAGES STARRED ';

export function getWorkOrderUsages(routeParams) {
    const request = axios.get('/api/work-order-usages-app/work-order-usages', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_WORK_ORDER_USAGES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedWorkOrderUsages(workOrderUsageId) {
    return {
        type: TOGGLE_IN_SELECTED_WORK_ORDER_USAGES,
        workOrderUsageId
    }
}


export function selectAllWorkOrderUsages() {
    return {
        type: SELECT_ALL_WORK_ORDER_USAGES
    }
}

export function deSelectAllWorkOrderUsages() {
    return {
        type: DESELECT_ALL_WORK_ORDER_USAGES
    }
}


export function openNewWorkOrderUsageDialog(data, taxability) {
    return {
        type: OPEN_NEW_WORK_ORDER_USAGE_DIALOG,
        data,
        taxability
    }
}

export function closeNewWorkOrderUsageDialog() {
    return {
        type: CLOSE_NEW_WORK_ORDER_USAGE_DIALOG
    }
}

export function openEditWorkOrderUsageDialog(data, taxability, readOnly) {
    window["warn"]("openEditWorkOrderUsageDialog ran!", data, taxability, readOnly)
    return {
        type: OPEN_EDIT_WORK_ORDER_USAGE_DIALOG,
        data,
        taxability,
        readOnly
    }
}

export function updateEditWorkOrderUsageDialog(data, taxability, readOnly) {
    return {
        type: UPDATE_EDIT_WORK_ORDER_USAGE_DIALOG,
        data,
        taxability,
        readOnly
    }
}

export function closeEditWorkOrderUsageDialog() {
    return {
        type: CLOSE_EDIT_WORK_ORDER_USAGE_DIALOG
    }
}

export function addWorkOrderUsage(newWorkOrderUsage) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/WorkOrderUsage`;
        const request = axios.post(url,
            { ...newWorkOrderUsage, Data: { ErrMsg: null } }
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch({
                    type: ADD_WORK_ORDER_USAGE,
                    data: response.data
                });
                dispatch(showMessage({
                    message: `Usage line #${response.data.Line} has been successfully created.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            console.warn('Error adding usage: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...newWorkOrderUsage, OfflineID: guid(), Data: { ErrMsg: null } };
                Promise.all([
                    dispatch(setOfflineData('ADD', 'post', url, offlineData, 'work-order-usages')),
                ]).then(() => {
                    dispatch({
                        type: ADD_WORK_ORDER_USAGE,
                        data: offlineData
                    })
                })
            }
        });
    };
}

export function updateWorkOrderUsage(workOrderUsage) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/WorkOrderUsage?Co=${encodeURIComponent(workOrderUsage.Co)}&WorkOrder=${encodeURIComponent(workOrderUsage.WorkOrder)}&Line=${encodeURIComponent(workOrderUsage.Line)}`;
        const request = axios.put(url,
            { ...workOrderUsage, Data: { ErrMsg: null } }
        );

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch({
                    type: UPDATE_WORK_ORDER_USAGE,
                    data: response.data
                });
                dispatch(showMessage({
                    message: `Usage line #${workOrderUsage.Line} has been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            console.warn('Error updating usage: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('UPDATE', 'put', url, { ...workOrderUsage, Data: { ErrMsg: null } }, 'work-order-usages')),
                ]).then(() => {
                    dispatch({
                        type: UPDATE_WORK_ORDER_USAGE,
                        data: { ...workOrderUsage, Data: { ErrMsg: null } }
                    })
                })
            }
        });
    };
}

export function removeWorkOrderUsage(workOrderUsage) {
    return (dispatch, getState) => {

        const url = `${window["apiLocation"]}/api/WorkOrderUsage?Co=${encodeURIComponent(workOrderUsage.Co)}&WorkOrder=${encodeURIComponent(workOrderUsage.WorkOrder)}&Line=${encodeURIComponent(workOrderUsage.Line)}`;
        const request = axios.delete(url,
            { ...workOrderUsage, Data: { ErrMsg: null } }
        );

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch({
                    type: REMOVE_WORK_ORDER_USAGE,
                    data: { ...workOrderUsage }
                });
                dispatch(showMessage({
                    message: `Usage line #${workOrderUsage.Line} has been successfully deleted.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        }).catch((error) => {
            console.warn('Error deleting usage: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('REMOVE', 'delete', url, { ...workOrderUsage, Data: { ErrMsg: null } }, 'work-order-usages')),
                ]).then(() => {
                    dispatch({
                        type: REMOVE_WORK_ORDER_USAGE,
                        data: { ...workOrderUsage, Data: { ErrMsg: null } }
                    })
                })
            }
        });
    };
}


export function removeWorkOrderUsages(workOrderUsageIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderUsagesApp.workOrderUsages;

        const request = axios.post('/api/work-order-usages-app/remove-workOrderUsages', {
            workOrderUsageIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_WORK_ORDER_USAGES
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_USAGES
                })
            ]).then(() => dispatch(getWorkOrderUsages(routeParams)))
        );
    };
}

export function toggleStarredWorkOrderUsage(workOrderUsageId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().workOrderUsagesApp.workOrderUsages;

        const request = axios.post('/api/work-order-usages-app/toggle-starred-workOrderUsage', {
            workOrderUsageId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_WORK_ORDER_USAGE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderUsages(routeParams)))
        );
    };
}

export function toggleStarredWorkOrderUsages(workOrderUsageIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderUsagesApp.workOrderUsages;

        const request = axios.post('/api/work-order-usages-app/toggle-starred-workOrderUsages', {
            workOrderUsageIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_WORK_ORDER_USAGES
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_USAGES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderUsages(routeParams)))
        );
    };
}

export function setWorkOrderUsagesStarred(workOrderUsageIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderUsagesApp.workOrderUsages;

        const request = axios.post('/api/work-order-usages-app/set-workOrderUsages-starred', {
            workOrderUsageIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_WORK_ORDER_USAGES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_USAGES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderUsages(routeParams)))
        );
    };
}

export function setWorkOrderUsagesUnstarred(workOrderUsageIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().workOrderUsagesApp.workOrderUsages;

        const request = axios.post('/api/work-order-usages-app/set-workOrderUsages-unstarred', {
            workOrderUsageIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_WORK_ORDER_USAGES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_WORK_ORDER_USAGES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getWorkOrderUsages(routeParams)))
        );
    };
}
