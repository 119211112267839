import * as Actions from 'store/actions/spConnection';

const workOrders = function (state = [], action) {
    switch (action.type) {
        case Actions.GET_WORK_ORDER_DATA:
            {
                return action.payload;
            }
        case Actions.SET_WORK_ORDER_DATA:
            {
                return action.payload;
            }
        // case Actions.UPDATE_WORK_ORDER_DATA:
        //     {
        //         const { payload } = action;
        //         const workOrders = state;
        //         for (var i = 0; i < payload.length; i++) {
        //             const update = payload[i];
        //             let exists = false;
        //             for (var w = 0; w < workOrders.length; w++) {
        //                 if (workOrders[w].ID == update.ID) {
        //                     exists = true;
        //                     switch (update.Action) {
        //                         case "UPDATE":
        //                             workOrders.splice(w, 1, update.Data);
        //                             break;
        //                         case "REMOVE":
        //                             workOrders.splice(w, 1);
        //                             break;
        //                     }
        //                 }
        //             }
        //             if (!exists && update.Action == "UPDATE") {
        //                 workOrders.push(update.Data);
        //             }
        //         }
        //         return workOrders;
        //     }
    }

    return state;
};

export default workOrders;