import axios from 'axios/index';
import _ from '@lodash';

export const SET_VP_COMPANY = '[SPCONNECTION] SET VP COMPANY';
export const SET_VP_COMPANY_DATA = '[SPCONNECTION] SET VP COMPANY DATA';
export const GET_VP_COMPANY_DATA = '[SPCONNECTION] GET VP COMPANY DATA';
export const SET_VP_VENDOR_DATA = '[SPCONNECTION] SET VP VENDOR DATA';
export const GET_VP_VENDOR_DATA = '[SPCONNECTION] GET VP VENDOR DATA';
export const SET_VP_DEPARTMENT_DATA = '[SPCONNECTION] SET VP DEPARTMENT DATA';
export const GET_VP_DEPARTMENT_DATA = '[SPCONNECTION] GET VP DEPARTMENT DATA';
export const SET_VP_EMPLOYEE_DATA = '[SPCONNECTION] SET VP EMPLOYEE DATA';
export const GET_VP_EMPLOYEE_DATA = '[SPCONNECTION] GET VP EMPLOYEE DATA';
export const SET_VP_MATERIAL_DATA = '[SPCONNECTION] SET VP MATERIAL DATA';
export const GET_VP_MATERIAL_DATA = '[SPCONNECTION] GET VP MATERIAL DATA';
export const SET_VP_EARN_CODE_DATA = '[SPCONNECTION] SET VP EARN CODE DATA';
export const GET_VP_EARN_CODE_DATA = '[SPCONNECTION] GET VP EARN CODE DATA';
export const SET_VP_GL_ACCOUNT_DATA = '[SPCONNECTION] SET VP GL ACCOUNT DATA';
export const GET_VP_GL_ACCOUNT_DATA = '[SPCONNECTION] GET VP GL ACCOUNT DATA';
export const SET_VP_PAY_TERM_DATA = '[SPCONNECTION] SET VP PAY TERM DATA';
export const GET_VP_PAY_TERM_DATA = '[SPCONNECTION] GET VP PAY TERM DATA';
export const SET_VP_TAX_CODE_DATA = '[SPCONNECTION] SET VP TAX CODE DATA';
export const GET_VP_TAX_CODE_DATA = '[SPCONNECTION] GET VP TAX CODE DATA';
export const SET_VP_REC_TYPE_DATA = '[SPCONNECTION] SET VP REC TYPE DATA';
export const GET_VP_REC_TYPE_DATA = '[SPCONNECTION] GET VP REC TYPE DATA';

export function getVPCompanyData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/Companies?Co=${Co}`);

    return (dispatch, getState) => {
        request.then((response) => {
            // const vpCo = _.find(response.data, { HQCo1: Co });
            //if (vpCo) {
            Promise.all([
                // dispatch({
                //     type: GET_VP_COMPANY_DATA,
                //     payload: response.data
                // }),
                dispatch({
                    type: SET_VP_COMPANY,
                    payload: response.data
                }),
                dispatch(getVPRecTypeData(response.data.HQCo1)),
                // dispatch(getVPDepartmentData(vpCo.HQCo1)),
                // dispatch(getVPEmployeeData(vpCo.HQCo1)),
                // dispatch(getVPVendorData(vpCo.VendorGroup)),
                // dispatch(getVPEarnCodeData(vpCo.HQCo1)),
                // dispatch(getVPGLAccountData(vpCo.HQCo1)),
                // dispatch(getVPTaxCodeData(vpCo.TaxGroup)),
            ]);
            /*} else {
                    Promise.all([
                        dispatch({
                            type: GET_VP_COMPANY_DATA,
                            payload: response.data
                        }),
                    ]);
                }*/
        });
    }
}

export function setVPCompanyData(data) {
    return {
        type: SET_VP_COMPANY_DATA,
        payload: data
    };
}

export function getVPVendorData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/Vendors`, {
        params: {
            Group: Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_VENDOR_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPVendorData(data) {
    return {
        type: SET_VP_VENDOR_DATA,
        payload: data
    };
}

export function getVPMaterialData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/Materials`, {
        params: {
            Group: Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_MATERIAL_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPMaterialData(data) {
    return {
        type: SET_VP_MATERIAL_DATA,
        payload: data
    };
}

export function getVPEmployeeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/Employees`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_EMPLOYEE_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPEmployeeData(data) {
    return {
        type: SET_VP_EMPLOYEE_DATA,
        payload: data
    };
}

export function getVPDepartmentData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/Departments`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_DEPARTMENT_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPDepartmentData(data) {
    return {
        type: SET_VP_DEPARTMENT_DATA,
        payload: data
    };
}

export function getVPEarnCodeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/EarnCodes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_EARN_CODE_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPEarnCodeData(data) {
    return {
        type: SET_VP_EARN_CODE_DATA,
        payload: data
    };
}

export function getVPGLAccountData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/GLAccounts`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_GL_ACCOUNT_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPGLAccountData(data) {
    return {
        type: SET_VP_GL_ACCOUNT_DATA,
        payload: data
    };
}

export function getVPPayTermData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/PayTerms`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_PAY_TERM_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPPayTermData(data) {
    return {
        type: SET_VP_PAY_TERM_DATA,
        payload: data
    };
}

export function getVPTaxCodeData(Group) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/TaxCodes`, {
        params: {
            Group
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_TAX_CODE_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPTaxCodeData(data) {
    return {
        type: SET_VP_TAX_CODE_DATA,
        payload: data
    };
}

export function getVPRecTypeData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VPSettings/RecTypes`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VP_REC_TYPE_DATA,
                payload: response.data
            })
        });
    }
}

export function setVPRecTypeData(data) {
    return {
        type: SET_VP_REC_TYPE_DATA,
        payload: data
    };
}
