import React, { Component } from 'react';
import {
    Grow, Slide, TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, InputAdornment, Typography, Toolbar, AppBar, Avatar, MenuItem, FormGroup, FormControlLabel, Switch
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from '@lodash';
import Media from 'react-media';
import { Inspector } from 'react-inspector';
import axios from 'axios';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        // background: 'rgb(36, 36, 36)',
    },
    paper: {
        background: 'rgb(36, 36, 36)',
    },
    dialog: {

    },
});

class InspectorDialog extends Component {
    // state = {
    //     storage: {}
    // };

    // componentDidMount() {
    //     const { data, open } = this.props;
    //     if (open && navigator && navigator.storage) {
    //         const est = navigator.storage.estimate();
    //         est.then((storage) => {
    //             storage.available = (storage.quota / (1024 * 1024)).toFixed(2);
    //             storage.used = (storage.usage / (1024 * 1024)).toFixed(2);
    //             storage.usagePct = (storage.used / storage.available * 100).toFixed(2);
    //             if (storage.usageDetails) {
    //                 storage.usageDetailsInMb = {};
    //                 Object.keys(storage.usageDetails).map(value => {
    //                     if (!isNaN(storage.usageDetails[value])) {
    //                         storage.usageDetailsInMb[value] = (storage.usageDetails[value] / (1024 * 1024)).toFixed(2);
    //                     }
    //                 })
    //             }
    //             this.setState({ storage });
    //         })
    //     }
    // }
    // componentDidUpdate = (prevProps, prevState) => {
    //     const { data, open } = this.props;
    //     if (open && !prevProps.open && navigator && navigator.storage) {
    //         const est = navigator.storage.estimate();
    //         est.then((storage) => {
    //             storage.available = (storage.quota / (1024 * 1024)).toFixed(2);
    //             storage.used = (storage.usage / (1024 * 1024)).toFixed(2);
    //             storage.usagePct = (storage.used / storage.available * 100).toFixed(2);
    //             if (storage.usageDetails) {
    //                 storage.usageDetailsInMb = {};
    //                 Object.keys(storage.usageDetails).map(value => {
    //                     if (!isNaN(storage.usageDetails[value])) {
    //                         storage.usageDetailsInMb[value] = (storage.usageDetails[value] / (1024 * 1024)).toFixed(2);
    //                     }
    //                 })
    //             }
    //             this.setState({ storage });
    //         })
    //     }
    // }

    // getFullData = () => {
    //     const { data } = this.props;
    //     const { storage } = this.state;
    //     if (data) {
    //         return {
    //             ...data,
    //             storage
    //         }
    //     } else {
    //         return {
    //             storage
    //         }
    //     }
    // }

    render() {
        const { classes, open, theme, name, securables, data } = this.props;
        const debugAccess = _.find(securables, { Securable: "inspector" });
        // const data = this.getFullData();
        const ordered = {};
        Object.keys(data).sort().forEach(function (key) {
            ordered[key] = data[key];
        });

        if (debugAccess) {
            return (
                <Media query="(min-width: 1200px)"/**/>
                    {matches =>
                        <Dialog
                            TransitionComponent={!matches ? SlideUp : Grow}
                            classes={{
                                root: classes.root,
                                paper: classNames(classes.paper, !matches ? "full-screen-dialog" : "")
                            }}
                            className={classes.root}
                            open={open}
                            onClose={this.props.onClose}
                            fullWidth
                            maxWidth="md"
                            fullScreen={!matches}
                        >

                            <AppBar position="static" elevation={1} className="dialog-header">
                                <Toolbar className="md:flex w-full">
                                    <Typography variant="subtitle1" color="inherit">
                                        <Icon className="mr-8 align-middle">code</Icon>
                                        Data Inspector
                                    </Typography>
                                </Toolbar>
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: matches.medium ? 8 : 6,
                                        color: 'white'
                                    }}
                                    onClick={this.props.onClose}
                                    className="dialog-header-icon"
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </AppBar>

                            <DialogContent classes={{ root: classNames("p-24 min-h-512", classes.dialog) }}>
                                <Inspector data={ordered} theme={theme} name={name} />
                            </DialogContent>
                        </Dialog>
                    }
                </Media>
            );
        } else {
            return '';
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(InspectorDialog));
