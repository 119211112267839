import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class CallQueueMembersList extends Component {

    state = {
        selectedCallQueueMembersMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedCallQueueMenu = (event) => {
        this.setState({ selectedCallQueueMembersMenu: event.currentTarget });
    };

    componentDidUpdate(prevProps, prevState) {
        window["warn"]('Call Queues List:', this.props);
    }

    closeSelectedCallQueuesMenu = () => {
        this.setState({ selectedCallQueueMembersMenu: null });
    };

    render() {
        const { classes, onSelected } = this.props;
        const data = this.props.data;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="">
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">contact_phone</Icon>Agents</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo) => {
                            return {
                                className: "cursor-pointer",
                                onClick: () => {
                                    if (rowInfo && onSelected) {
                                        onSelected(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            // {
                            //     Header: "Queue",
                            //     accessor: "Queue",
                            //     className: "font-bold justify-center",
                            //     width: 175
                            // },
                            {
                                Header: "Agent",
                                accessor: "UserName",
                                className: "font-bold",
                                width: 300,
                            },
                            {
                                Header: "Rating",
                                accessor: "Rating",
                                className: "justify-center",
                                Cell: row => `${row.value}/10`,
                                width: 96,
                            },
                            // {
                            //     Header: "Active",
                            //     accessor: "ActiveYN",
                            //     className: "justify-center",
                            //     width: 64,
                            // },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Agents found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCallQueues: Actions.getCallQueues,
        getUserData: Actions.getUserData,
        toggleInSelectedCallQueues: Actions.toggleInSelectedCallQueues,
        selectAllCallQueues: Actions.selectAllCallQueues,
        deSelectAllCallQueues: Actions.deSelectAllCallQueues,
        openEditCallQueueDialog: Actions.openEditCallQueueDialog,
        removeCallQueues: Actions.removeCallQueues,
        removeCallQueue: Actions.removeCallQueue,
        toggleStarredCallQueue: Actions.toggleStarredCallQueue,
        toggleStarredCallQueues: Actions.toggleStarredCallQueues,
        setCallQueuesStarred: Actions.setCallQueuesStarred,
        setCallQueuesUnstarred: Actions.setCallQueuesUnstarred
    }, dispatch);
}

function mapStateToProps({ callQueuesApp, spReducers }) {
    return {
        selectedCallQueueIds: callQueuesApp.CallQueues.selectedCallQueueIds,
        searchText: callQueuesApp.CallQueues.searchText,
        user: callQueuesApp.user,
        callQueueData: spReducers.callQueues
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CallQueueMembersList)));
