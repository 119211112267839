import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class PayTermsList extends Component {

    state = {
        selectedPayTermsMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedPayTermMenu = (event) => {
        this.setState({ selectedPayTermsMenu: event.currentTarget });
    };

    closeSelectedPayTermsMenu = () => {
        this.setState({ selectedPayTermsMenu: null });
    };

    render() {
        const { classes, payTerms, user, searchText, openNewPayTermDialog, selectedPayTermIds, selectAllPayTerms, deSelectAllPayTerms, toggleInSelectedPayTerms, openEditPayTermDialog, removePayTerm, toggleStarredPayTerm, setPayTermsUnstarred, setPayTermsStarred } = this.props;
        const data = this.getFilteredArray((!this.props.payTermData ? [] : this.props.payTermData), searchText);
        const { selectedPayTermsMenu } = this.state;
        const dueTypes = ['On Receipt', 'Days From', 'On Day'];
        const discountTypes = ['No Discount', 'Until Days From', 'Always'];

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewPayTermDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">update</Icon>Pay Terms</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditPayTermDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Pay Terms",
                                accessor: "PayTerms",
                                className: "font-bold justify-center",
                                width: 128
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 256
                            },
                            {
                                Header: "Due Type",
                                accessor: "DueType",
                                className: "justify-center",
                                Cell: row => {
                                    return `${row.original.DueType} - ${dueTypes[row.original.DueType]}`;
                                }
                            },
                            {
                                Header: "Due Days",
                                accessor: "DueDays",
                                className: "justify-center",
                            },
                            {
                                Header: "Discount Type",
                                accessor: "DiscountType",
                                className: "justify-center",
                                Cell: row => {
                                    return `${row.original.DiscountType} - ${discountTypes[row.original.DiscountType]}`;
                                }
                            },
                            {
                                Header: "Discount Days",
                                accessor: "DiscountDays",
                                className: "justify-center",
                            },
                            {
                                Header: "Discount %",
                                accessor: "DiscountRate",
                                className: "justify-center",
                                Cell: row => {
                                    return `${row.original.DiscountRate.toFixed(2)}%`;
                                }
                            },
                            {
                                Header: "Discount All?",
                                accessor: "DiscountAllYN",
                                className: "justify-center",
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Pay Terms found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPayTerms: Actions.getPayTerms,
        getUserData: Actions.getUserData,
        toggleInSelectedPayTerms: Actions.toggleInSelectedPayTerms,
        selectAllPayTerms: Actions.selectAllPayTerms,
        deSelectAllPayTerms: Actions.deSelectAllPayTerms,
        openEditPayTermDialog: Actions.openEditPayTermDialog,
        removePayTerm: Actions.removePayTerm,
        toggleStarredPayTerm: Actions.toggleStarredPayTerm,
        toggleStarredPayTerms: Actions.toggleStarredPayTerms,
        setPayTermsStarred: Actions.setPayTermsStarred,
        setPayTermsUnstarred: Actions.setPayTermsUnstarred,
        openNewPayTermDialog: Actions.openNewPayTermDialog
    }, dispatch);
}

function mapStateToProps({ payTermsApp, spReducers }) {
    return {
        payTerms: payTermsApp.payTerms.entities,
        selectedPayTermIds: payTermsApp.payTerms.selectedPayTermIds,
        searchText: payTermsApp.payTerms.searchText,
        user: payTermsApp.user,
        payTermData: spReducers.payTerms
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PayTermsList)));
