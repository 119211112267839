import * as Actions from 'store/actions/spConnection';

const initialState = {
    List: [],
    Co: null,
    QBOLink: null
};

const companies = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COMPANY_DATA:
            {
                return {
                    ...initialState,
                    ...state,
                    List: action.payload,
                    QBOLink: state.QBOLink
                };
            }
        case Actions.SET_COMPANY:
            {
                const { Co } = action;
                return {
                    ...initialState,
                    ...state,
                    QBOLink: state.QBOLink,
                    Co,
                };
            }
        case Actions.SET_QBO_LINK:
            {
                return {
                    ...state,
                    QBOLink: action.payload
                };
            }
    }

    return state;
};

export default companies;