import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Avatar, Button, Icon, MenuItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TimePicker } from "@material-ui/pickers";
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import getProfileImage from '../../../functions/getProfileImageUrl';
import {getTechnicianData} from '../../../../store/actions/spConnection/technicians.actions';
const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    formControl: {
        marginBottom: 24
    },
    updateShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteShift: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});

const newTechnicianState = {
    ID: null,
    avatar: 'assets/images/avatars/profile.jpg',
    Co: null,
    Technician: null,
    Employee: null,
    FirstName: null,
    LastName: null,
    MiddleInitial: null,
    TeamCategory: null,
    TeamRole: null,
    Phone: null,
    Email: null,
    Bio: null,
    ExemptYN: 'N',
    Notes: null,
    ActiveYN: 'Y',
    DefaultBusinessUnit: null,
    DefaultDivision: null,
    DefaultDepartment: null,
    value: 1,
    histTab: 0,
    Data: {
        WorkOrders: [],
        Trips: [],
        Timecard: null,
    },
    showTabs: false,
    shift: {
        ID: null,
        Co: null,
        Technician: null,
        Day: null,
        StartTime: null,
        EndTime: null,
        stDate: null,
        etDate: null,
    },
    disableDay: false
};

class TechnicianShiftsList extends Component {

    state = { ..._.cloneDeepWith(newTechnicianState) };

    componentDidMount() {
        let data = this.props.parentState;

        this.setState({ ..._.cloneDeepWith(newTechnicianState), ...data })
    }

    componentDidUpdate(prevProps, prevState) {
        let data = this.props.technicianDialog.data;

        if (!_.isEqual(this.props.technicianDialog, prevProps.technicianDialog)) { //|| !_.isEqual(loading, prevProps.loading)
            this.setState({ ...this.state, data});
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleStartTimeChange = (event) => {
        let { StartTime, stDate } = this.state.shift;
        if (event) {
            StartTime = event.format("HH:mm:ss")
            stDate = event.toDate()
            window["warn"]("time event", StartTime)
        } else {
            StartTime = null;
            stDate = null;
        }
        this.setState({ ...this.state, shift: { ...this.state.shift, StartTime, stDate } });
    }

    handleEndTimeChange = (event) => {
        let { EndTime, etDate } = this.state.shift;
        if (event) {
            EndTime = event.format("HH:mm:ss")
            etDate = event.toDate()
            window["warn"]("time event", EndTime)
        } else {
            EndTime = null;
            etDate = null;
        }
        this.setState({ ...this.state, shift: { ...this.state.shift, EndTime, etDate } });
    }

    postShift = () => {
        const { Co, shift, Technician } = this.state;

        const { ID } = shift;

        let day = shift.Day;

        let body = {
            Co: Co,
            Technician: Technician,
            Day: day,
            StartTime: shift.StartTime,
            EndTime: shift.EndTime,
        }

        axios.post(`${window["apiLocation"]}/api/TechnicianShift`, body).then((res) => {
            if (res) {
                const { Shifts } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Shifts, { Co, Technician, ID });
                if (index > -1) {
                    Shifts.splice(index, 1, res.data);
                } else {
                    Shifts.push(res.data);
                }
                this.setState({ 
                    ...this.state,
                    Data: {...this.state.Data, Shifts},
                    shift: newTechnicianState.shift
                }, () => {
                    this.props.showMessage({
                        message: `Technician Shift was added successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                    // getTechnicianData('1')
                     this.props.getTechnicianData('1');
                });
                this.props.setStateFxn({ ...this.props.parentState, Data: {...this.state.Data, Shifts}, })
            }
        });
    }

    updateShift = () => {
        const { Co, shift, Technician } = this.state;

        const { ID } = shift;

        let day = shift.Day;

        let body = {
            ID,
            Co: Co,
            Technician: Technician,
            Day: day,
            StartTime: shift.StartTime,
            EndTime: shift.EndTime,
        }

        axios.put(`${window["apiLocation"]}/api/TechnicianShift?Co=${encodeURIComponent(Co)}&ID=${encodeURIComponent(ID)}`, body).then((res) => {
            if (res) {
                const { Shifts } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Shifts, { Co, Technician, ID });
                if (index > -1) {
                    Shifts.splice(index, 1, res.data);
                } else {
                    Shifts.push(res.data);
                }
                this.setState({ 
                    ...this.state,
                    Data: {...this.state.Data, Shifts},
                    shift: newTechnicianState.shift,
                    disableDay: false
                }, () => {
                    this.props.showMessage({
                        message: `Technician Shift was updated successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                    this.props.getTechnicianData('1');
                });
                this.props.setStateFxn({ ...this.props.parentState, Data: {...this.state.Data, Shifts}, })
            }
        });
    }

    deleteShift = () => {
        const { Co, shift, Technician } = this.state;

        const { ID } = shift;

        axios.delete(`${window["apiLocation"]}/api/TechnicianShift?Co=${encodeURIComponent(Co)}&ID=${encodeURIComponent(ID)}`).then((res) => {
            if (res) {
                const { Shifts } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Shifts, { Co, Technician, ID });
                if (index > -1) {
                    Shifts.splice(index, 1);
                }
                this.setState({ 
                    ...this.state,
                    Data: {...this.state.Data, Shifts},
                    shift: newTechnicianState.shift,
                    disableDay: false
                }, () => {
                    this.props.showMessage({
                        message: `Technician Shift was deleted successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                    this.props.getTechnicianData('1');
                });
                this.props.setStateFxn({ ...this.props.parentState, Data: {...this.state.Data, Shifts}, })
            }
        });
    }

    render() {
        const { classes, technicians, onSelected, technicianDialog } = this.props;
        const data = technicianDialog.data;
        const Shifts = this.state.Data.Shifts;
        const avatar = 'assets/images/avatars/profile.jpg';

        window["warn"]("tech shifts state", this.state)

        const daysOfWeek = [
            {
                name: "Sunday",
                value: 0
            },
            {
                name: "Monday",
                value: 1
            },
            {
                name: "Tuesday",
                value: 2
            },
            {
                name: "Wednesday",
                value: 3
            },
            {
                name: "Thursday",
                value: 4
            },
            {
                name: "Friday",
                value: 5
            },
            {
                name: "Saturday",
                value: 6
            },
        ]


        return (
            <>
            <React.Fragment>
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">add</Icon>{`${!this.state.shift.ID ? "Add" : "Update"} Shift`}</Typography>
                <div className="sm:flex">
                    <Autocomplete
                        className={classes.formControl}
                        title="Day"
                        options={daysOfWeek}
                        menuItemComponent={(value) => {
                            return (
                                <MenuItem key={value.value} value={value.value}>
                                    <div className="flex">
                                        {`${value.name}`}
                                    </div>
                                </MenuItem>
                            )
                        }}
                        portal={true}
                        noflip={true}
                        activeOnly={true}
                        value={this.state.shift.Day !== null ? this.state.shift.Day : ''}
                        InputLabelProps={{ shrink: this.state.shift.Day !== null ? true : false }}
                        onSelect={(option) => {
                            const Day = option.value;
                            console.log(Day)
                            this.setState({ ...this.state, shift: { ...this.state.shift, Day }})}
                        }
                        required
                        disabled={this.state.disableDay}
                    />
                    <div className="hidden sm:block min-w-12 pt-20">
                    </div>
                    <TimePicker
                        className={classes.formControl}
                        variant="outlined"
                        inputVariant="outlined"
                        label="Start Time"
                        id="startTime"
                        value={this.state.shift.stDate}
                        onChange={this.handleStartTimeChange}
                        required
                        fullWidth
                    />
                    <div className="hidden sm:block min-w-12 pt-20">
                    </div>
                    <TimePicker
                        className={classes.formControl}
                        variant="outlined"
                        inputVariant="outlined"
                        label="End Time"
                        id="endTime"
                        value={this.state.shift.etDate}
                        onChange={this.handleEndTimeChange}
                        required
                        fullWidth
                    />
                    <div className="hidden sm:block min-w-12 pt-20">
                    </div>
                    {!this.state.shift.Co ?
                        <Button
                            className={classNames(classes.formControl, "w-full sm:w-1/2")}
                            variant="contained"
                            color="primary"
                            onClick={this.postShift}
                        >
                            Add
                        </Button> :
                        <div className="w-full flex">
                            <Button
                                className={classNames(classes.formControl, classes.updateShift, "w-full")}
                                variant="contained"
                                color="primary"
                                onClick={this.updateShift}
                            >
                                Update
                            </Button>
                            <Button
                                className={classNames(classes.formControl, classes.deleteShift)}
                                variant="contained"
                                onClick={this.deleteShift}
                            >
                                <Icon>delete</Icon>
                            </Button>
                        </div>
                    }
                </div>
            </React.Fragment>

            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <div className="sm:flex p-12 sm:p-0">
                        <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex"><Icon color="primary" className="text-32 mr-12">date_range</Icon>
                            <span>Shifts</span>
                        </Typography>
                    </div>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        window["warn"]("shift rowInfo", rowInfo.original)
                                        let shift = _.cloneDeepWith(rowInfo.original)
                                        shift.stDate = moment(shift.StartTime, "HH:mm:ss").toDate();
                                        shift.etDate = moment(shift.EndTime, "HH:mm:ss").toDate();
                                        this.setState({ ...this.state, shift: shift, disableDay: true})
                                    }
                                }
                            }
                        }}
                        data={_.orderBy(Shifts, ["Day", "StartTime"], ["asc", "asc"])}
                        columns={[
                            {
                                Header: "Technician",
                                accessor: "Technician",
                                minWidth: 96,
                                className: "justify-center",
                                Cell: row => {
                                    const { Co, Technician } = row.original;
                                    const value = _.find(technicians, { Co, Technician });
                                    return <React.Fragment>
                                        <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                        {`${value.FirstName} ${value.LastName}`}
                                    </React.Fragment>;
                                }
                            },
                            {
                                Header: "Day",
                                accessor: "Day",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => {
                                   let day = daysOfWeek[row.original.Day];
                                   return day.name;
                                }
                            },
                            {
                                Header: "Start Time",
                                accessor: "StartTime",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => {
                                    return moment(row.original.StartTime, "HH:mm:ss").format("hh:mm A")
                                }
                            },
                            {
                                Header: "End Time",
                                accessor: "EndTime",
                                className: "justify-center",
                                minWidth: 96,
                                Cell: row => {
                                    return moment(row.original.EndTime, "HH:mm:ss").format("hh:mm A")
                                }
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Records found"
                    />
                </div>
            </FuseAnimate>
            </>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
        getTechnicianData:getTechnicianData
    }, dispatch);
}

function mapStateToProps({ techniciansApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
        technicianDialog: techniciansApp.technicians.technicianDialog,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianShiftsList)));
