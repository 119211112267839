import * as Actions from '../actions';

const initialState = "row";

const boardViewReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_BOARD_VIEW:
            {
                return action.view
            }
        default:
            return state;
    }
};

export default boardViewReducer;
