import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import CustomerSitesList from 'main/content/apps/customer-sites/CustomerSitesList';
import CustomerSitesHeader from 'main/content/apps/customer-sites/CustomerSitesHeader';
import CustomerSitesLeftSidebarHeader from 'main/content/apps/customer-sites/CustomerSitesLeftSidebarHeader';
import CustomerSitesRightSidebarHeader from 'main/content/apps/customer-sites/CustomerSitesRightSidebarHeader';
import CustomerSitesSidebarContent from 'main/content/apps/customer-sites/CustomerSitesSidebarContent';
import _ from '@lodash';
import { Button, Icon, Tooltip } from '@material-ui/core';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class CustomerSitesApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.location, prevProps.location)) {
            this.props.getCustomerSites(this.props.match.params);
        }
    }

    render() {
        const { classes, openNewCustomerSiteDialog } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-12",
                                header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                            }}
                            header={
                                <CustomerSitesHeader pageLayout={() => this.pageLayout} />
                            }
                            leftSidebarHeader={
                                <CustomerSitesLeftSidebarHeader />
                            }
                            content={
                                <CustomerSitesList />

                            }
                            leftSidebarContent={
                                <CustomerSitesSidebarContent />
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCustomerSites: Actions.getCustomerSites,
        getUserData: Actions.getUserData,
        openNewCustomerSiteDialog: Actions.openNewCustomerSiteDialog
    }, dispatch);
}

function mapStateToProps({ customerSitesApp }) {
    return {
        customerSites: customerSitesApp.customerSites.entities,
        selectedCustomerSiteIds: customerSitesApp.customerSites.selectedCustomerSiteIds,
        searchText: customerSitesApp.customerSites.searchText,
        user: customerSitesApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSitesApp)));
