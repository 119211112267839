import {combineReducers} from 'redux';
import timecards from './timecards.reducer';
import user from './user.reducer';

const timecardsAppReducers = combineReducers({
    timecards,
    user
});

export default timecardsAppReducers;
