import React, { Component } from 'react';
import {
    withStyles,
    Button,
    Avatar,
    Divider,
    Tooltip,
    Badge,
    Icon
} from '@material-ui/core';
import classNames from 'classnames';
import { FuseScrollbars, FuseAnimateGroup } from '@fuse';
import { Capacitor } from '@capacitor/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import _ from '@lodash';
import getProfileImage from '../functions/getProfileImageUrl';

const sounds = require('./store/sounds/sounds.json');

const platform = Capacitor.getPlatform();

const styles = theme => ({
    root: {
        background: theme.palette.background.default,
        minWidth: 70,
        width: 70,
    },
    contactButton: {
        width: 70,
        minWidth: 70,
        flex: '0 0 auto',
        '&.active:after': {
            position: 'absolute',
            top: 8,
            right: 0,
            bottom: 8,
            content: "''",
            width: 4,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: theme.palette.primary.main
        }
    },
    unreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.error[500],
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    videoBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: 0,
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    status: {
        position: 'absolute',
        width: 12,
        height: 12,
        bottom: 4,
        left: 44,
        border: '2px solid ' + theme.palette.background.default,
        borderRadius: '50%',
        zIndex: 10,

        '&.online': {
            backgroundColor: '#4CAF50'
        },

        // '&.do-not-disturb': {
        //     backgroundColor: '#F44336'
        // },

        '&.idle': {
            backgroundColor: '#FFC107'
        },

        '&.offline': {
            backgroundColor: '#9f9f9f'
        }
    }
});

class ContactList extends Component {

    constructor(props) {
        super(props);
        this.state = { lastUpdate: new Date() }
    }

    handleContactClick = (contactId) => {
        this.props.openChatPanel();
        this.getChat(contactId);
    }

    getChat = _.debounce((contactId) => {
        this.props.getChat(contactId);
        this.scrollToTop();
    }, 350)

    shouldComponentUpdate(nextProps, nextState) {
        const { users: newUsers } = nextProps;
        const { users: oldUsers } = this.props;
        const { lastUpdate } = this.state;

        var currentDate = new Date();
        var differenceInSecondsSinceLastUpdate = Math.floor((currentDate - lastUpdate)) / 1000;
        if (differenceInSecondsSinceLastUpdate < 30) {
            return false;
        }

        const difference = _.differenceWith(newUsers, oldUsers, _.isEqual);

        const shouldUpdate = difference.length !== 0;

        this.setState((prevState) => ({
            ...prevState, lastUpdate: currentDate
        }));

        return shouldUpdate;
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { chats, user } = this.props;
        if (user && chats) {
            const oldIncoming = _.sumBy([...prevProps.chats] || [], (o) => { return _.filter(o.Messages, { To: user.UserName, Received: null }).length });
            const incoming = _.sumBy([...chats], (o) => { return _.filter(o.Messages, { To: user.UserName, Received: null }).length });
            // window["warn"](oldIncoming, incoming);
            if (platform === 'web' && this.pop && oldIncoming < incoming && user.DisableMessageToneYN !== "Y") {
                this.pop.play();
            }
        }
    }

    scrollToTop = () => {
        this.contactListScroll.scrollTop = 0;
    };

    getMinutes = (date) => {
        var now = new Date();
        var dt = new Date(date || null);
        var ms = (now - dt);
        var mins = ms / 60000;
        return mins;
    }

    render() {
        const { Co, classes, chats, users, user, selectedContactId } = this.props;
        const contacts = _.orderBy(_.filter([...users], { ActiveYN: 'Y' }), [(o) => {
            var minutes = this.getMinutes(o.Data.LastLogin);
            return (!o.Data.Status || minutes > 60 ? 0 : o.Data.Status)
        }, 'FirstName', 'LastName'], ['desc', 'asc', 'asc']);
        // window["warn"](contacts);
        const ContactButton = ({ contact, unread }) => {
            const statuses = ["offline", "idle", "online"]
            var minutes = this.getMinutes(contact.Data.LastLogin);
            var status = (!contact.Data.Status || minutes > 60 ? 0 : contact.Data.Status)
            return (
                <Tooltip title={<div>{`${contact.FirstName} ${contact.LastName}`}<br />{contact.Data.LastLogin && new Date(contact.Data.LastLogin).toLocaleString('en-US')}</div>} placement="left">
                    <Button
                        onClick={() => this.handleContactClick(contact.UserName)}
                        className={classNames(classes.contactButton, { 'active': (selectedContactId === contact.UserName) })}
                    >
                        {unread && (
                            <div className={classes.unreadBadge}>{unread}</div>
                        )}
                        {(contact.Data && contact.Data.Video && contact.Data.Video.Data && contact.Data.Video.Data.Chat && (contact.Data.Video.Data.Chat.To === user.UserName || contact.Data.Video.Data.Chat.From === user.UserName)) &&
                            <div className={classes.videoBadge}><Icon style={{ fontSize: 16 }}>videocam</Icon></div>
                        }
                        <div className={classNames(statuses[status], classes.status)} />
                        <Avatar
                            src={(contact.Data && contact.Data.Avatar) && getProfileImage(`Co=${Co}&ID=${contact.Data.Avatar}`)}
                            alt={`${contact.FirstName} ${contact.LastName}`}
                            className="text-16"
                        >
                            {!contact.Data || !contact.Data.Avatar || contact.Data.Avatar === '' ? contact.LastName && contact.FirstName ? `${contact.FirstName[0]}${contact.LastName[0]}` : contact.FirstName[0] : ''}
                        </Avatar>
                    </Button>
                </Tooltip>
            )
        };

        return (
            <FuseScrollbars
                className={classNames(classes.root, "flex flex-no-shrink flex-col overflow-y-auto py-8")
                }
                containerRef={(ref) => {
                    this.contactListScroll = ref
                }}
            >
                {contacts && (
                    <React.Fragment>
                        <audio style={{ display: 'none' }} ref={(ref) => { this.pop = ref; }} src={sounds["chat"]} />
                        <FuseAnimateGroup
                            enter={{
                                animation: "transition.expandIn"
                            }}
                            className="flex flex-col flex-no-shrink"
                        >
                            {(chats) &&
                                chats.map((chat, index) => {
                                    const contact = [...contacts].find((_contact) => _contact.UserName === chat.UserName);
                                    const unread = _.filter([...chat.Messages], { To: user.UserName, Received: null });
                                    return (
                                        contact ? <ContactButton key={index} contact={{ ...contact }} unread={[...unread].length > 0 ? [...unread].length : false} /> : ''
                                    )
                                })}
                            <Divider className="mx-24 my-8" />
                            {contacts.map((contact, index) => {
                                const chatContact = chats.find((_chat) => _chat.UserName === contact.UserName);
                                return (contact.UserName !== user.UserName) && !chatContact ? <ContactButton key={index} contact={{ ...contact }} /> : '';
                            })}
                        </FuseAnimateGroup>
                    </React.Fragment>
                )}
            </FuseScrollbars >
        );
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChat: Actions.getChat,
        openChatPanel: Actions.openChatPanel
    }, dispatch);
}

function mapStateToProps({ chatPanel, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        chats: chatPanel.user.conversations,
        users: spReducers.userProfiles.Users,
        selectedContactId: chatPanel.contacts.selectedContactId,
        user: spReducers.userProfiles.User
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactList));

