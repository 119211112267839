import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedServiceUnitTypeIds: [],
    routeParams: {},
    partTypeDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            Co: null,
            UnitType: null,
            UnitCat: null,
            PartType: null,
            Name: null,
            Description: null,
            ActiveYN: 'Y',
            Notes: null,
            AddedBy: null,
            AddedDate: null,
            Data: {
                ErrMsg: null
            }
        }
    },
    serviceUnitTypeDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: {
            Co: null,
            UnitType: null,
            UnitCat: null,
            Prefix: null,
            Name: null,
            Description: null,
            Notes: '',
            Data: {
                ErrMsg: null,
                Units: [],
                TrainingImages: [],
                PartTypes: [],
            },
            value: 0,
            images: [],
            deleteImages: [],
            searchResults: [],
            selectedSearchImages: [],
            selectedAttachmentImages: [],
            selectedSearchImage: null,
            selectedAttachmentImage: null,
            selectedTrainingImage: null,
            imageSearch: null,
            displayLimit: 60,
            anchorEl: null,
            searchAttachments: false,
            searchImages: false,
        }
    }
};

const serviceUnitTypesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_SERVICE_UNIT_TYPES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_SERVICE_UNIT_TYPES:
            {

                const serviceUnitTypeId = action.serviceUnitTypeId;

                let selectedServiceUnitTypeIds = [...state.selectedServiceUnitTypeIds];

                if (selectedServiceUnitTypeIds.find(id => id === serviceUnitTypeId) !== undefined) {
                    selectedServiceUnitTypeIds = selectedServiceUnitTypeIds.filter(id => id !== serviceUnitTypeId);
                }
                else {
                    selectedServiceUnitTypeIds = [...selectedServiceUnitTypeIds, serviceUnitTypeId];
                }

                return {
                    ...state,
                    selectedServiceUnitTypeIds: selectedServiceUnitTypeIds
                };
            }
        case Actions.SELECT_ALL_SERVICE_UNIT_TYPES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedServiceUnitTypeIds = arr.map(serviceUnitTypes => serviceUnitTypes.id);

                return {
                    ...state,
                    selectedServiceUnitTypeIds: selectedServiceUnitTypeIds
                };
            }
        case Actions.DESELECT_ALL_SERVICE_UNIT_TYPES:
            {
                return {
                    ...state,
                    selectedServiceUnitTypeIds: []
                };
            }
        case Actions.OPEN_NEW_SERVICE_UNIT_TYPE_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitTypeDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null,
                    }
                };
            }
        case Actions.CLOSE_NEW_SERVICE_UNIT_TYPE_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitTypeDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_SERVICE_UNIT_TYPE_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitTypeDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_SERVICE_UNIT_TYPE_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitTypeDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_SERVICE_UNIT_TYPE_DIALOG:
            {
                return {
                    ...state,
                    serviceUnitTypeDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_NEW_PART_TYPE_DIALOG:
            {
                return {
                    ...state,
                    partTypeDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null,
                    }
                };
            }
        case Actions.CLOSE_NEW_PART_TYPE_DIALOG:
            {
                return {
                    ...state,
                    partTypeDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_PART_TYPE_DIALOG:
            {
                return {
                    ...state,
                    partTypeDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_PART_TYPE_DIALOG:
            {
                return {
                    ...state,
                    partTypeDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        default:
            {
                return state;
            }
    }
};

export default serviceUnitTypesReducer;
