import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Avatar, Button, Icon, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { showMessage } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import getProfileImage from '../../../functions/getProfileImageUrl';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    formControl: {
        marginBottom: 24
    },
    updateSkill: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteSkill: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});

const newTechnicianState = {
    ID: null,
    avatar: 'assets/images/avatars/profile.jpg',
    Co: null,
    Technician: null,
    Employee: null,
    FirstName: null,
    LastName: null,
    MiddleInitial: null,
    TeamCategory: null,
    TeamRole: null,
    Phone: null,
    Email: null,
    Bio: null,
    ExemptYN: 'N',
    Notes: null,
    ActiveYN: 'Y',
    DefaultBusinessUnit: null,
    DefaultDivision: null,
    DefaultDepartment: null,
    value: 1,
    histTab: 0,
    Data: {
        WorkOrders: [],
        Trips: [],
        Timecard: null,
    },
    showTabs: false,
    skill: {
        Co: null,
        Technician: null,
        Service: null,
        Rating: null,
    },
    disableService: false
};

class TechnicianSkillsList extends Component {

    state = { ..._.cloneDeepWith(newTechnicianState) };

    componentDidMount() {
        // let data = this.props.technicianDialog.data;

        let data = this.props.parentState;

        window["warn"]("skills componentDidMount", data)

        this.setState({ ..._.cloneDeepWith(newTechnicianState), ...data })
    }

    componentDidUpdate(prevProps, prevState) {
        let data = this.props.technicianDialog.data;

        window["warn"]("skills componentDidUpdate", this.props.technicianDialog)
        //window["warn"]("skills prevProps", prevProps.technicianDialog)

        if (!_.isEqual(this.props.technicianDialog, prevProps.technicianDialog)) { //|| !_.isEqual(loading, prevProps.loading)
            this.setState({ ...this.state, data });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    //`${window["apiLocation"]}/api/Skillset?Co=${encodeURIComponent(this.state.Co)}&Technician=${encodeURIComponent(this.state.Technician)}&User=${encodeURIComponent(this.props.user)}`

    postSkill = () => {
        const { Co, skill, Technician } = this.state;

        const { Service } = skill;

        let body = {
            Co: Co,
            Technician: Technician,
            Service: skill.Service,
            Rating: skill.Rating
        }

        axios.post(`${window["apiLocation"]}/api/Skillset`, body).then((res) => {
            if (res) {
                const { Skills } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Skills, { Co, Technician, Service });
                if (index > -1) {
                    Skills.splice(index, 1, res.data);
                } else {
                    Skills.push(res.data);
                }
                this.setState({
                    ...this.state,
                    Data: { ...this.state.Data, Skills },
                    skill: newTechnicianState.skill
                }, () => {
                    this.props.showMessage({
                        message: `Technician Skill was added successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
                this.props.setStateFxn({ ...this.props.parentState, Data: { ...this.state.Data, Skills }, })
            }
        });
    }

    updateSkill = () => {
        const { Co, skill, Technician } = this.state;

        const { Service } = skill;

        let body = {
            Co: Co,
            Technician: Technician,
            Service: skill.Service,
            Rating: skill.Rating
        }

        axios.put(`${window["apiLocation"]}/api/Skillset?Co=${encodeURIComponent(Co)}&Technician=${encodeURIComponent(Technician)}&Service=${encodeURIComponent(Service)}`, body).then((res) => {
            if (res) {
                const { Skills } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Skills, { Co, Technician, Service });
                if (index > -1) {
                    Skills.splice(index, 1, res.data);
                } else {
                    Skills.push(res.data);
                }
                this.setState({
                    ...this.state,
                    Data: { ...this.state.Data, Skills },
                    skill: newTechnicianState.skill,
                    disableService: false
                }, () => {
                    this.props.showMessage({
                        message: `Technician Skill was updated successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
                this.props.setStateFxn({ ...this.props.parentState, Data: { ...this.state.Data, Skills }, })
            }
        });
    }

    deleteSkill = () => {
        const { Co, skill, Technician } = this.state;

        const { Service } = skill;

        axios.delete(`${window["apiLocation"]}/api/Skillset?Co=${encodeURIComponent(Co)}&Technician=${encodeURIComponent(Technician)}&Service=${encodeURIComponent(Service)}`).then((res) => {
            if (res) {
                const { Skills } = _.cloneDeepWith(this.state.Data);
                const index = _.findIndex(Skills, { Co, Technician, Service });
                if (index > -1) {
                    Skills.splice(index, 1);
                }
                this.setState({
                    ...this.state,
                    Data: { ...this.state.Data, Skills },
                    skill: newTechnicianState.skill,
                    disableService: false
                }, () => {
                    this.props.showMessage({
                        message: `Technician Skill was deleted successfully.`,
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    });
                });
                this.props.setStateFxn({ ...this.props.parentState, Data: { ...this.state.Data, Skills }, })
            }
        });
    }

    render() {
        const { classes, technicians, onSelected, technicianDialog, services } = this.props;
        const data = technicianDialog.data;
        const Skills = this.state.Data.Skills;
        const avatar = 'assets/images/avatars/profile.jpg';

        window["warn"]("tech skills state", this.state)


        return (
            <>
                <React.Fragment>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">add</Icon>{`${!this.state.skill.Service ? "Add" : "Update"} Skill`}</Typography>
                    <div className="sm:flex">
                        <Autocomplete
                            className={classes.formControl}
                            title="Service"
                            options={services}
                            menuItemComponent={(value) => {
                                return (
                                    <MenuItem key={value.Service} value={value.Service}>
                                        <div className="flex">
                                            {`${value.Service} - ${value.Description}`}
                                        </div>
                                    </MenuItem>
                                )
                            }}
                            portal={true}
                            value={this.state.skill.Service || ''}
                            onSelect={(option) => this.setState({ ...this.state, skill: { ...this.state.skill, Service: option.Service } })}
                            required
                            fullWidth
                            disabled={this.state.disableService}
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        <TextField
                            className={classNames(classes.formControl, "sm:w-1/2")}
                            label="Rating"
                            id="rating"
                            name="skill.Rating"
                            value={this.state.skill.Rating || ''}
                            onChange={this.handleChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">/10</InputAdornment>,
                                type: "number",
                                inputProps: { min: 0, max: 10 }
                            }}
                            variant="outlined"
                            required
                            fullWidth
                        />
                        <div className="hidden sm:block min-w-12 pt-20">
                        </div>
                        {!this.state.skill.Co ?
                            <Button
                                className={classNames(classes.formControl, "w-full sm:w-1/2")}
                                variant="contained"
                                color="primary"
                                onClick={this.postSkill}
                            >
                                Add
                            </Button> :
                            <div className="w-full flex">
                                <Button
                                    className={classNames(classes.formControl, classes.updateSkill, "w-full")}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.updateSkill}
                                >
                                    Update
                                </Button>
                                <Button
                                    className={classNames(classes.formControl, classes.deleteSkill)}
                                    variant="contained"
                                    onClick={this.deleteSkill}
                                >
                                    <Icon>delete</Icon>
                                </Button>
                            </div>
                        }
                    </div>
                </React.Fragment>

                <FuseAnimate animation="transition.slideUpIn" delay={300}>
                    <div>
                        <div className="sm:flex p-12 sm:p-0">
                            <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex"><Icon color="primary" className="text-32 mr-12">build</Icon>
                                <span>Skills</span>
                            </Typography>
                        </div>
                        <ReactTableFixedColumns
                            className={classNames(classes.root, "-striped -highlight border-0")}
                            getTrProps={(state, rowInfo, column) => {
                                return {
                                    className: "cursor-pointer",
                                    onClick: (e, handleOriginal) => {
                                        if (rowInfo) {
                                            window["warn"]("skill rowInfo", rowInfo.original)
                                            this.setState({ ...this.state, skill: _.cloneDeepWith(rowInfo.original), disableService: true })
                                        }
                                    }
                                }
                            }}
                            data={Skills} //_.orderBy(data, ["Mth"], ["desc"])
                            columns={[
                                {
                                    Header: "Technician",
                                    accessor: "Technician",
                                    minWidth: 96,
                                    className: "justify-center",
                                    Cell: row => {
                                        const { Co, Technician } = row.original;
                                        const value = _.find(technicians, { Co, Technician });
                                        return <React.Fragment>
                                            <Avatar style={{ marginRight: 8, marginLeft: 8 }} classes={{ root: classes.avatarRoot }} className={classes.avatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${value.Co}&ID=${value.Data.Avatar}&Thumb=true`) : avatar)} />
                                            {`${value.FirstName} ${value.LastName}`}
                                        </React.Fragment>;
                                    }
                                },
                                {
                                    Header: "Service",
                                    accessor: "Service",
                                    className: "justify-center",
                                    minWidth: 96,
                                    Cell: row => {
                                        const svc = _.find(services, { Service: row.original.Service });
                                        if (svc) {
                                            return `${svc.Service} - ${svc.Description}`;
                                        } else {
                                            return row.original.Service;
                                        }
                                    }
                                },
                                {
                                    Header: "Rating",
                                    accessor: "Rating",
                                    className: "justify-center",
                                    minWidth: 96,
                                    Cell: row => `${row.original.Rating}/10`
                                },
                            ]}
                            defaultPageSize={10}
                            noDataText="No Records found"
                        />
                    </div>
                </FuseAnimate>
            </>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
    }, dispatch);
}

function mapStateToProps({ techniciansApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
        technicianDialog: techniciansApp.technicians.technicianDialog,
        services: spReducers.services
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianSkillsList)));
