import * as Actions from 'store/actions/spConnection';

const initialState = {
    Statuses: [],
    Agents: [],
    Agent: {
        Status: 0,
        Data: {
            Station: {},
        }
    },
}
const agentStatus = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_AGENT_STATUS_DATA:
            {
                return {
                    ...state,
                    Agent: action.payload,
                };
            }
        case Actions.SET_AGENT_STATUS_DATA:
            {
                return {
                    ...state,
                    Agent: action.payload,
                };
            }
        case Actions.GET_AGENT_STATUSES_DATA:
            {
                return {
                    ...state,
                    Agents: action.payload,
                };
            }
        case Actions.SET_AGENT_STATUSES_DATA:
            {
                return {
                    ...state,
                    Agents: action.payload,
                };
            }
        case Actions.GET_AGENT_STATUS_LIST_DATA:
            {
                return {
                    ...state,
                    Statuses: action.payload
                };
            }
        case Actions.SET_AGENT_STATUS_LIST_DATA:
            {
                return {
                    ...state,
                    Statuses: action.payload
                };
            }
    }

    return state;
};

export default agentStatus;