import _ from '@lodash';
import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Icon, IconButton, MenuItem, Switch, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const initialState = {
    Co: null,
    UnitType: null,
    UnitCat: null,
    PartType: null,
    Name: null,
    Description: null,
    ActiveYN: 'Y',
    Notes: null,
    AddedBy: null,
    AddedDate: null,
    Data: {
        ErrMsg: null
    }
};

class PartTypeDialog extends Component {
    state = { ...initialState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        initialState.Co = this.props.Co;
        if (!prevProps.partTypeDialog.props.open && this.props.partTypeDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.partTypeDialog.type === 'edit' &&
                this.props.partTypeDialog.data &&
                !_.isEqual(this.props.partTypeDialog.data, prevState)) {
                this.setState({ ...this.props.partTypeDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.partTypeDialog.type === 'new' &&
                !_.isEqual(initialState, prevState)) {
                const { unitType } = this.props;
                const { Co, UnitCat, UnitType } = unitType;
                this.setState({ ...initialState, Co, UnitCat, UnitType });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.partTypeDialog.type === 'edit' ? this.props.closeEditPartTypeDialog() : this.props.closeNewPartTypeDialog();
    };

    canBeSubmitted() {
        const { PartType, Name, Description } = this.state;
        return (
            Name && Name.length > 0 && Description && Description.length > 0
        );
    }

    render() {
        const { classes, partTypeDialog, addPartType, updatePartType, serviceUnitCategories, serviceUnitTypes } = this.props;

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...partTypeDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {partTypeDialog.type === 'new' ? 'New Part Type' : 'Edit Part Type'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Unit Category"
                                    select
                                    id="untiCat"
                                    name="UnitCat"
                                    value={this.state.UnitCat}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.UnitCat ? true : false }}
                                    required
                                    fullWidth
                                >
                                    {serviceUnitCategories && serviceUnitCategories.map((value, index) => (
                                        <MenuItem key={index} value={value.UnitCat}>
                                            <div className="flex">
                                                {`${value.Name}`}
                                            </div>
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                            </div>
                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Unit Type"
                                    select
                                    id="untiType"
                                    name="UnitType"
                                    value={this.state.UnitType}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: this.state.UnitType ? true : false }}
                                    required
                                    fullWidth
                                >
                                    {(serviceUnitCategories && this.state.UnitCat) ?

                                        serviceUnitTypes && serviceUnitTypes.map((value, index) => value.UnitCat === this.state.UnitCat &&
                                            (
                                                <MenuItem key={index} value={value.UnitType}>
                                                    <div className="flex">
                                                        {`${value.Name}`}
                                                    </div>
                                                </MenuItem>
                                            )) :
                                        <MenuItem value={null}>
                                            Please Select a Category
                                        </MenuItem>
                                    }
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Part Type"
                                    id="name"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {partTypeDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addPartType(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                        </Button>
                            </DialogActions>
                        ) : (
                                <DialogActions className="dialog-actions justify-between pl-16">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            updatePartType(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                        </Button>
                                </DialogActions>
                            )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditPartTypeDialog: Actions.closeEditPartTypeDialog,
        closeNewPartTypeDialog: Actions.closeNewPartTypeDialog,
        addPartType: Actions.addPartType,
        updatePartType: Actions.updatePartType,
    }, dispatch);
}

function mapStateToProps({ serviceUnitTypesApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        partTypeDialog: serviceUnitTypesApp.serviceUnitTypes.partTypeDialog,
        serviceUnitCategories: spReducers.serviceUnitCategories,
        serviceUnitTypes: spReducers.serviceUnitTypes
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PartTypeDialog));
