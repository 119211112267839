import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, MenuItem, InputAdornment, FormControlLabel, Switch
} from '@material-ui/core';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newPayTermState = {
    Co: null,
    PayTerms: null,
    Description: null,
    DueType: 0,
    DueDays: null,
    DiscountType: 0,
    DiscountDays: null,
    DiscountRate: 0.00,
    DiscountAllYN: 'Y',
    ActiveYN: 'Y',
    Notes: '',
    QBOCompany: null,
    QBOTerms: null,
    Data: {

    }
};

class PayTermDialog extends Component {
    state = { ...newPayTermState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newPayTermState.Co = this.props.Co;
        if (!prevProps.payTermDialog.props.open && this.props.payTermDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.payTermDialog.type === 'edit' &&
                this.props.payTermDialog.data &&
                !_.isEqual({...newPayTermState, ...this.props.payTermDialog.data}, prevState)) {
                this.setState({ ...newPayTermState, ...this.props.payTermDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.payTermDialog.type === 'new' &&
                !_.isEqual(newPayTermState, prevState)) {
                this.setState({ ...newPayTermState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.payTermDialog.type === 'edit' ? this.props.closeEditPayTermDialog() : this.props.closeNewPayTermDialog();
    };

    canBeSubmitted() {
        const { PayTerms, Description } = this.state;
        return (
            PayTerms && PayTerms.length > 0 && Description && Description.length > 0
        );
    }

    render() {
        const { classes, payTermDialog, addPayTerm, updatePayTerm, removePayTerm, businessUnits, qboCompany, qboTerms } = this.props;
        const dueTypes = ['On Receipt', 'Days From', 'On Day'];
        const discountTypes = ['No Discount', 'Until Days From', 'Always'];

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...payTermDialog.props}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {payTermDialog.type === 'new' ? 'New Pay Terms' : 'Edit Pay Terms'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Pay Terms"
                                    id="payTerm"
                                    name="PayTerms"
                                    value={this.state.PayTerms}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(payTermDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="DueType"
                                    id="dueType"
                                    name="DueType"
                                    value={this.state.DueType}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    required
                                >
                                    {dueTypes.map((value, index) => (
                                        <MenuItem value={index}>
                                            {`${index} - ${value.substr(0, 30)}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Due Days"
                                    id="dueDays"
                                    name="DueDays"
                                    value={this.state.DueDays}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    required
                                    disabled={this.state.DueType === 0}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="DiscountType"
                                    id="discountType"
                                    name="DiscountType"
                                    value={this.state.DiscountType}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    required
                                >
                                    {discountTypes.map((value, index) => (
                                        <MenuItem value={index}>
                                            {`${index} - ${value.substr(0, 30)}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Discount Days"
                                    id="discountDays"
                                    name="DiscountDays"
                                    value={this.state.DiscountDays}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    disabled={this.state.DiscountType === 0}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Discount Rate"
                                    id="discountRate"
                                    name="DiscountRate"
                                    value={this.state.DiscountRate}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    required
                                    disabled={this.state.DiscountType === 0}
                                />
                            </div>
                            {qboCompany &&
                                <div className="flex">
                                    <Autocomplete
                                        className={classes.formControl}
                                        title="QBO Terms"
                                        options={qboTerms}
                                        menuItemComponent={(value) =>
                                            <MenuItem value={value.Id}>
                                                {`${value.Name}`}
                                            </MenuItem>
                                        }
                                        portal={true}
                                        value={this.state.QBOTerms || ''}
                                        onSelect={(option) => { if (payTermDialog.type !== 'new') { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOTerms: option.Id }) } else { this.setState({ ...this.state, QBOCompany: qboCompany.Co, QBOTerms: option.Id }) } }}
                                    // required
                                    />
                                </div>
                            }

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {payTermDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        addPayTerm(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                        </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions className="dialog-actions justify-between pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        updatePayTerm(this.state);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Save
                        </Button>
                            </DialogActions>
                        )}
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditPayTermDialog: Actions.closeEditPayTermDialog,
        closeNewPayTermDialog: Actions.closeNewPayTermDialog,
        addPayTerm: Actions.addPayTerm,
        updatePayTerm: Actions.updatePayTerm,
        removePayTerm: Actions.removePayTerm
    }, dispatch);
}

function mapStateToProps({ payTermsApp, spReducers }) {
    return {
        payTermDialog: payTermsApp.payTerms.payTermDialog,
        businessUnits: spReducers.businessUnits,
        Co: spReducers.companies.Co,
        qboCompany: spReducers.qboSettings.qboCompany,
        qboTerms: spReducers.qboSettings.payTerms,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PayTermDialog));
