import { FuseAnimate, FuseAnimateGroup, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Capacitor } from '@capacitor/core';
import { Avatar, Badge, Checkbox, Divider, Drawer, Icon, IconButton, InputAdornment, List, ListItem, ListItemText, ListSubheader, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openEditEventDialog } from '../content/apps/calendar/store/actions';
import { deleteBoard, openCardDialog } from '../content/apps/scrumboard/store/actions';
import { openEditTodoDialog } from '../content/apps/todo/store/actions';
import { openEditWorkOrderDialog } from '../content/apps/work-orders/store/actions';
import { openEditEstimateDialog } from '../content/apps/estimates/store/actions';
import { openEditInvoiceDialog } from '../content/apps/invoices/store/actions';
import { openEditGpsInventoryTransferDialog } from '../content/apps/gps-inventory-transfers/store/actions';
import { setRecording } from '../content/apps/voice-mailboxes/store/actions';
import { getChat, openChatPanel } from '../chatPanel/store/actions';
import * as Actions from './store/actions/index';
import { setNotificationData, clearLocalNotifications, clearPushNotifications } from 'store/actions';
import axios from 'axios';

const sounds = require('./store/sounds/sounds.json');

const styles = theme => ({
    root: {
        width: 365
    },
    avatar: {
        backgroundColor: theme.palette.error[500],
        height: 18,
        minWidth: 18,
        fontSize: 13,
        borderRadius: 9,
        padding: 4,
        marginRight: 8,
        width: 'auto',
    },
    badge: {
        top: 4,
        right: 8,
        fontSize: 10,
        padding: 4,
        height: 16,
        minWidth: 16,
    },
    primaryBG: {
        backgroundColor: theme.palette.primary.main
    }
});

class QuickPanel extends Component {
    state = {
        toDoSearchText: null,
        open: {
            notifications: _.filter(this.props.notifications, (o) => !o.DateReceived).length > 0,
        },
        select: false,
        selected: [],
        deleted: []
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { notifications } = this.props;
        if (notifications) {
            if (!_.isEqual(notifications, prevProps.notifications)) {
                const hasNew = _.filter(notifications, (o) => !o.DateReceived && _.findIndex(prevProps.notifications, { ID: o.ID }) < 0).length > 0;
                const platform = Capacitor.getPlatform();
                if (platform === 'web' && this.pop && hasNew) {
                    this.pop.play();
                }
            }
        }
    }

    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    };

    componentDidMount() {
        // this.props.getQuickPanelData();
    }

    removeNotification = (notification) => {
        const { ID } = notification;
        const request = axios.delete(`${window["apiLocation"]}/api/Notification?ID=${ID}`);
        request.then(() => {
            const { notifications } = this.props;
            const index = _.findIndex(notifications, { ID });
            if (index > -1) {
                notifications.splice(index, 1)
                this.props.setNotificationData([...notifications]);
            }
        }).catch(() => {
            const { notifications } = this.props;
            const index = _.findIndex(notifications, { ID });
            if (index > -1) {
                notifications.splice(index, 1)
                this.props.setNotificationData([...notifications]);
            }
        })
    }

    removeNotifications = () => {
        const { selected } = this.state;
        window["warn"]('Selected: ', selected);
        const request = axios.put(`${window["apiLocation"]}/api/Notification?Action=D`, selected);
        request.then(() => {
            const notifications = _.filter(_.cloneDeepWith(this.props.notifications), (o) => _.findIndex(selected, (i) => o.ID === i) < 0);
            this.setState({ selected: [], select: Boolean(notifications.length > 0), deleted: selected }, () => this.props.setNotificationData([...notifications]));
        }).catch(() => {
            const notifications = _.filter(_.cloneDeepWith(this.props.notifications), (o) => _.findIndex(selected, (i) => o.ID === i) < 0);
            this.setState({ selected: [], select: Boolean(notifications.length > 0), deleted: selected }, () => this.props.setNotificationData([...notifications]));
        })
    }

    removeAllNotifications = () => {
        const { notifications } = this.props;
        const selected = [];
        notifications.map((not) => {
            selected.push(not.ID);
        })
        window["warn"]('Selected: ', selected);
        const request = axios.put(`${window["apiLocation"]}/api/Notification?Action=D`, selected);
        request.then(() => {
            const notifications = _.filter(_.cloneDeepWith(this.props.notifications), (o) => _.findIndex(selected, (i) => o.ID === i) < 0);
            this.setState({ selected: [], select: Boolean(notifications.length > 0), deleted: selected }, () => this.props.setNotificationData([...notifications]));
            this.props.clearLocalNotifications();
            this.props.clearPushNotifications();
        }).catch(() => {
            const notifications = _.filter(_.cloneDeepWith(this.props.notifications), (o) => _.findIndex(selected, (i) => o.ID === i) < 0);
            this.setState({ selected: [], select: Boolean(notifications.length > 0), deleted: selected }, () => this.props.setNotificationData([...notifications]));
        })
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (!searchText || searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    searchToDos = (toDoSearchText) => {
        this.setState({ toDoSearchText });
    }

    render() {
        const { classes, state, toggleQuickPanel, notifications } = this.props;
        const { open, toDoSearchText, anchorEl, select, selected, deleted } = this.state;
        const toDos = _.orderBy(_.filter(this.props.toDos, (o) => o.CompletedYN !== "Y" && moment.utc(o.StartDate || o.StartBy).local().startOf('day').toDate() <= moment().toDate()), ["DueDate", "DueBy"], ["asc", "asc"]);
        const events = _.orderBy(_.filter(this.props.events, (o) => moment.utc(o.StartDate).local().startOf('day').toDate() <= moment().local().toDate() && moment.utc(o.EndDate).local().toDate() >= moment().toDate()), ["StartDate", "EndDate"], ["asc", "asc"]);
        const filteredToDos = this.getFilteredArray(toDos, toDoSearchText);
        return (
            <React.Fragment>
                <audio style={{ display: 'none' }} ref={(ref) => { this.pop = ref; }} src={sounds["alert"]} />
                <Drawer
                    classes={{ paper: classNames(classes.root, "full-height-drawer") }}
                    open={state}
                    anchor="right"
                    onClose={() => toggleQuickPanel(false)}
                >
                    {/* <ListSubheader classes={{root:"h-64 pt-20"}} component="div"><Icon className="mr-4" style={{ verticalAlign: 'middle' }}>notifications</Icon>Notifications</ListSubheader>
                    <Divider /> */}

                    <div className="mb-0 p-16 pt-4 h-64 bg-white relative panel-header">
                        <Typography className="text-16 font-bold">
                            {moment().format('dddd')}
                        </Typography>
                        <div className="flex">
                            <Typography className="leading-none text-24 font-light" color="textSecondary">{moment().format('MMM')}&nbsp;</Typography>
                            <Typography className="leading-none text-24 font-light" color="textSecondary">{moment().format('DD')},</Typography>
                            <Typography className="leading-none text-24 font-light" color="textSecondary">&nbsp;{moment().format('YYYY')}</Typography>
                        </div>
                        <IconButton className="pin-r absolute pin-t mt-8" onClick={() => toggleQuickPanel(false)} color="inherit">
                            <Icon color="action">close</Icon>
                        </IconButton>

                    </div>
                    <Divider />
                    <List classes={{ root: "py-0" }}>
                        <ListSubheader onClick={() => this.setState({ open: { ...open, notifications: anchorEl ? open.notifications : !open.notifications } })} component="div" className={classNames("bg-white cursor-pointer", notifications.length > 0 && "text-black flex justify-between")}>
                            <div className="">
                                <Badge color="error" badgeContent={_.filter(notifications, (o) => !o.DateReceived).length} classes={{ badge: classes.badge }}>
                                    <Icon color={notifications.length > 0 ? "primary" : "action"} className="mr-4 align-middle mb-4">notifications</Icon>
                                </Badge>
                                Notifications
                            </div>
                            {notifications.length > 0 &&
                                <div className="flex justify-end">
                                    <Icon className="mt-14 ml-8" onClick={(e) => { e.stopPropagation(); this.setState({ anchorEl: e.target, open: { ...open, notifications: true } }); }}>more_vert</Icon>
                                    <Icon className="mt-14 ml-8">{open.notifications ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => this.setState({ anchorEl: null, open: { ...open, notifications: true } })}>
                                        {select &&
                                            <MenuItem onClick={(e) => { e.stopPropagation(); this.setState({ select: false, anchorEl: null }); }}><Icon className="mr-6" color="action">cancel</Icon>Cancel</MenuItem>
                                        }
                                        {!select &&
                                            <MenuItem onClick={(e) => { e.stopPropagation(); this.setState({ select: true, anchorEl: null }); }}><Icon className="mr-6" color="action">check_box</Icon>Select Multi</MenuItem>
                                        }
                                        <MenuItem onClick={(e) => { e.stopPropagation(); this.setState({ anchorEl: null, open: { ...open, notifications: false } }, this.removeAllNotifications); }}><Icon className="mr-6" color="error">delete_sweep</Icon>Clear All</MenuItem>
                                    </Menu>
                                </div>
                            }
                        </ListSubheader>
                        {select && open.notifications &&
                            <div className="w-full flex justify-between">
                                <div className="flex justify-start">
                                    <Checkbox
                                        className="p-0 ml-16 mb-8"
                                        color="secondary"
                                        checked={selected.length === notifications.length}
                                        onChange={(e) => {
                                            if (selected.length !== notifications.length) {
                                                const selected = [];
                                                notifications.map((not) => {
                                                    selected.push(not.ID);
                                                })
                                                this.setState({ selected });
                                            } else {
                                                this.setState({ selected: [] });
                                            }
                                        }}
                                    />
                                    <Typography className="font-bold text-12 mt-4 ml-4">{selected.length} Selected</Typography>
                                </div>
                                <div className="flex justify-end mr-16">
                                    <Icon className="cursor-pointer" color="error" onClick={this.removeNotifications}>delete_sweep</Icon>
                                    <Icon className="ml-8 cursor-pointer" color="action" onClick={(e) => { e.stopPropagation(); this.setState({ selected: [], select: false }); }}>cancel</Icon>
                                </div>
                            </div>
                        }
                        {open.notifications &&
                            <FuseAnimateGroup enter={{ animation: "transition.slideUpIn" }} leave={{ animation: deleted.length < 10 ? "transition.slideUpOut" : undefined }}>
                                {/* <div className="w-full"> */}
                                {_.orderBy(notifications, ["DateSent"], ["desc"]).map(notification => (
                                    <ListItem
                                        className="pl-44 pr-4 py-0 cursor-pointer highlight flex hoverable"
                                        key={notification.ID}>
                                        <ListItemText
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (select) {
                                                    const index = _.findIndex(selected, (o) => o === notification.ID);
                                                    if (index < 0) {
                                                        selected.push(notification.ID);
                                                    } else {
                                                        selected.splice(index, 1);
                                                    }
                                                    this.setState({ selected })
                                                } else {
                                                    const { ID, Type, Data, DateReceived } = notification;
                                                    switch (Type) {
                                                        case 'Trip': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                const { Co, WorkOrder, Trip } = data;
                                                                this.props.openEditWorkOrderDialog({ Co, WorkOrder }, Trip);
                                                            }
                                                        }
                                                        case 'InventoryRequest': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                const { ID } = data;
                                                                this.props.openEditGpsInventoryTransferDialog({ ID });
                                                            }
                                                        }
                                                        case 'Email': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                const { Co, WorkOrder, BusinessUnit, Division, Estimate, Invoice } = data;
                                                                if (WorkOrder) {
                                                                    this.props.openEditWorkOrderDialog({ Co, WorkOrder });
                                                                }
                                                                if (BusinessUnit && Division && Estimate) {
                                                                    this.props.openEditEstimateDialog({ Co, BusinessUnit, Division, Estimate });
                                                                }
                                                                if (Invoice) {
                                                                    this.props.openEditInvoiceDialog({ Co, Invoice });
                                                                }
                                                            }
                                                        }
                                                            break;
                                                        case 'ToDoItem': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                const event = _.find(this.props.toDos, { Type: 'T', ID: data.ID });
                                                                if (event) {
                                                                    this.props.openEditTodoDialog(event);
                                                                }
                                                            }
                                                        }
                                                            break;
                                                        case 'ChatMessage': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                if (data.UserName) {
                                                                    this.props.openChatPanel();
                                                                    this.props.getChat(data.UserName, data.ID);
                                                                }
                                                            }
                                                        }
                                                            break;
                                                        case 'ProjectTask': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                // const event = _.find(this.props.toDos, { Type: 'P', ID: data.ID });
                                                                // if (event) {
                                                                this.props.openCardDialog({ Co: notification.Co, ...data }, true);
                                                                // }
                                                            }
                                                        }
                                                            break;
                                                        case 'CalendarEvent': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                const event = _.find(this.props.events, { ID: data.ID });
                                                                if (event) {
                                                                    const { StartDate, EndDate, Title, Description, AllDayYN, AddedBy, PublicYN } = event;
                                                                    this.props.openEditEventDialog({
                                                                        id: event.ID,
                                                                        start: moment.utc(StartDate).local().toDate(),
                                                                        end: moment.utc(EndDate).local().toDate(),
                                                                        title: Title,
                                                                        desc: Description,
                                                                        allDay: AllDayYN,
                                                                        publicYN: PublicYN,
                                                                        AddedBy,
                                                                        Data: {
                                                                            ...event.Data,
                                                                            type: "E",
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        }
                                                            break;
                                                        case 'Voicemail': {
                                                            if (Data) {
                                                                const data = JSON.parse(Data);
                                                                const recording = _.find(this.props.voicemail, { CallSid: data.CallSid });
                                                                if (recording) {
                                                                    this.props.setRecording(recording);
                                                                } else {
                                                                    this.props.setRecording(data.CallSid, true);
                                                                }
                                                            }
                                                        }
                                                            break;
                                                    }
                                                    if (!DateReceived) {
                                                        const request = axios.put(`${window["apiLocation"]}/api/Notification?ID=${ID}`);
                                                        request.then(() => {
                                                            const { notifications } = this.props;
                                                            const rec = _.find(notifications, { ID });
                                                            rec.DateReceived = new Date();
                                                            this.props.setNotificationData([...notifications]);
                                                        }).catch(() => {
                                                            const { notifications } = this.props;
                                                            const rec = _.find(notifications, { ID });
                                                            rec.DateReceived = new Date();
                                                            this.props.setNotificationData([...notifications]);
                                                        })
                                                    }
                                                }
                                            }}
                                            classes={{ root: "my-0 py-6 bt-dotted", primary: `flex ${!notification.DateReceived && "font-bold"}`, secondary: "text-11 truncate" }}
                                            primary={
                                                <React.Fragment>
                                                    {select ?
                                                        <Checkbox
                                                            className="p-0 mr-6"
                                                            style={{
                                                                marginLeft: -28
                                                            }}
                                                            checked={_.findIndex(selected, (o) => o === notification.ID) > -1}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onChange={(e) => {
                                                                const index = _.findIndex(selected, (o) => o === notification.ID);
                                                                if (index < 0) {
                                                                    selected.push(notification.ID);
                                                                } else {
                                                                    selected.splice(index, 1);
                                                                }
                                                                this.setState({ selected })
                                                            }}
                                                        /> :
                                                        !notification.DateReceived && <div style={{ marginLeft: -22 }} className={classNames("min-h-12 min-w-12 max-h-12 rounded-full mr-10 mt-6", classes.primaryBG)}></div>
                                                    }
                                                    <div className="truncate">{notification.Title}</div>
                                                </React.Fragment>
                                            }
                                            secondary={notification.Message}
                                        />
                                        <IconButton className="show-on-hover" onClick={() => this.removeNotification(notification)} color="inherit">
                                            <Icon color="error">remove_circle_outline</Icon>
                                        </IconButton>
                                    </ListItem>
                                ))}
                                {/* </div> */}
                            </FuseAnimateGroup>
                        }
                    </List>
                    <Divider />
                    <List classes={{ root: "py-0" }}>
                        <ListSubheader onClick={() => this.setState({ open: { ...open, events: !open.events } })} component="div" className={classNames("bg-white cursor-pointer", events.length > 0 && "text-black flex justify-between")}>
                            <div className="">
                                <Badge color="error" badgeContent={events.length} classes={{ badge: classes.badge }}>
                                    <Icon color={events.length > 0 ? "primary" : "action"} className="mr-4 align-middle mb-4">today</Icon>
                                </Badge>
                                Calendar
                            </div>
                            {events.length > 0 &&
                                <Icon className="mt-14">{open.events ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                            }
                        </ListSubheader>
                        {open.events &&
                            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                <div>
                                    {events.map(event => (
                                        <ListItem
                                            className="pl-44 pr-4 py-0 cursor-pointer highlight"
                                            key={event.ID}>
                                            <ListItemText
                                                onClick={() => {
                                                    const { ID, Type, StartDate, EndDate, Title, Description, AllDayYN, AddedBy, PublicYN, Data } = event;
                                                    this.props.openEditEventDialog({
                                                        id: ID,
                                                        start: moment.utc(StartDate).local().toDate(),
                                                        end: moment.utc(EndDate).local().toDate(),
                                                        title: Title,
                                                        desc: Description,
                                                        allDay: AllDayYN,
                                                        publicYN: PublicYN,
                                                        AddedBy,
                                                        Data: {
                                                            ...Data,
                                                            type: "E",
                                                        }
                                                    })
                                                }}
                                                classes={{ root: "my-0 py-6 bt-dotted", primary: "truncate", secondary: "text-11" }}
                                                primary={event.Title}
                                                secondary={`${moment.utc(event.StartDate || event.EndDate).local().format(event.AllDayYN === 'Y' ? "M/D/YYYY" : "h:mm A on M/D/YYYY")}`}
                                            />
                                        </ListItem>
                                    ))}
                                </div>
                            </FuseAnimate>
                        }
                    </List>
                    <Divider />
                    <List classes={{ root: "py-0" }}>
                        <ListSubheader onClick={() => this.setState({ open: { ...open, toDos: !open.toDos } })} component="div" className={classNames("bg-white cursor-pointer", toDos.length > 0 && "text-black flex justify-between")}>
                            <div className="">
                                <Badge color="error" badgeContent={toDos.length} classes={{ badge: classes.badge }}>
                                    <Icon color={toDos.length > 0 ? "primary" : "action"} className="mr-4 align-middle mb-4">check_box</Icon>
                                </Badge>
                                To-Do
                            </div>
                            {toDos.length > 0 &&
                                <Icon className="mt-14">{open.toDos ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                            }
                        </ListSubheader>
                        {open.toDos &&
                            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                                <div>
                                    {toDos.length > 0 &&
                                        <div className="w-full pl-36 pr-16">
                                            <TextField
                                                label={!toDoSearchText ? "Search" : undefined}
                                                InputProps={{
                                                    className: "pl-8 pr-0",
                                                    startAdornment: <InputAdornment placement="start"><Icon>search</Icon></InputAdornment>,
                                                    endAdornment: toDoSearchText ? <InputAdornment placement="end"><Icon className="cursor-pointer" onClick={() => this.setState({ toDoSearchText: null })}>close</Icon></InputAdornment> : undefined
                                                }}
                                                InputLabelProps={{ shrink: false, style: { marginLeft: 24 } }}
                                                name="toDoSearchText"
                                                onChange={(e) => this.setState({ toDoSearchText: e.target.value })}
                                                value={toDoSearchText || ''}
                                                variant="outlined"
                                                margin="dense"
                                                className="mt-0"
                                                fullWidth
                                            />
                                        </div>
                                    }
                                    {filteredToDos.map((event, index) => (
                                        <ListItem
                                            className="pl-44 pr-4 py-0 cursor-pointer highlight"
                                            key={event.ID}>
                                            <ListItemText
                                                onClick={() => {
                                                    if (event.Type === "P") {
                                                        this.props.openCardDialog(event, true);
                                                    } else {
                                                        this.props.openEditTodoDialog(event);
                                                    }
                                                }}
                                                classes={{ root: classNames("my-0 py-6", index > 0 && "bt-dotted"), primary: "truncate", secondary: classNames("text-11", moment.utc(event.DueDate || event.DueBy).local().toDate() < moment().toDate() ? "text-red" : "") }}
                                                primary={event.Title}
                                                secondary={event.DueDate ? `Due By ${moment.utc(event.DueDate).local().format("M/D/YYYY")}` : "Pending"}
                                            />
                                        </ListItem>
                                    ))}
                                </div>
                            </FuseAnimate>
                        }
                    </List>
                    <Divider />
                </Drawer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleQuickPanel: Actions.toggleQuickPanel,
        openEditEventDialog,
        openEditTodoDialog,
        openCardDialog,
        openEditWorkOrderDialog,
        openEditEstimateDialog,
        openEditInvoiceDialog,
        setRecording,
        getChat,
        openChatPanel,
        openEditGpsInventoryTransferDialog,
        setNotificationData,
        clearLocalNotifications
    }, dispatch);
}

function mapStateToProps({ quickPanel, spReducers }) {
    return {
        state: quickPanel.state,
        data: quickPanel.data,
        events: spReducers.calendar.data.Events,
        trips: spReducers.calendar.data.Trips,
        toDos: spReducers.calendar.data.ToDos,
        notifications: spReducers.notifications,
        voicemail: spReducers.voicemail
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(QuickPanel));
