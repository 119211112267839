import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import RGL, { WidthProvider } from "react-grid-layout";
import _ from 'lodash';
import FormElementDialog from './FormElementDialog';
import FormSection from './FormSection';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import moment from 'moment';

const GridLayout = WidthProvider(RGL);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    labels: {},
    formSection: {
        border: '1px dotted lightgrey',
        // padding: 8,
    },
    scrollEnable: {
        touchAction: 'unset !important',
    }

});

class FormSectionBuilder extends Component {

    state = {
        grabbing: false,
        needsUpdate: [],
    };

    render() {
        const { classes, form, technicians, scrollLock, securables, onNeedsUpdate, openNewFormElementDialog } = this.props;
        const { Co, Form, Section, Data, } = form;
        const data = [...Data.Elements];
        const accessLevel = _.find(securables, { Securable: "forms" });

        return (
            <div className="w-full pb-64">
                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">art_track</Icon>{`Elements`}</Typography>
                <FormSection section={form} scrollLock={scrollLock} editable={true} data={data} onNeedsUpdate={onNeedsUpdate} />
                {accessLevel && accessLevel.AccessLevel !== "R" &&
                    <div className="w-full pt-8">
                        <Button
                            className="w-full"
                            variant="contained"
                            color="primary"
                            onClick={() => openNewFormElementDialog({ Co, Form, Section })}
                        >
                            <Icon className="mr-6">add_circle_outline</Icon>New Element
                    </Button>
                    </div>
                }
                <FormElementDialog />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openNewFormElementDialog: Actions.openNewFormElementDialog,
        openEditFormElementDialog: Actions.openEditFormElementDialog,
    }, dispatch);
}

function mapStateToProps({ formsApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        formData: spReducers.forms,
        searchText: formsApp.forms.searchText,
        user: spReducers.userProfiles.User.UserName,
        employees: spReducers.employees,
        technicians: spReducers.technicians,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSectionBuilder)));
