import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { Avatar, Button, Card, CardHeader, CardContent, CardActions, Chip, Badge, Icon, Menu, IconButton, TextField, ListItemIcon, ListItemText, Popover, MenuItem, Tooltip, Typography, Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import * as quickPanelActions from 'main/quickPanel/store/actions';
import * as dialerActions from 'main/dialer/store/actions';
import * as authActions from 'auth/store/actions';
import * as chatPanelActions from 'main/chatPanel/store/actions';
import Autocomplete from 'main/content/components/autocomplete/Autocomplete';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import Chat from './Chat';
import { FuseShortcuts, FuseAnimate } from '@fuse';
import { Link } from 'react-router-dom';
import _ from '@lodash';
import getProfileImage from '../functions/getProfileImageUrl';

const styles = theme => ({
    chat: {
        background: 'transparent',
        border: '1px solid lightgrey',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: 300,
        height: 384,
        marginRight: 16,
        position: 'absolute',
        bottom: 0,
    },
    fullScreen: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
    },
    closed: {
        background: 'transparent',
        border: '1px solid lightgrey',
        borderBottom: 'none',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: 300,
        marginRight: 12,
        position: 'absolute',
        bottom: 0,
    },
    seperator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider
    },
    card: {
        cursor: 'pointer',
        width: '100%',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
    },
    cardHeader: {
        borderBottom: '1px solid lightgrey',
        boxShadow: '0px 0px 4px 0px #8f8f8f',
    },
    title: {
        fontWeight: 'bold',
    },
    subheader: {
        fontSize: 11,
    },
    actions: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    inactiveAvatar: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
    },
    unreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 2,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.error[500],
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    inactiveUnreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 2,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#8f8f8f',
        color: '#fff',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    closedUnreadBadge: {
        minWidth: 20,
        height: 20,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.error[500],
        color: theme.palette.primary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    closedInactiveUnreadBadge: {
        minWidth: 20,
        height: 20,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#8f8f8f',
        color: '#fff',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    formControl: {
        marginBottom: 12
    },

});

class Conversation extends Component {
    state = {
        open: true,
        sipPhone: null,
        sipSession: null,
        sipSessionActive: false,
        anchorEl: null,
        transfer: false,
    };

    componentDidMount() {
        const { sipPhone, sipSession, sipSessionActive } = this.props;

        this.setState({
            ...this.state,
            sipPhone,
            sipSession,
            sipSessionActive
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { sipPhone, sipSession, sipSessionActive } = this.props;
        if (!_.isEqual(this.state.sipPhone, sipPhone) || !_.isEqual(this.state.sipSession, sipSession) || !_.isEqual(this.state.sipSessionActive, sipSessionActive)) {
            this.setState({
                ...this.state,
                sipPhone,
                sipSession,
                sipSessionActive
            });
        }
    }

    formatPhone = (phone) => {
        const short = phone.substr(phone.length - 10, 10);
        return `(${short.substr(0, 3)}) ${short.substr(3, 3)}-${short.substr(6, 4)}`;
    }

    toggleOpen = () => {
        const { fullScreen } = this.props;
        if (!fullScreen) {
            const { open } = this.state;
            this.setState({ ...this.state, open: !open });
        }
    }

    handleCall = (phone) => {
        const { toggleDialer, initCall, dialer } = this.props;
        if (phone && phone.length === 7 || phone.length === 10 || phone.length === 12)
            toggleDialer(true);
        initCall(dialer, {
            destinationNumber: phone,
            audio: true,
            video: false,
        });
    }

    close = (e) => {
        const { contact } = this.props;
        e.stopPropagation();
        this.props.closeConversation(contact.Conversation);
    }

    endConversation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { Messages } = this.props.contact;
        this.props.updateConversation({ ...Messages[Messages.length - 1], EndConversationYN: 'Y', Data: { ErrMsg: null } });
    }

    openMenu = event => {
        event.stopPropagation();
        this.setState({ ...this.state, anchorEl: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null });
    };

    setTransferConversation = () => {
        this.setState({ ...this.state, transfer: true });
    }

    transferConversation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { Messages } = this.props.contact;
        this.setState({ ...this.state, transfer: false, });
        this.props.updateConversation({ ...Messages[Messages.length - 1], HandledBy: this.state.HandledBy, Data: { ErrMsg: null } });
    }


    render() {
        const { Co, classes, toggleQuickPanel, user, users, logout, openChatPanel, openMap, toggleDialer, contact, messages, order, fullScreen } = this.props;
        const { userMenu, sipPhone, sipSession, sipSessionActive, open, anchorEl, transfer } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';
        const last = contact.Messages[contact.Messages.length - 1];
        const active = last.EndConversationYN !== 'Y';
        const unread = last.From === contact.From;
        const userAvatar = '../../assets/images/avatars/profile.jpg';

        return (
            <div style={{ right: fullScreen ? 0 : (312 * order), }} className={classNames(fullScreen ? classNames(classes.fullScreen, "full-screen-dialog") : open ? classes.chat : classes.closed, "")}>
                <Card className={classes.card}>
                    <CardHeader onClick={this.toggleOpen} classes={{ title: classes.title, subheader: classes.subheader, avatar: "mr-8" }} className={classNames(classes.cardHeader, "conversation-header", `${open ? "p-6" : "p-0 pl-6 pr-6"} highlight`)}
                        avatar={
                            !open ?
                                <Icon className="mt-8" color={active ? "primary" : "action"}>{contact.Type === 'text' ? 'phone_iphone' : 'message'}</Icon>
                                :
                                <React.Fragment>
                                    {unread && (
                                        <div className={active ? classes.unreadBadge : classes.inactiveUnreadBadge}><Icon className="text-12">textsms</Icon></div>
                                    )}
                                    <Avatar className={active ? classes.avatar : classes.inactiveAvatar}>
                                        <Icon style={{ paddingTop: contact.Type !== 'text' ? 2 : 0 }}>{contact.Type === 'text' ? 'phone_iphone' : 'message'}</Icon>
                                    </Avatar>
                                </React.Fragment>
                        }
                        action={
                            <React.Fragment>
                                {open && contact.Type === 'text' &&
                                    <IconButton onClick={() => this.handleCall(contact.Phone)} classes={{ root: !open ? "p-6" : "p-8" }} className="mt-8">
                                        <Icon color="primary">phone</Icon>
                                    </IconButton>
                                }
                                {open && contact.Type === 'text' &&
                                    <IconButton onClick={this.openMenu} classes={{ root: !open ? "p-6" : "p-8" }} className={open ? "mt-8" : "mt-8"}>
                                        <Icon>more_vert</Icon>
                                    </IconButton>
                                }
                                <IconButton onClick={this.close} classes={{ root: !open ? "p-6" : "p-8" }} className={open ? "mt-8 mr-4" : "mt-8"}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </React.Fragment>
                        }
                        title={<Typography className="font-bold flex" style={{ alignItems: 'center' }}>{`${contact.Name || this.formatPhone(contact.Phone)}`}{!open && unread && <Icon className="text-20 ml-12 font-bold" color={active ? "error" : "action"}>textsms</Icon>}</Typography>}
                        subheader={open && contact.Name && contact.Phone && `${this.formatPhone(contact.Phone)}`}
                    />
                    {open &&
                        <Chat fullScreen={ fullScreen} chat={{ ...contact }} />
                    }
                </Card>
                <Menu id="conversation-menu" classes={{ paper: "min-w-256" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
                    <MenuItem onClick={this.endConversation}><Icon className="mr-8">snooze</Icon>End Conversation</MenuItem>
                    <MenuItem onClick={this.setTransferConversation}><Icon className="mr-8">forward</Icon>Transfer Conversation</MenuItem>
                    {transfer &&
                        <div className="p-12">
                            <Autocomplete
                                className={classes.formControl}
                                title="Transfer To"
                                options={users}
                                menuItemComponent={(value) => {
                                    if (value.UserName !== user.UserName) {
                                        return (
                                            <MenuItem disabled={value.UserName === user.UserName || value.Data.Status !== 2} value={value.UserName}>
                                                <div className="flex">
                                                    <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.userAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`) : userAvatar)} />
                                                    {`${value.FirstName} ${value.LastName}`}
                                                </div>
                                            </MenuItem>
                                        );
                                    }
                                }}
                                portal={true}
                                value={this.state.HandledBy}
                                onSelect={(option) => this.setState({ ...this.state, HandledBy: option.UserName })}
                                required
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={this.transferConversation}
                                disabled={!this.state.HandledBy}
                            >
                                Add
                            </Button>
                        </div>
                    }
                </Menu>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleQuickPanel: quickPanelActions.toggleQuickPanel,
        logout: authActions.logoutUser,
        openChatPanel: chatPanelActions.openChatPanel,
        openMap: chatPanelActions.openMap,
        toggleDialer: dialerActions.toggleDialer,
        initCall: dialerActions.initCall,
        updateConversation: Actions.endConversation,
        closeConversation: Actions.closeConversation,
    }, dispatch);
}


function mapStateToProps({ spReducers, dialer }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        dialer: dialer.sipPhone,
        users: spReducers.userProfiles.Users,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Conversation));
