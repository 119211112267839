import React, { Component } from 'react';
import {
    TextField, Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, Avatar, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import Media from 'react-media';
import _ from '@lodash';

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    }
});
const newUOMState = {
    Co: null,
    UOM: null,
    Description: '',
    ActiveYN: 'Y',
    Notes: '',
    Data: {

    }
};

class UOMDialog extends Component {
    state = { ...newUOMState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        newUOMState.Co = this.props.Co;
        if (!prevProps.uomDialog.props.open && this.props.uomDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.uomDialog.type === 'edit' &&
                this.props.uomDialog.data &&
                !_.isEqual({ ...newUOMState, ...this.props.uomDialog.data }, prevState)) {
                this.setState({ ...newUOMState, ...this.props.uomDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.uomDialog.type === 'new' &&
                !_.isEqual(newUOMState, prevState)) {
                this.setState({ ...newUOMState });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.uomDialog.type === 'edit' ? this.props.closeEditUOMDialog() : this.props.closeNewUOMDialog();
    };

    canBeSubmitted() {
        const { UOM, Description } = this.state;
        return (
            UOM && Description && UOM.length > 0 && Description.length > 0
        );
    }

    render() {
        const { classes, securables, uomDialog, addUOM, updateUOM, removeUOM, businessUnits } = this.props;
        const accessLevel = _.find(securables, { Securable: "units-of-measure" });

        return (
            <Media query="(min-width: 512px) and (min-height: 512px)">
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={ !matches}
                        className={classes.root}
                        {...uomDialog.props}
                        open={Boolean(accessLevel) && uomDialog.props.open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                    >

                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {uomDialog.type === 'new' ? 'New Unit of Measure' : 'Edit Unit of Measure'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-24" }}>
                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Unit of Measure"
                                    id="uom"
                                    name="UOM"
                                    value={this.state.UOM}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={(uomDialog.type === 'new' ? false : true)}
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div>
                        </DialogContent>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {uomDialog.type === 'new' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            addUOM(this.state);
                                            this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                            </Button>
                                ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                updateUOM(this.state);
                                                this.closeComposeDialog();
                                            }}
                                            disabled={!this.canBeSubmitted()}
                                        >
                                            Save
                            </Button>
                                    )}
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditUOMDialog: Actions.closeEditUOMDialog,
        closeNewUOMDialog: Actions.closeNewUOMDialog,
        addUOM: Actions.addUOM,
        updateUOM: Actions.updateUOM,
        removeUOM: Actions.removeUOM
    }, dispatch);
}

function mapStateToProps({ uomsApp, spReducers }) {
    return {
        uomDialog: uomsApp.uoms.uomDialog,
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(UOMDialog));
