import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class PhoneNumbersList extends Component {

    state = {
        selectedPhoneNumbersMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedPhoneNumberMenu = (event) => {
        this.setState({ selectedPhoneNumbersMenu: event.currentTarget });
    };

    closeSelectedPhoneNumbersMenu = () => {
        this.setState({ selectedPhoneNumbersMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, phoneNumbers, user, searchText, openNewPhoneNumberDialog, selectedPhoneNumberIds, selectAllPhoneNumbers, deSelectAllPhoneNumbers, toggleInSelectedPhoneNumbers, openEditPhoneNumberDialog, removePhoneNumbers, removePhoneNumber, toggleStarredPhoneNumber, setPhoneNumbersUnstarred, setPhoneNumbersStarred } = this.props;
        const data = this.getFilteredArray((!this.props.selectedPhoneNumbersData ? (!this.props.phoneNumberData ? [] : this.props.phoneNumberData) : this.props.selectedPhoneNumbersData), searchText);
        const { selectedPhoneNumbersMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="p-12">
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">phone_callback</Icon>Phone Numbers</Typography>
                    <ReactTableFixedColumns
                        color='transparent'
                        stripedColor='rgba(10,10,10,.01)'
                        highlightColor='rgba(10,10,10,.005)'
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditPhoneNumberDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Phone Number",
                                accessor: "Phone",
                                className: "font-bold justify-center",
                                width: 175
                            },
                            {
                                Header: "Name",
                                accessor: "Name",
                                className: "justify-center",
                                width: 200,
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 300,
                            },
                            {
                                Header: "Action",
                                accessor: "Action",
                                className: "justify-center",
                                width: 128,
                            },
                            {
                                Header: "Target",
                                className: "font-bold justify-center",
                                id: "actionTarget",
                                accessor: d => (d.Action === 'Enqueue' ? d.DefaultQueue : d.Action === 'Dial' ? d.DefaultDial : d.Action === 'Gather' ? d.DefaultGather : d.Action === 'Hunt' ? d.DefaultHuntGroup : d.Action === "Voicemail" ? d.DefaultVoiceMailbox : ''),
                                width: 175
                            },
                            {
                                Header: "Toll Free",
                                accessor: "TollFreeYN",
                                className: "justify-center",
                                width: 64,
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                                width: 64,
                            },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Phone Numbers found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPhoneNumbers: Actions.getPhoneNumbers,
        getUserData: Actions.getUserData,
        toggleInSelectedPhoneNumbers: Actions.toggleInSelectedPhoneNumbers,
        selectAllPhoneNumbers: Actions.selectAllPhoneNumbers,
        deSelectAllPhoneNumbers: Actions.deSelectAllPhoneNumbers,
        openEditPhoneNumberDialog: Actions.openEditPhoneNumberDialog,
        removePhoneNumber: Actions.removePhoneNumber,
        openNewPhoneNumberDialog: Actions.openNewPhoneNumberDialog
    }, dispatch);
}

function mapStateToProps({ phoneNumbersApp, spReducers }) {
    return {
        phoneNumbers: phoneNumbersApp.phoneNumbers.entities,
        selectedPhoneNumberIds: phoneNumbersApp.phoneNumbers.selectedPhoneNumberIds,
        searchText: phoneNumbersApp.phoneNumbers.searchText,
        user: phoneNumbersApp.user,
        phoneNumberData: spReducers.phoneNumbers
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PhoneNumbersList)));
