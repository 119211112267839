import YouTubeApp from 'main/content/apps/youtube/YouTubeApp';

export const YouTubeAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/apps/youtube',
            component: YouTubeApp
        }
    ]
};