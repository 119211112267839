import * as Actions from '../actions'
import _ from '@lodash'
import moment from 'moment'

const initialState = {
  data: {},
  deposits: {},
  searchText: '',
  filters: {
    Type: 'charge',
    // StartDate: moment.utc().add(-1, 'month').format('YYYY-MM-DD'),
    // EndDate: moment.utc().format('YYYY-MM-DD'),
    StartDate: moment().format('YYYY-MM-DD'),
    EndDate: moment().add(7, 'days').format('YYYY-MM-DD'),
    Success: null,
    // TopLevel: '1',
    IsManual: null,
    SortBy: null,
    SortOrder: 'DESC',
    PerPage: 20,
  },
  loading: false,
  transaction: null,
}

const paymentsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_LOADING: {
      const { loading } = action
      return {
        ...state,
        loading,
      }
    }
    case Actions.SET_PAYMENTS: {
      return {
        ...state,
        data: action.data,
        filters: action.filters,
        loading: action.loading,
        deposit: null,
      }
    }
    case Actions.SET_DEPOSITS: {
      return {
        ...state,
        deposits: action.data,
        filters: action.filters,
        loading: action.loading,
        deposit: null,
      }
    }
    case Actions.SET_DEPOSIT: {
      const { deposit } = action
      return {
        ...state,
        deposit,
        loading: false,
      }
    }
    case Actions.SET_DEPOSIT_TRANSACTIONS: {
      const { depositTransactions } = action
      return {
        ...state,
        depositTransactions,
        loading: false,
      }
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      }
    }
    case Actions.SET_PAYMENT_FILTERS: {
      const { filters } = action
      return {
        ...state,
        filters,
      }
    }
    case Actions.SET_TRANSACTION: {
      const { transaction } = action
      return {
        ...state,
        transaction,
      }
    }
    default: {
      return state
    }
  }
}

export default paymentsReducer
