import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Slide,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Receipt } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { saveAs } from '@progress/kendo-file-saver'
import axios from 'axios'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { bindActionCreators } from 'redux'
import ChecklistGenerator from '../checklists/ChecklistGenerator'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import { openEditCustomerDialog } from '../customers/store/actions'
import { openEditEstimateDialog } from '../estimates/store/actions'
import { updateTrip } from '../trips/store/actions'
import { openEditWorkOrderUsageDialog } from '../work-order-usages/store/actions'
import { openEditWorkOrderDialog } from '../work-orders/store/actions'
import InvoiceAddPaymentComponent from '../invoices/InvoiceAddPaymentComponent'

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  formControl: {
    marginBottom: 24,
    marginTop: 24,
  },
  customerSelect: {
    padding: 2,
    minHeight: 512,
    minWidth: '25%',
    border: '1px solid lightgrey',
    marginRight: 12,
    marginBottom: 24,
    borderRadius: 5,
    background: 'rgba(0, 0, 0, .05)',
  },
  customerCard: {
    borderRadius: 5,
    boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, .5)',
  },
  customerAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginBottom: 12,
    marginLeft: '-4px !important',
    height: 24,
    maxWidth: '66%',
  },
  chipLabel: {
    paddingLeft: 8,
    fontWeight: 'bold',
    color: '#fff',
    paddingBottom: 2,
  },
  chipAvatar: {
    marginLeft: '0 !important',
    color: '#3f3f3f',
    backgroundColor: '#3f3f3f',
    border: '3px solid #3f3f3f',
    width: '32px !important',
    height: '32px !important',
  },
  chipAvatarIcon: {
    color: '#3f3f3f',
    backgroundColor: '#fff',
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  postedMatch: {
    color: theme.palette.secondary.dark,
  },
  postedNoMatch: {
    color: theme.palette.error[500],
  },
  notPosted: {
    color: '#5f5f5f',
  },
  postedBatch: {
    color: theme.palette.primary.main,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  qty: {
    backgroundColor: '#3f3f3f',
    height: 18,
    minWidth: 24,
    fontSize: 13,
    paddingLeft: 6,
    paddingRight: 6,
    marginRight: 6,
    marginTop: 3,
    float: 'left',
    borderRadius: 12,
    color: '#fff',
    textAlign: 'center',
  },
  invHeader: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333',
  },
  invSubHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    marginTop: 4,
    color: '#333',
  },
  dividerPlain: {
    color: '#fff',
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
})

const newInvoiceState = {
  ID: null,
  Co: null,
  Invoice: null,
  InvoiceNumber: null,
  InvoiceDate: null,
  Status: null,
  Customer: null,
  PayTerms: null,
  DueDate: null,
  DiscountDate: null,
  DiscountRate: null,
  Description: null,
  WorkOrder: null,
  FirstName: null,
  LastName: null,
  BillName: null,
  BillContact: null,
  BillAddress1: null,
  BillAddress2: null,
  BillCity: null,
  BillState: null,
  BillZip: null,
  BillCountry: null,
  BillPhone: null,
  BillEmail: null,
  Technician: null,
  Notes: null,
  DeliveredYN: null,
  DeliveredDate: null,
  DeliveredBy: null,
  SummaryLevel: 'D',
  FormOfPayment: null,
  PaymentConfirmation: null,
  PaymentsCustomer: null,
  PaymentsInvoice: null,
  VoidDate: null,
  VoidedBy: null,
  EnteredBy: null,
  EnteredDate: null,
  UpdatedBy: null,
  UpdatedDate: null,
  AttachmentID: null,
  isSigning: false,
  Signature: null,
  SignedBy: null,
  Location: null,
  Site: null,
  Data: {
    ErrMsg: null,
    ID: null,
    Co: null,
    Invoice: null,
    InvoiceNumber: null,
    InvoiceDate: null,
    Status: null,
    Customer: null,
    PayTerms: null,
    DueDate: null,
    DiscountDate: null,
    DiscountRate: null,
    Description: null,
    WorkOrder: null,
    BillName: null,
    BillContact: null,
    BillAddress1: null,
    BillAddress2: null,
    BillCity: null,
    BillState: null,
    BillZip: null,
    BillCountry: null,
    BillPhone: null,
    BillEmail: null,
    Technician: null,
    Notes: null,
    DeliveredYN: null,
    DeliveredDate: null,
    DeliveredBy: null,
    SummaryLevel: 'D',
    FormOfPayment: null,
    PaymentConfirmation: null,
    PaymentsInvoice: null,
    PaymentsCustomer: null,
    VoidDate: null,
    VoidedBy: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    AttachmentID: null,
    isSigning: false,
    Signature: null,
    SignedBy: null,
    Location: null,
    Data: {
      Scopes: [],
      Usage: [],
      InvoiceDetails: [],
      Payments: [],
      PaymentDetail: [],
      ErrMsg: null,
    },
  },
  EditFOP: true,
  confirm: false,
  labor: false,
  OfflineID: null,
  addPayment: false,
  isDatepickerOpen: false,
  isDueDatepickerOpen: false,
  showWarrantyDetail: false,
  confirmDelete: false,
  promptPayment: false,
  addPayment: false,
  viewPayment: false,
  closeAfter: false,
  checklistsPDF: null,
  checklistResults: '',
  showEmailLogs: false,
  printPDF: null,
  addScope: false,
  syncing: false,
  editingLine: false,
  refreshing: false,
  viewChanges: false,
  IsFinanced: false,
  IsApprovedAccount: false
}

class InvoicePlaceholder extends Component {
  state = { ...newInvoiceState }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const invoiceNotes = this.props.notes

    newInvoiceState.Co = this.props.Co

    if (
      !prevProps.invoiceDialog.props.open &&
      this.props.invoiceDialog.props.open
    ) {
      if (
        this.props.invoiceDialog.type === 'edit' &&
        this.props.invoiceDialog.data &&
        !_.isEqual(this.props.invoiceDialog.data, prevProps.invoiceDialog.data)
      ) {
        this.setState(
          { ...newInvoiceState, ...this.props.invoiceDialog.data },
          this.createPaymentsCustomer,
        )
      }

      if (
        this.props.invoiceDialog.type === 'new' &&
        !_.isEqual(
          { ...newInvoiceState, ...this.props.invoiceDialog.data },
          prevState,
        )
      ) {
        let { InvoiceNumber, Notes } = this.props.invoiceDialog.data
        if (invoiceNotes) {
          const notes = invoiceNotes[InvoiceNumber]
          if (notes) {
            Notes = notes.Notes
          }
          this.setState(
            { ...newInvoiceState, ...this.props.invoiceDialog.data, Notes },
            this.createPaymentsCustomer,
          )
        } else {
          this.setState(
            { ...newInvoiceState, ...this.props.invoiceDialog.data, Notes },
            this.createPaymentsCustomer,
          )
        }
      }
    }
    if (
      this.props.invoiceDialog.type === 'update' &&
      this.props.invoiceDialog.data &&
      !_.isEqual(this.props.invoiceDialog.data, prevProps.invoiceDialog.data)
    ) {
      this.props.invoiceDialog.type = !this.props.invoiceDialog.data.Invoice
        ? 'new'
        : 'edit'
      const canEmail = Boolean(
        !prevProps.invoiceDialog.data.Invoice &&
        this.props.invoiceDialog.data.Invoice,
      )
      this.setState({ ...this.props.invoiceDialog.data }, () => {
        const { trip } = this.props.invoiceDialog.props
        if (canEmail) {
          this.openEmail(true, Boolean(trip && trip.Status < 8))
        }
      })
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  closeComposeDialog = () => {
    this.props.invoiceDialog.type === 'edit'
      ? this.props.closeEditInvoiceDialog()
      : this.props.closeNewInvoiceDialog()
  }

  canBeSubmitted() {
    const { InvoiceNumber, Description } = this.state
    return (
      InvoiceNumber &&
      Description &&
      InvoiceNumber.length > 0 &&
      Description.length > 0
    )
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    var byteCharacters = atob(b64Data)
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    var blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return formatted
  }
  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({
      ...this.state,
      Signature: this.sigPad.getCanvas().toDataURL('image/png'),
      isSigning: false,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  handleResize = () => {
    // Get the current signature data before resizing
    if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
      const signatureData = this.sigPad.toDataURL()
      // Handle the resize without clearing the canvas
      this.sigPad.clear()
      this.sigPad.fromDataURL(signatureData)
    }
  }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  exportToPDF = () => {
    const { InvoiceNumber } = this.state
    let gridElement = document.getElementById('invoice-print')
    if (!window.cordova) {
      drawDOM(gridElement, {
        keepTogether: '.no-break',
        paperSize: 'letter',
        margin: 20,
        scale: 0.6,
      })
        .then(group => {
          return exportPDF(group)
        })
        .then(dataUri => {
          var data = dataUri.split(';base64,')[1]
          const blob = this.b64toBlob(data, 'application/pdf')
          saveAs(dataUri, InvoiceNumber)
        })
    } else {
      const { cordova, pdf } = window
      var formCss = ''
      var styles = document.getElementsByTagName('style')
      for (var i = 0; i < styles.length; i++) {
        formCss += `<style>${styles[i].innerHTML}</style>`
      }
      formCss += '<style> .no-print { display:none !important; } </style>'
      let options = {
        documentSize: 'A4',
        type: 'share',
        fileName: `${InvoiceNumber}.pdf`,
      }
      const html = document.getElementById('invoice-print').innerHTML
      pdf
        .fromData(
          `<html><head>${formCss}</head><body>${html}</body></html>`,
          options,
        )
        .then(stats => window['warn']('status', stats)) // it will
        .catch(err => console.err(err))
    }
  }

  emailInvoice = (closeAfter, confirm) => {
    const { InvoiceNumber } = this.state
    let gridElement = document.getElementById('invoice-print')
    drawDOM(gridElement, {
      keepTogether: '.no-break',
      paperSize: 'letter',
      margin: 20,
      scale: 0.6,
    })
      .then(group => {
        return exportPDF(group)
      })
      .then(dataUri => {
        let html = ''
        const { Co, ID, checklistsPDF } = this.state
        const file = this.dataURLtoFile(dataUri, `${InvoiceNumber}.pdf`)
        const invoice = this.buildInvoiceEmail()
        const { businessUnits, divisions, templates, companies } = this.props
        const co = _.find(companies, { Co })
        const { Scopes } = this.state.Data.Data
        const { InvoiceDetails, Contacts } = this.state.Data.Data
        const billingContact = Contacts
          ? _.find(Contacts, { BillingYN: 'Y' })
          : null
        const { BusinessUnit, Division } =
          Scopes && Scopes.length > 0
            ? Scopes[0]
            : InvoiceDetails &&
              InvoiceDetails.length > 0 &&
              InvoiceDetails[0].Data &&
              InvoiceDetails[0].Data.Agreement
              ? InvoiceDetails[0].Data.Agreement
              : null
        if (BusinessUnit && Division && businessUnits && templates) {
          const bu = _.find(businessUnits, { Co, BusinessUnit })
          const div = _.find(divisions, { Co, BusinessUnit, Division })
          if (bu && div) {
            const template =
              div.Data.InvoiceEmailTemplate || bu.Data.InvoiceEmailTemplate
            html = template ? template.Markup : ''
          }
        }
        const Attachments = [file]
        if (checklistsPDF) {
          Attachments.push(checklistsPDF)
        }
        const data = {
          composeDialog: true,
          Title: 'Email Invoice',
          Icon: 'email',
          To: `${billingContact &&
              billingContact.Data.Contact &&
              billingContact.Data.Contact.Email
              ? billingContact.Data.Contact.Email
              : this.state.BillEmail
            };`,
          Subject: `Your Invoice from ${co.Data.Co.Name}`,
          Head: '',
          Message: '',
          Body: html,
          Attachments,
          Type: 'INVOICE',
          RecordID: ID,
        }
        this.setState({
          ...this.state,
          invoiceEmail: data,
          confirm,
          closeAfter,
        })
      })
  }

  buildInvoiceEmail = () => {
    const { Co } = this.state
    let Body = ``
    let Head = ``
    const data = {
      Head,
      Body,
    }
    return data
  }

  handleDateChange = event => {
    window['warn'](event)
    const InvoiceDate = event._d
    this.setState({ ...this.state, InvoiceDate, DueDate: InvoiceDate })
  }

  handleDueDateChange = event => {
    window['warn'](event)
    const DueDate = event._d
    this.setState({ ...this.state, DueDate })
  }

  openDatePicker = e => {
    this.setDatepickerOpen(true)
  }

  openDueDatePicker = e => {
    this.setDueDatepickerOpen(true)
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer'>
      {new Date(this.state.InvoiceDate).toLocaleDateString('en-US')}
    </span>
  )

  startPayment = () => {
    const { companies } = this.props
    const { Co, BillName, Customer, InvoiceNumber, BillEmail } = this.state
    const inv = this.state.Data
    var cname = BillName.split(',')
    if (cname.length < 2) {
      cname = cname[0].split(' ')
    }
    const lines = _.flatten(
      _.map(inv.Data.InvoiceDetails, _.property('Data.InvoiceLines')),
    )
    const sub = _.sumBy(lines, _.property('Amount'))
    const tax = _.sumBy(lines, _.property('TaxAmount'))
    const co = _.find(companies, { Co }).Data.Co
    const total = sub + tax
    var jstr = btoa(
      JSON.stringify(
        {
          action: 'startPayment',
          amount: total.toFixed(2),
          invoice_number: InvoiceNumber,
          customer_number: Customer,
          customer_first_name: cname[1].trim(),
          customer_last_name: cname[0].trim(),
          customer_email: BillEmail,
          callbackUrl: 'sp-payment://confirm-payment',
        },
        null,
        2,
      ),
    )
    var url = 'payzer://interapp#' + jstr
    console.warn(atob(jstr), url)
    // window.open(url, '_system');
  }

  setNotes = (InvoiceNumber, Notes) => {
    let { notes } = this.props
    const old = new Date()
    old.setMonth(old.getMonth() - 1)
    Object.keys(notes).map((note, index) => {
      if (new Date(note.Date) < old) {
        notes.splice(index, 1)
      }
    })
    if (notes) {
      notes[InvoiceNumber] = { Notes, Date: new Date() }
      this.props.updateNotes(notes)
    } else {
      notes = {}
      notes[InvoiceNumber] = { Notes, Date: new Date() }
      this.props.updateNotes(notes)
    }
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  handlePayment = payment => {
    const { Co, Data } = this.state
    if (payment) {
      if (Data.Data && Data.Data.PaymentDetail) {
        Data.Data.PaymentDetail.push(payment)
      }
      if (Data.Data && Data.Data.Payments) {
        Data.Data.Payments.push(
          !payment.ID
            ? {
              Co,
              PaymentID: payment.ID,
              PaymentDateTime: payment.PaymentDateTime,
              PaymentMethodType: payment.Data.PaymentMethod
                ? payment.Data.PaymentMethod.PaymentMethodType
                : payment.PaymentType,
              Nickname: payment.Data.PaymentMethod
                ? payment.Data.PaymentMethod.Nickname
                : '',
              CardLastFour: payment.Data.PaymentMethod
                ? payment.Data.PaymentMethod.CardLastFour
                : null,
              Amount: payment.Data.Allocations[0].Amount,
              Check: payment.Check,
              Memo: payment.Memo,
              Data: payment.Data,
            }
            : payment,
        )
      }
      this.setState({
        ...this.state,
        PaymentsInvoice: payment.PaymentsInvoice,
        Data,
      })
    }
  }

  handleVoidPayment = payment => {
    const { User } = this.props
    const { Data } = this.state
    if (payment) {
      payment.VoidDate = payment.VoideDate || new Date()
      payment.VoidedBy = payment.VoidedBy || User.UserName
      if (Data.Data && Data.Data.Payments) {
        const index = _.findIndex(Data.Data.Payments, {
          PaymentID: payment.PaymentID,
        })
        window['warn']('Voided Payment: ', payment, index, Data)
        if (index > -1) {
          Data.Data.Payments.splice(index, 1, payment)
        }
      }
      if (Data.Data && Data.Data.PaymentDetail) {
        const index = _.findIndex(Data.Data.PaymentDetail, {
          PaymentID: payment.PaymentID,
        })
        window['warn']('Voided Payment: ', payment, index, Data)
        if (index > -1) {
          Data.Data.PaymentDetail.splice(index, 1, payment)
        }
      }
      this.setState({ ...this.state, Data })
    }
  }

  setDatepickerOpen = bool => {
    const { isDatepickerOpen } = this.state
    if (isDatepickerOpen !== bool) {
      this.setState({ isDatepickerOpen: bool })
    }
  }

  setDueDatepickerOpen = bool => {
    const { isDueDatepickerOpen } = this.state
    if (isDueDatepickerOpen !== bool) {
      this.setState({ isDueDatepickerOpen: bool })
    }
  }

  formatPhone = str => {
    const phone = str.replace(/\(|\)|,|\.|-/g, '')
    return phone.length === 10
      ? `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`
      : str
  }

  createPaymentsCustomer = () => {
    const {
      Co,
      Customer,
      FirstName,
      LastName,
      Name,
      BillPhone,
      BillEmail,
      BillAddress1,
      BillAddress2,
      BillCity,
      BillState,
      BillCountry,
      BillZip,
      PaymentsCustomer,
      total,
    } = this.state
    if (!PaymentsCustomer) {
      const customer = {
        Co,
        Customer,
        firstname: FirstName || '',
        lastname: LastName || '',
        company: Name || '',
        phone: BillPhone ? BillPhone.replace(/[^a-zA-Z0-9]/g, '') : '',
        email: BillEmail || '',
        address_1: BillAddress1,
        address_2: BillAddress2 || '',
        address_city: BillCity,
        address_state: BillState,
        address_zip: BillZip,
        reference: Customer.toString(),
      }
      const request = axios.post(
        `${window['apiLocation']}/api/Omni/CreateCustomer`,
        customer,
      )
      request.then(response => {
        window['warn']('Payments Customer: ', response.data)
        if (response.data.id) {
          this.setState({ ...this.state, PaymentsCustomer: response.data.id })
        }
      })
    }
  }

  openEmail = (closeAfter, confirm) => {
    const inv = this.state.Data
    const checklists = []
    const {
      classes,
      companies,
      users,
      divisions,
      serviceUnitTypes,
      workOrderDialog,
    } = this.props
    const woData = workOrderDialog.data
    if (woData && woData.WorkOrder === this.state.WorkOrder) {
      const { Data } = woData
      const { Scopes, Site } = Data
      Scopes.map(scope => {
        if (
          _.filter(
            inv.Data.InvoiceDetails,
            o =>
              o.Scope === scope.Scope ||
              (o.Data.Usage && o.Data.Usage.Scope === scope.Scope),
          ).length > 0
        ) {
          if (scope.Data && scope.Data.ServiceUnits) {
            scope.Data.ServiceUnits.map(su => {
              if (su.Data && su.Data.ChecklistResults) {
                su.Data.ChecklistResults.map(checklist => {
                  checklist.info =
                    scope &&
                    [scope].map(scope => {
                      const { Co, BusinessUnit, Division } = scope || {}
                      const division = _.find(divisions, {
                        Co,
                        BusinessUnit,
                        Division,
                      })
                      const technician = _.find(users, {
                        UserName: checklist.EnteredBy,
                      })
                      const unit = _.find(Site.Data.Units, {
                        UnitID: su.UnitID,
                      })
                      return (
                        <div className='w-full' key={scope.ID}>
                          <div className='w-full flex justify-between my-24'>
                            {division && division.InvoiceHeaderImg && (
                              <img
                                className='h-48 sm:h-96'
                                src={division.InvoiceHeaderImg}
                              />
                            )}
                            <span className='sm:mt-12'>
                              <label
                                className={classNames(
                                  classes.invHeader,
                                  'sm:pr-8 text-20 sm:text-36',
                                )}
                              >
                                CHECKLIST
                              </label>
                              <div
                                className={classNames(
                                  classes.invSubHeader,
                                  'sm:pr-8 text-10 sm:text-16',
                                )}
                              >
                                Work Order #{this.state.WorkOrder}-{scope.Scope}
                              </div>
                            </span>
                          </div>

                          <div className='flex mb-24 justify-between p-16 pr-4'>
                            <div className='cursor-pointer'>
                              <div className='w-full text-16 font-bold text-left'>
                                {Data.Customer.Name &&
                                  Data.Customer.Name.length > 0
                                  ? Data.Customer.Name
                                  : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                              </div>
                              <div className='w-full text-12 text-left'>
                                {Data.Customer.Address1}
                              </div>
                              {Data.Customer.Address2 && (
                                <div className='w-full text-12 text-left'>
                                  {Data.Customer.Address2}
                                </div>
                              )}
                              <div className='w-full text-12 text-left'>
                                {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                              </div>
                            </div>

                            <div>
                              <div
                                className='flex cursor-pointer'
                                onClick={this.openDatePicker}
                              >
                                <img
                                  src='assets/icons/calendar_icon.png'
                                  className='w-32 h-32 mr-8'
                                />
                                <div className='w-full'>
                                  <div className='text-14 font-bold'>
                                    {checklist.ResultID
                                      ? moment(checklist.EnteredDate).format(
                                        'dddd',
                                      )
                                      : moment(Date()).format('dddd')}
                                  </div>
                                  <div className='text-10'>
                                    {checklist.ResultID
                                      ? moment(checklist.EnteredDate).format(
                                        'MMM DD YYYY',
                                      )
                                      : moment(Date()).format('MMM DD YYYY')}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='flex mt-24'>
                            <div className='w-full'>
                              <div
                                className='w-full flex justify-between rounded-full mb-12'
                                style={{ backgroundColor: '#3f3f3f' }}
                              >
                                <div
                                  className={classNames(
                                    'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                    classes.dividerHighlight,
                                  )}
                                >
                                  {checklist.Name}
                                </div>
                                <div
                                  className={classNames(
                                    'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                    classes.dividerPlain,
                                  )}
                                >
                                  {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-full flex justify-between'>
                            <div className='text-12 text-left pl-16'>
                              {/* <div className="w-full">
                                                        <strong className="mr-8 font-bold">Task:</strong>
                                                        <label>{task.Data.Task.Name}</label>
                                                    </div> */}
                              {unit.UnitType &&
                                _.find(serviceUnitTypes, {
                                  UnitType: unit.UnitType,
                                }) && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Unit Type:
                                    </strong>
                                    <label>
                                      {
                                        _.find(serviceUnitTypes, {
                                          UnitType: unit.UnitType,
                                        }).Name
                                      }
                                    </label>
                                  </div>
                                )}
                              <div className='w-full'>
                                <strong className='mr-8 font-bold'>
                                  Unit ID:
                                </strong>
                                <label>{`${unit.UnitID} - ${unit.Name}`}</label>
                              </div>
                              {unit.Manufacturer && (
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Manufacturer:
                                  </strong>
                                  <label>{unit.Manufacturer}</label>
                                </div>
                              )}
                              {unit.Model && (
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Model:
                                  </strong>
                                  <label>{unit.Model}</label>
                                </div>
                              )}
                              {unit.SerialNumber && (
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Serial #:
                                  </strong>
                                  <label>{unit.SerialNumber}</label>
                                </div>
                              )}
                            </div>
                            {technician && (
                              <div
                                className='flex mr-24 mt-4'
                                style={{ marginBottom: -28 }}
                              >
                                <img
                                  src='assets/icons/person_icon.png'
                                  className='w-24 h-24 mr-4'
                                />
                                <div className='w-full'>
                                  <div className='text-12 font-bold mt-4'>
                                    {`${technician.FirstName} ${technician.LastName}`}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })
                  checklist.footerInfo =
                    scope &&
                    [scope].map(scope => {
                      const { Co } = scope || {}
                      const co = (_.find(companies, { Co }) || { Data: {} })
                        .Data.Co
                      if (co) {
                        return (
                          <div className='mx-24' key={scope.ID}>
                            <div className='w-full sm:flex sm:justify-between'>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/location_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      {co.Name}
                                    </div>
                                    <div className='text-10'>{co.Address}</div>
                                    <div className='text-10'>
                                      {`${co.City}, ${co.State} ${co.Zip}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/email_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Email
                                    </div>
                                    <div className='text-10'>{co.Email}</div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/phone_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Phone
                                    </div>
                                    <div className='text-10'>
                                      {this.formatPhone(co.Phone)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                  checklists.push(checklist)
                })
              }
            })
          }
          if (scope.Data && scope.Data.ChecklistResults) {
            scope.Data.ChecklistResults.map(checklist => {
              const technician = _.find(users, {
                UserName: checklist.EnteredBy,
              })
              checklist.info =
                scope &&
                [scope].map(scope => {
                  const { Co, BusinessUnit, Division } = scope || {}
                  const division = _.find(divisions, {
                    Co,
                    BusinessUnit,
                    Division,
                  })
                  return (
                    <div className='w-full' key={scope.ID}>
                      <div className='w-full flex justify-between my-24'>
                        {division && division.InvoiceHeaderImg && (
                          <img
                            className='h-48 sm:h-96'
                            src={division.InvoiceHeaderImg}
                          />
                        )}
                        <span className='sm:mt-12'>
                          <label
                            className={classNames(
                              classes.invHeader,
                              'sm:pr-8 text-20 sm:text-36',
                            )}
                          >
                            CHECKLIST
                          </label>
                          <div
                            className={classNames(
                              classes.invSubHeader,
                              'sm:pr-8 text-10 sm:text-16',
                            )}
                          >
                            Work Order #{this.state.WorkOrder}-{scope.Scope}
                          </div>
                        </span>
                      </div>

                      <div className='flex mb-24 justify-between p-16 pr-4'>
                        <div className='cursor-pointer'>
                          <div className='w-full text-16 font-bold text-left'>
                            {Data.Customer.Name && Data.Customer.Name.length > 0
                              ? Data.Customer.Name
                              : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                          </div>
                          <div className='w-full text-12 text-left'>
                            {Data.Customer.Address1}
                          </div>
                          {Data.Customer.Address2 && (
                            <div className='w-full text-12 text-left'>
                              {Data.Customer.Address2}
                            </div>
                          )}
                          <div className='w-full text-12 text-left'>
                            {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                          </div>
                        </div>

                        <div>
                          <div
                            className='flex cursor-pointer'
                            onClick={this.openDatePicker}
                          >
                            <img
                              src='assets/icons/calendar_icon.png'
                              className='w-32 h-32 mr-8'
                            />
                            <div className='w-full'>
                              <div className='text-14 font-bold'>
                                {checklist.ResultID
                                  ? moment(checklist.EnteredDate).format('dddd')
                                  : moment(Date()).format('dddd')}
                              </div>
                              <div className='text-10'>
                                {checklist.ResultID
                                  ? moment(checklist.EnteredDate).format(
                                    'MMM DD YYYY',
                                  )
                                  : moment(Date()).format('MMM DD YYYY')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='flex mt-24'>
                        <div className='w-full'>
                          <div
                            className='w-full flex justify-between rounded-full mb-12'
                            style={{ backgroundColor: '#3f3f3f' }}
                          >
                            <div
                              className={classNames(
                                'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                classes.dividerHighlight,
                              )}
                            >
                              {checklist.Name}
                            </div>
                            <div
                              className={classNames(
                                'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                classes.dividerPlain,
                              )}
                            >
                              {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                            </div>
                          </div>
                        </div>
                      </div>
                      {technician && (
                        <div className='w-full flex justify-end'>
                          <div
                            className='flex mr-24 mt-4'
                            style={{ marginBottom: -28 }}
                          >
                            <img
                              src='assets/icons/person_icon.png'
                              className='w-24 h-24 mr-4'
                            />
                            <div className='w-full'>
                              <div className='text-12 font-bold mt-4'>
                                {`${technician.FirstName} ${technician.LastName}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })
              checklist.footerInfo =
                scope &&
                [scope].map(scope => {
                  const { Co } = scope || {}
                  const co = (_.find(companies, { Co }) || { Data: {} }).Data.Co
                  if (co) {
                    return (
                      <div className='mx-24' key={scope.ID}>
                        <div className='w-full sm:flex sm:justify-between'>
                          <div className='mt-24'>
                            <div className='w-full flex'>
                              <img
                                src='assets/icons/location_icon.png'
                                className='w-32 h-32 mr-8'
                              />
                              <div className='w-full'>
                                <div className='text-14 font-bold'>
                                  {co.Name}
                                </div>
                                <div className='text-10'>{co.Address}</div>
                                <div className='text-10'>
                                  {`${co.City}, ${co.State} ${co.Zip}`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-24'>
                            <div className='w-full flex'>
                              <img
                                src='assets/icons/email_icon.png'
                                className='w-32 h-32 mr-8'
                              />
                              <div className='w-full'>
                                <div className='text-14 font-bold'>Email</div>
                                <div className='text-10'>{co.Email}</div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-24'>
                            <div className='w-full flex'>
                              <img
                                src='assets/icons/phone_icon.png'
                                className='w-32 h-32 mr-8'
                              />
                              <div className='w-full'>
                                <div className='text-14 font-bold'>Phone</div>
                                <div className='text-10'>
                                  {this.formatPhone(co.Phone)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              checklists.push(checklist)
            })
          }
          if (scope.Data && scope.Data.AgreementTasks) {
            scope.Data.AgreementTasks.map(task => {
              if (task.Data.Task.Data.Units) {
                task.Data.Task.Data.Units.map(unit => {
                  unit.Data.ChecklistResults.map(checklist => {
                    checklist.info =
                      scope &&
                      [scope].map(scope => {
                        const { Co, BusinessUnit, Division } = scope || {}
                        const division = _.find(divisions, {
                          Co,
                          BusinessUnit,
                          Division,
                        })
                        const technician = _.find(users, {
                          UserName: checklist.EnteredBy,
                        })
                        return (
                          <div className='w-full' key={scope.ID}>
                            <div className='w-full flex justify-between my-24'>
                              {division && division.InvoiceHeaderImg && (
                                <img
                                  className='h-48 sm:h-96'
                                  src={division.InvoiceHeaderImg}
                                />
                              )}
                              <span className='sm:mt-12'>
                                <label
                                  className={classNames(
                                    classes.invHeader,
                                    'sm:pr-8 text-20 sm:text-36',
                                  )}
                                >
                                  CHECKLIST
                                </label>
                                <div
                                  className={classNames(
                                    classes.invSubHeader,
                                    'sm:pr-8 text-10 sm:text-16',
                                  )}
                                >
                                  Work Order #{this.state.WorkOrder}-
                                  {scope.Scope}
                                </div>
                              </span>
                            </div>

                            <div className='flex mb-24 justify-between p-16 pr-4'>
                              <div className='cursor-pointer'>
                                <div className='w-full text-16 font-bold text-left'>
                                  {Data.Customer.Name &&
                                    Data.Customer.Name.length > 0
                                    ? Data.Customer.Name
                                    : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                </div>
                                <div className='w-full text-12 text-left'>
                                  {Data.Customer.Address1}
                                </div>
                                {Data.Customer.Address2 && (
                                  <div className='w-full text-12 text-left'>
                                    {Data.Customer.Address2}
                                  </div>
                                )}
                                <div className='w-full text-12 text-left'>
                                  {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                </div>
                              </div>

                              <div>
                                <div
                                  className='flex cursor-pointer'
                                  onClick={this.openDatePicker}
                                >
                                  <img
                                    src='assets/icons/calendar_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      {checklist.ResultID
                                        ? moment(checklist.EnteredDate).format(
                                          'dddd',
                                        )
                                        : moment(Date()).format('dddd')}
                                    </div>
                                    <div className='text-10'>
                                      {checklist.ResultID
                                        ? moment(checklist.EnteredDate).format(
                                          'MMM DD YYYY',
                                        )
                                        : moment(Date()).format('MMM DD YYYY')}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='flex mt-24'>
                              <div className='w-full'>
                                <div
                                  className='w-full flex justify-between rounded-full mb-12'
                                  style={{ backgroundColor: '#3f3f3f' }}
                                >
                                  <div
                                    className={classNames(
                                      'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                      classes.dividerHighlight,
                                    )}
                                  >
                                    {checklist.Name}
                                  </div>
                                  <div
                                    className={classNames(
                                      'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                      classes.dividerPlain,
                                    )}
                                  >
                                    {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='w-full flex justify-between'>
                              <div className='text-12 text-left pl-16'>
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Task:
                                  </strong>
                                  <label>{task.Data.Task.Name}</label>
                                </div>
                                {unit.UnitType &&
                                  _.find(serviceUnitTypes, {
                                    UnitType: unit.UnitType,
                                  }) && (
                                    <div className='w-full'>
                                      <strong className='mr-8 font-bold'>
                                        Unit Type:
                                      </strong>
                                      <label>
                                        {
                                          _.find(serviceUnitTypes, {
                                            UnitType: unit.UnitType,
                                          }).Name
                                        }
                                      </label>
                                    </div>
                                  )}
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Unit ID:
                                  </strong>
                                  <label>{`${unit.UnitID} - ${unit.Name}`}</label>
                                </div>
                                {unit.Manufacturer && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Manufacturer:
                                    </strong>
                                    <label>{unit.Manufacturer}</label>
                                  </div>
                                )}
                                {unit.Model && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Model:
                                    </strong>
                                    <label>{unit.Model}</label>
                                  </div>
                                )}
                                {unit.SerialNumber && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Serial #:
                                    </strong>
                                    <label>{unit.SerialNumber}</label>
                                  </div>
                                )}
                              </div>
                              {technician && (
                                <div
                                  className='flex mr-24 mt-4'
                                  style={{ marginBottom: -28 }}
                                >
                                  <img
                                    src='assets/icons/person_icon.png'
                                    className='w-24 h-24 mr-4'
                                  />
                                  <div className='w-full'>
                                    <div className='text-12 font-bold mt-4'>
                                      {`${technician.FirstName} ${technician.LastName}`}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })
                    checklist.footerInfo =
                      scope &&
                      [scope].map(scope => {
                        const { Co } = scope || {}
                        const co = (_.find(companies, { Co }) || { Data: {} })
                          .Data.Co
                        if (co) {
                          return (
                            <div className='mx-24' key={scope.ID}>
                              <div className='w-full sm:flex sm:justify-between'>
                                <div className='mt-24'>
                                  <div className='w-full flex'>
                                    <img
                                      src='assets/icons/location_icon.png'
                                      className='w-32 h-32 mr-8'
                                    />
                                    <div className='w-full'>
                                      <div className='text-14 font-bold'>
                                        {co.Name}
                                      </div>
                                      <div className='text-10'>
                                        {co.Address}
                                      </div>
                                      <div className='text-10'>
                                        {`${co.City}, ${co.State} ${co.Zip}`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-24'>
                                  <div className='w-full flex'>
                                    <img
                                      src='assets/icons/email_icon.png'
                                      className='w-32 h-32 mr-8'
                                    />
                                    <div className='w-full'>
                                      <div className='text-14 font-bold'>
                                        Email
                                      </div>
                                      <div className='text-10'>{co.Email}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-24'>
                                  <div className='w-full flex'>
                                    <img
                                      src='assets/icons/phone_icon.png'
                                      className='w-32 h-32 mr-8'
                                    />
                                    <div className='w-full'>
                                      <div className='text-14 font-bold'>
                                        Phone
                                      </div>
                                      <div className='text-10'>
                                        {this.formatPhone(co.Phone)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })
                    checklists.push(checklist)
                  })
                })
              }
            })
          }
        }
      })
    }
    if (checklists.length > 0) {
      var checklistResults = (
        <ChecklistGenerator
          readOnly={true}
          checklists={checklists}
          onGenerate={checklistsPDF =>
            this.setState({ checklistResults: '', checklistsPDF }, () =>
              this.emailInvoice(closeAfter, confirm),
            )
          }
          fileName='Inspection Reports'
        />
      )
      window['warn']('ChecklistData: ', checklists, checklistResults)
      this.setState({ checklistResults })
    } else {
      this.setState({ checklistResults: '', checklistsPDF: null }, () =>
        this.emailInvoice(closeAfter, confirm),
      )
    }
  }

  printPDF = () => {
    const { InvoiceNumber } = this.state
    let gridElement = document.getElementById('invoice-print')
    drawDOM(gridElement, {
      keepTogether: '.prevent-split',
      paperSize: 'letter',
      margin: {
        top: `${((this.props.InvoiceSpacing.top / 330) * 11).toFixed(2)} in`,
        bottom: `${((this.props.InvoiceSpacing.bottom / 330) * 11).toFixed(
          2,
        )} in`,
        left: `${((this.props.InvoiceSpacing.left / 255) * 8.5).toFixed(2)} in`,
        right: `${((this.props.InvoiceSpacing.right / 255) * 8.5).toFixed(
          2,
        )} in`,
      },
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group)
      })
      .then(dataUri => {
        this.setState({ printPDF: dataUri, anchorEl: null })
      })
  }

  render() {
    const {
      theme,
      classes,
      invoiceDialog,
      addInvoice,
      securables,
      users,
      updateInvoice,
      taxCodes,
      removeInvoice,
      formOfPayments,
      materials,
      laborTypes,
      openEditWorkOrderUsageDialog,
      openEditWorkOrderDialog,
      openEditCustomerSiteDialog,
      openEditCustomerDialog,
      Co,
      companies,
      technicians,
      divisions,
    } = this.props
    const { trip } = invoiceDialog.props
    const inv = this.state.Data
    const { Contacts } = inv.Data
    var contact = _.find(Contacts, o => {
      return o.Data.Contact.Name === inv.BillContact
    })
    const { invoiceEmail, showWarrantyDetail, editLine } = this.state
    const assignments = inv.Data.Assignments
    const billableDetails = inv.ID
      ? inv.Data.InvoiceDetails
      : _.filter(
        inv.Data.InvoiceDetails,
        l =>
          (l.Data.Usage && l.Data.Usage.NonBillableYN !== 'Y') ||
          (l.Data.Scope && l.Data.Scope.NonBillableYN !== 'Y'),
      )
    const lines = _.flatten(
      _.map(billableDetails, _.property('Data.InvoiceLines')),
    )
    let sub = _.sumBy(lines, o => {
      return Number(o.Amount)
    })
    let tax = _.sumBy(lines, o => {
      return Number(o.TaxAmount)
    })
    const co = _.find(companies, { Co }).Data.Co
    let total = sub + tax
    let payments =
      inv.Data && inv.Data.Payments
        ? _.sumBy(inv.Data.Payments, o =>
          o.Data && o.Data.Payment && o.Data.Payment.VoidDate
            ? 0
            : o.Total || o.Amount,
        )
        : 0
    let due = total - payments
    window['warn'](
      'Invoice Data: ',
      this.state,
      inv,
      lines,
      payments,
      total,
      due,
    )
    if (trip) {
      const start = moment(trip.InProgressTime || trip.EnRouteTime)
      const end = moment(trip.CompletedTime)
      const sec = end.diff(start, 'seconds')
      trip.ActualDuration = sec / 60 / 60
    }
    const paymentTypes = {
      visa: 'Visa',
      mastercard: 'MasterCard',
      master: 'MasterCard',
      discover: 'Discover',
      american_express: 'American Express',
      amex: 'American Express',
      am_ex: 'American Express',
    }
    const scope =
      inv.Data && inv.Data.Scopes && inv.Data.Scopes.length > 0
        ? inv.Data.Scopes[0]
        : null
    const billCust = inv.Data.Customer
      ? inv.Data.Customer
      : scope
        ? scope.Data.Customer || {}
        : {}
    const agreement =
      inv.Data &&
        billableDetails &&
        billableDetails.length > 0 &&
        billableDetails[0].Data &&
        billableDetails[0].Data.Agreement
        ? billableDetails[0].Data.Agreement
        : null
    const division = scope
      ? _.find(divisions, {
        Co,
        BusinessUnit: scope.BusinessUnit,
        Division: scope.Division,
      })
      : agreement
        ? _.find(divisions, {
          Co,
          BusinessUnit: agreement.BusinessUnit,
          Division: agreement.Division,
        })
        : null
    let pmts = [...(inv.Data && inv.Data.Payments ? inv.Data.Payments : [])]

    window['warn']('Trip Info: ', trip)
    window['warn']('Edit Line Info: ', editLine)

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <React.Fragment>
            {
              // PDF Dialog
              this.state.printPDF && (
                <Dialog
                  classes={{
                    paper: classNames(
                      'mt-0 mb-0 w-full h-full',
                      !matches && 'full-screen-dialog',
                    ),
                  }}
                  open={this.state.printPDF}
                  onClose={() =>
                    this.setState({ printPDF: null, anchorEl: null })
                  }
                  maxWidth='sm'
                  fullScreen={!matches}
                >
                  <AppBar position='static' className='dialog-header'>
                    <Toolbar className='flex w-full'>
                      <Typography variant='subtitle1' color='inherit'>
                        <Icon className='mr-6 align-middle mb-2'>print</Icon>
                        Invoice.pdf
                      </Typography>
                    </Toolbar>
                    <IconButton
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: matches.medium ? 8 : 4,
                        color: 'white',
                      }}
                      onClick={() => {
                        this.setState({ printPDF: null, anchorEl: null })
                      }}
                      className='dialog-header-icon'
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </AppBar>
                  <DialogContent
                    classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}
                  >
                    <iframe
                      src={this.state.printPDF}
                      className='w-full overflow-auto'
                      style={{ height: 'calc(100% - 24px)' }}
                    />
                  </DialogContent>
                </Dialog>
              )
            }

            <div className='w-full h-full flex'>
              <Button
                variant='contained'
                color='primary'
                onClick={this.printPDF}
                className='w-full max-h-32'
              >
                <Receipt />
                &nbsp;&nbsp;Preview Invoice
              </Button>
            </div>

            {/* -------- Invoice Data -------- */}
            <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
              <div id='invoice-print' className='inv-print sm:mx-24'>
                <div className='w-full flex justify-between my-24'>
                  {this.props.InvoiceHeaderImg && (
                    <img
                      className='h-48 sm:h-96'
                      src={this.props.InvoiceHeaderImg}
                    />
                  )}
                  <span className='sm:mt-12'>
                    <label
                      className={classNames(
                        classes.invHeader,
                        'text-20 sm:text-36',
                      )}
                    >
                      INVOICE
                    </label>
                    <div
                      className={classNames(
                        classes.invSubHeader,
                        'text-10 sm:text-16',
                      )}
                    >
                      #GI595-1
                    </div>
                  </span>
                </div>

                <div className='flex mb-24 justify-between px-16'>
                  <div>
                    <div className='w-full text-16 font-bold text-left'>
                      Johnny Appleseed
                      <Icon className='cursor-pointer text-16 ml-4 no-print align-middle'>
                        contacts
                      </Icon>
                    </div>

                    <div className='w-full text-12 text-left'>
                      5468 Summer Ave
                    </div>

                    <div className='w-full text-12 text-left'>
                      Memphis, TN 38134
                    </div>
                  </div>

                  <div>
                    <div className='flex'>
                      <img
                        src='assets/icons/calendar_icon.png'
                        className='w-32 h-32 mr-8'
                      />
                      <div className='w-full'>
                        <div className='text-14 font-bold'>Monday</div>
                        <div className='text-10'>Sep 19, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex mt-24'>
                  <div className='w-full'>
                    <div
                      className='w-full flex justify-between rounded-full mb-12'
                      style={{ backgroundColor: '#3f3f3f' }}
                    >
                      <div
                        onClick={() =>
                          openEditWorkOrderDialog({
                            Co: this.state.Co,
                            WorkOrder: this.state.WorkOrder,
                          })
                        }
                        className={classNames(
                          'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                          classes.dividerHighlight,
                        )}
                      >
                        {this.state.WorkOrder
                          ? `Work Order #${this.state.WorkOrder}`
                          : 'Agreement Billing'}
                      </div>
                      <div
                        className={classNames(
                          'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                          classes.dividerPlain,
                        )}
                      >
                        {this.state.Location}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mx-24 mt-12'>
                  <div className='w-full mb-16'>
                    <div className='w-full flex justify-between'>
                      <Chip
                        classes={{
                          avatar: classes.chipAvatar,
                          label: classes.chipLabel,
                        }}
                        avatar={
                          <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                        }
                        label='Routine Maintenance'
                        className={classes.chip}
                      />
                    </div>
                    <Table className={classNames(classes.table, 'clear mb-12')}>
                      <TableHead>
                        <TableRow className='h-24'>
                          <TableCell className='w-1/6 p-0 table-cell font-bold'>
                            Qty
                          </TableCell>
                          <TableCell className='w-1/6 p-0 table-cell font-bold'>
                            UOM
                          </TableCell>
                          <TableCell className='w-1/2 table-cell p-0 font-bold'>
                            Description
                          </TableCell>
                          <TableCell className='w-1/6 text-right p-0 table-cell font-bold'>
                            Price
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover className='cursor-pointer h-24'>
                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                            {(1).toFixed(2)}
                          </TableCell>
                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                            {'EA'}
                          </TableCell>
                          <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                            {'Warranty Service - No Charge'}
                          </TableCell>
                          <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                            $0.00
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>

                <div className='w-full' style={{ marginBottom: -20 }}>
                  <div className='w-full flex justify-end rounded-full'>
                    <div
                      className={classNames(
                        'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                      )}
                    >
                      <div className='text-12 mt-4 mr-24 font-bold'>
                        Subtotal:
                      </div>
                      <div className='text-16 min-w-96 font-bold'>
                        {this.formatDollars(0)}
                      </div>
                    </div>
                  </div>
                  <div className='w-full flex justify-end rounded-full'>
                    <div
                      className={classNames(
                        'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                      )}
                    >
                      <div className='text-12 mt-4 mr-24 font-bold'>Tax:</div>
                      <div className='text-16 min-w-96 font-bold'>
                        {this.formatDollars(0)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex my-24'>
                  <div className='w-full'>
                    <div
                      className='w-full flex justify-between rounded-full'
                      style={{ backgroundColor: '#3f3f3f' }}
                    >
                      <div
                        className={classNames(
                          'w-2/3 py-8 pl-24 text-12 font-bold cursor-pointer truncate',
                          classes.dividerPlain,
                        )}
                      >
                        DUE BY Sep 21, 2022
                      </div>
                      <div
                        className={classNames(
                          'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                          classes.dividerHighlight,
                        )}
                      >
                        <div className='text-12 mt-8 mr-24 font-bold'>
                          Total:
                        </div>
                        <div className='text-20 min-w-96 font-bold'>
                          {this.formatDollars(0)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='no-break'>
                  <div className='m-24 pt-0 sm:flex'>
                    <div className='w-full mr-16'>
                      <strong style={{ marginRight: 4 }}>Notes:</strong>
                      <Icon
                        onClick={() => {
                          this.setState({ ...this.state, EditNotes: true })
                        }}
                        className='cursor-pointer text-16 ml-4 no-print align-middle'
                        style={{ marginTop: -6 }}
                      >
                        edit
                      </Icon>
                      <br />
                      {this.state.Notes}
                      {this.state.EditNotes && (
                        <TextField
                          className='mt-6'
                          label='Notes'
                          id='notes'
                          name='Notes'
                          value={this.state.Notes}
                          onChange={this.handleChange}
                          onBlur={() => {
                            this.setState(
                              { ...this.state, EditNotes: false },
                              () => {
                                const { InvoiceNumber, Notes } = this.state
                                this.setNotes(InvoiceNumber, Notes)
                              },
                            )
                          }}
                          variant='outlined'
                          multiline
                          rows={5}
                          autoFocus
                          fullWidth
                        />
                      )}
                    </div>
                    <div className='w-full sm:w-2/3 mt-24 sm:mt-0'>
                      <div className='w-full mb-6'>
                        <span>
                          <strong style={{ marginRight: 4 }}>
                            Customer Signature:
                          </strong>
                          {this.state.SignedBy}
                          <Icon
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                EditSignedBy: true,
                              })
                            }}
                            className='cursor-pointer text-16 ml-4 no-print align-middle'
                            style={{ marginTop: -6 }}
                          >
                            edit
                          </Icon>
                        </span>
                        <br />
                        {!this.state.isSigning && this.state.EditSignedBy && (
                          <TextField
                            className={classes.formControl}
                            label='Signed By'
                            id='signedBy'
                            name='SignedBy'
                            value={this.state.SignedBy}
                            onChange={this.handleChange}
                            onBlur={() => {
                              this.setState({
                                ...this.state,
                                EditSignedBy: false,
                              })
                            }}
                            variant='outlined'
                            autoFocus
                            fullWidth
                          />
                        )}
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ ...this.state, isSigning: true })
                        }}
                        style={{
                          border: '1px dotted #3f3f3f',
                          width: 'calc(100% - 2px)',
                          height: 96,
                          borderRadius: 5,
                        }}
                      >
                        {this.state.Signature && (
                          <img
                            style={{ height: 96 }}
                            src={this.state.Signature}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='m-24 mt-0'>
                    <div className={classNames('w-full mb-12')}>
                      <InvoiceAddPaymentComponent
                        due={due}
                        readOnly={false}
                        isFinanced={this.state.IsFinanced}
                        isApprovedAccount={this.state.IsApprovedAccount}
                        onIsFinancedChange={() =>
                          this.setState(prevState => ({
                            ...prevState,
                            IsFinanced: !prevState.IsFinanced,
                            IsApprovedAccount: false,
                          }))}
                        onIsApprovedAccountChange={() =>
                          this.setState(prevState => ({
                            ...prevState,
                            IsApprovedAccount: !prevState.IsApprovedAccount,
                            IsFinanced: false
                          }))}
                        onAddPaymentClick={() => {
                          if (!this.state.IsFinanced &&
                            !this.state.IsApprovedAccount) {
                            this.setState({
                              ...this.state,
                              addPayment: true,
                            })
                          }
                        }}
                      />
                      {pmts.map(pmt => {
                        const voided =
                          Boolean(pmt.VoidDate) ||
                          (pmt.Data &&
                            pmt.Data.Payment &&
                            Boolean(pmt.Data.Payment.VoidDate))
                        return (
                          <div
                            onClick={() => this.setState({ viewPayment: pmt })}
                            className='w-full flex pt-8 pb-8 text-11 font-bold relative cursor-pointer'
                          >
                            <div className='mr-16'>
                              {new Date(pmt.PaymentDateTime).toLocaleDateString(
                                'en-US',
                              )}
                            </div>
                            {pmt.Check ? (
                              <React.Fragment>
                                <div
                                  className={classNames(
                                    voided ? 'mr-6 line-through' : 'mr-16',
                                  )}
                                >{`Check #${pmt.Check}`}</div>
                              </React.Fragment>
                            ) : pmt.CardLastFour ? (
                              <React.Fragment>
                                <img
                                  src={`assets/images/cards/${pmt.PaymentMethodType}.png`}
                                  className='w-20 mr-4'
                                  style={{ height: 14 }}
                                />
                                <div
                                  className={classNames(
                                    voided ? 'mr-6 line-through' : 'mr-16',
                                  )}
                                >{`${paymentTypes[pmt.PaymentMethodType]
                                  } ending in ${pmt.CardLastFour}`}</div>
                              </React.Fragment>
                            ) : pmt.Data &&
                              pmt.Data.PaymentMethod &&
                              pmt.Data.PaymentMethod.CardLastFour ? (
                              <React.Fragment>
                                <img
                                  src={`assets/images/cards/${pmt.Data.PaymentMethod.PaymentMethodType}.png`}
                                  className='w-20 mr-4'
                                  style={{ height: 14 }}
                                />
                                <div
                                  className={classNames(
                                    voided ? 'mr-6 line-through' : 'mr-16',
                                  )}
                                >{`${paymentTypes[
                                  pmt.Data.PaymentMethod.PaymentMethodType
                                  ]
                                  } ending in ${pmt.Data.PaymentMethod.CardLastFour
                                  }`}</div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div
                                  className={classNames(
                                    voided ? 'mr-6 line-through' : 'mr-16',
                                  )}
                                >{`OTHER${pmt.Memo ? ` - ${pmt.Memo}` : ''
                                  }`}</div>
                              </React.Fragment>
                            )}
                            {voided ? (
                              <div className={classNames('mr-16')}>
                                {' '}
                                - VOIDED
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              className={classNames(
                                'pin-r absolute',
                                voided ? 'line-through' : '',
                              )}
                            >{`${this.formatDollars(
                              pmt.Amount || pmt.PaymentTotal,
                            )}`}</div>
                          </div>
                        )
                      })}

                      <div className='w-full' style={{ marginBottom: -20 }}>
                        <div className='w-full flex justify-end rounded-full'>
                          <div
                            className={classNames(
                              'w-auto rounded-full text-12 font-bold text-right flex justify-between',
                            )}
                          >
                            <div className='text-12 mt-4 mr-24 font-bold'>
                              Total Paid:
                            </div>
                            <div className='text-12 mt-4 min-w-96 font-bold'>
                              {this.formatDollars(total - due)}
                            </div>
                          </div>
                        </div>
                        <div className='w-full flex justify-end rounded-full'>
                          <div
                            className={classNames(
                              'w-auto rounded-full text-12 px-12 font-bold text-right flex justify-between text-white py-4',
                            )}
                            style={{ background: '#3f3f3f', marginRight: -12 }}
                          >
                            <div className='text-12 mr-24 font-bold'>
                              Balance Due:
                            </div>
                            <div
                              className={classNames(
                                'text-12 min-w-96 font-bold',
                              )}
                            >
                              {due > 0 ? this.formatDollars(due) : '$0.00'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full h-auto'>
                    {this.props.InvoiceDisclaimer && (
                      <div className='mx-24 mb-12'>
                        <strong style={{ marginRight: 4 }}>
                          Terms and Conditions:
                        </strong>
                        <div
                          className='font-bold text-red w-full mt-6'
                          style={{ fontSize: 9 }}
                        >
                          {this.props.InvoiceDisclaimer}
                        </div>
                      </div>
                    )}

                    <div className='mx-24'>
                      <div className='w-full sm:flex sm:justify-between'>
                        <div className='mt-24'>
                          <div className='w-full flex'>
                            <img
                              src='assets/icons/location_icon.png'
                              className='w-32 h-32 mr-8'
                            />
                            <div className='w-full'>
                              <div className='text-14 font-bold'>{co.Name}</div>
                              <div className='text-10'>{co.Address}</div>
                              <div className='text-10'>
                                {`${co.City}, ${co.State} ${co.Zip}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='mt-24'>
                          <div className='w-full flex'>
                            <img
                              src='assets/icons/email_icon.png'
                              className='w-32 h-32 mr-8'
                            />
                            <div className='w-full'>
                              <div className='text-14 font-bold'>Email</div>
                              <div className='text-10'>{co.Email}</div>
                            </div>
                          </div>
                        </div>
                        <div className='mt-24'>
                          <div className='w-full flex'>
                            <img
                              src='assets/icons/phone_icon.png'
                              className='w-32 h-32 mr-8'
                            />
                            <div className='w-full'>
                              <div className='text-14 font-bold'>Phone</div>
                              <div className='text-10'>
                                {this.formatPhone(co.Phone)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Dialog
                  open={this.state.isSigning}
                  classes={{ paper: classes.signaturePaper }}
                  fullWidth
                  maxWidth='sm'
                  onClose={() => {
                    if (this.sigPad.isEmpty()) {
                      this.setState({ ...this.state, isSigning: false })
                    }
                  }}
                >
                  <div className='w-full mb-12'>
                    <span>
                      <strong style={{ marginRight: 4 }}>
                        Customer Signature:
                      </strong>
                      {this.state.SignedBy}
                      <Icon
                        onClick={() => {
                          this.setState({ ...this.state, EditSignedBy: true })
                        }}
                        className='cursor-pointer text-16 ml-4 no-print align-middle'
                        style={{ marginTop: -6 }}
                      >
                        edit
                      </Icon>
                    </span>
                    <br />
                    {this.state.isSigning && this.state.EditSignedBy && (
                      <TextField
                        className={classes.formControl}
                        label='Signed By'
                        id='signedBy'
                        name='SignedBy'
                        value={this.state.SignedBy}
                        onChange={this.handleChange}
                        onBlur={() => {
                          this.setState({ ...this.state, EditSignedBy: false })
                        }}
                        variant='outlined'
                        autoFocus
                        fullWidth
                      />
                    )}
                  </div>
                  <div
                    className='w-full'
                    style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
                  >
                    <SignatureCanvas
                      ref={ref => {
                        this.sigPad = ref
                      }}
                      penColor='#66aef9'
                      canvasProps={{
                        className: 'sigCanvas',
                        style: { width: '100%', height: 150 },
                      }}
                    />
                  </div>
                  <div className='flex w-full mt-16'>
                    <Button
                      variant='contained'
                      className={classes.clearBtn}
                      fullWidth
                      onClick={this.clear}
                    >
                      Clear
                    </Button>
                    <div className='min-w-12'></div>
                    <Button
                      variant='contained'
                      className={classes.okBtn}
                      fullWidth
                      onClick={this.trim}
                    >
                      OK
                    </Button>
                  </div>
                </Dialog>
                {/*division && division.InvoiceFooterImg &&
                                    <img className="w-full" src={division.InvoiceFooterImg} />
                                */}
              </div>
            </div>
          </React.Fragment>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openEditCustomerSiteDialog,
      openEditWorkOrderDialog,
      openEditWorkOrderUsageDialog,
      updateTrip,
      openEditCustomerDialog,
      openEditEstimateDialog,
    },
    dispatch,
  )
}

function mapStateToProps({
  invoicesApp,
  workOrdersApp,
  spReducers,
  emailTemplateApp,
}) {
  return {
    invoiceDialog: invoicesApp.invoices.invoiceDialog,
    workOrderDialog: workOrdersApp.workOrders.workOrderDialog,
    companies: spReducers.companies.List,
    Co: spReducers.companies.Co,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    technicians: spReducers.technicians,
    templates: emailTemplateApp.templates.Templates,
    formOfPayments: spReducers.formOfPayments,
    User: spReducers.userProfiles.User,
    Users: spReducers.userProfiles.Users,
    services: spReducers.services,
    securables: spReducers.userProfiles.User.Data.Securables,
    users: spReducers.userProfiles.Users,
    notes: spReducers.invoices.notes,
    serviceUnitTypes: spReducers.serviceUnitTypes,
    materials: spReducers.materials.materials,
    laborTypes: spReducers.laborTypes,
    taxCodes: spReducers.taxCodes,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(InvoicePlaceholder),
)
