import _ from '@lodash';
import { Button, Chip, Avatar, Icon, Table, TableCell, TableFooter, TableRow, TextField, Typography, IconButton, Dialog, DialogContent, DialogTitle, MenuItem, InputAdornment, CardHeader, Divider, Menu, FormControlLabel, Switch, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CheckCircleOutline } from '@material-ui/icons';
import classNames from 'classnames';
import { impress } from 'main/content/compression/impress';
import parse from 'html-react-parser';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';



const drawerWidth = 240;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: theme.spacing(7) + 1,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    header: {

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    updateMember: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteMember: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#333',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#333',
            color: '#fff',
        },
    },
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bgSecondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    bgGrey: {
        backgroundColor: '#3f3f3f',
    },
    error: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.error[400],
            color: '#fff',
        },
        "&:active": {
            backgroundColor: theme.palette.error[600],
            color: '#fff',
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 300,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    drawerBadge: {
        height: 16,
        minWidth: 16,
        fontSize: '1rem',
        top: 4,
        right: -2
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
            'rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)',
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    doneIcon: {
        color: theme.palette.secondary.main,
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deleteIcon: {
        color: '#fff',
        textShadow: '0px 0px 2px #3f3f3f',
    },
    deletingIcon: {
        color: theme.palette.error[500],
        textShadow: '0px 0px 2px #3f3f3f',
    },
    closeIcon: {
        textShadow: '0px 0px 2px #3f3f3f',
    },
    blurBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        filter: 'blur(8px)',
        '-webkit-filter': 'blur(8px)',
        transform: 'scale(1.1)',
        '-webkit-transform': 'scale(1.1)',
    },
    fitBG: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    savings: {
        top: 0,
        right: 0,
        position: 'absolute',
        width: 72,
        padding: 4,
        paddingTop: 6,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 'auto',
        borderBottomLeftRadius: 6,
        borderTopRightRadius: 6,
        lineHeight: '9px',
        textAlign: 'center',
        // marginTop: 44,
    },
    selectedSavings: {
        top: 24,
        right: 0,
        position: 'absolute',
        width: 72,
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: '#3f3f3f',
        color: '#fff',
        margin: 'auto',
        borderBottomLeftRadius: 6,
        lineHeight: '9px',
        textAlign: 'center',
        // marginTop: 44,
    },
    chip: {
        color: '#fff',
        backgroundColor: '#3f3f3f',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 0,
        marginBottom: 12,
        height: 24,
    },
    chipLabel: {
        paddingLeft: 8,
        fontWeight: 'bold',
        color: '#fff',
        paddingBottom: 2,
    },
    chipAvatar: {
        marginLeft: '0px !important',
        color: '#3f3f3f',
        backgroundColor: '#3f3f3f',
        border: '3px solid #3f3f3f',
        width: '32px !important',
        height: '32px !important',
    },
    chipAvatarIcon: {
        color: '#3f3f3f',
        backgroundColor: '#fff',
    },
    qty: {
        backgroundColor: '#3f3f3f',
        height: 18,
        minWidth: 18,
        fontSize: 13,
        paddingLeft: 4,
        paddingRight: 4,
        marginRight: 6,
        marginTop: 3,
        // width: 24,
        float: 'left',
        borderRadius: 12,
        color: '#fff',
        textAlign: 'center'
    },
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
    },
    deleteButton: {
        background: theme.palette.error[500],
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover': {
            background: theme.palette.error[600],
        },
        '&:active': {
            background: theme.palette.error[600],
        },
    },
    discountDialog: {
        margin: 24,
        backgroundSize: 'cover',
        backgroundImage: 'url(assets/images/backgrounds/SP_Header_Dark.png)',
        backgroundPosition: 'center'
    },
    selectedPricing: {
        left: -4,
        top: 16,
        position: 'absolute',
        color: theme.palette.secondary.main
    }
});

var newOptionState = {
    ID: null,
    Co: null,
    BusinessUnit: null,
    Division: null,
    OptionBoard: null,
    Option: null,
    Name: null,
    Description: null,
    Notes: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    PriceTotal: 0.00,
    AttachmentID: null,
    SavingsType: "M",
    Data: {
        Components: [],
        ErrMsg: null,
    },
    addDiscount: false,
    DiscountType: null,
    DiscountAmt: null,
    DiscountTotal: null,
    discountAmt: null,
    anchorEl: null,
    priceView: {
        standardPriceView: true,
        memberPriceView: true,
        premiumPriceView: true
    }
}

class CatalogOptionBoardOption extends Component {

    state = { ...newOptionState };

    handleTabChange = (event, value) => {
        this.setState({ ...this.state, value });
    };

    componentDidMount() {
        const { data } = this.props;

        this.setState({ ...this.state, ...data });
    }

    componentDidUpdate = (prevProps) => {
        const { data } = this.props;

        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ ...this.state, ...data });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    selectOption = () => {
        this.props.onSelected(this.state);
    }

    editOption = (e) => {
        e.stopPropagation();
        this.props.onEdit(this.state);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    saveOption = () => {
        this.props.onChange(this.state);
    }

    calculateTax = (amt) => {
        let total = 0;
        const { Co, BusinessUnit, Division } = this.state;
        const { Site, IncludeTaxYN } = this.props;
        if (Site && IncludeTaxYN === 'Y') {
            let { TaxCode } = Site;
            if (!TaxCode) {
                const div = _.find(this.props.divisions, { Co, BusinessUnit, Division });
                if (div && div.DefaultTaxCode) {
                    TaxCode = div.DefaultTaxCode;
                } else {
                    const bu = _.find(this.props.businessUnits, { Co, BusinessUnit });
                    if (bu && bu.DefaultTaxCode) {
                        TaxCode = bu.DefaultTaxCode;
                    }
                }
            }
            if (TaxCode) {
                const { taxCodes } = this.props;
                const taxCode = _.find(taxCodes, { TaxCode });
                total = amt * (taxCode ? new Date(taxCode.EffectiveDate) <= new Date() ? (taxCode.NewRate / 100) : (taxCode.OldRate / 100) : 0);
            }
        }
        return total;
    }

    addDiscount = (e, subtotal) => {
        e.stopPropagation();
        const { DiscountType, discountAmt, Option } = this.state;
        const { optionBoard } = this.props;
        let discountTotal = 0;
        if (DiscountType === 'P') {
            discountTotal = subtotal * (discountAmt / 100)
        } else {
            discountTotal = discountAmt
        }

        const optionIndex = _.findIndex(optionBoard.Data.Options, { Option: Option });
        optionBoard.Data.Options[optionIndex].DiscountAmt = discountAmt;
        optionBoard.Data.Options[optionIndex].DiscountType = DiscountType;
        optionBoard.Data.Options[optionIndex].DiscountTotal = discountTotal;

        window["warn"]("discount subtotal catalog", subtotal, optionBoard, Option)

        this.setState({ ...this.state, DiscountAmt: discountAmt, DiscountTotal: discountTotal, addDiscount: null }, () => this.props.updateEditEstimateDialog({ ...this.props.parentState, DiscountAmt: discountAmt, DiscountTotal: discountTotal, addDiscount: null, Data: { ...this.props.parentState.Data, OptionBoard: optionBoard ? { ...optionBoard } : null } }))
    }

    deleteDiscount = (e) => {
        e.stopPropagation();
        const { Option } = this.state;
        const { optionBoard } = this.props;

        const optionIndex = _.findIndex(optionBoard.Data.Options, { Option: Option });
        optionBoard.Data.Options[optionIndex].DiscountAmt = null;
        optionBoard.Data.Options[optionIndex].DiscountType = null;
        optionBoard.Data.Options[optionIndex].DiscountTotal = null;

        this.setState({ ...this.state, DiscountType: null, DiscountAmt: null, DiscountTotal: null, discountAmt: null, addDiscount: null }, () => this.props.updateEditEstimateDialog({ ...this.props.parentState, DiscountType: null, DiscountAmt: null, DiscountTotal: null, discountAmt: null, addDiscount: false, Data: { ...this.props.parentState.Data, OptionBoard: optionBoard ? { ...optionBoard } : null } }))
    }

    // This toggles the prices view state
    handleSwitchToggle = (price) => {
        const { priceView } = this.state;
        priceView[`${price}View`] = !priceView[`${price}View`];
        this.setState({priceView});
    }
    handleOpenMenu = (e) => {
        this.setState({anchorEl: e.currentTarget})
    }
    handleCloseMenu = () => {
        this.setState({anchorEl: null})
    }

    render() {
        const { classes, selected, recommended, optionBoard, isMember, matches, SelectedPricing } = this.props;
        const pricing = { "S": "Standard", "M": "Member", "P": "Premium" };
        const components = _.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y');
        var savingsType = (this.state.SavingsType || optionBoard.DefaultSavingsType || "M");
        const selectedPrice = _.sumBy(components, (o) => o.Price);
        const normalPrice = _.sumBy(components, (o) => !o.Data.Assembly ? o.CustomPrice ? (o.Qty * o.CustomPrice) : o.Price : (o.Qty * (o.CustomPrice || (o.AddonYN === 'Y' ? o.Data.Assembly.AddOnPrice : o.Data.Assembly.Price))));
        let memberPrice = _.sumBy(components, (o) => !o.Data.Assembly ? o.CustomMemberPrice ? (o.Qty * o.CustomMemberPrice) : o.CustomPrice ? (o.Qty * o.CustomPrice) : o.Price : (o.Qty * (o.CustomMemberPrice || (o.AddonYN === 'Y' ? o.Data.Assembly.AddOnMemberPrice : o.Data.Assembly.MemberPrice))));
        let premiumPrice = _.sumBy(components, (o) => !o.Data.Assembly ? o.CustomPrice ? (o.Qty * o.CustomPrice) : o.Price : (o.Qty * (o.AddonYN === 'Y' ? (o.Data.Assembly.PremiumAddOnPrice || o.Data.Assembly.AddOnPrice || o.Data.Assembly.CustomPrice || o.Data.Assembly.Price) : (o.Data.Assembly.PremiumPrice || o.Data.Assembly.CustomPrice || o.Data.Assembly.Price))));
        const justifyContent = Object.values(this.state.priceView).filter((o) => o === true).length === 3 ? 'space-between' : 'center';
        let price = selectedPrice;
        const savings = (normalPrice - memberPrice);
        const prc = this.formatDollars(price);

        let tax = 0.00
        let total = 0.00

        if (this.state.DiscountTotal) {
            let discountTotal = 0;
            if (this.state.DiscountType === 'P') {
                discountTotal = price * (this.state.DiscountAmt / 100)
            } else {
                discountTotal = this.state.DiscountAmt
            }

            tax = this.calculateTax(price - discountTotal);
            total = price - discountTotal + tax;
        } else {
            tax = this.calculateTax(price)
            total = price + tax;
        }

        window["warn"](`Option #${this.state.Option}`, this.state, isMember, normalPrice, memberPrice, premiumPrice, SelectedPricing);
        return (
            <div className="mb-24">
                <div className="rounded-6 p-12 relative" style={{ border: '1px solid #f1f1f1' }}>
                    {(selected || recommended) &&
                        <div className={classNames("w-full text-center py-4 pin-t pin-l pin-r absolute rounded-t", classes.blackButton)}><span className={classNames("font-bold")}>Recommended</span></div>
                    }
                    <Chip
                        classes={{ root: (selected || recommended) ? "mt-40" : "mt-8", avatar: classes.chipAvatar, label: classes.chipLabel, }}
                        avatar={<Avatar src="assets/images/logos/SP_Cog_Gradient.png" />}
                        label={this.state.Name}
                        className={classNames(classes.chip, "w-auto min-w-128 justify-start")}
                    />
                    <div className={classNames((selected || recommended) ? classes.selectedSavings : classes.savings, SelectedPricing !== "M" && "py-6")} style={{ width: 84 }}>
                        <span className={`${SelectedPricing === "M" ? "text-14" : "text-12"} font-700`}>{SelectedPricing === "M" ? this.formatDollars(savings).split('.')[0] : pricing[SelectedPricing || "S"]}</span><br />
                        {SelectedPricing === "M" &&
                            <span style={{ fontSize: 8 }}>Member Savings</span>
                        }
                    </div>

                    <div><strong className="mr-4 mb-4">Description of Work:</strong><br />{this.state.Description}
                        {this.state.Data && this.state.Data.Components &&
                            <div className="w-full mt-8">
                                {
                                    //Basic Detail
                                    this.state.ComponentDetail === "B" &&
                                    <ul>
                                        {_.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y').map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, Qty, UOM } = value;
                                            const assembly = value.Data.Assembly;
                                            if (assembly) {
                                                return (
                                                    <li onClick={() => { if (this.props.onSelectedComponent) { this.props.onSelectedComponent(assembly) } }}>{`${Qty} - ${value.Name || assembly.Name}`}</li>
                                                );
                                            }
                                            if (!assembly) {
                                                var item = value;
                                                const { ImageID, CustomImgURL } = item;
                                                return (
                                                    <li className="cursor-pointer">{`${value.Qty} ${value.UOM} - ${value.Name || item.Description || ''}`}</li>
                                                );
                                            }
                                        })
                                        }
                                    </ul>
                                }
                                {
                                    //Basic Detail
                                    this.state.ComponentDetail === "S" &&
                                    <div>
                                        {_.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y').map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, Qty, UOM } = value;
                                            const assembly = value.Data.Assembly;
                                            if (assembly) {
                                                if (assembly.Data && assembly.Data.Images) {
                                                    assembly.Data.Image = _.find(assembly.Data.Images, { PrimaryYN: 'Y' });
                                                    if (!assembly.Data.Image && assembly.Data.Images.length > 0) {
                                                        assembly.Data.Image = assembly.Data.Images[0];
                                                    }
                                                }
                                                return (
                                                    <div onClick={() => { if (this.props.onSelectedComponent) { this.props.onSelectedComponent(assembly) } }} className="prevent-split flex mt-8 pt-6" style={{ borderTop: '1px solid #f1f1f1' }}>
                                                        <div className="w-1/6 pt-6 pr-6">
                                                            <img alt={assembly.Code} crossOrigin="Anonymous" className="w-full component-img" src={assembly.Data.Image ? `${window["apiLocation"]}/api/AssemblyImage?ID=${assembly.Data.Image.ID}` : 'assets/images/logos/SP_Cog_Gradient.png'} />
                                                        </div>
                                                        <div className="w-full">
                                                            <Typography className="w-full font-bold text-16">
                                                                {/* <div className={classNames(classes.qty)}>{value.Qty}</div> */}
                                                                {value.Name || assembly.Name}
                                                                {value.AddonYN === 'Y' &&
                                                                    <Typography color="secondary" className="font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest">ADD-ON</Typography>
                                                                }
                                                            </Typography>
                                                            <Typography className="clear" variant="caption">{parse(value.Description || assembly.Description)}</Typography>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (!assembly) {
                                                var item = value;
                                                const { ImageID, CustomImgURL } = item;
                                                return (
                                                    <div className="prevent-split flex mt-8 pt-6 cursor-pointer" style={{ borderTop: '1px solid #f1f1f1' }}>
                                                        <div className="w-1/6 pt-6 pr-6">
                                                            <img alt={value.Name} crossOrigin="Anonymous" className="w-full component-img" src={CustomImgURL || 'assets/images/logos/SP_Cog_Gradient.png'} />
                                                        </div>
                                                        <div className="w-full">
                                                            <Typography className="w-full font-bold text-16">
                                                                {/* <div className={classNames(classes.qty)}>{`${value.Qty} ${value.UOM}`}</div> */}
                                                                {value.Name || item.Description}
                                                                {value.AddonYN === 'Y' &&
                                                                    <Typography color="secondary" className="font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest">ADD-ON</Typography>
                                                                }
                                                            </Typography>
                                                            <Typography className="clear" variant="caption">{parse(value.Description || item.Description || '')}</Typography>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                }
                                {
                                    //Basic Detail
                                    this.state.ComponentDetail === "F" &&
                                    <div>
                                        {_.filter(this.state.Data.Components, (o) => o.DeleteYN !== 'Y').map((value, index) => {
                                            const { Co, BusinessUnit, Division, Assembly, Qty, UOM } = value;
                                            const assembly = value.Data.Assembly;
                                            if (assembly) {
                                                if (assembly.Data && assembly.Data.Images) {
                                                    assembly.Data.Image = _.find(assembly.Data.Images, { PrimaryYN: 'Y' });
                                                    if (!assembly.Data.Image && assembly.Data.Images.length > 0) {
                                                        assembly.Data.Image = assembly.Data.Images[0];
                                                    }
                                                }
                                                const o = value;
                                                const assemblyNormalPrice = value.Qty * (value.CustomPrice || (value.AddonYN === 'Y' ? assembly.AddOnPrice : assembly.Price));
                                                let assemblyMemberPrice = value.Qty * (value.CustomMemberPrice || (value.AddonYN === 'Y' ? assembly.AddOnMemberPrice : assembly.MemberPrice));
                                                let assemblyPremiumPrice = value.Qty * (value.AddonYN === 'Y' ? (assembly.PremiumAddOnPrice || assembly.AddOnPrice || assembly.CustomPrice || assembly.Price) : (assembly.PremiumPrice || assembly.CustomPrice || assembly.Price));
                                                let assemblyPrice = (!SelectedPricing || SelectedPricing === "R" || SelectedPricing === "S" ? assemblyNormalPrice : SelectedPricing === "M" ? assemblyMemberPrice : SelectedPricing === "P" ? assemblyPremiumPrice : assemblyNormalPrice);
                                                window["warn"]('Component Details: ', value, assembly, assemblyNormalPrice, assemblyMemberPrice, assemblyPremiumPrice, assemblyPrice)
                                                return (
                                                    <div onClick={() => { if (this.props.onSelectedComponent) { this.props.onSelectedComponent(assembly) } }} className="prevent-split flex mt-8 pt-6" style={{ borderTop: '1px solid #f1f1f1' }}>
                                                        <div className="w-1/6 pt-6 pr-6">
                                                            <img alt={assembly.Code} crossOrigin="Anonymous" className="w-full component-img" src={assembly.Data.Image ? `${window["apiLocation"]}/api/AssemblyImage?ID=${assembly.Data.Image.ID}` : 'assets/images/logos/SP_Cog_Gradient.png'} />
                                                        </div>
                                                        <div className="w-full">
                                                            <Typography className="w-full font-bold text-16">
                                                                <div className={classNames(classes.qty)}>{value.Qty} EA</div>
                                                                {value.Name || assembly.Name}
                                                                {value.AddonYN === 'Y' &&
                                                                    <Typography color="secondary" className="font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest">ADD-ON</Typography>
                                                                }
                                                                {assembly.Code &&
                                                                    <Typography color="secondary" className="font-bold text-12 mt-4 float-right">{assembly.Code}</Typography>
                                                                }
                                                            </Typography>
                                                            <Typography className="clear" variant="caption">{parse(value.Description || assembly.Description)}</Typography>
                                                            <Typography className="font-bold text-12 my-12 relative">
                                                                <Typography className="font-bold text-12 float-right">{this.formatDollars(assemblyPrice)}</Typography>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (!assembly) {
                                                var item = value;
                                                const { ImageID, CustomImgURL } = item;
                                                return (
                                                    <div className="prevent-split flex mt-8 pt-6 cursor-pointer" style={{ borderTop: '1px solid #f1f1f1' }}>
                                                        <div className="w-1/6 pt-6 pr-6">
                                                            <img alt={value.Name} crossOrigin="Anonymous" className="w-full component-img" src={CustomImgURL || 'assets/images/logos/SP_Cog_Gradient.png'} />
                                                        </div>
                                                        <div className="w-full">
                                                            <Typography className="w-full font-bold text-16">
                                                                <div className={classNames(classes.qty)}>{`${value.Qty} ${value.UOM}`}</div>
                                                                {value.Name || item.Description}
                                                                {value.AddonYN === 'Y' &&
                                                                    <Typography color="secondary" className="font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest">ADD-ON</Typography>
                                                                }
                                                                <Typography color="secondary" className="font-bold text-12 mt-4 float-right">{value.Material}</Typography>
                                                            </Typography>
                                                            <Typography className="clear" variant="caption">{parse(value.Description || item.Description || '')}</Typography>
                                                            <Typography className="font-bold text-12 my-12 relative">
                                                                <Typography className="font-bold text-12 float-right">{this.formatDollars(value.Price)}</Typography>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                        }
                                    </div>
                                }
                                <Divider className="my-12" />
                                <div className="mt-12">
                                    {prc &&
                                    <div>
                                        <Card className='flex text-center sm:px-36' style={{justifyContent: justifyContent}} onClick={this.handleOpenMenu}>
                                            
                                            <CardHeader
                                                classes={{ root: "relative cursor-pointer", title: "font-bold text-12 mt-2", subheader: "text-grey-darker text-12", avatar: classes.selectedPricing }}
                                                avatar={SelectedPricing === 'S' ? <CheckCircleOutline color="secondary" className="text-18" /> : null}
                                                style={{display: this.state.priceView.standardPriceView === true || SelectedPricing === 'S' ? 'flex' : 'none'}}
                                                title="Standard Price"
                                                subheader={this.formatDollars(normalPrice)}
                                                // onClick={this.handleOpenMenu}
                                            />
                                            <CardHeader
                                                classes={{ root: "relative cursor-pointer", title: "font-bold text-12 mt-2", subheader: "text-grey-darker text-12", avatar: classes.selectedPricing }}
                                                avatar={SelectedPricing === 'M' ? <CheckCircleOutline color="secondary" className="text-18" /> : null}
                                                style={{display: this.state.priceView.memberPriceView === true || SelectedPricing === 'M' ? 'flex' : 'none'}}
                                                title="Member Price"
                                                subheader={this.formatDollars(memberPrice)}
                                                // onClick={this.handleOpenMenu}
                                            />
                                            <CardHeader
                                                classes={{ root: "relative cursor-pointer", title: "font-bold text-12 mt-2", subheader: "text-grey-darker text-12", avatar: classes.selectedPricing }}
                                                avatar={SelectedPricing === 'P' ? <CheckCircleOutline color="secondary" className="text-18" /> : null}
                                                style={{display: this.state.priceView.premiumPriceView === true || SelectedPricing === 'P' ? 'flex' : 'none'}}
                                                title="Premium Price"
                                                subheader={this.formatDollars(premiumPrice)}
                                                // onClick={this.handleOpenMenu}
                                            />
                                        </Card>
                                            <Menu
                                            classes={{ list: classes.list }}
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleCloseMenu}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }} 
                                            >
                                                <div className='flex justify-start items-center' style={{background: '#4DBCE9', height: 40, paddingLeft: 16, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                                                    <p className='text-white text-15'>Pricing Visibility</p>
                                                    <Icon style={{ marginLeft: 5, marginTop: 3, color: 'white' }}>visibility</Icon>
                                                </div>
                                                <MenuItem>
                                                    <FormControlLabel
                                                        label="Standard Price"
                                                        control={
                                                            <Switch
                                                                onClick={() => this.handleSwitchToggle('standardPrice')}
                                                                checked={this.state.priceView.standardPriceView}
                                                                disabled={SelectedPricing === 'S' ? true : false}
                                                                color="primary"
                                                             />
                                                        }
                                                    />
                                                </MenuItem>
                                                <MenuItem>
                                                    <FormControlLabel
                                                        label="Member Price"
                                                        control={
                                                            <Switch
                                                                onClick={() => this.handleSwitchToggle('memberPrice')}
                                                                checked={this.state.priceView.memberPriceView}
                                                                disabled={SelectedPricing === 'M' ? true : false}
                                                                color="primary"
                                                             />
                                                        }
                                                    />
                                                </MenuItem>
                                                <MenuItem>
                                                    <FormControlLabel
                                                        label="Premium price"
                                                        control={
                                                            <Switch
                                                                onClick={() => this.handleSwitchToggle('premiumPrice')}
                                                                checked={this.state.priceView.premiumPriceView}
                                                                disabled={SelectedPricing === 'P' ? true : false}
                                                                color="primary"
                                                             />
                                                        }
                                                    />
                                                </MenuItem>
                                            </Menu>
                                        
                                        </div>
                                    }
                                    <Table>
                                        <TableFooter className="mt-24">
                                            <TableRow className="h-24">
                                                <TableCell className="w-1/6 p-0 table-cell font-bold text-red" style={{ borderBottom: 'none' }}>Subtotal</TableCell>
                                                <TableCell className="w-1/6 p-0 table-cell font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                <TableCell className="w-1/2 table-cell p-0 font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                <TableCell className="w-1/6 text-right p-0 table-cell font-bold text-red text-16" style={{ paddingRight: 0, borderBottom: 'none' }}>{prc}</TableCell>
                                            </TableRow>
                                            {this.state.DiscountAmt && this.state.DiscountType && this.state.DiscountTotal &&
                                                <TableRow className="h-24">
                                                    <TableCell className="w-1/6 p-0 table-cell font-bold border-b-0">Discount{this.state.DiscountType === 'P' ? ` (${this.state.DiscountAmt}%)` : ''}</TableCell>
                                                    <TableCell className="w-1/6 p-0 table-cell font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                    <TableCell className="w-1/2 table-cell p-0 font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                    <TableCell className="w-1/6 text-right p-0 table-cell border-b-0 cursor-pointer" style={{ paddingRight: 0 }} onClick={() => { if (this.props.source !== "dialog") { this.setState({ ...this.state, addDiscount: true }) } }}>
                                                        <div className="w-full">
                                                            {this.props.source !== "dialog" &&
                                                                <Icon className="cursor-pointer text-14 mt-4 mr-4 align-middle float-right no-print" style={{ marginRight: -16 }} color="action">edit</Icon>
                                                            }
                                                            <span className="font-bold border-b-0 float-right">{`-${this.formatDollars(this.state.DiscountTotal)}`}</span>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {this.props.IncludeTaxYN === "Y" &&
                                                <React.Fragment>
                                                    <TableRow className="h-24">
                                                        <TableCell className="w-1/6 p-0 table-cell font-bold text-red border-b-0">Tax</TableCell>
                                                        <TableCell className="w-1/6 p-0 table-cell font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                        <TableCell className="w-1/2 table-cell p-0 font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                        <TableCell className="w-1/6 text-right p-0 table-cell font-bold text-red border-b-0">{this.formatDollars(tax)}</TableCell>
                                                    </TableRow>
                                                    <TableRow className="h-24">
                                                        <TableCell className="w-1/6 p-0 table-cell font-bold text-red border-b-0">Total</TableCell>
                                                        <TableCell className="w-1/6 p-0 table-cell font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                        <TableCell className="w-1/2 table-cell p-0 font-bold" style={{ borderBottom: 'none' }}></TableCell>
                                                        <TableCell className="w-1/6 text-right p-0 table-cell font-bold text-red border-b-0">{this.formatDollars(total)}</TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                            {!this.state.DiscountTotal && this.props.canAddDiscount &&
                                                <TableRow className="h-24">
                                                    <TableCell className="w-1/6 p-0 table-cell font-bold text-red border-b-0"></TableCell>
                                                    <TableCell className="w-1/6 p-0 table-cell font-bold border-b-0"></TableCell>
                                                    <TableCell className="w-1/2 table-cell p-0 font-bold border-b-0"></TableCell>
                                                    <TableCell className="w-1/6 text-right p-0 table-cell border-b-0 cursor-pointer" style={{ paddingRight: 0 }} onClick={() => { this.setState({ ...this.state, addDiscount: true }) }}>

                                                        <div className="w-full">
                                                            <strong className="ml-4 text-12 float-right" style={{ color: 'black' }}>Add Discount</strong>
                                                            <Icon className="cursor-pointer text-14 ml-4 mt-4 align-middle float-right" color="primary">add_circle_outline</Icon>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableFooter>
                                    </Table>
                                </div>
                                {this.props.onSelected &&
                                    <Button
                                        variant="contained"
                                        className="w-full no-print mt-16"
                                        color="primary"
                                        onClick={() => this.props.onSelected(this.state)}
                                    >
                                        Select Option
                                    </Button>
                                }
                                {this.state.addDiscount &&
                                    <Dialog
                                        classes={{ paper: classNames(classes.discountDialog, "w-full rounded-lg p-24", !matches ? "m-0 rounded-none full-screen-dialog" : "") }}
                                        open={this.state.addDiscount}
                                        onClose={() => this.setState({ addDiscount: null, anchorEl: null })}
                                        maxWidth="sm"
                                        fullScreen={!matches}
                                    >
                                        <div className="dialog-header">
                                            <DialogTitle classes={{ root: "text-18 p-0" }} id="confirmation-dialog-title"><Icon className="mr-8 align-middle" style={{ marginBottom: 2 }} color="primary">add_circle_outline</Icon>{!this.state.DiscountTotal ? "Add" : "Update"} Discount</DialogTitle>
                                            <IconButton
                                                style={{
                                                    position: 'absolute',
                                                    right: 10,
                                                    top: 8,
                                                    color: '#333'
                                                }}
                                                onClick={() => {
                                                    this.setState({ addDiscount: null, anchorEl: null });
                                                }}
                                                className="dialog-header-icon"
                                            >
                                                <Icon>close</Icon>
                                            </IconButton>
                                        </div>
                                        <DialogContent classes={{ root: "p-0 py-8" }}>
                                            <div className={classNames("w-full")}>
                                                <TextField
                                                    className="w-full bg-white mb-12"
                                                    label="Discount Type"
                                                    id="type"
                                                    name="DiscountType"
                                                    variant="outlined"
                                                    value={this.state.DiscountType || ''}
                                                    onChange={(e) => this.setState({ ...this.state, DiscountType: e.target.value, DiscountAmt: null })}
                                                    margin="dense"
                                                    InputLabelProps={{
                                                        style: {
                                                            textShadow: '0px 0px 4px white'
                                                        }
                                                    }}
                                                    select
                                                >
                                                    <MenuItem value="P">
                                                        Percentage
                                                    </MenuItem>
                                                    <MenuItem value="F">
                                                        Fixed
                                                    </MenuItem>
                                                </TextField>
                                                {this.state.DiscountType &&
                                                    <TextField
                                                        className="w-full bg-white mb-12"
                                                        label="Discount Amount"
                                                        id="discount-amt"
                                                        name="DiscountAmt"
                                                        value={this.state.discountAmt || this.state.DiscountAmt || ''}
                                                        onChange={(e) => this.setState({ ...this.state, discountAmt: e.target.value })}
                                                        InputProps={{
                                                            style: {
                                                                textShadow: '0px 0px 4px white'
                                                            },
                                                            startAdornment: this.state.DiscountType === 'F' ? <InputAdornment position="start">$</InputAdornment> : null,
                                                            endAdornment: this.state.DiscountType === 'P' ? <InputAdornment position="end">%</InputAdornment> : null,
                                                            type: "number",
                                                            inputProps: { min: 0, max: this.state.DiscountType === 'P' ? 100 : (price - 1) }
                                                        }}
                                                        variant="outlined"
                                                        margin="dense"
                                                        required
                                                        fullWidth
                                                    />
                                                }
                                                {this.state.DiscountType && (this.state.discountAmt || this.state.DiscountAmt) &&
                                                    <div className="w-full flex">
                                                        <Button
                                                            variant="contained"
                                                            className={classNames("w-full", this.state.DiscountTotal ? "rounded-r-none" : "")}
                                                            color={"primary"}
                                                            onClick={(e) => this.addDiscount(e, price)}
                                                        //disabled={(this.state.PaymentType === "CHECK" && !this.state.Check) || (this.state.PaymentType !== "CHECK" && !this.state.Memo)}
                                                        >
                                                            {!this.state.DiscountTotal ? "Add" : "Update"} Discount
                                                        </Button>
                                                        {Boolean(this.state.DiscountTotal) &&
                                                            <Button
                                                                variant="contained"
                                                                className={classNames(classes.deleteButton, "w-64 rounded-l-none")}
                                                                color={"primary"}
                                                                onClick={(e) => this.deleteDiscount(e)}
                                                            //disabled={(this.state.PaymentType === "CHECK" && !this.state.Check) || (this.state.PaymentType !== "CHECK" && !this.state.Memo)}
                                                            >
                                                                <Icon>delete</Icon>
                                                            </Button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                }
                            </div>
                        }
                        {/* {
                            //Basic Detail
                            this.state.ComponentDetail === "C" &&
                            <ul className="prevent-split">
                                <EstimateFeatures estimate={{ ...this.state }} details={this.state.Data.Features} />
                            </ul>
                        } */}
                    </div>
                </div>
            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        impress,
        updateEstimate: Actions.updateEstimate,
        updateEditEstimateDialog: Actions.updateEditEstimateDialog,
    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        taxCodes: spReducers.taxCodes,
        divisions: spReducers.divisions,
        businessUnits: spReducers.businessUnits,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CatalogOptionBoardOption));
