import { FuseAnimate, FuseUtils } from '@fuse'
import {
  Avatar,
  Button,
  Checkbox,
  Fab,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Parser } from 'json2csv'
import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import { bindActionCreators } from 'redux'
import { updateTableSettings } from '../users/store/actions'
import * as Actions from './store/actions'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

function LoadingDiv() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .65)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        className='loader stretchBar'
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
        }}
      >
        <div className='rect1'></div>
        <div className='rect2'></div>
        <div className='rect3'></div>
        <div className='rect4'></div>
        <div className='rect5'></div>
        <br />
        <img
          style={{ width: 80, marginTop: 15 }}
          src='assets/images/splash/SPLoading.png'
        />
      </div>
    </div>
  )
}

function NoData() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .25)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
          paddingTop: 75,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        No Records Found
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    maxHeight: 'calc(100vh - 256px)',
    paddingBottom: 0,
  },
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  labels: {},
  techAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
})

const initialState = {
  loading: false,
  purchaseOrderData: [],
  selectedPurchaseOrderIds: [],
}

class PurchaseOrdersList extends Component {
  state = {
    ...initialState,
  }

  componentDidMount() {
    const {
      loading,
      purchaseOrderData,
      selectedPurchaseOrderIds,
      selectedPurchaseOrderData,
    } = this.props
    this.setState({
      ...this.state,
      purchaseOrderData,
      loading,
      selectedPurchaseOrderIds,
      selectedPurchaseOrderData,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      loading,
      purchaseOrderData,
      selectedPurchaseOrderData,
      selectedPurchaseOrderIds,
    } = this.props
    window['warn'](purchaseOrderData, prevProps.purchaseOrderData)
    if (loading !== prevProps.loading) {
      this.setState({ ...this.state, loading })
    }
    if (
      !_.isEqual(purchaseOrderData, prevProps.purchaseOrderData) ||
      !_.isEqual(
        selectedPurchaseOrderData,
        prevProps.selectedPurchaseOrderData,
      ) ||
      !_.isEqual(selectedPurchaseOrderIds, prevProps.selectedPurchaseOrderIds)
    ) {
      window['warn']('New PurchaseOrder Data: ', purchaseOrderData)
      this.setState({
        ...this.state,
        purchaseOrderData,
        loading: false,
        selectedPurchaseOrderIds,
        selectedPurchaseOrderData,
      })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  openSelectedPurchaseOrderMenu = event => {
    this.setState({ selectedPurchaseOrdersMenu: event.currentTarget })
  }

  closeSelectedPurchaseOrdersMenu = () => {
    this.setState({ selectedPurchaseOrdersMenu: null })
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return formatted
  }

  handleRowCount = count => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'purchase-orders' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'purchase-orders',
        Preferences: {
          RowCount: count,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.RowCount = count
    }
    this.props.updateTableSettings(prefs)
  }

  handleSortOrder = sort => {
    const { user } = this.props
    const { TableSettings } = user.Data
    let prefs = _.find(TableSettings, { Table: 'purchase-orders' })
    if (!prefs) {
      prefs = {
        UserName: user.UserName,
        Table: 'purchase-orders',
        Preferences: {
          SortOrder: sort,
        },
      }
      TableSettings.push(prefs)
    } else {
      prefs.Preferences.SortOrder = sort
    }
    this.props.updateTableSettings(prefs)
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  export = () => {
    const { selectedPurchaseOrderData } = this.props
    // const data = _.cloneDeepWith(
    //   !selectedPurchaseOrderData ? [] : selectedPurchaseOrderData,
    // )
    const data = _.cloneDeepWith(selectedPurchaseOrderData)
    data.map(value => {
      value.CostTotal =
        value.Data && value.Data.Lines
          ? _.sumBy(value.Data.Lines, o => {
              return o.CostTotal
            })
          : 0.0
      delete value.Data
    })
    const csvData = data.length > 0 ? new Parser() : null
    const Data = csvData.parse(data)
    var encoded = encodeURIComponent(Data)
    var csv = `data:text/csv;charset=utf-8, ${encoded}`
    var link = document.createElement('a')
    link.setAttribute('href', csv)
    link.setAttribute('download', 'Purchase Orders.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  getNextPO = (POs, prefix) => {
    const last = _.maxBy(POs || [], o => {
      if (!isNaN(o.PO.replace(prefix, ''))) {
        return Number(o.PO.replace(prefix, ''))
      }
    })
    const seq = last ? Number(last.PO.replace(prefix, '')) : 0
    const PO = `${prefix}${seq + 1}`
    return PO
  }

  selectAllPurchaseOrders = data => {
    const selected = []
    data.map(value => {
      if (value.Status === 0) selected.push(value.ID)
    })
    this.props.selectAllPurchaseOrders(selected)
  }

  openMenu = event => {
    event.stopPropagation()
    this.setState({ ...this.state, anchorEl: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ ...this.state, anchorEl: null })
  }

  rejectPurchaseOrders = () => {
    const { searchText } = this.props
    const { purchaseOrderData, selectedPurchaseOrderIds, loading } = this.state
    const data = this.props.selectedPurchaseOrderData
      ? this.props.selectedPurchaseOrderData
      : this.getFilteredArray(loading ? [] : purchaseOrderData, searchText)
    let purchaseOrders = []
    selectedPurchaseOrderIds.map(ID => {
      var purchaseOrder = _.find(data, { ID })
      if (purchaseOrder) {
        purchaseOrders.push({ ...purchaseOrder, ApprovedYN: 'N' })
      }
    })
    // this.props.updatePurchaseOrders(purchaseOrders);
    this.props.deSelectAllPurchaseOrders()
    this.closeMenu()
  }

  approvePurchaseOrders = () => {
    const { searchText } = this.props
    const { purchaseOrderData, selectedPurchaseOrderIds, loading } = this.state
    const data = this.props.selectedPurchaseOrderData
      ? this.props.selectedPurchaseOrderData
      : this.getFilteredArray(loading ? [] : purchaseOrderData, searchText)
    let purchaseOrders = []
    selectedPurchaseOrderIds.map(ID => {
      var purchaseOrder = _.find(data, { ID })
      if (purchaseOrder) {
        purchaseOrders.push({ ...purchaseOrder, Status: 2 })
      }
    })

    this.props.updatePurchaseOrders(purchaseOrders)
    this.props.deSelectAllPurchaseOrders()
    this.closeMenu()
  }

  render() {
    const {
      classes,
      securables,
      loading,
      fleetVehicles,
      status,
      companies,
      inventoryLocation,
      user,
      searchText,
      Co,
      vendor,
      workOrder,
      scope,
      vendors,
      inventoryLocations,
      payTerms,
      users,
      openEditPurchaseOrderDialog,
      openNewPurchaseOrderDialog,
      readOnly,
      selectedPurchaseOrderData,
    } = this.props
    const { TableSettings } = user.Data
    const prefs = _.find(TableSettings, { Table: 'purchase-orders' })
    const data = !this.props.selectedPurchaseOrderData
      ? this.getFilteredArray(
          !this.props.selectedPurchaseOrderData
            ? !this.props.purchaseOrderData
              ? []
              : this.props.purchaseOrderData
            : this.props.selectedPurchaseOrderData,
          searchText,
        )
      : this.props.selectedPurchaseOrderData
    const { selectedPurchaseOrdersMenu, anchorEl, selectedPurchaseOrderIds } =
      this.state
    data.map((row, index) => {
      const { CostTotal, Data, Status } = row
      row.batched =
        Data && Data.Lines
          ? _.sumBy(Data.Lines || [], o => {
              return o.Data && o.Data.VPPOIB ? o.Data.VPPOIB.OrigCost : 0
            })
          : 0
      row.posted =
        Data && Data.Lines
          ? _.sumBy(Data.Lines || [], o => {
              return o.Data && o.Data.VPPOIT ? o.Data.VPPOIT.OrigCost : 0
            })
          : 0
      row.total =
        Data && Data.Lines
          ? _.sumBy(Data.Lines || [], o => {
              return Status === 3 ? 0.0 : o.CostTotal
            })
          : 0
      row.invoiced =
        Data && Data.Lines
          ? _.sumBy(Data.Lines || [], o => {
              return o.Data && o.Data.VPPOIT
                ? o.Data.VPPOIT.InvCost +
                    o.Data.VPPOIT.InvMiscAmt +
                    o.Data.VPPOIT.InvTax
                : 0
            })
          : 0
      row.remaining =
        Data && Data.Lines
          ? _.sumBy(Data.Lines || [], o => {
              return o.Data && o.Data.VPPOIT ? o.Data.VPPOIT.RemCost : 0
            })
          : 0
    })
    const avatar = 'assets/images/logos/SP_Cog_Gradient.png'
    const userAvatar = '../../assets/images/avatars/profile.jpg'
    const shipMethods = { W: 'Will Call', D: 'Deliver' }
    const statusus = ['Pending', 'Open', 'Canceled', 'Closed']
    const accessLevel = _.find(securables, { Securable: 'purchase-orders' })
    let poData = { Co }
    if (vendor) {
      const {
        Vendor,
        POAddress1,
        POAddress2,
        POCity,
        POState,
        POZip,
        POCountry,
        POContact,
        POPhone,
        POEmail,
        TaxCode,
        PayTerms,
      } = vendor
      poData = {
        ...poData,
        Vendor,
        POAddress1,
        POAddress2,
        POCity,
        POState,
        POZip,
        POCountry,
        POContact,
        POPhone,
        POEmail,
        TaxCode,
        PayTerms,
      }
    }
    if (inventoryLocation) {
      const { Location, Description, Data, MobileYN, FleetVehicle } =
        inventoryLocation
      const { POs } = Data
      const fleetVehicle =
        MobileYN === 'Y' ? _.find(fleetVehicles, { FleetVehicle }) : null
      console.warn(inventoryLocation)
      poData = {
        ...poData,
        PO: this.getNextPO(POs, `IP${Location}-`),
        InventoryLocation: Location,
        POType: 'I',
        Description: `${Description} Materials`,
        Employee: fleetVehicle ? fleetVehicle.Operator : null,
      }
    }
    if (workOrder) {
      const { WorkOrder, Data, Trip } = workOrder
      const { POs, Scopes, Trips } = Data
      const { divisions } = this.props
      const trip = Trip ? _.find(Trips, { Trip }) : null
      const Scope = trip
        ? _.filter(Scopes, o => {
            return _.findIndex(trip.Data.Assignments, { Scope: o.Scope }) > -1
          })[0].Scope
        : Scopes[0].Scope
      const first = _.find(Scopes, { ReadyToBillYN: 'Y' })
      const dv = first
        ? _.find(divisions, {
            BusinessUnit: first.BusinessUnit,
            Division: first.Division,
          })
        : null
      // poData = { ...poData, PO: this.getNextPO(POs, `WP${WorkOrder}-`), WorkOrder, Scope, POType: 'W', Description: `WO #${WorkOrder} Materials` }
      poData = {
        ...poData,
        PO: this.getNextPO(
          POs,
          `${dv && dv.POPrefix ? dv.POPrefix : 'WP'}${WorkOrder}-`,
        ),
        WorkOrder,
        Scope,
        POType: 'W',
        Description: `WO #${WorkOrder} Materials`,
      }
    }
    if (scope) {
      const { WorkOrder, Data, Scope } = scope
      const { POs } = Data
      poData = {
        ...poData,
        PO: this.getNextPO(POs, `WP${WorkOrder}-`),
        WorkOrder,
        Scope,
        POType: 'W',
        Description: `WO #${WorkOrder} Scope #${Scope} Materials`,
      }
    }
    const company = _.find(companies, { Co })
    const integrationType =
      company && company.Data && company.Data.Co
        ? company.Data.Co.IntegrationType
        : null

    return (
      <FuseAnimate
        animation={this.props.animation || 'transition.slideUpIn'}
        delay={300}
      >
        <div>
          <Menu
            id='purchaseOrders-menu'
            classes={{ paper: 'min-w-256' }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.closeMenu}
          >
            <MenuItem onClick={this.approvePurchaseOrders}>
              <Icon className='mr-8'>check_circle_outline</Icon>Post Selected
              Purchase Orders
            </MenuItem>
          </Menu>
          {status && selectedPurchaseOrderData && (
            <Fab
              color='primary'
              aria-label='add'
              className={classes.addButton}
              onClick={this.export}
              // onClick={openNewUserDialog}
            >
              <Icon className='text-16'>save_alt</Icon>
            </Fab>
          )}

          {accessLevel &&
            accessLevel.AccessLevel !== 'R' &&
            !status &&
            !readOnly && (
              <Fab
                color='secondary'
                aria-label='add'
                className={classes.addButton}
                onClick={() => openNewPurchaseOrderDialog({ ...poData })}
              >
                <Icon>add</Icon>
              </Fab>
            )}
          <Typography
            variant='h6'
            style={{
              alignItems: 'center',
              display: readOnly ? 'none' : 'flex',
            }}
            className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
          >
            <Icon color='primary' className='text-32 mr-12'>
              list_alt
            </Icon>
            <span>Purchase Orders</span>
            {selectedPurchaseOrderIds.length > 0 && Boolean(status) && (
              <Button
                variant='contained'
                className='ml-12 pt-0 pb-0'
                color='primary'
                onClick={this.openMenu}
              >
                Actions
              </Button>
            )}
          </Typography>
          <ReactTableFixedColumns
            className={classNames(
              classes.root,
              '-striped -highlight border-0 rounded',
            )}
            getTrProps={(state, rowInfo, column) => {
              return {
                className: 'cursor-pointer',
                onClick: (e, handleOriginal) => {
                  if (rowInfo) {
                    openEditPurchaseOrderDialog(
                      { ...rowInfo.original },
                      readOnly,
                    )
                  }
                },
              }
            }}
            getTfootTrProps={() => {
              return {
                className: 'font-bold text-center',
              }
            }}
            data={data}
            NoDataComponent={loading ? LoadingDiv : NoData}
            columns={[
              {
                Header: () => (
                  <Checkbox
                    onClick={event => {
                      event.stopPropagation()
                    }}
                    className='p-0'
                    onChange={event => {
                      event.target.checked
                        ? this.selectAllPurchaseOrders(data)
                        : this.props.deSelectAllPurchaseOrders()
                    }}
                    checked={
                      selectedPurchaseOrderIds.length ===
                        _.filter(data, o => o.Status === 0).length &&
                      selectedPurchaseOrderIds.length > 0
                    }
                    indeterminate={
                      selectedPurchaseOrderIds.length !==
                        _.filter(data, o => o.Status === 0).length &&
                      selectedPurchaseOrderIds.length > 0
                    }
                  />
                ),
                fixed: 'left',
                accessor: '',
                show: Boolean(status),
                Cell: row => {
                  return (
                    <Checkbox
                      onClick={event => {
                        event.stopPropagation()
                      }}
                      className='p-0'
                      checked={selectedPurchaseOrderIds.includes(row.value.ID)}
                      disabled={row.value.Status === 2}
                      onChange={() =>
                        this.props.toggleInSelectedPurchaseOrders(row.value.ID)
                      }
                    />
                  )
                },
                className: 'justify-center p-0',
                sortable: false,
                width: 48,
                // show: !Boolean(this.props.selectedPurchaseOrderData)
              },
              {
                Header: '',
                accessor: 'Data',
                width: 48,
                className: 'justify-center',
                show: Boolean(status),
                Cell: row => {
                  const { CostTotal, Data, Status, batched, posted, total } =
                    row.original
                  if (integrationType === 'V') {
                    if (
                      (Data.VPPOHD || Data.VPPOHB) &&
                      (batched || posted) === total
                    ) {
                      return (
                        <Tooltip
                          title={
                            <div>
                              Batched: {this.formatDollars(batched)}
                              <br />
                              Posted: {this.formatDollars(posted)}
                            </div>
                          }
                          placement='top'
                        >
                          <Icon>
                            {Data.VPPOHB ? 'cloud_upload' : 'cloud_done'}
                          </Icon>
                        </Tooltip>
                      )
                    } else {
                      if (Status > 0) {
                        return (
                          <Tooltip
                            title={
                              <div>
                                Batched: {this.formatDollars(batched)}
                                <br />
                                Posted: {this.formatDollars(posted)}
                              </div>
                            }
                            placement='top'
                          >
                            <Icon color={Status === 3 ? 'action' : 'error'}>
                              {Status === 3 ? 'cloud_off' : 'error_outline'}
                            </Icon>
                          </Tooltip>
                        )
                      }
                    }
                  } else {
                    if (row.original.QBOPurchaseOrderTotal === total) {
                      return (
                        <Tooltip
                          title={
                            <div>
                              Posted:{' '}
                              {this.formatDollars(
                                row.original.QBOPurchaseOrderTotal,
                              )}
                            </div>
                          }
                          placement='top'
                        >
                          <Icon>cloud_done</Icon>
                        </Tooltip>
                      )
                    } else {
                      if (Status > 0) {
                        return (
                          <Tooltip
                            title={
                              <div>
                                Posted:{' '}
                                {this.formatDollars(
                                  row.original.QBOPurchaseOrderTotal,
                                )}
                              </div>
                            }
                            placement='top'
                          >
                            <Icon color={Status === 3 ? 'action' : 'error'}>
                              {Status === 3 ? 'cloud_off' : 'error_outline'}
                            </Icon>
                          </Tooltip>
                        )
                      }
                    }
                  }
                },
              },
              {
                Header: 'PO',
                accessor: 'PO',
                minWidth: 128,
                className: 'justify-center font-bold',
                Footer: status ? 'Total' : null,
              },
              {
                Header: 'Ordered Date',
                id: 'orderedDate',
                accessor: d =>
                  new Date(new Date(d.OrderedDate).toLocaleDateString('en-US')),
                className: 'justify-center',
                minWidth: 128,
                Cell: row =>
                  row.original.OrderedDate &&
                  moment(row.original.OrderedDate)
                    .toDate()
                    .toLocaleDateString('en-US'),
              },
              {
                Header: 'Vendor',
                accessor: 'Vendor',
                minWidth: 256,
                className: 'justify-center',
                show: !Boolean(vendor),
                Cell: row => {
                  if (row.original.Vendor && vendors) {
                    let vendor = _.find(vendors, {
                      Vendor: row.original.Vendor,
                    })
                    if (vendor) {
                      return `${vendor.Vendor} - ${vendor.Name}`
                    }
                  }
                },
              },
              {
                Header: 'Description',
                accessor: 'Description',
                minWidth: 256,
                className: 'justify-center',
              },
              {
                Header: 'Cost Total',
                id: 'costTotal',
                minWidth: 128,
                className: 'justify-center',
                accessor: d => d.total,
                Cell: row => this.formatDollars(row.original.total),
                Footer: status
                  ? this.formatDollars(_.sumBy(data, 'total'))
                  : null,
              },
              {
                Header: 'Batched Total',
                id: 'batchedTotal',
                minWidth: 128,
                className: 'justify-center',
                accessor: d => d.batched,
                Cell: row => this.formatDollars(row.original.batched),
                show: Boolean(status) && integrationType === 'V',
                Footer: status
                  ? this.formatDollars(_.sumBy(data, 'batched'))
                  : null,
              },
              {
                Header: 'Posted Total',
                id: 'postedTotal',
                minWidth: 128,
                className: 'justify-center',
                accessor: d => d.posted,
                Cell: row => this.formatDollars(row.original.posted),
                show: Boolean(status) && integrationType === 'V',
                Footer: status
                  ? this.formatDollars(_.sumBy(data, 'posted'))
                  : null,
              },
              {
                Header: 'Invoiced Total',
                id: 'invoicedTotal',
                minWidth: 128,
                className: 'justify-center',
                accessor: d => d.invoiced,
                Cell: row => this.formatDollars(row.original.invoiced),
                show: Boolean(status) && integrationType === 'V',
                Footer: status
                  ? this.formatDollars(_.sumBy(data, 'invoiced'))
                  : null,
              },
              {
                Header: 'Remaining Total',
                id: 'remainingTotal',
                minWidth: 128,
                className: 'justify-center',
                accessor: d => d.remaining,
                Cell: row => this.formatDollars(row.original.remaining),
                show: Boolean(status) && integrationType === 'V',
                Footer: status
                  ? this.formatDollars(_.sumBy(data, 'remaining'))
                  : null,
              },
              {
                Header: 'Ordered By',
                accessor: 'OrderedBy',
                minWidth: 256,
                className: 'justify-center',
                Cell: row => {
                  return (
                    row.original.OrderedBy &&
                    users &&
                    users.map(
                      (value, index) =>
                        value.UserName === row.original.OrderedBy && (
                          <React.Fragment>
                            <Avatar
                              style={{ marginRight: 8, marginLeft: 8 }}
                              classes={{ root: classes.avatarRoot }}
                              className={classes.techAvatar}
                              alt={value.Technician}
                              src={
                                value.Data && value.Data.Avatar
                                  ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`
                                  : avatar
                              }
                            />
                            {`${value.FirstName} ${value.LastName}`}
                          </React.Fragment>
                        ),
                    )
                  )
                },
              },
              {
                Header: 'Date Needed',
                accessor: 'DateNeeded',
                className: 'justify-center',
                minWidth: 128,
                Cell: row =>
                  row.original.DateNeeded &&
                  moment(row.original.DateNeeded)
                    .toDate()
                    .toLocaleDateString('en-US'),
              },
              {
                Header: 'Shipping Method',
                id: 'ShippingMethod',
                minWidth: 192,
                className: 'justify-center',
                accessor: d =>
                  `${
                    d.ShippingMethod &&
                    `${d.ShippingMethod} - ${shipMethods[d.ShippingMethod]}`
                  }`,
              },
              {
                Header: 'Work Order',
                accessor: 'WorkOrder',
                minWidth: 128,
                className: 'justify-center font-bold',
              },
              {
                Header: 'Stock Location',
                id: 'InventoryLocation',
                minWidth: 192,
                className: 'justify-center',
                accessor: d =>
                  inventoryLocations &&
                  d.InventoryLocation &&
                  `${
                    inventoryLocations &&
                    `${
                      _.find(inventoryLocations, {
                        Location: d.InventoryLocation,
                      }).Description
                    }`
                  }`,
              },
              {
                Header: 'Pay Terms',
                id: 'PayTerms',
                minWidth: 192,
                className: 'justify-center',
                accessor: d =>
                  payTerms &&
                  d.PayTerms &&
                  _.find(payTerms, { PayTerms: d.PayTerms }) &&
                  `${
                    payTerms &&
                    `${_.find(payTerms, { PayTerms: d.PayTerms }).Description}`
                  }`,
              },
              {
                Header: 'Added By',
                accessor: 'AddedBy',
                className: 'justify-center',
                minWidth: 256,
              },
              {
                Header: 'Added Date',
                accessor: 'AddedDate',
                className: 'justify-center',
                minWidth: 128,
                Cell: row =>
                  row.original.AddedDate &&
                  moment(row.original.CreatedDate)
                    .toDate()
                    .toLocaleDateString('en-US'),
              },
            ]}
            defaultSorted={
              status && prefs ? prefs.Preferences.SortOrder || [] : []
            }
            defaultPageSize={
              status && prefs ? prefs.Preferences.RowCount || 100 : 10
            }
            onPageSizeChange={this.handleRowCount}
            onSortedChange={this.handleSortOrder}
            noDataText='No Purchase Orders found'
          />
        </div>
      </FuseAnimate>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPurchaseOrders: Actions.getPurchaseOrders,
      getUserData: Actions.getUserData,
      toggleInSelectedPurchaseOrders: Actions.toggleInSelectedPurchaseOrders,
      selectAllPurchaseOrders: Actions.selectAllPurchaseOrders,
      deSelectAllPurchaseOrders: Actions.deSelectAllPurchaseOrders,
      openEditPurchaseOrderDialog: Actions.openEditPurchaseOrderDialog,
      removePurchaseOrders: Actions.removePurchaseOrders,
      removePurchaseOrder: Actions.removePurchaseOrder,
      toggleStarredPurchaseOrder: Actions.toggleStarredPurchaseOrder,
      toggleStarredPurchaseOrders: Actions.toggleStarredPurchaseOrders,
      setPurchaseOrdersStarred: Actions.setPurchaseOrdersStarred,
      setPurchaseOrdersUnstarred: Actions.setPurchaseOrdersUnstarred,
      openNewPurchaseOrderDialog: Actions.openNewPurchaseOrderDialog,
      updatePurchaseOrders: Actions.updatePurchaseOrders,
      updateTableSettings,
    },
    dispatch,
  )
}

function mapStateToProps({ purchaseOrdersApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    companies: spReducers.companies.List,
    divisions: spReducers.divisions,
    purchaseOrderData: spReducers.purchaseOrders,
    searchText: purchaseOrdersApp.purchaseOrders.searchText,
    selectedPurchaseOrderIds:
      purchaseOrdersApp.purchaseOrders.selectedPurchaseOrderIds,
    user: spReducers.userProfiles.User,
    purchaseOrderGroups: spReducers.purchaseOrderGroups,
    employees: spReducers.employees,
    fleetVehicles: spReducers.fleetVehicles,
    vendors: spReducers.vendors,
    payTerms: spReducers.payTerms,
    inventoryLocations: spReducers.inventoryLocations,
    users: spReducers.userProfiles.Users,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersList)),
)
