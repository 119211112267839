import { Geolocation } from '@capacitor/geolocation'
import { FuseAnimate, FuseAnimateGroup, FuseUtils } from '@fuse'
import _ from '@lodash'
import {
  Badge,
  Button,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Fab,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import axios from 'axios'
import classNames from 'classnames'
import GoogleMap from 'google-map-react'
import deburr from 'lodash/deburr'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import { formatPhone } from 'main/functions'
import { SPRoundIcon } from 'main/icons'
import moment from 'moment'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Swipeable from 'react-swipeable'
import { bindActionCreators } from 'redux'
import accents from 'remove-accents'
import {
  getTechnicianEstimates,
  getTechnicianSchedule,
  setTechnicianEstimates,
} from 'store/actions'
import CustomerSiteSearchBar from '../customer-sites/CustomerSiteSearchBar'
import { setSearchText } from '../customer-sites/store/actions'
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector'
import * as DispatchActions from '../dispatch-board/store/actions'
import {
  openEditEstimateDialog,
  openNewEstimateDialog,
} from '../estimates/store/actions'
import { clearSearchTrips } from '../technicians/store/actions'
import * as TimecardActions from '../timecards/store/actions'
import * as WorkOrderActions from '../work-orders/store/actions'
import * as Actions from './store/actions'
import TechnicianEstimate from './TechnicianEstimate'
import TechnicianSchedule from './TechnicianSchedule'
import TechnicianTimecard from './TechnicianTimecard'
import TechnicianWOSearch from './TechnicianWOSearch'
import TimecardPDFDialog from '../timecards/TimecardPDFDialog'

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

function Marker({ text, icon, style, badge, heading, type, status, onClick }) {
  return (
    <Tooltip
      title={text}
      placement='top'
      classes={{ popper: 'tech-schedule-tooltip' }}
    >
      <Badge
        onClick={onClick}
        badgeContent={badge ? badge : 0}
        classes={{
          root: type === 'tech' ? 'person-marker' : '',
          badge:
            type === 'tech'
              ? 'tech-badge'
              : status < 4
                ? 'tech-scheduled-badge cursor-pointer'
                : status < 8
                  ? 'tech-in-progress-badge cursor-pointer'
                  : 'tech-completed-badge cursor-pointer',
        }}
        color={status < 4 ? 'error' : 'primary'}
      >
        <div>
          <img style={style} src={icon} />
        </div>
      </Badge>
    </Tooltip>
  )
}

const styles = theme => ({
  root: {
    // maxHeight: '75vh',
    overflow: 'auto',
  },
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  calendar: {
    width: '100%',
    background: theme.palette.primary.main,
    // borderRadius: 5,
  },
  calendarHeader: {
    width: '100%',
  },
  calendarPicker: {
    height: 72,
    width: '100%',
    background: '#3f3f3f',
    // borderBottomLeftRadius: 5,
    // borderBottomRightRadius: 5
  },
  calendarPickerDates: {
    height: 72,
    width: '100%',
  },
  mailItem: {},
  avatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 30,
    height: 30,
    marginTop: -6,
    marginBottom: -6,
  },
  labels: {},
  badge: {
    top: 5,
    right: 12,
  },
  liRoot: {
    padding: 0,
  },
  clockInBtn: {
    background: '#333',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
  },
  clockOutBtn: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:active': {
      background: '#5f5f5f',
    },
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  withdraw: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  inlineBadge: {
    top: '25%',
    left: '110%',
    right: 'unset',
    fontSize: 11,
    padding: 4,
    height: 16,
    transform: 'none',
  },
  content: {
    overflow: 'hidden',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 'bold',
    fontSize: 12,
    maxWidth: '65%',
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: 11,
    maxWidth: '65%',
  },
  action: {
    marginTop: 0,
    marginRight: -8,
  },
  tab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  viewTab: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px 4px 0px #3f3f3f',
    color: theme.palette.primary.contrastText,
    paddingTop: '8px !important',
  },
  viewTabIcon: {
    color: theme.palette.primary.contrastText,
  },
  searchBadge: {
    top: 2,
    right: -8,
    position: 'relative',
    transform: 'none',
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
})

const initialState = {
  loading: false,
  date: new Date(),
  expanded: {},
  trips: [],
  timecards: [],
  timecard: null,
  selectedTrip: null,
  viewing: 'schedule',
  anchorEl: null,
  tcView: null,
  schedView: null,
  hold: false,
  animation: 'transition.slideLeftBigIn',
  isDatepickerOpen: false,
  estimateSite: null,
  estimateSiteDescription: null,
  estimateSiteList: [],
  siteEstimateHist: null,
  runFetchTimecards: false,
}

class TechnicianProfile extends Component {
  state = {
    ...initialState,
  }

  componentDidMount() {
    let { date, trips, allTrips, timecard, timecards, loading } = this.props
    // const dt = date ? new Date(date) : new Date();
    // if (dt) {
    //     this.setState({ ...this.state, date: dt, trips, allTrips, timecard, loading, tcView: moment(date).format("M/D/YYYY"), schedView: moment(date).format("M/D/YYYY") }, () => this.getGPS());
    // }
    this.setState(
      {
        ...this.state,
        date,
        trips,
        allTrips,
        timecard,
        timecards,
        loading,
        tcView: moment(date).format('M/D/YYYY'),
        schedView: moment(date).format('M/D/YYYY'),
      },
      () => {
        this.getGPS()
        this.checkAndHandleToday()
      },
    )
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {
    let { loading, trips, allTrips, timecard, timecards, date } = this.props
    if (
      !_.isEqual(date, prevProps.date) ||
      !_.isEqual(trips, prevProps.trips) ||
      !_.isEqual(allTrips, prevProps.allTrips) ||
      !_.isEqual(loading, prevProps.loading) ||
      !_.isEqual(timecard, prevProps.timecard)
    ) {
      // const dt = date ? new Date(date) : new Date();
      this.setState({ ...this.state, date, loading, trips, allTrips, timecard })
    }
    if (!_.isEqual(timecards, prevProps.timecards)) {
      // const dt = date ? new Date(date) : new Date();
      this.setState({ ...this.state, timecards })
    }
  }

  checkAndHandleToday = () => {
    const systemDate = new Date()
    const { date } = this.props

    if (systemDate !== date) {
      this.handleToday()
    } else {
    }
  }

  getGPS = () => {
    const { gps } = this.props
    if (
      !gps ||
      !gps.location ||
      !gps.location.coords ||
      !gps.location.coords.latitude ||
      !gps.location.coords.longitude
    ) {
      var geoOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }

      console.log('Fetching GPS Location - Technician')
      Geolocation.getCurrentPosition(geoOptions)
        .then(pos => {
          var crd = pos.coords

          var Latitude = crd.latitude
          var Longitude = crd.longitude
          this.setState({ ...this.state, userGPS: { Latitude, Longitude } })
        })
        .catch(err => {
          window['warn'](`ERROR(${err.code}): ${err.message}`)
        })
    } else {
      var Latitude = gps.location.coords.latitude
      var Longitude = gps.location.coords.longitude
      this.setState({ ...this.state, userGPS: { Latitude, Longitude } })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      window['log']('Filtered Array: ', arr)
      return arr
    }
    window['log']('Filtered Array: ', arr, searchText)
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  openSelectedTechnicianMenu = event => {
    this.setState({ selectedTechniciansMenu: event.currentTarget })
  }

  closeSelectedTechniciansMenu = () => {
    this.setState({ selectedTechniciansMenu: null })
  }

  handleDateChange = event => {
    window['warn'](event)
    const date = event._d
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.props.date)
    const newDate = this.formatDateInput(date)
    if (oldDate !== newDate) {
      this.setState(
        {
          ...this.state,
          date,
          schedView: moment(date).format('M/D/YYYY'),
          hold: false,
          sigImg: null,
          loading: true,
          trips: [],
        },
        () => {
          this.updateDate(date)
        },
      )
    }
  }

  handleDateClick = date => {
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    const newDate = this.formatDateInput(date)
    if (oldDate !== newDate) {
      this.setState(
        {
          ...this.state,
          date,
          hold: false,
          tcView: moment(date).format('M/D/YYYY'),
          schedView: moment(date).format('M/D/YYYY'),
        },
        () => {
          this.updateDate(date)
        },
      )
    }
  }

  handleSwipeDateLeft = () => {
    let { date } = this.state
    if (date) {
      const dt = new Date(date)
      const start = moment(dt).locale('us').weekday(1)
      const end = moment(dt).locale('us').weekday(7)
      const next = dt.setDate(dt.getDate() + 1)
      // if (next >= start && next <= end) {
      this.setState(
        {
          ...this.state,
          date: next,
          hold: false,
          tcView: moment(next).format('M/D/YYYY'),
          schedView: moment(next).format('M/D/YYYY'),
          animation: 'transition.slideRightBigIn',
        },
        () => {
          this.updateDate(next)
        },
      )
      // }
    }
  }

  handleSwipeDateRight = () => {
    let { date } = this.state
    if (date) {
      const dt = new Date(date)
      const start = moment(dt).locale('us').weekday(1)
      const end = moment(dt).locale('us').weekday(7)
      const next = dt.setDate(dt.getDate() - 1)
      // if (next >= start && next <= end) {
      this.setState(
        {
          ...this.state,
          date: next,
          hold: false,
          tcView: moment(next).format('M/D/YYYY'),
          schedView: moment(next).format('M/D/YYYY'),
          animation: 'transition.slideLeftBigIn',
        },
        () => {
          this.updateDate(next)
        },
      )
      // }
    }
  }

  handleToday = event => {
    const date = new Date()
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    const newDate = this.formatDateInput(date)
    this.setState(
      {
        ...this.state,
        date,
        sigImg: null,
        loading: true,
        trips: [],
        hold: false,
        tcView: moment(date).format('M/D/YYYY'),
        schedView: moment(date).format('M/D/YYYY'),
      },
      () => {
        this.updateDate(date, true)
      },
    )
  }

  handleNextDate = bool => {
    let { date } = this.state
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    date.setDate(date.getDate() + 7)
    const newDate = this.formatDateInput(date)
    this.setState(
      {
        ...this.state,
        date,
        sigImg: null,
        loading: true,
        trips: [],
        timecards: [],
        hold: false,
        tcView: bool ? null : moment(date).format('M/D/YYYY'),
        schedView: bool ? null : moment(date).format('M/D/YYYY'),
        animation: 'transition.slideRightBigIn',
      },
      () => {
        this.updateDate(date, true)
      },
    )
  }

  handlePrevDate = bool => {
    let { date } = this.state
    const old = this.formatDateInput(date)
    const oldDate = this.formatDateInput(this.state.date)
    date.setDate(date.getDate() - 7)
    const newDate = this.formatDateInput(date)
    this.setState(
      {
        ...this.state,
        date,
        sigImg: null,
        loading: true,
        trips: [],
        timecards: [],
        hold: false,
        tcView: bool ? null : moment(date).format('M/D/YYYY'),
        schedView: bool ? null : moment(date).format('M/D/YYYY'),
        animation: 'transition.slideLeftBigIn',
      },
      () => {
        this.updateDate(date, true)
      },
    )
  }

  updateDate = _.debounce((date, bool) => {
    this.props.getTechnicianSchedule(this.props.technician, date, 'W', bool)
  }, 500)

  formatDateInput(dt) {
    if (dt) {
      return dt.toLocaleDateString('en-US').replace(/\//g, '-');
    }

    return '';
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${months[dt.getMonth()]
      } ${dt.getDate()} ${dt.getFullYear()}`
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer mr-8'>
      {this.formatDateText(this.state.date)}
    </span>
  )

  handleRefresh = () => {
    let { date, viewing } = this.state
    if (viewing === 'estimates') {
      this.handleRefreshEstimates()
    } else {
      this.setState(
        { ...this.state, date, sigImg: null, loading: true, trips: [] },
        () => {
          this.updateDate(date, true)
        },
      )
    }
  }

  toggleTrip = trip => {
    const { expanded } = this.state
    if (expanded[trip]) {
      expanded[trip] = false
    } else {
      expanded[trip] = true
    }
    this.setState({ ...this.state, expanded })
  }

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      gestureHandling: 'cooperative',
      // mapTypeId: maps.MapTypeId.SATELLITE,
      styles: [
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#d1e751',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 16,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#292929',
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#333333',
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 60,
            },
            {
              visibility: 'on',
            },
            {
              weight: 2,
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#333333',
            },
            {
              lightness: '17',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#4dbce9',
            },
            {
              saturation: -60,
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#292929',
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [
            {
              lightness: '20',
            },
            {
              weight: 3,
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#878787',
            },
          ],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.business',
          elementType: 'geometry',
          stylers: [
            {
              lightness: '2',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.business',
          elementType: 'labels.text.fill',
          stylers: [
            {
              lightness: '30',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#5a5c57',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#292929',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#212121',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#949494',
            },
            {
              weight: 1,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#b0b0b0',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#a8a8a8',
            },
            {
              lightness: 15,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 30,
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#0f252e',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#4dbce9',
            },
            {
              saturation: -55,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#4a4a4a',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#333333',
            },
          ],
        },
      ],
    }
  }

  selectTrip = trip => {
    let { selectedTrip } = this.state
    this.setState({
      ...this.state,
      selectedTrip:
        trip && selectedTrip && selectedTrip.ID === trip.ID ? null : trip,
    })
  }

  updateTrip = trip => {
    const { UserName } = this.props.user
    trip.UpdatedBy = UserName
    trip.UpdatedDate = new Date()
    if (!isNaN(trip.Duration) && trip.Duration >= 0.5) {
      this.props.updateTrip(trip)
    }
  }

  openMenu = e => {
    this.setState({ ...this.state, anchorEl: e.currentTarget })
  }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({
      ...this.state,
      sigImg: this.sigPad.getCanvas().toDataURL('image/png'),
      isSigning: false,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  handleResize = () => {
    // Get the current signature data before resizing
    if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
      const signatureData = this.sigPad.toDataURL()
      // Handle the resize without clearing the canvas
      this.sigPad.clear()
      this.sigPad.fromDataURL(signatureData)
    }
  }

  renderStartLabel = () => {
    const { timecard } = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    return (
      <span className='text-12'>
        {timeIn &&
          moment(moment(timeIn)).local().toDate().toLocaleTimeString('en-US')}
      </span>
    )
  }

  renderEndLabel = () => {
    const { timecard } = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    return (
      <span className='text-12'>
        {timeOut &&
          moment(moment(timeOut)).local().toDate().toLocaleTimeString('en-US')}
      </span>
    )
  }

  handleTimeOutChange = event => {
    window['warn'](event)
    const { timecard, date } = this.state
    const tcData = timecard ? timecard.Data : null
    if (event) {
      const time = event._d
      const dt = new Date(date.toLocaleDateString('en-US'))
      dt.setTime(time.getTime())
      window['warn'](date, time, dt)
      if (tcData) {
        const punch = tcData.Punch[tcData.Punch.length - 1]
        punch.TimeOut = dt.toLocaleString('en-US')
        this.props.editPunch(punch)
      }
    } else {
      if (tcData) {
        const punch = tcData.Punch[tcData.Punch.length - 1]
        punch.TimeOut = null
        this.props.editPunch(punch)
      }
    }
  }

  handleTimeInChange = event => {
    window['warn'](event)
    const { technician } = this.props
    const { Co, Employee } = technician
    const { timecard, date } = this.state
    const tcData = timecard ? timecard.Data : null
    if (event) {
      const time = event._d
      const dt = new Date(date.toLocaleDateString('en-US'))
      dt.setTime(time.getTime())
      window['warn'](date, time, dt)
      const punch =
        tcData && tcData.Punch && tcData.Punch.length > 0
          ? tcData.Punch[0]
          : {
            Co,
            Employee,
            Date: date.toLocaleDateString('en-US'),
            TimeIn: dt.toLocaleString('en-US'),
          }
      punch.TimeIn = dt.toLocaleString('en-US')
      if (tcData && tcData.Punch && tcData.Punch.length > 0) {
        this.props.editPunch(punch)
      } else {
        this.props.clockIn(punch)
      }
    } else {
      const punch =
        tcData && tcData.Punch && tcData.Punch.length === 1
          ? tcData.Punch[0]
          : null
      if (punch) {
        this.props.removePunch(punch)
      }
    }
  }

  openStartPicker = e => {
    this.startPicker.open(e)
  }

  openEndPicker = e => {
    this.endPicker.open(e)
  }

  setDatepickerOpen = bool => {
    const { isDatepickerOpen } = this.state
    if (isDatepickerOpen !== bool) {
      this.setState({ isDatepickerOpen: bool })
    }
  }

  // handleDateChange = (event) => {
  //     window["warn"](event);
  //     const InvoiceDate = event._d;
  //     this.setState({ ...this.state, InvoiceDate, DueDate: InvoiceDate });
  // }

  openDatePicker = e => {
    this.setDatepickerOpen(true)
  }

  handleNextPage = () => {
    const { estimates, technician } = this.props
    const { Page, PageCount } = estimates
    if (Page < PageCount) {
      this.setState({ animation: 'transition.slideRightBigIn' }, () =>
        this.props.getTechnicianEstimates(technician, Page + 1),
      )
    }
  }

  handlePrevPage = () => {
    const { estimates, technician } = this.props
    const { Page } = estimates
    if (Page > 1) {
      this.setState({ animation: 'transition.slideLeftBigIn' }, () =>
        this.props.getTechnicianEstimates(technician, Page - 1),
      )
    }
  }

  handleFirstPage = () => {
    const { estimates, technician } = this.props
    const { Page } = estimates
    if (Page > 1) {
      this.setState({ animation: 'transition.slideLeftBigIn' }, () =>
        this.props.getTechnicianEstimates(technician, 1),
      )
    }
  }

  handleRefreshEstimates = () => {
    const { estimates, technician } = this.props
    const { Page } = estimates
    this.props.getTechnicianEstimates(technician, Page)
  }

  handleLastPage = () => {
    const { estimates, technician } = this.props
    const { Page, PageCount } = estimates
    if (Page < PageCount) {
      this.setState({ animation: 'transition.slideRightBigIn' }, () =>
        this.props.getTechnicianEstimates(technician, PageCount),
      )
    }
  }

  handleAddEstimate = () => {
    this.setState({ estimateSite: null, promptEstimate: true })
  }

  launchEstimate = () => {
    const { estimateSite } = this.state
    this.props.setSiteSearchText({ target: { value: null } })
    this.setState({ promptEstimate: false, estimateSite: null }, () => {
      const { Co, Customer, Site, Data } = estimateSite
      const { technician, catalogs } = this.props
      const { DefaultBusinessUnit, DefaultDivision, Technician } = technician
      const catalog = _.find(catalogs, {
        Co,
        BusinessUnit: DefaultBusinessUnit,
        Division: DefaultDivision,
      })
      const next = _.maxBy(Data.Estimates, o => {
        const seq = o.EstimateNumber.replace(
          `${catalog && catalog.DefaultEstimatePrefix
            ? catalog.DefaultEstimatePrefix
            : 'Q'
          }${Site}-`,
          '',
        )
        if (!isNaN(seq)) {
          return Number(seq)
        }
      })
      const seq = next
        ? Number(
          next.EstimateNumber.replace(
            `${catalog && catalog.DefaultEstimatePrefix
              ? catalog.DefaultEstimatePrefix
              : 'Q'
            }${Site}-`,
            '',
          ),
        )
        : 0
      this.props.openNewEstimateDialog({
        Co,
        BusinessUnit: DefaultBusinessUnit,
        Division: DefaultDivision,
        Customer,
        Site,
        Estimate: null,
        EstimateNumber: `${catalog ? catalog.DefaultEstimatePrefix : 'Q'
          }${Site}-${seq + 1}`,
        IssuedBy: Technician,
        IssuedDate: new Date(),
        Data: {
          Customer: Data.Customer,
          Site: estimateSite,
          Components: [],
          Features: [],
          Exclusions: [],
          Payments: [],
          ErrMsg: null,
        },
      })
    })
  }

  handleSiteSearch = _.debounce(() => {
    const { estimateSiteDescription } = this.state
    if (estimateSiteDescription && estimateSiteDescription.length > 0) {
      const { Co } = this.props
      const inputValue = deburr(
        accents.remove(estimateSiteDescription.trim()),
      ).toLowerCase()
      axios
        .get(
          `${window['apiLocation']
          }/api/CustomerSiteSearch?Co=${Co}&Term=${encodeURIComponent(
            inputValue,
          )}`,
        )
        .then(response => {
          this.setState({ estimateSiteList: response.data }, () =>
            window['warn'](this.state.estimateSiteList),
          )
        })
    } else {
      this.setState({ estimateSiteList: [] })
    }
  }, 750)

  handleSiteSelect = estimateSite => {
    const { siteEstimateHist } = this.state
    axios
      .get(
        `${window['apiLocation']}/api/CustomerSite?Co=${estimateSite.Co
        }&Customer=${estimateSite.Customer}&Site=${encodeURIComponent(
          estimateSite.Site,
        )}`,
      )
      .then(response => {
        this.setState({ estimateSite: response.data }, () => {
          axios
            .get(
              `${window['apiLocation']}/api/Estimate?Co=${estimateSite.Co
              }&Customer=${estimateSite.Customer}&Site=${estimateSite.Site
              }&Page=${siteEstimateHist ? siteEstimateHist.Page : 1}`,
            )
            .then(response2 => {
              this.setState({
                siteEstimateHist: response2.data,
                loading: false,
              })
            })
            .catch(() => {
              this.setState({ loading: false, siteEstimateHist: null })
            })
        })
      })
      .catch(() => {
        this.setState({ estimateSite: null })
      })
  }
  formatPhone(str) {
    return formatPhone(str)
  }

  resetRunFetch = () =>
    this.setState({ ...this.state, runFetchTimecards: false })

  render() {
    const {
      classes,
      technicians,
      user,
      searchText,
      openEditWorkOrderDialog,
      technician,
      onBack,
      searchResults,
      estimates,
    } = this.props
    const { Co, Employee } = technician
    const { Page, PageCount } = estimates
    const {
      date,
      animation,
      loading,
      expanded,
      trips,
      timecards,
      allTrips,
      timecard,
      userGPS,
      selectedTrip,
      viewing,
      anchorEl,
      tcView,
      schedView,
      estimateSite,
      estimateSiteList,
      estimateSiteDescription,
      siteEstimateHist,
    } = this.state
    const start = moment(date).locale('us').startOf('isoweek') // picking Monday on Sunday - fix this
    const sched = _.find(trips, o => {
      return (
        moment(o.Date).format('M/D/YYYY') ===
        moment(schedView).format('M/D/YYYY')
      )
    })
    const data = this.getFilteredArray(
      !this.props.selectedTechnicianData
        ? !this.props.technicianData
          ? []
          : this.props.technicianData
        : this.props.selectedTechnicianData,
      searchText,
    )
    const { selectedTechniciansMenu } = this.state
    const tcData = timecard ? timecard.Data : null
    const punches = tcData && tcData.Punch ? tcData.Punch : []
    const timeIn =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[0].TimeIn
        : null
    const timeOut =
      tcData && tcData.Punch && tcData.Punch.length > 0
        ? tcData.Punch[tcData.Punch.length - 1].TimeOut
        : null
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const statusProps = [
      {
        text: 'Scheduled',
        icon: 'access_time',
        color: 'rgb(100, 100, 200)',
        dateProp: 'ScheduledDate',
        timeProp: 'ScheduledTime',
      },
      {
        text: 'Notified',
        icon: 'offline_bolt',
        color: 'rgb(0, 150, 250)',
        dateProp: 'NotifiedDate',
        timeProp: 'NotifiedTime',
      },
      {
        text: 'Accepted',
        icon: 'offline_pin',
        color: 'rgb(0, 50, 250)',
        dateProp: 'AcceptedDate',
        timeProp: 'AcceptedTime',
      },
      {
        text: 'Rejected',
        icon: 'cancel',
        color: 'rgb(210, 0, 0)',
        dateProp: 'RejectedDate',
        timeProp: 'RejectedTime',
      },
      {
        text: 'En-Route',
        icon: 'explore',
        color: 'rgb(50, 170, 200)',
        dateProp: 'EnRouteDate',
        timeProp: 'EnRouteTime',
      },
      {
        text: 'Arrived',
        icon: 'my_location',
        color: 'rgb(50, 150, 100)',
        dateProp: 'ArrivedDate',
        timeProp: 'ArrivedTime',
      },
      {
        text: 'In Progress',
        icon: 'play_circle_outline',
        color: 'rgb(150, 200, 50)',
        dateProp: 'InProgressDate',
        timeProp: 'InProgressTime',
      },
      {
        text: 'On Hold',
        icon: 'pause_circle_outline',
        color: 'rgb(210, 100, 100)',
        dateProp: 'OnHoldDate',
        timeProp: 'OnHoldTime',
      },
      {
        text: 'Completed',
        icon: 'check_circle',
        color: 'rgb(100, 100, 100)',
        dateProp: 'CompletedDate',
        timeProp: 'CompletedTime',
      },
    ]
    const calWindow = []
    for (var i = 0; i < 7; i++) {
      let dt = new Date(start)
      dt.setDate(dt.getDate() + i)
      calWindow.push(dt)
    }
    const total = _.sumBy(timecards, t => {
      return t.timecard && t.timecard.Data
        ? _.sumBy(t.timecard.Data.Labor || [], l => {
          return l.Qty
        })
        : 0
    })
    const avatar = 'assets/images/avatars/profile.jpg'
    const onHold = _.filter(allTrips, o => {
      return Boolean(o.SourceTrip) && o.Status === 1
    })

    let increaseFontSize = false
    if (this.props.userPreferences) {
      let pref = JSON.parse(this.props.userPreferences)
      if (pref.IncreaseFontSize) {
        increaseFontSize = pref.IncreaseFontSize === 'Y' ? true : false
      }
    }

    return (
      <Media query='(min-width: 768px) and (min-height: 768px)'>
        {matches => (
          <FuseAnimate animation='transition.slideUpIn' delay={300}>
            <div className='w-full pb-6'>
              <Typography
                variant='h6'
                style={{
                  alignItems: 'center',
                  marginBottom: -4,
                  maxWidth: 'calc(100% - 40px)',
                }}
                className='flex pl-0 pt-4 sm:max-w-none overflow-x-auto'
              >
                <div
                  onClick={() =>
                    this.setState({ ...this.state, viewing: 'schedule' })
                  }
                  className={classNames(
                    viewing === 'schedule' ? classes.viewTab : classes.tab,
                    'w-auto p-6 pr-12 cursor-pointer',
                  )}
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Badge
                    classes={{ badge: classNames(classes.badge, 'text-11') }}
                    badgeContent={sched && sched.trips ? sched.trips.length : 0}
                    color='error'
                  >
                    <Icon
                      color='primary'
                      className={classNames(
                        viewing === 'schedule' ? classes.viewTabIcon : '',
                        'text-32 mr-8 mb-4',
                      )}
                    >
                      perm_contact_calendar
                    </Icon>
                  </Badge>
                  <span>Schedule</span>
                </div>
                <div
                  onClick={() =>
                    this.setState({ ...this.state, viewing: 'timecard' })
                  }
                  className={classNames(
                    viewing === 'timecard' ? classes.viewTab : classes.tab,
                    'w-auto p-6 pr-12 cursor-pointer',
                  )}
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Badge
                    classes={{ badge: classNames(classes.badge, 'text-11') }}
                    badgeContent={Number(total).toFixed(2)}
                    color='error'
                  >
                    <Icon
                      color='primary'
                      className={classNames(
                        viewing === 'timecard' ? classes.viewTabIcon : '',
                        'text-32 mr-8 mb-4',
                      )}
                    >
                      access_time
                    </Icon>
                  </Badge>
                  <span>Timecard</span>
                </div>
                <div
                  onClick={() =>
                    this.setState({ ...this.state, viewing: 'estimates' })
                  }
                  className={classNames(
                    viewing === 'estimates' ? classes.viewTab : classes.tab,
                    'w-auto p-6 pr-12 cursor-pointer',
                  )}
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Badge
                    classes={{ badge: classNames(classes.badge, 'text-11') }}
                    badgeContent={0}
                    color='error'
                  >
                    <Icon
                      color='primary'
                      className={classNames(
                        viewing === 'estimates' ? classes.viewTabIcon : '',
                        'text-32 mr-8 mb-4',
                      )}
                    >
                      format_list_numbered
                    </Icon>
                  </Badge>
                  <span>Estimates</span>
                </div>
                <div
                  onClick={() =>
                    this.setState({ ...this.state, viewing: 'dashboard' })
                  }
                  className={classNames(
                    viewing === 'dashboard' ? classes.viewTab : classes.tab,
                    'w-auto p-6 pr-12 cursor-pointer',
                  )}
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Badge
                    classes={{ badge: classNames(classes.badge, 'text-11') }}
                    badgeContent={0}
                    color='error'
                  >
                    <Icon
                      color='primary'
                      className={classNames(
                        viewing === 'dashboard' ? classes.viewTabIcon : '',
                        'text-32 mr-8 mb-4',
                      )}
                    >
                      insert_chart
                    </Icon>
                  </Badge>
                  <span>Performance</span>
                </div>
              </Typography>
              {['schedule', 'timecard', 'estimates'].indexOf(viewing) > -1 && (
                <IconButton
                  className='pin-r pin-t absolute'
                  style={{ marginTop: 3, marginRight: -4 }}
                  onClick={this.handleRefresh}
                >
                  <Icon
                    color='action'
                    className={
                      !loading && !estimates.loading
                        ? 'text-24 cursor-pointer'
                        : 'text-24 cursor-pointer spin'
                    }
                  >
                    refresh
                  </Icon>
                </IconButton>
              )}
              <div className='md:flex pb-48 md:pb-0'>
                <div
                  className={classNames(
                    `w-full relative min-w-1/2${['schedule', 'timecard', 'estimates'].indexOf(viewing) >
                      -1
                      ? ' md:max-w-1/2'
                      : ''
                    }`,
                    matches ? classes.root : '',
                  )}
                >
                  {viewing !== 'dashboard' && (
                    <div className={classNames('border-0')}>
                      <div
                        className={classNames(
                          classes.calendar,
                          'rounded xs:rounded-b-none md:rounded-r-none',
                        )}
                      >
                        {viewing !== 'estimates' ? (
                          <div
                            className={classNames(
                              classes.calendarHeader,
                              'flex justify-between',
                            )}
                          >
                            <Icon
                              onClick={() => this.handlePrevDate(false)}
                              className='text-24 text-white mt-6 cursor-pointer ml-4'
                            >
                              skip_previous
                            </Icon>
                            <Typography
                              onClick={this.openDatePicker}
                              variant='subheading'
                              style={{ alignItems: 'center', color: '#fff' }}
                              className={`${increaseFontSize ? 'text-16' : 'text-14'
                                } flex justify-center font-bold m-4 my-8 cursor-pointer`}
                            >
                              <Icon
                                onClick={e => {
                                  e.stopPropagation()
                                  this.handleToday()
                                }}
                                className='text-white text-20 mr-4'
                              >
                                today
                              </Icon>
                              {moment(date).format("MMMM Do 'YY")}
                            </Typography>
                            <Icon
                              onClick={() => this.handleNextDate(false)}
                              className='text-24 text-white mt-6 cursor-pointer mr-4'
                            >
                              skip_next
                            </Icon>
                            <DatePicker
                              onOpen={() => this.setDatepickerOpen(true)}
                              onClose={() => this.setDatepickerOpen(false)}
                              open={this.state.isDatepickerOpen}
                              label='Date'
                              inputProps={{
                                style: {
                                  padding: 12,
                                },
                              }}
                              TextFieldComponent={() => ''}
                              value={date || null}
                              onChange={this.handleDateChange}
                              showTodayButton
                              autoOk
                            />
                          </div>
                        ) : (
                          <div
                            className={classNames(
                              classes.calendarHeader,
                              'flex justify-between',
                            )}
                          >
                            {Page > 1 ? (
                              <Icon
                                onClick={this.handleFirstPage}
                                className='text-24 text-white mt-6 cursor-pointer ml-4'
                              >
                                skip_previous
                              </Icon>
                            ) : (
                              <div className='min-w-24 min-h-24 mr-4' />
                            )}
                            <Typography
                              variant='subheading'
                              style={{ alignItems: 'center', color: '#fff' }}
                              className={`${increaseFontSize ? 'text-16' : 'text-14'
                                } flex justify-center font-bold m-4 mt-8 cursor-pointer`}
                            >
                              {Page > 1 ? (
                                <Icon
                                  onClick={this.handlePrevPage}
                                  className='text-24 text-white cursor-pointer mr-4'
                                >
                                  arrow_left
                                </Icon>
                              ) : (
                                <div className='min-w-24 min-h-24 mr-4' />
                              )}
                              Page {estimates.Page} of {estimates.PageCount}
                              {Page < PageCount ? (
                                <Icon
                                  onClick={this.handleNextPage}
                                  className='text-24 text-white cursor-pointer ml-4'
                                >
                                  arrow_right
                                </Icon>
                              ) : (
                                <div className='min-w-24 min-h-24 ml-4' />
                              )}
                            </Typography>
                            {Page < PageCount ? (
                              <Icon
                                onClick={this.handleLastPage}
                                className='text-24 text-white mt-6 cursor-pointer mr-4'
                              >
                                skip_next
                              </Icon>
                            ) : (
                              <div className='min-w-24 min-h-24 ml-4' />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {viewing === 'schedule' && (
                    <div
                      className={classNames('border-0 md:pr-12')}
                      style={{ maxHeight: 'unset' }}
                    >
                      <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 0, whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }} className={`flex ${increaseFontSize ? "text-20" : "text-16"} mt-6`}><Icon color="primary" className="text-24 mr-8">{searchResults && searchResults.length > 0 ? "search" : "perm_contact_calendar"}</Icon>{searchResults && searchResults.length > 0 ? <span className="cursor-pointer">Search</span> : <span className="cursor-pointer">{`${sched ? 'Daily' : 'Weekly'} Schedule`}</span>}{<div className={`pin-r md:mr-16 absolute ${searchResults ? 'w-full' : ''}`}><TechnicianWOSearch tech={technician ? technician.Technician : null} /></div>}</Typography>
                      {searchResults && (
                        <FuseAnimateGroup
                          enter={{
                            animation,
                          }}
                          className='max-h-512 overflow-y-auto md:max-h-547 lg:max-h-632'
                        >
                          <TechnicianSchedule
                            selectTrip={this.selectTrip}
                            search={true}
                            animation={animation}
                            onClick={this.props.clearSearchTrips}
                            key={999}
                            data={{ trips: searchResults }}
                            open={true}
                          />
                        </FuseAnimateGroup>
                      )}
                      {!searchResults && sched && (
                        <Swipeable
                          onSwipedLeft={this.handleSwipeDateLeft}
                          onSwipedRight={this.handleSwipeDateRight}
                        >
                          <FuseAnimateGroup
                            enter={{
                              animation,
                            }}
                            className='max-h-512 overflow-y-auto md:max-h-547 lg:max-h-632'
                          >
                            <TechnicianSchedule
                              selectTrip={this.selectTrip}
                              animation={animation}
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  schedView: null,
                                  tcView: null,
                                })
                              }
                              key={sched.Date}
                              data={{ ...sched }}
                              open={true}
                            />
                          </FuseAnimateGroup>
                        </Swipeable>
                      )}
                      {!searchResults && !sched && (
                        <Swipeable
                          onSwipedLeft={() => this.handleNextDate(true)}
                          onSwipedRight={() => this.handlePrevDate(true)}
                        >
                          <FuseAnimateGroup
                            enter={{
                              animation,
                            }}
                            className='max-h-512 overflow-y-auto md:max-h-547 lg:max-h-632'
                          >
                            {(!this.state.hold ||
                              !onHold ||
                              onHold.length < 1) &&
                              trips.map((value, index) => {
                                var sel = moment(value.Date).format('M/D/YYYY')
                                return (
                                  <TechnicianSchedule
                                    date={moment(date).format('M/D/YYYY')}
                                    technician={{ ...technician }}
                                    selectTrip={this.selectTrip}
                                    onClick={() =>
                                      this.setState({
                                        ...this.state,
                                        schedView: sel,
                                        tcView: sel,
                                        date: new Date(sel),
                                      })
                                    }
                                    key={value.Date}
                                    data={{ ...value }}
                                    open={Boolean(
                                      schedView ===
                                      moment(value.Date).format('M/D/YYYY'),
                                    )}
                                  />
                                )
                              })}
                            {onHold &&
                              onHold.length > 0 &&
                              trips.length > 0 && (
                                <TechnicianSchedule
                                  hold={true}
                                  technician={{ ...technician }}
                                  selectTrip={this.selectTrip}
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      hold: !this.state.hold,
                                    })
                                  }
                                  key={'onhold'}
                                  data={{ trips: [...onHold] }}
                                  open={this.state.hold}
                                />
                              )}
                          </FuseAnimateGroup>
                        </Swipeable>
                      )}
                    </div>
                  )}
                  {viewing === 'timecard' && (
                    <div
                      className={classNames('border-0 md:pr-12')}
                      style={{ maxHeight: 'unset' }}
                    >
                      <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 0, whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }} className={`flex mt-6 ${increaseFontSize ? "text-20" : "text-16"}`}><Icon color="primary" className="text-24 mr-8">access_time</Icon><span className="cursor-pointer">Weekly Timecard</span></Typography>

                      <TimecardPDFDialog
                        runFetch={this.state.runFetchTimecards}
                        resetRunFetch={this.resetRunFetch.bind(this)}
                      />

                      <Swipeable
                        onSwipedLeft={() => this.handleNextDate(true)}
                        onSwipedRight={() => this.handlePrevDate(true)}
                      >
                        <FuseAnimateGroup
                          enter={{
                            animation,
                          }}
                        >
                          {timecards.map((value, index) => {
                            var sel = moment(value.Date).format('M/D/YYYY')
                            return (
                              <TechnicianTimecard
                                onClick={() => {
                                  if (value) {
                                    if (!value.timecard.Co)
                                      this.props.openNewTimecardDialog({
                                        Co,
                                        Employee,
                                        Date: new Date(sel).toISOString(),
                                        Data: {
                                          Punch: [],
                                          Labor: [],
                                          Attachments: [],
                                        },
                                      })
                                    else
                                      this.props.openEditTimecardDialog(
                                        value.timecard,
                                      )

                                    this.setState({
                                      ...this.state,
                                      tcView: sel,
                                      schedView: sel,
                                      date: new Date(sel),
                                    })
                                  }
                                }}
                                date={moment(date).format('M/D/YYYY')}
                                key={value.Date}
                                technician={{ ...technician }}
                                trips={[...trips]}
                                data={{ ...value }}
                                open={Boolean(
                                  tcView ===
                                  moment(value.Date).format('M/D/YYYY'),
                                )}
                              />
                            )
                          })}
                        </FuseAnimateGroup>
                      </Swipeable>
                    </div>
                  )}
                  {viewing === 'estimates' && (
                    <div className={classNames('border-0 md:pr-12 ')}>
                      <Typography
                        variant='h6'
                        style={{
                          alignItems: 'center',
                          marginBottom: 0,
                          whiteSpace: 'nowrap',
                          overflowX: 'auto',
                          maxWidth: '100%',
                        }}
                        className={`flex mt-6 ${increaseFontSize ? 'text-20' : 'text-16'
                          }`}
                      >
                        <Icon color='primary' className='text-24 mr-8'>
                          format_list_numbered
                        </Icon>
                        <span className='cursor-pointer'>Estimates Issued</span>
                        {
                          <Icon
                            onClick={this.handleAddEstimate}
                            color='secondary'
                            className={classNames(
                              increaseFontSize ? 'text-24' : 'text-20',
                              'ml-4 cursor-pointer',
                            )}
                          >
                            add_circle_outline
                          </Icon>
                        }
                      </Typography>

                      <Swipeable
                        onSwipedLeft={() => this.handleNextPage()}
                        onSwipedRight={() => this.handlePrevPage()}
                      >
                        <FuseAnimateGroup
                          enter={{
                            animation,
                          }}
                          className='max-h-512 overflow-y-auto md:max-h-547 lg:max-h-632'
                        >
                          {estimates.Results.map((value, index) => {
                            return (
                              <TechnicianEstimate
                                onClick={() => {
                                  this.props.openEditEstimateDialog(value)
                                }}
                                key={value.Estimate}
                                data={{ ...value }}
                              />
                            )
                          })}
                        </FuseAnimateGroup>
                      </Swipeable>
                      {this.state.promptEstimate && (
                        <ConfirmationDialog
                          open={this.state.promptEstimate}
                          title={
                            <div>
                              <Icon
                                className='mr-6 align-middle mb-4'
                                color='secondary'
                              >
                                format_list_numbered
                              </Icon>
                              New Estimate
                            </div>
                          }
                          fullScreen={!matches}
                          content={
                            <div className='w-full min-w-256 sm:min-w-384 min-h-384'>
                              <div className='flex flex-1'>
                                <Paper
                                  className='flex items-center w-full h-48 sm:h-56 p-16 pl-4 md:pl-16 rounded-8 relative'
                                  elevation={1}
                                >
                                  <Icon color='action'>search</Icon>
                                  <CustomerSiteSearchBar
                                    className='w-full pl-48'
                                    onSelected={option =>
                                      this.handleSiteSelect(option)
                                    }
                                    containerClassName='pin-l pin-r'
                                  />
                                </Paper>
                              </div>
                              {/* <Autocomplete
                                                                className={classes.formControl}
                                                                title="Site"
                                                                options={estimateSiteList || []}
                                                                searchButton={true}
                                                                menuItemComponent={(value) => {
                                                                    return <MenuItem disabled={value.ActiveYN === "N"} value={value.Site}>
                                                                        {`${value.Site} - ${(value.Description || '').substr(0, 50)}`}
                                                                    </MenuItem>
                                                                }}
                                                                debounce={750}
                                                                onChange={(text) => this.setState({ ...this.state, estimateSiteDescription: text }, this.handleSiteSearch)}
                                                                onBlur={() => this.setState({ ...this.state, searchingEstimateSite: false })}
                                                                search={this.state.estimateSiteDescription || ''}
                                                                value={(this.state.estimateSite || {}).Site || ''}
                                                                portal={true}
                                                                noflip={true}
                                                                onSelect={(option) => this.handleSiteSelect(option)}
                                                                required
                                                            /> */}
                              {estimateSite && (
                                <div className='w-full mt-12'>
                                  <Typography
                                    variant='h6'
                                    style={{
                                      alignItems: 'center',
                                      marginBottom: 0,
                                      whiteSpace: 'nowrap',
                                      overflowX: 'auto',
                                      maxWidth: '100%',
                                    }}
                                    className='flex mt-6 text-16'
                                  >
                                    <Icon
                                      color='primary'
                                      className='text-24 mr-8'
                                    >
                                      location_on
                                    </Icon>
                                    <span className='cursor-pointer'>
                                      Site Info
                                    </span>
                                  </Typography>
                                  <div className='pl-36 mt-8'>
                                    <Typography
                                      fullWidth
                                      style={{
                                        alignItems: 'center',
                                        lineHeight: 1,
                                      }}
                                      className='w-full font-bold text-11 mb-6'
                                    >{`${estimateSite.Site} - ${estimateSite.Description}`}</Typography>
                                    <Typography
                                      fullWidth
                                      style={{
                                        alignItems: 'center',
                                        lineHeight: 1,
                                      }}
                                      className='w-full text-11 mb-6'
                                    >{`${estimateSite.Address1} ${estimateSite.City}, ${estimateSite.State} ${estimateSite.Zip}`}</Typography>
                                    <Typography
                                      fullWidth
                                      style={{
                                        alignItems: 'center',
                                        lineHeight: 1,
                                      }}
                                      className='w-full text-11 mb-6'
                                    >{`${this.formatPhone(estimateSite.Phone)
                                      .formatted
                                      }`}</Typography>
                                  </div>
                                </div>
                              )}
                              {siteEstimateHist && siteEstimateHist.Results && (
                                <div className='w-full mt-12'>
                                  <Typography
                                    variant='h6'
                                    style={{
                                      alignItems: 'center',
                                      marginBottom: 0,
                                      whiteSpace: 'nowrap',
                                      overflowX: 'auto',
                                      maxWidth: '100%',
                                    }}
                                    className='flex mt-6 text-16'
                                  >
                                    <Icon
                                      color='primary'
                                      className='text-24 mr-8'
                                    >
                                      history
                                    </Icon>
                                    <span className='cursor-pointer'>
                                      Previous Estimates
                                    </span>
                                  </Typography>
                                  <div className='w-full max-h-384 overflow-y-auto'>
                                    {siteEstimateHist.Results.map(value => {
                                      return (
                                        <TechnicianEstimate
                                          onClick={() => {
                                            this.props.openEditEstimateDialog(
                                              value,
                                            )
                                          }}
                                          key={value.Estimate}
                                          data={{ ...value }}
                                        />
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                          confirmText={'New Estimate'}
                          disableConfirm={!estimateSite}
                          cancelText='Cancel'
                          onConfirm={this.launchEstimate}
                          onCancel={() => {
                            this.setState(
                              {
                                promptEstimate: null,
                                estimateSite: null,
                                estimateSiteDescription: null,
                                estimateSiteList: [],
                              },
                              () =>
                                this.props.setSiteSearchText({
                                  target: { value: null },
                                }),
                            )
                          }}
                        />
                      )}
                    </div>
                  )}
                  {viewing === 'dashboard' && (
                    <div
                      className={classNames(classes.root, 'border-0 mt-4')}
                      style={{
                        maxHeight: 'unset',
                        borderTop: '1px solid lightgrey',
                      }}
                    >
                      <DashboardSelector
                        data={this.props.technician}
                        form='technician'
                      />
                    </div>
                  )}
                </div>
                {userGPS &&
                  ['schedule', 'timecard', 'estimates'].indexOf(viewing) >
                  -1 && (
                    <div
                      className='min-w-56 lg:min-w-72 min-h-56 lg:min-h-72 mb--12 md:mb-0 mt-12 md:mt-0 md:h-616 lg:h-700 rounded-t md:rounded-none md:rounded-bl'
                      style={{ backgroundColor: '#3f3f3f', color: '#fff' }}
                    >
                      <div className='w-full h-56 md:h-72'>
                        {start && (
                          <div className='flex md:flex-wrap border-b-1 md:border-b-none md:border-r-1'>
                            {calWindow.map((dt, index) => {
                              const day = days[dt.getDay()]
                              const fmt = moment(dt).format('M/D/YYYY')
                              const sel = moment(date).format('M/D/YYYY')
                              return (
                                <div
                                  onClick={() => this.handleDateClick(dt)}
                                  className={classNames(
                                    'w-full cursor-pointer relative h-56 md:h-88 lg:h-100 border-r-dotted md:border-r-none md:border-b-dotted',
                                  )}
                                  style={{ background: 'transparent' }}
                                >
                                  <div
                                    className={`pin-t pin-b pin-l pin-r m-auto absolute w-auto ${fmt !== sel && 'md:h-48 pt-8'
                                      }`}
                                  >
                                    {fmt !== sel && (
                                      <Typography
                                        fullWidth
                                        style={{
                                          alignItems: 'center',
                                          margin: 0,
                                          lineHeight: 1,
                                          fontWeight: 'bold',
                                          color: 'grey',
                                        }}
                                        className='w-full text-center'
                                      >
                                        {day ? day.substr(0, 3) : ''}
                                      </Typography>
                                    )}
                                    <div className='w-full text-center text-grey relative'>
                                      {fmt === sel ? (
                                        <div
                                          className='absolute pin-l pin-r m-auto p-4 font-bold p-6 mt--4 md:mt-0 h-64 md:h-88 lg:h-100 w-64 md:w-88 lg:w-100 xs:ml--2 md:ml--17 lg:ml--14'
                                          style={{
                                            zIndex: 999,
                                            background: '#3f3f3f',
                                            borderRadius: '50%',
                                          }}
                                        >
                                          <div
                                            className='relative'
                                            style={{
                                              borderRadius: '50%',
                                              width: '100%',
                                              height: '100%',
                                              backgroundColor: '#d1e751',
                                              color: '#333',
                                            }}
                                          >
                                            {/* {dt.getDate()} */}
                                            <SPRoundIcon
                                              onClick={this.handleRefresh}
                                              className={classNames(
                                                'text-52 md:text-76 lg:text-88',
                                                loading && 'spin-slow',
                                              )}
                                              style={{ color: '#3f3f3f' }}
                                            />
                                            {/* <div className="pin-t pin-b pin-l pin-r m-auto absolute w-48 h-48">
                                                                                            {dt.getDate()}
                                                                                        </div> */}
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className='absolute pin-l pin-r m-auto h-28 w-28 p-4 font-bold'
                                          style={{
                                            background: 'transparent',
                                            color: 'grey',
                                          }}
                                        >
                                          {dt.getDate()}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {userGPS &&
                  ['schedule', 'timecard', 'estimates'].indexOf(viewing) >
                  -1 && (
                    <div className='w-full relative'>
                      <div className='mt-12 md:mt-0'>
                        <div
                          // onScroll={(e) => e.preventDefault()}
                          className='rounded rounded-t-none md:rounded-tr md:rounded-l-none h-560 md:h-616 lg:h-700'
                          style={{
                            width: '100%',
                            backgroundColor: '#eeeeee',
                            // marginBottom: 8,
                            overflow: 'hidden',
                          }}
                        >
                          <GoogleMap
                            options={this.getMapOptions}
                            gestureHandling='cooperative'
                            bootstrapURLKeys={{
                              key: googleMapsApiKey,
                            }}
                            defaultZoom={12}
                            defaultCenter={[
                              userGPS.Latitude,
                              userGPS.Longitude,
                            ]}
                            center={
                              selectedTrip &&
                                selectedTrip.Data.WorkOrder.Data.Site.Latitude &&
                                selectedTrip.Data.WorkOrder.Data.Site.Longitude
                                ? [
                                  Number(
                                    selectedTrip.Data.WorkOrder.Data.Site
                                      .Latitude,
                                  ),
                                  Number(
                                    selectedTrip.Data.WorkOrder.Data.Site
                                      .Longitude,
                                  ),
                                ]
                                : [userGPS.Latitude, userGPS.Longitude]
                            }
                          >
                            <Marker
                              style={{
                                width: 32,
                                height: 32,
                                borderRadius: '50%',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                margin: 'auto',
                                cursor: 'pointer',
                                position: 'absolute',
                                transform: 'rotate(-45deg)',
                              }}
                              icon={
                                user.Data && user.Data.Avatar
                                  ? `${window['apiLocation']}/api/Attachment/GetProfileImage?ID=${user.Data.Avatar}&Thumb=true`
                                  : avatar
                              }
                              text={'Your Location'}
                              lat={userGPS.Latitude}
                              lng={userGPS.Longitude}
                              type='tech'
                            />
                            {sched &&
                              sched.trips &&
                              [...sched.trips].map((trip, index) => {
                                const { Data } = trip
                                if (Data) {
                                  const { WorkOrder } = Data
                                  if (WorkOrder && WorkOrder.Data) {
                                    const { Site } = WorkOrder.Data
                                    if (
                                      Site &&
                                      Site.Latitude &&
                                      Site.Longitude
                                    ) {
                                      return (
                                        <Marker
                                          key={trip.ID}
                                          heading={0}
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            width: 32,
                                            height: 42,
                                            marginTop: -42,
                                            marginLeft: -16,
                                            cursor: 'pointer',
                                          }}
                                          icon='assets/images/logos/SPMarker_Gradient.png'
                                          text={Site.Description}
                                          lat={Number(Site.Latitude)}
                                          lng={Number(Site.Longitude)}
                                          badge={index + 1}
                                          type='estimateSite'
                                          status={trip.Status}
                                          onClick={() => {
                                            if (trip.Status > 0) {
                                              openEditWorkOrderDialog(
                                                trip,
                                                trip.Trip,
                                              )
                                            }
                                          }}
                                        />
                                      )
                                    }
                                  }
                                }
                              })}
                          </GoogleMap>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </FuseAnimate>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTechnicians: Actions.getTechnicians,
      getUserData: Actions.getUserData,
      toggleInSelectedTechnicians: Actions.toggleInSelectedTechnicians,
      selectAllTechnicians: Actions.selectAllTechnicians,
      deSelectAllTechnicians: Actions.deSelectAllTechnicians,
      openEditTechnicianDialog: Actions.openEditTechnicianDialog,
      removeTechnicians: Actions.removeTechnicians,
      removeTechnician: Actions.removeTechnician,
      toggleStarredTechnician: Actions.toggleStarredTechnician,
      toggleStarredTechnicians: Actions.toggleStarredTechnicians,
      setTechniciansStarred: Actions.setTechniciansStarred,
      setTechniciansUnstarred: Actions.setTechniciansUnstarred,
      openNewTechnicianDialog: Actions.openNewTechnicianDialog,
      openNewTimecardDialog: TimecardActions.openNewTimecardDialog,
      openEditTimecardDialog: TimecardActions.openEditTimecardDialog,
      getTechnicianSchedule,
      getWOData: DispatchActions.getWOData,
      getTripData: DispatchActions.getTripData,
      setTrips: DispatchActions.setTrips,
      setBoardDate: DispatchActions.setBoardDate,
      loadingTrips: DispatchActions.loadingTrips,
      updateTrips: DispatchActions.updateTrips,
      setSchedule: DispatchActions.setSchedule,
      openEditWorkOrderDialog: WorkOrderActions.openEditWorkOrderDialog,
      openNewEstimateDialog,
      openEditEstimateDialog,
      updateTrip: DispatchActions.updateTrip,
      clockIn: Actions.clockIn,
      editPunch: Actions.editPunch,
      editTimecard: Actions.editTimecard,
      removePunch: Actions.removePunch,
      getTechnicianEstimates,
      setTechnicianEstimates,
      clearSearchTrips,
      setSiteSearchText: setSearchText,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers, technicianApp, techniciansApp }) {
  return {
    loading: spReducers.technician.loading,
    timecards: spReducers.technician.timecards,
    estimates: spReducers.technician.estimates,
    searchText: technicianApp.technician.searchText,
    user: spReducers.userProfiles.User,
    Co: spReducers.companies.Co,
    date: spReducers.technician.date,
    technician: spReducers.technician.technician,
    catalogs: spReducers.catalog.catalogs,
    gps: spReducers.gps,
    searchResults:
      techniciansApp.technicians.technicianDialog.data &&
        techniciansApp.technicians.technicianDialog.data.Data &&
        techniciansApp.technicians.technicianDialog.data.Data.searchResults
        ? techniciansApp.technicians.technicianDialog.data.Data.searchResults
        : null,
    userPreferences: spReducers.userProfiles.User.Preferences,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicianProfile)),
)
