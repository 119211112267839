import _ from '@lodash'
import {
  Badge,
  Avatar,
  Button,
  Icon,
  MenuItem,
  Tooltip,
  Typography,
  Chip,
  Dialog,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  DialogContent,
  AppBar,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import SignatureCanvas from 'react-signature-canvas'
import classNames from 'classnames'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import Media from 'react-media'
import { formatPhone } from 'main/functions'
import { TimePicker } from '@material-ui/pickers'
import TimecardBreak from './TimecardBreak'
import TimecardLabor from './TimecardLabor'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'

const styles = theme => ({
  root: {
    maxHeight: 'calc(100vh - 256px)',
    paddingBottom: 0,
  },
  avatar: {
    backgroundColor: theme.palette.primary[500],
  },
  techAvatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
  dividerPlain: {
    color: '#fff',
  },
  pageBreak: {},
  inlineBadge: {
    top: '25%',
    left: '110%',
    right: 'unset',
    fontSize: 11,
    padding: 4,
    height: 16,
    transform: 'none',
  },
})

const initialState = {
  selectedInvoiceIds: [],
  ranFetch: false,
  bulkTimecards: null,
  margin: null,
  printPDF: null,
  pdfOpen: false,
  pdfLoading: false,
}

class TimecardPDFDialog extends Component {
  state = {
    ...initialState,
  }

  componentDidMount() {
    const { selectedInvoiceIds } = this.props
    this.setState({ ...this.state, selectedInvoiceIds })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedInvoiceIds, runFetch } = this.props
    const { ranFetch } = this.state

    if (runFetch !== prevProps.runFetch && ranFetch === false) {
      this.setState({ ...this.state, selectedInvoiceIds }, () =>
        this.fetchTimecards(),
      )
    }
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  formatPhone = str => {
    return formatPhone(str).formatted
  }

  testFxn = () => {
    return true
  }

  fetchTimecards = async () => {
    const { timecards, getTimecards } = this.props

    this.setState({
      ...this.state,
      pdfOpen: true,
      pdfLoading: true,
      anchorEl: null,
    })

    let margin

    //TODO: start here
    let gridElement = await timecards.map((timecardData, i) => {
      const timecard = timecardData.timecard
      const {
        classes,
        employees,
        approve,
        source,
        laborTypes,
        supervisor,
        divisions,
        securables,
      } = this.props
      // const timecard = { ...this.state };
      const date = moment(moment(timecard.Date)).local().toDate()
      const { Co, Employee } = timecard
      const trips = this.props.trips || []
      const tcData = timecard ? timecard.Data : null
      const punches = tcData && tcData.Punch ? tcData.Punch : []
      const timeIn =
        tcData && tcData.Punch && tcData.Punch.length > 0
          ? tcData.Punch[0].TimeIn
          : null
      const timeOut =
        tcData && tcData.Punch && tcData.Punch.length > 0
          ? tcData.Punch[tcData.Punch.length - 1].TimeOut
          : null
      const employee = _.find(employees, { Co, Employee })
      const avatar = 'assets/images/avatars/profile.jpg'
      const suggestedTrips =
        tcData && tcData.Labor
          ? _.filter(
              trips,
              o =>
                (tcData && tcData.Labor
                  ? _.findIndex(tcData.Labor, { WorkOrder: o.WorkOrder })
                  : -1) < 0 &&
                o.CompletedTime &&
                (o.InProgressTime || o.ArrivedTime || o.EnRouteTime),
            )
          : []
      const suggested =
        tcData && tcData.Labor
          ? _.sumBy(
              suggestedTrips,
              trip =>
                (new Date(trip.CompletedTime) -
                  new Date(
                    trip.InProgressTime || trip.ArrivedTime || trip.EnRouteTime,
                  ).getTime()) /
                36e5,
            ).toFixed(2)
          : null
      const { DefaultBusinessUnit, DefaultDivision } = employee || {}
      const division =
        DefaultBusinessUnit && DefaultDivision
          ? _.find(divisions, {
              Co,
              BusinessUnit: DefaultBusinessUnit,
              Division: DefaultDivision,
            })
          : null
      const accessLevel = _.find(securables, { Securable: 'timecards' })
      const fullAccess = accessLevel && accessLevel.AccessLevel === 'F'
      if (!employee) {
        return <div></div>
      }

      return (
        // <div className="w-full relative">
        <div className={'w-full relative'} key={i}>
          <div className='inv-print sm:mx-8'>
            <div
              className='w-full flex justify-between mb-24'
              ref={this.props.refProp}
            >
              {division && division.InvoiceHeaderImg && (
                <img
                  className='h-36 sm:h-64 md:h-80'
                  src={division.InvoiceHeaderImg}
                />
              )}
              <span className='md:mt-12'>
                <label
                  className={classNames(
                    classes.invHeader,
                    'text-20 sm:text-36',
                  )}
                >
                  TIMECARD
                </label>
                {/* <Typography variant="caption" className={classNames(classes.invSubHeader, "text-10 sm:text-12 text-right")}><Icon color={!this.state.ID ? "action" : this.state.ApprovedYN === 'Y' ? "secondary" : this.state.ApprovedYN === 'N' ? "error" : "primary"} className="text-14 mr-4" style={{ verticalAlign: 'middle' }}>{!this.state.ID ? "add" : this.state.ApprovedYN === 'Y' ? "cloud_done" : this.state.ApprovedYN === 'N' ? "cloud_offline" : this.state.SubmittedYN === 'Y' ? "cloud_upload" : "edit"}</Icon>{!this.state.ID ? 'New' : this.state.ApprovedYN === 'Y' ? 'Approved' : this.state.ApprovedYN === 'N' ? 'Rejected' : this.state.SubmittedYN === 'Y' ? 'Submitted' : 'Open'}</Typography> */}
                {/* <div className={classNames(classes.invSubHeader, "text-10 sm:text-16")}>#{this.state.InvoiceNumber}</div> */}
              </span>
            </div>

            <div className='flex mb-24 justify-between px-16'>
              <div className='cursor-pointer'>
                <div className='flex cursor-pointer'>
                  <img
                    src={
                      employee.Data && employee.Data.Avatar
                        ? `${window['apiLocation']}/api/Attachment/GetProfileImage?Co=${Co}&ID=${employee.Data.Avatar}&Thumb=true`
                        : avatar
                    }
                    className='w-32 h-32 mr-8 rounded-full'
                  />
                  <div className='w-full'>
                    <div className='text-14 font-bold'>
                      {`${employee.FirstName} ${employee.LastName}`}
                    </div>
                    <div className='text-10'>{`Emp #${employee.Employee}`}</div>
                  </div>
                </div>
              </div>

              <div>
                <div className='flex cursor-pointer'>
                  <img
                    src='assets/icons/calendar_icon.png'
                    className='w-32 h-32 mr-8'
                  />
                  <div className='w-full'>
                    <div className='text-14 font-bold'>
                      {moment(this.state.Date).format('dddd')}
                    </div>
                    <div className='text-10'>
                      {moment(this.state.Date).format('MMM DD, YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.ApprovedYN === 'N' && (
              <div
                className='p-8 rounded m-24 py-24 w-full cursor-pointer'
                style={{ backgroundColor: '#f9f9f9', border: '1px dashed red' }}
              >
                <Typography className='text-16 font-bold text-red w-full text-center'>
                  <Icon color='error' className='mr-6 align-middle mb-2'>
                    cloud_off
                  </Icon>
                  TIMECARD REJECTED
                </Typography>
                <div className='w-full'>
                  <div className='italic mt-4 text-center'>
                    {this.state.Notes
                      ? `"${this.state.Notes}"`
                      : 'No Reason Given'}
                  </div>
                </div>
              </div>
            )}

            <TimePicker
              clearable
              inputVariant='outlined'
              views={['hours', 'minutes']}
              onOpen={() => this.setStartpickerOpen(true)}
              onClose={() => this.setStartpickerOpen(false)}
              open={this.state.isStartpickerOpen}
              className={classes.formControl}
              label='Time In'
              id='timeIn'
              format='h:mm A'
              openTo='hours'
              value={timeIn ? moment(timeIn).local().toDate() : date}
              onChange={this.handleTimeInChange}
              TextFieldComponent={() => ''}
            />

            <div
              className='flex'
              onClick={() => {
                if (
                  date.toLocaleDateString('en-US') ===
                    new Date().toLocaleDateString('en-US') &&
                  !timeIn
                ) {
                  const { Co, Employee } = timecard

                  const punch = {
                    Co,
                    Employee,
                    Date: date.toLocaleDateString('en-US'),
                    TimeIn: new Date().toLocaleString('en-US'),
                  }
                  this.props.clockIn(punch, source)
                } else {
                  this.openStartPicker()
                }
              }}
            >
              <div className='w-full'>
                <div
                  className='w-full flex justify-between rounded-full cursor-pointer h-32'
                  style={{ backgroundColor: '#3f3f3f' }}
                >
                  <div
                    className={classNames(
                      'w-auto rounded-full py-4 px-24 text-14 font-bold truncate cursor-pointer',
                      classes.dividerHighlight,
                    )}
                  >
                    {timeIn ? (
                      <React.Fragment>Time In</React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Icon className='mr-4 text-20 text-white align-middle mb-2'>
                          timer
                        </Icon>
                        Clock In
                      </React.Fragment>
                    )}
                  </div>
                  <div
                    className={classNames(
                      'w-1/2 pt-6 px-24 text-12 font-bold text-right truncate cursor-pointer',
                      classes.dividerPlain,
                    )}
                  >
                    {timeIn ? (
                      <React.Fragment>
                        {moment(timeIn).local().format('h:mm A')}
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full px-12 clearfix'>
              {tcData && timeIn && suggested && suggested > 0 && (
                <div
                  className='bg-grey-lighter p-12 py-4 rounded-lg mt-12'
                  style={{ border: '1px dotted lightgrey' }}
                >
                  <Typography
                    variant='h6'
                    style={{ alignItems: 'center', clear: 'both' }}
                    className='flex mb-8'
                  >
                    <Badge
                      badgeContent={suggested}
                      color='primary'
                      classes={{ badge: classes.inlineBadge }}
                    >
                      <span className='text-16'>Suggested</span>
                    </Badge>
                  </Typography>
                  {suggestedTrips.map(trip => {
                    const { WorkOrder } = trip
                    const assignment =
                      trip.Data.Assignments && trip.Data.Assignments.length > 0
                        ? _.orderBy(
                            trip.Data.Assignments,
                            ['Scope'],
                            ['asc'],
                          )[0]
                        : {}
                    const { Scope } = assignment
                    const scope = Scope
                      ? _.find(trip.Data.WorkOrder.Data.Scopes, { Scope })
                      : {}
                    const { BusinessUnit, Division } = scope
                    const exists =
                      (tcData && tcData.Labor
                        ? _.findIndex(tcData.Labor, { WorkOrder })
                        : -1) > -1
                    if (Scope && !exists) {
                      const laborType =
                        laborTypes &&
                        _.filter(laborTypes, {
                          Type: 'W',
                          BusinessUnit,
                          Division,
                        }).length > 0
                          ? _.filter(laborTypes, {
                              Type: 'W',
                              BusinessUnit,
                              Division,
                            })[0]
                          : null
                      const Qty = (
                        (new Date(trip.CompletedTime) -
                          new Date(
                            trip.InProgressTime ||
                              trip.ArrivedTime ||
                              trip.EnRouteTime,
                          ).getTime()) /
                        36e5
                      ).toFixed(2)
                      const suggested = {
                        Qty,
                        WorkOrder,
                        Scope,
                        Type: 'W',
                        Description: `Direct Labor - WO #${WorkOrder}`,
                        LaborType: laborType ? laborType.LaborType : null,
                        HourlyRate: laborType
                          ? laborType.StandardUnitCost
                          : null,
                        Total:
                          Qty * (laborType ? laborType.StandardUnitCost : 0),
                      }
                      return (
                        <TimecardLabor
                          key={`${WorkOrder}-${Scope}`}
                          /*onOpen={(hasOpenSuggestion) => this.setState({ hasOpenSuggestion })}*/ source={
                            source
                          }
                          date={this.state.Date}
                          employee={employee}
                          trips={[trip]}
                          data={suggested}
                          suggested={true}
                        />
                      )
                    }
                  })}
                </div>
              )}
              {tcData && timeIn && (
                <div className='w-full mb-12'>
                  <Typography
                    variant='h6'
                    style={{
                      alignItems: 'center',
                      marginTop: 4,
                      clear: 'both',
                    }}
                    className='flex'
                  >
                    <Badge
                      badgeContent={
                        tcData.Labor && _.sumBy(tcData.Labor, 'Qty').toFixed(2)
                      }
                      color='primary'
                      classes={{ badge: classes.inlineBadge }}
                    >
                      <span className='text-16'>Hours</span>
                    </Badge>
                  </Typography>
                  {tcData &&
                    tcData.Labor &&
                    tcData.Labor.map((value, index) => (
                      <TimecardLabor
                        source={source}
                        approve={Boolean(approve)}
                        date={this.state.Date}
                        employee={employee}
                        submitted={timecard.SubmittedYN}
                        approved={timecard.ApprovedYN}
                        key={value.ID}
                        trips={[...trips]}
                        data={{ ...value }}
                        hideIcons={true}
                      />
                    ))}
                  {/* {(fullAccess || timecard.SubmittedYN !== 'Y') &&
                                    <TimecardLabor source={source} date={this.state.Date} employee={employee} trips={[...trips]} data={null} />
                                } */}

                  {tcData && tcData.Punch && tcData.Punch.length > 1 && (
                    <Typography
                      variant='h6'
                      style={{
                        alignItems: 'center',
                        marginTop: -8,
                        clear: 'both',
                      }}
                      className='flex'
                    >
                      <span className='text-16'>Breaks</span>
                    </Typography>
                  )}

                  {tcData &&
                    tcData.Punch &&
                    tcData.Punch.map((value, index) => {
                      if (index > 0) {
                        return (
                          <TimecardBreak
                            source={source}
                            key={value.ID}
                            submitted={timecard.SubmittedYN}
                            data={{
                              StartRec: tcData.Punch[index - 1],
                              EndRec: value,
                            }}
                          />
                        )
                      }
                    })}
                </div>
              )}
            </div>
            <TimePicker
              clearable
              inputVariant='outlined'
              views={['hours', 'minutes']}
              onOpen={() => this.setEndpickerOpen(true)}
              onClose={() => this.setEndpickerOpen(false)}
              open={this.state.isEndpickerOpen}
              //className="no-print"
              format='h:mm A'
              openTo='hours'
              value={timeOut ? moment(timeOut).local().toDate() : date}
              onChange={this.handleTimeOutChange}
              TextFieldComponent={() => ''}
            />
            {timeIn && (
              <div
                className='flex mb-24'
                onClick={() => {
                  if (
                    date.toLocaleDateString('en-US') ===
                      new Date().toLocaleDateString('en-US') &&
                    timeIn &&
                    !timeOut
                  ) {
                    var rec = this.state
                    window['log'](rec)
                    const punch = tcData.Punch[tcData.Punch.length - 1]
                    punch.TimeOut = new Date().toLocaleString('en-US')
                    this.props.editPunch(punch, source)
                  } else {
                    this.openEndPicker()
                  }
                }}
              >
                <div className='w-full'>
                  <div
                    className='w-full flex justify-between rounded-full cursor-pointer h-32'
                    style={{ backgroundColor: '#3f3f3f' }}
                  >
                    <div
                      className={classNames(
                        'w-2/3 py-4 pt-6 pl-24 text-12 font-bold cursor-pointer',
                        classes.dividerPlain,
                      )}
                    >
                      {Boolean(timeOut) && (
                        <React.Fragment>Time Out</React.Fragment>
                      )}
                    </div>
                    <div
                      className={classNames(
                        'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between cursor-pointer',
                        classes.dividerHighlight,
                      )}
                    >
                      <div className='text-12 pt-4 font-bold mt-2 truncate'>
                        {timeOut
                          ? moment(timeOut).local().format('h:mm A')
                          : 'Clock Out'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {timeIn && timeOut && (
              <div className='w-full flex justify-end items-center font-bold mb-24'>
                <div className='text-14 px-8' style={{ color: '#3f3f3f' }}>
                  Duration:{' '}
                </div>
                <div
                  className='rounded-full text-12 px-16 py-6'
                  style={{ backgroundColor: '#3f3f3f', color: '#ffffff' }}
                >
                  {moment
                    .duration(moment(timeOut).diff(moment(timeIn)))
                    .asHours()
                    .toFixed(2)}{' '}
                  Hours
                </div>
              </div>
            )}
            {/* {this.state.showSigForPrint &&
                    <><span><strong style={{ marginRight: 4 }}>Signature:</strong></span><br />
                    <div className="w-full mb-12 rounded-lg" onClick={() => { if (timecard.SubmittedYN !== 'Y') { this.setState({ ...this.state, isSigning: true, sigImg: null }) } }} style={{ border: '1px dotted #3f3f3f', width: '100%', height: 150, borderRadius: 5 }}>
                        {
                            (this.state.sigImg || timecard.Signature) &&
                            <img style={{ width: '100%', height: 150 }} src={this.state.sigImg || timecard.Signature} />
                        }
                    </div></>
                    } */}
            {date.toLocaleDateString('en-US') ===
              new Date().toLocaleDateString('en-US') &&
              timeIn &&
              timeOut &&
              timecard.SubmittedYN !== 'Y' && (
                <React.Fragment>
                  {!this.state.sigImg && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        const { Co, Employee } = timecard

                        const punch = {
                          Co,
                          Employee,
                          Date: date.toLocaleDateString('en-US'),
                          TimeIn: new Date().toLocaleString('en-US'),
                        }
                        this.props.clockIn(punch, source)
                      }}
                      className={classNames('w-full mb-24 rounded-full')}
                    >
                      <Icon className='mr-8'>timer</Icon>
                      Clock In
                    </Button>
                  )}
                </React.Fragment>
              )}
          </div>
          <div className={classNames(classes.root, 'border-0')}>
            {timeIn && timeOut && (
              <React.Fragment>
                {!supervisor && (
                  <React.Fragment>
                    <Dialog
                      open={this.state.isSigning}
                      classes={{ paper: classes.signaturePaper }}
                      fullWidth
                      maxWidth='sm'
                      onClose={() => {
                        if (this.sigPad.isEmpty()) {
                          this.setState({ ...this.state, isSigning: false })
                        }
                      }}
                      onTouchMove={e => e.stopPropagation()}
                    >
                      <div className='w-full mb-12'>
                        <span>
                          <strong style={{ marginRight: 4 }}>Signature:</strong>
                        </span>
                        <br />
                      </div>
                      <div
                        className='w-full'
                        style={{
                          border: '1px dotted #3f3f3f',
                          borderRadius: 5,
                        }}
                      >
                        <SignatureCanvas
                          ref={ref => {
                            this.sigPad = ref
                          }}
                          penColor='#66aef9'
                          canvasProps={{
                            className: 'sigCanvas',
                            style: { width: '100%', height: 150 },
                          }}
                        />
                      </div>
                      <div className='flex w-full mt-16'>
                        <Button
                          variant='contained'
                          className={classes.clearBtn}
                          fullWidth
                          onClick={this.clear}
                        >
                          Clear
                        </Button>
                        <div className='min-w-12'></div>
                        <Button
                          variant='contained'
                          className={classes.okBtn}
                          fullWidth
                          onClick={this.trim}
                        >
                          OK
                        </Button>
                      </div>
                    </Dialog>
                    <span>
                      <strong style={{ marginRight: 4 }}>Signature:</strong>
                    </span>
                    <br />
                    <div
                      className='w-full mb-12 rounded-lg'
                      onClick={() => {
                        if (timecard.SubmittedYN !== 'Y') {
                          this.setState({
                            ...this.state,
                            isSigning: true,
                            sigImg: null,
                          })
                        }
                      }}
                      style={{
                        border: '1px dotted #3f3f3f',
                        width: '100%',
                        height: 150,
                        borderRadius: 5,
                      }}
                    >
                      {(this.state.sigImg || timecard.Signature) && (
                        <img
                          style={{ width: '100%', height: 150 }}
                          src={this.state.sigImg || timecard.Signature}
                        />
                      )}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          {/* <div className={classNames(i < timecards.length - 1 ? "page-break" : "")}></div> */}
          <div
            className={classNames(
              timecards[i + 1].timecard.Data ? 'page-break' : '',
            )}
          ></div>
        </div>
      )
    })

    this.setState(
      {
        ...this.state,
        bulkTimecards: gridElement,
        margin: margin,
        ranFetch: true,
      },
      () => this.props.resetRunFetch(),
    )

    this.printPDF()
  }

  printPDF = () => {
    //const { margin } = this.state;
    let margin = {
      top: `0.5 in`,
      bottom: `0.5 in`,
      left: `0.3 in`,
      right: `0.3 in`,
    }
    let gridElement = document.getElementById('timecard-print')
    drawDOM(gridElement, {
      keepTogether: '.prevent-split',
      forcePageBreak: '.page-break',
      paperSize: 'letter',
      margin,
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group, {
          title: `Multi-Invoice PDF`,
          creator: 'Service Point Pro',
        })
      })
      .then(dataUri => {
        this.setState({
          ...this.state,
          printPDF: dataUri,
          pdfLoading: false,
          ranFetch: false,
        })
      })
  }

  render() {
    const { bulkTimecards, pdfOpen, pdfLoading, printPDF, selectedInvoiceIds } =
      this.state

    window['warn']('timecard pdf gghh', this.props.timecards, bulkTimecards)

    return (
      <Media query='(min-width: 1200px)'>
        {matches => (
          <div>
            <Dialog
              open={bulkTimecards}
              style={{ transform: 'translate(5000px, 5000px' }}
              fullWidth
              maxWidth='md'
              fullScreen={!matches}
            >
              <DialogContent>
                <div id='timecard-print'>{bulkTimecards}</div>
              </DialogContent>
            </Dialog>

            {
              // PDF Dialog
              pdfOpen && (
                <Dialog
                  classes={{
                    paper: classNames(
                      'mt-0 mb-0 w-full h-full',
                      !matches && 'full-screen-dialog',
                    ),
                  }}
                  open={pdfOpen}
                  onClose={() =>
                    this.setState({
                      printPDF: null,
                      anchorEl: null,
                      pdfOpen: false,
                    })
                  }
                  fullWidth
                  maxWidth='md'
                  fullScreen={!matches}
                >
                  <AppBar position='static' className='dialog-header'>
                    <Toolbar className='flex w-full'>
                      <Typography variant='subtitle1' color='inherit'>
                        <Icon className='mr-6 align-middle mb-2'>print</Icon>
                        Timecards Preview
                      </Typography>
                    </Toolbar>
                    {pdfLoading && (
                      <IconButton
                        style={{
                          position: 'absolute',
                          right: 56,
                          top: matches.medium ? 8 : 4,
                          color: 'white',
                        }}
                        className='dialog-header-icon'
                      >
                        <Icon className='spin'>refresh</Icon>
                      </IconButton>
                    )}
                    <IconButton
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: matches.medium ? 8 : 4,
                        color: 'white',
                      }}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          printPDF: null,
                          pdfOpen: null,
                        })
                      }}
                      className='dialog-header-icon'
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </AppBar>
                  <DialogContent
                    classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}
                  >
                    {printPDF && (
                      <iframe
                        src={printPDF}
                        className='w-full overflow-auto'
                        style={{ height: 'calc(100% - 24px)' }}
                      />
                    )}
                  </DialogContent>
                </Dialog>
              )
            }
          </div>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //fetchInvoices: Actions.fetchInvoices,
      getTimecards: Actions.getTimecards,
    },
    dispatch,
  )
}

function mapStateToProps({
  invoicesApp,
  spReducers,
  timecardsApp,
  dispatchBoardApp,
}) {
  return {
    loading: timecardsApp.timecards.timecardDialog.loading,
    selectedTimecardIds: timecardsApp.timecards.selectedTimecardIds,
    searchText: timecardsApp.timecards.searchText,
    user: spReducers.userProfiles.User,
    workOrderData: spReducers.workOrders,
    date:
      timecardsApp.timecards.timecardDialog.date ||
      dispatchBoardApp.dispatchBoard.date,
    //timecards: spReducers.timecards,
    schedule: dispatchBoardApp.dispatchBoard.schedule,
    Co: spReducers.companies.Co,
    employees: spReducers.employees,
    laborTypes: spReducers.laborTypes,
    securables: spReducers.userProfiles.User.Data.Securables,
    divisions: spReducers.divisions,
    timecards: spReducers.technician.timecards,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TimecardPDFDialog)),
)
