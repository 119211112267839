import { FuseAnimate } from '@fuse';
import _ from '@lodash';
import { Button, Card, CardContent, CardHeader, Icon, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { TimePicker } from "@material-ui/pickers";
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {

    },
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 30,
        height: 30,
        marginTop: -6,
        marginBottom: -6,
    },
    selectedCallAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    avatarTech: {
        backgroundColor: theme.palette.primary[500],
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    card: {
        marginTop: 0,
        marginBottom: 8,
        cursor: 'pointer',
        width: '100%',
        border: '1px solid lightgrey',
    },
    addButton: {
        marginTop: 0,
        marginBottom: 8,
        cursor: 'pointer',
        width: '100%',
        border: 'none',
        boxShadow: 'none'
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    tabs: {
        width: '100%',
    },
    tab: {
        minWidth: '25%',
        maxWidth: '25%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    action: {
        marginTop: 0,
        marginRight: -8,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    info: {
        padding: 8,
        paddingTop: 0,
        paddingBottom: 0 + '!important',
    },
    campaign: {
        width: 'auto',
        top: 0,
        right: -5,
        marginTop: 5,
        borderRadius: 4,
        padding: 10,
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: 12,
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: 11,
    },
    tripAdminTab: {
        minWidth: '20%',
        maxWidth: '20%',
        paddingTop: 12,
        paddingBottom: 12,
    },
    container: {
        padding: 0,
    },
    trip: {
        minWidth: 300,
    },
    tripTabContent: {
        minHeight: 175,
        maxHeight: 175,
        overflow: 'auto',
    },
    formControl: {
        marginBottom: 12
    },
    footer: {
        top: 'auto',
        bottom: 0,
    },
    scopeBadge: {
        top: 5,
        right: 2,
    },
    tripBadge: {
        top: 35,
        right: 35,
        background: '#3f3f3f',
    },
    tripBadgeIcon: {
        fontSize: 14,
        marginTop: 1,
    },
    tripCountBadge: {
        top: 15,
        right: -7,
        height: 17,
        minWidth: 17,
        fontSize: '1rem',
        border: '2px solid #3f3f3f',
        paddingTop: 2,
    },
    input: {
        height: 36,
    },
    inputLabel: {
        fontSize: 12,
        '&[data-shrink="false"]': {
            marginTop: -8,
        },
        '&[data-shrink="true"]': {
            marginTop: 0
        }
    },
    cardContent: {
        padding: 12,
        borderTop: '1px solid lightgrey',
        paddingBottom: '4px !important',
    },
    addButtonContent: {
        padding: 12,
        border: '1px solid lightgrey',
        borderRadius: 4,
        paddingBottom: '4px !important',
    },
    updateButton: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
});
const initialState = {
    StartRec: {
        TimeIn: null,
        TimeOut: null,
        Notes: null,
    },
    EndRec: {
        TimeIn: null,
        TimeOut: null,
        Notes: null,
    },
    open: false,
    isStartpickerOpen: false,
    isEndpickerOpen: false,
};

class TimecardBreak extends Component {
    state = initialState;

    componentDidMount = () => {
        const { data } = this.props;
        if (data && !_.isEqual({ ...initialState, ...data }, this.state)) {
            this.setState({ ...initialState, ...data });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleOpen = () => {
        let { open } = this.state;
        open = !open;
        this.setState({ open });
    }

    stop = (event) => {
        event.stopPropagation();
    }

    formatTime(time) {
        if (time) {
            return moment(time).format("h:mm A")
            // return tm.toLocaleTimeString('en-US');
        } else {
            return '';
        }
    }

    handleTimeOutChange = (event) => {
        let { StartRec, EndRec } = this.state;
        window["warn"](event);
        // if (moment(EndRec.TimeIn).toDate() >= event._d) {
        StartRec.TimeOut = event._d.toLocaleString('en-US');
        this.setState({ ...this.state, StartRec }, () => window["warn"](this.state));
        // }
    };

    handleTimeInChange = (event) => {
        let { StartRec, EndRec } = this.state;
        window["warn"](event);
        // if (moment(StartRec.TimeOut).toDate() <= event._d) {
        EndRec.TimeIn = event._d.toLocaleString('en-US');
        this.setState({ ...this.state, EndRec }, () => window["warn"](this.state));
        // }
    };

    openStartPicker = (e) => {
        const { submitted } = this.props;
        if (submitted !== 'Y') {
            this.setStartpickerOpen(true);
        }
    }

    openEndPicker = (e) => {
        const { submitted } = this.props;
        if (submitted !== 'Y') {
            this.setEndpickerOpen(true);
        }
    }

    renderStartLabel = (classes) => (
        <TextField
            className={classes.formControl}
            label="Time Out"
            InputProps={{
                className: classes.input,
            }}
            InputLabelProps={{
                className: classes.inputLabel
            }}
            onClick={this.openStartPicker}
            value={this.formatTime(moment(moment(this.state.StartRec.TimeOut)).local().toDate().toLocaleString('en-US'))}
            variant="outlined"
            disabled={this.props.submitted === 'Y'}
            fullWidth
        />
    );

    renderEndLabel = (classes) => (
        <TextField
            className={classes.formControl}
            label="Time In"
            InputProps={{
                className: classes.input,
            }}
            InputLabelProps={{
                className: classes.inputLabel
            }}
            onClick={this.openEndPicker}
            value={this.formatTime(moment(moment(this.state.EndRec.TimeIn)).local().toDate().toLocaleString('en-US'))}
            variant="outlined"
            disabled={this.props.submitted === 'Y'}
            fullWidth
        />
    );

    setStartpickerOpen = (bool) => {
        this.setState({ isStartpickerOpen: bool });
    }

    setEndpickerOpen = (bool) => {
        this.setState({ isEndpickerOpen: bool });
    }

    render() {
        const { classes, data, submitted, source } = this.props;

        const { value, timecard, trip, tripValue, open } = this.state;
        window["warn"]('Timecard Break data: ', data, data.StartRec, data.EndRec, moment(data.StartRec.TimeOut).local().toDate(), moment(data.EndRec.TimeOut).local().toDate());

        return (
            <Card onClick={this.stop} onMouseDown={this.stop} onMouseMove={this.stop} className={classNames(classes.card, "dispatch-work-order")}>
                <CardHeader onClick={this.handleOpen} className={classNames("p-0 pl-12 pr-12")}
                    classes={
                        { content: classes.content, action: classes.action, avatar: "mr-4", title: classes.title, subheader: classes.subheader, }
                    }
                    avatar={
                        <Icon className="text-20 mt-6" color="error">pause_circle_outline</Icon>
                    }
                    action={
                        <Typography className="flex text-12 mt-4">
                            <span className="mt-4 font-bold">{`${this.formatTime(moment(moment(data.StartRec.TimeOut)).local().toDate().toLocaleString('en-US'))} - ${this.formatTime(moment(moment(data.EndRec.TimeIn)).local().toDate().toLocaleString('en-US'))}`}</span><Icon color="action" style={{ marginTop: 2 }} className="text-24 mr-8 ml-4">{!open ? "keyboard_arrow_down" : "keyboard_arrow_up"}</Icon>
                        </Typography>
                    }
                    title={
                        <Typography className="flex text-12 font-bold">
                            <span style={{ marginTop: 2 }}>{`${data.StartRec.Notes || 'Break'}`}</span>
                        </Typography>
                    }
                />

                {open &&
                    <FuseAnimate animation="transition.slideDownIn">
                        <CardContent classes={{ root: data ? classes.cardContent : classes.addButtonContent }}>

                            <div className="sm:flex">
                                <TimePicker
                                    inputVariant="outlined"
                                    className={classes.formControl}
                                    label="Time Out"
                                    id="timeOut"
                                    format="h:mm A"
                                    openTo="minutes"
                                    value={moment(data.StartRec.TimeOut).toDate()}
                                    onChange={this.handleTimeOutChange}
                                    margin="dense"
                                    autoOk={false}
                                    fullWidth
                                    disabled={submitted === 'Y'}
                                />
                                <div className="hidden sm:block min-w-8 pt-20">
                                </div>
                                <TimePicker
                                    inputVariant="outlined"
                                    className={classes.formControl}
                                    label="Time In"
                                    id="timeIn"
                                    format="h:mm A"
                                    openTo="minutes"
                                    value={moment(data.EndRec.TimeIn).toDate()}
                                    onChange={this.handleTimeInChange}
                                    margin="dense"
                                    autoOk={false}
                                    fullWidth
                                    disabled={submitted === 'Y'}
                                />
                            </div>
                            <div className="sm:flex">
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="StartRec.Notes"
                                    margin="dense"
                                    value={this.state.StartRec.Notes || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    disabled={submitted === 'Y'}
                                    fullWidth
                                />
                            </div>
                            <div className="w-full flex">
                                <Button
                                    className={classNames(classes.formControl, classes.updateButton, "w-full")}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.props.editBreak({ ...this.state }, source);
                                        this.setState({ ...this.state, open: false });
                                    }}
                                    disabled={submitted === 'Y'}
                                >
                                    Update
                                </Button>
                                <Button
                                    className={classNames(classes.formControl, classes.deleteButton)}
                                    variant="contained"
                                    onClick={() => {
                                        this.props.removeBreak({ ...this.state }, source);
                                        this.setState({ ...this.state, open: false });
                                    }}
                                    disabled={submitted === 'Y'}
                                >
                                    <Icon>delete</Icon>
                                </Button>
                            </div>
                        </CardContent>
                    </FuseAnimate>
                }
            </Card>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        editBreak: Actions.editBreak,
        removeBreak: Actions.removeBreak,
    }, dispatch);
}

function mapStateToProps({ spReducers, dispatchBoardApp, dialer }) {
    return {
        user: dispatchBoardApp.user,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TimecardBreak)));
