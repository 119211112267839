import axios from 'axios/index';
import { getUserData } from 'main/content/apps/services/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_SERVICES = '[SERVICES APP] GET SERVICES';
export const SET_SEARCH_TEXT = '[SERVICES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_SERVICES = '[SERVICES APP] TOGGLE IN SELECTED SERVICES';
export const SELECT_ALL_SERVICES = '[SERVICES APP] SELECT ALL SERVICES';
export const DESELECT_ALL_SERVICES = '[SERVICES APP] DESELECT ALL SERVICES';
export const OPEN_NEW_SERVICE_DIALOG = '[SERVICES APP] OPEN NEW SERVICE DIALOG';
export const CLOSE_NEW_SERVICE_DIALOG = '[SERVICES APP] CLOSE NEW SERVICE DIALOG';
export const OPEN_EDIT_SERVICE_DIALOG = '[SERVICES APP] OPEN EDIT SERVICE DIALOG';
export const CLOSE_EDIT_SERVICE_DIALOG = '[SERVICES APP] CLOSE EDIT SERVICE DIALOG';
export const ADD_SERVICE = '[SERVICES APP] ADD SERVICE';
export const UPDATE_SERVICE = '[SERVICES APP] UPDATE SERVICE';
export const REMOVE_SERVICE = '[SERVICES APP] REMOVE SERVICE';
export const REMOVE_SERVICES = '[SERVICES APP] REMOVE SERVICES';
export const TOGGLE_STARRED_SERVICE = '[SERVICES APP] TOGGLE STARRED SERVICE';
export const TOGGLE_STARRED_SERVICES = '[SERVICES APP] TOGGLE STARRED SERVICES';
export const SET_SERVICES_STARRED = '[SERVICES APP] SET SERVICES STARRED ';

export function getServices(routeParams) {
    const request = axios.get('/api/services-app/services', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_SERVICES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedServices(serviceId) {
    return {
        type: TOGGLE_IN_SELECTED_SERVICES,
        serviceId
    }
}


export function selectAllServices() {
    return {
        type: SELECT_ALL_SERVICES
    }
}

export function deSelectAllServices() {
    return {
        type: DESELECT_ALL_SERVICES
    }
}


export function openNewServiceDialog() {
    return {
        type: OPEN_NEW_SERVICE_DIALOG
    }
}

export function closeNewServiceDialog() {
    return {
        type: CLOSE_NEW_SERVICE_DIALOG
    }
}

export function openEditServiceDialog(data) {
    return {
        type: OPEN_EDIT_SERVICE_DIALOG,
        data
    }
}

export function closeEditServiceDialog() {
    return {
        type: CLOSE_EDIT_SERVICE_DIALOG
    }
}

export function addService(newService) {
    return (dispatch, getState) => {

        const { routeParams } = getState().servicesApp.services;

        const request = axios.post(`${window["apiLocation"]}/api/Service`,
            newService
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_SERVICE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service ${newService.Service} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateService(service) {
    return (dispatch, getState) => {

        const { routeParams } = getState().servicesApp.services;

        const request = axios.put(`${window["apiLocation"]}/api/Service?Co=${encodeURIComponent(service.Co)}&Service=${encodeURIComponent(service.Service)}`,
            service
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_SERVICE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Service ${service.Service} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeService(serviceId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().servicesApp.services;

        const request = axios.post('/api/services-app/remove-service', {
            serviceId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICE
                })
            ]).then(() => dispatch(getServices(routeParams)))
        );
    };
}


export function removeServices(serviceIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().servicesApp.services;

        const request = axios.post('/api/services-app/remove-services', {
            serviceIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_SERVICES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICES
                })
            ]).then(() => dispatch(getServices(routeParams)))
        );
    };
}

export function toggleStarredService(serviceId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().servicesApp.services;

        const request = axios.post('/api/services-app/toggle-starred-service', {
            serviceId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServices(routeParams)))
        );
    };
}

export function toggleStarredServices(serviceIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().servicesApp.services;

        const request = axios.post('/api/services-app/toggle-starred-services', {
            serviceIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_SERVICES
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServices(routeParams)))
        );
    };
}

export function setServicesStarred(serviceIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().servicesApp.services;

        const request = axios.post('/api/services-app/set-services-starred', {
            serviceIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServices(routeParams)))
        );
    };
}

export function setServicesUnstarred(serviceIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().servicesApp.services;

        const request = axios.post('/api/services-app/set-services-unstarred', {
            serviceIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_SERVICES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_SERVICES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getServices(routeParams)))
        );
    };
}
