import axios from 'axios/index';

export const SET_VOICE_MAILBOX_DATA = '[SPCONNECTION] SET VOICE MAILBOX DATA';
export const GET_VOICE_MAILBOX_DATA = '[SPCONNECTION] GET VOICE MAILBOX DATA';
export const SUBSCRIBE_VOICE_MAILBOX_LIST = '[SPCONNECTION] SUBSCRIBE VOICE MAILBOX LIST';

export function getInitialVoiceMailboxData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VoiceMailbox`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getVoiceMailboxData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/VoiceMailbox`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_VOICE_MAILBOX_DATA,
                payload: response.data
            })
        });
    }
}

export function setVoiceMailboxData(data) {
    return {
        type: SET_VOICE_MAILBOX_DATA,
        payload: data
    };
}