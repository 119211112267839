import * as Actions from '../actions';

const intialState = {
    map: false,
    chat: false,
    search: null,
}
const contactsReducer = function (state = false, action) {
    switch (action.type) {
        case Actions.OPEN_CHAT_PANEL:
            {
                return {
                    ...state,
                    chat: true,
                }
            }
        case Actions.SET_MAP_SEARCH:
            {
                const {search} = action;
                return {
                    ...state,
                    search,
                    map: Boolean(search) ? state.map || 'fullHeight' : state.map
                }
            }
        case Actions.CLOSE_CHAT_PANEL:
            {
                return {
                    ...state,
                    chat: false,
                }
            }
        case Actions.TOGGLE_CHAT_PANEL:
            {
                return {
                    ...state,
                    chat: !state.chat,
                }
            }
        case Actions.OPEN_MAP:
            {
                return {
                    ...state,
                    map: state.map || 'fullHeight',
                }
            }
        case Actions.RESIZE_MAP:
            {
                return {
                    ...state,
                    map: action.size,
                }
            }
        case Actions.CLOSE_MAP:
            {
                return {
                    ...state,
                    map: false,
                }
            }
        case Actions.TOGGLE_MAP:
            {
                return {
                    ...state,
                    map: !state.map,
                };
            }
        default:
            {
                return state;
            }
    }
};

export default contactsReducer;