import axios from 'axios/index';
import { getFilters } from './filters.actions';
import { getFolders } from './folders.actions';
import { getLabels } from './labels.actions';
import _ from '@lodash';

import { showMessage, setCalendarData } from 'store/actions';

export const GET_TODOS = '[TODO APP] GET TODOS';
export const UPDATE_TODOS = '[TODO APP] UPDATE TODOS';
export const TOGGLE_STARRED = '[TODO APP] TOGGLE STARRED';
export const TOGGLE_COMPLETED = '[TODO APP] TOGGLE COMPLETED';
export const TOGGLE_IMPORTANT = '[TODO APP] TOGGLE IMPORTANT';
export const UPDATE_TODO = '[TODO APP] UPDATE TODO';
export const ADD_TODO = '[TODO APP] ADD TODO';
export const REMOVE_TODO = '[TODO APP] REMOVE TODO';
export const SET_SEARCH_TEXT = '[TODO APP] SET SEARCH TEXT';
export const OPEN_NEW_TODO_DIALOG = '[TODO APP] OPEN NEW TODO DIALOG';
export const CLOSE_NEW_TODO_DIALOG = '[TODO APP] CLOSE NEW TODO DIALOG';
export const OPEN_EDIT_TODO_DIALOG = '[TODO APP] OPEN EDIT TODO DIALOG';
export const CLOSE_EDIT_TODO_DIALOG = '[TODO APP] CLOSE EDIT TODO DIALOG';
export const TOGGLE_ORDER_DESCENDING = '[TODO APP] TOGGLE ORDER DESCENDING';
export const CHANGE_ORDER = '[TODO APP] CHANGE ORDER';

export function getData(match) {
    return (dispatch) => {
        Promise.all([
            dispatch(getFilters()),
            dispatch(getFolders()),
            dispatch(getLabels())
        ]).then(
            () => dispatch(getTodos(match)));
    }
}

export function getTodos(match) {

    return (dispatch, getState) => {
        const state = getState();
        const { ToDos } = state.spReducers.calendar.data;
        dispatch({
            type: GET_TODOS,
            routeParams: match.params,
            payload: ToDos
        })
    }
}

export function updateTodos() {
    return (dispatch, getState) => {

        const { routeParams } = getState().todoApp.todos;

        window["warn"]('ToDo Updated Params', routeParams);
        const request = axios.get('/api/todo-app/todos', {
            params: routeParams
        });

        return request.then((response) =>
            dispatch({
                type: UPDATE_TODOS,
                payload: response.data
            })
        );
    }
}

export function toggleCompleted(todo) {
    const newTodo = {
        ...todo,
        CompletedYN: todo.CompletedYN != "Y" ? "Y" : null
    };
    return (dispatch) => dispatch(updateTodo(newTodo));
}

export function toggleStarred(todo) {
    const newTodo = {
        ...todo,
        StarredYN: todo.StarredYN != "Y" ? "Y" : null
    };
    return (dispatch) => dispatch(updateTodo(newTodo));
}

export function toggleImportant(todo) {
    const newTodo = {
        ...todo,
        ImportantYN: todo.ImportantYN != "Y" ? "Y" : null
    };

    return (dispatch) => dispatch(updateTodo(newTodo));
}

export function openNewTodoDialog() {
    return {
        type: OPEN_NEW_TODO_DIALOG
    }
}

export function closeNewTodoDialog() {
    return {
        type: CLOSE_NEW_TODO_DIALOG
    }
}

export function openEditTodoDialog(data) {
    return {
        type: OPEN_EDIT_TODO_DIALOG,
        data
    }
}

export function closeEditTodoDialog() {
    return {
        type: CLOSE_EDIT_TODO_DIALOG
    }
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value.toLowerCase()
    }
}

export function toggleOrderDescending() {
    return {
        type: TOGGLE_ORDER_DESCENDING
    }
}

export function changeOrder(orderBy) {
    return {
        type: CHANGE_ORDER,
        orderBy
    }
}


export function addTodo(newItem) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/ToDoItem`, newItem);

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `To-Do Item successfully added.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const state = getState();
                const data = _.cloneDeepWith(state.spReducers.calendar.data);
                const { month } = state.spReducers.calendar;
                const index = _.findIndex(data.ToDos, { ID: response.data.ID });
                window["warn"]('Added To-Do Item: ', response.data, data, index);
                if (index > -1) {
                    data.ToDos.splice(index, 1, response.data);
                } else {
                    data.ToDos.push(response.data);
                }
                dispatch(setCalendarData(data, month));
            }
        });
    };
}

export function updateTodo(item) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/ToDoItem?Item=${item.ID}`, item);

        return request.then((response) => {
            if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data.Data.ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `To-Do Item successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const state = getState();
                const data = _.cloneDeepWith(state.spReducers.calendar.data);
                const { month } = state.spReducers.calendar;
                const index = _.findIndex(data.ToDos, { ID: response.data.ID });
                window["warn"]('Updated To-Do Item: ', response.data, data, index);
                if (index > -1) {
                    data.ToDos.splice(index, 1, response.data);
                }
                dispatch(setCalendarData(data, month));
            }
        });
    };
}

export function removeTodo(itemId) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/ToDoItem?Item=${itemId}`);

        return request.then((response) => {
            if (response.data && response.data.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${response.data}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `To-Do Item successfully removed.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
                const state = getState();
                const data = _.cloneDeepWith(state.spReducers.calendar.data);
                const { month } = state.spReducers.calendar;
                const index = _.findIndex(data.ToDos, { ID: itemId });
                if (index > -1) {
                    data.ToDos.splice(index, 1);
                }
                dispatch(setCalendarData(data, month));
            }

        });
    }
}