import { FuseUtils } from '@fuse';
import _ from '@lodash';
import { Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CatalogAssemblyCard from '../catalog/CatalogAssemblyCard';
import CatalogTMCard from '../catalog/CatalogTMCard';
import { updateTableSettings } from '../users/store/actions';
import { openNewAgreementDialog } from '../agreements/store/actions';
import * as Actions from './store/actions';

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 256px)',
        paddingBottom: 0,
    },
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {},
    techAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },

});

class EstimatesList extends Component {

    state = {
        components: [],
    };

    componentDidMount() {
        const { components } = this.props;
        if (components) {
            this.setState({ components });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { components } = this.props;
        if (components && !_.isEqual(components, prevProps.components)) {
            this.setState({ components });
        }
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    removeComponent = (value) => {
        if (this.props.onRemoveComponent) {
            this.props.onRemoveComponent();
        }
    }

    handleUpdateAgreement = (agreement) => {
        window["warn"]('Updated Agreement Info: ', agreement);
        const { estimate } = this.props;
        const data = {};
        Object.keys(agreement).map((key) => {
            if (key.substr(0, 1) === key.substr(0, 1).toUpperCase()) {
                data[key] = agreement[key];
            }
        })
        delete data.Data.Site;
        delete data.Data.Customer;
        const { Co, BusinessUnit, Division, Data } = estimate;
        const { Address1, City, State, Zip } = Data.Site;
        const { Components } = Data;
        const index = _.findIndex(Components, { Component: agreement.Component });
        if (index > -1) {
            const component = Components[index];
            component.Price = agreement.TotalPrice;
            component.PriceMethod = 'F';
            component.CustomPrice = agreement.TotalPrice;
            component.CustomMemberPrice = agreement.TotalPrice;
            component.AgreementData = JSON.stringify(data);
            component.Name = agreement.Data.AgreementType.Name;
            component.Description = agreement.Data.AgreementType.Description;
            component.CustomImgURL = `https://maps.googleapis.com/maps/api/streetview?key=${googleMapsApiKey}&size=200x140&location=${encodeURIComponent(`${Address1} ${City}, ${State} ${Zip}`)}`;
            if (this.props.onUpdate) {
                Components.splice(index, 1, component);
                this.props.onUpdate(Components);
            }
            window["warn"]('New Agreement Info: ', data, JSON.stringify(data));
        };
    }

    render() {
        const { classes, catalog, estimate, onAddNew, optionBoard, topBar, selectedComponent, materials, laborTypes, SelectedPricing } = this.props;
        const { components } = this.state;

        const tempComponents = components ? _.filter(components, (o) => o.DeleteYN !== 'Y') : [];

        window["warn"]("estimates list components", this.props.components)

        return (
            <div className="w-full flex max-w-full overflow-x-auto">
                {components && tempComponents.map((value) => {
                    const { Co, BusinessUnit, Division, Material, LaborType } = value;
                    const assembly = value.Data.Assembly;
                    const material = _.find(materials, { Co, Material });
                    const laborType = _.find(laborTypes, { Co, BusinessUnit, Division, LaborType });

                    let multipleOfSameComponent = false;
                    if (components.length > 0) {
                        let componentList = [...components];
                        let multiples = _.remove(componentList, (o) => o.Assembly === value.Assembly);
                        if (multiples.length > 1) {
                            multipleOfSameComponent = true;
                        }
                    }

                    if (assembly) {
                        return (
                            <CatalogAssemblyCard
                                topBar={topBar}
                                onClickBefore={onAddNew}
                                estimate={estimate}
                                SelectedPricing={SelectedPricing}
                                onSelected={(assembly) => { if (this.props.onSelected) { this.props.onSelected(assembly) } }}
                                onAddComponent={(components) => { if (this.props.onUpdate) { this.props.onUpdate(components); } }}
                                onRemoved={(components) => { if (this.props.onUpdate) { this.props.onUpdate(components); } }}
                                onUpdated={(components) => { if (this.props.onUpdate) { this.props.onUpdate(components); } }}
                                onDelete={() => this.removeComponent()}
                                value={assembly}
                                component={_.cloneDeep(value)}
                                key={value.Component}
                                multipleOfSameComponent={multipleOfSameComponent}
                            />
                        );
                    }
                    if (!assembly) {
                        return (
                            <CatalogTMCard
                                topBar={topBar}
                                estimate={estimate}
                                onSelected={(item) => {
                                    if (item.AgreementData) {
                                        const agr = JSON.parse(item.AgreementData);
                                        agr.Data.Customer = estimate.Data.Customer;
                                        agr.Data.Site = estimate.Data.Site;
                                        agr.Component = item.Component;
                                        this.props.openNewAgreementDialog(agr, undefined, this.handleUpdateAgreement);
                                    } else {
                                        if (this.props.onSelectedItem) {
                                            this.props.onSelectedItem(item)
                                        }
                                    }
                                }}
                                onAdded={(components) => this.setState({ components }, () => { if (this.props.onUpdate) { this.props.onUpdate(components); } })}
                                onRemoved={(components) => this.setState({ components }, () => { if (this.props.onUpdate) { this.props.onUpdate(components); } })}
                                onUpdated={(components) => this.setState({ components }, () => { if (this.props.onUpdate) { this.props.onUpdate(components); } })}
                                onDelete={() => this.removeComponent()}
                                value={value}
                                component={value}
                                key={value.Component}
                                multipleOfSameComponent={multipleOfSameComponent}
                            />
                        );
                    }
                })}
                {onAddNew && !optionBoard &&
                    <Typography variant="subtitle2" onClick={onAddNew} style={{ alignItems: 'center' }} className="flex mt-12 mb-12 cursor-pointer"><Icon color="primary" className="text-24 m-6">add</Icon>Add Assembly</Typography>
                }
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditEstimateDialog: Actions.openEditEstimateDialog,
        openNewAgreementDialog,
        updateTableSettings,
    }, dispatch);
}

function mapStateToProps({ estimatesApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        companies: spReducers.companies,
        user: spReducers.userProfiles.User,
        users: spReducers.userProfiles.Users,
        technicians: spReducers.technicians,
        materials: spReducers.materials.materials,
        laborTypes: spReducers.laborTypes,
        // assemblies: spReducers.catalog.assemblies
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesList)));
