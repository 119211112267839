import axios from 'axios/index';

export const SET_PAY_TERM_DATA = '[SPCONNECTION] SET PAY TERM DATA';
export const GET_PAY_TERM_DATA = '[SPCONNECTION] GET PAY TERM DATA';
export const SUBSCRIBE_PAY_TERM_LIST = '[SPCONNECTION] SUBSCRIBE PAY TERM LIST';

export function getInitialPayTermData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/PayTerm`, {
        params: {
            Co
        }
    });
    request.then((response) => {
        return response.data;
    });
}

export function getPayTermData(Co) {
    const request = axios.get(`${window["apiLocation"]}/api/PayTerm`, {
        params: {
            Co
        }
    });

    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_PAY_TERM_DATA,
                payload: response.data
            })
        }).then(dispatch({
            type: SUBSCRIBE_PAY_TERM_LIST,
            Co
        }));
    }
}

export function setPayTermData(data) {
    return {
        type: SET_PAY_TERM_DATA,
        payload: data
    };
}