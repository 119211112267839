import { FuseChipSelect } from '@fuse/index';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Button, Divider, Drawer, FormControlLabel, Icon, IconButton, ListItem, ListItemText, MenuItem, Switch, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import clsx from 'clsx';
import * as Actions from 'main/content/apps/scrumboard/store/actions';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import React, { Component } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import connect from 'react-redux/es/connect/connect';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import RecycleBinDrawer from '../drawers/RecycleBinDrawer';
import BoardAddList from './BoardAddList';
import BoardList from './BoardList';
import BoardSearchBar from './BoardSearchBar';
import Autocomplete from '../../../components/autocomplete/Autocomplete';
import getProfileImage from '../../../../functions/getProfileImageUrl';

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.contrastBg,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // padding: 24,
        // overflow: 'auto',
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    updateRecord: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: 80,
    },
    deleteRecord: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: theme.palette.error[500],
        color: '#fff',
        minWidth: 32
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100vh - 64px)',
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    memberBadge: {
        top: 16,
        right: 16,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: '50%'
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 24,
        height: 24,
        marginTop: 0,
        marginBottom: 0,
    },
});

class Board extends Component {

    state = {
        menuOpen: false,
        view: "row",
        recycleBinOpen: false,
        settingsDrawerOpen: false,
        confirm: false,
        newName: null,
        boardMember: null
    };

    componentDidMount() {
        const { board, view } = this.props;
        if (board) {
            this.setState({ newName: board.Name, view });
        }
        this.props.getBoard(this.props.match.params.Co, this.props.match.params.boardId);
    }

    componentDidUpdate(prevProps, prevState) {
        const { board, view } = this.props;
        if (!_.isEqual(board, prevProps.board)) {
            if (board) {
                this.setState({ newName: board.Name, view });
            } else {
                this.setState({ newName: null, view });
            }
        } else {
            if (prevProps.view !== view) {
                this.setState({ view });
            }
        }
    }

    componentWillUnmount() {
        this.setState({ newName: null }, () => this.props.resetBoard());
    }

    canBeSubmitted = () => {
        return (this.state.newName && this.state.newName.length > 0);
    }

    onDragEnd = (data) => {
        const result = _.cloneDeep(data);
        const { source, destination, combine } = result;

        result.draggableId = Number(result.draggableId)
        if (result.type !== 'list') {
            if (source) source.droppableId = Number(source.droppableId)
            if (destination) destination.droppableId = Number(destination.droppableId)
            if (combine) {
                combine.draggableId = Number(combine.draggableId)
                combine.droppableId = Number(combine.droppableId)
            }
        }

        // make task a subtask
        if (combine) {
            this.props.convertToSubtask(result)
        }

        // dropped nowhere
        if (!destination) {
            return;
        }

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // reordering list
        if (result.type === 'list') {
            this.props.reorderList(result);
        }

        // reordering card
        if (result.type === 'card') {
            this.props.reorderCard(result);
        }
    };

    toggleSettingsDrawer = (state) => {
        state = (state === undefined) ? !this.state.settingsDrawerOpen : state;

        this.setState({
            settingsDrawerOpen: state
        });
    };

    setAssignedTo = (values) => {
        const { filters } = this.props;
        const assignedTo = values.map((val) => val.value);
        this.props.setFilters({ ...filters, assignedTo });
    }

    setAddedBy = (values) => {
        const { filters } = this.props;
        const addedBy = values.map((val) => val.value);
        this.props.setFilters({ ...filters, addedBy });
    }

    setTags = (values) => {
        const { filters } = this.props;
        const tags = values.map((val) => val.value);
        this.props.setFilters({ ...filters, tags });
    }

    render() {
        const { classes, board, recycleBin, deleteBoard, username, securables, searchText, users, filters, tags, sortBy } = this.props;
        const { menuOpen, recycleBinOpen } = this.state;
        const accessLevel = _.find(securables, { Securable: "project-tracking" });
        const avatar = 'assets/images/avatars/profile.jpg';
        if (!board) {
            return (<div className={classNames(classes.root, "flex flex-1 flex-col w-full h-full relative")}></div>);
        }
        const { Co } = board;
        const tagAccessLevel = _.find(securables, { Securable: "to-do-labels" });
        const cards = _.filter(board.Data.Cards, (o) => o.DeletedYN !== 'Y');
        window["warn"]('Flattened Cards: ', cards);
        const assignees = _.map(_.flatten(_.map(cards, (o) => o.Data.Members)), (a) => a.UserName);
        const accessLevels = { 'O': { name: 'Owner', color: 'bg-green' }, 'R': { name: 'Read-Only', color: 'bg-red' }, 'E': { name: 'Read / Write', color: 'bg-orange' }, 'F': { name: 'Full Access', color: 'bg-blue' } };

        return (
            <div
                className={classNames(classes.root, "flex flex-1 flex-col w-full h-full relative")}
                ref={(root) => {
                    this.root = root;
                }}
            >

                {/* Recycle Bin Drawer */}
                <RecycleBinDrawer
                    drawerOpen={recycleBinOpen}
                    closeDrawer={() => this.setState({ recycleBinOpen: false })}
                    board={board}
                />

                {/* Project Settings Drawer */}
                <Drawer
                    anchor="right"
                    variant="permanent"
                    className={clsx(classNames(classes.drawer, "pin-r absolute"), {
                        [classes.drawerOpen]: menuOpen,
                        [classes.drawerClose]: !menuOpen
                    })}
                    classes={{
                        paper: clsx("relative border-none h-full", {
                            [classes.drawerOpen]: menuOpen,
                            [classes.drawerClose]: !menuOpen
                        })
                    }}
                    open={menuOpen}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        tabIndex={0}
                        className="w-full h-full relative"
                    >
                        <div className="p-12 relative" style={{ maxHeight: 'calc(100vh - 144px)', overflowY: 'auto' }}>
                            <Typography variant="h6" className="w-full font-bold text-16 mb-12 mt-4"><Icon className="align-middle mr-4 mb-4" color="primary">tune</Icon>Project Settings</Typography>
                            <IconButton onClick={() => this.setState({ menuOpen: false })} color="inherit" className="pin-t pin-r absolute mt-4 mr-4 text-black">
                                <Icon>close</Icon>
                            </IconButton>
                            <TextField
                                className="mb-6"
                                variant="outlined"
                                fullWidth
                                label="Project Name"
                                name="Name"
                                value={this.state.newName || ''}
                                onChange={(e) => this.setState({ newName: e.target.value })}
                            />
                            <div className="w-full">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={board.RestrictedYN === 'Y'}
                                            name="RestrictedYN"
                                            onChange={(e) => {
                                                board.RestrictedYN = (e.target.checked ? 'Y' : 'N');
                                                this.setState({ boardMember: null });
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Private"
                                />
                            </div>
                            {board.RestrictedYN === 'Y' &&
                                <div className="w-full mb-16">
                                    <div className="w-full flex justify-between">
                                        <Typography variant="h6" className="w-full font-bold text-14"><Icon className="align-middle mr-4 mb-4" color="primary">people</Icon>Project Members</Typography>
                                        <Icon className="align-middle mt-4 cursor-pointer text-20" onClick={() => {
                                            if (!this.state.boardMember) {
                                                this.setState({
                                                    boardMember: {
                                                        ID: null,
                                                        UserName: null,
                                                        AccessLevel: null
                                                    }
                                                })
                                            } else {
                                                this.setState({ boardMember: null });
                                            }
                                        }} color="action">{this.state.boardMember ? "close" : "add_circle_outline"}</Icon>
                                    </div>
                                    {this.state.boardMember &&
                                        <React.Fragment>
                                            <Autocomplete
                                                className={classes.formControl}
                                                title="User"
                                                options={_.filter(users, (o) => o.UserName !== board.AddedBy)}
                                                margin="dense"
                                                required
                                                menuItemComponent={(value) => {
                                                    return <MenuItem value={value.UserName}>
                                                        <div className="flex">
                                                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.userAvatar} alt={value.Technician} src={(value.Data && value.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${value.Data.Avatar}&Thumb=true` ): avatar)} />
                                                            {`${value.FirstName} ${value.LastName}`}
                                                        </div>
                                                    </MenuItem>
                                                }}
                                                value={this.state.boardMember.UserName || ''}
                                                onSelect={(option) => this.setState({ ...this.state, boardMember: { ...this.state.boardMember, UserName: option.UserName } })}
                                            />
                                            <TextField
                                                className="mb-12"
                                                label="Access Level"
                                                id="accessLevel"
                                                select
                                                margin="dense"
                                                name="AccessLevel"
                                                value={this.state.boardMember.AccessLevel || ''}
                                                onChange={(e) => {
                                                    const AccessLevel = e.target.value;
                                                    this.setState({
                                                        boardMember: {
                                                            ...this.state.boardMember,
                                                            AccessLevel
                                                        }
                                                    })
                                                }}
                                                variant="outlined"
                                                required
                                                fullWidth
                                            >
                                                <MenuItem value="R">
                                                    Read-Only
                                                </MenuItem>
                                                <MenuItem value="E">
                                                    Read / Write
                                                </MenuItem>
                                                <MenuItem value="F">
                                                    Full Access
                                                </MenuItem>
                                            </TextField>
                                            <br />
                                            {!this.state.boardMember.ID ?
                                                <Button
                                                    className={classNames(classes.formControl, "w-full mb-12")}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        const { Co, ID } = board;
                                                        const { UserName, AccessLevel } = this.state.boardMember;
                                                        const { Members } = board.Data;
                                                        const memb = {
                                                            Co,
                                                            BoardID: ID,
                                                            ID: -1,
                                                            UserName,
                                                            AccessLevel,
                                                            Data: {}
                                                        };
                                                        this.setState({ boardMember: null }, () => this.props.addBoardMember(memb));
                                                        // this.setState({ board, boardMember: null });
                                                    }}
                                                    disabled={!this.state.boardMember.UserName || !this.state.boardMember.AccessLevel}
                                                >
                                                    Add
                                                </Button> :
                                                <div className="w-full flex">
                                                    <Button
                                                        className={classNames(classes.formControl, classes.updateRecord, "w-full mb-12")}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            const member = _.cloneDeepWith(this.state.boardMember);
                                                            this.setState({ boardMember: null }, () => this.props.updateBoardMember({ ...member, Data: {} }))
                                                        }}
                                                    // disabled={!this.canMemberBeSubmitted()}
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        className={classNames(classes.formControl, classes.deleteRecord, "mb-12")}
                                                        variant="contained"
                                                        onClick={() => {
                                                            const member = _.cloneDeepWith(this.state.boardMember);
                                                            this.setState({ boardMember: null }, () => this.props.removeBoardMember({ ...member }))
                                                        }}
                                                    // disabled={_.find(pages, { id: this.state.securable.Securable }) && _.find(pages, { id: this.state.securable.Securable }).url === this.state.DefaultHomepage}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </Button>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                    <div className="w-full bg-grey-lighter rounded p-6">
                                        {[{ UserName: board.AddedBy, AccessLevel: 'O' }, ...(board.Data.Members || [])].map((value) => {
                                            const memb = _.find(users, { UserName: value.UserName });
                                            if (memb) {
                                                return (
                                                    <div onClick={value.AccessLevel === "O" ? undefined : () => this.setState({ boardMember: value })} className={classNames("w-full flex justify-between", value.AccessLevel === 'O' ? "cursor-not-allowed" : "cursor-pointer")}>
                                                        <div className="flex my-4" key={value.UserName}>
                                                            <Avatar style={{ marginRight: 8, }} classes={{ root: classes.avatarRoot }} className={classes.userAvatar} alt={memb.Technician} src={(memb.Data && memb.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${memb.Data.Avatar}&Thumb=true` ): avatar)} />
                                                            <b className="mt-2">{`${memb.FirstName} ${memb.LastName}`}</b>
                                                        </div>
                                                        <div className={classNames("px-8 rounded text-white text-11 h-16 mt-10", accessLevels[value.AccessLevel].color)}>
                                                            {accessLevels[value.AccessLevel].name}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                    <Divider className="mt-16" />
                                </div>
                            }
                            <div className="mt-4 w-full">
                                <Typography variant="h6" className="w-full font-bold text-14 mb-8"><Icon className="align-middle mr-4 mb-4">filter_list</Icon>Task Filters</Typography>
                                <FuseChipSelect
                                    className="w-full mb-12"
                                    value={
                                        filters.addedBy.map(memb => {
                                            const member = _.find(users, { UserName: memb });
                                            return {
                                                value: member.UserName,
                                                label: (
                                                    <Tooltip title={`${member.FirstName} ${member.LastName}`}>
                                                        <Badge classes={{ badge: classNames(classes.memberBadge) }} badgeContent={`${member.FirstName.substr(0, 1)}${member.LastName.substr(0, 1)}`}>
                                                            <Avatar className="-ml-12 w-32 h-32" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${member.Data.Avatar}` ): avatar} />
                                                        </Badge>
                                                    </Tooltip>)
                                            }
                                        })
                                    }
                                    onChange={(value) => this.setAddedBy(value)}
                                    label="Added By"
                                    placeholder="Select Users"
                                    isMulti
                                    textFieldProps={{
                                        variant: "outlined"
                                    }}
                                    options={_.uniq(_.map(cards, (o) => o.AddedBy)).map((memb) => {
                                        const member = _.find(users, { UserName: memb });
                                        if (member) {
                                            return {
                                                value: member.UserName,
                                                label: (<span className="flex items-center"><Avatar className="w-32 h-32 mr-8" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${member.Data.Avatar}` ): avatar} />{`${member.FirstName} ${member.LastName}`}</span>)
                                            }
                                        }
                                    })}
                                    variant="fixed"
                                />

                                <FuseChipSelect
                                    className="w-full mb-12"
                                    value={
                                        filters.tags.map(id => {
                                            const tag = _.find(tags, { ID: id });
                                            if (tag) {
                                                return {
                                                    value: tag.ID,
                                                    label: tag.Name,
                                                    color: tag.Color,
                                                    background: tag.Background
                                                }
                                            }
                                        })
                                    }
                                    onChange={(value) => this.setTags(value)}
                                    label="Tags"
                                    placeholder="Select Tags"
                                    isMulti
                                    textFieldProps={{
                                        variant: "outlined"
                                    }}
                                    variant="fixed"
                                    noOptionsComponent={
                                        <div>
                                            {(tagAccessLevel && tagAccessLevel.AccessLevel !== "R") ?
                                                <ListItem
                                                    button
                                                    onClick={() => this.setState({ newTag: true })}
                                                >
                                                    <Icon className="list-item-icon mr-6"
                                                        color="secondary">add_circle_outline</Icon>
                                                    <ListItemText primary={"New Tag"} />
                                                </ListItem> : "No Options"
                                            }
                                        </div>
                                    }
                                    options={tags.map((label) => (
                                        {
                                            value: label.ID,
                                            label: label.Name,
                                            color: label.Color,
                                            background: label.Background
                                        }
                                    ))}
                                    onCreateOption={(Name) => {

                                    }}
                                />
                                <FuseChipSelect
                                    className="w-full mb-12"
                                    value={
                                        filters.assignedTo.map(memb => {
                                            const member = _.find(users, { UserName: memb });
                                            return {
                                                value: member.UserName,
                                                label: (
                                                    <Tooltip title={`${member.FirstName} ${member.LastName}`}>
                                                        <Badge classes={{ badge: classNames(classes.memberBadge) }} badgeContent={`${member.FirstName.substr(0, 1)}${member.LastName.substr(0, 1)}`}>
                                                            <Avatar className="-ml-12 w-32 h-32" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${member.Data.Avatar}` ): avatar} />
                                                        </Badge>
                                                    </Tooltip>)
                                            }
                                        })
                                    }
                                    onChange={(value) => this.setAssignedTo(value)}
                                    label="Assigned To"
                                    placeholder="Select Users"
                                    isMulti
                                    textFieldProps={{
                                        variant: "outlined"
                                    }}
                                    options={_.uniq(assignees).map((memb) => {
                                        const member = _.find(users, { UserName: memb });
                                        if (member) {
                                            return {
                                                value: member.UserName,
                                                label: (<span className="flex items-center"><Avatar className="w-32 h-32 mr-8" src={member.Data && member.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${member.Data.Avatar}` ): avatar} />{`${member.FirstName} ${member.LastName}`}</span>)
                                            }
                                        }
                                    })}
                                    variant="fixed"
                                />
                                <DatePicker
                                    // variant="inline"
                                    inputVariant="outlined"
                                    className="w-full mb-12"
                                    label="Due By"
                                    id="dueBy"
                                    format="MM/DD/YYYY"
                                    value={filters.dueBy || null}
                                    onChange={(e) => {
                                        const { filters } = this.props;
                                        this.props.setFilters({ ...filters, dueBy: e ? e.format('MM/DD/YYYY') : null });
                                    }}
                                    autoOk={true}
                                    clearable={true}
                                    showTodayButton={true}
                                    fullWidth
                                />
                            </div>
                            <div className="mt-4 w-full">
                                <Typography variant="h6" className="w-full font-bold text-14 mb-8"><Icon className="align-middle mr-4 mb-4">swap_vert</Icon>Task Sorting</Typography>
                                <TextField
                                    className="mb-12"
                                    value={sortBy.prop || ''}
                                    label="Sort By"
                                    onChange={(e) => this.props.setSorting({ ...sortBy, prop: e.target.value })}
                                    fullWidth
                                    variant="outlined"
                                    select
                                >
                                    <MenuItem value={"DisplayIndex"}>Default</MenuItem>
                                    <MenuItem value={"Name"}>Task Name</MenuItem>
                                    <MenuItem value={"AddedDate"}>Added Date</MenuItem>
                                    <MenuItem value={"DueBy"}>Due Date</MenuItem>
                                    <MenuItem value={"Priority"}>Priority</MenuItem>
                                </TextField>
                                <br />
                                <TextField
                                    className="mb-12"
                                    value={sortBy.order || 'asc'}
                                    label="Sort Direction"
                                    onChange={(e) => this.props.setSorting({ ...sortBy, order: e.target.value })}
                                    fullWidth
                                    variant="outlined"
                                    select
                                >
                                    <MenuItem value={"asc"}>Ascending</MenuItem>
                                    <MenuItem value={"desc"}>Descending</MenuItem>
                                </TextField>
                            </div>
                        </div>
                        {accessLevel && (accessLevel.AccessLevel === 'F' || board.AddedBy === username) &&
                            <React.Fragment>
                                <div className="w-full sm:absolute sm:pin-b">
                                    <Button
                                        variant="contained"
                                        className={classNames("m-12 float-left")}
                                        color="primary"
                                        onClick={() => this.props.renameBoard({ ...board, Name: (this.state.newName || board.Name) })}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                    <IconButton
                                        className={classNames("m-12 float-right")}
                                        onClick={() => this.setState({ ...this.state, confirm: true })}
                                    >
                                        <Icon color="action">delete_sweep</Icon>
                                    </IconButton>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </Drawer>

                <AppBar position="static" color="primary" className={classes.dark}>
                    <Toolbar className="flex items-center justify-between px-4 sm:px-24 h-64">

                        <IconButton color="inherit" to="/apps/project-tracking/boards/" component={Link}>
                            <Icon>keyboard_arrow_left</Icon>
                        </IconButton>

                        <div className="flex flex-1 justify-center items-center">
                            <BoardSearchBar board={board} />
                        </div>
                        {this.state.view === "col" && <BoardAddList view="col"/>} 

                        {this.state.view === "row" ? <Tooltip placement="left" title="Column View">
                            <IconButton className="float-right" color="inherit" onClick={(e)=>this.setState({...this.state,view:this.state.view === "row" ? "col" : "row"}, () => this.props.setBoardView(this.state.view))}>
                                <Icon>format_list_bulleted</Icon>
                            </IconButton>
                        </Tooltip> : <Tooltip placement="left" title="Card View">
                            <IconButton className="float-right" color="inherit" onClick={(e)=>this.setState({...this.state,view:this.state.view === "row" ? "col" : "row"}, () => this.props.setBoardView(this.state.view))}>
                                <Icon>view_column</Icon>
                            </IconButton>
                        </Tooltip>}
                        <Tooltip placement="left" title="Recycle Bin">
                            <IconButton className="float-right" color="inherit" onClick={(e) => this.setState({ recycleBinOpen: true })}>
                                <Icon>restore_from_trash</Icon>
                            </IconButton>
                        </Tooltip>

                        <Tooltip placement="left" title="Project Settings">
                            <IconButton className="float-right" color="inherit" onClick={(e) => this.setState({ menuOpen: true })}>
                                <Icon>tune</Icon>
                            </IconButton>
                        </Tooltip>
                        {(username === board.AddedBy || accessLevel && accessLevel.AccessLevel === 'F') &&
                            <ConfirmationDialog
                                open={this.state.confirm}
                                title={<div><Icon className="mr-6 align-middle mb-4">delete</Icon>Are You Sure?</div>}
                                content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this project?`}</div>}
                                confirmText="Confirm"
                                cancelText="Never Mind"
                                onCancel={() => this.setState({ ...this.state, confirm: false })}
                                onConfirm={() => this.setState({ ...this.state, confirm: false }, () => { deleteBoard(board); })}
                            />
                        }
                    </Toolbar>
                </AppBar>

                <div className="flex flex-1 p-16 md:p-24 overflow-x-auto overflow-y-auto mb-80">

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable
                            droppableId="list"
                            type="list"
                            direction={`${this.state.view === "col" ? "vertical" : "horizontal"}`}
                            ignoreContainerClipping={true}
                        >
                            {(provided) => (
                                <div ref={provided.innerRef} className={`flex ${this.state.view==="col" && "flex-col w-full items-end"}`}>

                                    {board.Data && _.sortBy(board.Data.Lists, ['DisplayIndex', 'ID']).map((list, index) => (
                                        (list.ID >= 0 || list.Data.Cards.length > 0) &&
                                        <BoardList
                                            key={list.ID}
                                            list={list}
                                            index={index}
                                            filters={filters}
                                            sortBy={sortBy}
                                            username={username}
                                            searchText={searchText}
                                            cards={board.Data.Cards}
                                            view={this.state.view}
                                            boardData = {board.Data}
                                        />
                                    ))}
                                    {provided.placeholder}
                                    {this.state.view === "row" && <BoardAddList view={this.state.view}/>} 
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>


            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBoard: Actions.getBoard,
        resetBoard: Actions.resetBoard,
        deleteBoard: Actions.deleteBoard,
        renameBoard: Actions.renameBoard,
        convertToSubtask: Actions.convertToSubtask,
        reorderList: Actions.reorderList,
        reorderCard: Actions.reorderCard,
        setFilters: Actions.setFilters,
        setSorting: Actions.setSorting,
        addBoardMember: Actions.addBoardMember,
        updateBoardMember: Actions.updateBoardMember,
        removeBoardMember: Actions.removeBoardMember,
        setBoardView: Actions.setBoardView
    }, dispatch);
}

function mapStateToProps({ scrumboardApp, spReducers }) {
    return {
        board: scrumboardApp.board,
        view: scrumboardApp.boardView,
        recycleBin: scrumboardApp.recycleBin,
        Co: spReducers.companies.Co,
        username: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables,
        searchText: scrumboardApp.boards.searchText,
        users: spReducers.userProfiles.Users,
        filters: scrumboardApp.boards.filters,
        sortBy: scrumboardApp.boards.sortBy,
        tags: spReducers.calendar.tags,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Board)));
