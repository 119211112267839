import _ from '@lodash';
import { AppBar, Badge, Button, Chip, Dialog, DialogActions, FormControlLabel, Icon, IconButton, Switch, Tab, Tabs, TextField, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SketchPicker } from 'react-color';
import * as Actions from './store/actions';
import axios from 'axios';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? props.padding : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }}>
            {props.children}
        </Typography>
    );
}

const styles = theme => ({
    root: {},
    formControl: {
        marginBottom: 24
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
});
const newTagBuilderState = {
    ID: null,
    Name: null,
    Color: '#fff',
    Background: '#4dbce9',
    Class: "",
    Data: {
        ErrMsg: null,
    },
    ActiveYN: "Y",
    AddedBy: null,
    AddedDate: null,
};

class TagBuilderDialog extends Component {
    state = { ..._.cloneDeepWith(newTagBuilderState) };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data, open } = this.props;
        if (open && !_.isEqual(open, prevProps.open)) {
            this.setState({ ..._.cloneDeepWith(newTagBuilderState), ...data });
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    closeComposeDialog = () => {
        this.props.onClose();
    };

    canBeSubmitted() {
        const { Name, } = this.state;
        return (
            Name &&
            Name.length > 0
        );
    }

    handleAdd = () => {
        const request = axios.post(`${window["apiLocation"]}/api/ToDoTag`, this.state);
        request.then((response) => {
            this.props.onAdd(response.data);
        }).catch((err) => {
            this.props.onClose();
        })
    }

    handleUpdate = () => {
        const request = axios.put(`${window["apiLocation"]}/api/ToDoTag?Tag=${this.state.ID}`, this.state);
        request.then((response) => {
            this.props.onUpdate(response.data);
        }).catch((err) => {
            this.props.onClose();
        })
    }

    render() {
        const { classes, securables, tagBuilderDialog, open } = this.props;
        const accessLevel = _.find(securables, { Securable: "to-do-labels" });

        return (
            <Media query={"(min-width: 512px) and (min-height: 512px)"}>
                {matches =>
                    <Dialog
                        classes={{
                            root: classes.root,
                            paper: matches ? classes.paper : "full-screen-dialog"/*if fullScreen*/
                        }}
                        fullScreen={!matches}
                        className={classes.root}
                        open={Boolean(accessLevel) && open}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth={"sm"}
                    >
                        <AppBar position="static" elevation={1} className="dialog-header"/*if fullScreen*/>
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {!this.state.ID ? 'New Tag' : `Edit Tag`}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 10 : 4/*if fullScreen*/,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"/*if fullScreen*/
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>
                        <TabContainer>

                            <div className="flex">

                                <TextField
                                    className={classes.formControl}
                                    label="Name"
                                    id="name"
                                    name="Name"
                                    value={this.state.Name}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div>

                            <div className="w-full sm:flex">
                                <div className="w-full mb-12">
                                    <Typography variant="h6" className="text-12 font-bold mb-8">Background Color</Typography>
                                    <SketchPicker disableAlpha={true} color={this.state.Background} onChangeComplete={(color) => this.setState({ Background: color.hex })} />
                                </div>
                                <div className="min-h-12 sm:min-w-12"></div>
                                <div className="w-full mb-12">
                                    <Typography variant="h6" className="text-12 font-bold mb-8">Text Color</Typography>
                                    <SketchPicker disableAlpha={true} color={this.state.Color} onChangeComplete={(color) => this.setState({ Color: color.hex })} />
                                </div>
                            </div>

                        </TabContainer>

                        {accessLevel && accessLevel.AccessLevel !== "R" &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {!this.state.ID ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.handleAdd(this.state);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.handleUpdate(this.state);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )}
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.ActiveYN === 'Y'}
                                            name="ActiveYN"
                                            onChange={this.handleChange}
                                            color="primary"
                                        />
                                    }
                                    label="Active?"
                                />
                            </DialogActions>
                        }
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ spReducers }) {
    return {
        Co: spReducers.companies.Co,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TagBuilderDialog));
