import axios from 'axios';
import { getUserData } from 'main/content/apps/hunt-groups/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_HUNT_GROUPS = '[HUNT GROUPS APP] GET HUNT GROUPS';
export const SET_SEARCH_TEXT = '[HUNT GROUPS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_HUNT_GROUPS = '[HUNT GROUPS APP] TOGGLE IN SELECTED HUNT GROUPS';
export const SELECT_ALL_HUNT_GROUPS = '[HUNT GROUPS APP] SELECT ALL HUNT GROUPS';
export const DESELECT_ALL_HUNT_GROUPS = '[HUNT GROUPS APP] DESELECT ALL HUNT GROUPS';
export const OPEN_NEW_HUNT_GROUP_DIALOG = '[HUNT GROUPS APP] OPEN NEW HUNT GROUP DIALOG';
export const CLOSE_NEW_HUNT_GROUP_DIALOG = '[HUNT GROUPS APP] CLOSE NEW HUNT GROUP DIALOG';
export const OPEN_EDIT_HUNT_GROUP_DIALOG = '[HUNT GROUPS APP] OPEN EDIT HUNT GROUP DIALOG';
export const UPDATE_EDIT_HUNT_GROUP_DIALOG = '[HUNT GROUPS APP] UPDATE EDIT HUNT GROUP DIALOG';
export const CLOSE_EDIT_HUNT_GROUP_DIALOG = '[HUNT GROUPS APP] CLOSE EDIT HUNT GROUP DIALOG';
export const ADD_HUNT_GROUP = '[HUNT GROUPS APP] ADD HUNT GROUP';
export const UPDATE_HUNT_GROUP = '[HUNT GROUPS APP] UPDATE HUNT GROUP';
export const REMOVE_HUNT_GROUP = '[HUNT GROUPS APP] REMOVE HUNT GROUP';
export const REMOVE_HUNT_GROUPS = '[HUNT GROUPS APP] REMOVE HUNT GROUPS';
export const TOGGLE_STARRED_HUNT_GROUP = '[HUNT GROUPS APP] TOGGLE STARRED HUNT GROUP';
export const TOGGLE_STARRED_HUNT_GROUPS = '[HUNT GROUPS APP] TOGGLE STARRED HUNT GROUPS';
export const SET_HUNT_GROUPS_STARRED = '[HUNT GROUPS APP] SET HUNT GROUPS STARRED ';
export const SET_SEARCH_RESULTS = '[HUNT GROUPS APP] SET SEARCH RESULTS';

export function getHuntGroups(routeParams) {
    const request = axios.get('/api/hunt-groups-app/hunt-groups', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_HUNT_GROUPS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedHuntGroups(huntGroupId) {
    return {
        type: TOGGLE_IN_SELECTED_HUNT_GROUPS,
        huntGroupId
    }
}


export function selectAllHuntGroups() {
    return {
        type: SELECT_ALL_HUNT_GROUPS
    }
}

export function deSelectAllHuntGroups() {
    return {
        type: DESELECT_ALL_HUNT_GROUPS
    }
}


export function openNewHuntGroupDialog() {
    return {
        type: OPEN_NEW_HUNT_GROUP_DIALOG
    }
}

export function closeNewHuntGroupDialog() {
    return {
        type: CLOSE_NEW_HUNT_GROUP_DIALOG
    }
}

export function openEditHuntGroupDialog(data) {
    return {
        type: OPEN_EDIT_HUNT_GROUP_DIALOG,
        data
    }
}

export function closeEditHuntGroupDialog() {
    return {
        type: CLOSE_EDIT_HUNT_GROUP_DIALOG
    }
}

export function addHuntGroup(newHuntGroup) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/HuntGroup`,
            newHuntGroup
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_HUNT_GROUP
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Hunt Group has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateHuntGroup(huntGroup) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/HuntGroup?Co=${encodeURIComponent(huntGroup.Co)}&HuntGroup=${encodeURIComponent(huntGroup.ID)}`,
            huntGroup
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_HUNT_GROUP
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Hunt Group has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeHuntGroup(huntGroupId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().huntGroupsApp.huntGroups;

        const request = axios.post('/api/hunt-groups-app/remove-huntGroup', {
            huntGroupId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_HUNT_GROUP
                })
            ]).then(() => dispatch(getHuntGroups(routeParams)))
        );
    };
}


export function removeHuntGroups(huntGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().huntGroupsApp.huntGroups;

        const request = axios.post('/api/hunt-groups-app/remove-huntGroups', {
            huntGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_HUNT_GROUPS
                }),
                dispatch({
                    type: DESELECT_ALL_HUNT_GROUPS
                })
            ]).then(() => dispatch(getHuntGroups(routeParams)))
        );
    };
}

export function toggleStarredHuntGroup(huntGroupId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().huntGroupsApp.huntGroups;

        const request = axios.post('/api/hunt-groups-app/toggle-starred-huntGroup', {
            huntGroupId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_HUNT_GROUP
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getHuntGroups(routeParams)))
        );
    };
}

export function toggleStarredHuntGroups(huntGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().huntGroupsApp.huntGroups;

        const request = axios.post('/api/hunt-groups-app/toggle-starred-huntGroups', {
            huntGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_HUNT_GROUPS
                }),
                dispatch({
                    type: DESELECT_ALL_HUNT_GROUPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getHuntGroups(routeParams)))
        );
    };
}

export function setHuntGroupsStarred(huntGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().huntGroupsApp.huntGroups;

        const request = axios.post('/api/hunt-groups-app/set-huntGroups-starred', {
            huntGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_HUNT_GROUPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_HUNT_GROUPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getHuntGroups(routeParams)))
        );
    };
}

export function setHuntGroupsUnstarred(huntGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().huntGroupsApp.huntGroups;

        const request = axios.post('/api/hunt-groups-app/set-huntGroups-unstarred', {
            huntGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_HUNT_GROUPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_HUNT_GROUPS
                }),
                dispatch(getUserData())
            ])
        );
    };
}

export function purchaseHuntGroup(num) {
    return (dispatch, getState) => {
        const request = axios.post(`${window["apiLocation"]}/api/IncomingHuntGroup`, num);

        return request.then((response) => {
            Promise.all([
                dispatch({
                    type: ADD_HUNT_GROUP
                })
            ]).then(() => {
                dispatch(showMessage({
                    message: `Hunt Group ${num.Number} has been successfully purchased.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            })
        });
    }
}

export function searchAvailableNumbers(Co, area, search) {
    return (dispatch, getState) => {
        var request = axios.get(`${window["apiLocation"]}/api/IncomingHuntGroup?Co=${encodeURIComponent(Co)}&AreaCode=${encodeURIComponent(area)}&Pattern=${search}`);

        return request.then((response) => {
            dispatch({
                type: SET_SEARCH_RESULTS,
                payload: response.data,
            })
        });
    }
}
