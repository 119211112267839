import axios from 'axios';
import { getUserData } from 'main/content/apps/audit-posting/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const SET_SEARCH_TEXT = '[AUDIT POSTING APP] SET SEARCH TEXT';
export const SET_TYPE = '[AUDIT POSTING APP] SET TYPE';
export const SET_VENDOR = '[AUDIT POSTING APP] SET VENDOR';
export const SET_STATUS = '[AUDIT POSTING APP] SET STATUS';
export const SET_PAYMENT_STATUS = '[AUDIT POSTING APP] SET PAYMENT STATUS';
export const SET_PAYMENT_TYPE = '[AUDIT POSTING APP] SET PAYMENT TYPE';
export const SET_GL_ACCOUNT = '[AUDIT POSTING APP] SET GL ACCOUNT';
export const SET_START_DATE = '[AUDIT POSTING APP] SET START DATE';
export const SET_DELIVERY_STATUS = '[AUDIT POSTING APP] SET DELIVERY STATUS';
export const SET_REVIEW_STATUS = '[AUDIT POSTING APP] SET REVIEW STATUS';
export const SET_END_DATE = '[AUDIT POSTING APP] SET END DATE';
export const SET_AUDIT_DATA = '[AUDIT POSTING APP] SET AUDIT DATA';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function setType(type) {
    return {
        type: SET_TYPE,
        data: type,
    }
}

export function setVendor(vendor) {
    return {
        type: SET_VENDOR,
        data: vendor,
    }
}

export function setGLAccount(glAccount) {
    return {
        type: SET_GL_ACCOUNT,
        data: glAccount,
    }
}

export function setDeliveryStatus(deliveryStatus) {
    return {
        type: SET_DELIVERY_STATUS,
        data: deliveryStatus,
    }
}

export function setReviewStatus(reviewStatus) {
    return {
        type: SET_REVIEW_STATUS,
        data: reviewStatus,
    }
}

export function setStatus(status) {
    return {
        type: SET_STATUS,
        data: status,
    }
}

export function setPaymentStatus(paymentStatus) {
    return {
        type: SET_PAYMENT_STATUS,
        data: paymentStatus,
    }
}

export function setPaymentType(paymentType) {
    return {
        type: SET_PAYMENT_TYPE,
        data: paymentType,
    }
}

export function setStartDate(date) {
    return {
        type: SET_START_DATE,
        date,
    }
}

export function setEndDate(date) {
    return {
        type: SET_END_DATE,
        date,
    }
}

export function getData(Co, type, start, end) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_AUDIT_DATA,
            data: [],
            loading: true,
        });
        let url = '';
        switch (type) {
            case 'C':
                {
                    url = `${window["apiLocation"]}/api/Call?Co=${Co}&StartDate=${new Date(start).toLocaleDateString('en-US').replace(/\//g, '-')}&EndDate=${new Date(end).toLocaleDateString('en-US').replace(/\//g, '-')}`;
                }
                break;
            case 'P':
                {
                    url = `${window["apiLocation"]}/api/PurchaseOrder?Co=${Co}&StartDate=${new Date(start).toLocaleDateString('en-US').replace(/\//g, '-')}&EndDate=${new Date(end).toLocaleDateString('en-US').replace(/\//g, '-')}`;
                }
                break;
            case 'I':
                {
                    url = `${window["apiLocation"]}/api/Invoice?Co=${Co}&StartDate=${new Date(start).toLocaleDateString('en-US').replace(/\//g, '-')}&EndDate=${new Date(end).toLocaleDateString('en-US').replace(/\//g, '-')}`;
                }
                break;
            case 'T':
                {
                    url = `${window["apiLocation"]}/api/TimecardLabor?Co=${Co}&StartDate=${new Date(start).toLocaleDateString('en-US').replace(/\//g, '-')}&EndDate=${new Date(end).toLocaleDateString('en-US').replace(/\//g, '-')}`;
                }
                break;
            case 'W':
                {
                    url = `${window["apiLocation"]}/api/WorkOrder?Co=${Co}&StartDate=${new Date(start).toLocaleDateString('en-US').replace(/\//g, '-')}&EndDate=${new Date(end).toLocaleDateString('en-US').replace(/\//g, '-')}`;
                }
                break;
            case 'S':
                {
                    url = `${window["apiLocation"]}/api/WorkOrderScope?Co=${Co}&StartDate=${new Date(start).toLocaleDateString('en-US').replace(/\//g, '-')}&EndDate=${new Date(end).toLocaleDateString('en-US').replace(/\//g, '-')}`;
                }
                break;
        }

        const request = axios.get(url);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_AUDIT_DATA,
                    data: response.data,
                    loading: false,
                })
            ])
        );
    }
}

export function setData(data) {
    return {
        type: SET_AUDIT_DATA,
        data
    }
}