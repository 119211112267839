import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import classNames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import MailCompose from 'main/content/apps/mail/MailCompose'
import PhoneInput from '../../components/inputs/PhoneInput'
import { formatPhone } from 'main/functions'
import { WINDOW } from '@sentry/browser'

const styles = theme => ({
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  borderTop: {
    borderTop: '1px solid lightgrey',
  },
  avatar: {
    width: 28,
    height: 28,
  },
  contactChip: {
    fontSize: 10,
    marginRight: 4,
    height: 14,
    borderRadius: 4,
  },
  chipPadding: {
    padding: '0px 7px',
  },
  error: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  ok: {
    background: '#333',
    color: '#fff',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  greenText: {
    color: '#d1e751',
  },
})
const initialState = {
  details: [],
  exclusion: null,
  contact: null,
  customer: null,
  site: null,
  autoFocus: false,
  emailWarning: false,
}

class ContactList extends Component {
  state = { ..._.cloneDeepWith(initialState) }

  componentDidMount() {
    const { details, customer, site, contact } = this.props
    this.setState({ ...this.state, details, customer, site, contact })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { details, customer, site, contact } = this.props
    if (
      !_.isEqual(prevProps.details, details) ||
      !_.isEqual(prevProps.customer, customer) ||
      !_.isEqual(prevProps.site, site) ||
      !_.isEqual(prevProps.contact, contact)
    ) {
      this.setState({ ...this.state, details, customer, site, contact })
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  formatPhone = str => {
    return formatPhone(str).formatted
  }

  closeMenu = () => {
    this.setState({ ...this.state, anchorEl: null, anchorData: null })
  }

  addContact = () => {
    const { contact, details, customer, site } = this.state

    const request = axios.post(`${window['apiLocation']}/api/Contact`, contact)

    request.then(response => {
      if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
        this.props.showMessage({
          message: `Error: ${response.data.Data.ErrMsg}`,
          autoHideDuration: 30000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        })
        this.setState({ autoFocus: false })
      } else {
        if (site) {
          this.addSiteContact(response.data)
        } else if (customer) {
          this.addCustomerContact(response.data)
        }
      }
    })
  }

  updateContact = () => {
    const { contact, details, customer, site } = this.state

    const request = axios.put(
      `${window['apiLocation']}/api/Contact?Contact=${encodeURIComponent(
        contact.Contact,
      )}`,
      {
        ...contact,
        Data: {
          ErrMsg: null,
        },
      },
    )

    request.then(response => {
      if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
        this.props.showMessage({
          message: `Error: ${response.data.Data.ErrMsg}`,
          autoHideDuration: 30000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        })
        this.setState({ autoFocus: false })
      } else {
        const Contacts = _.cloneDeepWith(details)
        const current = _.find(
          Contacts,
          o => o.Data.Contact.ID === this.state.contact.ID,
        )
        if (current) {
          current.Data.Contact = this.state.contact
        }
        this.setState(
          { ...this.state, contact: null, details: Contacts, autoFocus: false },
          () => {
            this.props.onUpdate(Contacts)
            this.props.showMessage({
              message: `Contact #${response.data.Contact} was successfully updated`,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            })
          },
        )
      }
    })
  }

  removeContact = () => {
    const { contact, details, customer, site } = this.state
    const { Contact } = contact
    const { Co, Customer, Site } = site || customer

    if (Contact) {
      if (site) {
        this.removeSiteContact({ Co, Customer, Site, Contact })
      } else if (customer) {
        this.removeCustomerContact({ Co, Customer, Contact })
      }
    }
  }

  addCustomerContact = newContact => {
    const { details, customer, site } = this.state

    const { Co, Customer, Site } = customer
    const { Contact } = newContact
    const contact = {
      Co,
      Customer,
      Contact,
      ActiveYN: 'Y',
      PrimaryYN: details.length > 0 ? 'N' : 'Y',
      BillingYN: details.length > 0 ? 'N' : 'Y',
      ApprovalRequiredYN: null,
      Data: {
        ErrMsg: null,
        Contact: newContact,
      },
    }
    if (Customer) {
      const request = axios.post(
        `${window['apiLocation']}/api/CustomerContact`,
        contact,
      )

      request.then(response => {
        if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
          this.props.showMessage({
            message: `Error: ${response.data.Data.ErrMsg}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          })
          this.setState({ autoFocus: false })
        } else {
          const Contacts = _.cloneDeepWith(details)
          Contacts.push({
            ...response.data,
            Data: {
              Contact: newContact,
            },
          })
          this.setState(
            {
              ...this.state,
              contact: null,
              details: Contacts,
              autoFocus: false,
            },
            () => {
              this.props.onUpdate(Contacts)
              this.props.showMessage({
                message: `Successfully added Contact #${response.data.Contact} to Customer #${response.data.Customer}`,
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              })
            },
          )
        }
      })
    } else {
      const Contacts = _.cloneDeepWith(details)
      Contacts.push({
        ...contact,
        Data: {
          Contact: newContact,
        },
      })
      this.setState(
        { ...this.state, contact: null, details: Contacts, autoFocus: false },
        () => {
          this.props.onUpdate(Contacts)
        },
      )
    }
  }

  updateCustomerContact = contact => {
    const { details, customer, site } = this.state

    const { Co, Customer, Contact } = contact

    if (Co && Customer && Contact) {
      const request = axios.put(
        `${window['apiLocation']}/api/CustomerContact?Co=${
          contact.Co
        }&Customer=${contact.Customer}&Contact=${encodeURIComponent(
          contact.Contact,
        )}`,
        {
          ...contact,
          Data: {
            ...contact.Data,
            ErrMsg: null,
          },
        },
      )
      request.then(response => {
        const Contacts = _.cloneDeepWith(details)
        const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
        if (index > -1) {
          Contacts.splice(index, 1, response.data)
        }
        this.setState(
          { ...this.state, contact: null, details: Contacts },
          () => {
            this.props.onUpdate(Contacts)
            this.props.showMessage({
              message: `Contact #${response.data.Contact} was successfully updated`,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            })
          },
        )
      })
    } else {
      const Contacts = _.cloneDeepWith(details)
      const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
      if (index > -1) {
        Contacts.splice(index, 1, contact)
      }
      this.setState(
        { ...this.state, contact: null, details: Contacts, autoFocus: false },
        () => {
          this.props.onUpdate(Contacts)
        },
      )
    }
  }

  removeCustomerContact = contact => {
    const { details, customer, site } = this.state

    const { Co, Customer, Contact } = contact
    if (Co && Customer && Contact) {
      const request = axios.delete(
        `${window['apiLocation']}/api/CustomerContact?Co=${
          contact.Co
        }&Customer=${contact.Customer}&Contact=${encodeURIComponent(
          contact.Contact,
        )}`,
      )

      request.then(response => {
        const Contacts = _.cloneDeepWith(details)
        const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
        if (index > -1) {
          Contacts.splice(index, 1)
        }
        this.setState(
          { ...this.state, contact: null, details: Contacts },
          () => {
            this.props.onUpdate(Contacts)
            this.props.showMessage({
              message: `Contact #${contact.Contact} was successfully removed from Customer #${contact.Customer}`,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            })
          },
        )
      })
    } else {
      const Contacts = _.cloneDeepWith(details)
      const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
      if (index > -1) {
        Contacts.splice(index, 1)
      }
      this.setState(
        { ...this.state, contact: null, details: Contacts, autoFocus: false },
        () => {
          this.props.onUpdate(Contacts)
        },
      )
    }
  }

  addSiteContact = newContact => {
    const { details, customer, site } = this.state

    const { Co, Customer, Site } = site
    const { Contact } = newContact
    const contact = {
      Co,
      Customer,
      Site,
      Contact,
      ActiveYN: 'Y',
      PrimaryYN: details.length > 0 ? 'N' : 'Y',
      ApprovalRequiredYN: null,
      Data: {
        ErrMsg: null,
        Contact: newContact,
      },
    }

    if (Customer && Site) {
      const request = axios.post(
        `${window['apiLocation']}/api/CustomerSiteContact`,
        contact,
      )

      request.then(response => {
        if (response.data.ErrMsg && response.data.ErrMsg.length > 0) {
          this.props.showMessage({
            message: `Error: ${response.data.Data.ErrMsg}`,
            autoHideDuration: 30000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          })
          this.setState({ autoFocus: false })
        } else {
          const Contacts = _.cloneDeepWith(details)
          Contacts.push({
            ...response.data,
            Data: {
              Contact: newContact,
            },
          })
          this.setState(
            {
              ...this.state,
              contact: null,
              details: Contacts,
              autoFocus: false,
            },
            () => {
              this.props.onUpdate(Contacts)
              this.props.showMessage({
                message: `Successfully added Contact #${response.data.Contact} to Customer #${response.data.Customer} Site #${response.data.Site}`,
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              })
            },
          )
        }
      })
    } else {
      const Contacts = _.cloneDeepWith(details)
      Contacts.push({
        ...contact,
        Data: {
          Contact: newContact,
        },
      })
      this.setState(
        { ...this.state, contact: null, details: Contacts, autoFocus: false },
        () => {
          this.props.onUpdate(Contacts)
        },
      )
    }
  }

  updateSiteContact = contact => {
    const { details, customer, site } = this.state

    const { Co, Customer, Site, Contact } = contact

    if (Co && Customer && Site && Contact) {
      const request = axios.put(
        `${window['apiLocation']}/api/CustomerSiteContact?Co=${
          contact.Co
        }&Customer=${contact.Customer}&Site=${encodeURIComponent(
          contact.Site,
        )}&Contact=${encodeURIComponent(contact.Contact)}`,
        {
          ...contact,
          Data: {
            ...contact.Data,
            ErrMsg: null,
          },
        },
      )

      request.then(response => {
        const Contacts = _.cloneDeepWith(details)
        const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
        if (index > -1) {
          Contacts.splice(index, 1, response.data)
        }
        this.setState(
          { ...this.state, contact: null, details: Contacts },
          () => {
            this.props.onUpdate(Contacts)
            this.props.showMessage({
              message: `Successfully updated Contact #${response.data.Contact}`,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            })
          },
        )
      })
    } else {
      const Contacts = _.cloneDeepWith(details)
      const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
      if (index > -1) {
        Contacts.splice(index, 1, contact)
      }
      this.setState(
        { ...this.state, contact: null, details: Contacts, autoFocus: false },
        () => {
          this.props.onUpdate(Contacts)
        },
      )
    }
  }

  removeSiteContact = contact => {
    const { details, customer, site } = this.state

    const { Co, Customer, Site, Contact } = contact

    if (Co && Customer && Site && Contact) {
      const request = axios.delete(
        `${window['apiLocation']}/api/CustomerSiteContact?Co=${
          contact.Co
        }&Customer=${contact.Customer}&Site=${encodeURIComponent(
          contact.Site,
        )}&Contact=${encodeURIComponent(contact.Contact)}`,
      )

      request.then(response => {
        const Contacts = _.cloneDeepWith(details)
        const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
        if (index > -1) {
          Contacts.splice(index, 1)
        }
        this.setState(
          { ...this.state, contact: null, details: Contacts },
          () => {
            this.props.onUpdate(Contacts)
            this.props.showMessage({
              message: `Contact #${contact.Contact} was successfully removed from Customer #${contact.Customer} Site #${contact.Site}`,
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            })
          },
        )
      })
    } else {
      const Contacts = _.cloneDeepWith(details)
      const index = _.findIndex(Contacts, o => o.Contact === contact.Contact)
      if (index > -1) {
        Contacts.splice(index, 1)
      }
      this.setState(
        { ...this.state, contact: null, details: Contacts, autoFocus: false },
        () => {
          this.props.onUpdate(Contacts)
        },
      )
    }
  }

  formatPhoneLink(str) {
    const phone = str.replace(/\(|\)|,|\.|-/g, '')
    return phone.length === 10
      ? `+1-${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(6, 4)}`
      : str
  }

  emailContact = email => {
    const { site, customer } = this.state
    const Attachments = []

    const data = {
      composeDialog: true,
      Title: `Email ${site ? 'Site' : 'Customer'} Contact`,
      Icon: 'email',
      To: `${email};`,
      Subject: ``,
      Head: '',
      Message: '',
      Body: '',
      Attachments,
    }
    this.setState(
      { ...this.state, contactEmailData: { ...data } },
      this.closeMenu,
    )
    return data
  }

  render() {
    const {
      classes,
      estimate,
      parent,
      editable,
      billing,
      label,
      onCancel,
      customer,
      details: propDetails,
    } = this.props
    let { details, anchorEl, anchorData, contactEmailData, autoFocus } =
      this.state

    let customerId = null
    if (!customer) {
      customerId = propDetails[0]
    } else {
      customerId = customer
    }

    if (details) {
      details = _.filter([...details], o => {
        return o.DeleteYN !== 'Y'
      })
    }
    return (
      <div className='w-full'>
        {contactEmailData && (
          <MailCompose
            onClose={() =>
              this.setState({ ...this.state, contactEmailData: null })
            }
            hideButton={true}
            data={{ ...contactEmailData }}
          />
        )}
        <label
          className='text-12 p-4 px-6 ml-8'
          style={{ background: '#fff', color: 'grey' }}
        >
          {label ? label : `${billing ? 'Billing ' : ''}Contacts`}:
        </label>
        <div
          className='mb-24 sm:mb-12 rounded-4 px-12 mb-12 py-4'
          style={{ border: '1px solid rgba(0, 0, 0, .25)', marginTop: -11 }}
        >
          <div className={details && details.length > 0 ? '' : 'my-8'}>
            <React.Fragment>
              {anchorEl &&
                anchorData &&
                (!billing || anchorData.BillingYN === 'Y') && (
                  <Menu
                    id='contact-menu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.closeMenu}
                  >
                    {this.props.woContactActive === 'Y' && (
                      <MenuItem>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                this.props.siteContact === anchorData.Contact
                              }
                              name='anchorData.woContact'
                              onChange={() => {
                                if (
                                  this.props.siteContact === anchorData.Contact
                                ) {
                                  this.props.updateSiteContact(null)
                                } else {
                                  this.props.updateSiteContact(
                                    anchorData.Contact,
                                  )
                                }
                              }}
                              color='primary'
                            />
                          }
                          label='Work Order Contact'
                        />
                      </MenuItem>
                    )}
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={anchorData.PrimaryYN === 'Y'}
                            name='anchorData.PrimaryYN'
                            onChange={() => {
                              if (anchorData.PrimaryYN === 'Y') {
                                anchorData.PrimaryYN = 'N'
                              } else {
                                anchorData.PrimaryYN = 'Y'
                              }
                              if (anchorData.Site) {
                                this.updateSiteContact(anchorData)
                              } else {
                                this.updateCustomerContact(anchorData)
                              }
                            }}
                            color='primary'
                            disabled={anchorData.RequiresApprovalYN === 'Y'}
                          />
                        }
                        label='Primary Contact'
                      />
                    </MenuItem>
                    {this.state.customer && !this.state.site && (
                      <MenuItem>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={anchorData.BillingYN === 'Y'}
                              name='anchorData.BillingYN'
                              onChange={() => {
                                if (anchorData.BillingYN === 'Y') {
                                  anchorData.BillingYN = 'N'
                                } else {
                                  anchorData.BillingYN = 'Y'
                                }
                                if (anchorData.Site) {
                                  this.updateSiteContact(anchorData)
                                } else {
                                  this.updateCustomerContact(anchorData)
                                }
                              }}
                              color='primary'
                              disabled={anchorData.RequiresApprovalYN === 'Y'}
                            />
                          }
                          label='Billing Contact'
                        />
                      </MenuItem>
                    )}
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={anchorData.RequiresApprovalYN === 'Y'}
                            name='anchorData.RequiresApprovalYN'
                            onChange={() => {
                              if (anchorData.RequiresApprovalYN === 'Y') {
                                anchorData.RequiresApprovalYN = 'N'
                              } else {
                                anchorData.RequiresApprovalYN = 'Y'
                              }
                              if (anchorData.Site) {
                                this.updateSiteContact(anchorData)
                              } else {
                                this.updateCustomerContact(anchorData)
                              }
                            }}
                            color='primary'
                            disabled={
                              anchorData.PrimaryYN === 'Y' ||
                              anchorData.BillingYN === 'Y'
                            }
                          />
                        }
                        label='Requires Approval'
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState(
                          {
                            ...this.state,
                            contact: {
                              ..._.cloneDeepWith(anchorData.Data.Contact),
                              customerId: anchorData.customerId,
                            },
                            autoFocus: true,
                          },
                          this.closeMenu,
                        )
                        window['warn'](this.state, anchorData)
                      }}
                    >
                      <Icon className='mr-24'>edit</Icon>Edit Contact Info
                    </MenuItem>
                  </Menu>
                )}
              {[...details].map((value, index) => {
                const {
                  Contact,
                  Customer: contactCustomerId,
                  Data,
                  PrimaryYN,
                  RequiresApprovalYN,
                  BillingYN,
                } = value

                if (billing && BillingYN !== 'Y') {
                  return ''
                }
                return (
                  <CardHeader
                    key={index}
                    classes={{
                      action: '',
                      root: classNames(
                        'p-4 py-12',
                        index > 0 ? classes.borderTop : '',
                      ),
                      avatar: 'mr-8',
                      title: 'leading-none',
                      subheader: 'leading-none',
                    }}
                    avatar={
                      <Avatar
                        color='primary'
                        className={classNames(classes.blue, classes.avatar)}
                      >
                        <Icon className='text-20'>perm_contact_calendar</Icon>
                      </Avatar>
                    }
                    title={
                      <div className='flex items-center'>
                        <label className='text-14 font-bold text-grey-darkest mr-4'>
                          {Data.Contact.Name}
                        </label>
                        {RequiresApprovalYN === 'Y' && (
                          <Chip
                            classes={{ label: 'px-4' }}
                            className={classNames(
                              classes.contactChip,
                              'text-white',
                            )}
                            style={{ backgroundColor: '#f44336' }}
                            label={'Requires Approval'}
                          />
                        )}
                        {PrimaryYN === 'Y' && (
                          <Chip
                            classes={{ label: 'px-4' }}
                            className={classNames(
                              classes.contactChip,
                              'text-white',
                            )}
                            style={{ backgroundColor: '#4dbce9' }}
                            label={'Primary'}
                          />
                        )}
                        {BillingYN === 'Y' && (
                          <Chip
                            classes={{ label: 'px-4' }}
                            className={classNames(
                              classes.contactChip,
                              'text-grey-darkest',
                            )}
                            style={{ backgroundColor: '#D1E751' }}
                            label={'Billing'}
                          />
                        )}
                      </div>
                    }
                    subheader={
                      <>
                        <label>
                          <span className='text-11 text-grey-dark font-semibold'>
                            {this.formatPhone(Data.Contact.Phone)}
                          </span>
                          <br />
                          <span className='text-11 text-grey-dark font-semibold'>
                            {Data.Contact.Email}
                          </span>
                        </label>
                        {customerId !== contactCustomerId && (
                          <span className='text-11 text-orange-dark font-semibold'>
                            {`This contact belongs to parent customer #${contactCustomerId}`}
                          </span>
                        )}
                      </>
                    }
                    action={
                      <div className='flex mt-12 pr-4'>
                        {Data.Contact.Phone && (
                          <Tooltip
                            title={this.formatPhone(Data.Contact.Phone)}
                            placement='top'
                          >
                            <Link
                              href={`tel:${this.formatPhoneLink(
                                Data.Contact.Phone,
                              )}`}
                              color='inherit'
                            >
                              <Icon
                                color='secondary'
                                className='text-24 mr-12 cursor-pointer'
                              >
                                phone
                              </Icon>
                            </Link>
                          </Tooltip>
                        )}
                        {Data.Contact.Email && (
                          <Tooltip title={Data.Contact.Email} placement='top'>
                            <Icon
                              onClick={() =>
                                this.emailContact(Data.Contact.Email)
                              }
                              color='primary'
                              className='text-24 mr-12 cursor-pointer'
                            >
                              email
                            </Icon>
                          </Tooltip>
                        )}
                        <Tooltip title='More Options' placement='top'>
                          <Icon
                            color='action'
                            onClick={e => {
                              this.setState({
                                ...this.state,
                                anchorEl: e.target,
                                anchorData: {
                                  ...value,
                                  customerId: contactCustomerId,
                                },
                              })
                            }}
                            className='text-24 cursor-pointer'
                          >
                            more_vert
                          </Icon>
                        </Tooltip>
                      </div>
                    }
                  />
                )
              })}
            </React.Fragment>
          </div>
          {!this.state.contact && (
            <Typography
              variant='subtitle2'
              style={{
                alignItems: 'center',
                borderTop:
                  details &&
                  details.length > 0 &&
                  (!billing || _.filter(details, { BillingYN: 'Y' }).length > 0)
                    ? '1px solid lightgrey'
                    : 'none',
              }}
              className={classNames(
                'text-12 flex cursor-pointer justify-start',
                details && details.length > 0 ? 'pt-12 pb-8' : 'pt-4 pb-8',
              )}
              onClick={() => {
                const contact = {
                  ID: null,
                  Name: null,
                  Phone: null,
                  Email: null,
                  Data: {
                    ErrMsg: null,
                  },
                }
                this.setState({ ...this.state, contact, autoFocus: true })
              }}
            >
              <Icon
                color='primary'
                className='text-18 mr-4'
                style={{ marginBottom: 2 }}
              >
                add
              </Icon>
              New Contact
            </Typography>
          )}
          {this.state.contact && (
            <div className='w-full relative'>
              {this.state.contact && (
                <Icon
                  style={{ zIndex: 999 }}
                  onClick={() =>
                    this.setState(
                      { ...this.state, contact: null, autoFocus: false },
                      this.props.onCancel,
                    )
                  }
                  color='action'
                  className='text-24 absolute pin-r pin-t mt-4 mr-4 cursor-pointer'
                >
                  close
                </Icon>
              )}
              <div
                className='w-full flex mb-12 relative'
                style={{
                  borderTop:
                    details && details.length > 0
                      ? '1px solid lightgrey'
                      : 'none',
                }}
              >
                <div className='w-full'>
                  <div className='sm:flex clear px-4'>
                    <div className='w-full sm:flex'>
                      <TextField
                        className='mb-24 sm:mb-0'
                        label='Name'
                        id='contact'
                        name='contact.Name'
                        value={this.state.contact.Name || ''}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        margin='dense'
                        required
                        fullWidth
                        autoFocus={autoFocus}
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      {/* <TextField
                                                className="mb-24 sm:mb-0"
                                                label="Phone"
                                                id="phone"
                                                name="contact.Phone"
                                                value={this.state.contact.Phone || ''}
                                                onChange={this.handleChange}
                                                onBlur={this.handleBlur}
                                                inputProps={{
                                                    autoComplete: "off"
                                                }}
                                                margin="dense"
                                                required
                                                fullWidth
                                            /> */}
                      <PhoneInput
                        {...this.props}
                        className='mb-24 sm:mb-0'
                        label='Phone'
                        id='phone'
                        name='contact.Phone'
                        value={this.state.contact.Phone || ''}
                        onChange={value => {
                          this.setState({
                            contact: { ...this.state.contact, Phone: value },
                          })
                        }}
                        onBlur={this.handleBlur}
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        margin='dense'
                        required={true}
                        fullWidth={true}
                      />
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <TextField
                        label={
                          this.state.contact.NoEmailYN === true ||
                          this.state.contact.NoEmailYN === 'Y'
                            ? 'NO EMAIL'
                            : 'Email'
                        }
                        id='email'
                        name='contact.Email'
                        value={this.state.contact.Email || ''}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        InputProps={{
                          autoComplete: 'off',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Tooltip
                                title={
                                  this.state.contact.NoEmailYN === 'Y'
                                    ? 'Set to an email'
                                    : 'Set to NO Email'
                                }
                                placement='top'
                                arrow
                              >
                                {this.state.contact.NoEmailYN === 'Y' ? (
                                  <IconButton
                                    style={{ height: 30, width: 30 }}
                                    onClick={() => {
                                      this.setState(prevState => ({
                                        ...prevState,
                                        contact: {
                                          ...prevState.contact,
                                          NoEmailYN: 'N',
                                        },
                                      }))
                                    }}
                                  >
                                    <Icon
                                      style={{
                                        color: 'red',
                                        fontSize: 22,
                                        cursor: 'pointer',
                                      }}
                                    >
                                      mail
                                    </Icon>
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    style={{ height: 30, width: 30 }}
                                    onClick={() => {
                                      if (this.state.contact.Email) {
                                        this.setState(prevState => ({
                                          ...prevState,
                                          emailWarning: true,
                                        }))
                                      } else {
                                        this.setState(prevState => ({
                                          ...prevState,
                                          contact: {
                                            ...prevState.contact,
                                            NoEmailYN: 'Y',
                                          },
                                        }))
                                      }
                                    }}
                                  >
                                    <Icon
                                      style={{
                                        color: '#4dbce9',
                                        fontSize: 22,
                                        cursor: 'pointer',
                                      }}
                                    >
                                      mail
                                    </Icon>
                                  </IconButton>
                                )}
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        disabled={
                          this.state.contact.NoEmailYN === 'Y' ? true : false
                        }
                        margin='dense'
                        required
                        fullWidth
                      />
                      {/* Warning Dialog when switching email to no email */}
                      <Dialog
                        open={Boolean(this.state.emailWarning)}
                        maxWidth='sm'
                        fullWidth={true}
                        onClose={() => {
                          this.setState({ emailWarning: false })
                        }}
                      >
                        <AppBar position='static' color='primary'>
                          <Toolbar className='flex w-full'>
                            <Typography color='red'>WARNING</Typography>
                            <IconButton
                              style={{
                                height: 30,
                                width: 30,
                                position: 'absolute',
                                right: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                              }}
                              onClick={() => {
                                this.setState({ emailWarning: false })
                              }}
                            >
                              <Icon style={{ color: 'white', fontSize: 25 }}>
                                close
                              </Icon>
                            </IconButton>
                          </Toolbar>
                        </AppBar>
                        <DialogContent>
                          <Typography>
                            by toggling email to NO EMAIL you will delete:{' '}
                            {this.state.contact.Email}
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() =>
                              this.setState({ emailWarning: false })
                            }
                            color='primary'
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState(prevState => ({
                                ...prevState,
                                contact: {
                                  ...prevState.contact,
                                  NoEmailYN: 'Y',
                                  Email: '',
                                },
                                emailWarning: false,
                              }))
                            }}
                            color='primary'
                          >
                            I am sure
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                  {!this.state.contact.ID && (
                    <Button
                      variant='contained'
                      className='w-full mt-12'
                      color='primary'
                      onClick={this.addContact}
                      disabled={
                        !this.state.contact.Name ||
                        (!this.state.contact.Phone && !this.state.contact.Email)
                      }
                    >
                      Add Contact
                    </Button>
                  )}
                  {this.state.contact.ID && (
                    <div className='flex'>
                      <Button
                        variant='contained'
                        className={classNames(
                          classes.black,
                          'w-full mt-12 rounded-r-none',
                        )}
                        color='primary'
                        onClick={this.updateContact}
                        disabled={
                          !this.state.contact.Name ||
                          (!this.state.contact.Phone &&
                            !this.state.contact.Email)
                        }
                      >
                        Update Contact
                      </Button>
                      {/* {anchorData.customerId === this.state.contact.ID && ( */}
                      <Tooltip
                        title={
                          this.state.details.length > 1
                            ? `Remove: ${this.state.contact.Name}`
                            : 'Customer requires at least one contact'
                        }
                        arrow
                        placement='top'
                      >
                        <Button
                          variant='contained'
                          className={classNames(
                            this.state.details.length > 1
                              ? classes.error
                              : classes.ok,
                            'w-64 mt-12 rounded-l-none',
                          )}
                          onClick={
                            this.state.details.length > 1
                              ? this.removeContact
                              : null
                          }
                        >
                          <Icon>delete</Icon>
                        </Button>
                      </Tooltip>
                      {/* )} */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage,
    },
    dispatch,
  )
}

function mapStateToProps({ spReducers }) {
  return {
    Co: spReducers.companies.Co,
    User: spReducers.userProfiles.User,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(ContactList),
)
