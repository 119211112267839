import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import TimecardLabor from './TimecardLabor';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        padding: 12,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
});

class TimecardLaborDialog extends React.Component {
    state = {

    };

    componentDidMount() {
        const { initialState } = this.props;
        this.setState(initialState);
    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    render() {
        const { classes, title, message, onClose, open, date, employee, trips, source, data } = this.props;

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="sm"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                open={open}
            >
                <DialogTitle classes={{ root: "p-16 pl-24 pr-24" }} id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent classes={{ root: "p-24 pt-8 pb-8" }}>
                    {message}
                    <TimecardLabor onSubmitted={onClose} source={source} date={date} employee={employee} trips={[...trips]} data={data} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        All Done
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TimecardLaborDialog.propTypes = {
    onClose: PropTypes.func,
    value: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addLabor: Actions.addLabor,
        updateLabor: Actions.updateLabor,
        removeLabor: Actions.removeLabor,
    }, dispatch);
}

function mapStateToProps({ spReducers, dispatchBoardApp, timecardsApp }) {
    return {
        user: dispatchBoardApp.user,
        laborTypes: spReducers.laborTypes,
        dialog: timecardsApp.timecards.timecardDialog,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(TimecardLaborDialog));