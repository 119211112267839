import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    type: 'P',
    startDate: firstDayInPreviousMonth(),
    endDate: new Date(),
    status: null,
    paymentStatus: null,
    paymentType: null,
    glAccount: null,
    deliveryStatus: null,
    reviewStatus: null,
    vendor: null,
    entities: [],
    searchText: '',
    loading: false,
};

function firstDayInPreviousMonth() {
    var d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);
    return d;
}

const auditPostingReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.SET_TYPE:
            {
                return {
                    ...state,
                    type: action.data
                };
            }
        case Actions.SET_VENDOR:
            {
                return {
                    ...state,
                    vendor: action.data
                };
            }
        case Actions.SET_GL_ACCOUNT:
            {
                return {
                    ...state,
                    glAccount: action.data
                };
            }
        case Actions.SET_DELIVERY_STATUS:
            {
                return {
                    ...state,
                    deliveryStatus: action.data
                };
            }
        case Actions.SET_REVIEW_STATUS:
            {
                return {
                    ...state,
                    reviewStatus: action.data
                };
            }
        case Actions.SET_STATUS:
            {
                return {
                    ...state,
                    status: action.data
                };
            }
        case Actions.SET_PAYMENT_STATUS:
            {
                return {
                    ...state,
                    paymentStatus: action.data
                };
            }
        case Actions.SET_PAYMENT_TYPE:
            {
                return {
                    ...state,
                    paymentType: action.data
                };
            }
        case Actions.SET_START_DATE:
            {
                return {
                    ...state,
                    startDate: action.date
                };
            }
        case Actions.SET_END_DATE:
            {
                return {
                    ...state,
                    endDate: action.date
                };
            }
        case Actions.SET_AUDIT_DATA:
            {
                return {
                    ...state,
                    entities: action.data,
                    loading: action.loading,
                };
            }
        default:
            {
                return state;
            }
    }
};

export default auditPostingReducer;
