import * as Actions from 'store/actions'
import * as Login from 'auth/store/actions'
import { Capacitor } from '@capacitor/core'
import * as ChatActions from 'main/chatPanel/store/actions/user.actions'
import * as MessagingActions from 'main/messaging/store/actions/text-messages.actions'
import * as DispatchActions from 'main/content/apps/dispatch-board/store/actions/dispatch-board.actions'
import * as InvoiceActions from 'main/content/apps/invoices/store/actions'
import * as TripActions from 'main/content/apps/trips/store/actions'
import * as WorkOrderActions from 'main/content/apps/work-orders/store/actions'
import * as WorkOrderUsageActions from 'main/content/apps/work-order-usages/store/actions'
import * as WorkOrderScopeActions from 'main/content/apps/work-order-scopes/store/actions'
import * as CallGatherActions from 'main/content/apps/call-gathers/store/actions'
import * as CallGatherKeyActions from 'main/content/apps/call-gather-keys/store/actions'
import * as HuntGroupActions from 'main/content/apps/hunt-groups/store/actions'
import * as DialGroupActions from 'main/content/apps/dial-groups/store/actions'
import * as DialGroupMemberActions from 'main/content/apps/dial-group-members/store/actions'
import * as PurchaseOrderLineActions from 'main/content/apps/purchase-order-lines/store/actions'
import * as PurchaseOrderActions from 'main/content/apps/purchase-orders/store/actions'
import * as VendorActions from 'main/content/apps/vendors/store/actions'
import * as TechnicianActions from 'main/content/apps/technicians/store/actions'
import * as AgreementTypeActions from 'main/content/apps/agreement-types/store/actions'
import * as AgreementTypeTaskActions from 'main/content/apps/agreement-type-tasks/store/actions'
import * as AgreementActions from 'main/content/apps/agreements/store/actions'
import * as TextGatherActions from 'main/content/apps/text-gathers/store/actions'
import * as TextGatherPhraseActions from 'main/content/apps/text-gather-phrases/store/actions'
import * as ServiceUnitActions from 'main/content/apps/service-units/store/actions'
import * as ServiceUnitTypeActions from 'main/content/apps/service-unit-types/store/actions'
import * as ServiceUnitCategoryActions from 'main/content/apps/service-unit-categories/store/actions'
import * as CustomerSiteActions from 'main/content/apps/customer-sites/store/actions'
import * as CustomerActions from 'main/content/apps/customers/store/actions'
import * as VaultActions from '../actions/spAuthentication'
import { showNotification } from 'main/quickPanel/store/actions'
import { setSiteData } from 'main/content/apps/call-handler/store/actions'
import CacheManager from './cache'
import { updateUserSettings } from 'auth/store/actions'
import { updateInList } from 'main/functions'
import moment from 'moment'
import _ from 'lodash'
import * as $ from 'jquery'
import Constants from '../../Constants'

window.jQuery = window.$ = $

require('signalr')

moment.updateLocale('en', {
  week: {
    dow: 0, // First day of week is Monday
  },
})

//$(document).bind('mousemove', (e) => { e.preventDefault(); });

const offline = new CacheManager()

let centralHub
let spSocket = window['apiLocation']
  ? $.hubConnection(`${window['apiLocation']}`)
  : null
if (spSocket) {
  spSocket.logging = true
  centralHub = spSocket.createHubProxy('centralHub')
}
let socketStarted = false

const augmentDistId = (clientId, distId) => {
  if (!distId || distId.startsWith(clientId)) {
    return distId
  }

  return `${clientId}_${distId}`
}

const SPMonitor = store => next => action => {
  const platform = Capacitor.getPlatform()
  const state = store.getState()
  const { navigation } = state.fuse
  const { spReducers, spAuth } = state
  const { User } = spReducers.userProfiles
  const { authentication } = spAuth

  var user = User.UserName
  const { Co } = spReducers.companies
  switch (action.type) {
    case VaultActions.INITIALIZE_VAULT:
      {
        const vault = action.payload

        vault.onError(e => {
          store.dispatch(Login.logoutUser())
        })

        vault.onLock(() => {
          store.dispatch(VaultActions.setVaultIsLocked(true))
          // dispatch(VaultActions.setLogin({ unm: null, pass: null }));
        })

        vault.onUnlock(() =>
          store.dispatch(VaultActions.setVaultIsLocked(false)),
        )
      }
      break
    case Actions.START_SIGNAL_R:
      {
        try {
          if (spSocket) {
            spSocket.stop()
            socketStarted = false
          }
          spSocket = window['apiLocation']
            ? $.hubConnection(`${window['apiLocation']}`)
            : null
          if (spSocket) {
            spSocket.logging = true
            centralHub = spSocket.createHubProxy('centralHub')
          }
          if (spSocket && typeof spSocket.stop === 'function') {
            spSocket.stop()
          }
          centralHub.on('hubSync', (type, list) => {
            // window["warn"]('Hub Sync: ', type, list);
            switch (type) {
              case 'UserProfiles':
                {
                  const state = store.getState()
                  const { Users } = state.spReducers.userProfiles
                  let payload = Users
                  list.map(obj => {
                    const method = obj.Method || 'UPDATE'
                    const { UserName } = obj
                    if (!obj.Data) {
                      obj.Data = {
                        ...obj,
                      }
                    }
                    payload = updateInList(Users, obj, method, { UserName })
                  })
                  const type = Actions.GET_USER_PROFILE_LIST_DATA
                  store.dispatch({ type, payload: [...payload] })
                }
                break
              case 'UserGPS':
                {
                  const state = store.getState()
                  const { Users } = state.spReducers.userProfiles
                  let payload = Users
                  list.map(loc => {
                    const { UserName } = loc
                    const obj = {
                      UserName,
                      Data: {
                        Location: loc,
                      },
                    }
                    payload = updateInList(Users, obj, 'MERGE', { UserName })
                  })
                  const type = Actions.GET_USER_PROFILE_LIST_DATA
                  store.dispatch({ type, payload: [...payload] })
                }
                break
            }
          })
          centralHub.on('hubObject', (type, obj, method) => {
            // window["log"]('LIVE FEED: ', method, type, obj);
            switch (type) {
              case 'ChatMessage':
                {
                  const state = store.getState()
                  const { User } = state.spReducers.userProfiles
                  const UserName = obj.To === User.UserName ? obj.From : obj.To
                  const { conversations } = state.chatPanel.user
                  let conversation = _.find(conversations, { UserName })
                  // window["warn"](obj, conversation, conversations);
                  if (conversation) {
                    const conv = _.cloneDeepWith(conversation)
                    conv.Messages = updateInList(conv.Messages, obj, method, {
                      ID: obj.ID,
                    })
                    const payload = updateInList(
                      conversations,
                      conv,
                      'UPDATE',
                      {
                        UserName,
                      },
                    )
                    // window["warn"](obj, conversation, payload);
                    store.dispatch({
                      type: ChatActions.GET_CONVERSATIONS,
                      payload: [...payload],
                    })
                  } else {
                    const conv = { UserName, Messages: [obj] }
                    const convs = _.cloneDeepWith(conversations)
                    convs.push(conv)
                    // window["warn"](obj, conv, convs);
                    store.dispatch({
                      type: ChatActions.GET_CONVERSATIONS,
                      payload: [...convs],
                    })
                  }
                }
                break
              case 'OptionBoardOptionsType':
                {
                  const state = store.getState()
                  const { optionTypes } = state.spReducers
                  const type = Actions.SET_OPTIONS_TYPE_DATA
                  const payload = updateInList(optionTypes, obj, method, {
                    Co: obj.Co,
                    OptionsType: obj.OptionsType,
                  })
                  // window["warn"]('Option Types Updated: ', obj, type, payload);
                  store.dispatch({ type, payload })
                }
                break
              case 'ToDoItem':
                {
                  const state = store.getState()
                  const { calendar } = state.spReducers
                  const { month } = calendar
                  const { ToDos } = calendar.data
                  const type = Actions.SET_CALENDAR_DATA
                  const { ID, Type } = obj
                  const payload = {
                    ...calendar.data,
                    ToDos: updateInList(ToDos, obj, method, { ID, Type }),
                  }
                  // window["warn"]("To Do Items Updated: ", obj, type, payload);
                  store.dispatch({ type, payload, month })
                }
                break
              case 'CalendarEvent':
                {
                  const state = store.getState()
                  const { calendar } = state.spReducers
                  const { month } = calendar
                  const { Events } = calendar.data
                  const type = Actions.SET_CALENDAR_DATA
                  const { ID, Type } = obj
                  const payload = {
                    ...calendar.data,
                    Events: updateInList(Events, obj, method, { ID, Type }),
                  }
                  window['warn'](
                    'Calendar Events Updated: ',
                    obj,
                    type,
                    payload,
                  )
                  store.dispatch({ type, payload, month })
                }
                break
              case 'ToDoTag':
                {
                  const state = store.getState()
                  const { calendar } = state.spReducers
                  const { tags } = calendar
                  const type = Actions.SET_TAG_DATA
                  const { ID } = obj
                  const payload = updateInList(tags, obj, method, { ID })
                  // window["warn"]('To Do Tags Updated: ', obj, type, payload);
                  store.dispatch({ type, payload })
                }
                break
              case 'UserGPS':
                {
                  const state = store.getState()
                  const { gps } = state.spReducers.gps
                  const type = Actions.SET_GPS_DATA
                  const payload = updateInList(gps, obj, method, {
                    UserName: obj.UserName,
                  })
                  store.dispatch({ type, gps: payload })
                }
                break
              case 'Notification':
                {
                  const state = store.getState()
                  const { notifications } = state.spReducers
                  const type = Actions.SET_NOTIFICATION_DATA
                  const payload = updateInList(notifications, obj, method, {
                    ID: obj.ID,
                  })
                  store.dispatch({ type, payload })
                  if (method === 'ADD') {
                    store.dispatch(showNotification(obj))
                    if (platform === 'web') {
                      store.dispatch(
                        Actions.createNotification(
                          obj.ID,
                          obj.Title,
                          obj.Message,
                          obj.Data,
                          obj.Type,
                        ),
                      )
                    }
                  }
                }
                break
              case 'CustomDispatchBoard':
                {
                  const state = store.getState()
                  const { boards } = state.spReducers
                  const type = Actions.SET_BOARD_DATA
                  const payload = updateInList(boards, obj, method, {
                    Co: obj.Co,
                    Board: obj.Board,
                  })
                  store.dispatch({ type, payload })
                }
                break
              case 'CustomFolder':
                {
                  const state = store.getState()
                  const { folders } = state.spReducers
                  const type = Actions.SET_FOLDER_DATA
                  const payload = updateInList(folders, obj, method, {
                    Co: obj.Co,
                    Folder: obj.Folder,
                  })
                  store.dispatch({ type, payload })
                }
                break
              case 'Assembly':
                {
                  const state = store.getState()
                  const { assemblies } = state.spReducers.catalog
                  const type = Actions.SET_ASSEMBLY_DATA
                  const payload = updateInList(assemblies, obj, method, {
                    Co: obj.Co,
                    BusinessUnit: obj.BusinessUnit,
                    Division: obj.Division,
                    Assembly: obj.Assembly,
                  })
                  store.dispatch({ type, payload })
                }
                break
              case 'AssemblyCategory':
                {
                  const state = store.getState()
                  const { categories } = state.spReducers.catalog
                  const type = Actions.SET_ASSEMBLY_CATEGORY_DATA
                  const payload = updateInList(categories, obj, method, {
                    Co: obj.Co,
                    BusinessUnit: obj.BusinessUnit,
                    Division: obj.Division,
                    Category: obj.Category,
                  })
                  store.dispatch({ type, payload })
                }
                break
              case 'AssemblyCategoryAssignment':
                {
                  const state = store.getState()
                  const { categories } = state.spReducers.catalog
                  const category = _.find(categories, {
                    Co: obj.Co,
                    BusinessUnit: obj.BusinessUnit,
                    Division: obj.Division,
                    Category: obj.Category,
                  })
                  if (category) {
                    category.Data.Assemblies = updateInList(
                      category.Data.Assemblies,
                      obj,
                      method,
                      {
                        Co: obj.Co,
                        BusinessUnit: obj.BusinessUnit,
                        Division: obj.Division,
                        Assembly: obj.Assembly,
                        Category: obj.Category,
                      },
                    )
                  }
                  // window["warn"]('New Assignment: ', category, obj, categories);
                  const type = Actions.SET_ASSEMBLY_CATEGORY_DATA
                  store.dispatch({ type, payload: [...categories] })
                }
                break
              case 'Call':
                {
                  const state = store.getState()
                  const { callHandler } = state.spReducers
                  const { Calls } = callHandler
                  const { Sid, CallSid } = obj

                  const type = Actions.SET_CALL_HANDLER_DATA
                  const payload = {
                    ...callHandler,
                    Calls: updateInList(Calls, obj, method, {
                      Sid: Sid || CallSid,
                    }),
                  }
                  store.dispatch({ type, payload: [payload] })
                }
                break
              case 'Timecard':
                {
                  store.dispatch(Actions.updateTimecardData(obj, method))
                }
                break
              case 'AgentStatuses':
                {
                  const state = store.getState()
                  const { Agents } = state.spReducers.agentStatus
                  const { UserName } = obj

                  const type = Actions.SET_AGENT_STATUSES_DATA
                  const payload = updateInList(Agents, obj, method, {
                    UserName,
                  })

                  store.dispatch({ type, payload: [...payload] })
                }
                break
              case 'UserProfiles':
                {
                  const state = store.getState()
                  const { Users } = state.spReducers.userProfiles
                  const { UserName } = obj
                  if (!obj.Data) {
                    obj.Data = {
                      ...obj,
                    }
                  }
                  const type = Actions.GET_USER_PROFILE_LIST_DATA
                  const payload = updateInList(Users, obj, method, { UserName })

                  store.dispatch({ type, payload: [...payload] })
                }
                break
              case 'UserProfile':
                {
                  const state = store.getState()
                  const { spReducers } = state
                  let { User } = spReducers.userProfiles
                  if (User.UserName === obj.UserName) {
                    store.dispatch(Actions.setUserProfileData(obj))
                    let co = spReducers.companies.Co
                    if (obj.Data && obj.Data.TableSettings) {
                      obj.Data.TableSettings.map(settings => {
                        settings.Preferences = JSON.parse(settings.Preferences)
                      })
                    }
                    Promise.all([
                      store.dispatch(Actions.setUserPrivileges(co, obj)),
                    ])
                  }
                }
                break
              case 'Technician':
                {
                  const { Co, Technician } = obj
                  const state = store.getState()
                  const { technicians } = state.spReducers
                  const payload = updateInList(technicians, obj, method, {
                    Co,
                    Technician,
                  })
                  store.dispatch({
                    type: Actions.SET_TECHNICIAN_DATA,
                    payload,
                  })
                  store.dispatch(DispatchActions.setBoardTechnicians(payload))
                }
                break
              case 'TechTrip':
                {
                  const state = store.getState()
                  const { start, allTrips } = state.spReducers.technician
                  const newStart = moment(start)
                    .locale('us')
                    .weekday(1)
                    .format('M/D/YYYY')
                  const { Co, WorkOrder, Trip } = obj
                  const raw = updateInList(allTrips, obj, method, {
                    Co,
                    WorkOrder,
                    Trip,
                  })
                  const schedules = []
                  for (var i = 0; i < 7; i++) {
                    let ndt = new Date(newStart)
                    ndt.setDate(ndt.getDate() + i)
                    ndt = moment(ndt).format('M/D/YYYY')
                    const trips = _.filter(raw, o => {
                      return (
                        moment(
                          o.InProgressDate ||
                            o.ArrivedDate ||
                            o.EnRouteDate ||
                            o.ScheduledDate,
                        ).format('M/D/YYYY') === ndt
                      )
                    })
                    schedules.push({ Date: ndt, trips })
                  }
                  Promise.all([
                    store.dispatch({
                      type: Actions.SET_TECHNICIAN_SCHEDULE,
                      payload: schedules,
                      raw,
                      persist: true,
                    }),
                  ]).then(() => {
                    if (method === 'ADD') {
                      store.dispatch(Actions.checkGeofence(obj))
                    }
                  })
                }
                break
              case 'Trip':
                {
                  if (method === 'ADD-MULTI') {
                    obj.map(val => {
                      store.dispatch(
                        DispatchActions.updateTechnicianTrip(val, 'ADD'),
                      )
                    })
                  } else {
                    store.dispatch(
                      DispatchActions.updateTechnicianTrip(obj, method),
                    )
                  }
                }
                break
              case 'WorkOrder':
                {
                  const state = store.getState()
                  const { spReducers, dispatchBoardApp } = state
                  const { workOrders } = spReducers
                  const { trips } = dispatchBoardApp.dispatchBoard
                  const { Co, WorkOrder } = obj
                  const type = Actions.SET_WORK_ORDER_DATA
                  const payload = updateInList(workOrders, obj, method, {
                    Co,
                    WorkOrder,
                  })
                  Promise.all([
                    store.dispatch({ type, payload: [...payload] }),
                  ]).then(() => {
                    store.dispatch(DispatchActions.setBoardTrips(trips))
                  })
                }
                break
              case 'AgreementWorkOrder':
                {
                  const state = store.getState()
                  const { spReducers } = state
                  const { workOrders } = spReducers
                  const { Co, WorkOrder } = obj
                  const type = Actions.SET_WORK_ORDER_DATA
                  const payload = updateInList(workOrders, obj, method, {
                    Co,
                    WorkOrder,
                  })
                  store.dispatch({ type, payload: [...payload] })
                }
                break
              case 'Voicemail':
                {
                  const state = store.getState()
                  const { spReducers } = state
                  const { voicemail } = spReducers
                  const { Co, CallSid } = obj
                  const type = Actions.SET_VOICEMAIL_DATA
                  const payload = updateInList(voicemail, obj, method, {
                    Co,
                    CallSid,
                  })
                  store.dispatch({ type, payload: [...payload] })
                }
                break
              case 'VoiceMailbox':
                {
                  const state = store.getState()
                  const { spReducers } = state
                  const { voiceMailboxes } = spReducers
                  const { Co, VoiceMailbox } = obj
                  const type = Actions.SET_VOICE_MAILBOX_DATA
                  const payload = updateInList(voiceMailboxes, obj, method, {
                    Co,
                    VoiceMailbox,
                  })
                  store.dispatch({ type, payload: [...payload] })
                }
                break
            }
          })
          centralHub.on('hubResponse', (type, resp) => {
            // window["log"]("LIVE FEED: ", type, resp);
            switch (type) {
              case 'Company':
                {
                  const companies = resp
                  const state = store.getState()
                  const { List } = state.spReducers.companies
                  const newList = []
                  companies.map(co => {
                    const index = _.findIndex(List, { Co: co.Co })
                    if (index > -1) {
                      newList.push(List[index])
                      newList[index].Data.Co = co
                    }
                  })
                  store.dispatch({
                    type: Actions.GET_COMPANY_DATA,
                    payload: newList,
                  })
                }
                break
              case 'CompanyUser':
                {
                  const companies = resp
                  const state = store.getState()
                  const { Co } = state.spReducers.companies
                  const { current } = state.fuse.settings
                  const { User } = state.spReducers.userProfiles
                  const userCo = _.find(User.Data.Companies, { Co })
                  if (userCo && userCo.SelectedTheme) {
                    store.dispatch(
                      Actions.setSettings({
                        ...current,
                        theme: { ...current.theme, main: userCo.SelectedTheme },
                      }),
                    )
                  }
                  store.dispatch({
                    type: Actions.GET_COMPANY_DATA,
                    payload: resp,
                  })
                }
                break
              case 'ChatMessage':
                store.dispatch({
                  type: ChatActions.GET_CONVERSATIONS,
                  payload: resp,
                })
                break
              case 'ReportSecurable':
                store.dispatch({
                  type: Actions.SET_REPORT_SECURABLE_DATA,
                  payload: resp,
                })
                break
              case 'DashboardSecurable':
                store.dispatch({
                  type: Actions.SET_DASHBOARD_SECURABLE_DATA,
                  payload: resp,
                })
                break
              case 'PhoneNumber':
                store.dispatch({
                  type: Actions.SET_PHONE_NUMBER_DATA,
                  payload: resp,
                })
                break
              case 'Gather':
                {
                  store.dispatch({
                    type: Actions.SET_CALL_GATHER_DATA,
                    payload: resp,
                  })
                }
                break
              case 'Class':
                {
                  store.dispatch({
                    type: Actions.SET_CLASS_DATA,
                    payload: resp,
                  })
                }
                break
              case 'ClassCategory':
                {
                  store.dispatch({
                    type: Actions.SET_CLASS_CATEGORY_DATA,
                    payload: resp,
                  })
                }
                break
              case 'UserRole':
                {
                  Promise.all([
                    store.dispatch({
                      type: Actions.SET_USER_ROLE_LIST_DATA,
                      payload: resp,
                    }),
                  ]).then(() => {
                    let co = store.getState().spReducers.companies.Co
                    Promise.all([
                      store.dispatch(Actions.setUserPrivileges(co, null, resp)),
                    ])
                  })
                }
                break
              case 'HuntGroup':
                {
                  store.dispatch({
                    type: Actions.SET_HUNT_GROUP_DATA,
                    payload: resp,
                  })
                }
                break
              case 'Team':
                {
                  store.dispatch({
                    type: Actions.SET_TEAM_DATA,
                    payload: resp,
                  })
                }
                break
              case 'Form':
                {
                  store.dispatch({
                    type: Actions.SET_FORM_DATA,
                    payload: resp,
                  })
                }
                break
              case 'Checklist':
                {
                  store.dispatch({
                    type: Actions.SET_CHECKLIST_DATA,
                    payload: resp,
                  })
                }
                break
              case 'TextMessage':
                {
                  store.dispatch({
                    type: Actions.SET_TEXT_MESSAGE_DATA,
                    payload: resp,
                  })
                }
                break
              case 'TextConversation':
                {
                  store.dispatch(MessagingActions.updateConversation(resp[0]))
                }
                break
              case 'TextGather':
                {
                  store.dispatch({
                    type: Actions.SET_TEXT_GATHER_DATA,
                    payload: resp,
                  })
                }
                break
              case 'TechnicianUATime':
                {
                  store.dispatch({
                    type: Actions.SET_UA_TIME_DATA,
                    payload: resp,
                  })
                }
                break
              case 'CallQueue':
                {
                  store.dispatch({
                    type: Actions.SET_CALL_QUEUE_DATA,
                    payload: resp,
                  })
                }
                break
              case 'Voicemail':
                {
                  store.dispatch({
                    type: Actions.SET_VOICEMAIL_DATA,
                    payload: resp,
                  })
                }
                break
              case 'VoiceMailbox':
                {
                  store.dispatch({
                    type: Actions.SET_VOICE_MAILBOX_DATA,
                    payload: resp,
                  })
                }
                break
              case 'AudioClips':
                store.dispatch({
                  type: Actions.SET_AUDIO_CLIPS,
                  payload: resp,
                })
                break
              case 'HoldMusic':
                store.dispatch({
                  type: Actions.SET_HOLD_MUSIC,
                  payload: resp,
                })
                break
              case 'Call':
                store.dispatch({
                  type: Actions.SET_CALL_HANDLER_DATA,
                  payload: resp,
                })
                break
              case 'UOM':
                store.dispatch({
                  type: Actions.SET_UOM_DATA,
                  payload: resp,
                })
                break
              case 'WorkOrder':
                Promise.all([
                  store.dispatch({
                    type: Actions.SET_WORK_ORDER_DATA,
                    payload: resp,
                  }),
                ]).then(() => {
                  const state = store.getState()
                  const { dispatchBoardApp } = state
                  const { trips } = dispatchBoardApp.dispatchBoard
                  store.dispatch(DispatchActions.setBoardTrips(trips))
                })
                break
              case 'Customer':
                store.dispatch({
                  type: Actions.SET_CUSTOMER_DATA,
                  payload: resp,
                })
                break
              case 'GLAccount':
                store.dispatch({
                  type: Actions.SET_GL_ACCOUNT_DATA,
                  payload: resp,
                })
                break
              case 'CostType':
                store.dispatch({
                  type: Actions.SET_COST_TYPE_DATA,
                  payload: resp,
                })
                break
              case 'PayTerm':
                store.dispatch({
                  type: Actions.SET_PAY_TERM_DATA,
                  payload: resp,
                })
                break
              case 'CompanyUser':
                store.dispatch({
                  type: Actions.GET_COMPANY_DATA,
                  payload: resp,
                })
                break
              case 'CustomerSite':
                store.dispatch({
                  type: Actions.SET_CUSTOMER_SITE_DATA,
                  payload: resp,
                })
                break
              case 'Service':
                store.dispatch({
                  type: Actions.SET_SERVICE_DATA,
                  payload: resp,
                })
                break
              case 'ServiceType':
                store.dispatch({
                  type: Actions.SET_SERVICE_TYPE_DATA,
                  payload: resp,
                })
                break
              case 'LaborType':
                store.dispatch({
                  type: Actions.SET_LABOR_TYPE_DATA,
                  payload: resp,
                })
                break
              case 'Department':
                store.dispatch({
                  type: Actions.SET_DEPARTMENT_DATA,
                  payload: resp,
                })
                break
              case 'ReportCategory':
                store.dispatch({
                  type: Actions.SET_REPORT_CATEGORY_DATA,
                  payload: resp,
                })
                break
              case 'BusinessUnit':
                store.dispatch({
                  type: Actions.SET_BUSINESS_UNIT_DATA,
                  payload: resp,
                })
                break
              case 'Division':
                store.dispatch({
                  type: Actions.SET_DIVISION_DATA,
                  payload: resp,
                })
                break
              case 'TaxCode':
                store.dispatch({
                  type: Actions.SET_TAX_CODE_DATA,
                  payload: resp,
                })
                break
              case 'RateTemplate':
                store.dispatch({
                  type: Actions.SET_RATE_TEMPLATE_DATA,
                  payload: resp,
                })
                break
              case 'ArrivalWindow':
                store.dispatch({
                  type: Actions.SET_ARRIVAL_WINDOW_DATA,
                  payload: resp,
                })
                break
              case 'FormOfPayment':
                store.dispatch({
                  type: Actions.SET_FORM_OF_PAYMENT_DATA,
                  payload: resp,
                })
                break
              case 'AgreementType':
                store.dispatch({
                  type: Actions.SET_AGREEMENT_TYPE_DATA,
                  payload: resp,
                })
                break
              case 'Agreement':
                store.dispatch({
                  type: Actions.SET_AGREEMENT_DATA,
                  payload: resp,
                })
                break
              case 'Technician':
                store.dispatch({
                  type: Actions.SET_TECHNICIAN_DATA,
                  payload: resp,
                })
                store.dispatch(DispatchActions.setBoardTechnicians(resp))
                break
              case 'Employee':
                store.dispatch({
                  type: Actions.SET_EMPLOYEE_DATA,
                  payload: resp,
                })
                break
              case 'Vendor':
                store.dispatch({
                  type: Actions.SET_VENDOR_DATA,
                  payload: resp,
                })
                break
              case 'PurchaseOrder':
                store.dispatch({
                  type: Actions.SET_PURCHASE_ORDER_DATA,
                  payload: resp,
                })
                break
              case 'MaterialCategory':
                store.dispatch({
                  type: Actions.SET_MATERIAL_CATEGORY_DATA,
                  payload: resp,
                })
                break
              case 'Material':
                store.dispatch({
                  type: Actions.SET_MATERIAL_DATA,
                  payload: resp,
                })
                break
              case 'FleetVehicleCategory':
                store.dispatch({
                  type: Actions.SET_FLEET_VEHICLE_CATEGORY_DATA,
                  payload: resp,
                })
                break
              case 'ServiceUnitCategory':
                store.dispatch({
                  type: Actions.SET_SERVICE_UNIT_CATEGORY_DATA,
                  payload: resp,
                })
                break
              case 'ServiceUnitType':
                store.dispatch({
                  type: Actions.SET_SERVICE_UNIT_TYPE_DATA,
                  payload: resp,
                })
                break
              case 'FleetVehicle':
                store.dispatch({
                  type: Actions.SET_FLEET_VEHICLE_DATA,
                  payload: resp,
                })
                break
              case 'InventoryLocationGroup':
                store.dispatch({
                  type: Actions.SET_INVENTORY_LOCATION_GROUP_DATA,
                  payload: resp,
                })
                break
              case 'InventoryLocation':
                store.dispatch({
                  type: Actions.SET_INVENTORY_LOCATION_DATA,
                  payload: resp,
                })
                break
              case 'Trip':
                store.dispatch(DispatchActions.setBoardTrips(resp))
                break
              case 'TechTrip':
                {
                  const state = store.getState()
                  const { start } = state.spReducers.technician
                  const newStart = moment(start)
                    .locale('us')
                    .weekday(1)
                    .format('M/D/YYYY')
                  const schedules = []
                  for (var i = 0; i < 7; i++) {
                    let ndt = new Date(newStart)
                    ndt.setDate(ndt.getDate() + i)
                    ndt = moment(ndt).format('M/D/YYYY')
                    const trips = _.filter(resp, o => {
                      return (
                        moment(
                          o.InProgressDate ||
                            o.ArrivedDate ||
                            o.EnRouteDate ||
                            o.ScheduledDate,
                        ).format('M/D/YYYY') === ndt
                      )
                    })
                    schedules.push({ Date: ndt, trips })
                  }
                  store.dispatch({
                    type: Actions.SET_TECHNICIAN_SCHEDULE,
                    payload: schedules,
                    raw: resp,
                    persist: true,
                  })
                }
                break
              case 'UserProfile':
                {
                  let co = store.getState().spReducers.companies.Co
                  if (resp[0].Data && resp[0].Data.TableSettings) {
                    resp[0].Data.TableSettings.map(settings => {
                      settings.Preferences = JSON.parse(settings.Preferences)
                    })
                  }
                  Promise.all([
                    store.dispatch(Actions.setUserPrivileges(co, resp[0])),
                  ])
                }
                break
              case 'UserProfiles':
                store.dispatch({
                  type: Actions.GET_USER_PROFILE_LIST_DATA,
                  payload: resp,
                })
                break
              case 'AgentStatuses':
                store.dispatch({
                  type: Actions.SET_AGENT_STATUSES_DATA,
                  payload: resp,
                })
                break
              case 'AgentStatus':
                store.dispatch({
                  type: Actions.SET_AGENT_STATUS_DATA,
                  payload: resp[0],
                })
                break
            }
          })
          spSocket.stateChanged(change => {
            if (change.newState === $.signalR.connectionState.reconnecting) {
              socketStarted = false
              store.dispatch({
                type: Actions.SHOW_MESSAGE,
                options: {
                  message: `Database connection lost - attempting to reconnect...`,
                  autoHideDuration: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  variant: 'warning',
                },
              })
            } else if (
              change.newState === $.signalR.connectionState.connected
            ) {
              socketStarted = true
              store.dispatch({
                type: Actions.CONNECTED_SIGNAL_R,
                connected: true,
              })
              store.dispatch({
                type: Actions.SHOW_MESSAGE,
                options: {
                  message: `Connected to Database`,
                  autoHideDuration: 5000,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  variant: 'success',
                },
              })
            } else if (
              change.newState === $.signalR.connectionState.disconnected
            ) {
              socketStarted = false
              store.dispatch({
                type: Actions.CONNECTED_SIGNAL_R,
                connected: false,
              })
              store.dispatch({
                type: Actions.SHOW_MESSAGE,
                options: {
                  message: `Disconnected from Database`,
                  autoHideDuration: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  variant: 'error',
                },
              })
            }
          })
          const { authentication, api } = state.spAuth
          const { client } = api
          if (authentication) {
            spSocket.qs = {
              access_token: authentication.access_token,
            }

            if (process.env.NODE_ENV === 'development') {
              spSocket.qs[Constants.SUBDOMAIN_HEADER_KEY] = client
            }
          }
          spSocket
            .start()
            .done(() => {
              socketStarted = true
              store.dispatch({
                type: Actions.SHOW_MESSAGE,
                options: {
                  message: `Connected to Database`,
                  autoHideDuration: 5000,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  variant: 'success',
                },
              })
              store.dispatch({
                type: Actions.CONNECTED_SIGNAL_R,
                connected: true,
              })
              store.dispatch(Actions.getCompanyList(user))
            })
            .catch(err => {
              socketStarted = false
              store.dispatch({
                type: Actions.CONNECTED_SIGNAL_R,
                connected: false,
              })
              store.dispatch({
                type: Actions.SHOW_MESSAGE,
                options: {
                  message: `Error connecting to Database`,
                  autoHideDuration: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  variant: 'error',
                },
              })
              //if (user && store.getState().auth.login.success) {
              //    store.dispatch(Actions.logIn(user.user, atob(user.password), 200));
              //}
            })
          store.dispatch({
            type: Actions.SHOW_MESSAGE,
            options: {
              message: `Connecting to Database...`,
              autoHideDuration: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: 'info',
            },
          })
        } catch (error) {
          store.dispatch({
            type: Actions.SHOW_MESSAGE,
            options: {
              message: `Error: ${error}`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            },
          })
        }
      }
      break
    case Actions.STOP_SIGNAL_R:
      {
        if (spSocket) {
          spSocket.stop()
        }
      }
      break
    case Actions.SUBSCRIBE_LIST:
      {
        const { List } = action
        if (socketStarted) {
          const { clientId } = authentication
          const augmentedList = augmentDistId(clientId, List)
          centralHub.invoke('JoinDist', augmentedList).then(() => {
            store.dispatch({ type: Actions.ADD_SUBSCRIPTION, augmentedList })
          })
        }
      }
      break
    case Actions.UNSUBSCRIBE_LIST:
      {
        const { List } = action
        if (socketStarted) {
          const { clientId } = authentication
          const augmentedList = augmentDistId(clientId, List)
          centralHub.invoke('LeaveDist', augmentedList).then(() => {
            store.dispatch({ type: Actions.REMOVE_SUBSCRIPTION, augmentedList })
          })
        }
      }
      break
    case PurchaseOrderActions.ADD_PURCHASE_ORDER:
      {
        const po = action.data
        const { workOrdersApp, workOrderScopesApp, vendorsApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (data.WorkOrder === po.WorkOrder) {
            data.Data.POs.push(po)
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (data.WorkOrder === po.WorkOrder) {
            data.Data.POs.push(po)
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          if (data.Vendor === po.Vendor) {
            data.Data.POs.push(po)
            store.dispatch({
              type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
              data,
            })
          }
        }
      }
      break
    case PurchaseOrderActions.UPDATE_PURCHASE_ORDER:
      {
        const po = action.data
        const { workOrdersApp, workOrderScopesApp, vendorsApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (data.WorkOrder === po.WorkOrder) {
            data.Data.POs.splice(
              _.findIndex(data.Data.POs, { PO: po.PO }),
              1,
              po,
            )
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (data.WorkOrder === po.WorkOrder) {
            data.Data.POs.splice(
              _.findIndex(data.Data.POs, { PO: po.PO }),
              1,
              po,
            )
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          if (data.Vendor === po.Vendor) {
            data.Data.POs.splice(
              _.findIndex(data.Data.POs, { PO: po.PO }),
              1,
              po,
            )
            store.dispatch({
              type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
              data,
            })
          }
        }
      }
      break
    case PurchaseOrderActions.REMOVE_PURCHASE_ORDER:
      {
        const po = action.data
        const { workOrdersApp, workOrderScopesApp, vendorsApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (data.WorkOrder === po.WorkOrder) {
            data.Data.POs.splice(_.findIndex(data.Data.POs, { PO: po.PO }), 1)
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (data.WorkOrder === po.WorkOrder) {
            data.Data.POs.splice(_.findIndex(data.Data.POs, { PO: po.PO }), 1)
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          if (data.Vendor === po.Vendor) {
            data.Data.POs.splice(_.findIndex(data.Data.POs, { PO: po.PO }), 1)
            store.dispatch({
              type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
              data,
            })
          }
        }
      }
      break
    case PurchaseOrderLineActions.ADD_PURCHASE_ORDER_LINE:
      {
        const line = action.data
        const {
          purchaseOrdersApp,
          workOrdersApp,
          workOrderScopesApp,
          vendorsApp,
        } = state
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        const { workOrderDialog } = workOrdersApp.workOrders
        const { vendorDialog } = vendorsApp.vendors
        const { purchaseOrderDialog } = purchaseOrdersApp.purchaseOrders
        if (line.Data && line.Data.Usage) {
          if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
            var { data } = workOrderScopeDialog
            if (
              data.WorkOrder === line.WorkOrder &&
              data.Scope === line.Scope
            ) {
              data.Data.Usage.push(line.Data.Usage)
              var po = _.find(data.Data.POs, { PO: line.PO })
              if (po && po.Data && po.Data.Lines) {
                po.Data.Lines.push(line)
                // window["warn"](data, po);
              }
              store.dispatch({
                type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
                data,
              })
            }
            // window["warn"](data);
          }
          if (workOrderDialog.props.open && workOrderDialog.data) {
            var { data } = workOrderDialog
            if (data.WorkOrder === line.WorkOrder) {
              data.Data.Usage.push(line.Data.Usage)
              for (var b = 0; b < data.Data.Billing.length; b++) {
                const Billing = data.Data.Billing[b]
                const scope = _.find(Billing.Scopes, { Scope: line.Scope })
                if (scope) {
                  scope.Data.Usage.push(line.Data.Usage)
                }
              }
              var po = _.find(data.Data.POs, { PO: line.PO })
              if (po && po.Data && po.Data.Lines) {
                if (
                  workOrderScopeDialog.props.open &&
                  workOrderScopeDialog.data
                ) {
                  po.Data.Lines.splice(
                    _.findIndex(po.Data.Lines, {
                      PO: line.PO,
                      POLine: line.POLine,
                    }),
                    1,
                    line,
                  )
                } else {
                  po.Data.Lines.push(line)
                }
                // window["warn"](data, po);
              }
              store.dispatch({
                type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
                data,
              })
            }
            // window["warn"](data);
          }
        }
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          var po = _.find(data.Data.POs, { PO: line.PO })
          if (po && po.Data && po.Data.Lines) {
            po.Data.Lines.push(line)
            // window["warn"](data, po);
          }
          store.dispatch({
            type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (purchaseOrderDialog.props.open && purchaseOrderDialog.data) {
          var { data } = purchaseOrderDialog
          if (data.PO === line.PO) {
            if (
              _.findIndex(data.Data.Lines, {
                PO: line.PO,
                POLine: line.POLine,
              }) > -1
            ) {
              data.Data.Lines.splice(
                _.findIndex(data.Data.Lines, {
                  PO: line.PO,
                  POLine: line.POLine,
                }),
                1,
                line,
              )
            } else {
              data.Data.Lines.push(line)
            }
            store.dispatch({
              type: PurchaseOrderActions.UPDATE_EDIT_PURCHASE_ORDER_DIALOG,
              data,
            })
            // window["warn"](data);
          }
        }
      }
      break
    case PurchaseOrderLineActions.UPDATE_PURCHASE_ORDER_LINE:
      {
        const line = action.data
        const {
          purchaseOrdersApp,
          workOrdersApp,
          workOrderScopesApp,
          vendorsApp,
        } = state
        const { purchaseOrderDialog } = purchaseOrdersApp.purchaseOrders
        if (purchaseOrderDialog.props.open && purchaseOrderDialog.data) {
          var { data } = purchaseOrderDialog
          // window["warn"]('Lines: ', data.Data.Lines);
          if (data.PO === line.PO) {
            data.Data.Lines.splice(
              _.findIndex(data.Data.Lines, {
                PO: line.PO,
                POLine: line.POLine,
              }),
              1,
              line,
            )
            store.dispatch({
              type: PurchaseOrderActions.UPDATE_EDIT_PURCHASE_ORDER_DIALOG,
              data,
            })
          }
        }
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          var po = _.find(data.Data.POs, { PO: line.PO })
          if (po && po.Data && po.Data.Lines) {
            po.Data.Lines.splice(
              _.findIndex(po.Data.Lines, { PO: line.PO, POLine: line.POLine }),
              1,
              line,
            )
          }
          store.dispatch({
            type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
            data,
          })
        }
        if (line.Data && line.Data.Usage) {
          const { workOrderDialog } = workOrdersApp.workOrders
          if (workOrderDialog.props.open && workOrderDialog.data) {
            var { data } = workOrderDialog
            if (data.WorkOrder === line.WorkOrder) {
              // data.Data.Usage.push(line.Data.Usage);
              data.Data.Usage.splice(
                _.findIndex(data.Data.Usage, {
                  Line: line.Data.Usage.Line,
                  PO: line.PO,
                  POLine: line.POLine,
                }),
                1,
                line.Data.Usage,
              )
              for (var b = 0; b < data.Data.Billing.length; b++) {
                const Billing = data.Data.Billing[b]
                const scope = _.find(Billing.Scopes, { Scope: line.Scope })
                if (scope) {
                  scope.Data.Usage.splice(
                    _.findIndex(scope.Data.Usage, {
                      Line: line.Data.Usage.Line,
                      PO: line.PO,
                      POLine: line.POLine,
                    }),
                    1,
                    line.Data.Usage,
                  )
                }
              }
              var po = _.find(data.Data.POs, { PO: line.PO })
              if (po && po.Data && po.Data.Lines) {
                po.Data.Lines.splice(
                  _.findIndex(po.Data.Lines, {
                    PO: line.PO,
                    POLine: line.POLine,
                  }),
                  1,
                  line,
                )
                // window["warn"](data, po);
              }
              store.dispatch({
                type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
                data,
              })
            }
          }
          const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
          if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
            var { data } = workOrderScopeDialog
            if (
              data.WorkOrder === line.WorkOrder &&
              data.Scope === line.Scope
            ) {
              data.Data.Usage.splice(
                _.findIndex(data.Data.Usage, {
                  Line: line.Data.Usage.Line,
                  PO: line.PO,
                  POLine: line.POLine,
                }),
                1,
                line.Data.Usage,
              )
              var po = _.find(data.Data.POs, { PO: line.PO })
              if (po && po.Data && po.Data.Lines) {
                po.Data.Lines.splice(
                  _.findIndex(po.Data.Lines, {
                    PO: line.PO,
                    POLine: line.POLine,
                  }),
                  1,
                  line,
                )
                // window["warn"](data, po);
              }
              store.dispatch({
                type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
                data,
              })
            }
          }
        }
      }
      break
    // case DispatchActions.SET_TRIPS:
    //     {
    //         if (action.payload[0]) {
    //             window["warn"]({ ...action.payload[0].Data });
    //         }
    //     }
    //     break;
    case PurchaseOrderLineActions.REMOVE_PURCHASE_ORDER_LINE:
      {
        const line = action.data
        const {
          purchaseOrdersApp,
          workOrdersApp,
          workOrderScopesApp,
          vendorsApp,
        } = state
        const { purchaseOrderDialog } = purchaseOrdersApp.purchaseOrders
        if (purchaseOrderDialog.props.open && purchaseOrderDialog.data) {
          var { data } = purchaseOrderDialog
          // window["warn"]('Lines: ', data.Data.Lines);
          if (data.PO === line.PO) {
            data.Data.Lines.splice(
              _.findIndex(data.Data.Lines, {
                PO: line.PO,
                POLine: line.POLine,
              }),
              1,
            )
            store.dispatch({
              type: PurchaseOrderActions.UPDATE_EDIT_PURCHASE_ORDER_DIALOG,
              data,
            })
          }
        }
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          var po = _.find(data.Data.POs, { PO: line.PO })
          if (po && po.Data && po.Data.Lines) {
            po.Data.Lines.splice(
              _.findIndex(po.Data.Lines, { PO: line.PO, POLine: line.POLine }),
              1,
            )
          }
          store.dispatch({
            type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
            data,
          })
        }
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (data.WorkOrder === line.WorkOrder) {
            data.Data.Usage.splice(
              _.findIndex(data.Data.Usage, {
                PO: line.PO,
                POLine: line.POLine,
              }),
              1,
            )
            for (var b = 0; b < data.Data.Billing.length; b++) {
              const Billing = data.Data.Billing[b]
              const scope = _.find(Billing.Scopes, { Scope: line.Scope })
              if (scope) {
                scope.Data.Usage.splice(
                  _.findIndex(scope.Data.Usage, {
                    PO: line.PO,
                    POLine: line.POLine,
                  }),
                  1,
                )
              }
            }
            var po = _.find(data.Data.POs, { PO: line.PO })
            if (po && po.Data && po.Data.Lines) {
              po.Data.Lines.splice(
                _.findIndex(po.Data.Lines, {
                  PO: line.PO,
                  POLine: line.POLine,
                }),
                1,
              )
              // window["warn"](data, po);
            }
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (data.WorkOrder === line.WorkOrder && data.Scope === line.Scope) {
            data.Data.Usage.splice(
              _.findIndex(data.Data.Usage, {
                PO: line.PO,
                POLine: line.POLine,
              }),
              1,
            )
            var po = _.find(data.Data.POs, { PO: line.PO })
            if (po && po.Data && po.Data.Lines) {
              po.Data.Lines.splice(
                _.findIndex(po.Data.Lines, {
                  PO: line.PO,
                  POLine: line.POLine,
                }),
                1,
              )
              // window["warn"](data, po);
            }
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept POLine Receiving info and pass along to affected dialogs
    case PurchaseOrderLineActions.ADD_PURCHASE_ORDER_RECEIVING:
      {
        const rec = action.data
        const { purchaseOrderLinesApp } = state
        const { purchaseOrderLineDialog } =
          purchaseOrderLinesApp.purchaseOrderLines
        if (
          purchaseOrderLineDialog.props.open &&
          purchaseOrderLineDialog.data
        ) {
          var { data } = purchaseOrderLineDialog
          if (data.PO == rec.PO && data.POLine === rec.POLine) {
            data.Data.Receiving.push(rec)
          }
          store.dispatch({
            type: PurchaseOrderLineActions.UPDATE_EDIT_PURCHASE_ORDER_LINE_DIALOG,
            data,
          })
        }
      }
      break
    //Intercept POLine Receiving info and pass along to affected dialogs
    case PurchaseOrderLineActions.UPDATE_PURCHASE_ORDER_RECEIVING:
      {
        const rec = action.data
        const { purchaseOrderLinesApp } = state
        const { purchaseOrderLineDialog } =
          purchaseOrderLinesApp.purchaseOrderLines
        if (
          purchaseOrderLineDialog.props.open &&
          purchaseOrderLineDialog.data
        ) {
          var { data } = purchaseOrderLineDialog
          if (data.PO == rec.PO && data.POLine === rec.POLine) {
            data.Data.Receiving.splice(
              _.findIndex(data.Data.Receiving, {
                PO: rec.PO,
                POLine: rec.POLine,
                Seq: rec.Seq,
              }),
              1,
              rec,
            )
            store.dispatch({
              type: PurchaseOrderLineActions.UPDATE_EDIT_PURCHASE_ORDER_LINE_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept POLine Receiving info and pass along to affected dialogs
    case PurchaseOrderLineActions.REMOVE_PURCHASE_ORDER_RECEIVING:
      {
        const rec = action.data
        const { purchaseOrderLinesApp } = state
        const { purchaseOrderLineDialog } =
          purchaseOrderLinesApp.purchaseOrderLines
        if (
          purchaseOrderLineDialog.props.open &&
          purchaseOrderLineDialog.data
        ) {
          var { data } = purchaseOrderLineDialog
          if (data.PO == rec.PO && data.POLine === rec.POLine) {
            data.Data.Receiving.splice(
              _.findIndex(data.Data.Receiving, {
                PO: rec.PO,
                POLine: rec.POLine,
                Seq: rec.Seq,
              }),
              1,
            )
            store.dispatch({
              type: PurchaseOrderLineActions.UPDATE_EDIT_PURCHASE_ORDER_LINE_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Vendor Material info and pass along to affected dialogs
    case VendorActions.ADD_VENDOR_MATERIAL:
      {
        const rec = action.data
        const { vendorsApp } = state
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          if (data.Vendor == rec.Vendor) {
            data.Data.Materials.push(rec)
          }
          store.dispatch({
            type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
            data,
          })
        }
      }
      break
    //Intercept Vendor Material info and pass along to affected dialogs
    case VendorActions.UPDATE_VENDOR_MATERIAL:
      {
        const rec = action.data
        const { vendorsApp } = state
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          if (data.Vendor == rec.Vendor) {
            data.Data.Materials.splice(
              _.findIndex(data.Data.Materials, {
                Vendor: rec.Vendor,
                Material: rec.Material,
              }),
              1,
              rec,
            )
            store.dispatch({
              type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Vendor Material info and pass along to affected dialogs
    case VendorActions.REMOVE_VENDOR_MATERIAL:
      {
        const rec = action.data
        const { vendorsApp } = state
        const { vendorDialog } = vendorsApp.vendors
        if (vendorDialog.props.open && vendorDialog.data) {
          var { data } = vendorDialog
          if (data.Vendor == rec.Vendor) {
            data.Data.Materials.splice(
              _.findIndex(data.Data.Materials, {
                Vendor: rec.Vendor,
                Material: rec.Material,
              }),
              1,
            )
            store.dispatch({
              type: VendorActions.UPDATE_EDIT_VENDOR_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Scope info and pass along to affected dialogs
    case WorkOrderScopeActions.ADD_WORK_ORDER_SCOPE:
      {
        const scope = action.data
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === scope.WorkOrder) {
            data.Data.Scopes.push(scope)
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
        }
      }
      break
    case WorkOrderScopeActions.UPDATE_WORK_ORDER_SCOPE:
      {
        const scope = action.data
        if (scope.PriceMethod === 'F') {
          var usage = _.filter(scope.Data.Usage, {
            Scope: scope.Scope,
            Status: 'NEW',
          })
          for (var u = 0; u < usage.length; u++) {
            if (usage[u].Type < 5) {
              usage[u].Status = 'FLAT PRICE'
            }
          }
        }
        if (scope.PriceMethod === 'T') {
          var usage = _.filter(scope.Data.Usage, {
            Scope: scope.Scope,
            Status: 'FLAT PRICE',
          })
          for (var u = 0; u < usage.length; u++) {
            usage[u].Status = 'NEW'
          }
        }
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === scope.WorkOrder) {
            data.Data.Scopes.splice(
              _.findIndex(data.Data.Scopes, { Scope: scope.Scope }),
              1,
              scope,
            )
            if (data.Data.Billing && data.Data.Billing.length) {
              for (var b = 0; b < data.Data.Billing.length; b++) {
                const Billing = data.Data.Billing[b]
                if (Billing && Billing.Scopes) {
                  const index = _.findIndex(Billing.Scopes, {
                    Scope: scope.Scope,
                  })
                  if (index > -1) {
                    Billing.Scopes[index] = _.cloneDeepWith(scope)
                    // window["warn"](scope, Billing.Scopes[index]);
                  }
                }
              }
            }
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data: { ...data, autoInv: scope.autoInv },
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === scope.WorkOrder) {
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data: { ...data, ...scope },
            })
          }
        }
      }
      break
    case Actions.GET_WORK_ORDER_DATA:
      {
        const resp = action.payload
        const { date } = state.dispatchBoardApp.dispatchBoard
        const nav = _.find(navigation, o => {
          return o.id === 'dispatch-board'
        })
        if (nav) {
          nav.badge = {
            title: _.filter(resp, o => {
              return (
                _.filter(o.Data.Scopes, s => {
                  return (
                    s.Status !== 4 &&
                    new Date(s.DueBy).toLocaleDateString('en-US') ===
                      new Date(date).toLocaleDateString('en-US') &&
                    s.Data.Assignments.length < 1
                  )
                }).length > 0
              )
            }).length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    case Actions.SET_WORK_ORDER_DATA:
      {
        const resp = action.payload
        const { date } = state.dispatchBoardApp.dispatchBoard
        const nav = _.find(navigation, o => {
          return o.id === 'dispatch-board'
        })
        if (nav) {
          nav.badge = {
            title: _.filter(resp, o => {
              return (
                _.filter(o.Data.Scopes, s => {
                  return (
                    s.Status !== 4 &&
                    new Date(s.DueBy).toLocaleDateString('en-US') ===
                      new Date(date).toLocaleDateString('en-US') &&
                    s.Data.Assignments.length < 1
                  )
                }).length > 0
              )
            }).length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    case Actions.GET_VOICEMAIL_DATA:
      {
        const state = store.getState()
        const resp = action.payload
        const { voiceMailboxes, userProfiles } = state.spReducers
        const user = userProfiles.User.UserName
        const mailboxes = _.filter(
          voiceMailboxes,
          o => _.findIndex(o.Data.Handlers, { UserName: user }) > -1,
        )
        const messages = _.filter(
          resp,
          o => _.findIndex(mailboxes, { VoiceMailbox: o.VoiceMailbox }) > -1,
        )
        const nav = _.find(navigation, o => {
          return o.id === 'voicemail'
        })
        if (nav) {
          nav.badge = {
            title: messages.length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    case Actions.SET_VOICEMAIL_DATA:
      {
        const state = store.getState()
        const resp = action.payload
        const { voiceMailboxes, userProfiles } = state.spReducers
        const user = userProfiles.User.UserName
        const mailboxes = _.filter(
          voiceMailboxes,
          o => _.findIndex(o.Data.Handlers, { UserName: user }) > -1,
        )
        const messages = _.filter(
          resp,
          o => _.findIndex(mailboxes, { VoiceMailbox: o.VoiceMailbox }) > -1,
        )
        const nav = _.find(navigation, o => {
          return o.id === 'voicemail'
        })
        if (nav) {
          nav.badge = {
            title: messages.length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    case Actions.GET_VOICE_MAILBOX_DATA:
      {
        const state = store.getState()
        const resp = action.payload
        const { voicemail, userProfiles } = state.spReducers
        const user = userProfiles.User.UserName
        const mailboxes = _.filter(
          resp,
          o => _.findIndex(o.Data.Handlers, { UserName: user }) > -1,
        )
        const messages = _.filter(
          voicemail,
          o => _.findIndex(mailboxes, { VoiceMailbox: o.VoiceMailbox }) > -1,
        )
        const nav = _.find(navigation, o => {
          return o.id === 'voicemail'
        })
        if (nav) {
          nav.badge = {
            title: messages.length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    case Actions.SET_VOICE_MAILBOX_DATA:
      {
        const state = store.getState()
        const resp = action.payload
        const { voicemail, userProfiles } = state.spReducers
        const user = userProfiles.User.UserName
        const mailboxes = _.filter(
          resp,
          o => _.findIndex(o.Data.Handlers, { UserName: user }) > -1,
        )
        const messages = _.filter(
          voicemail,
          o => _.findIndex(mailboxes, { VoiceMailbox: o.VoiceMailbox }) > -1,
        )
        const nav = _.find(navigation, o => {
          return o.id === 'voicemail'
        })
        if (nav) {
          nav.badge = {
            title: messages.length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    // case Actions.GET_VOICEMAIL_DATA: {
    //     const resp = action.payload;
    //     const nav = _.find(navigation, (o) => { return o.id === 'voicemail' });
    //     if (nav) {
    //         nav.badge = {
    //             title: _.filter(resp, (o) => o.Status === 0 && !o.HandledBy).length,
    //         };
    //         store.dispatch({
    //             type: Actions.SET_NAVIGATION,
    //             navigation: [...navigation]
    //         });
    //     }
    // }
    //     break;
    case Actions.SET_TECHNICIAN_DATE:
      {
        const { date } = action
        const { allTrips } = store.getState().spReducers.technician
        const resp = _.filter(allTrips, o => {
          return (
            new Date(o.ScheduledDate).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US')
          )
        })
        const nav = _.find(navigation, o => {
          return o.id === 'technician'
        })
        if (nav) {
          nav.badge = {
            title: _.filter(resp, t => {
              return t.Status < 7
            }).length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    case Actions.SET_TECHNICIAN_SCHEDULE:
      {
        const state = store.getState()
        const { date } = state.spReducers.technician
        const resp = _.filter(action.raw, o => {
          return (
            new Date(o.ScheduledDate).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US')
          )
        })
        const nav = _.find(navigation, o => {
          return o.id === 'technician'
        })
        if (nav) {
          nav.badge = {
            title: _.filter(resp, t => {
              return t.Status < 7
            }).length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
        const { spReducers } = state
        const { technician, offline } = spReducers
        const { start } = technician
        let { schedules } = offline
        const { payload, raw, persist } = action
        store.dispatch(Actions.setTripReminders(raw))
        if (persist) {
          const sched = { start, payload, raw }
          if (schedules) {
            if (schedules.length > 4) {
              schedules.splice(0, 1)
            }
            const index = _.findIndex(schedules, { start })
            if (index > -1) {
              schedules.splice(index, 1, sched)
            } else {
              schedules.push(sched)
            }
          } else {
            schedules = []
            schedules.push(sched)
          }
          store.dispatch(Actions.setOfflineSchedules(schedules))
        }
      }
      break
    case Actions.GET_TECHNICIAN_SCHEDULE:
      {
        const state = store.getState()
        const { date } = state.spReducers.technician
        const resp = _.filter(action.raw, o => {
          return (
            new Date(o.ScheduledDate).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US')
          )
        })
        const nav = _.find(navigation, o => {
          return o.id === 'technician'
        })
        if (nav) {
          nav.badge = {
            title: _.filter(resp, t => {
              return t.Status < 7
            }).length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
        const { spReducers } = state
        const { offline } = spReducers
        let { schedules } = offline
        const { payload, raw, start } = action
        const sched = { start, payload, raw }
        store.dispatch(Actions.setTripReminders(raw))
        if (schedules) {
          if (schedules.length > 4) {
            schedules.splice(0, 1)
          }
          const index = _.findIndex(schedules, { start })
          if (index > -1) {
            schedules.splice(index, 1, sched)
          } else {
            schedules.push(sched)
          }
        } else {
          schedules = []
          schedules.push(sched)
        }
        store.dispatch(Actions.setOfflineSchedules(schedules))
      }
      break
    case Actions.SET_CALL_HANDLER_DATA:
      {
        const resp = action.payload
        const nav = _.find(navigation, o => {
          return o.id === 'call-handler'
        })
        if (nav) {
          nav.badge = {
            title: resp[0].Calls.length,
          }
          store.dispatch({
            type: Actions.SET_NAVIGATION,
            navigation: [...navigation],
          })
        }
      }
      break
    case WorkOrderScopeActions.REMOVE_WORK_ORDER_SCOPE:
      {
        const scope = action.data
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === scope.WorkOrder) {
            data.Data.Scopes.splice(
              _.findIndex(data.Data.Scopes, { Scope: scope.Scope }),
              1,
            )
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
      }
      break
    // Intercept Trip Assignment data and forward to necessary dialogs
    case TripActions.ADD_TRIP_ASSIGNMENT:
      {
        const assignment = action.data
        const { workOrdersApp, workOrderScopesApp, techniciansApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === assignment.WorkOrder) {
            const trip = _.find(data.Data.Trips, { Trip: assignment.Trip })
            if (trip) {
              trip.Data.Assignments.push(assignment)
              store.dispatch({
                type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
                data,
              })
            }
          }
        }
        const { technicianDialog } = techniciansApp.technicians
        if (technicianDialog.props.open && technicianDialog.data) {
          const { data } = technicianDialog
          const { Trips } = data.Data
          if (Trips) {
            const trip = _.find(Trips, {
              WorkOrder: assignment.WorkOrder,
              Trip: assignment.Trip,
            })
            if (trip) {
              trip.Data.Assignments.push(assignment)
              store.dispatch({
                type: TechnicianActions.UPDATE_EDIT_TECHNICIAN_DIALOG,
                data,
              })
            }
          }
        }
      }
      break
    // Intercept Trip Assignment data and forward to necessary dialogs
    case TripActions.REMOVE_TRIP_ASSIGNMENT:
      {
        const assignment = action.data
        const { workOrdersApp, workOrderScopesApp, techniciansApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === assignment.WorkOrder) {
            const trip = _.find(data.Data.Trips, { Trip: assignment.Trip })
            if (trip) {
              const index = _.findIndex(trip.Data.Assignments, {
                Scope: assignment.Scope,
              })
              if (index > -1) {
                trip.Data.Assignments.splice(index, 1)
              }
            }
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { technicianDialog } = techniciansApp.technicians
        if (technicianDialog.props.open && technicianDialog.data) {
          const { data } = technicianDialog
          const { Trips } = data.Data
          if (Trips) {
            const trip = _.find(Trips, {
              WorkOrder: assignment.WorkOrder,
              Trip: assignment.Trip,
            })
            if (trip) {
              const index = _.findIndex(trip.Data.Assignments, {
                Scope: assignment.Scope,
              })
              if (index > -1) {
                trip.Data.Assignments.splice(index, 1)
                store.dispatch({
                  type: TechnicianActions.UPDATE_EDIT_TECHNICIAN_DIALOG,
                  data,
                })
              }
            }
          }
        }
      }
      break
    // Intercept Invoice data and forward to necessary dialogs
    case InvoiceActions.ADD_INVOICE:
      {
        const inv = action.data
        if (inv.Invoice) {
          // window["warn"]("New Invoice Added: ", inv);
          const { workOrdersApp, workOrderScopesApp } = state
          // UPDATE Work order dialog
          const { workOrderDialog } = workOrdersApp.workOrders
          if (workOrderDialog.props.open && workOrderDialog.data) {
            const { data } = workOrderDialog
            if (data.WorkOrder === inv.WorkOrder) {
              data.Data.Invoices.push(inv)
              var Billing =
                data.Data.Billing[
                  _.findIndex(
                    data.Data.Billing,
                    c => c.Customer.Customer === inv.Customer,
                  )
                ]
              Billing.Invoices.push(inv)
              for (var s = 0; s < data.Data.Scopes.length; s++) {
                var scope = data.Data.Scopes[s]
                if (scope.ReadyToBillYN === 'Y' && scope.PriceMethod === 'F') {
                  scope.Invoice = inv.Invoice
                  scope.InvoiceDate = inv.InvoiceDate
                  scope.InvoiceNumber = inv.InvoiceNumber
                }
              }
              for (var s = 0; s < Billing.Scopes.length; s++) {
                var scope = Billing.Scopes[s]
                if (scope.ReadyToBillYN === 'Y') {
                  var details = inv.Data.Data.InvoiceDetails
                  for (var u = 0; u < details.length; u++) {
                    if (details[u].Scope === scope.Scope) {
                      scope.Invoice = inv.Invoice
                      scope.InvoiceDate = inv.InvoiceDate
                      scope.InvoiceNumber = inv.InvoiceNumber
                    }
                  }
                  var usage = _.filter(scope.Data.Usage, { Status: 'NEW' })
                  for (var u = 0; u < usage.length; u++) {
                    usage[u].Status = 'INVOICE PENDING'
                    usage[u].Invoice = inv.Invoice
                    usage[u].InvoiceNumber = inv.InvoiceNumber
                    usage[u].InvoiceDate = inv.InvoiceDate
                  }
                  usage = _.filter(data.Data.Usage, {
                    Status: 'NEW',
                    Scope: scope.Scope,
                  })
                  for (var u = 0; u < usage.length; u++) {
                    usage[u].Status = 'INVOICE PENDING'
                    usage[u].Invoice = inv.Invoice
                    usage[u].InvoiceNumber = inv.InvoiceNumber
                    usage[u].InvoiceDate = inv.InvoiceDate
                  }
                }
              }
              store.dispatch({
                type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
                data,
              })
            }
          }
          // UPDATE Work order scope dialog
          const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
          if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
            const { data } = workOrderScopeDialog
            if (data.WorkOrder === inv.WorkOrder) {
              data.Data.Invoices.push(inv)
              var Billing =
                data.Data.Billing[
                  _.findIndex(
                    data.Data.Billing,
                    c => c.Customer.Customer === inv.Customer,
                  )
                ]
              Billing.Invoices.push(inv)
              var scope = data
              var details = inv.Data.Data.InvoiceDetails
              for (var u = 0; u < details.length; u++) {
                if (details[u].Scope === scope.Scope) {
                  scope.Invoice = inv.Invoice
                  scope.InvoiceDate = inv.InvoiceDate
                  scope.InvoiceNumber = inv.InvoiceNumber
                }
              }
              var usage = _.filter(scope.Data.Usage, { Status: 'NEW' })
              for (var u = 0; u < usage.length; u++) {
                usage[u].Status = 'INVOICE PENDING'
                usage[u].Invoice = inv.Invoice
                usage[u].InvoiceNumber = inv.InvoiceNumber
                usage[u].InvoiceDate = inv.InvoiceDate
              }
              usage = _.filter(data.Data.Usage, {
                Status: 'NEW',
                Scope: scope.Scope,
              })
              for (var u = 0; u < usage.length; u++) {
                usage[u].Status = 'INVOICE PENDING'
                usage[u].Invoice = inv.Invoice
                usage[u].InvoiceNumber = inv.InvoiceNumber
                usage[u].InvoiceDate = inv.InvoiceDate
              }
              store.dispatch({
                type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
                data,
              })
            }
          }
        }
      }
      break
    case InvoiceActions.UPDATE_INVOICE:
      {
        const inv = action.data
        // window["warn"]('Invoice Updated: ', inv);
        const { workOrdersApp, workOrderScopesApp } = state
        // UPDATE Work order dialog
        const { workOrderDialog } = workOrdersApp.workOrders

        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === inv.WorkOrder) {
            const { Data } = data

            if (Data && Data.Invoices) {
              const invoicesIndex = _.findIndex(Data.Invoices, {
                Invoice: inv.Invoice,
              })

              if (invoicesIndex !== -1) {
                Data.Invoices.splice(invoicesIndex, 1, inv)
              }
            }

            if (Data && Data.Billing) {
              const billingIndex = _.findIndex(
                Data.Billing,
                c => c.Customer.Customer === inv.Customer,
              )

              if (billingIndex !== -1) {
                Data.Billing[billingIndex].Invoices.splice(
                  _.findIndex(Data.Billing[billingIndex].Invoices, {
                    Invoice: inv.Invoice,
                  }),
                  1,
                  inv,
                )
              }
            }

            // data.Data.Invoices.splice(
            //   _.findIndex(data.Data.Invoices, { Invoice: inv.Invoice }),
            //   1,
            //   inv,
            // )
            // var Billing =
            //   data.Data.Billing[
            //     _.findIndex(
            //       data.Data.Billing,
            //       c => c.Customer.Customer === inv.Customer,
            //     )
            //   ]
            // Billing.Invoices.splice(
            //   _.findIndex(Billing.Invoices, { Invoice: inv.Invoice }),
            //   1,
            //   inv,
            // )

            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        // UPDATE Work order scope dialog
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          window['warn']('SCOPE OPENEDDDD')
          const { data } = workOrderScopeDialog

          if (data.WorkOrder === inv.WorkOrder) {
            const { Data } = data

            if (Data && Data.Invoices) {
              const invoicesIndex = _.findIndex(Data.Invoices, {
                Invoice: inv.Invoice,
              })

              if (invoicesIndex !== -1) {
                Data.Invoices.splice(invoicesIndex, 1, inv)
              }
            }

            if (Data && Data.Billing) {
              const billingIndex = _.findIndex(
                Data.Billing,
                c => c.Customer.Customer === inv.Customer,
              )

              if (billingIndex !== -1) {
                Data.Billing[billingIndex].Invoices.splice(
                  _.findIndex(Data.Billing[billingIndex].Invoices, {
                    Invoice: inv.Invoice,
                  }),
                  1,
                  inv,
                )
              }
            }

            // data.Data.Invoices.splice(
            //   _.findIndex(data.Data.Invoices, { Invoice: inv.Invoice }),
            //   1,
            //   inv,
            // )
            // var Billing =
            //   data.Data.Billing[
            //     _.findIndex(
            //       data.Data.Billing,
            //       c => c.Customer.Customer === inv.Customer,
            //     )
            //   ]
            // Billing.Invoices.splice(
            //   _.findIndex(Billing.Invoices, { Invoice: inv.Invoice }),
            //   1,
            //   inv,
            // )
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    case InvoiceActions.REMOVE_INVOICE:
      {
        const inv = action.data
        // window["warn"]('Invoice Removed: ', inv);
        const { workOrdersApp, workOrderScopesApp } = state
        // UPDATE Work order dialog
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === inv.WorkOrder) {
            data.Data.Invoices.splice(
              _.findIndex(data.Data.Invoices, { Invoice: inv.Invoice }),
              1,
            )
            var Billing =
              data.Data.Billing[
                _.findIndex(
                  data.Data.Billing,
                  c => c.Customer.Customer === inv.Customer,
                )
              ]
            Billing.Invoices.splice(
              _.findIndex(Billing.Invoices, { Invoice: inv.Invoice }),
              1,
            )
            for (var s = 0; s < data.Data.Scopes.length; s++) {
              var scope = data.Data.Scopes[s]
              if (
                scope.Invoice === inv.Invoice &&
                scope.InvoiceNumber === inv.InvoiceNumber
              ) {
                scope.Invoice = null
                scope.InvoiceDate = null
                scope.InvoiceNumber = null
              }
            }
            for (var s = 0; s < Billing.Scopes.length; s++) {
              var scope = Billing.Scopes[s]
              var details = inv.Data.Data.InvoiceDetails
              for (var u = 0; u < details.length; u++) {
                if (details[u].Scope === scope.Scope) {
                  scope.Invoice = null
                  scope.InvoiceDate = null
                  scope.InvoiceNumber = null
                }
              }
              var usage = _.filter(scope.Data.Usage, { Invoice: inv.Invoice })
              for (var u = 0; u < usage.length; u++) {
                usage[u].Status =
                  scope.PriceMethod === 'F' && usage[u].AddOnYN !== 'Y'
                    ? 'FLAT_PRICE'
                    : 'NEW'
                usage[u].Invoice = null
                usage[u].InvoiceNumber = null
                usage[u].InvoiceDate = null
              }
              usage = _.filter(data.Data.Usage, {
                Invoice: inv.Invoice,
                Scope: scope.Scope,
              })
              for (var u = 0; u < usage.length; u++) {
                usage[u].Status =
                  scope.PriceMethod === 'F' && usage[u].AddOnYN !== 'Y'
                    ? 'FLAT_PRICE'
                    : 'NEW'
                usage[u].Invoice = null
                usage[u].InvoiceNumber = null
                usage[u].InvoiceDate = null
              }
            }
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        // UPDATE Work order scope dialog
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === inv.WorkOrder) {
            data.Data.Invoices.splice(
              _.findIndex(data.Data.Invoices, { Invoice: inv.Invoice }),
              1,
            )
            var Billing =
              data.Data.Billing[
                _.findIndex(
                  data.Data.Billing,
                  c => c.Customer.Customer === inv.Customer,
                )
              ]
            Billing.Invoices.splice(
              _.findIndex(Billing.Invoices, { Invoice: inv.Invoice }),
              1,
            )
            var scope = data
            var details = inv.Data.Data.InvoiceDetails
            for (var u = 0; u < details.length; u++) {
              if (details[u].Scope === scope.Scope) {
                scope.Invoice = null
                scope.InvoiceDate = null
                scope.InvoiceNumber = null
              }
            }
            var usage = _.filter(scope.Data.Usage, { Invoice: inv.Invoice })
            for (var u = 0; u < usage.length; u++) {
              usage[u].Status = 'NEW'
              usage[u].Invoice = null
              usage[u].InvoiceNumber = null
              usage[u].InvoiceDate = null
            }
            usage = _.filter(data.Data.Usage, {
              Invoice: inv.Invoice,
              Scope: scope.Scope,
            })
            for (var u = 0; u < usage.length; u++) {
              usage[u].Status = 'NEW'
              usage[u].Invoice = null
              usage[u].InvoiceNumber = null
              usage[u].InvoiceDate = null
            }
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Usage info and pass along to affected dialogs
    case WorkOrderUsageActions.ADD_WORK_ORDER_USAGE:
      {
        const line = action.data
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === line.WorkOrder) {
            const { Scopes } = data.Data
            const scp = _.find(Scopes, { Scope: line.Scope })
            if (scp && scp.PriceMethod === 'F' && line.AddOnYN !== 'Y') {
              line.Status = 'FLAT PRICE'
            }
            data.Data.Usage.push(line)
            for (var b = 0; b < data.Data.Billing.length; b++) {
              const Billing = data.Data.Billing[b]
              const scope = _.find(Billing.Scopes, { Scope: line.Scope })
              if (scope) {
                scope.Data.Usage.push(line)
              }
            }
            const scope = _.find(data.Data.Scopes, { Scope: line.Scope })
            if (scope) {
              scope.Data.Usage.push(line)
            }
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === line.WorkOrder && data.Scope === line.Scope) {
            data.Data.Usage.push(line)
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    case WorkOrderUsageActions.UPDATE_WORK_ORDER_USAGE:
      {
        const line = action.data
        const { workOrdersApp, workOrderScopesApp, invoicesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === line.WorkOrder) {
            data.Data.Usage.splice(
              _.findIndex(data.Data.Usage, { Line: line.Line }),
              1,
              line,
            )
            for (var b = 0; b < data.Data.Billing.length; b++) {
              const Billing = data.Data.Billing[b]
              const scope = _.find(Billing.Scopes, { Scope: line.Scope })
              if (scope) {
                var index = _.findIndex(scope.Data.Usage, { Line: line.Line })
                if (index > -1) {
                  scope.Data.Usage.splice(index, 1, line)
                }
              }
            }
            const scope = _.find(data.Data.Scopes, { Scope: line.Scope })
            if (scope) {
              var index = _.findIndex(scope.Data.Usage, { Line: line.Line })
              if (index > -1) {
                scope.Data.Usage.splice(index, 1, line)
              }
            }
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === line.WorkOrder && data.Scope === line.Scope) {
            data.Data.Usage.splice(
              _.findIndex(data.Data.Usage, { Line: line.Line }),
              1,
              line,
            )
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
        const { invoiceDialog } = invoicesApp.invoices
        if (invoiceDialog.props.open && invoiceDialog.data) {
          const { data } = invoiceDialog
          if (data.WorkOrder === line.WorkOrder) {
            var index = _.findIndex(data.Data.Data.InvoiceDetails, {
              UsageLine: line.Line,
            })
            if (index > -1) {
              const detail = data.Data.Data.InvoiceDetails[index]
              data.Data.Data.InvoiceDetails.splice(index, 1, {
                ...detail,
                Data: {
                  Usage: line,
                  InvoiceLines: [
                    {
                      Co: detail.Co,
                      Invoice: detail.Invoice,
                      InvoiceDetail: detail.InvoiceDetail,
                      InvoiceDetailLine: 1,
                      Amount: line.PriceTotal,
                      TaxAmount: line.TaxTotal,
                    },
                  ],
                },
              })
              const lines = _.flatten(
                _.map(
                  data.Data.Data.InvoiceDetails,
                  _.property('Data.InvoiceLines'),
                ),
              )
              data.BilledTotal = _.sumBy(lines, _.property('Amount'))
              data.TaxTotal = _.sumBy(lines, _.property('TaxAmount'))
              store.dispatch({
                type: InvoiceActions.UPDATE_EDIT_INVOICE_DIALOG,
                data,
              })
            }
          }
        }
      }
      break
    case WorkOrderUsageActions.REMOVE_WORK_ORDER_USAGE:
      {
        const line = action.data
        const { workOrdersApp, workOrderScopesApp, invoicesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === line.WorkOrder) {
            data.Data.Usage.splice(
              _.findIndex(data.Data.Usage, { Line: line.Line }),
              1,
            )
            for (var b = 0; b < data.Data.Billing.length; b++) {
              const Billing = data.Data.Billing[b]
              const scope = _.find(Billing.Scopes, { Scope: line.Scope })
              if (scope) {
                var index = _.findIndex(scope.Data.Usage, { Line: line.Line })
                if (index > -1) {
                  scope.Data.Usage.splice(index, 1)
                }
              }
            }
            const scope = _.find(data.Data.Scopes, { Scope: line.Scope })
            if (scope) {
              var index = _.findIndex(scope.Data.Usage, { Line: line.Line })
              if (index > -1) {
                scope.Data.Usage.splice(index, 1)
              }
            }
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === line.WorkOrder && data.Scope === line.Scope) {
            if (data.Data && data.Data.Usage) {
              data.Data.Usage.splice(
                _.findIndex(data.Data.Usage, { Line: line.Line }),
                1,
              )
              store.dispatch({
                type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
                data,
              })
            }
          }
        }
        const { invoiceDialog } = invoicesApp.invoices
        if (invoiceDialog.props.open && invoiceDialog.data) {
          const { data } = invoiceDialog
          if (data.WorkOrder === line.WorkOrder) {
            var index = _.findIndex(data.Data.Data.InvoiceDetails, {
              UsageLine: line.Line,
            })
            if (index > -1) {
              data.Data.Data.InvoiceDetails.splice(index, 1)
              const lines = _.flatten(
                _.map(
                  data.Data.Data.InvoiceDetails,
                  _.property('Data.InvoiceLines'),
                ),
              )
              data.BilledTotal = _.sumBy(lines, _.property('Amount'))
              data.TaxTotal = _.sumBy(lines, _.property('TaxAmount'))
              store.dispatch({
                type: InvoiceActions.UPDATE_EDIT_INVOICE_DIALOG,
                data,
              })
            }
          }
        }
      }
      break
    //Intercept Trip info and pass along to affected dialogs
    case TripActions.ADD_TRIP:
      {
        const trip = action.data
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === trip.WorkOrder) {
            data.Data.Trips.push(trip)
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === trip.WorkOrder) {
            data.Data.Trips.push(trip)
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    case TripActions.UPDATE_TRIP:
      {
        const trip = action.data
        const state = store.getState()
        const { workOrdersApp, workOrderScopesApp, spReducers } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === trip.WorkOrder) {
            data.Data.Trips.splice(
              _.findIndex(data.Data.Trips, { Trip: trip.Trip }),
              1,
              trip,
            )
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === trip.WorkOrder) {
            data.Data.Trips.splice(
              _.findIndex(data.Data.Trips, { Trip: trip.Trip }),
              1,
              trip,
            )
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
        const { date, technician, trips, allTrips } = spReducers.technician
        if (technician && trip.Technician === technician.Technician) {
          const start = moment(date).locale('us').weekday(1).format('M/D/YYYY')
          const { Co, WorkOrder, Trip } = trip
          const raw = updateInList(
            allTrips,
            trip,
            'UPDATE',
            { Co, WorkOrder, Trip },
            true,
          )
          // window["warn"](trip, raw);
          const schedules = []
          for (var i = 0; i < 7; i++) {
            let ndt = new Date(start)
            ndt.setDate(ndt.getDate() + i)
            ndt = moment(ndt).format('M/D/YYYY')
            const trips = _.filter(raw, o => {
              return (
                moment(
                  o.InProgressDate ||
                    o.ArrivedDate ||
                    o.EnRouteDate ||
                    o.ScheduledDate,
                ).format('M/D/YYYY') === ndt
              )
            })
            schedules.push({ Date: ndt, trips })
          }
          store.dispatch({
            type: Actions.SET_TECHNICIAN_SCHEDULE,
            payload: schedules,
            raw,
            persist: true,
          })
        }
      }
      break
    case TripActions.REMOVE_TRIP:
      {
        const trip = action.data
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderDialog.props.open && workOrderDialog.data) {
          const { data } = workOrderDialog
          if (data.WorkOrder === trip.WorkOrder) {
            data.Data.Trips.splice(
              _.findIndex(data.Data.Trips, { Trip: trip.Trip }),
              1,
            )
            store.dispatch({
              type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
              data,
            })
          }
        }
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          const { data } = workOrderScopeDialog
          if (data.WorkOrder === trip.WorkOrder) {
            data.Data.Trips.splice(
              _.findIndex(data.Data.Trips, { Trip: trip.Trip }),
              1,
            )
            store.dispatch({
              type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    case CallGatherKeyActions.ADD_CALL_GATHER_KEY:
      {
        const gather = action.data
        const { callGathersApp } = state
        const { callGatherDialog } = callGathersApp.callGathers
        if (callGatherDialog.props.open && callGatherDialog.data) {
          const { data } = callGatherDialog
          if (data.ID === gather.Gather) {
            data.Data.Keys.push(gather)
            store.dispatch({
              type: CallGatherActions.UPDATE_EDIT_CALL_GATHER_DIALOG,
              data,
            })
          }
        }
      }
      break
    case CallGatherKeyActions.UPDATE_CALL_GATHER_KEY:
      {
        const gather = action.data
        const { callGathersApp } = state
        const { callGatherDialog } = callGathersApp.callGathers
        if (callGatherDialog.props.open && callGatherDialog.data) {
          const { data } = callGatherDialog
          if (data.ID === gather.Gather) {
            data.Data.Keys.splice(
              _.findIndex(data.Data.Keys, {
                Gather: gather.Gather,
                Key: gather.Key,
              }),
              1,
              gather,
            )
            store.dispatch({
              type: CallGatherActions.UPDATE_EDIT_CALL_GATHER_DIALOG,
              data,
            })
          }
        }
      }
      break
    case CallGatherKeyActions.REMOVE_CALL_GATHER_KEY:
      {
        const gather = action.data
        const { callGathersApp } = state
        const { callGatherDialog } = callGathersApp.callGathers
        if (callGatherDialog.props.open && callGatherDialog.data) {
          const { data } = callGatherDialog
          if (data.ID === gather.Gather) {
            data.Data.Keys.splice(
              _.findIndex(data.Data.Keys, {
                Gather: gather.Gather,
                Key: gather.Key,
              }),
              1,
            )
            store.dispatch({
              type: CallGatherActions.UPDATE_EDIT_CALL_GATHER_DIALOG,
              data,
            })
          }
        }
      }
      break
    case DialGroupMemberActions.ADD_DIAL_GROUP_MEMBER:
      {
        const member = action.data
        const { dialGroupsApp } = state
        const { dialGroupDialog } = dialGroupsApp.dialGroups
        if (dialGroupDialog.props.open && dialGroupDialog.data) {
          const { data } = dialGroupDialog
          if (data.ID === member.DialID) {
            data.Data.Members.push(member)
            store.dispatch({
              type: DialGroupActions.UPDATE_EDIT_DIAL_GROUP_DIALOG,
              data,
            })
          }
        }
      }
      break
    case DialGroupMemberActions.UPDATE_DIAL_GROUP_MEMBER:
      {
        const member = action.data
        const { dialGroupsApp } = state
        const { dialGroupDialog } = dialGroupsApp.dialGroups
        if (dialGroupDialog.props.open && dialGroupDialog.data) {
          const { data } = dialGroupDialog
          if (data.ID === member.DialID) {
            data.Data.Members.splice(
              _.findIndex(data.Data.Members, {
                GroupID: member.GroupID,
                ID: member.ID,
              }),
              1,
              member,
            )
            store.dispatch({
              type: DialGroupActions.UPDATE_EDIT_DIAL_GROUP_DIALOG,
              data,
            })
          }
        }
      }
      break
    case DialGroupMemberActions.REMOVE_DIAL_GROUP_MEMBER:
      {
        const member = action.data
        const { dialGroupsApp } = state
        const { dialGroupDialog } = dialGroupsApp.dialGroups
        if (dialGroupDialog.props.open && dialGroupDialog.data) {
          const { data } = dialGroupDialog
          if (data.ID === member.DialID) {
            data.Data.Members.splice(
              _.findIndex(data.Data.Members, {
                GroupID: member.GroupID,
                ID: member.ID,
              }),
              1,
            )
            store.dispatch({
              type: DialGroupActions.UPDATE_EDIT_DIAL_GROUP_DIALOG,
              data,
            })
          }
        }
      }
      break
    case DialGroupActions.ADD_DIAL_GROUP:
      {
        const group = action.data
        const { huntGroupsApp } = state
        const { huntGroupDialog } = huntGroupsApp.huntGroups
        if (huntGroupDialog.props.open && huntGroupDialog.data) {
          const { data } = huntGroupDialog
          if (data.ID === group.GroupID) {
            data.Data.Members.push(group)
            store.dispatch({
              type: HuntGroupActions.UPDATE_EDIT_HUNT_GROUP_DIALOG,
              data,
            })
          }
        }
      }
      break
    case DialGroupActions.UPDATE_DIAL_GROUP:
      {
        const group = action.data
        const { huntGroupsApp } = state
        const { huntGroupDialog } = huntGroupsApp.huntGroups
        if (huntGroupDialog.props.open && huntGroupDialog.data) {
          const { data } = huntGroupDialog
          if (data.ID === group.GroupID) {
            data.Data.Members.splice(
              _.findIndex(data.Data.Members, {
                GroupID: group.GroupID,
                ID: group.ID,
              }),
              1,
              group,
            )
            store.dispatch({
              type: HuntGroupActions.UPDATE_EDIT_HUNT_GROUP_DIALOG,
              data,
            })
          }
        }
      }
      break
    case DialGroupActions.REMOVE_DIAL_GROUP:
      {
        const group = action.data
        const { huntGroupsApp } = state
        const { huntGroupDialog } = huntGroupsApp.huntGroups
        if (huntGroupDialog.props.open && huntGroupDialog.data) {
          const { data } = huntGroupDialog
          if (data.ID === group.GroupID) {
            data.Data.Members.splice(
              _.findIndex(data.Data.Members, {
                GroupID: group.GroupID,
                ID: group.ID,
              }),
              1,
            )
            store.dispatch({
              type: HuntGroupActions.UPDATE_EDIT_HUNT_GROUP_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Agreement Type Override info and pass along to affected dialogs
    case AgreementTypeTaskActions.ADD_AGREEMENT_TYPE_TASK:
      {
        const rec = action.data
        const { agreementTypesApp } = state
        const { agreementTypeDialog } = agreementTypesApp.agreementTypes
        if (agreementTypeDialog.props.open && agreementTypeDialog.data) {
          var { data } = agreementTypeDialog
          if (data.AgreementType == rec.AgreementType) {
            data.Data.Tasks.push(rec)
          }
          store.dispatch({
            type: AgreementTypeActions.UPDATE_EDIT_AGREEMENT_TYPE_DIALOG,
            data,
          })
        }
      }
      break
    //Intercept Agreement Type Override info and pass along to affected dialogs
    case AgreementTypeTaskActions.UPDATE_AGREEMENT_TYPE_TASK:
      {
        const rec = action.data
        const { agreementTypesApp } = state
        const { agreementTypeDialog } = agreementTypesApp.agreementTypes
        if (agreementTypeDialog.props.open && agreementTypeDialog.data) {
          var { data } = agreementTypeDialog
          if (data.AgreementType == rec.AgreementType) {
            data.Data.Tasks.splice(
              _.findIndex(data.Data.Tasks, { TaskType: rec.TaskType }),
              1,
              rec,
            )
            store.dispatch({
              type: AgreementTypeActions.UPDATE_EDIT_AGREEMENT_TYPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Agreement Type Override info and pass along to affected dialogs
    case AgreementTypeTaskActions.REMOVE_AGREEMENT_TYPE_TASK:
      {
        const rec = action.data
        const { agreementTypesApp } = state
        const { agreementTypeDialog } = agreementTypesApp.agreementTypes
        if (agreementTypeDialog.props.open && agreementTypeDialog.data) {
          var { data } = agreementTypeDialog
          if (data.AgreementType == rec.AgreementType) {
            data.Data.Tasks.splice(
              _.findIndex(data.Data.Tasks, { TaskType: rec.TaskType }),
              1,
            )
            store.dispatch({
              type: AgreementTypeActions.UPDATE_EDIT_AGREEMENT_TYPE_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Agreement Type Task Override info and pass along to affected dialogs
    case AgreementTypeTaskActions.ADD_AGREEMENT_TYPE_TASK_OVERRIDE:
      {
        const rec = action.data
        const { agreementTypeTasksApp } = state
        const { agreementTypeTaskDialog } =
          agreementTypeTasksApp.agreementTypeTasks
        if (
          agreementTypeTaskDialog.props.open &&
          agreementTypeTaskDialog.data
        ) {
          var { data } = agreementTypeTaskDialog
          if (
            data.AgreementType == rec.AgreementType &&
            data.TaskType == rec.TaskType
          ) {
            data.Data.Overrides.push(rec)
          }
          store.dispatch({
            type: AgreementTypeTaskActions.UPDATE_EDIT_AGREEMENT_TYPE_TASK_DIALOG,
            data,
          })
        }
      }
      break
    //Intercept Agreement Type Task Override info and pass along to affected dialogs
    case AgreementTypeTaskActions.UPDATE_AGREEMENT_TYPE_TASK_OVERRIDE:
      {
        const rec = action.data
        const { agreementTypeTasksApp } = state
        const { agreementTypeTaskDialog } =
          agreementTypeTasksApp.agreementTypeTasks
        if (
          agreementTypeTaskDialog.props.open &&
          agreementTypeTaskDialog.data
        ) {
          var { data } = agreementTypeTaskDialog
          if (
            data.AgreementType == rec.AgreementType &&
            data.TaskType == rec.TaskType
          ) {
            data.Data.Overrides.splice(
              _.findIndex(data.Data.Overrides, { UnitType: rec.UnitType }),
              1,
              rec,
            )
            store.dispatch({
              type: AgreementTypeTaskActions.UPDATE_EDIT_AGREEMENT_TYPE_TASK_DIALOG,
              data,
            })
          }
        }
      }
      break
    //Intercept Agreement Type Task Override info and pass along to affected dialogs
    case AgreementTypeTaskActions.REMOVE_AGREEMENT_TYPE_TASK_OVERRIDE:
      {
        const rec = action.data
        const { agreementTypeTasksApp } = state
        const { agreementTypeTaskDialog } =
          agreementTypeTasksApp.agreementTypeTasks
        if (
          agreementTypeTaskDialog.props.open &&
          agreementTypeTaskDialog.data
        ) {
          var { data } = agreementTypeTaskDialog
          if (
            data.AgreementType == rec.AgreementType &&
            data.TaskType == rec.TaskType
          ) {
            data.Data.Overrides.splice(
              _.findIndex(data.Data.Overrides, { UnitType: rec.UnitType }),
              1,
            )
            store.dispatch({
              type: AgreementTypeTaskActions.UPDATE_EDIT_AGREEMENT_TYPE_TASK_DIALOG,
              data,
            })
          }
        }
      }
      break
    case AgreementActions.ADD_AGREEMENT:
      {
        const line = action.data
        const {
          customersApp,
          workOrdersApp,
          workOrderScopesApp,
          customerSitesApp,
        } = state
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        const { workOrderDialog } = workOrdersApp.workOrders
        const { customerSiteDialog } = customerSitesApp.customerSites
        const { customerDialog } = customersApp.customers
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (line.Data && line.Data.Usage) {
            if (
              data.WorkOrder === line.WorkOrder &&
              (data.Scope === line.Scope ||
                _.findIndex(line.Data.Sites, { Site: data.Data.Site.Site }) >
                  -1)
            ) {
              data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
            }
          }
          if (
            data.Data.Site.Customer == line.Customer &&
            data.Data.Site.Site == line.Site
          ) {
            data.Data.Site.Data.Agreements.push(line)
          }
          store.dispatch({
            type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (line.Data && line.Data.Usage) {
            if (data.WorkOrder === line.WorkOrder) {
              data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
              for (var b = 0; b < data.Data.Billing.length; b++) {
                const Billing = data.Data.Billing[b]
                const scope = _.find(Billing.Scopes, { Scope: line.Scope })
                if (scope) {
                  scope.Data.Usage = [...scope.Data.Usage, ...line.Data.Usage]
                }
              }
            }
          }
          if (
            data.Customer == line.Customer &&
            (data.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Site }) > -1)
          ) {
            data.Data.Site.Data.Agreements.push(line)
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerSiteDialog.props.open && customerSiteDialog.data) {
          var { data } = customerSiteDialog
          if (
            data.Customer == line.Customer &&
            (data.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Site }) > -1)
          ) {
            data.Data.Agreements.push(line)
          }
          store.dispatch({
            type: CustomerSiteActions.UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerDialog.props.open && customerDialog.data) {
          var { data } = customerDialog
          if (data.Customer == line.Customer) {
            data.Data.Agreements.push(line)
          }
          store.dispatch({
            type: CustomerActions.UPDATE_EDIT_CUSTOMER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case AgreementActions.UPDATE_AGREEMENT:
      {
        const line = action.data
        const {
          customersApp,
          workOrdersApp,
          workOrderScopesApp,
          customerSitesApp,
        } = state
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        const { workOrderDialog } = workOrdersApp.workOrders
        const { customerSiteDialog } = customerSitesApp.customerSites
        const { customerDialog } = customersApp.customers
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (
            data.Data.Site.Customer == line.Customer &&
            (data.Data.Site.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Data.Site.Site }) > -1)
          ) {
            const { Agreement, Cycle, Revision } = line
            var index = _.findIndex(data.Data.Site.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Site.Data.Agreements.splice(index, 1, line)
            }
          }
          store.dispatch({
            type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (
            data.Customer == line.Customer &&
            (data.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Site }) > -1)
          ) {
            const { Agreement, Cycle, Revision } = line
            var index = _.findIndex(data.Data.Site.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Site.Data.Agreements.splice(index, 1, line)
            }
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerSiteDialog.props.open && customerSiteDialog.data) {
          var { data } = customerSiteDialog
          if (
            data.Customer == line.Customer &&
            (data.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Site }) > -1)
          ) {
            const { Agreement, Cycle, Revision } = line
            var index = _.findIndex(data.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Agreements.splice(index, 1, line)
            }
          }
          store.dispatch({
            type: CustomerSiteActions.UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerDialog.props.open && customerDialog.data) {
          var { data } = customerDialog
          if (data.Customer == line.Customer) {
            const { Agreement, Cycle, Revision } = line
            var index = _.findIndex(data.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Agreements.splice(index, 1, line)
            }
          }
          store.dispatch({
            type: CustomerActions.UPDATE_EDIT_CUSTOMER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case AgreementActions.REMOVE_AGREEMENT:
      {
        const line = action.data
        const {
          customersApp,
          workOrdersApp,
          workOrderScopesApp,
          customerSitesApp,
        } = state
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        const { workOrderDialog } = workOrdersApp.workOrders
        const { customerSiteDialog } = customerSitesApp.customerSites
        const { customerDialog } = customersApp.customers
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (
            data.Data.Site.Customer == line.Customer &&
            (data.Data.Site.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Data.Site.Site }) > -1)
          ) {
            const { Agreement, Cycle, Revision } = line
            if (line.Data && line.Data.Usage) {
              if (
                data.WorkOrder === line.WorkOrder &&
                data.Scope === line.Scope
              ) {
                data.Data.Usage = _.reject(data.Data.Usage, o => {
                  return (
                    o.Agreement === Agreement &&
                    o.AgreementCycle === Cycle &&
                    o.AgreementRevision === Revision &&
                    ['NEW', 'FLAT PRICE'].indexOf(o.Status) > -1
                  )
                })
              }
            }
            var index = _.findIndex(data.Data.Site.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Site.Data.Agreements.splice(index, 1)
            }
          }
          store.dispatch({
            type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (
            data.Customer == line.Customer &&
            (data.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Site }) > -1)
          ) {
            const { Agreement, Cycle, Revision } = line
            if (line.Data && line.Data.Usage) {
              if (data.WorkOrder === line.WorkOrder) {
                data.Data.Usage = _.reject(data.Data.Usage, o => {
                  return (
                    o.Agreement === Agreement &&
                    o.AgreementCycle === Cycle &&
                    o.AgreementRevision === Revision &&
                    ['NEW', 'FLAT PRICE'].indexOf(o.Status) > -1
                  )
                })
              }
              for (var b = 0; b < data.Data.Billing.length; b++) {
                const Billing = data.Data.Billing[b]
                const scope = _.find(Billing.Scopes, { Scope: line.Scope })
                if (scope) {
                  scope.Data.Usage = _.reject(scope.Data.Usage, o => {
                    return (
                      o.Agreement === Agreement &&
                      o.AgreementCycle === Cycle &&
                      o.AgreementRevision === Revision &&
                      ['NEW', 'FLAT PRICE'].indexOf(o.Status) > -1
                    )
                  })
                }
              }
            }
            var index = _.findIndex(data.Data.Site.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Site.Data.Agreements.splice(index, 1)
            }
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerSiteDialog.props.open && customerSiteDialog.data) {
          var { data } = customerSiteDialog
          if (
            data.Customer == line.Customer &&
            (data.Site == line.Site ||
              _.findIndex(line.Data.Sites, { Site: data.Site }) > -1)
          ) {
            const { Agreement, Cycle, Revision } = line
            var index = _.findIndex(data.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Agreements.splice(index, 1)
            }
          }
          store.dispatch({
            type: CustomerSiteActions.UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerDialog.props.open && customerDialog.data) {
          var { data } = customerDialog
          if (data.Customer == line.Customer) {
            const { Agreement, Cycle, Revision } = line
            var index = _.findIndex(data.Data.Agreements, {
              Agreement,
              Cycle,
              Revision,
            })
            if (index > -1) {
              data.Data.Agreements.splice(index, 1)
            }
          }
          store.dispatch({
            type: CustomerActions.UPDATE_EDIT_CUSTOMER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case AgreementActions.ASSIGN_AGREEMENT_TASK:
      {
        const line = action.data
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (line.Data && line.Data.Usage) {
            data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
            if (
              data.WorkOrder === line.WorkOrder &&
              data.Scope === line.Scope
            ) {
              data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
            }
          }
          store.dispatch({
            type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (line.Data && line.Data.Usage) {
            if (data.WorkOrder === line.WorkOrder) {
              data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
              for (var b = 0; b < data.Data.Billing.length; b++) {
                const Billing = data.Data.Billing[b]
                const scope = _.find(Billing.Scopes, { Scope: line.Scope })
                if (scope) {
                  scope.Data.Usage = [...scope.Data.Usage, ...line.Data.Usage]
                }
              }
              const scope = _.find(data.Data.Scopes, { Scope: line.Scope })
              if (scope) {
                if (!scope.Data.AgreementTasks) {
                  scope.Data.AgreementTasks = []
                }
                scope.Data.AgreementTasks.push(line)
                scope.Data.Usage = [...scope.Data.Usage, ...line.Data.Usage]
              }
            }
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case AgreementActions.ASSIGN_AGREEMENT_TASKS:
      {
        const lines = action.data
        let scopeData, woData
        lines.forEach(line => {
          const { workOrdersApp, workOrderScopesApp } = state
          const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
          const { workOrderDialog } = workOrdersApp.workOrders
          if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
            var data = scopeData || workOrderScopeDialog.data
            if (line.Data && line.Data.Usage) {
              data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
              if (
                data.WorkOrder === line.WorkOrder &&
                data.Scope === line.Scope
              ) {
                data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
              }
            }
            scopeData = data
          }
          if (workOrderDialog.props.open && workOrderDialog.data) {
            var data = woData || workOrderDialog.data
            if (line.Data && line.Data.Usage) {
              if (data.WorkOrder === line.WorkOrder) {
                data.Data.Usage = [...data.Data.Usage, ...line.Data.Usage]
                for (var b = 0; b < data.Data.Billing.length; b++) {
                  const Billing = data.Data.Billing[b]
                  const scope = _.find(Billing.Scopes, { Scope: line.Scope })
                  if (scope) {
                    scope.Data.Usage = [...scope.Data.Usage, ...line.Data.Usage]
                  }
                }
                const scope = _.find(data.Data.Scopes, { Scope: line.Scope })
                if (scope) {
                  if (!scope.Data.AgreementTasks) {
                    scope.Data.AgreementTasks = []
                  }
                  scope.Data.AgreementTasks.push(line)
                  scope.Data.Usage = [...scope.Data.Usage, ...line.Data.Usage]
                }
              }
            }
            woData = data
          }
        })
        if (scopeData) {
          store.dispatch({
            type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
            data: scopeData,
          })
          // window["warn"](scopeData);
        }
        if (woData) {
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data: woData,
          })
          // window["warn"](woData);
        }
      }
      break
    case AgreementActions.UNASSIGN_AGREEMENT_TASK:
      {
        const line = action.data
        const { workOrdersApp, workOrderScopesApp } = state
        const { workOrderScopeDialog } = workOrderScopesApp.workOrderScopes
        const { workOrderDialog } = workOrdersApp.workOrders
        if (workOrderScopeDialog.props.open && workOrderScopeDialog.data) {
          var { data } = workOrderScopeDialog
          if (data.WorkOrder === line.WorkOrder && data.Scope === line.Scope) {
            var lines = _.filter(data.Data.Usage, {
              AgreementTask: line.TaskType,
            })
            for (var t = 0; t < lines.length; t++) {
              var index = _.find(data.Data.Usage, { Line: lines[t].Line })
              if (index > -1) {
                if (
                  ['INVOICED', 'INVOICE PENDING'].indexOf(
                    data.Data.Usage[index].Status,
                  ) < 0
                ) {
                  data.Data.Usage.splice(index, 1)
                }
              }
            }
          }
          store.dispatch({
            type: WorkOrderScopeActions.UPDATE_EDIT_WORK_ORDER_SCOPE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (data.WorkOrder === line.WorkOrder) {
            var lines = _.filter(data.Data.Usage, {
              AgreementTask: line.TaskType,
            })
            for (var t = 0; t < lines.length; t++) {
              var index = _.findIndex(data.Data.Usage, { Line: lines[t].Line })
              if (index > -1) {
                if (
                  ['INVOICED', 'INVOICE PENDING'].indexOf(
                    data.Data.Usage[index].Status,
                  ) < 0
                ) {
                  data.Data.Usage.splice(index, 1)
                }
              }
            }
            for (var b = 0; b < data.Data.Billing.length; b++) {
              const Billing = data.Data.Billing[b]
              const scope = _.findIndex(Billing.Scopes, { Scope: line.Scope })
              if (scope && scope.Data) {
                var lines = _.filter(scope.Data.Usage, {
                  AgreementTask: line.TaskType,
                })
                for (var t = 0; t < lines.length; t++) {
                  var index = _.findIndex(scope.Data.Usage, {
                    Line: lines[t].Line,
                  })
                  if (index > -1) {
                    if (
                      ['INVOICED', 'INVOICE PENDING'].indexOf(
                        scope.Data.Usage[index].Status,
                      ) < 0
                    ) {
                      scope.Data.Usage.splice(index, 1)
                    }
                  }
                }
              }
            }
            const scope = _.find(data.Data.Scopes, { Scope: line.Scope })
            if (scope) {
              var taskIndex = _.findIndex(scope.Data.AgreementTasks, {
                TaskType: line.TaskType,
              })
              if (taskIndex > -1) {
                scope.Data.AgreementTasks.splice(taskIndex, 1)
              }
              var lines = _.filter(scope.Data.Usage, {
                AgreementTask: line.TaskType,
              })
              for (var t = 0; t < lines.length; t++) {
                var index = _.find(scope.Data.Usage, { Line: lines[t].Line })
                if (index > -1) {
                  if (
                    ['INVOICED', 'INVOICE PENDING'].indexOf(
                      scope.Data.Usage[index].Status,
                    ) < 0
                  ) {
                    scope.Data.Usage.splice(index, 1)
                  }
                }
              }
            }
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case ServiceUnitActions.ADD_SERVICE_UNIT:
      {
        const line = action.data
        const {
          customersApp,
          agreementsApp,
          customerSitesApp,
          workOrdersApp,
          callHandlerApp,
        } = state
        const { agreementDialog } = agreementsApp.agreements
        const { customerSiteDialog } = customerSitesApp.customerSites
        const { customerDialog } = customersApp.customers
        const { workOrderDialog } = workOrdersApp.workOrders
        const callHandlerData = callHandlerApp.callHandler.data
        if (
          callHandlerData &&
          callHandlerData.Site &&
          callHandlerData.Site.Customer === line.Customer &&
          callHandlerData.Site.Site === line.Site
        ) {
          if (callHandlerData.Site.Data && callHandlerData.Site.Data.Units) {
            callHandlerData.Site.Data.Units.push(line)
            store.dispatch(setSiteData(callHandlerData.Site))
          }
        }
        if (agreementDialog.props.open && agreementDialog.data) {
          var { data } = agreementDialog
          if (data.Customer == line.Customer) {
            let { Customer, Sites } = data.Data
            if (Customer) {
              const { Site } = Customer.Data
              if (Site && _.isArray(Site)) {
                const site = _.find(Site, { Site: line.Site })
                if (site && site.Data && site.Data.Units) {
                  site.Data.Units.push(line)
                }
              }
            }
            // if (Sites) {
            //     const site = _.find(Sites, { Site: line.Site });
            //     if (site) {
            //         site.Data.Units.push(line);
            //     }
            // }
            if (data.Site == line.Site) {
              data.Data.Site.Data.Units.push(line)
            }
          }
          store.dispatch({
            type: AgreementActions.UPDATE_EDIT_AGREEMENT_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerSiteDialog.props.open && customerSiteDialog.data) {
          var { data } = customerSiteDialog
          if (data.Customer == line.Customer && data.Site == line.Site) {
            data.Data.Units.push(line)
          }
          store.dispatch({
            type: CustomerSiteActions.UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (
            data.Customer == line.Customer &&
            data.Site == line.Site &&
            data.Data &&
            data.Data.Site &&
            data.Data.Site.Data &&
            data.Data.Site.Data.Units
          ) {
            data.Data.Site.Data.Units.push(line)
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case ServiceUnitActions.UPDATE_SERVICE_UNIT:
      {
        const line = action.data
        const {
          customersApp,
          agreementsApp,
          customerSitesApp,
          workOrdersApp,
          callHandlerApp,
        } = state
        const { agreementDialog } = agreementsApp.agreements
        const { customerSiteDialog } = customerSitesApp.customerSites
        const { customerDialog } = customersApp.customers
        const { workOrderDialog } = workOrdersApp.workOrders
        const callHandlerData = callHandlerApp.callHandler.data
        if (
          callHandlerData &&
          callHandlerData.Site &&
          callHandlerData.Site.Customer === line.Customer &&
          callHandlerData.Site.Site === line.Site
        ) {
          if (callHandlerData.Site.Data && callHandlerData.Site.Data.Units) {
            const index = _.findIndex(callHandlerData.Site.Data.Units, {
              UnitID: line.UnitID,
            })
            if (index > -1) {
              callHandlerData.Site.Data.Units.splice(index, 1, line)
              store.dispatch(setSiteData(callHandlerData.Site))
            }
          }
        }
        if (agreementDialog.props.open && agreementDialog.data) {
          var { data } = agreementDialog
          if (data.Customer == line.Customer) {
            let { Customer, Sites } = data.Data
            if (Customer) {
              const site = _.find(Customer.Data.Sites, { Site: line.Site })
              if (site && site.Data && site.Data.Units) {
                const index = _.findIndex(site.Data.Units, {
                  UnitID: line.UnitID,
                })
                if (index > -1) {
                  site.Data.Units.splice(index, 1, line)
                }
              }
            }
            // if (Sites) {
            //     const site = _.find(Sites, { Site: line.Site });
            //     if (site && site.Data) {
            //         const index = _.findIndex(site.Data.Units, { UnitID: line.UnitID });
            //         if (index > -1) {
            //             site.Data.Units.splice(index, 1, line)
            //         }
            //     }
            // }
            if (data.Site == line.Site) {
              const index = _.findIndex(data.Data.Site.Data.Units, {
                UnitID: line.UnitID,
              })
              if (index > -1) {
                data.Data.Site.Data.Units.splice(index, 1, line)
              }
            }
          }
          store.dispatch({
            type: AgreementActions.UPDATE_EDIT_AGREEMENT_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (customerSiteDialog.props.open && customerSiteDialog.data) {
          var { data } = customerSiteDialog
          if (data.Customer == line.Customer && data.Site == line.Site) {
            const index = _.findIndex(data.Data.Units, { UnitID: line.UnitID })
            if (index > -1) {
              data.Data.Units.splice(index, 1, line)
            }
          }
          store.dispatch({
            type: CustomerSiteActions.UPDATE_EDIT_CUSTOMER_SITE_DIALOG,
            data,
          })
          // window["warn"](data);
        }
        if (workOrderDialog.props.open && workOrderDialog.data) {
          var { data } = workOrderDialog
          if (
            data.Customer == line.Customer &&
            data.Site == line.Site &&
            data.Data &&
            data.Data.Site &&
            data.Data.Site.Data &&
            data.Data.Site.Data.Units
          ) {
            const index = _.findIndex(data.Data.Site.Data.Units, {
              UnitID: line.UnitID,
            })
            if (index > -1) {
              data.Data.Site.Data.Units.splice(index, 1, line)
            }
          }
          store.dispatch({
            type: WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case ServiceUnitTypeActions.ADD_SERVICE_UNIT_TYPE:
      {
        const line = action.data
        const { serviceUnitTypesApp, serviceUnitCategoriesApp } = state
        const { serviceUnitCategoryDialog } =
          serviceUnitCategoriesApp.serviceUnitCategories
        if (
          serviceUnitCategoryDialog.props.open &&
          serviceUnitCategoryDialog.data
        ) {
          var { data } = serviceUnitCategoryDialog
          if (data.UnitCat == line.UnitCat) {
            data.Data.Types.push(line)
          }
          store.dispatch({
            type: ServiceUnitCategoryActions.UPDATE_EDIT_SERVICE_UNIT_CATEGORY_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case ServiceUnitTypeActions.UPDATE_SERVICE_UNIT_TYPE:
      {
        const line = action.data
        const { serviceUnitTypesApp, serviceUnitCategoriesApp } = state
        const { serviceUnitCategoryDialog } =
          serviceUnitCategoriesApp.serviceUnitCategories
        if (
          serviceUnitCategoryDialog.props.open &&
          serviceUnitCategoryDialog.data
        ) {
          var { data } = serviceUnitCategoryDialog
          if (data.UnitCat == line.UnitCat) {
            const index = _.findIndex(data.Data.Types, {
              UnitType: line.UnitType,
            })
            if (index > -1) {
              data.Data.Types.splice(index, 1, line)
            }
          }
          store.dispatch({
            type: ServiceUnitCategoryActions.UPDATE_EDIT_SERVICE_UNIT_CATEGORY_DIALOG,
            data,
          })
          // window["warn"](data);
        }
      }
      break
    case TextGatherPhraseActions.ADD_TEXT_GATHER_PHRASE:
      {
        const phrase = action.data
        const { textGathersApp } = state
        const { textGatherDialog } = textGathersApp.textGathers
        if (textGatherDialog.props.open && textGatherDialog.data) {
          const { data } = textGatherDialog
          if (data.ID === phrase.Gather) {
            data.Data.Phrases.push(phrase)
            store.dispatch({
              type: TextGatherActions.UPDATE_EDIT_TEXT_GATHER_DIALOG,
              data,
            })
          }
        }
      }
      break
    case TextGatherPhraseActions.UPDATE_TEXT_GATHER_PHRASE:
      {
        const phrase = action.data
        const { textGathersApp } = state
        const { textGatherDialog } = textGathersApp.textGathers
        if (textGatherDialog.props.open && textGatherDialog.data) {
          const { data } = textGatherDialog
          if (data.ID === phrase.Gather) {
            data.Data.Phrases.splice(
              _.findIndex(data.Data.Phrases, { ID: phrase.ID }),
              1,
              phrase,
            )
            store.dispatch({
              type: TextGatherActions.UPDATE_EDIT_TEXT_GATHER_DIALOG,
              data,
            })
          }
        }
      }
      break
    case TextGatherPhraseActions.REMOVE_TEXT_GATHER_PHRASE:
      {
        const phrase = action.data
        const { textGathersApp } = state
        const { textGatherDialog } = textGathersApp.textGathers
        if (textGatherDialog.props.open && textGatherDialog.data) {
          const { data } = textGatherDialog
          if (data.ID === phrase.Gather) {
            data.Data.Phrases.splice(
              _.findIndex(data.Data.Phrases, { ID: phrase.ID }),
              1,
            )
            store.dispatch({
              type: TextGatherActions.UPDATE_EDIT_TEXT_GATHER_DIALOG,
              data,
            })
          }
        }
      }
      break
    case WorkOrderActions.OPEN_EDIT_WORK_ORDER_DIALOG:
      {
        const response = action
        const { WorkOrder } = response.data
        const state = store.getState()
        let wos = state.spReducers.offline.workOrders
        if (wos) {
          if (wos.length > 4) {
            wos.splice(0, 1)
          }
          const index = _.findIndex(wos, { WorkOrder })
          if (index > -1) {
            wos.splice(index, 1, response.data)
          } else {
            wos.push(response.data)
          }
        } else {
          wos = []
          wos.push(response.data)
        }
        store.dispatch(Actions.setOfflineWorkOrders(wos))
      }
      break
    case WorkOrderActions.UPDATE_EDIT_WORK_ORDER_DIALOG:
      {
        const response = action
        const { WorkOrder } = response.data
        const state = store.getState()
        let wos = state.spReducers.offline.workOrders
        if (wos) {
          if (wos.length > 4) {
            wos.splice(0, 1)
          }
          const index = _.findIndex(wos, { WorkOrder })
          if (index > -1) {
            wos.splice(index, 1, response.data)
          } else {
            wos.push(response.data)
          }
        } else {
          wos = []
          wos.push(response.data)
        }
        store.dispatch(Actions.setOfflineWorkOrders(wos))
      }
      break
  }
  next(action)
}

export default SPMonitor
