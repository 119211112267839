import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FuseUtils, FuseAnimate } from '@fuse'
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Icon,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from '@material-ui/core'
import { bindActionCreators } from 'redux'
import * as Actions from './store/actions'
import ReactTable from 'react-table'
import classNames from 'classnames'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import RGL, { WidthProvider } from 'react-grid-layout'
import _ from 'lodash'
import FormElementDialog from './FormElementDialog'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
// import html2canvas from 'html2canvas';
import Media from 'react-media'
import { impress } from 'main/content/compression/impress'
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment'

const GridLayout = WidthProvider(RGL)

const styles = theme => ({
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    backgroundColor: theme.palette.primary[500],
    width: 24,
    height: 24,
  },
  labels: {},
  formSection: {
    border: '1px dotted lightgrey',
    // padding: 8,
  },
  scrollEnable: {
    touchAction: 'unset !important',
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
})

class FormSectionBuilder extends Component {
  state = {
    grabbing: false,
    value: null,
    notes: null,
  }

  componentDidMount() {
    const { el } = this.props
    const { Value, Notes } = el
    this.setState({ ...this.state, value: Value, notes: Notes })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { el } = this.props
    const { Value, Notes } = el
    if (
      !_.isEqual(Value, prevProps.el.Value) ||
      !_.isEqual(Notes, prevProps.el.Notes)
    ) {
      this.setState({ ...this.state, value: Value, notes: Notes })
    }
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText)
  }

  openSelectedFormSectionMenu = event => {
    this.setState({ selectedFormElementsMenu: event.currentTarget })
  }

  closeSelectedFormElementsMenu = () => {
    this.setState({ selectedFormElementsMenu: null })
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return formatted
  }

  setGrabbing = grabbing => {
    this.setState({ ...this.state, grabbing })
  }

  handleChange = event => {
    const { el, onChange } = this.props
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
      () => {
        if (onChange) {
          onChange(el, this.state.value, this.state.notes)
        }
      },
    )
  }

  handleLayout = layout => {
    const { form, onNeedsUpdate } = this.props
    const data = [...form.Data.Elements]
    const needsUpdate = []
    layout.map((item, i) => {
      let el = data[i]
      if (
        item.x !== el.PositionX ||
        item.y !== el.PositionY ||
        item.w !== el.Columns ||
        item.h !== el.Rows
      ) {
        needsUpdate.push({
          ...el,
          PositionX: item.x,
          PositionY: item.y,
          Columns: item.w,
          Rows: item.h,
        })
      }
    })
    this.setState({ ...this.state, needsUpdate }, () =>
      onNeedsUpdate(needsUpdate),
    )
  }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    const { el, onChange } = this.props
    this.setState(
      {
        ...this.state,
        value: this.sigPad.getCanvas().toDataURL('image/png'),
        isSigning: false,
      },
      () => {
        if (onChange) {
          onChange(el, this.state.value)
        }
      },
    )
  }

  fileSelectedHandler = event => {
    const { el } = this.props
    impress(event.target.files[0], 1024, 50, false, img => {
      window['log'](img)
      el.Src = img.Base64
      this.props.updateFormElement({ ...el, Src: img.base64 })
      // this.setState({ ...this.state, Src: img.base64 });
    })
  }

  render() {
    const { classes, scrollLock, el, editable, onChange } = this.props
    const { grabbing } = this.state
    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <div
            onMouseDown={() => this.setGrabbing(true)}
            onMouseUp={() => this.setGrabbing(false)}
            className={`w-full h-full form-section relative ${
              grabbing ? 'cursor-grabbing' : 'cursor-grab'
            }`}
          >
            {scrollLock && editable && (
              <Icon
                onClick={() => this.props.openEditFormElementDialog(el)}
                color='action'
                className='absolute pin-t pin-r text-20 cursor-pointer edit-icon z-10'
              >
                edit
              </Icon>
            )}
            {!scrollLock && editable && (
              <div className='pin-l pin-r pin-t pin-b m-auto absolute text-center h-24 w-auto text-grey-light'>
                {el.Type === 'DIV' && el.Title}
              </div>
            )}
            {el.Type === 'TTL' && (
              <Typography
                fullWidth
                style={{ alignItems: 'center', marginLeft: 0 }}
                className='flex text-18'
              >
                <strong>{el.Title}</strong>
              </Typography>
            )}
            {el.Type === 'SUB' && (
              <Typography
                fullWidth
                style={{ alignItems: 'center', marginLeft: 4 }}
                className='flex text-16'
              >
                <strong>{el.Title}</strong>
              </Typography>
            )}
            {el.Type === 'IMG' && el.Src && (
              <React.Fragment>
                <img
                  onClick={() => {
                    if (scrollLock && editable) {
                      this.fileInput.click()
                    }
                  }}
                  className='w-full h-auto cursor-pointer'
                  src={el.Src}
                />
                <input
                  type='file'
                  style={{ display: 'none' }}
                  onChange={this.fileSelectedHandler}
                  ref={fileInput => (this.fileInput = fileInput)}
                />
              </React.Fragment>
            )}
            {el.Type === 'IMG' && !el.Src && (
              <React.Fragment>
                <div
                  onClick={() => {
                    if (scrollLock && editable) {
                      this.fileInput.click()
                    }
                  }}
                  className='w-full h-full relative cursor-pointer'
                >
                  <Icon
                    className='pin-l pin-r pin-t pin-b absolute m-auto'
                    color='primary'
                  >
                    cloud_upload
                  </Icon>
                </div>
                <input
                  type='file'
                  style={{ display: 'none' }}
                  onChange={this.fileSelectedHandler}
                  ref={fileInput => (this.fileInput = fileInput)}
                />
              </React.Fragment>
            )}
            {el.Type === 'IN' && (
              <TextField
                className={classes.formControl}
                label={el.Title}
                id='value'
                name='value'
                value={this.state.value || ''}
                onChange={this.handleChange}
                variant='outlined'
                margin='dense'
                required={el.RequiredYN === 'Y'}
                disabled={!scrollLock}
                fullWidth
              />
            )}
            {el.Type === 'NTS' && (
              <TextField
                className={classes.formControl}
                label={el.Title}
                id='value'
                name='value'
                value={this.state.value || ''}
                onChange={this.handleChange}
                variant='outlined'
                required={el.RequiredYN === 'Y'}
                disabled={!scrollLock}
                multiline
                rows={4}
                margin='dense'
                fullWidth
              />
            )}
            {el.Type === 'DIV' && <div className='h-full w-full'></div>}
            {el.Type === 'CB' && (
              <div className='w-full' style={{ marginTop: -6 }}>
                <FormControlLabel
                  control={
                    <React.Fragment>
                      <img
                        src={`assets/images/icons/${
                          this.state.value === 'Y' ? 'Checkbox' : 'Unchecked'
                        }.png`}
                        className='hidden form-print w-16 h-16 mr-6 ml-16'
                      />
                      <Checkbox
                        className='form-hidden'
                        style={{ padding: 4, marginLeft: 12 }}
                        checked={this.state.value === 'Y'}
                        onChange={this.handleChange}
                        name='value'
                        value={this.state.value}
                        color='primary'
                        margin='dense'
                      />
                    </React.Fragment>
                  }
                  margin='dense'
                  label={<b>{el.Title}</b>}
                />
                {el.AddNotesYN === 'Y' && (
                  <div
                    className={`w-full pl-32 form-notes`}
                    style={{ marginTop: -14, lineHeight: '14px' }}
                  >
                    <InputBase
                      className={classes.formControl}
                      id='notes'
                      name='notes'
                      value={this.state.notes || ''}
                      onChange={this.handleChange}
                      inputProps={{
                        style: {
                          padding: 0,
                          paddingTop: 2,
                          fontStyle: 'italic',
                          fontSize: 10,
                          borderBottom: '1px dotted lightgrey',
                        },
                      }}
                      disabled={!scrollLock}
                      fullWidth
                    />
                  </div>
                )}
              </div>
            )}
            {el.Type === 'SIG' && (
              <React.Fragment>
                <div className='w-full'>
                  <strong style={{ marginRight: 4 }}>{el.Title}:</strong>
                </div>
                <div
                  className='w-full relative'
                  onClick={() => {
                    this.setState({ ...this.state, isSigning: true })
                  }}
                  style={{
                    border: '1px dotted #3f3f3f',
                    width: '100%',
                    height: 'calc(100% - 64px)',
                    borderRadius: 5,
                  }}
                >
                  {this.state.value && (
                    <img
                      className='pin-t pin-b pin-l pin-r m-auto absolute'
                      style={{ width: 'auto', height: '100%' }}
                      src={this.state.value}
                    />
                  )}
                </div>
                <div
                  className='w-full mb-6'
                  style={{ lineHeight: '12px', textAlign: 'center' }}
                >
                  <strong
                    style={{ marginRight: 4, fontSize: 10, color: 'red' }}
                  >
                    {el.Description}
                  </strong>
                </div>
                <Dialog
                  open={this.state.isSigning}
                  classes={{ paper: classes.signaturePaper }}
                  fullWidth
                  maxWidth='sm'
                  onClose={() => {
                    if (this.sigPad.isEmpty()) {
                      this.setState({ ...this.state, isSigning: false })
                    }
                  }}
                >
                  <div className='w-full mb-12'>
                    <strong style={{ marginRight: 4 }}>{el.Title}:</strong>
                  </div>
                  <div
                    className='w-full'
                    style={{ border: '1px dotted #3f3f3f', borderRadius: 5 }}
                  >
                    <SignatureCanvas
                      ref={ref => {
                        this.sigPad = ref
                      }}
                      penColor='#66aef9'
                      canvasProps={{
                        className: 'sigCanvas',
                        style: { width: '100%', height: 150 },
                      }}
                    />
                  </div>
                  <div className='flex w-full mt-16'>
                    <Button
                      variant='contained'
                      className={classes.clearBtn}
                      fullWidth
                      onClick={this.clear}
                    >
                      Clear
                    </Button>
                    <div className='min-w-12'></div>
                    <Button
                      variant='contained'
                      className={classes.okBtn}
                      fullWidth
                      onClick={this.trim}
                    >
                      OK
                    </Button>
                  </div>
                </Dialog>
              </React.Fragment>
            )}
          </div>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserData: Actions.getUserData,
      openNewFormElementDialog: Actions.openNewFormElementDialog,
      openEditFormElementDialog: Actions.openEditFormElementDialog,
      updateFormElement: Actions.updateFormElement,
    },
    dispatch,
  )
}

function mapStateToProps({ formsApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    formData: spReducers.forms,
    searchText: formsApp.forms.searchText,
    user: spReducers.userProfiles.User.UserName,
    employees: spReducers.employees,
    technicians: spReducers.technicians,
    securables: spReducers.userProfiles.User.Data.Securables,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSectionBuilder)),
)
