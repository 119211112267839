import _ from '@lodash';
import { Card, CircularProgress, Drawer, Icon, IconButton, ListItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import clsx from 'clsx';
import FileList from 'main/content/apps/file-manager/FileList';
import InvoicesList from 'main/content/apps/invoices/InvoicesList';
import TripsList from 'main/content/apps/trips/TripsList';
import WorkOrderUsagesList from 'main/content/apps/work-order-usages/WorkOrderUsagesList';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseOrdersList from '../purchase-orders/PurchaseOrdersList';
import ScopeDetails from '../work-orders/WorkOrderScope';
import * as Actions from './store/actions/work-order-history.actions';
import WorkOrderHistory from './WorkOrderHistory';

const styles = theme => ({
    root: {},
    histTable: {
        marginLeft: theme.spacing(7) + 1,
        padding: 8,
    },
    histDrawer: {
        width: 64,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%',
        marginTop: '60px'
    },
    histShelf: {
        position: 'relative'
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        zIndex: 99999,
    },
    drawerClose: {
        width: 0,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    dividerPlain: {
        color: '#fff',
    },
    dividerHighlight: {
        background: theme.palette.primary.main,
        color: '#fff'
    },
    invHeader: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        fontSize: 36,
        fontWeight: 'bold',
        color: '#333'
    },
    invSubHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'right',
        marginTop: 4,
        color: '#333'
    },
})

// Want WorkerOrders to copy OPEN_EDIT_WORK_ORDER_DIALOG structure
let newWorkOrderHistoryState = {
    Co: null,
    loading: false,
    open: false,
    data: null,
    expanded: null,
    scopeExpanded: {}
}


class WorkOrderHistoryApp extends Component {
    state = { ..._.cloneDeepWith(newWorkOrderHistoryState) };

    componentDidMount() {
        const { data } = this.props;
        this.setState({ data, open: Boolean(data) });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data } = this.props;
        if (!_.isEqual(data, prevProps.data)) {
            this.setState({ data, open: Boolean(data) });
        }
    }


    handleDrawerClose = () => {
        this.props.setWorkOrderHistory(null);
        this.setState({ open: false, data: null })
    }

    render() {
        const { classes } = this.props;
        const { data } = this.state;


        return (
            <Drawer
                className={clsx(classNames(classes.drawer, "h-full absolute"), {
                    [classes.drawerOpen]: this.state.open,
                    [classes.drawerClose]: !this.state.open
                })}
                classes={{
                    paper: clsx("border-none h-full", {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open
                    })
                }}
                variant='permanent'
                anchor='right'
                open={this.state.open}
            >
                <WorkOrderHistory back={this.handleDrawerClose} data={data} />
            </Drawer >
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setWorkOrderHistory: Actions.setWorkOrderHistory
        //addAlert
    }, dispatch);
}

function mapStateToProps({ workOrderHistoryApp, spReducers, invoicesApp }) {
    return {
        data: workOrderHistoryApp.workOrderHistory.data,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(WorkOrderHistoryApp));