import { FuseAnimate, FuseUtils } from '@fuse'
import { Icon, Typography, Tooltip, Fab } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import * as Actions from './store/actions'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import _ from 'lodash'
import { Parser } from 'json2csv'
import { showMessage } from 'store/actions'
import { FeatureFlagsContext } from '../../../../providers/featureFlagsProvider'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

function LoadingDiv() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .65)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        className='loader stretchBar'
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
        }}
      >
        <div className='rect1'></div>
        <div className='rect2'></div>
        <div className='rect3'></div>
        <div className='rect4'></div>
        <div className='rect5'></div>
        <br />
        <img
          style={{ width: 80, marginTop: 15 }}
          src='assets/images/splash/SPLoading.png'
        />
      </div>
    </div>
  )
}

function NoData() {
  return (
    <div
      style={{
        padding: 20,
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, .25)',
        transition: 'all .3s ease',
        zIndex: 10,
        opacity: 1,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          margin: 'auto',
          left: 0,
          right: 0,
          top: 0,
          bottom: 30,
          width: '100%',
          position: 'absolute',
          paddingTop: 75,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        No Records Found
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    maxHeight: 'calc(100vh - 288px)',
    paddingBottom: 0,
  },
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  mailList: {
    padding: 0,
  },
  addButton: {
    float: 'right',
    width: 24,
    height: 24,
    minHeight: 0,
    marginRight: 8,
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
    marginTop: 2,
    zIndex: 3,
  },
  mailItem: {},
  avatar: {
    width: 32,
    height: 32,
  },
  labels: {},
  green: {
    backgroundColor: '#d1e751',
    color: '#3f3f3f',
  },
  blue: {
    backgroundColor: '#4dbce9',
    color: '#fff',
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  borderTop: {
    borderTop: '1px solid lightgrey',
  },
  error: {
    backgroundColor: theme.palette.error[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error[600],
    },
    '&:active': {
      backgroundColor: theme.palette.error[700],
    },
    '&:focus': {
      backgroundColor: theme.palette.error[700],
    },
  },
  ok: {
    background: '#333',
    color: '#fff',
    '&:hover': {
      background: '#5f5f5f',
    },
    '&:active': {
      background: '#3f3f3f',
    },
    '&:focus': {
      backgroundColor: '#5f5f5f',
    },
  },
  black: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:active': {
      backgroundColor: '#333',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#333',
      color: '#fff',
    },
  },
  greenText: {
    color: '#d1e751',
  },
  listContainer: {
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: '#f6f6f6',
  },
  type_charge: {
    color: '#fff',
    backgroundColor: '#d1e751',
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
  type_refund: {
    color: '#fff',
    backgroundColor: 'orange',
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
  type_void: {
    color: '#fff',
    backgroundColor: '#6f6f6f',
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
})

class DepositTransactions extends Component {
  static contextType = FeatureFlagsContext

  state = {
    selectedPaymentsMenu: null,
    anchorEl: null,
    featureFlags: this.context,
  }

  getFilteredArray = (entities, searchText) => {
    const arr = Object.keys(entities).map(id => entities[id])
    if (searchText.length === 0) {
      return arr
    }
    return FuseUtils.filterArrayByString(arr, searchText.replace(/\|/g, ' '))
  }

  formatDateText(dt) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${days[dt.getDay()]} ${
      months[dt.getMonth()]
    } ${dt.getDate()} ${dt.getFullYear()}`
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy
    return formatted
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  getTransaction = tran => {
    const { Co } = this.props
    const request = axios.get(
      `${
        window['apiLocation']
      }/api/Omni/Transaction?Co=${Co}&id=${encodeURIComponent(
        tran.transaction_id,
      )}`,
    )
    request.then(response => {
      this.props.setTransaction(response.data)
    })
  }

  getExportData = () => {
    if (this.state.featureFlags['spp-238-new-deposit-deatils'] === true) {
      const { depositTransactions } = this.props
      this.exportData([...depositTransactions])
    } else {
      const { depositData } = this.props
      this.exportData([...depositData.data])
    }
  }

  getCustomerNameFromTransactionRecord = record => {
    if (
      record.SPCustomer &&
      record.SPCustomer.Name &&
      record.SPCustomer.Name.length > 0
    ) {
      return record.SPCustomer.Name
    }

    if (
      record.SPCustomer &&
      record.SPCustomer.FirstName &&
      record.SPCustomer.FirstName.length > 0
    ) {
      return `${record.SPCustomer.FirstName} ${record.SPCustomer.LastName}`
    }

    if (
      record.Customer &&
      record.Customer.FullName &&
      record.Customer.FullName.length > 0
    ) {
      return record.Customer.FullName
    }

    if (record.PaymentMethod) {
      return record.PaymentMethod.FullName
    }

    return ''
  }

  exportData = data => {
    try {
      let newData = []

      if (this.state.featureFlags['spp-238-new-deposit-deatils'] === true) {
        data.map(value => {
          let obj = {}
          obj['Payment Date'] = moment
            .utc(value.CreatedDate)
            .local()
            .format('h:mm A on M/D/YYYY')
          obj['Customer Name'] =
            this.getCustomerNameFromTransactionRecord(value)
          obj['Payment Method'] = value.PaymentMethod.CardType

          if (
            value.SPPayment &&
            value.SPPayment.Data &&
            value.SPPayment.Data.Allocations &&
            value.SPPayment.Data.Allocations.length > 0
          ) {
            obj['Taken By'] = value.SPPayment.Data.Allocations[0].EnteredBy
            obj['Invoice Number'] =
              value.SPPayment.Data.Allocations[0].InvoiceNumber
          }

          obj['Last 4'] = value.PaymentMethod.CardLastFourDigits
          obj['Amount'] =
            value.Type === 'refund'
              ? this.formatDollars(value.Total * -1)
              : this.formatDollars(value.Total)
          obj['Fees'] = this.formatDollars(value.DepositFees)
          obj['Customer'] =
            value.SPCustomer && value.SPCustomer.VPCustomer
              ? value.SPCustomer.VPCustomer
              : value.SPCustomer.Customer

          newData.push(obj)
        })
      } else {
        data.map(value => {
          let obj = {}
          obj['DateTime'] = moment
            .utc(value.batched_at)
            .local()
            .format('h:mm A on M/D/YYYY')
          obj['Payment Method'] =
            `${value.card_type} Ending in ${value.last_four}`
          obj['Amount'] = this.formatDollars(value.total)
          obj['Fees'] = this.formatDollars(value.fees)
          obj['ID'] = value.transaction_id
          obj['Authorization'] = value.auth_id

          newData.push(obj)
        })
      }
      const csvData = new Parser()
      const Data = csvData.parse(newData)
      var encoded = encodeURIComponent(Data)
      var csv = `data:text/csv;charset=utf-8, ${encoded}`
      var link = document.createElement('a')
      link.setAttribute('href', csv)
      link.setAttribute(
        'download',
        `Deposits-${moment().format('M-D-YYYY')}.csv`,
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      this.props.showMessage({
        message: `Error: ${error.message}`,
        autoHideDuration: 30000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'error',
      })
    }
  }

  render() {
    const { classes, loading, searchText, Co, filters, securables } = this.props

    var data
    var tableColumns
    if (this.state.featureFlags['spp-238-new-deposit-deatils'] === true) {
      const co = _.find(this.props.companies, { Co: Co })
      const isVP = co ? co.Data.Co.VPCompany : false

      data = this.props.depositTransactions

      tableColumns = [
        {
          Header: 'Status',
          accessor: 'WasSuccessfull',
          width: 64,
          Cell: row =>
            row.original.IsVoided ? (
              <Icon className='text-20 text-grey'>cancel</Icon>
            ) : row.original.WasSuccessfull ? (
              <Icon color='secondary' className='text-20'>
                check_circle_outline
              </Icon>
            ) : (
              <Icon color='error' className='text-20'>
                error
              </Icon>
            ),
          className: 'justify-center',
          sortable: false,
        },
        {
          Header: 'Customer Name',
          accessor: r => {
            return this.getCustomerNameFromTransactionRecord(r)
          },
          id: 'Customer_name',
          width: 256,
          className: 'justify-center font-bold capitalize',
          sortable: false,
        },
        {
          Header: 'Payment Info',
          accessor: 'PaymentMethod.CardType',
          width: 250,
          Cell: row =>
            row.value ? (
              <div className='flex'>
                <img
                  src={`assets/images/cards/${row.value}.png`}
                  className='mr-4'
                  style={{ height: 20 }}
                />
                {row.original.PaymentMethod.CardLastFourDigits ? (
                  <div className='ml-6 align-middle'>{`Ends in ${row.original.PaymentMethod.CardLastFourDigits} - ${row.original.PaymentMethod.CustomerName}`}</div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <Icon className='text-20'>help</Icon>
            ),
          className: 'justify-start',
          sortable: false,
        },
        {
          Header: 'Type',
          accessor: r =>
            `${!r.WasSuccessfull ? 'Failed ' : r.IsVoided ? 'Voided ' : ''}${
              r.Type
            }`,
          id: 'type',
          width: 128,
          className: 'justify-center capitalize',
          Cell: row => (
            <div
              className={classNames(
                classes[`type_${row.original.Type}`],
                !row.original.WasSuccessfull
                  ? 'bg-red'
                  : row.original.IsVoided
                    ? 'bg-grey'
                    : '',
              )}
            >{`${
              !row.original.WasSuccessfull
                ? 'Failed '
                : row.original.IsVoided
                  ? 'Voided '
                  : ''
            }${row.original.Type}`}</div>
          ),
          sortable: false,
        },
        {
          Header: 'Date',
          accessor: 'CreatedDate',
          width: 192,
          Cell: row =>
            moment.utc(row.value).local().format('h:mm A on M/D/YYYY'),
          className: 'justify-center font-bold text-12',
        },
        {
          Header: 'Amount',
          accessor: 'Total',
          width: 160,
          Cell: row => (
            <div className='flex'>
              <div
                className={`${
                  row.original.IsVoided ||
                  row.original.TotalRefunded > 0 ||
                  !row.original.WasSuccessfull
                    ? 'line-through text-grey'
                    : ''
                }`}
              >
                {this.formatDollars(row.value)}
              </div>
              {row.original.TotalRefunded > 0 && (
                <div className={'ml-4'}>
                  {this.formatDollars(row.value - row.original.TotalRefunded)}
                </div>
              )}
            </div>
          ),
          className: 'justify-center font-bold',
        },
        {
          Header: 'Fees',
          accessor: 'DepositFees',
          width: 128,
          Cell: row => (
            <div className='flex'>{this.formatDollars(row.value)}</div>
          ),
          className: 'justify-center',
        },
        {
          Header: 'Allocations',
          id: 'allocations',
          accessor: r => {
            if (r.SPPayment) {
              return _.sumBy(
                _.filter(r.SPPayment.Data.Allocations, o => !o.VoidDate),
                'Amount',
              )
            } else {
              return 0.0
            }
          },
          width: 160,
          Cell: row => {
            const transaction = row.original
            const allocated =
              Math.round(
                (!transaction ||
                !transaction.WasSuccessfull ||
                transaction.Type !== 'charge'
                  ? 0
                  : !transaction.SPPaymentData
                    ? 0
                    : _.sumBy(
                        _.filter(
                          transaction.SPPaymentData.Data.Allocations,
                          o => !o.VoidDate,
                        ),
                        'Amount',
                      ) + Number.EPSILON) * 100,
              ) / 100
            const total =
              Math.round(
                (!transaction || !transaction.WasSuccessfull
                  ? 0
                  : transaction.IsVoided
                    ? 0
                    : transaction.Total -
                      transaction.TotalRefunded +
                      Number.EPSILON) * 100,
              ) / 100
            return (
              <div className='flex'>
                {transaction.Type === 'charge' && total !== allocated && (
                  <Tooltip
                    placement='top'
                    title={
                      !transaction.SPPaymentData
                        ? 'Unallocated Payment Balance'
                        : total < allocated
                          ? 'Over-Allocated Payment Balance'
                          : 'Under-Allocated Payment Balance'
                    }
                  >
                    <Icon className='text-18 mr-4 text-orange'>warning</Icon>
                  </Tooltip>
                )}
                <div className='flex'>{this.formatDollars(row.value)}</div>
              </div>
            )
          },
          className: 'justify-center font-bold',
        },
        {
          Header: 'VP Customer',
          id: 'sp-customer',
          accessor: r => (r.SPCustomer ? r.SPCustomer.VPCustomer : null),
          width: 96,
          className: 'justify-center font-bold',
          show: isVP,
        },
        {
          Header: 'ID',
          accessor: 'Id',
          width: 224,
          Cell: row => (
            <div className='w-full flex hoverable'>
              <div className='w-full text-center text-12 truncate'>
                {row.value}
              </div>
              <Icon
                onClick={e => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(row.value)
                }}
                color='action'
                className='show-on-hover text-16 align-middle ml-4'
              >
                file_copy
              </Icon>
            </div>
          ),
          className: 'justify-center font-bold',
          sortable: false,
        },
      ]
    } else {
      data = this.getFilteredArray(
        !this.props.selectedDepositData
          ? !this.props.depositData || !this.props.depositData.data
            ? []
            : this.props.depositData.data
          : this.props.selectedDepositData,
        searchText,
      )

      tableColumns = [
        {
          Header: 'DateTime',
          accessor: 'batched_at',
          width: 160,
          Cell: row =>
            moment.utc(row.value).local().format('h:mm:ss A on M/D/YYYY'),
          className: 'justify-center font-bold text-12',
        },
        {
          Header: 'Payment Method',
          accessor: 'card_type',
          width: 160,
          Cell: row =>
            row.value ? (
              <div className='flex'>
                <img
                  src={`assets/images/cards/${row.value}.png`}
                  className='mr-4'
                  style={{ height: 20 }}
                />
                {row.original.last_four ? (
                  <div className='ml-6 align-middle'>{`Ending in ${row.original.last_four}`}</div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <Icon className='text-20'>help</Icon>
            ),
          className: 'justify-center',
          sortable: false,
        },
        {
          Header: 'Amount',
          accessor: 'total',
          width: 128,
          Cell: row => (
            <div className='flex'>{this.formatDollars(row.value)}</div>
          ),
          className: 'justify-center',
        },
        {
          Header: 'Fees',
          accessor: 'fees',
          width: 128,
          Cell: row => (
            <div className='flex'>{this.formatDollars(row.value)}</div>
          ),
          className: 'justify-center',
        },
        {
          Header: 'ID',
          accessor: 'transaction_id',
          width: 224,
          Cell: row => (
            <div className='w-full flex hoverable'>
              <div className='w-full text-center text-12 truncate'>
                {row.value}
              </div>
              <Icon
                onClick={e => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(row.value)
                }}
                color='action'
                className='show-on-hover text-16 align-middle ml-4'
              >
                file_copy
              </Icon>
            </div>
          ),
          className: 'justify-center font-bold',
        },
        {
          Header: 'Authorization',
          accessor: 'auth_id',
          width: 224,
          Cell: row => (
            <div className='w-full flex hoverable'>
              <div className='w-full text-center text-12 truncate'>
                {row.value}
              </div>
              <Icon
                onClick={e => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(row.value)
                }}
                color='action'
                className='show-on-hover text-16 align-middle ml-4'
              >
                file_copy
              </Icon>
            </div>
          ),
          className: 'justify-center font-bold',
        },
      ]
    }

    const exportPayments = _.find(securables, { Securable: 'export-payments' })

    return (
      <div className='w-full'>
        <Media query='(min-width: 768px)'>
          {() => (
            <FuseAnimate animation='transition.slideUpIn' delay={300}>
              <div>
                {exportPayments && (
                  <Tooltip title='Export to CSV' placement='top'>
                    <Fab
                      color='primary'
                      aria-label='add'
                      className={classes.addButton}
                      onClick={() => this.getExportData()}
                    >
                      <Icon className='text-16'>save_alt</Icon>
                    </Fab>
                  </Tooltip>
                )}
                <Typography
                  variant='h6'
                  style={{ alignItems: 'center' }}
                  className='flex truncate text-16 sm:text-20 mb-6 sm:mb-12'
                >
                  <Icon
                    onClick={() => {
                      this.props.setDeposit(null)
                      this.props.setDepositTransactions(null)
                    }}
                    color='primary'
                    className='text-32 mr-12 cursor-pointer'
                  >
                    keyboard_arrow_left
                  </Icon>
                  {this.state.featureFlags['spp-238-new-deposit-deatils'] ===
                    true && 'Deposit Transactions'}
                  {this.state.featureFlags['spp-238-new-deposit-deatils'] !==
                    true && 'Payment Deposits'}
                </Typography>
                <ReactTableFixedColumns
                  className={classNames(
                    classes.root,
                    '-striped -highlight border-0 rounded',
                  )}
                  getTrProps={(state, rowInfo) => {
                    const trn = rowInfo ? rowInfo.original : null
                    return {
                      className: `cursor-pointer`,
                      onClick: e => {
                        e.stopPropagation()
                        if (rowInfo) {
                          this.getTransaction(rowInfo.original)
                        }
                      },
                    }
                  }}
                  // manual
                  data={data}
                  loading={loading} // Display the loading overlay when we need it
                  NoDataComponent={loading ? LoadingDiv : NoData}
                  LoadingComponent={loading ? LoadingDiv : undefined}
                  columns={tableColumns}
                  defaultPageSize={20}
                  noDataText='No Transactions found'
                />
              </div>
            </FuseAnimate>
          )}
        </Media>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPayments: Actions.getPayments,
      setPaymentFilters: Actions.setPaymentFilters,
      setTransaction: Actions.setTransaction,
      setDeposit: Actions.setDeposit,
      setDepositTransactions: Actions.setDepositTransactions,
      showMessage,
    },
    dispatch,
  )
}

function mapStateToProps({ paymentsApp, spReducers }) {
  return {
    Co: spReducers.companies.Co,
    companies: spReducers.companies.List,
    depositData: paymentsApp.payments.deposit,
    depositTransactions: paymentsApp.payments.depositTransactions,
    searchText: paymentsApp.payments.searchText,
    user: spReducers.userProfiles.User.UserName,
    securables: spReducers.userProfiles.User.Data.Securables,
    filters: paymentsApp.payments.filters,
    loading: paymentsApp.payments.loading,
    transaction: paymentsApp.payments.transaction,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositTransactions)),
)
