import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Button, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Fab, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from '@lodash';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 8,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class VoiceMailboxesList extends Component {

    state = {
        selectedVoiceMailboxesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedVoiceMailboxMenu = (event) => {
        this.setState({ selectedVoiceMailboxesMenu: event.currentTarget });
    };

    closeSelectedVoiceMailboxesMenu = () => {
        this.setState({ selectedVoiceMailboxesMenu: null });
    };

    parseSSML(ssml) {
        var parser = new DOMParser();
        var SSML = parser.parseFromString(`<say>${ssml}</say>`, "text/xml");
        var nodes = SSML.getElementsByTagName("prosody");
        var content = '';
        for (var i = 0; i < nodes.length; i++) {
            content += `${nodes[i].childNodes[0].nodeValue} `;
        }
        window["warn"](content, ssml, nodes, SSML);
        return content;
    }

    render() {
        const { classes, searchText, openEditVoiceMailboxDialog, voiceMailboxes, user, securables } = this.props;
        const accessLevel = _.find(securables, { Securable: "voicemail" });
        const mailboxes = _.filter(voiceMailboxes, (o) => _.findIndex(o.Data.Handlers || [], { UserName: user }) > -1 || (accessLevel && accessLevel.AccessLevel === "F"));
        const data = this.getFilteredArray(mailboxes, searchText);

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    {accessLevel && accessLevel.AccessLevel === "F" &&
                        <Fab
                            color="secondary"
                            aria-label="add"
                            className={classes.addButton}
                            onClick={this.props.openNewVoiceMailboxDialog}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    }
                    <div className="">
                        <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">voicemail</Icon>Mailboxes</Typography>
                        <ReactTableFixedColumns
                            color='transparent'
                            stripedColor='rgba(10,10,10,.01)'
                            highlightColor='rgba(10,10,10,.005)'
                            className={classNames(classes.root, "-striped -highlight border-0")}
                            getTrProps={(state, rowInfo) => {
                                return {
                                    className: "cursor-pointer",
                                    onClick: () => {
                                        if (rowInfo) {
                                            openEditVoiceMailboxDialog(rowInfo.original);
                                        }
                                    }
                                }
                            }}
                            data={data}
                            columns={[
                                {
                                    Header: "Mailbox",
                                    accessor: "VoiceMailbox",
                                    className: "font-bold justify-center",
                                    width: 64
                                },
                                {
                                    Header: "Name",
                                    accessor: "Name",
                                    className: "justify-center",
                                    width: 200,
                                },
                                {
                                    Header: "Description",
                                    accessor: "Description",
                                    width: 300,
                                },
                                {
                                    Header: "Active",
                                    accessor: "ActiveYN",
                                    className: "justify-center",
                                    width: 64,
                                },
                            ]}
                            defaultPageSize={10}
                            noDataText="No Mailboxes found"
                        />
                    </div>
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
        openEditVoiceMailboxDialog: Actions.openEditVoiceMailboxDialog,
        openNewVoiceMailboxDialog: Actions.openNewVoiceMailboxDialog
    }, dispatch);
}

function mapStateToProps({ voiceMailboxesApp, spReducers }) {
    return {
        voiceMailboxes: spReducers.voiceMailboxes,
        searchText: voiceMailboxesApp.voiceMailboxes.searchText,
        user: spReducers.userProfiles.User.UserName,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(VoiceMailboxesList)));
