import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedCustomerSiteIds: [],
    routeParams: {},
    customerSiteDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    }
};

const customerSitesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CUSTOMER_SITES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        //case Actions.LOAD_WORK_ORDERS
        //    {
        //        return {
        //            ...state,
        //            workOrders: action.workOrders
        //        };
        //    }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.TOGGLE_IN_SELECTED_CUSTOMER_SITES:
            {

                const customerSiteId = action.customerSiteId;

                let selectedCustomerSiteIds = [...state.selectedCustomerSiteIds];

                if (selectedCustomerSiteIds.find(id => id === customerSiteId) !== undefined) {
                    selectedCustomerSiteIds = selectedCustomerSiteIds.filter(id => id !== customerSiteId);
                }
                else {
                    selectedCustomerSiteIds = [...selectedCustomerSiteIds, customerSiteId];
                }

                return {
                    ...state,
                    selectedCustomerSiteIds: selectedCustomerSiteIds
                };
            }
        case Actions.SELECT_ALL_CUSTOMER_SITES:
            {
                const arr = Object.keys(state.entities).map(k => state.entities[k]);

                const selectedCustomerSiteIds = arr.map(customerSite => customerSite.id);

                return {
                    ...state,
                    selectedCustomerSiteIds: selectedCustomerSiteIds
                };
            }
        case Actions.DESELECT_ALL_CUSTOMER_SITES:
            {
                return {
                    ...state,
                    selectedCustomerSiteIds: []
                };
            }
        case Actions.OPEN_NEW_CUSTOMER_SITE_DIALOG:
            {
                window["log"]('CUSTOMER DATA FOR NEW SITE: ', action.data, (action.data.Customer ? true : false));
                return {
                    ...state,
                    customerSiteDialog: {
                        type: (action.data.Customer ? 'customer' : 'new'),
                        props: {
                            open: true
                        },
                        data: (action.data.Customer ? action.data : null)
                    }
                };
            }
        case Actions.CLOSE_NEW_CUSTOMER_SITE_DIALOG:
            {
                return {
                    ...state,
                    customerSiteDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_CUSTOMER_SITE_DIALOG:
            {
                return {
                    ...state,
                    customerSiteDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.DATA_EDIT_CUSTOMER_SITE_DIALOG:
            {
                return {
                    ...state,
                    customerSiteDialog: {
                        type: 'data',
                        props: {
                            ...state.customerSiteDialog.props
                        },
                        data: action.data
                    }
                };
            }
        case Actions.ADD_EDIT_CUSTOMER_SITE_DIALOG:
            {
                return {
                    ...state,
                    customerSiteDialog: {
                        type: 'add',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.UPDATE_EDIT_CUSTOMER_SITE_DIALOG:
            {
                return {
                    ...state,
                    customerSiteDialog: {
                        type: 'update',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_CUSTOMER_SITE_DIALOG:
            {
                return {
                    ...state,
                    customerSiteDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.REMOVE_CUSTOMER_SITE: {
            return {
                ...state,
                customerSiteDialog: {
                    ...state.customerSiteDialog,
                    props: {
                        open: false,
                    }
                }
            }
        }
        default:
            {
                return state;
            }
    }
};

export default customerSitesReducer;
