import _ from '@lodash'
import {
  AppBar,
  Dialog,
  Grow,
  Icon,
  IconButton,
  Card,
  Typography,
  CardContent,
  CardHeader,
  Toolbar,
  Backdrop,
  Slide,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  openMinimizedDialog,
  updateAllMinimizedDialogs,
} from '../minimized-dialogs/store/actions/minimized-dialogs.actions'
import {
  addSplitscreenDialog,
  showSplitscreenDialog,
  setSplitscreenDialog,
  hideAllSplitscreenDialogs,
  captureSplitscreenDialogs,
} from '../splitscreen-dialogs/store/actions/splitscreen-dialogs.actions'
import Draggable from 'react-draggable'
import html2canvas from 'html2canvas'
import DraggableOptionsDialog from 'main/content/apps/draggable-dialog/DraggableOptionsDialog'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    //minHeight: 'calc(100% - 64px)',
  },
  paperInc: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  mini: {
    margin: 0,
    maxHeight: '300px',
    height: '300px',
    maxWidth: '300px',
    width: '300px',
  },
  fullHeight: {
    margin: 4,
    maxHeight: 'calc(100vh - 8px)',
    height: 'calc(100vh - 8px)',
    maxWidth: 'calc(50vw - 8px)',
    width: 'calc(50vw - 8px)',
  },
  halfHeight: {
    margin: 4,
    maxHeight: 'calc(50vh - 8px)',
    height: 'calc(50vh - 8px)',
    maxWidth: 'calc(50vw - 8px)',
    width: 'calc(50vw - 8px)',
  },
  rightSplitOverlay: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    opacity: '0.5',
    zIndex: '-1',
    right: 0,
    top: 0,
    position: 'fixed',
  },
  leftSplitOverlay: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    opacity: '0.5',
    zIndex: '-1',
    left: 0,
    top: 0,
    position: 'fixed',
  },
  lowerRightSplitOverlay: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    opacity: '0.5',
    zIndex: '-1',
    right: -5,
    //top: 0,
    bottom: -5,
    position: 'fixed',
    //border: '2px dotted black',
  },
  lowerLeftSplitOverlay: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    opacity: '0.5',
    zIndex: '-1',
    left: -5,
    //top: 0,
    bottom: -5,
    position: 'fixed',
    //border: '2px dotted black',
  },
  upperRightSplitOverlay: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    opacity: '0.5',
    zIndex: '-1',
    right: -5,
    top: -5,
    position: 'fixed',
    //border: '2px dotted black',
  },
  upperLeftSplitOverlay: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    opacity: '0.5',
    zIndex: '-1',
    left: -5,
    top: -5,
    position: 'fixed',
    //border: '2px dotted black',
  },
  screenOverlay: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    left: 0,
    top: 0,
    position: 'fixed',
    //zIndex: '15',
  },
  rightDropBox: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '2',
    right: 0,
    top: '15%',
    position: 'fixed',
    height: '75%',
  },
  leftDropBox: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '2',
    left: 0,
    top: '15%',
    position: 'fixed',
    height: '75%',
  },
  dropHereLeft: {
    color: '#4dbce9',
    top: '50%',
    position: 'fixed',
    left: '7%',
    opacity: '1',
  },
  dropHereRight: {
    color: '#4dbce9',
    top: '50%',
    position: 'fixed',
    right: '7%',
    opacity: '1',
  },
  splitDialog: {
    minWidth: 200,
    cursor: 'pointer',
  },
  cardHeader: {
    backgroundColor: '#4dbce9',
    borderBottom: '1px solid lightgrey',
    boxShadow: '0px 0px 4px 0px #8f8f8f',
    color: 'white',
  },
  cropDiv: {
    height: 200,
    maxWidth: 350,
    overflow: 'hidden',
    padding: 0,
    margin: 0,
  },
})

const newDraggableState = {
  minimizedDialog: false,
  disableBackdropClick: false,
  dragged: null,
  screenOverlay: null,
  rightSplitOverlay: null,
  leftSplitOverlay: null,
  lowerRightSplitOverlay: null,
  lowerLeftSplitOverlay: null,
  upperRightSplitOverlay: null,
  upperLeftSplitOverlay: null,
  rightDropBox: null,
  leftDropBox: null,
  posReset: true,
  //position: { x: 480, y: 0 },
  position: { x: 0, y: 0 },
  startingPosition: null,
  initialDialogWidth: 960,
  dialogWidth: 960,
  removedFromSplitscreen: false,
  topDialog: false,
  splitImageCaptured: null,
  initialZindex: true,
  dialogHeight: null,
  snapPosition: null,
  snapLayout: null,
  mini: false,
  show: false,
  minHeight: null,
  dragging: false,
  dialogWidth2: '50vw',
  location: null,
  posSet: null,
  windowWidth: null,
  windowHeight: null,
  posOg: { x: 0, y: 0 },
  docked: false,
  fullScreenDialog: false,
}

class DraggableDialog extends Component {
  state = { ...newDraggableState }

  dragRef = React.createRef()

  componentDidMount() {
    const { type } = this.props

    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight

    window.addEventListener('resize', this.handleResize)

    if (type === 'Work Order' || type === 'Estimate') {
      this.setState({
        ...this.state,
        minHeight: null,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      })
    } else {
      this.setState({
        ...this.state,
        minHeight: 'calc(100% - 64px)',
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // window["warn"]("draggable position1111", this.props.type, this.props.storeDialog.props.open)
    const { header, type, TypeState } = this.props
    /**
     * After Dialog Open
     */
    let windowWidth = window.innerWidth

    if (this.state.posReset) {
      // || this.state.window !== windowWidth) {
      if (windowWidth > 1850) {
        this.setState({
          ...this.state,
          position: { x: windowWidth / 4, y: 0 },
          dialogWidth: windowWidth / 2,
          dialogWidth2: '50vw',
          startingPosition: { x: windowWidth / 4, y: 0 },
          posReset: false,
          window: windowWidth,
        })
      } else if (windowWidth > 1400 && windowWidth <= 1850) {
        this.setState({
          ...this.state,
          position: { x: windowWidth / 5, y: 0 },
          dialogWidth: windowWidth / 2,
          dialogWidth2: '50vw',
          startingPosition: { x: windowWidth / 5, y: 0 },
          posReset: false,
          window: windowWidth,
        })
      } else if (windowWidth > 1250 && windowWidth <= 1400) {
        this.setState({
          ...this.state,
          position: { x: windowWidth / 6, y: 0 },
          dialogWidth: windowWidth / 2,
          dialogWidth2: '50vw',
          startingPosition: { x: windowWidth / 6, y: 0 },
          posReset: false,
          window: windowWidth,
        })
      } else if (windowWidth > 1100 && windowWidth <= 1250) {
        this.setState({
          ...this.state,
          position: { x: windowWidth / 8, y: 0 },
          dialogWidth: windowWidth / 2,
          dialogWidth2: '50vw',
          startingPosition: { x: windowWidth / 8, y: 0 },
          posReset: false,
          window: windowWidth,
        })
      } else if (windowWidth <= 1100) {
        this.setState({
          ...this.state,
          position: { x: 0, y: 0 },
          dialogWidth: 560,
          dialogWidth2: windowWidth / 2,
          startingPosition: { x: 0, y: 0 },
          posReset: false,
          window: windowWidth,
        })
      }
    }

    const { splitscreenDialogs, screenOverlay } = this.props
    let dialogList = [...splitscreenDialogs]

    const draggedIndex = _.findIndex(dialogList, o => o.dragged === true)

    if (
      this.props.storeDialog.props.open &&
      !this.state.screenOverlay &&
      !this.state.dragged &&
      draggedIndex === -1
    ) {
      this.setState({ ...this.state, screenOverlay: true })
    }

    if (!this.props.storeDialog.props.open && this.state.screenOverlay) {
      this.setState({ ...this.state, screenOverlay: false })
    }

    const dialogIndex = _.findIndex(dialogList, o => o.header === header)

    // window["warn"](
    //   "splitScreenDialog addDialogToSplitOptions true",
    //   header,
    //   prevProps.storeDialog.dialogHeight,
    //   this.props.dialogHeight
    // );
    if (dialogIndex === -1 && TypeState && !this.state.removedFromSplitscreen) {
      //if(dialogIndex === -1 && TypeState && !prevProps.storeDialog.props.open && this.props.storeDialog.props.open){
      let minimizedDialogList = [...this.props.minimizedDialogs]
      let minimizedDialogIndex = _.findIndex(minimizedDialogList, o => {
        return o.header === header
      })
      //   window["warn"](
      //     "splitScreenDialog addDialogToSplitOptions true",
      //     header,
      //     dialogIndex,
      //     minimizedDialogIndex,
      //     this.props.minimizedDialogs
      //   );
      if (minimizedDialogIndex > -1) {
        this.addDialogToSplitOptions(true)
      } else {
        this.addDialogToSplitOptions(false)
      }
    }
    if (
      !prevProps.storeDialog.props.open &&
      this.props.storeDialog.props.open &&
      this.state.removedFromSplitscreen
    ) {
      //   window["warn"](
      //     "minHeight 1343",
      //     this.state.minHeight,
      //     prevState.minHeight,
      //     this.props.minHeight
      //   );
      if (this.props.type === 'Work Order') {
        this.setState({
          ...this.state,
          removedFromSplitscreen: false,
          minHeight: 'calc(100% - 64px)',
          dialogHeight: null,
        }) //
      } else if (this.props.type === 'Incoming Calls') {
        this.setState({
          ...this.state,
          removedFromSplitscreen: false,
          minHeight: this.state.minHeight,
          dialogHeight: this.state.dialogHeight,
        }) //'calc(100% - 64px)'
      } else {
        this.setState({
          ...this.state,
          removedFromSplitscreen: false,
          minHeight: this.state.minHeight,
          dialogHeight: null,
        }) //'calc(100% - 64px)'
      }
    }

    if (dialogIndex > -1) {
      if (
        dialogList[dialogIndex] + 1 < dialogList.length &&
        this.state.initialZindex === true
      ) {
        this.setState({
          ...this.state,
          initialZindex: false,
          screenOverlay: null,
        })
      }

      //   window["warn"](
      //     "topD fff",
      //     header,
      //     dialogList[dialogIndex].top,
      //     this.state.topDialog,
      //     this.state.initialZindex
      //   );
      if (dialogList[dialogIndex].top !== this.state.topDialog) {
        if (dialogList[dialogIndex].top === false) {
          this.setState({
            ...this.state,
            topDialog: dialogList[dialogIndex].top,
            initialZindex: false,
          })
        } else {
          this.setState({
            ...this.state,
            topDialog: dialogList[dialogIndex].top,
          })
        }
      }
    }

    if (
      !this.props.initialWidthSet &&
      (this.props.type === 'Work Order' || this.props.type === 'Estimate') &&
      this.state.initialDialogWidth
    ) {
      this.setState({ ...this.state, initialDialogWidth: null })
    }

    if (
      prevProps.storeDialog.props.open &&
      this.props.storeDialog.props.open &&
      !this.state.removedFromSplitscreen &&
      !this.state.dragging
    ) {
      //   window["warn"](
      //     "open 122344",
      //     this.props.header,
      //     prevState.dialogHeight,
      //     this.state.dialogHeight
      //   );
      if (prevState.dialogHeight !== null && !this.state.dialogHeight) {
        this.setState({ ...this.state, dialogHeight: prevState.dialogHeight })
      }
    }

    if (prevProps.header !== header) {
      let dialogList = [...this.props.splitscreenDialogs]
      let minimizedDialogList = [...this.props.minimizedDialogs]

      const dialogIndex = _.findIndex(dialogList, o => o.header === header)
      const typeIndex = _.findIndex(dialogList, o => o.type === this.props.type)
      if (dialogIndex === -1 && typeIndex > -1) {
        const oldDialogHeaderIndex = _.findIndex(
          dialogList,
          o => o.header === prevProps.header,
        )
        if (oldDialogHeaderIndex !== -1 && dialogList[oldDialogHeaderIndex]) {
          dialogList[oldDialogHeaderIndex].header = header
          this.props.setSplitscreenDialog(dialogList, false, null)
        }

        const oldMiniHeaderIndex = _.findIndex(
          minimizedDialogList,
          o => o.header === prevProps.header,
        )
        if (
          oldMiniHeaderIndex !== -1 &&
          minimizedDialogList[oldMiniHeaderIndex]
        ) {
          minimizedDialogList[oldMiniHeaderIndex].header = header
          this.props.openMinimizedDialog(minimizedDialogList)
        }
      }
    }

    if (
      prevProps.storeDialog.props.open &&
      !this.props.storeDialog.props.open &&
      !this.state.posSet &&
      !this.state.minimizedDialog
    ) {
      let splitDialogList = [...this.props.splitscreenDialogs]
      const dialogIndex = _.findIndex(splitDialogList, o => o.header === header)
      if (dialogIndex > -1) {
        // window["warn"]("open/close issue", dialogList, header);
        this.closeDialog(null)
      }
    }
  }

  minimizeDialog = () => {
    const { header, icon, dialogState } = this.props
    // window["warn"]("123 minimizeDialog ran!");
    let dialogList = [...this.props.minimizedDialogs]
    let dialogIndex = _.findIndex(dialogList, o => {
      return o.header === header
    })
    if (dialogIndex > -1) {
      dialogList[dialogIndex].show = true
    } else {
      dialogList.push({
        header: header,
        open: false,
        icon: icon,
        show: true,
        dialogState: dialogState,
        reopenFxn: this.openMinimizedDialog.bind(this),
        closeFxn: this.closeDialog.bind(this),
      })
    }

    this.props.openMinimizedDialog(dialogList)
    this.closeDialogForSplitscreen()
    //this.addDialogToSplitOptions(true);

    this.setState({
      ...this.state,
      minimizedDialog: true,
      screenOverlay: null,
    })
  }

  openMinimizedDialog = () => {
    this.setState(
      { ...this.state, minimizedDialog: true },
      this.props.reopenDialogFxn({
        ...this.props.dialogState,
        minimizedDialog: true,
      }),
    )
  }

  addDialogToSplitOptions = addedToMinimizedDialogs => {
    const { dialogWidth } = this.state
    const { header, type, icon, dialogState, description } = this.props
    let dialogList = [...this.props.splitscreenDialogs]

    dialogList.push({
      header: header,
      type: type,
      open: true,
      icon: icon,
      split: false,
      width: dialogWidth,
      description: description,
      top: false,
      image: this.state.splitImageCaptured,
      //dialogState: this.state,
      dialogState: dialogState,
      reopenFxn: this.setPosition.bind(this),
      closeFxn: this.closeSplitOptionDialog.bind(this),
      closeFxn2: this.closeDialogForSplitscreen.bind(this),
      captureImage: this.captureElementForSplitScreen.bind(this),
    })

    let typeIndex = _.findIndex(
      dialogList,
      o => o.type === type && o.header !== header,
    )

    let newDialogList = []
    if (typeIndex > -1) {
      newDialogList = _.pull(dialogList, dialogList[typeIndex])
    }

    // window["warn"]("typeIndex", typeIndex, newDialogList, dialogList);

    this.props.setSplitscreenDialog(dialogList, false, null)

    if (!addedToMinimizedDialogs) {
      let minimizedDialogList = [...this.props.minimizedDialogs]
      minimizedDialogList.push({
        header: header,
        open: false,
        icon: icon,
        show: false,
        dialogState: dialogState,
        reopenFxn: this.openMinimizedDialog.bind(this),
        closeFxn: this.closeDialog.bind(this),
      })
      this.props.openMinimizedDialog(minimizedDialogList)
    }

    this.setState(
      { ...this.state, removedFromSplitscreen: false },
      this.props.captureSplitscreenDialogs(),
    )
  }

  removeSplitAndMinimizedDialogs = () => {
    const { header } = this.props
    const {
      splitscreenDialogs,
      setSplitscreenDialog,
      minimizedDialogs,
      openMinimizedDialog,
      splitscreenDialog,
    } = this.props
    let ogSplitDialogList = [...splitscreenDialogs]
    let splitDialogList = [...splitscreenDialogs]
    const filteredSplitDialog = _.filter(
      splitDialogList,
      o => o.header === header,
    )
    const newDialogSplitList = _.pull(splitDialogList, filteredSplitDialog[0])

    const filteredSplitDialog2 = _.filter(
      ogSplitDialogList,
      o => o.split === true,
    )

    if (ogSplitDialogList.length > 2 && filteredSplitDialog2.length > 0) {
      setSplitscreenDialog(
        newDialogSplitList,
        true,
        splitscreenDialog.splitPosition,
      )
    } else {
      setSplitscreenDialog(
        newDialogSplitList,
        false,
        splitscreenDialog.splitPosition,
      )
    }

    let minimizedDialogList = [...minimizedDialogs]
    const filteredMinimizedDialog = _.filter(
      minimizedDialogList,
      o => o.header === header,
    )
    const newDialogMinimizedList = _.pull(
      minimizedDialogList,
      filteredMinimizedDialog[0],
    )
    openMinimizedDialog(newDialogMinimizedList)
  }

  handleDrag = e => {
    //e.preventDefault();
    const { header, splitscreenDialog } = this.props
    let parent = document.querySelector('body')
    let parentRect = parent.getBoundingClientRect()

    //let draggable = selector;
    let draggable = this.dragRef.current
    let draggableRect = draggable.getBoundingClientRect()

    let translate = draggable.style.transform

    let transformX = translate.split('(')[1].split(',')[0]
    let transformY = translate.split('(')[1].split(',')[1].slice(1, -1)

    const {
      splitscreenDialogs,
      setSplitscreenDialog,
      captureSplitscreenDialogs,
    } = this.props
    const { splitImageCaptured } = this.state
    let dialogList = [...splitscreenDialogs]
    const dialogIndex = _.findIndex(dialogList, o => o.header === header)

    let mini = this.state.mini
    let minHeight = this.state.minHeight

    if (parseInt(transformY.split('px')[0]) <= -15 && mini === false) {
      mini = true
      minHeight = null
    }
    // window["warn"]("handleDrag transformX", transformX);
    if (parseInt(transformY.split('px')[0]) >= 60 && mini === true) {
      mini = false
      minHeight = 'calc(100% - 64px)'
    }

    const leftSnapPos = { x: 0, y: 0 }

    if (dialogIndex > -1) {
      //dialogList[dialogIndex].split = false;
      dialogList[dialogIndex].docked = null

      dialogList.map(dialog => {
        dialog.split = false
      })
    }

    let screenOverlay = this.state.screenOverlay
    let newX = parseInt(transformX.split('px')[0])
    let newY = parseInt(transformY.split('px')[0])
    let diffX = Math.abs(newX - this.state.startingPosition.x)
    let diffY = Math.abs(newY - this.state.startingPosition.y)
    // if (parseInt(transformX.split('px')[0]) !== startingPosition.x || parseInt(transformY.split('px')[0]) !== startingPosition.y) {
    //     screenOverlay = null;
    // }

    if (diffX >= 30 || diffY >= 30) {
      screenOverlay = null
    }

    this.setState(
      {
        ...this.state,
        disableBackdropClick: true,
        rightDropBox: 'rgba(0, 0, 0, 0.3)',
        leftDropBox: 'rgba(0, 0, 0, 0.3)',
        dialogHeight: null,
        mini: mini,
        show: true,
        minHeight: minHeight,
        dragged: true,
        dragging: true,
        screenOverlay: screenOverlay,
      },
      setSplitscreenDialog(dialogList, false, splitscreenDialog.splitPosition),
    )

    if (this.props.type === 'Work Order' || this.props.type === 'Estimate') {
      this.props.heightUpdateFxn(null, null, null)
    }
  }

  onDragStop = e => {
    e.preventDefault()

    const { dialogWidth, startingPosition, snapPosition, position } = this.state

    let parent = document.querySelector('body')
    let parentRect = parent.getBoundingClientRect()

    let draggable = this.dragRef.current
    let draggableRect = draggable.getBoundingClientRect()

    let translate = draggable.style.transform

    let transformX = translate.split('(')[1].split(',')[0]
    let transformY = translate.split('(')[1].split(',')[1].slice(1, -1)

    let split = false

    const { splitscreenDialogs, setSplitscreenDialog, splitscreenDialog } =
      this.props
    let dialogList = [...splitscreenDialogs]
    const dialogIndex = _.findIndex(
      dialogList,
      o => o.header === this.props.header,
    )
    let overlay = null
    if (splitscreenDialogs.length > 1) {
      overlay = 'rgba(0, 0, 0, 0.3)'
    }

    let screenOverlay = this.state.screenOverlay
    let newX = parseInt(transformX.split('px')[0])
    let newY = parseInt(transformY.split('px')[0])
    let diffX = Math.abs(newX - startingPosition.x)
    let diffY = Math.abs(newY - startingPosition.y)
    // if (parseInt(transformX.split('px')[0]) !== startingPosition.x || parseInt(transformY.split('px')[0]) !== startingPosition.y) {
    //     screenOverlay = null;
    // }

    if (diffX >= 30 || diffY >= 30) {
      screenOverlay = null
    }

    // window["warn"](
    //   "draggable snapPosition onDragStop",
    //   newX,
    //   startingPosition.x,
    //   diffX,
    //   diffY,
    //   position,
    //   startingPosition
    // );

    if (this.state.snapPosition) {
      const snapDialogs = _.findIndex(
        dialogList,
        o => o.docked === this.state.snapPosition,
      )

      split = true
      if (dialogIndex > -1) {
        dialogList[dialogIndex].split = true
        dialogList[dialogIndex].docked = this.state.snapPosition
        //dialogList[dialogIndex].top = true;
      }

      let show = true

      const dockedDialogs = _.filter(
        dialogList,
        o => o.docked !== undefined && o.docked !== null,
      )

      //Fixes bug where dialogs hide after being docked then dragged then docked again
      if (dockedDialogs.length === dialogList.length && snapDialogs === -1) {
        // window["warn"](
        //   "updateSnapPosition onDragStop",
        //   dockedDialogs,
        //   dialogList,
        //   snapDialogs
        // );
        show = false
        overlay = false
      }

      //   window["warn"](
      //     "lljjk",
      //     splitscreenDialog.layout,
      //     this.state.snapLayout,
      //     dialogList
      //   );

      if (
        splitscreenDialog.layout &&
        splitscreenDialog.layout !== this.state.snapLayout
      ) {
        show = true
        overlay = true

        dialogList.map(dialog => {
          //dialog.docked = null;
          if (dialog.header !== this.props.header) {
            dialog.docked = null
          }
        })
      } else {
        if (snapDialogs > -1) {
          dialogList.map(dialog => {
            if (dialog.header !== this.props.header) {
              dialog.docked = null
            }
          })
        }
      }

      //draggableRect.height = 900

      let windowHeight = window.innerHeight

      let halfHeight = windowHeight / 2

      //   window["warn"]("windowHeight", windowHeight);

      if (snapPosition === 'right') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: overlay,
            upperRightSplitOverlay: false,
            upperLeftSplitOverlay: false,
            lowerRightSplitOverlay: false,
            lowerLeftSplitOverlay: false,
            position: { x: dialogWidth, y: 0 },
            posOg: { x: dialogWidth, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            dialogHeight: 'full',
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(dialogList, show, 'right', null),
        )
      } else if (snapPosition === 'left') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: overlay,
            leftSplitOverlay: false,
            upperRightSplitOverlay: false,
            upperLeftSplitOverlay: false,
            lowerRightSplitOverlay: false,
            lowerLeftSplitOverlay: false,
            position: { x: 0, y: 0 },
            posOg: { x: 0, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            dialogHeight: 'full',
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(dialogList, show, 'left', null),
        )
      } else if (snapPosition === 'upper-right') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: false,
            upperLeftSplitOverlay: overlay,
            lowerRightSplitOverlay: overlay,
            lowerLeftSplitOverlay: overlay,
            position: { x: dialogWidth, y: 0 },
            posOg: { x: dialogWidth, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(
            dialogList,
            show,
            'upper-right',
            parentRect.height,
          ),
        )
      } else if (snapPosition === 'upper-left') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: overlay,
            upperLeftSplitOverlay: false,
            lowerRightSplitOverlay: overlay,
            lowerLeftSplitOverlay: overlay,
            position: { x: 0, y: 0 },
            posOg: { x: 0, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(
            dialogList,
            show,
            'upper-left',
            parentRect.height,
          ),
        )
      } else if (snapPosition === 'lower-right') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: overlay,
            upperLeftSplitOverlay: overlay,
            lowerRightSplitOverlay: false,
            lowerLeftSplitOverlay: overlay,
            position: { x: dialogWidth, y: halfHeight },
            posOg: { x: dialogWidth, y: halfHeight },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(
            dialogList,
            show,
            'lower-right',
            parentRect.height,
          ),
        )
      } else if (snapPosition === 'lower-left') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: overlay,
            upperLeftSplitOverlay: overlay,
            lowerRightSplitOverlay: overlay,
            lowerLeftSplitOverlay: false,
            position: { x: 0, y: halfHeight },
            posOg: { x: 0, y: halfHeight },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(
            dialogList,
            show,
            'lower-left',
            parentRect.height,
          ),
        )
      } else if (snapPosition === 'right-2') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: overlay,
            upperRightSplitOverlay: false,
            upperLeftSplitOverlay: false,
            lowerRightSplitOverlay: false,
            lowerLeftSplitOverlay: false,
            position: { x: dialogWidth, y: 0 },
            posOg: { x: dialogWidth, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: '100%',
            dragging: false,
            dialogHeight: 'full',
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(dialogList, show, 'right-2', parentRect.height),
        )
      } else if (snapPosition === 'left-2') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: overlay,
            leftSplitOverlay: false,
            upperRightSplitOverlay: false,
            upperLeftSplitOverlay: false,
            lowerRightSplitOverlay: false,
            lowerLeftSplitOverlay: false,
            position: { x: 0, y: 0 },
            posOg: { x: 0, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: '100%',
            dragging: false,
            dialogHeight: 'full',
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(dialogList, show, 'left-2', parentRect.height),
        )
      } else if (snapPosition === 'upper-right-2') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: false,
            upperLeftSplitOverlay: overlay,
            lowerRightSplitOverlay: overlay,
            lowerLeftSplitOverlay: overlay,
            position: { x: dialogWidth, y: 0 },
            posOg: { x: dialogWidth, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(
            dialogList,
            show,
            'upper-right-2',
            parentRect.height,
          ),
        )
      } else if (snapPosition === 'upper-left-2') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: overlay,
            upperLeftSplitOverlay: false,
            lowerRightSplitOverlay: overlay,
            lowerLeftSplitOverlay: overlay,
            position: { x: 0, y: 0 },
            posOg: { x: 0, y: 0 },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(
            dialogList,
            show,
            'upper-left-2',
            parentRect.height,
          ),
        )
      } else if (snapPosition === 'lower-right-2') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: overlay,
            upperLeftSplitOverlay: overlay,
            lowerRightSplitOverlay: false,
            lowerLeftSplitOverlay: overlay,
            position: { x: dialogWidth, y: halfHeight },
            posOg: { x: dialogWidth, y: halfHeight },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(dialogList, show, 'lower-right-2', null),
        )
      } else if (snapPosition === 'lower-left-2') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: overlay,
            upperLeftSplitOverlay: overlay,
            lowerRightSplitOverlay: overlay,
            lowerLeftSplitOverlay: false,
            position: { x: 0, y: halfHeight },
            posOg: { x: 0, y: halfHeight },
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: 'half',
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
          },
          this.afterSplitSnap(
            dialogList,
            show,
            'lower-left-2',
            parentRect.height,
          ),
        )
      } else if (snapPosition === 'center') {
        this.setState(
          {
            ...this.state,
            rightSplitOverlay: false,
            leftSplitOverlay: false,
            upperRightSplitOverlay: false,
            upperLeftSplitOverlay: false,
            lowerRightSplitOverlay: false,
            lowerLeftSplitOverlay: false,
            position: this.state.startingPosition,
            posOg: this.state.startingPosition,
            initialDialogWidth: null,
            rightDropBox: null,
            leftDropBox: null,
            screenOverlay: screenOverlay,
            dialogHeight: null,
            mini: false,
            show: false,
            snapPosition: null,
            minHeight: null,
            dragging: false,
            location: snapPosition,
            docked: true,
            initialZindex: true,
          },
          this.afterSplitSnapCenter(dialogList, false, 'center', null),
        )
      }
    }

    if (!split) {
      this.setState({
        ...this.state,
        position: {
          x: parseInt(transformX.split('px')[0]),
          y: parseInt(transformY.split('px')[0]),
        },
        rightDropBox: null,
        leftDropBox: null,
        screenOverlay: screenOverlay,
        rightSplitOverlay: null,
        leftSplitOverlay: null,
        snapPosition: null,
        dragging: false,
        show: false,
        docked: false,
      })
      if (this.props.type === 'Incoming Calls') {
        this.props.heightUpdateFxn(null)
      }
    }
  }

  onDragStart = e => {
    //e.preventDefault();

    const { header, updateAllMinimizedDialogs } = this.props
    //let draggable = selector;
    let draggable = this.dragRef.current
    let translate = draggable.style.transform
    let transformX = translate.split('(')[1].split(',')[0]
    let transformY = translate.split('(')[1].split(',')[1].slice(1, -1)

    const { splitscreenDialogs, setSplitscreenDialog, splitscreenDialog } =
      this.props
    let dialogList = [...splitscreenDialogs]
    // window["warn"]("onDragStart ran!", dialogList, header);
    const dialogIndex = _.findIndex(dialogList, o => o.header === header)

    const splitDialogIndex = _.findIndex(dialogList, o => o.split === true)

    let newX = parseInt(transformX.split('px')[0])
    let newY = parseInt(transformY.split('px')[0])
    let diffX = Math.abs(newX - this.state.startingPosition.x)
    let diffY = Math.abs(newY - this.state.startingPosition.y)

    let screenOverlay = this.state.screenOverlay

    if (diffX >= 30 || diffY >= 30) {
      //dialogList[dialogIndex].dragged = true;
      screenOverlay = null
      dialogList[dialogIndex].dragged = true
    }

    // dialogList[dialogIndex].dragged = true;

    if (dialogList.length > 1) {
      if (dialogList[dialogIndex].top === false) {
        const topIndex = _.findIndex(dialogList, o => o.top === true)
        if (topIndex > -1) {
          dialogList[topIndex].top = false
        }

        dialogList[dialogIndex].top = true

        // if (this.state.initialZindex) {
        //     this.setState({ ...this.state, initialZindex: false })
        // }

        //this.setState({ ...this.state, top: true, initialZindex: false }, setSplitscreenDialog(dialogList, this.props.splitscreenDialog.show, this.props.splitscreenDialog.splitPosition))

        //setSplitscreenDialog(dialogList, this.props.splitscreenDialog.show, this.props.splitscreenDialog.splitPosition)
      }
    }

    this.setState(
      {
        ...this.state,
        position: {
          x: parseInt(transformX.split('px')[0]),
          y: parseInt(transformY.split('px')[0]),
        },
        snapPosition: null,
        location: null,
        docked: false,
        screenOverlay: screenOverlay,
      },
      setSplitscreenDialog(dialogList, false, splitscreenDialog.splitPosition),
    )

    if (
      dialogList.length > 1 &&
      splitDialogIndex > -1 &&
      splitscreenDialog.show === true
    ) {
      let dialogList = [...splitscreenDialog.data]

      const dockedDialogs = _.filter(
        dialogList,
        o => o.docked && o.docked.length > 0,
      )
      let dialogsToUpdate = [header]
      dockedDialogs.map(dialog => {
        dialogsToUpdate.push(dialog.header)
      })
      updateAllMinimizedDialogs(dialogsToUpdate)
      //updateAllMinimizedDialogs([header])
    }
  }

  setPosition = (position, height) => {
    let finalPosition
    let { dialogWidth } = this.state
    const { dialogState, minimizedDialogs, header } = this.props

    let windowWidth = window.screen.width
    if (
      this.props.header.includes('Work Order') ||
      this.props.header.includes('Quote')
    ) {
      dialogWidth = windowWidth / 2
    }

    let parent = document.querySelector('body')
    let parentRect = parent.getBoundingClientRect()

    let windowHeight = window.innerHeight
    let halfHeight = windowHeight / 2

    let dialogHeight

    let finalLocation = position

    if (position === 'left') {
      finalPosition = { x: dialogWidth, y: 0 }
      dialogHeight = 'full'
      finalLocation = 'right'
    } else if (position === 'right') {
      finalPosition = { x: 0, y: 0 }
      dialogHeight = 'full'
      finalLocation = 'left'
    } else if (position === 'upper-right') {
      finalPosition = { x: dialogWidth, y: halfHeight }
      dialogHeight = 'half'
      finalLocation = 'lower-right'
    } else if (position === 'upper-left') {
      finalPosition = { x: dialogWidth, y: 0 }
      dialogHeight = 'half'
      finalLocation = 'upper-right'
    } else if (position === 'lower-right') {
      finalPosition = { x: 0, y: halfHeight }
      dialogHeight = 'half'
      finalLocation = 'lower-left'
    } else if (position === 'lower-left') {
      finalPosition = { x: 0, y: 0 }
      dialogHeight = 'half'
      finalLocation = 'upper-left'
    } else if (position === 'left-2') {
      finalPosition = { x: dialogWidth, y: 0 }
      dialogHeight = 'half'
      finalLocation = 'upper-right-2'
    } else if (position === 'right-2') {
      finalPosition = { x: 0, y: halfHeight }
      dialogHeight = 'half'
      finalLocation = 'lower-left-2'
    } else if (position === 'upper-right-2') {
      finalPosition = { x: dialogWidth, y: halfHeight }
      dialogHeight = 'half'
      finalLocation = 'lower-right-2'
    } else if (position === 'upper-left-2') {
      finalPosition = { x: dialogWidth, y: 0 }
      dialogHeight = 'full'
      finalLocation = 'right-2'
    } else if (position === 'lower-right-2') {
      finalPosition = { x: 0, y: 0 }
      dialogHeight = 'full'
      finalLocation = 'left-2'
    } else if (position === 'lower-left-2') {
      finalPosition = { x: 0, y: 0 }
      dialogHeight = 'half'
      finalLocation = 'upper-left-2'
    }

    let currentState = { ...dialogState }
    //currentState.position = {x: 480, y: 0}
    currentState.position = finalPosition
    currentState.initialDialogWidth = null
    currentState.minimizeDialog = true
    currentState.splitImageCaptured = null
    currentState.disableBackdropClick = true
    currentState.screenOverlay = null
    currentState.dialogWidth = '50vw'
    currentState.dialogWidth2 = this.state.dialogWidth2
    //currentState.dialogHeight = height ? '60vh' : null;
    currentState.dialogHeight = dialogHeight
    currentState.minHeight = null
    currentState.location = finalLocation
    currentState.posSet = finalPosition
    currentState.docked = true

    //let dialogHeight = height ? '60vh' : null;

    // window["warn"](
    //   "setPosition Ran",
    //   this.props.header,
    //   dialogHeight,
    //   currentState.minHeight,
    //   position
    // );

    this.setState(
      {
        ...this.state,
        position: finalPosition,
        posOg: finalPosition,
        docked: true,
        initialDialogWidth: null,
        splitImageCaptured: null,
        disableBackdropClick: true,
        screenOverlay: null,
        posReset: false,
        dialogHeight: dialogHeight,
        minHeight: null,
        location: finalLocation,
      },
      this.props.reopenDialogFxn(currentState),
    )
    if (
      this.props.type === 'Incoming Calls' ||
      this.props.type === 'Work Order' ||
      this.props.type === 'Estimate'
    ) {
      this.props.heightUpdateFxn(position, finalPosition)
    }

    let minimizedDialogList = [...this.props.minimizedDialogs]
    const minimizedDialogIndex = _.findIndex(
      minimizedDialogList,
      o => o.header === header,
    )
    if (minimizedDialogIndex > -1) {
      minimizedDialogList[minimizedDialogIndex].show = false
      this.props.openMinimizedDialog(minimizedDialogList)
    }
  }

  updateSnapPosition = (position, layout) => {
    // window["warn"]("updateSnapPosition aaa", position, this.state.snapPosition);
    this.setState({
      ...this.state,
      snapPosition: position,
      snapLayout: layout,
    })
  }

  afterSplitSnap = (dialogList, show, splitPosition, height) => {
    // window["warn"](
    //   "draggable snapPosition afterSplitSnap",
    //   splitPosition,
    //   this.state.snapLayout
    // );
    const { setSplitscreenDialog, hideAllSplitscreenDialogs, header } =
      this.props
    setSplitscreenDialog(
      dialogList,
      show,
      splitPosition,
      height,
      1,
      this.state.snapLayout,
    )
    hideAllSplitscreenDialogs(header)
    if (this.props.type === 'Incoming Calls') {
      this.props.heightUpdateFxn(splitPosition)
    } else if (
      this.props.type === 'Work Order' ||
      this.props.type === 'Estimate'
    ) {
      if (splitPosition.includes('upper') || splitPosition.includes('lower')) {
        this.props.heightUpdateFxn(splitPosition, splitPosition, 'half')
      } else {
        this.props.heightUpdateFxn(splitPosition, splitPosition, 'full')
      }
    }
  }

  afterSplitSnapCenter = (dialogList, show, splitPosition, height) => {
    // window["warn"](
    //   "draggable snapPosition afterSplitSnap",
    //   splitPosition,
    //   this.state.snapLayout
    // );
    const { setSplitscreenDialog, hideAllSplitscreenDialogs, header } =
      this.props
    setSplitscreenDialog(
      dialogList,
      show,
      splitPosition,
      height,
      1,
      this.state.snapLayout,
    )
    //hideAllSplitscreenDialogs(header)
    if (this.props.type === 'Incoming Calls') {
      this.props.heightUpdateFxn(splitPosition)
    } else if (
      this.props.type === 'Work Order' ||
      this.props.type === 'Estimate'
    ) {
      if (splitPosition.includes('upper') || splitPosition.includes('lower')) {
        this.props.heightUpdateFxn(splitPosition, splitPosition, 'half')
      } else {
        this.props.heightUpdateFxn(splitPosition, splitPosition, 'full')
      }
    }
  }

  captureElementForSplitScreen = () => {
    const { picRef, header } = this.props
    //const element = this.ref.current;
    // window["warn"]("picRef", header, typeof picRef);
    if (typeof picRef === 'object') {
      const element = picRef
      return html2canvas(element).then(canvas => {
        let image = canvas.toDataURL('image/png', 1.0)
        return image
      })
    } else {
      return picRef
    }
  }

  closeDialog = async e => {
    if (e) {
      e.stopPropagation()
    }
    const {
      splitscreenDialogs,
      setSplitscreenDialog,
      minimizedDialogs,
      openMinimizedDialog,
      splitscreenDialog,
      header,
      cornered,
    } = this.props
    let splitDialogList = [...splitscreenDialogs]
    const filteredSplitDialog = _.filter(
      splitDialogList,
      o => o.header === header,
    )
    const newDialogSplitList = _.pull(splitDialogList, filteredSplitDialog[0])

    setSplitscreenDialog(
      newDialogSplitList,
      false,
      splitscreenDialog.splitPosition,
    )

    let minimizedDialogList = [...minimizedDialogs]
    const filteredMinimizedDialog = _.filter(
      minimizedDialogList,
      o => o.header === header,
    )
    let newDialogMinimizedList = _.pull(
      minimizedDialogList,
      filteredMinimizedDialog[0],
    )

    let dialogList = [...splitscreenDialogs]
    const splitDialogIndex = _.findIndex(dialogList, o => o.split === true)
    if (
      dialogList.length > 1 &&
      splitDialogIndex > -1 &&
      splitscreenDialog.show === true
    ) {
      newDialogMinimizedList.map(dialog => {
        let filteredMinimizedDialog = _.filter(
          newDialogSplitList,
          o => o.header === dialog.header,
        )
        // window["warn"](
        //   "closeDialog updateAllMinimizedDialogs",
        //   filteredMinimizedDialog
        // );

        if (!filteredMinimizedDialog[0].docked) {
          dialog.show = true
        }
      })
    }

    openMinimizedDialog(newDialogMinimizedList)

    // window["warn"]("close dialog ran 123", newDialogSplitList);

    this.setState(
      {
        ...this.state,
        removedFromSplitscreen: true,
        rightSplitOverlay: null,
        leftSplitOverlay: null,
        upperRightSplitOverlay: false,
        upperLeftSplitOverlay: false,
        lowerRightSplitOverlay: false,
        lowerLeftSplitOverlay: false,
        screenOverlay: null,
        posReset: true,
        initialDialogWidth: 960,
        dialogWidth: 960,
        dialogHeight: null,
        minHeight: '100%',
        location: null,
        initialZindex: true,
        topDialog: false,
        dragged: false,
        dragging: false,
        posSet: false,
        docked: false,
      },
      () => this.props.closeComposeDialog(),
    )
  }

  closeSplitOptionDialog = async e => {
    e.stopPropagation()
    const {
      splitscreenDialogs,
      setSplitscreenDialog,
      minimizedDialogs,
      openMinimizedDialog,
      splitscreenDialog,
      header,
      cornered,
    } = this.props
    let ogSplitDialogList = [...splitscreenDialogs]
    let splitDialogList = [...splitscreenDialogs]
    const filteredSplitDialog = _.filter(
      splitDialogList,
      o => o.header === header,
    )
    const newDialogSplitList = _.pull(splitDialogList, filteredSplitDialog[0])

    const filteredSplitDialog2 = _.filter(
      ogSplitDialogList,
      o => o.split === true,
    )

    if (ogSplitDialogList.length > 2 && filteredSplitDialog2.length > 0) {
      //   window["warn"](
      //     "newDialogSplitList ogSplitDialogList",
      //     newDialogSplitList,
      //     splitscreenDialogs
      //   );
      setSplitscreenDialog(
        newDialogSplitList,
        true,
        splitscreenDialog.splitPosition,
      )
    } else {
      //   window["warn"](
      //     "newDialogSplitList else",
      //     newDialogSplitList,
      //     splitscreenDialogs
      //   );
      setSplitscreenDialog(
        newDialogSplitList,
        false,
        splitscreenDialog.splitPosition,
      )
    }

    let minimizedDialogList = [...minimizedDialogs]
    const filteredMinimizedDialog = _.filter(
      minimizedDialogList,
      o => o.header === header,
    )
    const newDialogMinimizedList = _.pull(
      minimizedDialogList,
      filteredMinimizedDialog[0],
    )
    openMinimizedDialog(newDialogMinimizedList)

    // window["warn"]("close dialog ran 123", newDialogSplitList);

    this.setState(
      {
        ...this.state,
        removedFromSplitscreen: true,
        rightSplitOverlay: null,
        leftSplitOverlay: null,
        upperRightSplitOverlay: false,
        upperLeftSplitOverlay: false,
        lowerRightSplitOverlay: false,
        lowerLeftSplitOverlay: false,
        screenOverlay: null,
        posReset: true,
        initialDialogWidth: 960,
        dialogWidth: 960,
        dialogHeight: null,
        minHeight: '100%',
        location: null,
        initialZindex: true,
        topDialog: false,
        dragged: false,
        dragging: false,
        posSet: false,
        docked: false,
      },
      () => this.props.closeComposeDialog(),
    )
  }

  closeDialogForSplitscreen = () => {
    window['warn'](
      'hideAllSplitscreenDialogs closeDialogForSplitscreen ran',
      this.props.header,
      this.state.position,
    )
    this.setState(
      {
        ...this.state,
        removedFromSplitscreen: true,
        rightSplitOverlay: null,
        leftSplitOverlay: null,
        upperRightSplitOverlay: false,
        upperLeftSplitOverlay: false,
        lowerRightSplitOverlay: false,
        lowerLeftSplitOverlay: false,
        screenOverlay: null,
        posReset: true,
        initialDialogWidth: 960,
        dialogWidth: 960,
        dialogHeight: null,
        minHeight: '100%',
        location: null,
        initialZindex: true,
        topDialog: false,
        posSet: true,
      },
      () => this.props.closeComposeDialog(),
    )
    this.props.closeComposeDialog()
  }

  handleResize = () => {
    const { docked, position, posOg, windowHeight, windowWidth } = this.state
    const { header, dialogState, heightUpdateFxn } = this.props

    //window["warn"]("windowWidth Update", this.props.header, dialogState)

    if (docked === true) {
      let x =
        windowWidth !== window.innerWidth
          ? (posOg['x'] * window.innerWidth) / windowWidth
          : posOg['x']
      let y =
        windowHeight !== window.innerHeight
          ? (posOg['y'] * window.innerHeight) / windowHeight
          : posOg['y']

      let newPosition = {
        x: x,
        y: y,
      }

      let fullScreenDialog = window.innerWidth <= 1200 ? true : false

      this.setState({
        ...this.state,
        position: newPosition,
        fullScreenDialog: fullScreenDialog,
      })

      //   window["warn"](
      //     "windowWidth Update",
      //     this.props.header,
      //     posOg,
      //     newPosition
      //   );
    }

    if (docked === false && dialogState.docked === true) {
      let x =
        windowWidth !== window.innerWidth
          ? (dialogState.position['x'] * window.innerWidth) / windowWidth
          : dialogState.position['x']
      let y =
        windowHeight !== window.innerHeight
          ? (dialogState.position['y'] * window.innerHeight) / windowHeight
          : dialogState.position['y']
      let newPosition = {
        x: x,
        y: y,
      }

      let fullScreenDialog = window.innerWidth <= 1200 ? true : false

      //   window["warn"](
      //     "windowWidth Update",
      //     this.props.header,
      //     this.props.posSet,
      //     dialogState.position,
      //     newPosition
      //   );
      if (heightUpdateFxn) {
        heightUpdateFxn(
          dialogState.location,
          newPosition,
          dialogState.dialogHeight,
        )
      }
      this.setState({
        ...this.state,
        position: newPosition,
        fullScreenDialog: fullScreenDialog,
      })
    }
  }

  render() {
    const {
      classes,
      dialogProps,
      matches,
      splitscreenDialogs,
      setSplitscreenDialog,
      header,
      type,
    } = this.props
    const { screenOverlay, position } = this.state

    const currentDialog =
      splitscreenDialogs.length > 0
        ? _.find(splitscreenDialogs, o => {
            return o.header === header
          })
        : null
    let image =
      currentDialog && currentDialog.image ? currentDialog.image : null
    //let mini = this.state.mini;
    let mini = false
    let show = this.state.show

    const parent = document.querySelector('body')
    let parentRect = 0
    if (parent) {
      parentRect = parent.getBoundingClientRect()
    }

    let draggable = this.dragRef.current
    let draggableRect = 0
    let translate = 0
    let transformX = 0
    let transformY = 0
    if (draggable) {
      draggableRect = draggable.getBoundingClientRect()
      translate = draggable.style.transform

      if (translate) {
        transformX = translate.split('(')[1].split(',')[0]
        transformY = translate.split('(')[1].split(',')[1].slice(1, -1)
      }
    }

    const appBarChildren = this.props.AppBarChildren(
      this.props.matches,
      this.minimizeDialog,
      this.closeDialog,
      mini,
      classes.cardHeader,
    )

    const toolbarChildren = this.props.ToolbarChildren()

    let matchesCompare = this.state.fullScreenDialog
      ? false
      : type === 'Work Order'
        ? matches.medium
        : matches

    let location = this.props.location
      ? this.props.location
      : this.state.location

    let dialogHeight
    if (type === 'Work Order' || type === 'Estimate') {
      if (this.props.dialogHeight) {
        dialogHeight = this.props.dialogHeight
      } else {
        dialogHeight = this.state.dialogHeight
      }
    } else {
      dialogHeight = this.state.dialogHeight
    }

    // window["warn"](
    //   "draggable children",
    //   header,
    //   screenOverlay,
    //   this.state.docked
    // );

    let dialogList = [...splitscreenDialogs]
    let thisDialog = _.findIndex(dialogList, o => o.header === header)
    let screenOverlayIndex = thisDialog + 15

    //screenOverlay = !this.state.dragged && !this.state.dragging ? true : false

    const draggedIndex = _.findIndex(dialogList, o => o.dragged === true)

    return (
      <>
        {/* <div 
                    onClick={(e) => this.closeDialog(e)} 
                    className={classNames(classes.screenOverlay, "h-full w-full")}
                    id={type.replace(" ", "-")}
                    style={{ 
                        display: this.props.posSet ? 'none' : Boolean(screenOverlay) ? 'flex' : 'none',
                        zIndex: screenOverlayIndex,
                    }}
                ></div> */}

        <DraggableOptionsDialog
          show={show}
          updateSnapPosition={this.updateSnapPosition.bind(this)}
          snapPosition={this.state.snapPosition}
        />
        <Draggable
          handle='#draggable-area'
          onDrag={e => this.handleDrag(e)}
          onStart={e => this.onDragStart(e)}
          onStop={e => this.onDragStop(e)}
          position={
            matchesCompare
              ? this.props.posSet && !this.state.dragged
                ? this.props.posSet
                : position
              : { x: 0, y: 0 }
          }
          bounds={
            !mini
              ? {
                  left: draggableRect.width * -1.5 + 680,
                  right: draggableRect.width * 1.5 + 280,
                  top: draggableRect.height * -0.8,
                  bottom: parentRect.bottom - 100,
                }
              : null
          }
          disabled={Boolean(window.cordova) || !matchesCompare}
        >
          <Dialog
            TransitionComponent={!this.props.matches ? SlideUp : Grow}
            classes={{
              root: classes.root,
              paper: matchesCompare
                ? mini
                  ? classes.mini
                  : dialogHeight === 'full'
                    ? classes.fullHeight
                    : dialogHeight === 'half'
                      ? classes.halfHeight
                      : header === 'Incoming Calls' || type === 'Timecard'
                        ? classes.paperInc
                        : classes.paper
                : 'full-screen-dialog',
            }}
            style={{
              maxWidth: !matchesCompare
                ? '100vw'
                : mini
                  ? 300
                  : this.state.initialDialogWidth
                    ? this.state.initialDialogWidth
                    : this.state.dialogWidth2,
              maxHeight: !matchesCompare
                ? '100vh'
                : mini
                  ? 300
                  : dialogHeight === 'full'
                    ? '100vh'
                    : dialogHeight === 'half'
                      ? '50vh'
                      : null,
              minHeight:
                this.props.minHeight && !mini && !this.state.dragged
                  ? '100%'
                  : this.state.minHeight,
              left: 0,
              zIndex: mini
                ? 20
                : this.state.dragging
                  ? 20
                  : this.state.topDialog
                    ? 17
                    : this.state.initialZindex
                      ? 17
                      : 16,
              opacity: mini ? 0.7 : 1,
              pointerEvents: this.state.dragging && mini ? 'none' : 'all',
            }}
            fullScreen={!matchesCompare}
            disableBackdropClick={!Boolean(screenOverlay)}
            //hideBackdrop={!Boolean(screenOverlay)}
            BackdropProps={{
              style: {
                display: this.props.posSet
                  ? 'none'
                  : this.state.docked
                    ? 'none'
                    : draggedIndex > -1
                      ? 'none'
                      : Boolean(screenOverlay)
                        ? 'flex'
                        : 'none',
                width: window.innerWidth,
                height: window.innerHeight * 2,
                top: -1 * (window.innerHeight / 2),
                left: -1 * this.state.position.x,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
              },
            }}
            onClose={e => this.closeDialog(e)}
            {...dialogProps}
            data-header={header}
            ref={this.dragRef}
            onClick={event => {
              event.stopPropagation()
              let dialogList = [...splitscreenDialogs]
              const dialogIndex = _.findIndex(
                dialogList,
                o => o.header === header,
              )

              const draggedIndex = _.findIndex(
                dialogList,
                o => o.dragged === true,
              )

              //   window["warn"](
              //     "dialog click stuff",
              //     dialogList,
              //     draggedIndex,
              //     this.state.dragged
              //   );

              //if (dialogList.length > 1 && draggedIndex > -1){
              if (dialogList.length > 1 && draggedIndex > -1) {
                if (dialogList[dialogIndex].top === false) {
                  const topIndex = _.findIndex(dialogList, o => o.top === true)
                  if (topIndex > -1) {
                    dialogList[topIndex].top = false
                  }

                  dialogList[dialogIndex].top = true

                  // if (this.state.initialZindex) {
                  //     this.setState({ ...this.state, initialZindex: false })
                  // }

                  //this.setState({ ...this.state, top: true, initialZindex: false }, setSplitscreenDialog(dialogList, this.props.splitscreenDialog.show, this.props.splitscreenDialog.splitPosition))

                  setSplitscreenDialog(
                    dialogList,
                    this.props.splitscreenDialog.show,
                    this.props.splitscreenDialog.splitPosition,
                  )
                }
              }
            }}
          >
            {!mini ? (
              <>
                <AppBar
                  position='static'
                  elevation={1}
                  className='dialog-header'
                >
                  <Toolbar
                    className='flex w-full'
                    id='draggable-area'
                    style={{ cursor: 'move' }}
                  >
                    {toolbarChildren}
                  </Toolbar>
                  {appBarChildren}
                </AppBar>
                {this.props.children}
              </>
            ) : (
              <Card
                style={{
                  maxWidth: matches.medium ? 300 : 350,
                }}
                className={classNames(classes.splitDialog)}
              >
                <CardHeader
                  className={classNames(classes.cardHeader)}
                  title={
                    <Typography
                      className='flex'
                      style={{ alignItems: 'center' }}
                    >
                      {this.props.header}
                    </Typography>
                  }
                  avatar={<Icon className='mt-8'>{this.props.icon}</Icon>}
                  action={
                    <React.Fragment>
                      <IconButton
                        onClick={e => this.closeDialog(e)}
                        style={{ color: 'white' }}
                        classes={{ root: 'p-8' }}
                        className={'mt-8 mr-4'}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </React.Fragment>
                  }
                  style={{ padding: 8, cursor: 'move' }}
                  id='draggable-area'
                />
                <CardContent style={{ padding: 0 }}>
                  {image ? (
                    <div className={classNames(classes.cropDiv)}>
                      <img
                        src={image}
                        style={{ height: 'auto', width: '100%' }}
                      />
                    </div>
                  ) : (
                    <div>
                      <p style={{ textAlign: 'center' }}>
                        {this.props.description}
                      </p>
                    </div>
                  )}
                </CardContent>
              </Card>
            )}
          </Dialog>
        </Draggable>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openMinimizedDialog,
      updateAllMinimizedDialogs,
      addSplitscreenDialog,
      showSplitscreenDialog,
      setSplitscreenDialog,
      hideAllSplitscreenDialogs,
      captureSplitscreenDialogs,
    },
    dispatch,
  )
}

function mapStateToProps({
  spReducers,
  minimizedDialogApp,
  splitscreenDialogApp,
}) {
  return {
    Co: spReducers.companies.Co,
    minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
    splitscreenDialogs: splitscreenDialogApp.splitscreenDialog.data,
    splitscreenDialog: splitscreenDialogApp.splitscreenDialog,
    cornered: splitscreenDialogApp.splitscreenDialog.cornered,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(DraggableDialog),
)
