import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedTaxCodeIds: [],
    routeParams       : {},
    taxCodeDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const taxCodesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_TAX_CODES:
        {
            return {
                ...state,
                entities   : _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_TAX_CODES:
        {

            const taxCodeId = action.taxCodeId;

            let selectedTaxCodeIds = [...state.selectedTaxCodeIds];

            if ( selectedTaxCodeIds.find(id => id === taxCodeId) !== undefined )
            {
                selectedTaxCodeIds = selectedTaxCodeIds.filter(id => id !== taxCodeId);
            }
            else
            {
                selectedTaxCodeIds = [...selectedTaxCodeIds, taxCodeId];
            }

            return {
                ...state,
                selectedTaxCodeIds: selectedTaxCodeIds
            };
        }
        case Actions.SELECT_ALL_TAX_CODES:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedTaxCodeIds = arr.map(taxCodes => taxCodes.id);

            return {
                ...state,
                selectedTaxCodeIds: selectedTaxCodeIds
            };
        }
        case Actions.DESELECT_ALL_TAX_CODES:
        {
            return {
                ...state,
                selectedTaxCodeIds: []
            };
        }
        case Actions.OPEN_NEW_TAX_CODE_DIALOG:
        {
            return {
                ...state,
                taxCodeDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_TAX_CODE_DIALOG:
        {
            return {
                ...state,
                taxCodeDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_TAX_CODE_DIALOG:
        {
            return {
                ...state,
                taxCodeDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_TAX_CODE_DIALOG:
        {
            return {
                ...state,
                taxCodeDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default taxCodesReducer;
