import { FusePageCarded } from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import CatalogHeader from 'main/content/apps/catalog/CatalogHeader';
import CatalogLeftSidebarHeader from 'main/content/apps/catalog/CatalogLeftSidebarHeader';
import CatalogList from 'main/content/apps/catalog/CatalogList';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CatalogDialog from './CatalogDialog';
import * as Actions from './store/actions';


const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    }
});

class CustomersApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        const { classes, openNewCatalogDialog, selectedAssembly } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <FusePageCarded
                        classes={{
                            root: "w-full",
                            content: "flex flex-col p-12",
                            header: "items-center min-h-72 h-72 sm:h-136 sm:min-h-136"
                        }}
                        header={
                            <CatalogHeader pageLayout={() => this.pageLayout} />
                        }
                        leftSidebarHeader={
                            <CatalogLeftSidebarHeader />
                        }
                        content={
                            <CatalogList />
                        }
                        // leftSidebarContent={
                        //     <CatalogAssemblyCategoryList />
                        // }
                        sidebarInner
                        onRef={instance => {
                            this.pageLayout = instance;
                        }}
                        innerScroll={true}
                    />
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUserData: Actions.getUserData,
    }, dispatch);
}

function mapStateToProps({ catalogApp }) {
    return {
        selectedAssembly: catalogApp.assembly,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
