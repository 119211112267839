import * as Actions from '../actions';
import _ from '@lodash';

const initialState = null;

const boardReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_BOARD:
            {
                return action.payload
            }
        case Actions.RESET_BOARD:
            {
                return initialState;
            }
        case Actions.ORDER_LIST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: action.payload,
                    }
                };
            }
        case Actions.ORDER_CARD:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: action.payload,
                    }
                };
            }
        case Actions.ADD_LIST:
            {
                const { Lists } = state.Data;
                const List = action.payload;
                List.Data = {
                    Cards: [],
                }
                if (Lists) {
                    Lists.push(List);
                };
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: Lists ? Lists : [List],
                    }
                };
            }
        case Actions.ADD_CARD:
            {
                const card = action.payload;
                card.Data = {
                    Attachments: [],
                    Comments: [],
                    Members: [],
                    Attachments: [],
                    Checklists: [],
                    Tags: [],
                };
                const { Lists } = state.Data;
                const List = _.find(Lists, { ID: card.ListID });
                if (List && List.Data) {
                    (List.Data.Cards || []).push(card);

                } else {
                    // Restored List ID === -1
                    const restoredList = _.find(Lists, { ID: -1 });
                    if (restoredList) {
                        restoredList.Data.Cards.push(card);
                    } else {
                        Lists.push({
                            ID: -1,
                            Name: "Restored",
                            BoardID: state.ID,
                            Co: state.Co,
                            Data: {
                                Cards: [card]
                            },
                            Status: 0
                        })
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists
                    }
                }
            }
        case Actions.ADD_LABEL:
            {
                return {
                    ...state,
                    labels: [
                        ...state.labels,
                        action.payload
                    ]
                };
            }
        case Actions.UPDATE_CARD:
            {
                const { Lists, Cards } = state.Data;
                const { card, clearList } = action.payload
                
                const updatedCard = _.cloneDeep(card); 
                if (clearList) updatedCard.ListID = null;

                // Update Cards
                const index = _.findIndex(Cards, { ID: card.ID });
                if (index > -1) {
                    Cards.splice(index, 1, updatedCard);
                }else {
                    Cards.push(updatedCard);
                }

                // Update Lists           
                const List = _.find(Lists, { ID: card.ListID });
                if (List && List.Data.Cards) {
                    const { Cards } = List.Data;
                    const index = _.findIndex(Cards, { ID: card.ID });
                    if (index > -1) {
                        if (clearList) 
                            Cards.splice(index, 1);
                        else 
                            Cards.splice(index, 1, card);

                    }else {
                        if (!clearList) Cards.push(card);
                    }
                }

                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists,
                        Cards
                    }
                };
            }
        case Actions.REMOVE_CARD:
            {
                const { Lists } = state.Data;
                const List = _.find(Lists, { ID: action.payload.ListID });
                if (List && List.Data.Cards) {
                    const { Cards } = List.Data;
                    for (var i = 0; i < Cards.length; i++) {
                        var card = Cards[i];
                        if (card.ID == action.payload.ID) {
                            Cards.splice(i, 1);
                        }
                    }
                }
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists,
                    }
                };
            }
        case Actions.RENAME_LIST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: state.Data.Lists.map(list => {
                            if (list.ID === action.listId) {
                                list.Name = action.listTitle
                            }
                            return list;
                        })
                    }
                };
            }
        case Actions.REMOVE_LIST:
            {
                return {
                    ...state,
                    Data: {
                        ...state.Data,
                        Lists: _.reject(state.Data.Lists, { ID: action.payload.ID })
                    }
                };
            }
        case Actions.CHANGE_BOARD_SETTINGS:
            {
                return {
                    ...state,
                    settings: action.payload
                };
            }
        case Actions.DELETE_BOARD:
            {
                return initialState;
            }
        case Actions.RENAME_BOARD:
            {
                return {
                    ...state,
                    Name: action.payload.Name,
                };
            }
        default:
            return state;
    }
};

export default boardReducer;
