import _ from '@lodash';
import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Divider, DialogActions, Drawer, FormControlLabel, Icon, IconButton, InputAdornment, MenuItem, Slide, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { bindActionCreators } from 'redux';
import Autocomplete from '../../../components/autocomplete/Autocomplete';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { guid, showMessage } from 'store/actions';
import clsx from 'clsx';
import format from 'string-template';
import moment from 'moment';


const loose = (obj) => {
    return Function('"use strict";return (' + obj + ')')();
}

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'auto',
    },
    dialogRoot: {

    },
    profileAvatar: {
        backgroundColor: theme.palette.primary[500],
        width: 24,
        height: 24,
    },
    paper: {
        margin: 12,
        minHeight: 'calc(100% - 64px)',
        overflowX: 'hidden',
    },
    formControl: {
        marginBottom: 12
    },
    dark: {
        backgroundColor: '#3f3f3f',
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: 'calc(100% - 64px)',
        top: 64,
    },
    drawerOpen: {
        width: 512,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    black: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
        "&:active": {
            backgroundColor: '#4f4f4f',
            color: '#fff',
        },
        "&:hover": {
            backgroundColor: '#444',
            color: '#fff',
        },
        "&:focus": {
            backgroundColor: '#444',
            color: '#fff',
        },
    },
    colorSecondary: {
        color: `${theme.palette.secondary.main} !important`,
    },
    colorError: {
        color: `${theme.palette.error[400]} !important`,
    },
    colorPrimary: {
        color: `${theme.palette.primary.main} !important`,
    },
    colorWhite: {
        color: '#fff !important',
    },
    columnButton: {
        '& .edit': {
            display: 'none',
        },
        '&:hover .edit': {
            display: 'block',
        }
    },
    tile: {
        '& .cell': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
        '& .cell .add-icon': {
            fontSize: 36,
            color: '#f1f1f1',
        },
        '& .cell:hover .add-icon': {
            color: theme.palette.secondary.main
        }
    },
    drawer: {
        width: 0,
        height: 510,
        top: 0,
    },
    drawerOpen: {
        width: 300,
        maxWidth: '100%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
});

const newTileState = {
    layout: null,
    results: [],
    columns: [],
    cells: {
        c1: { cell: 1 },
        c2: { cell: 2 },
        c3: { cell: 3 },
        c4: { cell: 4 },
        c5: { cell: 5 },
        c6: { cell: 6 },
    },
    calculations: [],
    aggregations: [],
    values: {},
    calculation: {},
    aggregation: null,
    cell: null,
}

class Tile extends Component {

    state = {
        ..._.cloneDeepWith(newTileState)
    };

    componentDidMount() {
        const { results, options, filters } = this.props;
        if (options && results) {
            const { Options } = options;
            const tile = this.buildTile(results, Options);
            this.setState({ ..._.cloneDeepWith(newTileState), ...this.state, results, filters, ...tile });
        } else {
            if (results) {
                const columns = this.getColumns(results);
                this.setState({ ..._.cloneDeepWith(newTileState), ...this.state, results, filters, columns });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { results, options, filters } = this.props;
        if (!_.isEqual(results, prevProps.results) || !_.isEqual(options, prevProps.options) || !_.isEqual(filters, prevProps.filters)) {
            if (options && results) {
                const { Options } = options;
                const tile = this.buildTile(results, Options);
                this.setState({ ..._.cloneDeepWith(newTileState), ...this.state, results, filters, ...tile });
            } else {
                if (results) {
                    const columns = this.getColumns(results);
                    this.setState({ ..._.cloneDeepWith(newTileState), ...this.state, results, filters, columns });
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState({ ..._.cloneDeepWith(newTileState) });
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    buildTile = (data, options) => {
        const Options = JSON.parse(options);
        const { aggregations, calculations, cells, layout } = Options;
        const columns = this.getColumns(data);
        const values = this.getValues(data, aggregations, calculations);
        return {
            columns,
            calculations,
            aggregations,
            cells,
            values,
            layout
        }
    }

    getValue = (aggregation) => {
        const { results, aggregations, values, filters } = _.cloneDeepWith(this.state);
        const { column } = aggregation;
        const filtered = _.filter(results, { ...filters });
        let value;
        if (aggregation.aggregation === 'countBy') {
            value = _.uniqBy(filtered, column).length;
        } else {
            value = _[aggregation.aggregation](filtered, column);
        }
        return value;

    }

    getValues(results, aggregations, calculations) {
        const values = {};
        const { filters } = _.cloneDeepWith(this.state);
        const filtered = _.filter(results, { ...filters });
        // window["warn"]('Tile Filters: ', filters, filtered)
        aggregations.map((aggregation) => {
            const { id, column } = aggregation;
            if (aggregation.aggregation === 'countBy') {
                aggregation.value = _.uniqBy(filtered, (o) => { if (o[column]) { return o[column] } }).length;
            } else {
                aggregation.value = _[aggregation.aggregation](filtered, column);
            }
            window["warn"]('Aggregation value: ', aggregation.value);
            values[id] = aggregation.value;
        });
        calculations.map((calculation) => {
            const { id, expression } = calculation;
            calculation.value = loose(`${format(calculation.expression, values)}`);
            values[id] = calculation.value;
        });
        return (aggregations, calculations, values);
    }

    saveTile = () => {
        const { aggregations, calculations, cells, layout } = _.cloneDeepWith(this.state);

        const options = {
            layout,
            aggregations,
            calculations,
            cells
        };

        if (this.props.onAdded) {
            if (!this.props.options || !this.props.options.ID || !this.props.options.Component) {
                this.props.onAdded(JSON.stringify(options));
            }
        }

        if (this.props.onUpdated) {
            if (this.props.options && this.props.options.ID && this.props.options.Component) {
                this.props.onUpdated(JSON.stringify(options));
            }
        }
    }

    getColumns(data) {
        const columns = Object.keys(data[0]).map(key => {
            return {
                Header: key,
                headerClassName: 'font-bold text-12',
                accessor: key,
                id: key,
                dataType: !isNaN(data[0][key]) ? 'number' : moment(data[0][key]).isValid() ? 'date' : 'text'
            };
        });
        return columns;
    }

    formatNumber = (num) => {
        return Intl.NumberFormat('en-US').format(num);
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    formatPercentage = (num) => {
        if (!isNaN(num)) {
            return `${Number(num).toFixed(2)}%`
        } else {
            return num;
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    addUpdateCalculation = (calculation) => {
        const { calculations, values } = _.cloneDeepWith(this.state);
        calculation.id = calculation.id || guid();
        const { id, expression } = calculation;
        const exp = `${format(expression, values)}`;
        window["warn"](exp, expression, values);
        calculation.value = loose(exp);
        values[id] = calculation.value;
        const index = _.findIndex(calculations, { id });
        if (index > -1) {
            calculations.splice(index, 1, calculation);
        } else {
            calculations.push(calculation);
        }
        this.setState({ ...this.state, calculations, calculation: {}, values });
    }

    addUpdateAggregation = (aggregation) => {
        const { values, aggregations } = _.cloneDeepWith(this.state);
        const { column } = aggregation;
        if (!aggregation.id) {
            aggregation.id = `${column}${aggregation.aggregation ? aggregation.aggregation.replace('By', '').toUpperCase() : ''}`;
        }
        const { id } = aggregation;
        let value = this.getValue(aggregation);
        if (value && isNaN(value)) {
            value = value[column];
        }
        aggregation.value = Number(value).toFixed(2);
        values[id] = aggregation.value;
        const index = _.findIndex(aggregations, { id });
        if (index > -1) {
            aggregations.splice(index, 1, aggregation);
        } else {
            aggregations.push(aggregation);
        }
        this.setState({ ...this.state, aggregations, aggregation: null, values });
    }

    removeCalculation = (calculation) => {
        const { calculations } = _.cloneDeepWith(this.state);
        const { id } = calculation;
        const index = _.findIndex(calculations, { id });
        if (index > -1) {
            calculations.splice(index, 1);
            this.setState({ ...this.state, calculations });
        }
    }

    removeAggregation = (aggregation) => {
        const { aggregations } = _.cloneDeepWith(this.state);
        const { id } = aggregation;
        const index = _.findIndex(aggregations, { id });
        if (index > -1) {
            aggregations.splice(index, 1);
            this.setState({ ...this.state, aggregations });
        }
    }

    buildCell = (cell, size) => {
        const { edit } = this.props;
        const { title, format } = cell;
        const { aggregations, calculations, results } = _.cloneDeepWith(this.state);
        const values = this.getValues(results, aggregations, calculations);
        const value = values[cell.value];
        // window["warn"](cell, values, value);
        if (value && title) {
            if (format === "P") {
                return (
                    <React.Fragment>
                        <div className="w-full relative" style={{ paddingTop: '100%', transform: 'translate(0, 0%)' }}>
                            <CircularProgress variant="static" style={{ width: '80%', margin: '10%', height: 'unset', color: '#f1f1f1' }} className="pin-l pin-r pin-t pin-b absolute" value={100} />
                            <CircularProgress variant="static" style={{ width: '80%', margin: '10%', height: 'unset' }} className="pin-l pin-r pin-t pin-b absolute" value={value > 0 ? value <= 100 ? Math.round(value, 0) : 100 : 0} />
                        </div>
                        <div className={`pin-l pin-r pin-t pin-b absolute m-auto ${size === 'S' ? 'mb-8' : size === 'M' ? 'mb-12' : 'mb-16'}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="caption" component="div" color="textSecondary" className={size === 'S' ? "text-16" : size === 'M' ? "text-24" : "text-32"}>{`${Math.round(value, 0)}%`}</Typography>
                        </div>
                        <div className={`pin-l pin-r pin-t pin-b absolute m-auto ${size === 'S' ? 'mt-24' : size === 'M' ? 'mt-36' : 'mt-48'}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="caption" component="div" color="textSecondary" className={size === 'S' ? "text-10" : size === 'M' ? "text-12" : "text-16"}>{title}</Typography>
                        </div>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <div className={`pin-l pin-r pin-t pin-b absolute m-auto ${size === 'S' ? 'mb-8' : size === 'M' ? 'mb-12' : 'mb-16'}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="caption" component="div" color="textSecondary" className={classNames("font-bold", size === 'S' ? "text-16" : size === 'M' ? "text-24" : "text-32")}>{`${format === 'D' ? `$${this.formatNumber(Math.round(value, 0))}` : this.formatNumber(Math.round(value, 0))}`}</Typography>
                        </div>
                        <div className={`pin-l pin-r pin-t pin-b absolute m-auto ${size === 'S' ? 'mt-24' : size === 'M' ? 'mt-36' : 'mt-48'}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="caption" component="div" color="textSecondary" className={size === 'S' ? "text-10" : size === 'M' ? "text-12" : "text-16"}>{title}</Typography>
                        </div>
                    </React.Fragment>
                );
            }
        } else {
            return (
                edit ? <Icon className="pin-l pin-r pin-t pin-b absolute m-auto add-icon">add_circle_outline</Icon> : ''
            );
        }
    }

    render() {
        const { classes, edit, dialog } = this.props;
        const { results, columns, layout, cells, cell, aggregations, aggregation, calculations, calculation } = this.state;
        return (
            <React.Fragment>
                {edit &&
                    <div className="w-full">
                        <div className="w-full sm:flex">
                            <div className="w-full mb-12">
                                <Typography className="mb-4 w-full font-bold text-12">
                                    <Icon color="primary" className="mr-4 align-middle mb-4 text-16">border_all</Icon>Layout
                                </Typography>
                                <div className="w-full overflow-x-auto rounded-6 mb-24 p-12 flex" style={{ border: '1px solid lightgrey', backgroundColor: '#f1f1f1' }}>

                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '1' })} style={{ backgroundColor: layout === '1' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-24 h-24 m-6 rounded-2">
                                            <div className="flex">
                                                <div className="w-full h-24">

                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '2' })} style={{ backgroundColor: layout === '2' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-48 h-24 m-6 rounded-2">
                                            <div className="flex">
                                                <div className="w-full h-24" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                </div>
                                                <div className="w-full h-24">

                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '3' })} style={{ backgroundColor: layout === '3' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-72 h-24 m-6 rounded-2">
                                            <div className="flex">
                                                <div className="w-full h-24" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                </div>
                                                <div className="w-full h-24">

                                                </div>
                                                <div className="w-full h-24" style={{ borderLeft: '1px solid #f1f1f1' }}>

                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '1/1' })} style={{ backgroundColor: layout === '1/1' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-24 h-48 m-6 rounded-2">
                                            <div className="w-full h-48">
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-24">

                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-24">

                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '2/2' })} style={{ backgroundColor: layout === '2/2' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-48 h-48 m-6 rounded-2">
                                            <div className="w-full h-48">
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-24" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                    </div>
                                                    <div className="w-full h-24">

                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-24" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                    </div>
                                                    <div className="w-full h-24">

                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '3/3' })} style={{ backgroundColor: layout === '3/3' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-72 h-48 m-6 rounded-2">
                                            <div className="w-full h-48">
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-24" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                    </div>
                                                    <div className="w-full h-24">

                                                    </div>
                                                    <div className="w-full h-24" style={{ borderLeft: '1px solid #f1f1f1' }}>

                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-24" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                    </div>
                                                    <div className="w-full h-24">

                                                    </div>
                                                    <div className="w-full h-24" style={{ borderLeft: '1px solid #f1f1f1' }}>

                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '1/2' })} style={{ backgroundColor: layout === '1/2' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-48 h-72 m-6 rounded-2">
                                            <div className="w-full h-72">
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-48">
                                                        <div className="relative w-full">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-24" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                    </div>
                                                    <div className="w-full h-24">

                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '1|1/1' })} style={{ backgroundColor: layout === '1|1/1' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-72 h-48 m-6 rounded-2">
                                            <div className="w-full h-48 flex">
                                                <div className="w-48 h-48" style={{ borderRight: '1px solid #f1f1f1' }}>

                                                </div>
                                                <div className="w-24">
                                                    <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '1/1|1' })} style={{ backgroundColor: layout === '1/1|1' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-72 h-48 m-6 rounded-2">
                                            <div className="w-full h-48 flex">
                                                <div className="w-24" style={{ borderRight: '1px solid #f1f1f1' }}>
                                                    <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-48 h-48">

                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="p-6 float-left cursor-pointer" onClick={() => this.setState({ ...this.state, layout: '1/1|1|1/1' })} style={{ backgroundColor: layout === '1/1|1|1/1' ? 'lightgrey' : 'transparent' }}>
                                        <Card className="w-96 h-48 m-6 rounded-2">
                                            <div className="w-full h-48 flex">
                                                <div className="w-24" style={{ borderRight: '1px solid #f1f1f1' }}>
                                                    <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-48 h-48">

                                                </div>
                                                <div className="w-24" style={{ borderLeft: '1px solid #f1f1f1' }}>
                                                    <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-full h-24">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {edit && layout &&
                            <div className={classNames(classes.tile, "w-full relative h-512 rounded-6 overflow-auto")} style={{ border: '1px solid lightgrey', backgroundColor: '#f1f1f1' }} onClick={() => this.setState({ ...this.state, cell: null })}>
                                <Drawer
                                    anchor="right"
                                    variant="permanent"
                                    className={clsx(classNames(classes.drawer, "pin-r absolute"), {
                                        [classes.drawerOpen]: Boolean(cell),
                                        [classes.drawerClose]: !Boolean(cell)
                                    })}
                                    classes={{
                                        paper: clsx("relative border-none h-full", {
                                            [classes.drawerOpen]: Boolean(cell),
                                            [classes.drawerClose]: !Boolean(cell)
                                        })
                                    }}
                                    open={Boolean(cell)}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <div tabIndex={0}>
                                        {cell &&
                                            <div className="p-12 relative">
                                                <Typography className="mb-4 w-full font-bold text-12">
                                                    <Icon color="primary" className="mr-4 align-middle mb-6 text-16">insert_chart</Icon>{`Cell #${cell.cell} Content`}
                                                </Typography>
                                                <IconButton onClick={() => this.setState({ ...this.state, cell: null })} className="pin-r pin-t absolute mt-4 mr-8 p-4">
                                                    <Icon>close</Icon>
                                                </IconButton>
                                                <div className="w-full">
                                                    <Autocomplete
                                                        className="w-full mb-12"
                                                        title="Column"
                                                        options={[...aggregations, ...calculations]}
                                                        menuItemComponent={(option) => {
                                                            return (
                                                                <MenuItem value={option.id}>
                                                                    <div className="flex">
                                                                        {option.title}
                                                                    </div>
                                                                </MenuItem>
                                                            );
                                                        }}
                                                        portal={true}
                                                        value={cell ? cell.value : null}
                                                        onSelect={(option) => { const { id, title, format } = option; cell.value = id; cell.title = title; cell.format = format; this.setState({ ...this.state, cell, cells }) }}
                                                    />
                                                    <TextField
                                                        className={classes.formControl}
                                                        label="Title"
                                                        id="title"
                                                        name="cell.title"
                                                        value={cell.title || ''}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            autoComplete: "off"
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        className={classes.formControl}
                                                        label="Format"
                                                        id="format"
                                                        name="cell.format"
                                                        value={cell.format || ''}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        select
                                                    >
                                                        <MenuItem value={null}>
                                                            None
                                                        </MenuItem>
                                                        <MenuItem value="P">
                                                            Percentage
                                                        </MenuItem>
                                                        <MenuItem value="D">
                                                            Dollars
                                                        </MenuItem>
                                                    </TextField>
                                                </div>
                                                <Divider className="mb-12" />
                                                <Typography className="mb-4 w-full font-bold text-12">
                                                    <Icon color="primary" className="mr-4 align-middle mb-6 text-16">import_export</Icon>Aggregations
                                                </Typography>
                                                <div className="w-full">
                                                    <Autocomplete
                                                        className="w-full mb-12"
                                                        title="Column"
                                                        options={columns}
                                                        menuItemComponent={(option) => {
                                                            return (
                                                                <MenuItem value={option.id}>
                                                                    <div className="flex">
                                                                        {option.Header}
                                                                    </div>
                                                                </MenuItem>
                                                            );
                                                        }}
                                                        portal={true}
                                                        value={aggregation ? aggregation.column : null}
                                                        onSelect={(option) => {
                                                            if (!aggregation) {
                                                                this.addUpdateAggregation({ column: option.id, title: option.Header, aggregation: option.dataType === 'number' ? 'sumBy' : 'countBy' });
                                                            } else {
                                                                aggregation.column = option.id;
                                                                this.setState({ ...this.state, aggregation });
                                                            }
                                                        }}
                                                    />
                                                    {
                                                        aggregation &&
                                                        <React.Fragment>
                                                            <TextField
                                                                className={classes.formControl}
                                                                label="Title"
                                                                id="title"
                                                                name="aggregation.title"
                                                                value={aggregation.title || ''}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                inputProps={{
                                                                    autoComplete: "off"
                                                                }}
                                                                fullWidth
                                                            />
                                                            <TextField
                                                                className={classes.formControl}
                                                                label="Aggregation Type"
                                                                id="aggregation"
                                                                name="aggregation.aggregation"
                                                                value={aggregation.aggregation || ''}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                select
                                                            >
                                                                <MenuItem value="countBy">
                                                                    Count
                                                                </MenuItem>
                                                                <MenuItem value="sumBy">
                                                                    Sum
                                                                </MenuItem>
                                                                <MenuItem value="meanBy">
                                                                    Average
                                                                </MenuItem>
                                                                <MenuItem value="minBy">
                                                                    Minimum
                                                                </MenuItem>
                                                                <MenuItem value="maxBy">
                                                                    Maximum
                                                                </MenuItem>
                                                            </TextField>
                                                            <TextField
                                                                className={classes.formControl}
                                                                label="Format"
                                                                id="format"
                                                                name="aggregation.format"
                                                                value={aggregation.format || ''}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                select
                                                            >
                                                                <MenuItem value={null}>
                                                                    Default
                                                                </MenuItem>
                                                                <MenuItem value="P">
                                                                    Percentage
                                                                </MenuItem>
                                                                <MenuItem value="D">
                                                                    Dollars
                                                                </MenuItem>
                                                            </TextField>
                                                            {_.findIndex(aggregations, { id: aggregation.id }) > -1 ?
                                                                <Button
                                                                    variant="contained"
                                                                    className={classNames(classes.black, "mb-12")}
                                                                    color="primary"
                                                                    onClick={() => this.addUpdateAggregation(aggregation)}
                                                                    fullWidth
                                                                    disabled={!aggregation.column || !aggregation.title || !aggregation.aggregation}
                                                                >
                                                                    Update
                                                                </Button>
                                                                :
                                                                <Button
                                                                    variant="contained"
                                                                    className={classNames(classes.black, "mb-12")}
                                                                    color="primary"
                                                                    onClick={() => this.addUpdateAggregation(aggregation)}
                                                                    fullWidth
                                                                    disabled={!aggregation.column || !aggregation.title || !aggregation.aggregation}
                                                                >
                                                                    Add
                                                                </Button>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    {aggregations.map((value) => {
                                                        return (
                                                            <Tooltip key={value.id} placement="top" title={`Value=${value.value}`}>
                                                                <Chip
                                                                    deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>}
                                                                    onDelete={() => this.removeAggregation(value)}
                                                                    onClick={() => {
                                                                        if (calculation && calculation.title && calculation.title.length > 0) {
                                                                            let { expression } = calculation;
                                                                            if (expression) {
                                                                                expression += `{${value.id}}`;
                                                                            } else {
                                                                                expression = `{${value.id}}`;
                                                                            }
                                                                            this.setState({ ...this.state, calculation: { ...calculation, expression } });
                                                                        } else {
                                                                            this.setState({ ...this.state, aggregation: { ...value } })
                                                                        }
                                                                    }}
                                                                    className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")}
                                                                    icon={<Icon className={classNames(classes.colorSecondary, "text-16")}>import_export</Icon>}
                                                                    label={<span>{value.title}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>}
                                                                />
                                                            </Tooltip>
                                                        );
                                                    })}
                                                </div>
                                                <Divider className="mb-12" />
                                                <Typography className="mb-4 w-full font-bold text-12">
                                                    <Icon color="primary" className="mr-4 align-middle mb-6 text-16">functions</Icon>Calculations
                                                </Typography>
                                                <div className="w-full">
                                                    <TextField
                                                        className={classes.formControl}
                                                        label="Title"
                                                        id="title"
                                                        name="calculation.title"
                                                        value={calculation.title || ''}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            autoComplete: "off"
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    {calculation.title && calculation.title.length > 0 &&
                                                        <React.Fragment>
                                                            <TextField
                                                                className={classes.formControl}
                                                                label="Expression"
                                                                id="expression"
                                                                name="calculation.expression"
                                                                value={calculation.expression || ''}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">=</InputAdornment>,
                                                                }}
                                                                inputProps={{
                                                                    autoComplete: "off"
                                                                }}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                multiline
                                                                fullWidth
                                                            />
                                                            <TextField
                                                                className={classes.formControl}
                                                                label="Format"
                                                                id="format"
                                                                name="calculation.format"
                                                                value={calculation.format || ''}
                                                                onChange={this.handleChange}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                select
                                                            >
                                                                <MenuItem value={null}>
                                                                    None
                                                                </MenuItem>
                                                                <MenuItem value="P">
                                                                    Percentage
                                                                </MenuItem>
                                                                <MenuItem value="D">
                                                                    Dollars
                                                                </MenuItem>
                                                            </TextField>
                                                            {!calculation.id ?
                                                                <Button
                                                                    variant="contained"
                                                                    className={classNames(classes.black, "mb-12")}
                                                                    color="primary"
                                                                    onClick={() => this.addUpdateCalculation(calculation)}
                                                                    fullWidth
                                                                    disabled={!calculation.expression || !calculation.title}
                                                                >
                                                                    Add
                                                                </Button> :
                                                                <Button
                                                                    variant="contained"
                                                                    className={classNames(classes.black, "mb-12")}
                                                                    color="primary"
                                                                    onClick={() => this.addUpdateCalculation(calculation)}
                                                                    fullWidth
                                                                    disabled={!calculation.expression || !calculation.title}
                                                                >
                                                                    Update
                                                                </Button>
                                                            }

                                                        </React.Fragment>
                                                    }
                                                    {calculations.map((value) => {
                                                        return (
                                                            <Tooltip key={value.id} placement="top" title={`Value=${value.value}`}>
                                                                <Chip deleteIcon={<Icon className={classNames(classes.colorError, "pin-r absolute")}>delete</Icon>} onDelete={() => this.removeCalculation(value)} onClick={() => this.setState({ ...this.state, calculation: { ...value } })} className={classNames(classes.black, classes.columnButton, "mb-8 w-full rounded-6 justify-start relative")} icon={<Icon className={classNames(classes.colorSecondary, "text-16")}>functions</Icon>} label={<span>{value.title}<Icon className={classNames(classes.colorSecondary, "edit text-12 ml-6 mt-4 float-right")}>edit</Icon></span>} />
                                                            </Tooltip>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Drawer>
                                <Card
                                    onClick={(e) => e.stopPropagation()}
                                    className="pin-l pin-r pin-t pin-b absolute m-auto rounded-6"
                                    style={
                                        {
                                            width:
                                                ['1', '1/1'].indexOf(layout) > -1 ? 128 :
                                                    ['2', '2/2', '1/2'].indexOf(layout) > -1 ? 256 :
                                                        ['3', '3/3', '1|1/1', '1/1|1'].indexOf(layout) > -1 ? 384 : 512,
                                            height:
                                                ['1', '2', '3'].indexOf(layout) > -1 ? 164 :
                                                    ['1/2'].indexOf(layout) > -1 ? 420 : 292
                                        }
                                    }>
                                    {this.props.title &&
                                        <React.Fragment>
                                            <Typography className="w-full font-bold text-12 cursor-pointer p-6 pb-0 truncate pr-36" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                <Icon color="secondary" className="mr-4 align-middle mb-4">insert_chart</Icon>
                                                {this.props.title}
                                            </Typography>
                                            <IconButton
                                                style={{
                                                    position: 'absolute',
                                                    right: 7,
                                                    top: 7,
                                                }}
                                                className="p-0"
                                                onClick={() => {
                                                    // this.closeDialog();
                                                }}
                                            >
                                                <Icon>more_vert</Icon>
                                            </IconButton>
                                        </React.Fragment>
                                    }
                                    {layout === '1' &&
                                        <div className="flex">
                                            <div onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} className="w-full h-128 relative cell">
                                                {this.buildCell(cells.c1, 'S')}
                                            </div>
                                        </div>
                                    }
                                    {layout === '2' &&
                                        <div className="flex">
                                            <div onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} className="w-full h-128 relative cell" style={{ borderRight: '1px solid #f1f1f1' }}>
                                                {this.buildCell(cells.c1, 'S')}
                                            </div>
                                            <div onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })} className="w-full h-128 relative cell">
                                                {this.buildCell(cells.c2, 'S')}
                                            </div>
                                        </div>
                                    }
                                    {layout === '3' &&
                                        <div className="flex">
                                            <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                                {this.buildCell(cells.c1, 'S')}
                                            </div>
                                            <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                                {this.buildCell(cells.c2, 'S')}
                                            </div>
                                            <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })} style={{ borderLeft: '1px solid #f1f1f1' }}>
                                                {this.buildCell(cells.c3, 'S')}
                                            </div>
                                        </div>
                                    }
                                    {layout === '1/1' &&
                                        <div className="w-full h-256">
                                            <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                                    {this.buildCell(cells.c1, 'S')}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                                    {this.buildCell(cells.c2, 'S')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {layout === '1/2' &&
                                        <div className="w-full h-384">
                                            <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                <div className="w-full h-256 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                                    {this.buildCell(cells.c1, 'M')}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                                    {this.buildCell(cells.c2, 'S')}
                                                </div>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                                    {this.buildCell(cells.c3, 'S')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {layout === '2/2' &&
                                        <div className="w-256 h-256">
                                            <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                                    {this.buildCell(cells.c1, 'S')}
                                                </div>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                                    {this.buildCell(cells.c2, 'S')}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                                    {this.buildCell(cells.c3, 'S')}
                                                </div>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c4 }) })}>
                                                    {this.buildCell(cells.c4, 'S')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {layout === '3/3' &&
                                        <div className="w-384 h-256">
                                            <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                                    {this.buildCell(cells.c1, 'S')}
                                                </div>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                                    {this.buildCell(cells.c2, 'S')}
                                                </div>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })} style={{ borderLeft: '1px solid #f1f1f1' }}>
                                                    {this.buildCell(cells.c3, 'S')}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c4 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                                    {this.buildCell(cells.c4, 'S')}
                                                </div>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c5 }) })}>
                                                    {this.buildCell(cells.c5, 'S')}
                                                </div>
                                                <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c6 }) })} style={{ borderLeft: '1px solid #f1f1f1' }}>
                                                    {this.buildCell(cells.c6, 'S')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {layout === '1|1/1' &&
                                        <div className="w-full h-256 flex">
                                            <div className="w-256 h-256 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                                {this.buildCell(cells.c1, 'M')}
                                            </div>
                                            <div className="w-128">
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                                        {this.buildCell(cells.c2, 'S')}
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                                        {this.buildCell(cells.c3, 'S')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {layout === '1/1|1' &&
                                        <div className="w-full h-256 flex">
                                            <div className="w-128" style={{ borderRight: '1px solid #f1f1f1' }}>
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                                        {this.buildCell(cells.c1, 'S')}
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                                        {this.buildCell(cells.c3, 'S')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-256 h-256 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                                {this.buildCell(cells.c2, 'M')}
                                            </div>
                                        </div>
                                    }
                                    {layout === '1/1|1|1/1' &&
                                        <div className="w-full h-256 flex">
                                            <div className="w-128" style={{ borderRight: '1px solid #f1f1f1' }}>
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                                        {this.buildCell(cells.c1, 'S')}
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c4 }) })}>
                                                        {this.buildCell(cells.c4, 'S')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-256 h-256 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                                {this.buildCell(cells.c2, 'M')}
                                            </div>
                                            <div className="w-128" style={{ borderLeft: '1px solid #f1f1f1' }}>
                                                <div className="flex" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                                        {this.buildCell(cells.c3, 'S')}
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-full h-128 relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c5 }) })}>
                                                        {this.buildCell(cells.c5, 'S')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Card>
                            </div>
                        }
                    </div>
                }
                {edit &&
                    <DialogActions className="dialog-actions justify-start p-0 m-0 mt-12">
                        {!this.props.options || !this.props.options.ID || !this.props.options.Component ?
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.saveTile}
                            >
                                Add
                            </Button> :
                            <Button
                                className="m-0"
                                variant="contained"
                                color="primary"
                                onClick={this.saveTile}
                            >
                                Update
                            </Button>
                        }
                    </DialogActions>
                }
                {!edit && layout &&
                    <React.Fragment>
                        {layout === '1' &&
                            <div className={classNames("flex", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} className="w-full h-full relative cell">
                                    {this.buildCell(cells.c1, 'S')}
                                </div>
                            </div>
                        }
                        {layout === '2' &&
                            <div className={classNames("flex", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} className="w-full h-full relative cell" style={{ borderRight: '1px solid #f1f1f1' }}>
                                    {this.buildCell(cells.c1, 'S')}
                                </div>
                                <div onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })} className="w-full h-full relative cell">
                                    {this.buildCell(cells.c2, 'S')}
                                </div>
                            </div>
                        }
                        {layout === '3' &&
                            <div className={classNames("flex", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                    {this.buildCell(cells.c1, 'S')}
                                </div>
                                <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                    {this.buildCell(cells.c2, 'S')}
                                </div>
                                <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })} style={{ borderLeft: '1px solid #f1f1f1' }}>
                                    {this.buildCell(cells.c3, 'S')}
                                </div>
                            </div>
                        }
                        {layout === '1/1' &&
                            <div className={classNames("w-full", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="flex h-1/2" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                        {this.buildCell(cells.c1, 'S')}
                                    </div>
                                </div>
                                <div className="flex h-1/2">
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                        {this.buildCell(cells.c2, 'S')}
                                    </div>
                                </div>
                            </div>
                        }
                        {layout === '1/2' &&
                            <div className={classNames("w-ful", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="flex h-2/3" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                        {this.buildCell(cells.c1, 'M')}
                                    </div>
                                </div>
                                <div className="flex h-1/3">
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                        {this.buildCell(cells.c2, 'S')}
                                    </div>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                        {this.buildCell(cells.c3, 'S')}
                                    </div>
                                </div>
                            </div>
                        }
                        {layout === '2/2' &&
                            <div className={classNames("w-full", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="flex h-1/2" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                        {this.buildCell(cells.c1, 'S')}
                                    </div>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                        {this.buildCell(cells.c2, 'S')}
                                    </div>
                                </div>
                                <div className="flex h-1/2">
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                        {this.buildCell(cells.c3, 'S')}
                                    </div>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c4 }) })}>
                                        {this.buildCell(cells.c4, 'S')}
                                    </div>
                                </div>
                            </div>
                        }
                        {layout === '3/3' &&
                            <div className={classNames("w-full", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="flex h-1/2" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                        {this.buildCell(cells.c1, 'S')}
                                    </div>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                        {this.buildCell(cells.c2, 'S')}
                                    </div>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })} style={{ borderLeft: '1px solid #f1f1f1' }}>
                                        {this.buildCell(cells.c3, 'S')}
                                    </div>
                                </div>
                                <div className="flex h-1/2">
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c4 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                        {this.buildCell(cells.c4, 'S')}
                                    </div>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c5 }) })}>
                                        {this.buildCell(cells.c5, 'S')}
                                    </div>
                                    <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c6 }) })} style={{ borderLeft: '1px solid #f1f1f1' }}>
                                        {this.buildCell(cells.c6, 'S')}
                                    </div>
                                </div>
                            </div>
                        }
                        {layout === '1|1/1' &&
                            <div className={classNames("w-full flex", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="w-2/3 h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })} style={{ borderRight: '1px solid #f1f1f1' }}>
                                    {this.buildCell(cells.c1, 'M')}
                                </div>
                                <div className="w-1/3 h-full">
                                    <div className="flex h-1/2" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                            {this.buildCell(cells.c2, 'S')}
                                        </div>
                                    </div>
                                    <div className="flex h-1/2">
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                            {this.buildCell(cells.c3, 'S')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {layout === '1/1|1' &&
                            <div className={classNames("w-full flex", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="w-1/3 h-full" style={{ borderRight: '1px solid #f1f1f1' }}>
                                    <div className="flex w-full h-1/2" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                            {this.buildCell(cells.c1, 'S')}
                                        </div>
                                    </div>
                                    <div className="flex w-full h-1/2">
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                            {this.buildCell(cells.c3, 'S')}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-2/3 h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                    {this.buildCell(cells.c2, 'M')}
                                </div>
                            </div>
                        }
                        {layout === '1/1|1|1/1' &&
                            <div className={classNames("w-full flex", classes.tile)} style={{ height: 'calc(100% - 32px)' }}>
                                <div className="w-1/4 h-full" style={{ borderRight: '1px solid #f1f1f1' }}>
                                    <div className="flex w-full h-1/2" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c1 }) })}>
                                            {this.buildCell(cells.c1, 'S')}
                                        </div>
                                    </div>
                                    <div className="flex w-full h-1/2">
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c4 }) })}>
                                            {this.buildCell(cells.c4, 'S')}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c2 }) })}>
                                    {this.buildCell(cells.c2, 'M')}
                                </div>
                                <div className="w-1/4 h-full" style={{ borderLeft: '1px solid #f1f1f1' }}>
                                    <div className="flex w-full h-1/2" style={{ borderBottom: '1px solid #f1f1f1' }}>
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c3 }) })}>
                                            {this.buildCell(cells.c3, 'S')}
                                        </div>
                                    </div>
                                    <div className="flex w-full h-1/2">
                                        <div className="w-full h-full relative cell" onClick={() => this.setState({ ...this.setState({ ...this.state, cell: cells.c5 }) })}>
                                            {this.buildCell(cells.c5, 'S')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage
    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        technicians: spReducers.technicians,
        users: spReducers.userProfiles.Users,
        employees: spReducers.employees,
        filters: dashboardBuilderApp.dashboard.filters
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Tile)));
