import axios from 'axios';
import { getUserData } from 'main/content/apps/forms/store/actions/user.actions';
import { showMessage } from 'store/actions';
import _ from '@lodash';

export const SET_SEARCH_TEXT = '[FORMS APP] SET SEARCH TEXT';
export const OPEN_NEW_FORM_DIALOG = '[FORMS APP] OPEN NEW FORM DIALOG';
export const CLOSE_NEW_FORM_DIALOG = '[FORMS APP] CLOSE NEW FORM DIALOG';
export const OPEN_EDIT_FORM_DIALOG = '[FORMS APP] OPEN EDIT FORM DIALOG';
export const ADD_EDIT_FORM_DIALOG = '[FORMS APP] ADD EDIT FORM DIALOG';
export const UPDATE_EDIT_FORM_DIALOG = '[FORMS APP] UPDATE EDIT FORM DIALOG';
export const DATA_EDIT_FORM_DIALOG = '[FORMS APP] DATA EDIT FORM DIALOG';
export const CLOSE_EDIT_FORM_DIALOG = '[FORMS APP] CLOSE EDIT FORM DIALOG';
export const OPEN_NEW_FORM_SECTION_DIALOG = '[FORMS APP] OPEN NEW FORM SECTION DIALOG';
export const CLOSE_NEW_FORM_SECTION_DIALOG = '[FORMS APP] CLOSE NEW FORM SECTION DIALOG';
export const OPEN_EDIT_FORM_SECTION_DIALOG = '[FORMS APP] OPEN EDIT FORM SECTION DIALOG';
export const ADD_EDIT_FORM_SECTION_DIALOG = '[FORMS APP] ADD EDIT FORM SECTION DIALOG';
export const UPDATE_EDIT_FORM_SECTION_DIALOG = '[FORMS APP] UPDATE EDIT FORM SECTION DIALOG';
export const DATA_EDIT_FORM_SECTION_DIALOG = '[FORMS APP] DATA EDIT FORM SECTION DIALOG';
export const CLOSE_EDIT_FORM_SECTION_DIALOG = '[FORMS APP] CLOSE EDIT FORM SECTION DIALOG';
export const OPEN_NEW_FORM_ELEMENT_DIALOG = '[FORMS APP] OPEN NEW FORM ELEMENT DIALOG';
export const CLOSE_NEW_FORM_ELEMENT_DIALOG = '[FORMS APP] CLOSE NEW FORM ELEMENT DIALOG';
export const OPEN_EDIT_FORM_ELEMENT_DIALOG = '[FORMS APP] OPEN EDIT FORM ELEMENT DIALOG';
export const ADD_EDIT_FORM_ELEMENT_DIALOG = '[FORMS APP] ADD EDIT FORM ELEMENT DIALOG';
export const UPDATE_EDIT_FORM_ELEMENT_DIALOG = '[FORMS APP] UPDATE EDIT FORM ELEMENT DIALOG';
export const DATA_EDIT_FORM_ELEMENT_DIALOG = '[FORMS APP] DATA EDIT FORM ELEMENT DIALOG';
export const CLOSE_EDIT_FORM_ELEMENT_DIALOG = '[FORMS APP] CLOSE EDIT FORM ELEMENT DIALOG';
export const OPEN_NEW_FORM_ASSIGNMENT_DIALOG = '[FORMS APP] OPEN NEW FORM ASSIGNMENT DIALOG';
export const CLOSE_NEW_FORM_ASSIGNMENT_DIALOG = '[FORMS APP] CLOSE NEW FORM ASSIGNMENT DIALOG';
export const OPEN_EDIT_FORM_ASSIGNMENT_DIALOG = '[FORMS APP] OPEN EDIT FORM ASSIGNMENT DIALOG';
export const ADD_EDIT_FORM_ASSIGNMENT_DIALOG = '[FORMS APP] ADD EDIT FORM ASSIGNMENT DIALOG';
export const UPDATE_EDIT_FORM_ASSIGNMENT_DIALOG = '[FORMS APP] UPDATE EDIT FORM ASSIGNMENT DIALOG';
export const DATA_EDIT_FORM_ASSIGNMENT_DIALOG = '[FORMS APP] DATA EDIT FORM ASSIGNMENT DIALOG';
export const CLOSE_EDIT_FORM_ASSIGNMENT_DIALOG = '[FORMS APP] CLOSE EDIT FORM ASSIGNMENT DIALOG';
export const ADD_FORM = '[FORMS APP] ADD FORM';
export const UPDATE_FORM = '[FORMS APP] UPDATE FORM';
export const REMOVE_FORM = '[FORMS APP] REMOVE FORM';
export const ADD_FORM_SECTION = '[FORMS APP] ADD FORM SECTION';
export const UPDATE_FORM_SECTION = '[FORMS APP] UPDATE FORM SECTION';
export const REMOVE_FORM_SECTION = '[FORMS APP] REMOVE FORM SECTION';

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function openNewFormDialog(data) {
    return {
        type: OPEN_NEW_FORM_DIALOG,
        data
    }
}

export function closeNewFormDialog() {
    return {
        type: CLOSE_NEW_FORM_DIALOG
    }
}

export function openEditFormDialog(data) {
    return (dispatch, getState) => {

        const state = getState();
        window["warn"](state);

        const { Co, Form } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Form?Co=${encodeURIComponent(Co)}&Form=${encodeURIComponent(Form)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_FORM_DIALOG,
                    data: response.data
                }),
            ])
        );
    };
}

export function updateEditFormDialog(data) {
    return {
        type: DATA_EDIT_FORM_DIALOG,
        data
    }
}

export function closeEditFormDialog() {
    return {
        type: CLOSE_EDIT_FORM_DIALOG
    }
}

export function addForm(newForm) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/Form`,
            newForm
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_EDIT_FORM_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form #${response.data.Form} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditFormDialog(response.data))
            })
        );
    };
}

export function updateForm(form) {
    return (dispatch, getState) => {


        const request = axios.put(`${window["apiLocation"]}/api/Form?Co=${encodeURIComponent(form.Co)}&Form=${encodeURIComponent(form.Form)}`,
            form
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_FORM_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form #${response.data.Form} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
                dispatch(updateEditFormDialog(form))
            })
        );
    };
}

export function openNewFormSectionDialog(data) {
    return {
        type: OPEN_NEW_FORM_SECTION_DIALOG,
        data
    }
}

export function closeNewFormSectionDialog() {
    return {
        type: CLOSE_NEW_FORM_SECTION_DIALOG
    }
}

export function openEditFormSectionDialog(data) {
    return {
        type: OPEN_EDIT_FORM_SECTION_DIALOG,
        data: data
    }
}

export function updateEditFormSectionDialog(data) {
    return {
        type: DATA_EDIT_FORM_SECTION_DIALOG,
        data
    }
}

export function closeEditFormSectionDialog() {
    return {
        type: CLOSE_EDIT_FORM_SECTION_DIALOG
    }
}

export function addFormSection(newFormSection) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;


        const request = axios.post(`${window["apiLocation"]}/api/FormSection`,
            newFormSection
        );

        return request.then((response) =>
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Sections: [...data.Data.Sections, response.data],
                    }
                })),
                dispatch(updateEditFormSectionDialog({
                    ...response.data
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Section has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateFormSection(section) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;
        console.warn(section.Data.Elements);


        const request = axios.put(`${window["apiLocation"]}/api/FormSection?Co=${encodeURIComponent(section.Co)}&Form=${encodeURIComponent(section.Form)}&Section=${encodeURIComponent(section.Section)}`,
            section
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Sections, { ID: response.data.ID });
            data.Data.Sections.splice(index, 1, { ...response.data, Data: { ...response.data.Data, Elements: section.Data.Elements } });
            console.warn(data);
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Sections: [...data.Data.Sections],
                    }
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Section has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeFormSection(section) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;


        const request = axios.delete(`${window["apiLocation"]}/api/FormSection?Co=${encodeURIComponent(section.Co)}&Form=${encodeURIComponent(section.Form)}&Section=${encodeURIComponent(section.Section)}`,
            section
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Sections, { ID: response.data.ID });
            data.Data.Sections.splice(index, 1);
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                    Data: {
                        ...data.Data,
                        Sections: [...data.Data.Sections],
                    }
                }))
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Section has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function openNewFormElementDialog(data) {
    return {
        type: OPEN_NEW_FORM_ELEMENT_DIALOG,
        data
    }
}

export function closeNewFormElementDialog() {
    return {
        type: CLOSE_NEW_FORM_ELEMENT_DIALOG
    }
}

export function openEditFormElementDialog(data) {
    return {
        type: OPEN_EDIT_FORM_ELEMENT_DIALOG,
        data: data
    }
}

export function updateEditFormElementDialog(data) {
    return {
        type: DATA_EDIT_FORM_ELEMENT_DIALOG,
        data
    }
}

export function closeEditFormElementDialog() {
    return {
        type: CLOSE_EDIT_FORM_ELEMENT_DIALOG
    }
}

export function addFormElement(newFormElement) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;
        const sectionData = state.formsApp.forms.formSectionDialog.data;


        const request = axios.post(`${window["apiLocation"]}/api/FormElement`,
            newFormElement
        );

        return request.then((response) => {
            const section = _.find(data.Data.Sections, { Section: response.data.Section });
            section.Data.Elements.push(response.data);
            // sectionData.Data.Elements.push(response.data);
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                })),
                dispatch(updateEditFormSectionDialog({
                    ...sectionData,
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Element has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateFormElement(element) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;
        const sectionData = state.formsApp.forms.formSectionDialog.data;


        const request = axios.put(`${window["apiLocation"]}/api/FormElement?Co=${encodeURIComponent(element.Co)}&Form=${encodeURIComponent(element.Form)}&Section=${element.Section}&Element=${encodeURIComponent(element.Element)}`,
            element
        );

        return request.then((response) => {
            const sectionIndex = _.findIndex(sectionData.Data.Elements, { Element: element.Element });
            if (sectionIndex > -1) {
                sectionData.Data.Elements.splice(sectionIndex, 1, response.data);
            }
            const section = _.find(data.Data.Sections, { Section: element.Section });
            const index = _.findIndex(section.Data.Elements, { Element: element.Element });
            if (index > -1) {
                section.Data.Elements.splice(index, 1, response.data);
            }
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                })),
                dispatch(updateEditFormSectionDialog({
                    ...sectionData,
                }))
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Element has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeFormElement(element) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;
        const sectionData = state.formsApp.forms.formSectionDialog.data;


        const request = axios.delete(`${window["apiLocation"]}/api/FormElement?Co=${encodeURIComponent(element.Co)}&Form=${encodeURIComponent(element.Form)}&Section=${element.Section}&Element=${encodeURIComponent(element.Element)}`,
            element
        );

        return request.then((response) => {
            const sectionIndex = _.findIndex(sectionData.Data.Elements, { Element: element.Element });
            if (sectionIndex > -1) {
                sectionData.Data.Elements.splice(sectionIndex, 1);
            }
            const section = _.find(data.Data.Sections, { Section: element.Section });
            const index = _.findIndex(section.Data.Elements, { Element: element.Element });
            if (index > -1) {
                section.Data.Elements.splice(index, 1);
            }
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                })),
                dispatch(updateEditFormSectionDialog({
                    ...sectionData,
                }))
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Element has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeForm(formId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().formsApp.forms;

        const request = axios.post('/api/forms-app/remove-form', {
            formId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_FORM
                })
            ])
        );
    };
}


export function openNewFormAssignmentDialog(data) {
    return {
        type: OPEN_NEW_FORM_ASSIGNMENT_DIALOG,
        data
    }
}

export function closeNewFormAssignmentDialog() {
    return {
        type: CLOSE_NEW_FORM_ASSIGNMENT_DIALOG
    }
}

export function openEditFormAssignmentDialog(data) {
    return {
        type: OPEN_EDIT_FORM_ASSIGNMENT_DIALOG,
        data: data
    }
}

export function updateEditFormAssignmentDialog(data) {
    return {
        type: DATA_EDIT_FORM_ASSIGNMENT_DIALOG,
        data
    }
}

export function closeEditFormAssignmentDialog() {
    return {
        type: CLOSE_EDIT_FORM_ASSIGNMENT_DIALOG
    }
}

export function addFormAssignment(newFormAssignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;


        const request = axios.post(`${window["apiLocation"]}/api/FormAssignment`,
            newFormAssignment
        );

        return request.then((response) => {
            data.Data.Assignments.push(response.data);
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                })),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Assignment has been successfully added.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        });
    };
}

export function updateFormAssignment(assignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;


        const request = axios.put(`${window["apiLocation"]}/api/FormAssignment?Co=${encodeURIComponent(assignment.Co)}&Form=${encodeURIComponent(assignment.Form)}&ID=${encodeURIComponent(assignment.ID)}`,
            assignment
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Assignments, { ID: assignment.ID });
            if (index > -1) {
                data.Data.Assignments.splice(index, 1, response.data);
            }
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                })),
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Assignment has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}

export function removeFormAssignment(assignment) {
    return (dispatch, getState) => {

        const state = getState();
        const { data } = state.formsApp.forms.formDialog;


        const request = axios.delete(`${window["apiLocation"]}/api/FormAssignment?Co=${encodeURIComponent(assignment.Co)}&Form=${encodeURIComponent(assignment.Form)}&ID=${encodeURIComponent(assignment.ID)}`,
            assignment
        );

        return request.then((response) => {
            const index = _.findIndex(data.Data.Assignments, { ID: assignment.ID });
            if (index > -1) {
                data.Data.Assignments.splice(index, 1);
            }
            Promise.all([
                dispatch(updateEditFormDialog({
                    ...data,
                })),
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Form Assignment has been successfully removed.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        }
        );
    };
}