import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class TaxCodesList extends Component {

    state = {
        selectedTaxCodesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedTaxCodeMenu = (event) => {
        this.setState({ selectedTaxCodesMenu: event.currentTarget });
    };

    closeSelectedTaxCodesMenu = () => {
        this.setState({ selectedTaxCodesMenu: null });
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    formatPercentage(num) {
        const str = num.toString();
        const formatted = str + '%'
        return formatted;
    }

    render() {
        const { classes, taxCodes, user, searchText, openNewTaxCodeDialog, selectedTaxCodeIds, selectAllTaxCodes, deSelectAllTaxCodes, toggleInSelectedTaxCodes, openEditTaxCodeDialog, removeTaxCodes, removeTaxCode, toggleStarredTaxCode, setTaxCodesUnstarred, setTaxCodesStarred } = this.props;
        const data = this.getFilteredArray((!this.props.taxCodeData ? [] : this.props.taxCodeData), searchText);
        const { selectedTaxCodesMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewTaxCodeDialog}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered_rtl</Icon>Tax Codes</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditTaxCodeDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "TaxCode",
                                accessor: "TaxCode",
                                className: "font-bold justify-center",
                                width: 150
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                width: 400
                            },
                            {
                                Header: "Old Rate",
                                id: "oldRate",
                                className: "justify-center",
                                accessor: d => this.formatPercentage(d.OldRate)
                            },
                            {
                                Header: "New Rate",
                                id: "newRate",
                                className: "justify-center",
                                accessor: d => this.formatPercentage(d.NewRate)
                            },
                            {
                                Header: "Effective Date",
                                id: "effectiveDate",
                                className: "justify-center",
                                accessor: d => d.MultiLevelYN !== "Y" ? this.formatDate(d.EffectiveDate) : ''

                            },
                            {
                                Header: "GL Account",
                                accessor: "GLAccount",
                                className: "justify-center",
                                show: false
                            },
                            {
                                Header: "Multi-Level",
                                accessor: "MultiLevelYN",
                                className: "justify-center",
                            },
                            {
                                Header: "Active",
                                accessor: "ActiveYN",
                                className: "justify-center",
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Tax Codes found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTaxCodes: Actions.getTaxCodes,
        getUserData: Actions.getUserData,
        toggleInSelectedTaxCodes: Actions.toggleInSelectedTaxCodes,
        selectAllTaxCodes: Actions.selectAllTaxCodes,
        deSelectAllTaxCodes: Actions.deSelectAllTaxCodes,
        openEditTaxCodeDialog: Actions.openEditTaxCodeDialog,
        removeTaxCodes: Actions.removeTaxCodes,
        removeTaxCode: Actions.removeTaxCode,
        toggleStarredTaxCode: Actions.toggleStarredTaxCode,
        toggleStarredTaxCodes: Actions.toggleStarredTaxCodes,
        setTaxCodesStarred: Actions.setTaxCodesStarred,
        setTaxCodesUnstarred: Actions.setTaxCodesUnstarred,
        openNewTaxCodeDialog: Actions.openNewTaxCodeDialog
    }, dispatch);
}

function mapStateToProps({ taxCodesApp, spReducers }) {
    return {
        taxCodes: taxCodesApp.taxCodes.entities,
        selectedTaxCodeIds: taxCodesApp.taxCodes.selectedTaxCodeIds,
        searchText: taxCodesApp.taxCodes.searchText,
        user: taxCodesApp.user,
        taxCodeData: spReducers.taxCodes
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TaxCodesList)));
