import React, { Component } from 'react';
import {
    TextField, Button, Badge, Avatar, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography, Toolbar, AppBar, FormControlLabel, Switch, Grow, Slide, Tooltip
} from '@material-ui/core';
import Media from 'react-media';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import { connect } from 'react-redux';
import _ from '@lodash';
import moment from 'moment';
import { FuseUtils, FuseChipSelect } from '@fuse';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import { SignalCellularNullRounded } from '@material-ui/icons';
import ConfirmationDialog from 'main/content/components/dialogs/confirmation';
import classNames from 'classnames';
import getProfileImage from '../../../functions/getProfileImageUrl';
// import { Video, VideoConference } from '@signalwire-community/react';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {},
    formControl: {
        marginTop: 8,
        marginBottom: 16
    },
    attendeeBadge: {
        top: 16,
        right: 16,
        backgroundColor: 'rgba(0,0,0,.15)',
        color: '#fff',
        fontSize: 'x-small',
        minWidth: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: '50%'
    },
});

const defaultEventState = {
    id: FuseUtils.generateGUID(),
    title: null,
    allDay: null,
    publicYN: null,
    start: new Date(),
    end: new Date(),
    desc: null,
    Data: {
        type: 'C',
        trip: null,
        Attendees: [],
        ErrMsg: null
    },
    AddedBy: null,
    AddedDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
};

class EventDialog extends Component {
    state = { ...defaultEventState };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        if (!prevProps.eventDialog.props.open && this.props.eventDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.eventDialog.type === 'edit' &&
                this.props.eventDialog.data &&
                !_.isEqual(this.props.eventDialog.data, prevProps.eventDialog.data)) {
                this.setState({ ...this.props.eventDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.eventDialog.type === 'new') {
                this.setState({ ...defaultEventState, ...this.props.eventDialog.data });
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : null : event.target.value));
    };

    closeComposeDialog = () => {
        this.setState({ ...defaultEventState }, () => {
            this.props.eventDialog.type === 'edit' ? this.props.closeEditEventDialog() : this.props.closeNewEventDialog();
        });
    };

    canBeSubmitted() {
        const { title } = this.state;
        return (
            title && title.length > 0
        );
    }

    handleStartDateChange = (event) => {
        const { end } = this.state;
        this.setState({ ...this.state, start: event.toDate(), end: (event.toDate() > end ? event.toDate() : end) });
    };

    handleEndDateChange = (event) => {
        const { start } = this.state;
        window["warn"](event);
        this.setState({ ...this.state, end: event.toDate(), start: (event.toDate() < start ? event.toDate() : start) });
    };

    handleAttendeeAction = (attendees) => {
        const { users, User } = this.props;
        const { ID, Data } = this.state;
        const { Attendees } = Data;
        const AddedBy = User.UserName;
        window["warn"]('Old Attendees: ', _.cloneDeepWith(Attendees), attendees);
        for (var i = 0; i < attendees.length; i++) {
            const Attendee = attendees[i].value;
            const isAttendee = _.findIndex(Attendees, { Attendee });
            if (isAttendee < 0) {
                const attendee = {
                    ItemID: ID,
                    Attendee,
                    Data: {
                        ErrMsg: null,
                    }
                };
                Attendees.push(attendee);
                window["warn"]('Added Attendee: ', attendee, _.cloneDeepWith(Attendees));
            } else {
                Attendees[isAttendee].DeleteYN = null;
            }
        };

        for (var i = 0; i < Attendees.length; i++) {
            const attendee = Attendees[i];
            const { Attendee } = attendee;
            const isAttendee = _.find(attendees, { value: Attendee });
            window["warn"]('Removed Attendee: ', attendee, isAttendee, Attendees);
            if (!isAttendee) {
                if (!attendee.ID) {
                    Attendees.splice(i, 1);
                } else {
                    attendee.DeleteYN = 'Y';
                }
            }
        }
        window["warn"]('New Attendees: ', _.cloneDeepWith(Attendees), attendees);
        this.setState({ ...this.state, Data: { ...Data, Attendees } });

    }

    render() {
        const { classes, eventDialog, addEvent, updateEvent, removeEvent, Co, users, securables, User } = this.props;
        const { type } = this.state.Data;
        const start = moment(this.state.start).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
        const end = moment(this.state.end).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
        const avatar = 'assets/images/avatars/profile.jpg';
        const accessLevel = _.find(securables, { Securable: "calendar" });


        return (
            <Media query="(min-width: 1200px)">
                {matches =>
                    <Dialog
                        TransitionComponent={!matches ? SlideUp : Grow}
                        className={classes.root}
                        {...eventDialog.props}
                        classes={{
                            paper: matches ? "" : "full-screen-dialog"
                        }}
                        onClose={this.closeComposeDialog}
                        fullWidth
                        maxWidth="xs"
                        fullScreen={!matches}
                    >

                        <AppBar position="static" className="dialog-header">
                            <Toolbar className="flex w-full">
                                <Typography variant="subtitle1" color="inherit">
                                    {eventDialog.type === 'new' ? 'New Event' : 'Edit Event'}
                                </Typography>
                            </Toolbar>
                            <IconButton style={{
                                position: 'absolute',
                                right: 10,
                                top: matches ? 8 : 4,
                                color: 'white'
                            }}
                                onClick={() => {
                                    this.closeComposeDialog();
                                }}
                                className="dialog-header-icon"
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </AppBar>

                        <DialogContent classes={{ root: "p-16 pb-0 sm:p-24 sm:pb-0" }}>

                            <TextField
                                id="title"
                                label="Title"
                                className={classNames(classes.formControl, this.state.publicYN === "Y" ? "mb-0" : "")}
                                name="title"
                                value={this.state.title || ''}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />

                            {this.state.publicYN !== "Y" &&
                                <div className="w-full">
                                    <FuseChipSelect
                                        label="Attendees"
                                        value={
                                            this.state.Data.Attendees.map(memb => {
                                                const attendee = _.find(users, { UserName: memb.Attendee });
                                                if (attendee && memb.DeleteYN !== "Y") {
                                                    return {
                                                        value: attendee.UserName,
                                                        name: `${attendee.FirstName} ${attendee.LastName}`,
                                                        label: (
                                                            <Tooltip title={`${attendee.FirstName} ${attendee.LastName}`}>
                                                                <Badge classes={{ badge: classNames(classes.attendeeBadge) }} badgeContent={`${attendee.FirstName.substr(0, 1)}${attendee.LastName.substr(0, 1)}`}>
                                                                    <Avatar className="-ml-12 w-32 h-32" src={attendee.Data && attendee.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${attendee.Data.Avatar}` ): avatar} />
                                                                </Badge>
                                                            </Tooltip>)
                                                    }
                                                }
                                            })
                                        }
                                        onChange={(value) => this.handleAttendeeAction(value)}
                                        placeholder="Select Attendees"
                                        isMulti
                                        textFieldProps={{
                                            variant: "outlined",
                                        }}
                                        options={_.filter(users, { ActiveYN: 'Y' }).map((attendee) => (
                                            {
                                                value: attendee.UserName,
                                                name: `${attendee.FirstName} ${attendee.LastName}`,
                                                label: (<span className="flex items-center"><Avatar className="w-32 h-32 mr-8" src={attendee.Data && attendee.Data.Avatar ? getProfileImage(`Co=${Co}&ID=${attendee.Data.Avatar}` ): avatar} />{`${attendee.FirstName} ${attendee.LastName}`}</span>)
                                            }
                                        ))}
                                        variant="fixed"
                                    />
                                </div>
                            }

                            <div className="flex justify-between">
                                <FormControlLabel
                                    className={classNames(classes.formControl, "mb-0")}
                                    label="All Day"
                                    control={
                                        <Switch
                                            checked={this.state.allDay === "Y"}
                                            id="allDay"
                                            name="allDay"
                                            onChange={this.handleChange}
                                        />
                                    } />
                            </div>


                            {/* {this.state.allDay ?
                        <DatePicker variant="inline" inputVariant="outlined"
                            className={classes.formControl}
                            label="Start"
                            id="start"
                            format="MM/DD/YYYY"
                            value={this.state.start}
                            onChange={this.handleStartDateChange}
                            variant="outlined"
                            fullWidth
                        />
                        : */}
                            <DateTimePicker variant="inline" inputVariant="outlined"
                                className={classes.formControl}
                                label="Start"
                                id="start"
                                format="MM/DD/YYYY h:mm A"
                                value={this.state.start}
                                onChange={this.handleStartDateChange}
                                openTo="hours"
                                variant="outlined"
                                fullWidth
                            />
                            {/* } */}

                            {/* {this.state.allDay ?
                        <DatePicker variant="inline" inputVariant="outlined"
                            className={classes.formControl}
                            label="End"
                            id="end"
                            format="MM/DD/YYYY"
                            value={this.state.end}
                            onChange={this.handleEndDateChange}
                            variant="outlined"
                            fullWidth
                        />
                        : */}
                            <DateTimePicker variant="inline" inputVariant="outlined"
                                className={classes.formControl}
                                label="End"
                                id="end"
                                format="MM/DD/YYYY h:mm A"
                                value={this.state.end}
                                onChange={this.handleEndDateChange}
                                openTo="hours"
                                variant="outlined"
                                fullWidth
                            />
                            {/* } */}

                            <TextField
                                className={classes.formControl}
                                id="desc"
                                label="Description"
                                type="text"
                                name="desc"
                                value={this.state.desc || ''}
                                onChange={this.handleChange}
                                multiline rows={5}
                                variant="outlined"
                                fullWidth
                            />
                        </DialogContent>

                        {eventDialog.type === 'new' ? (
                            <DialogActions className="dialog-actions justify-between pl-8 sm:pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const { start, end, title, desc, allDay, publicYN, Data } = this.state;
                                        const { type } = Data;
                                        const rec = {
                                            ID: null,
                                            Type: type,
                                            StartDate: start,
                                            EndDate: end,
                                            Title: title,
                                            Description: desc,
                                            AllDayYN: allDay ? "Y" : null,
                                            PublicYN: publicYN ? "Y" : null,
                                            Data: {
                                                ...Data,
                                            }
                                        };
                                        addEvent(rec);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                >
                                    Add
                                </Button>
                                <FormControlLabel
                                    label="Public"
                                    control={
                                        <Switch
                                            checked={this.state.publicYN === "Y"}
                                            id="public"
                                            name="publicYN"
                                            onChange={this.handleChange}
                                        />
                                    } />
                            </DialogActions>
                        ) : ((accessLevel && accessLevel.AccessLevel !== "R") || this.state.AddedBy === User.UserName) &&
                        (
                            <DialogActions className="dialog-actions justify-between pl-8 sm:pl-16">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const { id, start, end, title, desc, allDay, publicYN, Data } = this.state;
                                        const { type } = Data;
                                        const rec = {
                                            ID: id,
                                            Type: type,
                                            StartDate: start,
                                            EndDate: end,
                                            Title: title,
                                            Description: desc,
                                            AllDayYN: allDay ? "Y" : null,
                                            PublicYN: publicYN ? "Y" : null,
                                            Data: {
                                                ...Data,
                                            }
                                        };
                                        updateEvent(rec);
                                        this.closeComposeDialog();
                                    }}
                                    disabled={!this.canBeSubmitted()}
                                > Save
                                </Button>
                                <FormControlLabel
                                    label="Public"
                                    control={
                                        <Switch
                                            checked={this.state.publicYN === "Y"}
                                            id="public"
                                            name="publicYN"
                                            onChange={this.handleChange}
                                        />
                                    } />
                                <IconButton
                                    onClick={() => this.setState({ confirmDelete: true })}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </DialogActions>
                        )}
                        <ConfirmationDialog
                            open={this.state.confirmDelete}
                            title={<div><Icon className="mr-6 align-middle mb-4" color="error">delete</Icon>Are You Sure?</div>}
                            content={<div className="w-full pt-8 pb-8">{`Are you sure you wish to delete this Event? This action cannot be undone.`}</div>}
                            confirmText="Delete"
                            cancelText="Cancel"
                            onCancel={() => this.setState({ ...this.state, confirmDelete: false })}
                            onConfirm={() => this.setState({ ...this.state, confirmDelete: false }, () => {
                                removeEvent(this.state.id);
                                this.closeComposeDialog();
                            })}
                        />
                    </Dialog>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditEventDialog: Actions.closeEditEventDialog,
        closeNewEventDialog: Actions.closeNewEventDialog,
        addEvent: Actions.addEvent,
        updateEvent: Actions.updateEvent,
        removeEvent: Actions.removeEvent,
    }, dispatch);
}

function mapStateToProps({ calendarApp, spReducers }) {
    return {
        eventDialog: calendarApp.events.eventDialog,
        Co: spReducers.companies.Co,
        users: spReducers.userProfiles.Users,
        securables: spReducers.userProfiles.User.Data.Securables,
        User: spReducers.userProfiles.User
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(EventDialog));
