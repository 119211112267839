import _ from '@lodash';
import { Avatar, Icon, ListItem, Tooltip, Typography, withStyles } from '@material-ui/core';
import { SPRoundIcon } from 'main/icons';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

const styles = theme => ({
    root: {},
    commentBubble: {
        borderRadius: '5px 20px 20px 5px',
        border: '1px solid ' + theme.palette.divider
    }
});

const SupportRequestActivity = ({ item, members, classes, isSupportProvider, onUpdate, userName }) => {
    const avatar = 'assets/images/avatars/profile.jpg';
    // const user = _.find(members, { UserName: item.UserName });

    return (
        <ListItem dense className="px-0">
            {item.UserName.indexOf('@servicepointpro.com') > -1 ? <SPRoundIcon className="text-32" color="secondary" /> : <Icon className="text-32" color="primary">account_circle</Icon>}
            <div className={classNames(classes.commentBubble, "flex flex-col ml-16 p-12 relative")}>
                <div className="flex items-center">
                    <Typography className="font-bold">{item.UserName}</Typography>
                    <Typography className="ml-8 text-12" color="textSecondary">{moment.utc(item.AddedDate).local().format("h:mm A on M/D/YYYY")}</Typography>
                </div>
                <Typography>{item.Comment}</Typography>
                {isSupportProvider && <Tooltip title={(item.InternalYN === "Y" ? "Mark Comment as Public" : "Mark Comment as Private")} open={userName == item.UserName ? undefined : false} placement="top"><div onClick={() => { if (onUpdate) { onUpdate({ ...item, InternalYN: (item.InternalYN === 'Y' ? 'N' : 'Y') }); } }} className="absolute pin-b pin-r cursor-pointer" style={{ marginBottom: -6, marginRight: -6 }}>{item.InternalYN === 'Y' ? <Icon className="mr-4 text-20 align-middle" color="error">visibility_off</Icon> : <Icon className="mr-4 text-20 align-middle" color="primary">public</Icon>}</div></Tooltip>}
            </div>
        </ListItem>
    )
};

export default withStyles(styles, { withTheme: true })(SupportRequestActivity);
