import axios from 'axios/index';
import { getUserData } from 'main/content/apps/timecards/store/actions/user.actions';
import { setTimecardData } from 'store/actions/spConnection/timecards.actions';
import { setTechnicianTimecards } from 'store/actions/spConnection/technician.actions';
import { setSupervisorData } from 'store/actions/spConnection/supervisor.actions';
import { showMessage, setOfflineData, guid } from 'store/actions';
import moment from 'moment';
import _ from '@lodash';

export const GET_TIMECARDS = '[TIMECARDS APP] GET TIMECARDS';
export const SET_SEARCH_TEXT = '[TIMECARDS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_TIMECARDS = '[TIMECARDS APP] TOGGLE IN SELECTED TIMECARDS';
export const SELECT_ALL_TIMECARDS = '[TIMECARDS APP] SELECT ALL TIMECARDS';
export const DESELECT_ALL_TIMECARDS = '[TIMECARDS APP] DESELECT ALL TIMECARDS';
export const OPEN_NEW_TIMECARD_DIALOG = '[TIMECARDS APP] OPEN NEW TIMECARD DIALOG';
export const CLOSE_NEW_TIMECARD_DIALOG = '[TIMECARDS APP] CLOSE NEW TIMECARD DIALOG';
export const OPEN_EDIT_TIMECARD_DIALOG = '[TIMECARDS APP] OPEN EDIT TIMECARD DIALOG';
export const ADD_EDIT_TIMECARD_DIALOG = '[TIMECARDS APP] ADD EDIT TIMECARD DIALOG';
export const UPDATE_EDIT_TIMECARD_DIALOG = '[TIMECARDS APP] UPDATE EDIT TIMECARD DIALOG';
export const DATA_EDIT_TIMECARD_DIALOG = '[TIMECARDS APP] DATA EDIT TIMECARD DIALOG';
export const CLOSE_EDIT_TIMECARD_DIALOG = '[TIMECARDS APP] CLOSE EDIT TIMECARD DIALOG';
export const ADD_TIMECARD = '[TIMECARDS APP] ADD TIMECARD';
export const UPDATE_TIMECARD = '[TIMECARDS APP] UPDATE TIMECARD';
export const REMOVE_TIMECARD = '[TIMECARDS APP] REMOVE TIMECARD';
export const REMOVE_TIMECARDS = '[TIMECARDS APP] REMOVE TIMECARDS';
export const TOGGLE_STARRED_TIMECARD = '[TIMECARDS APP] TOGGLE STARRED TIMECARD';
export const TOGGLE_STARRED_TIMECARDS = '[TIMECARDS APP] TOGGLE STARRED TIMECARDS';
export const SET_TIMECARDS_STARRED = '[TIMECARDS APP] SET TIMECARDS STARRED ';
export const SET_TIMECARD = '[TIMECARDS APP] SET TIMECARD';
export const SET_TIMECARD_TIMECARD = '[TIMECARDS APP] SET TIMECARD TIMECARD';

export function getTimecards(routeParams) {
    const request = axios.get('/api/timecards-app/timecards', {
        params: routeParams
    });
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_TIMECARDS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedTimecards(timecardId) {
    return {
        type: TOGGLE_IN_SELECTED_TIMECARDS,
        timecardId
    }
}


export function selectAllTimecards(timecards) {
    return {
        type: SELECT_ALL_TIMECARDS,
        selectedTimecardIds: timecards
    }
}

export function deSelectAllTimecards() {
    return {
        type: DESELECT_ALL_TIMECARDS,
        selectedTimecardIds: []
    }
}


export function openNewTimecardDialog(data) {
    return {
        type: OPEN_NEW_TIMECARD_DIALOG,
        data
    }
}

export function closeNewTimecardDialog() {
    return {
        type: CLOSE_NEW_TIMECARD_DIALOG
    }
}

export function openPreloadTimecardDialog(data) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_EDIT_TIMECARD_DIALOG,
            data: data,
        })
    }
}

export function openEditTimecardDialog(data, supervisor = false) {
    return (dispatch, getState) => {

        const state = getState();
        window["warn"](state);

        const { Co, Employee, Timecard } = data;
        const date = new Date(data.Date);

        const request = axios.get(`${window["apiLocation"]}/api/Timecard?Co=${encodeURIComponent(Co)}&Date=${encodeURIComponent(date.toLocaleDateString('en-US'))}&Employee=${encodeURIComponent(Employee)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: OPEN_EDIT_TIMECARD_DIALOG,
                    data: response.data,
                    supervisor
                }),
            ])
        );
    };
}

export function clockIn(punch, source) {
    return (dispatch, getState) => {
        const state = getState();
        const { open } = state.timecardsApp.timecards.timecardDialog.props;
        const date = state.timecardsApp.timecards.timecardDialog.date;
        const data = state.timecardsApp.timecards.timecardDialog.data;

        const request = axios.post(`${window["apiLocation"]}/api/Timecard`, punch);

        return request.then((response) => {
            if (open) {
                Promise.all([
                    dispatch({
                        type: SET_TIMECARD,
                        date,
                        loading: false,
                        data: {
                            ...data,
                            ...response.data
                        }
                    }),
                ]);
            }

            switch (source) {
                case 'technician':
                    {
                        const { timecards } = state.spReducers.technician;
                        const index = _.findIndex(timecards, (o) => { return moment(o.Date).local().toDate().toLocaleDateString('en-US') === moment(response.data.Date).local().toDate().toLocaleDateString('en-US') });
                        if (index > -1) {
                            timecards[index].timecard = response.data;
                        };
                        Promise.all([
                            dispatch(setTechnicianTimecards([...timecards])),
                        ])
                    }
                    break;
                case 'supervisor':
                    {
                        const { Timecards } = state.spReducers.supervisor.data;
                        const index = _.findIndex(Timecards, (o) => { return o.Employee === response.data.Employee && moment(o.Date).local().toDate().toLocaleDateString('en-US') === moment(response.data.Date).local().toDate().toLocaleDateString('en-US') });
                        if (index > -1) {
                            Timecards[index] = response.data;
                        } else {
                            Timecards.push(response.data);
                        }
                        Promise.all([
                            dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                        ])
                    }
                    break;
            }
        }
        );
    };
}

export function addTripLabor(data) {
    window["warn"]('Labor Data: ', data);
    return (dispatch, getState) => {
        const state = getState();

        const url = `${window["apiLocation"]}/api/TripLabor`;
        const request = axios.post(url, data);

        return request.then((response) => {
            const { timecards } = state.spReducers.technician;
            let err = '';
            response.data.map((rec) => {
                if (!rec.Data.ErrMsg || rec.Data.ErrMsg.length < 1) {
                    const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(rec.Date)).local().toDate().toLocaleDateString('en-US') });
                    if (tcIndex > -1) {
                        const Timecard = timecards[tcIndex].timecard;
                        if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                            Timecard.Data.Labor.push(rec);
                        }
                    };
                } else {
                    err += `${rec.Data.ErrMsg} | `;
                }
            });
            if (err.length > 0) {
                dispatch(showMessage({
                    message: err,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            }
            Promise.all([
                dispatch(setTechnicianTimecards([...timecards])),
            ])
        }).catch((error) => {
            console.warn('Error adding labor: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
        });
    };
}

export function addLabor(data, source) {
    window["warn"]('Labor Data: ', data);
    return (dispatch, getState) => {
        const state = getState();

        const url = `${window["apiLocation"]}/api/TimecardLabor`;
        const request = axios.post(url, { ...data });

        return request.then((response) => {
            const { open } = state.timecardsApp.timecards.timecardDialog.props;
            const Timecard = state.timecardsApp.timecards.timecardDialog.data;
            const date = Timecard ? Timecard.Date : null;
            if (open && Timecard && Timecard.Data) {
                Promise.all([
                    dispatch({
                        type: SET_TIMECARD,
                        date,
                        loading: false,
                        data: {
                            ...Timecard,
                            Data: {
                                ...Timecard.Data,
                                Labor: Timecard.Data.Labor ? [...Timecard.Data.Labor, response.data] : [response.data]
                            }
                        }
                    }),
                ])
            }

            switch (source) {
                case 'technician':
                    {
                        const { timecards } = state.spReducers.technician;
                        const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(response.data.Date)).local().toDate().toLocaleDateString('en-US') });
                        if (tcIndex > -1) {
                            const Timecard = timecards[tcIndex].timecard;
                            if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                Timecard.Data.Labor.push(response.data);
                            }
                        };
                        Promise.all([
                            dispatch(setTechnicianTimecards([...timecards])),
                        ])
                    }
                    break;
                case 'supervisor':
                    {
                        const { Timecards } = state.spReducers.supervisor.data;
                        const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === response.data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(response.data.Date)).local().toDate().toLocaleDateString('en-US') });
                        if (tcIndex > -1) {
                            const Timecard = Timecards[tcIndex];
                            if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                Timecard.Data.Labor.push(response.data);
                            }
                        };
                        Promise.all([
                            dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                        ])
                    }
                    break;
            }
        }).catch((error) => {
            console.warn('Error adding labor: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                const offlineData = { ...data, OfflineID: guid() };
                Promise.all([
                    dispatch(setOfflineData('ADD', 'post', url, offlineData, 'timecards')),
                ]).then(() => {
                    const { open } = state.timecardsApp.timecards.timecardDialog.props;
                    const Timecard = state.timecardsApp.timecards.timecardDialog.data;
                    const date = Timecard ? Timecard.Date : null;
                    if (open && Timecard && Timecard.Data) {
                        Promise.all([
                            dispatch({
                                type: SET_TIMECARD,
                                date,
                                loading: false,
                                data: {
                                    ...Timecard,
                                    Data: {
                                        ...Timecard.Data,
                                        Labor: Timecard.Data.Labor ? [...Timecard.Data.Labor, offlineData] : [offlineData]
                                    }
                                }
                            }),
                        ])
                    }

                    switch (source) {
                        case 'technician':
                            {
                                const { timecards } = state.spReducers.technician;
                                const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(offlineData.Date)).local().toDate().toLocaleDateString('en-US') });
                                if (tcIndex > -1) {
                                    const Timecard = timecards[tcIndex].timecard;
                                    if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                        Timecard.Data.Labor.push(offlineData);
                                    }
                                };
                                Promise.all([
                                    dispatch(setTechnicianTimecards([...timecards])),
                                ])
                            }
                            break;
                        case 'supervisor':
                            {
                                const { Timecards } = state.spReducers.supervisor.data;
                                const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === offlineData.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(offlineData.Date)).local().toDate().toLocaleDateString('en-US') });
                                if (tcIndex > -1) {
                                    const Timecard = Timecards[tcIndex];
                                    if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                        Timecard.Data.Labor.push(offlineData);
                                    }
                                };
                                Promise.all([
                                    dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                                ])
                            }
                            break;
                    }
                })
            }
        });
    };
}

export function updateLabor(data, source) {
    return (dispatch, getState) => {
        const state = getState();

        const url = `${window["apiLocation"]}/api/TimecardLabor?Co=${data.Co}&Employee=${data.Employee}&Date=${encodeURIComponent(data.Date)}&ID=${data.ID}`;
        const request = axios.put(url, data);

        return request.then((response) => {
            const { open } = state.timecardsApp.timecards.timecardDialog.props;
            const Timecard = state.timecardsApp.timecards.timecardDialog.data;
            const date = Timecard ? Timecard.Date : null;
            if (open && Timecard && Timecard.Data) {
                const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                if (index > -1) {
                    Timecard.Data.Labor.splice(index, 1, response.data);
                    Promise.all([
                        dispatch({
                            type: SET_TIMECARD,
                            date,
                            loading: false,
                            data: {
                                ...Timecard,
                                Data: {
                                    ...Timecard.Data,
                                    Labor: Timecard.Data.Labor,
                                }
                            }
                        }),
                    ])
                }
            }

            switch (source) {
                case 'technician':
                    {
                        const { timecards } = state.spReducers.technician;
                        const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(response.data.Date)).local().toDate().toLocaleDateString('en-US') });
                        if (tcIndex > -1) {
                            const Timecard = timecards[tcIndex].timecard;
                            if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                if (index > -1) {
                                    Timecard.Data.Labor.splice(index, 1, response.data);
                                }
                            }
                        };
                        Promise.all([
                            dispatch(setTechnicianTimecards([...timecards])),
                        ]);
                    }
                    break;
                case 'supervisor':
                    {
                        const { Timecards } = state.spReducers.supervisor.data;
                        const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === response.data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(response.data.Date)).local().toDate().toLocaleDateString('en-US') });
                        if (tcIndex > -1) {
                            const Timecard = Timecards[tcIndex];
                            if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                if (index > -1) {
                                    Timecard.Data.Labor.splice(index, 1, response.data);
                                }
                            }
                        };
                        Promise.all([
                            dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                        ]);
                    }
                    break;
            }
        }).catch((error) => {
            console.warn('Error updating labor: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('UPDATE', 'put', url, data, 'timecards')),
                ]).then(() => {
                    const { open } = state.timecardsApp.timecards.timecardDialog.props;
                    const Timecard = state.timecardsApp.timecards.timecardDialog.data;
                    const date = Timecard ? Timecard.Date : null;
                    if (open && Timecard && Timecard.Data) {
                        const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                        if (index > -1) {
                            Timecard.Data.Labor.splice(index, 1, data);
                            Promise.all([
                                dispatch({
                                    type: SET_TIMECARD,
                                    date,
                                    loading: false,
                                    data: {
                                        ...Timecard,
                                        Data: {
                                            ...Timecard.Data,
                                            Labor: Timecard.Data.Labor,
                                        }
                                    }
                                }),
                            ])
                        }
                    }

                    switch (source) {
                        case 'technician':
                            {
                                const { timecards } = state.spReducers.technician;
                                const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(data.Date)).local().toDate().toLocaleDateString('en-US') });
                                if (tcIndex > -1) {
                                    const Timecard = timecards[tcIndex].timecard;
                                    if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                        const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                        if (index > -1) {
                                            Timecard.Data.Labor.splice(index, 1, data);
                                        }
                                    }
                                };
                                Promise.all([
                                    dispatch(setTechnicianTimecards([...timecards])),
                                ]);
                            }
                            break;
                        case 'supervisor':
                            {
                                const { Timecards } = state.spReducers.supervisor.data;
                                const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(data.Date)).local().toDate().toLocaleDateString('en-US') });
                                if (tcIndex > -1) {
                                    const Timecard = Timecards[tcIndex];
                                    if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                        const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                        if (index > -1) {
                                            Timecard.Data.Labor.splice(index, 1, data);
                                        }
                                    }
                                };
                                Promise.all([
                                    dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                                ]);
                            }
                            break;
                    }
                })
            }
        });
    };
}

export function removeLabor(data, source) {
    return (dispatch, getState) => {
        const state = getState();
        const { timecards } = state.spReducers.technician;

        const url = `${window["apiLocation"]}/api/TimecardLabor?Co=${data.Co}&Employee=${data.Employee}&Date=${encodeURIComponent(data.Date)}&ID=${data.ID}`;
        const request = axios.delete(url, data);

        return request.then((response) => {
            const { open } = state.timecardsApp.timecards.timecardDialog.props;
            const Timecard = state.timecardsApp.timecards.timecardDialog.data;
            const date = Timecard ? Timecard.Date : null;
            if (open && Timecard && Timecard.Data) {
                Promise.all([
                    dispatch({
                        type: SET_TIMECARD,
                        date,
                        loading: false,
                        data: {
                            ...Timecard,
                            Data: {
                                ...Timecard.Data,
                                Labor: _.reject(Timecard.Data.Labor, { ID: data.ID })
                            }
                        }
                    }),
                ])
            }

            switch (source) {
                case 'technician':
                    {
                        const { timecards } = state.spReducers.technician;
                        const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(data.Date)).local().toDate().toLocaleDateString('en-US') });
                        if (tcIndex > -1) {
                            const Timecard = timecards[tcIndex].timecard;
                            if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                if (index > -1) {
                                    Timecard.Data.Labor.splice(index, 1);
                                }
                            }
                        };
                        Promise.all([
                            dispatch(setTechnicianTimecards([...timecards])),
                        ]);
                    }
                    break;
                case 'supervisor':
                    {
                        const { Timecards } = state.spReducers.supervisor.data;
                        const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(data.Date)).local().toDate().toLocaleDateString('en-US') });
                        if (tcIndex > -1) {
                            const Timecard = Timecards[tcIndex];
                            if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                if (index > -1) {
                                    Timecard.Data.Labor.splice(index, 1);
                                }
                            }
                        };
                        Promise.all([
                            dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                        ]);
                    }
                    break;
            }
        }).catch((error) => {
            console.warn('Error deleting labor: ', error, request);
            if (error.message) {
                dispatch(showMessage({
                    message: `${error.message}`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    variant: 'offline'
                }));
            }
            if (error.request) {
                Promise.all([
                    dispatch(setOfflineData('REMOVE', 'delete', url, data, 'timecards')),
                ]).then(() => {
                    const { open } = state.timecardsApp.timecards.timecardDialog.props;
                    const Timecard = state.timecardsApp.timecards.timecardDialog.data;
                    const date = Timecard ? Timecard.Date : null;
                    if (open && Timecard && Timecard.Data) {
                        Promise.all([
                            dispatch({
                                type: SET_TIMECARD,
                                date,
                                loading: false,
                                data: {
                                    ...Timecard,
                                    Data: {
                                        ...Timecard.Data,
                                        Labor: _.reject(Timecard.Data.Labor, { ID: data.ID })
                                    }
                                }
                            }),
                        ])
                    }

                    switch (source) {
                        case 'technician':
                            {
                                const { timecards } = state.spReducers.technician;
                                const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(data.Date)).local().toDate().toLocaleDateString('en-US') });
                                if (tcIndex > -1) {
                                    const Timecard = timecards[tcIndex].timecard;
                                    if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                        const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                        if (index > -1) {
                                            Timecard.Data.Labor.splice(index, 1);
                                        }
                                    }
                                };
                                Promise.all([
                                    dispatch(setTechnicianTimecards([...timecards])),
                                ]);
                            }
                            break;
                        case 'supervisor':
                            {
                                const { Timecards } = state.spReducers.supervisor.data;
                                const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(data.Date)).local().toDate().toLocaleDateString('en-US') });
                                if (tcIndex > -1) {
                                    const Timecard = Timecards[tcIndex];
                                    if (Timecard && Timecard.Data && Timecard.Data.Labor) {
                                        const index = _.findIndex(Timecard.Data.Labor, { ID: data.ID });
                                        if (index > -1) {
                                            Timecard.Data.Labor.splice(index, 1);
                                        }
                                    }
                                };
                                Promise.all([
                                    dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                                ]);
                            }
                            break;
                    }
                })
            }
        });
    };
}


export function editPunch(punch, source) {
    return (dispatch, getState) => {

        const state = getState();
        const date = state.timecardsApp.timecards.timecardDialog.date;

        const { Co, Employee } = punch;

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${Co}&Employee=${Employee}&Date=${encodeURIComponent(punch.Date)}&ID=${punch.ID}`, punch);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                const { open } = state.timecardsApp.timecards.timecardDialog.props;
                const data = state.timecardsApp.timecards.timecardDialog.data;
                const Timecard = data;
                const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                if (open && Punch) {
                    const index = _.findIndex(Punch, { ID: punch.ID });
                    Punch.splice(index, 1, response.data);
                    Promise.all([
                        dispatch({
                            type: SET_TIMECARD,
                            date,
                            loading: false,
                            data: {
                                ...Timecard,
                                Data: {
                                    ...Timecard.Data,
                                    Punch,
                                }
                            }
                        }),
                    ])
                }

                switch (source) {
                    case 'technician':
                        {
                            const { timecards } = state.spReducers.technician;
                            const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(punch.Date)).local().toDate().toLocaleDateString('en-US') });
                            if (tcIndex > -1) {
                                const Timecard = timecards[tcIndex].timecard;
                                const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                                const index = _.findIndex(Punch, { ID: punch.ID });
                                Punch.splice(index, 1, response.data);
                            };
                            Promise.all([
                                dispatch(setTechnicianTimecards([...timecards])),
                            ])
                        }
                        break;
                    case 'supervisor':
                        {
                            const { Timecards } = state.spReducers.supervisor.data;
                            const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === response.data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(punch.Date)).local().toDate().toLocaleDateString('en-US') });
                            if (tcIndex > -1) {
                                const Timecard = Timecards[tcIndex];
                                const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                                const index = _.findIndex(Punch, { ID: punch.ID });
                                Punch.splice(index, 1, response.data);
                            };
                            Promise.all([
                                dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                            ])
                        }
                        break;
                }
            } else {

            }
        });
    };
}


export function removePunch(punch, source) {
    return (dispatch, getState) => {

        const state = getState();
        const date = state.timecardsApp.timecards.timecardDialog.date;
        const data = state.timecardsApp.timecards.timecardDialog.data;
        const { open } = state.timecardsApp.timecards.timecardDialog.props;
        const Timecard = data;
        const { Punch } = Timecard ? Timecard.Data : {};

        const { Co, Employee } = punch;

        const request = axios.delete(`${window["apiLocation"]}/api/Timecard?Co=${Co}&Employee=${Employee}&Date=${encodeURIComponent(punch.Date)}&ID=${punch.ID}`, punch);

        return request.then((response) => {
            if (!response.data || response.data.length === 0) {
                const index = _.findIndex(Punch, { ID: punch.ID });
                if (Punch && Array.isArray(Punch)) {
                    if (index > -1) {
                        Punch.splice(index, 1);
                    }
                Promise.all([
                    dispatch({
                        type: SET_TIMECARD,
                        date,
                        loading: false,
                        data: {
                            ...Timecard,
                            Data: {
                                ...Timecard.Data,
                                Punch,
                            }
                        }
                    }),
                ])
                
                switch (source) {
                    case 'technician':
                        {
                            const { timecards } = state.spReducers.technician;
                            const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(punch.Date)).local().toDate().toLocaleDateString('en-US') });
                            if (tcIndex > -1) {
                                const Timecard = timecards[tcIndex].timecard;
                                const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                                const index = _.findIndex(Punch, { ID: punch.ID });
                                Punch.splice(index, 1);
                            };
                            Promise.all([
                                dispatch(setTechnicianTimecards([...timecards])),
                            ])
                        }
                        break;
                    case 'supervisor':
                        {
                            const { Timecards } = state.spReducers.supervisor.data;
                            const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === response.data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(punch.Date)).local().toDate().toLocaleDateString('en-US') });
                            if (tcIndex > -1) {
                                const Timecard = Timecards[tcIndex];
                                const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                                const index = _.findIndex(Punch, { ID: punch.ID });
                                Punch.splice(index, 1);
                            };
                            Promise.all([
                                dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                            ])
                        }
                        break;
                }
            }
            } else {

            }
        });
    };
}

export function editBreak(punch, source) {
    return (dispatch, getState) => {

        const state = getState();

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.StartRec.Co}&Employee=${punch.StartRec.Employee}&Date=${encodeURIComponent(punch.StartRec.Date)}&ID=${punch.StartRec.ID}`, punch.StartRec);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                switch (source) {
                    case 'dialog':
                        {
                            const date = state.timecardsApp.timecards.timecardDialog.date;
                            const data = state.timecardsApp.timecards.timecardDialog.data;
                            const Timecard = data;
                            const { Punch } = Timecard.Data;
                            const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                            Punch.splice(index, 1, response.data);
                            const request2 = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);

                            return request2.then((response2) => {
                                if (!response2.data.ErrMsg || response2.data.ErrMsg.length === 0) {
                                    const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                                    Punch.splice(index2, 1, response2.data);
                                    Promise.all([
                                        dispatch({
                                            type: SET_TIMECARD,
                                            date,
                                            loading: false,
                                            data: {
                                                ...Timecard,
                                                Data: {
                                                    ...Timecard.Data,
                                                    Punch,
                                                }
                                            }
                                        }),
                                    ])
                                } else {

                                }
                            })
                        }
                        break;
                    case 'technician':
                        {
                            const { timecards } = state.spReducers.technician;
                            const tcIndex = _.findIndex(timecards, (o) => { return moment(o.Date).local().toDate().toLocaleDateString('en-US') === moment(punch.StartRec.Date).local().toDate().toLocaleDateString('en-US') });
                            const Timecard = timecards[tcIndex].timecard;
                            const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                            const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                            Punch.splice(index, 1, response.data);
                            const request2 = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);

                            return request2.then((response2) => {
                                if (!response2.data.ErrMsg || response2.data.ErrMsg.length === 0) {
                                    const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                                    Punch.splice(index2, 1, response2.data);
                                    Promise.all([
                                        dispatch(setTechnicianTimecards([...timecards])),
                                    ])
                                } else {

                                }
                            })
                        }
                        break;
                    case 'supervisor':
                        {
                            const { Timecards } = state.spReducers.supervisor.data;
                            const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === punch.StartRec.Employee && moment(o.Date).local().toDate().toLocaleDateString('en-US') === moment(punch.StartRec.Date).local().toDate().toLocaleDateString('en-US') });
                            const Timecard = Timecards[tcIndex];
                            const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                            const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                            Punch.splice(index, 1, response.data);
                            const request2 = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);

                            return request2.then((response2) => {
                                if (!response2.data.ErrMsg || response2.data.ErrMsg.length === 0) {
                                    const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                                    Punch.splice(index2, 1, response2.data);
                                    Promise.all([
                                        dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                                    ])
                                } else {

                                }
                            })
                        }
                        break;
                }
            } else {

            }
        }
        );
    };
}

export function removeBreak(punch, source) {
    return (dispatch, getState) => {

        const state = getState();

        const startRec = {
            ...punch.StartRec,
            TimeOut: punch.EndRec.TimeOut,
        };

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${punch.StartRec.Co}&Employee=${punch.StartRec.Employee}&Date=${encodeURIComponent(punch.StartRec.Date)}&ID=${punch.StartRec.ID}`, startRec);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                switch (source) {
                    case 'dialog':
                        {
                            const date = state.timecardsApp.timecards.timecardDialog.date;
                            const data = state.timecardsApp.timecards.timecardDialog.data;
                            const { open } = state.timecardsApp.timecards.timecardDialog.props;
                            const Timecard = data;
                            const { Punch } = Timecard ? Timecard.Data : {};
                            if (open && Punch) {
                                const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                                Punch.splice(index, 1, response.data);
                            }
                            const request2 = axios.delete(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);

                            return request2.then((response2) => {
                                if (open && (!response2.data || response2.data.length === 0)) {
                                    const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                                    Punch.splice(index2, 1);
                                    Promise.all([
                                        dispatch({
                                            type: SET_TIMECARD,
                                            date,
                                            loading: false,
                                            data: {
                                                ...Timecard,
                                                Data: {
                                                    ...Timecard.Data,
                                                    Punch,
                                                }
                                            }
                                        }),
                                    ])
                                } else {

                                }
                            })
                        }
                        break;
                    case 'technician':
                        {
                            const { timecards } = state.spReducers.technician;
                            const tcIndex = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') });
                            const Timecard = timecards.length !== 0 ? timecards[tcIndex].timecard: {};
                            const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                            const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                            Punch.splice(index, 1, response.data);
                            const request2 = axios.delete(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);
                            return request2.then((response2) => {
                                if (!response2.data || response2.data.length === 0) {
                                    const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                                    Punch.splice(index2, 1);
                                    Promise.all([
                                        dispatch(setTechnicianTimecards([...timecards])),
                                    ])
                                } else {

                                }
                            })
                        }
                        break;
                    case 'supervisor':
                        {
                            const { Timecards } = state.spReducers.supervisor.data;
                            const tcIndex = _.findIndex(Timecards, (o) => { return o.Employee === punch.StartRec.Employee && moment(o.Date).local().toDate().toLocaleDateString('en-US') === moment(o.Date).local().toDate().toLocaleDateString('en-US') });
                            const Timecard = Timecards[tcIndex];
                            const { Punch } = Timecard && Timecard.Data ? Timecard.Data : {};
                            const index = _.findIndex(Punch, { ID: punch.StartRec.ID });
                            Punch.splice(index, 1, response.data);
                            const request2 = axios.delete(`${window["apiLocation"]}/api/Timecard?Co=${punch.EndRec.Co}&Employee=${punch.EndRec.Employee}&Date=${encodeURIComponent(punch.EndRec.Date)}&ID=${punch.EndRec.ID}`, punch.EndRec);

                            return request2.then((response2) => {
                                if (!response2.data || response2.data.length === 0) {
                                    const index2 = _.findIndex(Punch, { ID: punch.EndRec.ID });
                                    Punch.splice(index2, 1);
                                    Promise.all([
                                        dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                                    ])
                                } else {

                                }
                            })
                        }
                        break;
                }
            } else {

            }
        }
        );
    };
}

export function editTimecard(tcData, source) {
    window["warn"](source);
    return (dispatch, getState) => {

        const state = getState();
        const data = state.spReducers.timecards.timecards;
        const Timecard = state.timecardsApp.timecards.timecardDialog.data;
        const { open } = state.timecardsApp.timecards.timecardDialog.props;

        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${tcData.Co}&Employee=${tcData.Employee}&Date=${encodeURIComponent(tcData.Date)}`, tcData);

        return request.then((response) => {
            if (!response.data.ErrMsg || response.data.ErrMsg.length === 0) {
                if (open) {
                    const index = _.findIndex(data, { ID: response.data.ID });
                    if (index > -1) {
                        response.data.Hours = data[index].Hours
                    };
                    data.splice(index, 1, response.data);
                    Promise.all([
                        dispatch({
                            type: SET_TIMECARD,
                            loading: false,
                            data: {
                                ...response.data,
                                Data: {
                                    ...Timecard.Data,
                                }
                            }
                        }),
                        dispatch(setTimecardData(data)),
                    ])
                }

                switch (source) {
                    case 'technician':
                        {
                            const { timecards } = state.spReducers.technician;
                            const index = _.findIndex(timecards, (o) => { return moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(response.data.Date)).local().toDate().toLocaleDateString('en-US') });
                            if (index > -1) {
                                timecards[index].timecard = response.data;
                            };
                            Promise.all([
                                dispatch(setTechnicianTimecards([...timecards])),
                            ])

                        }
                        break;
                    case 'supervisor':
                        {
                            const { Timecards } = state.spReducers.technician;
                            const index = _.findIndex(Timecards, (o) => { return o.Employee === response.data.Employee && moment(moment(o.Date)).local().toDate().toLocaleDateString('en-US') === moment(moment(response.data.Date)).local().toDate().toLocaleDateString('en-US') });
                            if (index > -1) {
                                Timecards[index] = response.data;
                            };
                            Promise.all([
                                dispatch(setSupervisorData(state.spReducers.supervisor.data)),
                            ])

                        }
                        break;
                }
            } else {

            }
        }
        );
    };
}

export function updateEditTimecardDialog(data,) {
    return (dispatch, getState) => {

        const { Co, Customer, Timecard } = data;

        const request = axios.get(`${window["apiLocation"]}/api/Timecard?Co=${encodeURIComponent(Co)}&Timecard=${encodeURIComponent(Timecard)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: DATA_EDIT_TIMECARD_DIALOG,
                    data: response.data
                })
            ])
        );
    };
}

export function closeEditTimecardDialog() {
    return {
        type: CLOSE_EDIT_TIMECARD_DIALOG
    }
}

export function updateTimecard(timecard) {
    return (dispatch, getState) => {


        const request = axios.put(`${window["apiLocation"]}/api/Timecard?Co=${encodeURIComponent(timecard.Co)}&Timecard=${encodeURIComponent(timecard.Timecard)}`,
            timecard
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_EDIT_TIMECARD_DIALOG,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Timecard ID ${response.data.Timecard} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateTimecards(timecards) {
    return (dispatch, getState) => {


        const request = axios.post(`${window["apiLocation"]}/api/Timecard/Batch`,
            timecards
        );

        return request.then((response) => {
            var ErrMsg = '';
            response.data.map(value => {
                if (value.Data.ErrMsg && value.Data.ErrMsg.length > 0) {
                    ErrMsg += `${value.Data.ErrMsg} | `;
                }
            });
            if (ErrMsg.length > 0) {
                dispatch(showMessage({
                    message: `Error: ${ErrMsg}`,
                    autoHideDuration: 30000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
            } else {
                dispatch(showMessage({
                    message: `Selected timecards have been successfully updated.`,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'success'
                }));
            }
        });
    };
}

export function removeTimecard(timecardId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().timecardsApp.timecards;

        const request = axios.post('/api/timecards-app/remove-timecard', {
            timecardId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TIMECARD
                })
            ]).then(() => dispatch(getTimecards(routeParams)))
        );
    };
}