import {combineReducers} from 'redux';
import dashboards from './dashboards.reducer';
import dashboard from './dashboard.reducer';

const dashboardBuilderAppReducers = combineReducers({
    dashboards,
    dashboard,
});

export default dashboardBuilderAppReducers;
