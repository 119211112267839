import axios from 'axios/index';
import moment from 'moment';
import { SUBSCRIBE_LIST } from 'store/actions';

export const SET_CALENDAR_DATA = '[SPCONNECTION] SET CALENDAR DATA';
export const SET_TAG_DATA = '[SPCONNECTION] SET TO DO TAG DATA';

export function getToDoTagData() {

    return (dispatch, getState) => {
        const request = axios.get(`${window["apiLocation"]}/api/ToDoTag`);
        request.then((res) => {
            dispatch(setTagData(res.data));
        });
    }
}

export function getCalendarData(Co, mth, tech) {
    const month = moment(mth).format('MM-DD-YYYY')

    return (dispatch, getState) => {
        const state = getState();
        const { User } = state.spReducers.userProfiles;
        const { UserName } = User;
        const cal = {
            Events: [],
            Trips: [],
            ToDos: []
        }
        const eventRequest = axios.get(`${window["apiLocation"]}/api/CalendarEvent?Mth=${month}`);
        eventRequest.then((res) => {
            cal.Events = res.data;
            dispatch({ type: SUBSCRIBE_LIST, List: `${UserName}_CalendarEvents`, });
            const toDoRequest = axios.get(`${window["apiLocation"]}/api/ToDoItem`);
            toDoRequest.then((res2) => {
                cal.ToDos = res2.data;
                dispatch({ type: SUBSCRIBE_LIST, List: `${UserName}_ToDoItems`, });
                if (tech) {
                    const tripRequest = axios.get(`${window["apiLocation"]}/api/DispatchBoard?Co=${Co}&Date=${encodeURIComponent(month)}&Tech=${encodeURIComponent(tech)}&View=M&Shallow=Y`);
                    tripRequest.then((res3) => {
                        cal.Trips = res3.data;
                        dispatch(setCalendarData(cal, mth));
                    });
                } else {
                    dispatch(setCalendarData(cal, mth));
                }
            });
        });
    }
}

export function setTagData(data) {
    return {
        type: SET_TAG_DATA,
        payload: data
    };
}

export function setCalendarData(data, month) {
    return {
        type: SET_CALENDAR_DATA,
        payload: data,
        month
    };
}