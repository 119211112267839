import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: [],
    searchText: '',
    selectedAcademyIds: [],
    routeParams: {},
    academyDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    categories: [],
    category: null,
    classes: [],
    class: null,
    step: null,
};

const academyReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CLASSES:
            {
                return {
                    ...state,
                    entities: _.keyBy(action.payload, 'id'),
                    routeParams: action.routeParams
                };
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.SET_CLASS:
            {
                return {
                    ...state,
                    class: action.class,
                }
            }
        case Actions.SET_CLASS_STEP:
            {
                return {
                    ...state,
                    step: action.step,
                }
            }
        case Actions.SET_CLASSES:
            {
                return {
                    ...state,
                    classes: action.classes,
                }
            }
        case Actions.SET_CLASS_CATEGORY:
            {
                return {
                    ...state,
                    category: action.category,
                }
            }
        case Actions.SET_CLASS_CATEGORIES:
            {
                return {
                    ...state,
                    categories: action.categories,
                }
            }
        default:
            {
                return state;
            }
    }
};

export default academyReducer;
