import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../store/actions';
import { withStyles, Typography, Icon } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
// import EmailDashboardBuilder from '../EmailDashboardBuilder';
import { FuseAnimateGroup, FuseAnimate } from '@fuse';
import { SPRoundIcon } from 'main/icons';

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        paddingBottom: 80,
        overflow: 'auto',
    },
    dashboard: {
        cursor: 'pointer',
        boxShadow: theme.shadows[0],
        transitionProperty: 'box-shadow border-color',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        background: '#fff',
        color: '#333',
        '&:hover': {
            boxShadow: theme.shadows[6]
        }
    },
    newDashboard: {
        borderWidth: 2,
        borderStyle: 'dashed',
        background: 'transparent',
        color: theme.palette.primary.contrastText,
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
        '&:hover': {
            borderColor: fade(theme.palette.primary.contrastText, 0.8)
        }
    },
    grey: {
        backgroundColor: '#3f3f3f',
        color: '#fff',
    }
});


class Dashboards extends Component {
    state = {
        Co: null,
        User: null,
    }
    componentDidMount() {
        //this.props.getDashboards(this.props.Co);
    }

    componentWillUnmount() {
        //this.props.resetDashboards();
    }

    newDashboard = () => {
        const { Co, User } = this.props;
        const dashboard = {
            Co: Co,
            Dashboard: null,
            Name: 'New Dashboard',
            AddedBy: User,
            AddedDate: new Date(),
            Notes: null,
            Data: {
                ErrMsg: null,
                Components: [],
                Reports: [],
                DataSources: [],
            }
        }
        this.props.newDashboard(dashboard);
    }

    render() {
        const { classes, dashboards, Co, User } = this.props;

        return (
            <div className={classNames(classes.root, "flex flex-grow flex-no-shrink flex-col items-center")}>
                {/* <EmailDashboardBuilder components="<div></div>" style="" /> */}
                <FuseAnimate>
                    <Typography className="mt-24 sm:mt-64 sm:py-24 text-24 font-400" color="inherit"><SPRoundIcon className="align-middle text-32 mr-6 mb-4" />Dashboard Studio</Typography>
                </FuseAnimate>

                <div>
                    <FuseAnimateGroup
                        className="flex flex-wrap w-full justify-center py-32 px-16"
                        enter={{
                            animation: "transition.slideUpBigIn",
                            duration: 300
                        }}
                    >
                        {dashboards.map(dashboard => (
                            <div className="w-224 h-224 p-16 relative" key={dashboard.ID}>
                                {!dashboard.Co &&
                                    <div className={classNames("pin-t pin-l mt-6 ml-24 absolute text-12 font-bold text-10 rounded pt-4 px-4 pb-0", classes.grey)}><Icon className="mr-4 text-16 align-middle mb-4">cloud_done</Icon>Standard</div>
                                }
                                <Link
                                    to={'/apps/dashboard-builder/dashboards/' + Co + '/' + dashboard.Dashboard}
                                    className={classNames(classes.dashboard, "flex flex-col items-center justify-center w-full h-full rounded py-24")}
                                    role="button"
                                >
                                    <Icon className="text-56">dashboard</Icon>
                                    <Typography className="text-16 font-300 text-center pt-16 px-32" color="inherit">{dashboard.Name}</Typography>
                                    {/* <Typography className="text-10 font-300 text-center pt-16 px-32 capitalize font-bold" color="inherit">Form: {dashboard.Form}</Typography> */}
                                </Link>
                            </div>
                        ))}
                        <div className="w-224 h-224 p-16">
                            <div
                                className={classNames(classes.dashboard, classes.newDashboard, "flex flex-col items-center justify-center w-full h-full rounded py-24")}
                                onClick={this.newDashboard}
                            >
                                <Icon className="text-56">add_circle</Icon>
                                <Typography className="text-16 font-300 text-center pt-16 px-32" color="inherit">Add new dashboard</Typography>
                            </div>
                        </div>
                    </FuseAnimateGroup>

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDashboards: Actions.getDashboards,
        resetDashboards: Actions.resetDashboards,
        newDashboard: Actions.newDashboard
    }, dispatch);
}

function mapStateToProps({ dashboardBuilderApp, spReducers }) {
    return {
        dashboards: dashboardBuilderApp.dashboards.Dashboards,
        Co: spReducers.companies.Co,
        User: spReducers.userProfiles.User.UserName,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboards)));
