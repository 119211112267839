import * as Actions from "store/actions/spConnection";
import { fuseNavigationConfig } from "fuse-configs/fuseNavigationConfig";
import _ from "@lodash";

// window["warn"](fuseNavigationConfig);

function getSecurables(arr, securables) {
  arr.map((value) => {
    if (value.type === "item") {
      if (value.securables) {
        securables = [...securables, ...value.securables];
      }
    } else {
      if (value.securables) {
        securables = [...securables, ...value.securables];
      }
      securables = getSecurables(value.children, securables);
    }
  });
  return securables;
}

function getPages(arr, pages) {
  arr.map((value) => {
    if (value.type === "item") {
      pages = [...pages, value];
    } else {
      pages = getPages(value.children, pages);
    }
  });
  return pages;
}

//TODO: Remove IncreaseFontSize when backend is updated
const initialState = {
  Users: [],
  User: {
    Data: {
      Stations: [],
      Roles: [],
      Securables: [],
      Pages: [],
      TableSettings: [],
      DefaultHomepage: "/",
    },
  },
  IncreaseFontSize: "Y",
  timestamp: new Date("1/1/1900"),
  Securables: getSecurables(fuseNavigationConfig, []),
  Pages: getPages(fuseNavigationConfig, []),
};
const userProfiles = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_USER_PROFILE_DATA: {
      return {
        ...state,
        User: action.payload,
      };
    }
    case Actions.SET_GLOBAL_SECURABLES: {
      const Securables = getSecurables(fuseNavigationConfig, []);
      return {
        ...state,
        Securables,
      };
    }
    case Actions.SET_USER_PROFILE_DATA: {
      return {
        ...state,
        User: action.payload,
      };
    }
    case Actions.GET_USER_PROFILE_LIST_DATA: {
      return {
        ...state,
        Users: action.payload,
        timestamp: action.timestamp || new Date(),
      };
    }
    case Actions.SET_USER_PROFILE_LIST_DATA: {
      return {
        ...state,
        Users: action.payload,
        timestamp: action.timestamp || new Date(),
      };
    }
    case Actions.SET_USER_PRIVILEGES: {
      return {
        ...state,
        User: action.payload,
      };
    }
  }

  return state;
};

export default userProfiles;
