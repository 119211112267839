import { Button, CardActions, Divider, Drawer, Icon, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import { openEditCustomerDialog } from '../customers/store/actions';
import { openEditInvoiceDialog } from '../invoices/store/actions';
import InvoicePaymentRecord from '../invoices/InvoicePaymentRecord';
import * as Actions from './store/actions';
import CustomerRedirectSearchBar from '../customers/CustomerRedirectSearchBar';

const styles = theme => ({
    clear: {
        backgroundColor: theme.palette.error[500],
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error[400],
        },
        '&:active': {
            backgroundColor: theme.palette.error[700],
        }
    },
    drawer: {
        width: 0,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 360,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxShadow: '-2px 0px 8px 0px rgba(0,0,0,.05)',
        zIndex: 99999
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
    },
    type_charge: {
        color: '#fff',
        backgroundColor: '#d1e751',
        borderRadius: 5,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 11
    },
    type_refund: {
        color: '#fff',
        backgroundColor: 'orange',
        borderRadius: 5,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 11
    },
    type_void: {
        color: '#fff',
        backgroundColor: '#6f6f6f',
        borderRadius: 5,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 11
    },
    cnp: {
        backgroundColor: '#5f5f5f',
        color: '#fff'
    },
    cp: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    error: {
        backgroundColor: theme.palette.error[500],
        borderRadius: 6,
        width: '100%',
        padding: 8,
        color: '#fff',
        whiteSpace: 'normal'
    },
    invoiceNumber: {
        backgroundColor: theme.palette.primary.main
    }
});

const initialState = {
    willRefund: false,
    refundAmount: null,
    partial: false,
    confirmVoid: false,
    confirmRefund: false,
    viewPayment: false,
    spCustomer: null
}
class PaymentDetailSidebar extends Component {

    state = {
        ...initialState
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.transaction, prevProps.transaction)) {
            this.setState({ refundAmount: null, partial: false });
        }
    }

    formatDollars = (num) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    }

    refundTransaction = (refundAmount) => {
        const { transaction, Co, payments } = this.props;
        // const { refundAmount } = this.state;
        const { id } = transaction || {};
        if (Co && id && refundAmount) {
            const request = axios.get(`${window["apiLocation"]}/api/Omni/Refund?Co=${Co}&id=${encodeURIComponent(id)}&amount=${refundAmount}`);
            return request.then((response) => {
                this.props.setTransaction(response.data);
                const index = _.findIndex(payments.data, { id: response.data.id });
                if (index > -1) {
                    const pmts = _.cloneDeepWith(payments);
                    pmts.data.splice(index, 1, response.data);
                    this.props.setPayments(pmts);
                }
                this.setState({ confirmRefund: false });
            }).catch((err) => {
                window["warn"]('Refund Error: ', err);
            })
        }
    }

    voidTransaction = () => {
        const { transaction, Co, payments } = this.props;
        const { id } = transaction || {};
        if (Co && id) {
            const request = axios.get(`${window["apiLocation"]}/api/Omni/Void?Co=${Co}&id=${encodeURIComponent(id)}`);
            return request.then((response) => {
                this.props.setTransaction(response.data);
                const index = _.findIndex(payments.data, { id: response.data.id });
                if (index > -1) {
                    const pmts = _.cloneDeepWith(payments);
                    pmts.data.splice(index, 1, response.data);
                    this.props.setPayments(pmts);
                }
                this.setState({ confirmVoid: false });
            }).catch((err) => {
                window["warn"]('Void Error: ', err);
            })
        }
        return;
    }

    attachCardPayment = (transaction) => {
        const { id, sp_customer, total, total_refunded, created_at, payment_method, payment_method_id } = transaction;
        const { Co, Customer, PaymentsCustomer } = sp_customer || this.state.spCustomer;
        const PaymentTotal = total - total_refunded;
        const tran = {
            Co,
            Customer,
            PaymentID: id,
            PaymentType: 'CREDIT',
            PaymentTotal,
            PaymentDateTime: created_at,
            PaymentBy: payment_method.person_name,
            PaymentMethodID: payment_method_id,
            Memo: 'Re-Sync - Credit / Debit Payment',
            Data: {
                Allocations: [],
                ErrMsg: null,
                PaymentMethod: {
                    Co,
                    Customer,
                    PaymentMethodID: payment_method.id,
                    PaymentMethodType: payment_method.card_type,
                    CardholderName: payment_method.person_Name,
                    CardLastFour: payment_method.card_last_four,
                    CardExpirationDate: payment_method.card_exp_datetime,
                    Nickname: payment_method.nickname,
                    // PaymentsCustomer
                }
            }
        };

        if (tran.Co && tran.Customer) {

            const request = axios.post(`${window["apiLocation"]}/api/Payment`, tran);

            return request.then((response) => {
                if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg.length < 1) {
                    transaction.sp_data = response.data;
                    this.setState({ viewPayment: true });
                }
            });
        }
    }

    render() {
        const { Co, classes, filters, transaction } = this.props;
        const { refundAmount, partial } = this.state;
        const allocated = Math.round(((!transaction || !transaction.success || transaction.type !== 'charge') ? 0 : !transaction.sp_data ? 0 : _.sumBy(_.filter(transaction.sp_data.Data.Allocations, (o) => !o.VoidDate), 'Amount') + Number.EPSILON) * 100) / 100;
        const total = Math.round(((!transaction || !transaction.success) ? 0 : transaction.is_voided ? 0 : (transaction.total - transaction.total_refunded) + Number.EPSILON) * 100) / 100;
        return (
            <Drawer
                anchor="right"
                variant="permanent"
                className={clsx(classNames(classes.drawer, "h-full pin-r pin-t absolute"), {
                    [classes.drawerOpen]: Boolean(transaction),
                    [classes.drawerClose]: !Boolean(transaction)
                })}
                classes={{
                    paper: clsx("relative border-none h-full pb-64", {
                        [classes.drawerOpen]: Boolean(transaction),
                        [classes.drawerClose]: !Boolean(transaction)
                    })
                }}
                open={Boolean(transaction)}
                onClose={() => this.props.setTransaction(null)}
                onClick={(e) => e.stopPropagation()}
            >
                {transaction &&
                    <div className="w-full p-8">
                        <div className="relative flex justify-between mb-4">
                            <Typography variant="h6" style={{ alignItems: 'center', }} className="flex text-16 my-8"><Icon color="primary" className="text-24 mr-12">payment</Icon>Transaction Details</Typography>
                            <IconButton
                                className="align-middle"
                                onClick={() => this.props.setTransaction(null)}
                                aria-label="close"
                            >
                                <Icon color="action" className="text-20">close</Icon>
                            </IconButton>
                        </div>

                        <Divider />

                        <div onClick={() => navigator.clipboard.writeText(transaction.id)} className={classNames("text-10 truncate w-full flex font-600 mt-8 cursor-pointer justify-between")}>ID: {transaction.id}<Icon className="text-14 ml-6" color="primary">file_copy</Icon></div>
                        <div className={classNames("w-full text-24 flex justify-between my-4 font-600")}><div className={transaction.is_voided ? "line-through" : ""}>{this.formatDollars(transaction.total - transaction.total_refunded)}</div><div className={classNames("align-middle ml-8 capitalize h-16 mt-8", classes[`type_${transaction.type}`], !transaction.success ? 'bg-red' : transaction.is_voided ? 'bg-grey' : transaction.total_refunded > 0 ? 'bg-orange' : '')}>{`${!transaction.success ? 'Failed ' : transaction.is_voided ? 'Voided ' : transaction.total_refunded === transaction.total ? 'Refunded ' : transaction.total_refunded > 0 ? 'Partially Refunded ' : ''}${transaction.type}`}</div></div>
                        <div className={classNames("text-10 truncate w-full flex font-600 mb-12 justify-between")}><label className={classNames("font-600 text-10 rounded px-6", !transaction.source ? classes.cnp : transaction.source.indexOf('CPSDK') > -1 ? classes.cp : "")}>{!transaction.source ? "CARD NOT PRESENT" : transaction.source.indexOf('CPSDK') > -1 ? "CARD PRESENT" : ""}</label>{moment.utc(transaction.created_at).local().format("M/D/YYYY")} at {moment.utc(transaction.created_at).local().format("h:mm:ss A")}</div>

                        <Divider />

                        {transaction.message &&
                            <React.Fragment>
                                <div className={classNames(classes.error, "my-12 flex")}><Icon className="text-white text-20 mr-6 align-middle">error</Icon><div className="w-full text-14 font-600">{transaction.message}</div></div>
                                <Divider />
                            </React.Fragment>
                        }

                        <Typography variant="h6" style={{ alignItems: 'center', }} className="flex text-16 mt-8 justify-between"><div className=""><Icon className="text-20 mr-4 align-middle mb-4" color="action">account_box</Icon>Customer Details</div>{transaction.sp_customer ? <div onClick={() => this.props.openEditCustomerDialog({ Co: transaction.sp_customer.Co, Customer: transaction.sp_customer.Customer })} className={classNames("rounded px-4 text-white w-auto align-middle text-12 cursor-pointer", classes.invoiceNumber)}>#{transaction.sp_customer.Customer}</div> : ''}</Typography>
                        {transaction.sp_customer ?
                            <div className="w-full text-12 pl-24 mb-12">
                                <div className="w-full capitalize my-6 font-bold">{`${transaction.sp_customer.FirstName || ''} ${transaction.sp_customer.LastName || ''}`}</div>
                                {transaction.sp_customer.Name && transaction.sp_customer.Name.length > 0 &&
                                    <div className="w-full capitalize mb-6 font-bold">{transaction.sp_customer.Name}</div>
                                }
                                {transaction.sp_customer.Address1.length > 0 &&
                                    <div className="w-full capitalize mb-6">{transaction.sp_customer.Address1}</div>
                                }
                                {transaction.sp_customer.Address2 && transaction.sp_customer.Address2.length > 0 &&
                                    <div className="w-full capitalize mb-6">{transaction.sp_customer.Address2}</div>
                                }
                                {transaction.sp_customer.City.length > 0 &&
                                    <div className="w-full capitalize mb-6">{transaction.sp_customer.City}</div>
                                }
                                {transaction.sp_customer.State.length > 0 &&
                                    <div className="w-full capitalize mb-6">{transaction.sp_customer.State}</div>
                                }
                                {transaction.sp_customer.Zip.length > 0 &&
                                    <div className="w-full capitalize mb-6">{transaction.sp_customer.Zip}</div>
                                }
                            </div> :
                            <div className="p-12">
                                <CustomerRedirectSearchBar handleCustomer={(customer) => { transaction.sp_customer = customer; this.setState({ spCustomer: customer }) }} textField={true} />
                            </div>
                            // <div className="w-full text-12 pl-24 mb-12">
                            //     <div className="w-full capitalize my-6 font-bold">{`${transaction.customer.firstname || ''} ${transaction.customer.lastname || ''}`}</div>
                            //     {transaction.customer.company.length > 0 &&
                            //         <div className="w-full capitalize mb-6 font-bold">{transaction.customer.company}</div>
                            //     }
                            //     {transaction.customer.address_1.length > 0 &&
                            //         <div className="w-full capitalize mb-6">{transaction.customer.address_1}</div>
                            //     }
                            //     {transaction.customer.address_2.length > 0 &&
                            //         <div className="w-full capitalize mb-6">{transaction.customer.address_2}</div>
                            //     }
                            //     {transaction.customer.address_city.length > 0 &&
                            //         <div className="w-full capitalize mb-6">{transaction.customer.address_city}</div>
                            //     }
                            //     {transaction.customer.address_state.length > 0 &&
                            //         <div className="w-full capitalize mb-6">{transaction.customer.address_state}</div>
                            //     }
                            //     {transaction.customer.address_zip.length > 0 &&
                            //         <div className="w-full capitalize mb-6">{transaction.customer.address_zip}</div>
                            //     }
                            // </div>
                        }

                        <Divider />

                        <Typography variant="h6" style={{ alignItems: 'center', }} className="flex text-16 mt-8"><Icon className="text-20 mr-4" color="action">payment</Icon>Payment Method</Typography>
                        <div className="w-full text-12 pl-24 mb-12">
                            <div className="w-full capitalize my-6 font-bold">{transaction.payment_method.person_name}</div>
                            {(transaction.payment_method.card_type && transaction.payment_method.card_type.length > 0) &&
                                <div className="w-full capitalize my-6"><div className="flex"><img src={`assets/images/cards/${transaction.payment_method.card_type}.png`} className="align-middle" style={{ height: 16 }} />{transaction.payment_method.card_last_four ? <div className="ml-6 align-middle">{`Ending in ${transaction.payment_method.card_last_four}`}</div> : ''}</div></div>
                            }
                            {(transaction.payment_method.card_exp_datetime && transaction.payment_method.card_exp_datetime.length > 0) &&
                                <div className="w-full capitalize mb-6">Expires {moment(transaction.payment_method.card_exp_datetime).format("MM/YYYY")}</div>
                            }
                        </div>

                        <Divider />

                        <Typography variant="h6" style={{ alignItems: 'center', }} className="flex text-16 mt-8"><Icon className="text-20 mr-4" color="action">history</Icon>Transaction History</Typography>
                        <div className="w-full text-12 pl-24 mb-12">
                            {transaction.child_transactions.map((tran) => {
                                return (
                                    <div key={tran.id} className="w-full my-6 flex justify-between">
                                        <div className="flex mr-6">
                                            <div className="">{`${moment.utc(tran.created_at).local().format("M/D/YYYY")} at ${moment.utc(tran.created_at).local().format("h:mm:ss A")}`}</div>
                                            <div className={classNames("align-middle ml-8 capitalize h-16", classes[`type_${tran.type}`], !tran.success ? 'bg-red' : tran.is_voided ? 'bg-grey' : '')}>{`${!tran.success ? 'Failed ' : ''}${tran.type === 'refund' && tran.total < transaction.total ? 'Partial ' : ''}${tran.type}`}</div>
                                        </div>
                                        {["refund", "void"].indexOf(tran.type) > -1 ?
                                            <div className="font-bold">({this.formatDollars(tran.total)})</div> :
                                            <div className="font-bold">{this.formatDollars(tran.total)}</div>
                                        }
                                    </div>
                                );
                            })}
                            <div className="w-full my-6 flex justify-between">
                                <div className="flex mr-6">
                                    <div className="">{`${moment.utc(transaction.created_at).local().format("M/D/YYYY")} at ${moment.utc(transaction.created_at).local().format("h:mm:ss A")}`}</div>
                                    <div className={classNames("align-middle ml-8 capitalize h-16", classes[`type_${transaction.type}`], !transaction.success ? 'bg-red' : '')}>{`${!transaction.success ? 'Failed ' : ''}${transaction.type}`}</div>
                                </div>
                                {["refund", "void"].indexOf(transaction.type) > -1 ?
                                    <div className="font-bold">({this.formatDollars(transaction.total)})</div> :
                                    <div className="font-bold">{this.formatDollars(transaction.total)}</div>
                                }
                            </div>
                        </div>

                        <Divider />
                        {(transaction.type === "charge" && !transaction.sp_data && total !== allocated) &&
                            <Typography variant="h6" style={{ alignItems: 'center', }} className="flex text-16 mt-8 justify-between"><div className="flex"><Tooltip placement="top" title="Unallocated Payment Balance"><Icon className="text-20 mr-4 text-orange mt-2">warning</Icon></Tooltip>Invoice Allocations</div>{transaction.sp_customer && <div onClick={() => this.attachCardPayment(transaction)} className={classNames(classes.invoiceNumber, "text-white px-4 rounded text-12 cursor-pointer")}><Icon className="text-14 align-middle text-white mr-4 mb-2">add</Icon>ADD</div>}</Typography>
                        }

                        {transaction.type === "charge" && transaction.sp_data &&
                            <React.Fragment>
                                <Typography variant="h6" style={{ alignItems: 'center', }} className="flex text-16 mt-8 justify-between"><div className="flex">{total !== allocated ? <Tooltip placement="top" title={total < allocated ? "Over-Allocated Payment Balance" : "Under-Allocated Payment Balance"}><Icon className="text-20 mr-4 text-orange mt-2">warning</Icon></Tooltip> : <Icon className="text-20 mr-4" color="action">receipt</Icon>}Invoice Allocations</div><div onClick={() => this.setState({ viewPayment: true })} className={classNames(classes.invoiceNumber, "text-white px-4 rounded text-12 cursor-pointer")}><Icon className="text-14 align-middle text-white mr-4 mb-2">edit</Icon>EDIT</div></Typography>
                                <div className="w-full text-12 pl-24 mb-12">
                                    {transaction.sp_data.Data.Allocations.map((tran) => {
                                        return (
                                            <div onClick={() => this.props.openEditInvoiceDialog({ Co: tran.Co, Invoice: tran.Invoice })} key={tran.ID} className={classNames("w-full my-6 flex justify-between cursor-pointer", tran.VoidDate || transaction.sp_data.VoidDate ? "line-through" : '')}>
                                                <div className="flex mr-6">
                                                    <div className={classNames("mr-6 rounded text-white px-4", tran.VoidDate || transaction.sp_data.VoidDate ? "bg-grey" : classes.invoiceNumber)}>{tran.InvoiceNumber}</div>
                                                    <div className="">{`${moment.utc(tran.EnteredDate).local().format("M/D/YYYY")}`}</div>
                                                </div>
                                                <div className="font-bold">{this.formatDollars(tran.Amount)}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <InvoicePaymentRecord open={this.state.viewPayment} data={{ ...transaction.sp_data }} payment={{ ...transaction.sp_data }} onVoid={() => { }} onClose={() => { this.setState({ ...this.state, viewPayment: false }) }} />
                                <Divider />
                            </React.Fragment>
                        }
                    </div>
                }
                {
                    transaction && transaction.is_voidable &&
                    <CardActions
                        className="w-full p-8 justify-end"
                        classes={{ root: "bg-white" }}
                    >
                        {this.state.confirmVoid &&
                            <Button
                                variant="text"
                                color="error"
                                onClick={() => this.setState({ confirmVoid: false })}
                                className="text-red"
                            >
                                Cancel
                            </Button>
                        }
                        {!this.state.confirmVoid ?
                            <Button
                                variant="contained"
                                color="primary"
                                className=""
                                onClick={() => this.setState({ confirmVoid: true })}
                            >
                                Void
                            </Button> :
                            <DebounceButton
                                buttonText={"Confirm Void"}
                                processingText={"Voiding"}
                                variant={"contained"}
                                color={"primary"}
                                clickFxn={() => this.voidTransaction()}
                                debounceInterval={750}
                            />
                        }
                    </CardActions>
                }
                {
                    transaction && transaction.is_refundable &&
                    <React.Fragment>
                        {this.state.partial &&
                            <div className="p-8 w-full">
                                <TextField
                                    name="refundAmount"
                                    label="Refund Amount"
                                    type="number"
                                    value={this.state.refundAmount}
                                    onChange={(e) => this.setState({ refundAmount: e.target.value })}
                                    InputProps={{
                                        startAdornment: this.state.refundAmount ? <InputAdornment placement="start">$</InputAdornment> : ''
                                    }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                        }
                        <CardActions
                            className="w-full p-8 justify-end"
                            classes={{ root: "bg-white" }}
                        >
                            {!this.state.confirmRefund && !this.state.partial &&
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => this.setState({ partial: true, refundAmount: transaction.total - transaction.total_refunded })}
                                >
                                    Partial
                                </Button>
                            }
                            {this.state.confirmRefund &&
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => this.setState({ confirmRefund: false })}
                                    className="text-red"
                                >
                                    Cancel
                                </Button>
                            }
                            {!this.state.confirmRefund ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className=""
                                    disabled={refundAmount && (refundAmount < 0 || refundAmount > (transaction.total - transaction.total_refunded))}
                                    onClick={() => this.setState({ confirmRefund: true })}
                                >
                                    {`Refund ${this.formatDollars(refundAmount || (transaction.total - transaction.total_refunded))}`}
                                </Button>
                                :
                                <DebounceButton
                                    buttonText={`Confirm Refund`}
                                    processingText={`Refunding ${this.formatDollars(refundAmount || (transaction.total - transaction.total_refunded))}`}
                                    variant={"contained"}
                                    color={"primary"}
                                    clickFxn={() => this.refundTransaction((refundAmount && refundAmount > 0 && refundAmount <= (transaction.total - transaction.total_refunded) ? refundAmount : (transaction.total - transaction.total_refunded)))}
                                    debounceInterval={750}
                                    isDisabled={refundAmount && (refundAmount < 0 || refundAmount > (transaction.total - transaction.total_refunded))}
                                />
                            }

                        </CardActions>
                    </React.Fragment>
                }
            </Drawer >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setPaymentFilters: Actions.setPaymentFilters,
        getPayments: Actions.getPayments,
        setTransaction: Actions.setTransaction,
        setPayments: Actions.setPayments,
        openEditInvoiceDialog,
        openEditCustomerDialog
    }, dispatch);
}

function mapStateToProps({ spReducers, paymentsApp }) {
    return {
        Co: spReducers.companies.Co,
        filters: paymentsApp.payments.filters,
        loading: paymentsApp.payments.loading,
        payments: paymentsApp.payments.data,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentDetailSidebar)));
