import * as Actions from '../actions/minimized-dialogs.actions';
import CacheManager from 'store/middlewares/cache';

import _ from '@lodash';

const offline = new CacheManager();

const initialState = {
    data: [],
};


const minimizedDialogReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_MINIMIZED_DIALOG:
            {
                return {
                    ...state,
                    data: action.payload
                };
            }
        default:
            {
                return state;
            }
    }
};

export default minimizedDialogReducer;