import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from './store/actions';
import TechniciansList from 'main/content/apps/technicians/TechniciansList';
import TechnicianHeader from 'main/content/apps/technician/TechnicianHeader';
import TechnicianProfile from './TechnicianProfile';
import _ from '@lodash';
import { Button, Icon, Tooltip } from '@material-ui/core';
import Media from 'react-media';
import classNames from 'classnames';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        zIndex: 99
    },
    header: {
        minHeight: 48,
        height: 48
    },
});

class CustomersApp extends Component {

    componentDidMount() {
        // this.props.getUserData();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (!_.isEqual(this.props.location, prevProps.location)) {
    //         this.props.getTechnicians(this.props.match.params);
    //     }
    // }

    render() {
        const { classes, openNewTechnicianDialog, trips, allTrips, timecard, technician } = this.props;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        <FusePageCarded
                            classes={{
                                root: "w-full",
                                content: "flex flex-col p-8 sm:p-12 pb-0",
                                header: classNames("items-center", classes.header)
                            }}
                            header={
                                <TechnicianHeader />
                            }
                            content={
                                technician ? <TechnicianProfile trips={[...trips]} allTrips={[...allTrips]} timecard={{ ...timecard }} technician={{ ...technician }} /> : 
                                <div></div>
                            }
                            sidebarInner
                            onRef={instance => {
                                this.pageLayout = instance;
                            }}
                            innerScroll={true}
                        />
                    </React.Fragment>
                }
            </Media>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTechnicians: Actions.getTechnicians,
        getUserData: Actions.getUserData,
        openNewTechnicianDialog: Actions.openNewTechnicianDialog
    }, dispatch);
}

function mapStateToProps({ techniciansApp, spReducers }) {
    return {
        technician: spReducers.technician.technician,
        trips: spReducers.technician.trips,
        allTrips: spReducers.technician.allTrips,
        timecard: spReducers.technician.timecard,
        user: techniciansApp.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersApp)));
