import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { Avatar, Button, Card, CardHeader, CardContent, CardActions, Chip, Badge, Icon, IconButton, ListItemIcon, ListItemText, Popover, MenuItem, Tooltip, Typography, Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import * as quickPanelActions from 'main/quickPanel/store/actions';
import * as dialerActions from 'main/dialer/store/actions';
import * as authActions from 'auth/store/actions';
import * as chatPanelActions from 'main/chatPanel/store/actions';
import { bindActionCreators } from 'redux';
import Conversation from 'main/messaging/Conversation';
import { FuseShortcuts, FuseAnimate } from '@fuse';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import _ from '@lodash';
import MinimizedDialogs from './content/apps/minimized-dialogs/MinimizedDialogs';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px -2px 10px 0px rgba(0,0,0,0.12)',
        paddingBottom: 'calc(env(safe-area-inset-bottom) - 20px)'
    },
    chat: {
        background: 'transparent',
        border: '1px solid lightgrey',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: 300,
        height: 384,
        marginRight: 16,
        position: 'absolute',
        bottom: 0,
    },
    seperator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider
    },
    card: {
        cursor: 'pointer',
        width: '100%',
    },
    cardHeader: {
        borderBottom: '1px solid lightgrey',
        boxShadow: '0px 0px 4px 0px #8f8f8f',
    },
    title: {
        fontWeight: 'bold',
    },
    subheader: {
        fontSize: 11,
    },
    actions: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }
});

class MainFooter extends Component {
    state = {
        minimizedDialogs: []

    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        // window["warn"]("test", this.props.minimizedDialogs, this.props.minimizedDialog)
        if(!_.isEqual(prevProps.minimizedDialog,this.props.minimizedDialog)){
            this.setState({minimizedDialogs: this.props.minimizedDialog.data})
        }

    }

    render() {
        const { classes, toggleQuickPanel, user, logout, openChatPanel, openMap, toggleDialer, conversations, minimizedDialogs } = this.props;
        const { userMenu, sipPhone, sipSession, sipSessionActive } = this.state;
        const avatar = 'assets/images/avatars/profile.jpg';

        // window["warn"]("minimizedDialogs footer", minimizedDialogs)
        // window["warn"]("conversations footer", conversations)

        let trueIndex = -1;

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <React.Fragment>
                        {
                            [...conversations].map((contact, index) => {
                                return (
                                    <Conversation key={index} fullScreen={!matches} order={index} contact={{ ...contact, Type: 'text', Phone: contact.From }} />
                                );
                            })
                        }
                        {!matches ? (
                            <div className={classNames(classes.root, "flex flex-row min-h-56 md:hidden")}>
                                <Hidden mdUp>
                                    <FuseShortcuts placement="bottom" />
                                </Hidden>
                            </div>) : (
                            minimizedDialogs.map((dialog, index) => {
                                if(dialog.show === false){
                                    return(<></>);
                                } else {
                                    trueIndex++;
                                    return (
                                        <MinimizedDialogs key={index} fullScreen={!matches} order={trueIndex} data={dialog} />
                                    );
                                }
                            })
                        )
                        }

                    </React.Fragment>
                }
            </Media>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleQuickPanel: quickPanelActions.toggleQuickPanel,
        logout: authActions.logoutUser,
        openChatPanel: chatPanelActions.openChatPanel,
        openMap: chatPanelActions.openMap,
        toggleDialer: dialerActions.toggleDialer,
    }, dispatch);
}


function mapStateToProps({ spReducers, dialer, messagingApp, minimizedDialogApp }) {
    return {
        user: spReducers.userProfiles.User,
        sipPhone: dialer.sipPhone,
        sipSession: dialer.sipSession,
        sipSessionActive: dialer.sipSessionActive,
        conversations: messagingApp.textMessages.conversations,
        minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
        minimizedDialog: minimizedDialogApp.minimizedDialog
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MainFooter));
