import { FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import { AppBar, Avatar, Badge, Button, Dialog, DialogActions, Grow, Icon, IconButton, MenuItem, Slide, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { AttachFile, BusinessCenter, Dashboard } from '@material-ui/icons';
import axios from 'axios';
import classNames from 'classnames';
import { fuseNavigationConfig } from 'fuse-configs/fuseNavigationConfig';
import FileList from 'main/content/apps/file-manager/FileList';
import { SPRoundIcon } from 'main/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { guid } from '../../../../store/actions';
import { showMessage, setQBOLink } from 'store/actions';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import DebounceButton from '../../components/debounce-button/DebounceButton';
import PhoneInput from '../../components/inputs/PhoneInput';
import DashboardSelector from '../dashboard-builder/dashboards/DashboardSelector';
import * as FileActions from '../file-manager/store/actions';
import CompanyModule from './CompanyModule';
import ImageDialog from './ImageDialog';
import * as Actions from './store/actions';
import DraggableDialog from '../draggable-dialog/DraggableDialog';

const SlideUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Marker({ text, icon, style, badge, heading, onClick }) {
    return (
        <Tooltip title={text} placement="top">
            <div onClick={onClick}>
                <img onMouseDown={(e) => { e.stopPropagation(); }} style={style} src={icon} />
            </div>
        </Tooltip>
    );
}

function TabContainer(props) {
    return (
        <Typography ref={props.refProp} component="div" style={{ padding: props.padding ? !isNaN(props.padding) ? props.padding : undefined : 8 * 3, maxHeight: 'calc(100% - 144px)', overflow: 'auto' }} {...props}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

const styles = theme => ({
    root: {

    },
    avatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    profile: {
        backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
        backgroundColor: 'rgba(0,0,0,.75)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        borderRadius: 5,
        paddingTop: 64,
        marginBottom: 16,

    },
    addButton: {
        float: 'right',
        width: 30,
        height: 30,
        minHeight: 0
    },
    newWOButton: {
        float: 'right',
        fontSize: 'x-small',
        width: '100%',
        fontWeight: 'bold',
        padding: '15px',
        marginBottom: 24
    },
    streetView: {
        borderRadius: '5px'
    },
    histTable: {
        marginLeft: 73,
    },
    formControl: {
        marginBottom: 24
    },
    hist: {
        display: 'flex',
        position: 'relative'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#fafafa',
        color: '#333333',
        paddingLeft: 12,
        fontWeight: 'bold'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        height: '100%'
    },
    shelf: {
        position: 'relative'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '55px'
    },
    content: {
        width: '100%',
        minHeight: '300px'
    },
});
const newCompanyState = {
    ID: null,
    Co: null,
    Name: null,
    Description: null,
    Address: null,
    Address2: null,
    City: null,
    State: null,
    Zip: null,
    Phone: null,
    Email: null,
    Fax: null,
    Website: null,
    Link: null,
    Country: 'US',
    Latitude: null,
    Longitude: null,
    Notes: null,
    Status: 'A',
    value: 0,
    AttachmentID: null,
    ImgUrl: null,
    Data: {
        Attachments: [],
    },
    streetView: false,
    imgDialog: false,
    IntegrationType: null,
    VPCompany: null,
    VPRecType: null,
    QBOCompany: null,
    PaymentsToken: null,
    PaymentsApiKey: null,
    DefaultTaxGL: null,
    UseManagedTaxCodesYN: null,
    vpRecTypes: [],
    dialogHeight: null,
    minHeight: null,
    location: null
};

class CompaniesDialog extends Component {
    state = { ..._.cloneDeepWith(newCompanyState) };

    ref = React.createRef();

    componentDidMount() {
        if (this.state.IntegrationType === 'V' && Boolean(this.state.VPCompany)) {
            this.getVPRecTypes();
        }
    }

    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        if (!prevProps.companyDialog.props.open && this.props.companyDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            const data = this.props.companyDialog.data;
            if (this.props.companyDialog.type === 'edit' &&
                data &&
                !_.isEqual({ ..._.cloneDeepWith(newCompanyState), ...data }, prevState)) {
                this.setState({ ..._.cloneDeepWith(newCompanyState), ...data }, this.getVPRecTypes);
            }

            /**
             * Dialog type: 'new'
             * Update State
             */

            if (this.props.companyDialog.type === 'new' &&
                !_.isEqual(_.cloneDeepWith(newCompanyState), prevState)) {
                this.setState({ ..._.cloneDeepWith(newCompanyState) });
            }
        }
        if (prevProps.companyDialog.props.open && this.props.companyDialog.props.open) {
            if (this.props.companyDialog.type === 'update' &&
                this.props.companyDialog.data &&
                !_.isEqual(this.props.companyDialog.data, prevState)) {
                const data = this.props.companyDialog.data;
                this.props.companyDialog.type = 'edit';
                this.setState({ ...data });
            }
        }
    }

    getParams = (url) => {
        const query = url.search.substring(1);
        const obj = {};
        const vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0].length > 0) {
                obj[decodeURIComponent(pair[0])] = pair[1];
            }
        }
        return obj;
    }


    closeComposeDialog = () => {
        this.props.companyDialog.type === 'edit' ? this.props.closeEditCompanyDialog() : this.props.closeNewCompanyDialog();
        this.setState({ ...this.state, removedFromSplitscreen: true, screenOverlay: null, posReset: true })
    };

    closeDialogForSplitscreen = () => {
        this.props.companyDialog.type === 'edit' ? this.props.closeEditCompanyDialog() : this.props.closeNewCompanyDialog();
    }

    //handleAddressChange = (event) => {
    //    const { Address1, City, State, Zip, Latitude, Longitude } = this.state;
    //    //if (Latitude == null || Longitude == null || Latitude.length < 2 || Longitude.length < 2) {
    //    if (Address1.length > 5 && City.length > 2 && State.length >= 2) {
    //    this.setState({ Address: `${this.state.Address1.trim()} ${this.state.City.trim()}, ${this.state.State.trim()} ${this.state.Zip.trim()}` });
    //        Geocode.setApiKey(googleMapsApiKey);
    //        Geocode.fromAddress(`${Address1.trim()} ${City.trim()}, ${State.trim()} ${Zip.trim()}`.trim()).then(
    //            response => {
    //                const { lat, lng } = response.results[0].geometry.location;
    //                this.setState({ Latitude: lat, Longitude: lng });
    //                window["log"]('GPS: ', response, this.state);
    //            },
    //            error => {
    //                window["error"](error);
    //            }
    //        );
    //    }
    //    //}
    //};
    canBeSubmitted() {
        const { Description, Name, Address, City, State, Zip } = this.state;
        return (
            Name && Name.length > 0 && Description && Description.length > 0 && Address && City && State && Zip
        );
    }

    openImgDialog = () => {
        this.setState({ ...this.state, imgDialog: true });
    }

    getVPRecTypes = () => {
        const { IntegrationType, VPCompany } = this.state;
        if (IntegrationType === 'V' && Boolean(VPCompany)) {
            const request = axios.get(`${window["apiLocation"]}/api/VPSettings/RecTypes`, {
                params: {
                    Co: VPCompany
                }
            });
            request.then((response) => {
                this.setState({ vpRecTypes: response.data });
            }).catch(() => {
                this.setState({ vpRecTypes: [] });
            });
        }
    }

    initQBO = () => {
        const { Co } = this.state;
        const request = axios.get(`${window["apiLocation"]}/api/QBOAuth`);
        return request.then((response) => {
            const { RedirectUrl, InitToken } = response.data;
            if (RedirectUrl) {
                const params = this.getParams(new URL(RedirectUrl));
                Promise.all([
                    this.props.setQBOLink({ Co, InitToken, VerificationToken: params.state })
                ]).then(() => {
                    window.location = RedirectUrl;
                });
            }
        }).catch((err) => {
            window["warn"]('Error Initiating QBO Connection: ', err);
        })
    }

    initQBD = () => {
        const QBOCompany = guid().toUpperCase().substring(0,30);
        this.setState({ QBOCompany });
    }

    AppBarContents = (matches, minimizeFxn, closeFxn) => {
        const { value } = this.state;

        return (
            <>
                <IconButton style={{
                    position: 'absolute',
                    right: 54,
                    top: !matches.small ? 8 : 4,
                    color: 'white',
                    //display: matches ? 'block' : 'none'
                }}
                    className="dialog-header-icon"
                    onClick={minimizeFxn}
                >
                    <Icon>minimize</Icon>
                </IconButton>
                <IconButton style={{
                    position: 'absolute',
                    right: 10,
                    top: matches ? 10 : 4/*if fullScreen*/,
                    color: 'white'
                }}
                    onClick={(e) => {
                        closeFxn(e);
                    }}
                    className="dialog-header-icon"/*if fullScreen*/
                >
                    <Icon>close</Icon>
                </IconButton>
                <Tabs
                    value={value}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="on"
                >
                    <Tab disableRipple icon={<BusinessCenter />} label="Info" />
                    <Tab disableRipple icon={<Icon>table_chart</Icon>} label="Modules" />
                    <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.Attachments ? this.state.Data.Attachments.length : 0} color="error"><AttachFile /></Badge>} label="Attachments" />
                    <Tab disableRipple icon={<Dashboard />} label="Dashboard" />
                </Tabs>
            </>
        )
    }

    ToolbarContents = () => {
        const { companyDialog } = this.props;

        return (
            <>
                <SPRoundIcon className="cursor-pointer text-32 mr-8" />
                <Typography variant="subtitle1" color="inherit">
                    {['new', 'customer'].indexOf(companyDialog.type) > -1 ? 'New Company' : `Company #${this.state.Co} Overview`}
                </Typography>
            </>
        )
    }

    render() {
        const { classes, securables, theme, companyDialog, addCompany, updateCompany, glAccounts, vpSettings } = this.props
        const { Co, Customer, Company, Contact, Phone, value, histTab, streetView, Address, Latitude, Longitude, imgDialog } = this.state;
        const accessLevel = _.find(securables, { Securable: "companies" });
        const avatar = 'assets/images/logos/SP_Cog_Gradient.png';

        const dialogProps = {
            ...companyDialog.props,
            className: classes.root,
            onClose: this.closeComposeDialog,
            fullWidth: true,
            maxWidth: "md",
            //hideBackdrop: true,
            disableEnforceFocus: true
        }

        return (
            <Media query="(min-width: 1200px)"/**/>
                {matches =>
                    <DraggableDialog
                        SlideUp={SlideUp}
                        matches={matches}
                        dialogProps={{ ...dialogProps }}
                        AppBarChildren={this.AppBarContents}
                        ToolbarChildren={this.ToolbarContents}
                        closeComposeDialog={this.closeComposeDialog.bind(this)}
                        closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(this)}
                        header={`Company #${this.state.Co}`}
                        type="Company"
                        picRef={this.ref.current}
                        icon="business"
                        dialogState={this.state}
                        reopenDialogFxn={this.props.openEditCompanyDialog}
                        description={this.state.Description}
                        storeDialog={companyDialog}
                        TypeState={this.state.Co}
                        screenOverlay={this.state.screenOverlay}
                    //posSet={this.state.posSet}
                    //initialWidthSet={initialWidthSet}
                    //minHeight={this.state.minHeight}
                    //location={this.state.location}
                    //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
                    //dialogHeight={this.state.dialogHeight}
                    >

                        {value === 0 && <TabContainer refProp={this.ref}>
                            <ImageDialog category="assets/images/forms/" onChange={(img) => this.setState({ ...this.state, ImgUrl: img })} open={imgDialog} onClose={() => this.setState({ ...this.state, imgDialog: false })} />
                            {(this.state.Name && this.state.Name.length > 0) &&
                                <div className={classNames(classes.profile, "flex flex-col items-center justify-center")}>
                                    <Avatar style={{ cursor: 'pointer', boxShadow: '2px 2px 10px 2px #3f3f3f' }} onClick={() => this.openImgDialog()} className="w-1/4 h-auto" alt="company avatar" src={this.state.ImgUrl || avatar} />
                                    {companyDialog.type === 'edit' && (
                                        <Typography variant="h6" color="inherit" className="p-16">
                                            {this.state.Name}
                                        </Typography>
                                    )}
                                </div>
                            }
                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                    <Icon color="action">business</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Company"
                                    id="company"
                                    name="Co"
                                    type="string"
                                    value={this.state.Co || 'TBD'}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    disabled="true"
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Name"
                                    id="name"
                                    name="Name"
                                    value={this.state.Name || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                    <Icon color="action">chat_bubble_outline</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Description"
                                    id="description"
                                    name="Description"
                                    value={this.state.Description || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    fullWidth
                                />
                            </div>


                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                    <Icon color="action">location_on</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Address"
                                    id="address"
                                    name="Address"
                                    value={this.state.Address || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Address (cont'd)"
                                    id="address2"
                                    name="Address2"
                                    value={this.state.Address2 || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="City"
                                    id="city"
                                    name="City"
                                    value={this.state.City || ''}
                                    onChange={this.handleChange}
                                    onBlur={this.getAddress}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="State"
                                    id="state"
                                    name="State"
                                    value={this.state.State || ''}
                                    onChange={this.handleChange}
                                    onBlur={this.getAddress}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Zip Code"
                                    id="zip"
                                    name="Zip"
                                    value={this.state.Zip || ''}
                                    onChange={this.handleChange}
                                    onBlur={this.getAddress}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                    <Icon color="action">star</Icon>
                                </div>
                                {/* <TextField
                                    className={classes.formControl}
                                    label="Phone"
                                    id="phone"
                                    name="Phone"
                                    value={this.state.Phone || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                /> */}
                                <PhoneInput
                                    {...this.props}
                                    className={classes.formControl}
                                    label="Phone"
                                    id="phone"
                                    name="Phone"
                                    value={this.state.Phone || ''}
                                    onChange={(value) => {
                                        this.setState({ ...this.state, Phone: value });
                                    }}
                                    variant="outlined"
                                    required={true}
                                    fullWidth={true}
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Email"
                                    id="email"
                                    name="Email"
                                    value={this.state.Email || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                    <Icon color="action">settings</Icon>
                                </div>
                                <Autocomplete
                                    className={classes.formControl}
                                    title="Default Tax GL"
                                    options={glAccounts}
                                    menuItemComponent={(value) => {
                                        return value.ActiveYN === "Y" &&
                                            <MenuItem value={value.GLAccount}>
                                                {`${value.GLAccount} - ${value.Description}`}
                                            </MenuItem>
                                    }}
                                    portal={true}
                                    value={this.state.DefaultTaxGL || ''}
                                    onSelect={(option) => this.setState({ ...this.state, DefaultTaxGL: option.GLAccount })}
                                    required
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Use Managed Tax Codes"
                                    id="useManagedTaxCodesYN"
                                    name="UseManagedTaxCodesYN"
                                    value={this.state.UseManagedTaxCodesYN || ''}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value="Y">
                                        Yes
                                    </MenuItem>
                                    <MenuItem value="N">
                                        No
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                    <Icon color="action">swap_vert</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Integration Type"
                                    id="integrationType"
                                    name="IntegrationType"
                                    value={this.state.IntegrationType || ''}
                                    onChange={(e) => this.setState({ IntegrationType: e.target.value, QBOCompany: null })}
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value={null}>
                                        None
                                    </MenuItem>
                                    <MenuItem value="Q">
                                        Intuit Quickbooks Online
                                    </MenuItem>
                                    <MenuItem value="D">
                                        Intuit Quickbooks Desktop
                                    </MenuItem>
                                    <MenuItem value="V">
                                        Vista by Viewpoint
                                    </MenuItem>
                                </TextField>
                                {this.state.IntegrationType &&
                                    <React.Fragment>
                                        <div className="hidden sm:block min-w-12 pt-20">
                                        </div>
                                        {this.state.IntegrationType === "V" &&
                                            <TextField
                                                className={classes.formControl}
                                                label="VP Company"
                                                id="vpCompany"
                                                name="VPCompany"
                                                type="number"
                                                value={this.state.VPCompany || ''}
                                                onChange={this.handleChange}
                                                onBlur={this.getVPRecTypes}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        }
                                        {this.state.IntegrationType === "Q" && Boolean(this.state.QBOCompany) &&
                                            <TextField
                                                className={classes.formControl}
                                                label="QBO Company"
                                                id="qboCompany"
                                                name="QBOCompany"
                                                value={this.state.QBOCompany || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                fullWidth
                                                disabled={true}
                                            />
                                        }
                                        {this.state.IntegrationType === "Q" && !Boolean(this.state.QBOCompany) &&
                                            <DebounceButton
                                                className={classNames(classes.formControl, "w-full")}
                                                buttonText={"Connect to Quickbooks"}
                                                processingText={"Connecting to Quickbooks"}
                                                variant={"contained"}
                                                color={"primary"}
                                                clickFxn={this.initQBO}
                                                debounceInterval={3000}
                                            />
                                        }
                                        {this.state.IntegrationType === "D" && Boolean(this.state.QBOCompany) &&
                                            <TextField
                                                className={classes.formControl}
                                                label="QBD Token"
                                                id="qboCompany"
                                                name="QBOCompany"
                                                value={this.state.QBOCompany || ''}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                fullWidth
                                                disabled={true}
                                            />
                                        }
                                        {this.state.IntegrationType === "D" && Boolean(this.state.QBOCompany) &&
                                            <Button
                                                className={classNames(classes.formControl, "w-48 ml-12")}
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(this.state.QBOCompany);
                                                    this.setState({ qbdCopied: true }, () => setTimeout(() => this.setState({ qbdCopied: false }), 3000));
                                                }}
                                            >
                                                {this.state.qbdCopied ? "Copied" : "Copy"}
                                            </Button>
                                        }
                                        {this.state.IntegrationType === "D" && !Boolean(this.state.QBOCompany) &&
                                            <DebounceButton
                                                className={classNames(classes.formControl, "w-full")}
                                                buttonText={"Generate Token"}
                                                processingText={"Generating Token"}
                                                variant={"contained"}
                                                color={"primary"}
                                                clickFxn={this.initQBD}
                                                debounceInterval={3000}
                                            />
                                        }
                                        {this.state.IntegrationType === "Q" && !Boolean(this.state.QBOCompany) &&
                                            <DebounceButton
                                                className={classNames(classes.formControl, "w-full")}
                                                buttonText={"Connect to Quickbooks"}
                                                processingText={"Connecting to Quickbooks"}
                                                variant={"contained"}
                                                color={"primary"}
                                                clickFxn={this.initQBO}
                                                debounceInterval={3000}
                                            />
                                        }
                                        {this.state.IntegrationType === "V" && Boolean(this.state.VPCompany) &&
                                            <React.Fragment>
                                                <div className="hidden sm:block min-w-12 pt-20">
                                                </div>
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    title="Default VP RecType"
                                                    options={this.state.vpRecTypes}
                                                    menuItemComponent={(value) => {
                                                        return <MenuItem value={value.RecType}>
                                                            {`${value.RecType} - ${value.Description}`}
                                                        </MenuItem>
                                                    }}
                                                    portal={true}
                                                    value={this.state.VPRecType || ''}
                                                    onSelect={(option) => this.setState({ ...this.state, VPRecType: option.RecType })}
                                                    required
                                                />
                                            </React.Fragment>
                                        }
                                    </React.Fragment>}
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4 sm:pt-20">
                                    <Icon color="action">note</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Notes"
                                    id="notes"
                                    name="Notes"
                                    value={this.state.Notes}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    fullWidth
                                />
                            </div>
                            {/* <div className="flex">
                                <div className="w-1/2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.ActiveYN === 'Y'}
                                                name="ActiveYN"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Active?"
                                    />
                                </div>
                            </div> */}
                        </TabContainer>}
                        {
                            value === 1 && companyDialog.type == 'edit' &&
                            <TabContainer padding="unset" className="p-24 pt-12">
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">table_chart</Icon>Modules</Typography>
                                <FuseAnimateGroup
                                    enter={{
                                        animation: "transition.slideUpBigIn"
                                    }}
                                    leave={{
                                        animation: "transition.slideUpBigOut"
                                    }}
                                    className="pl-32"
                                >
                                    {fuseNavigationConfig.map(data => <CompanyModule key={data.id} data={data} />)}
                                </FuseAnimateGroup>
                            </TabContainer>
                        }
                        {value === 2 && companyDialog.type == 'edit' && <TabContainer><FileList attachments={this.state.Data.Attachments} /></TabContainer>}
                        {value === 3 && companyDialog.type == 'edit' &&
                            <TabContainer>
                                <DashboardSelector data={this.state} form="company" />
                            </TabContainer>}

                        {(accessLevel && accessLevel.AccessLevel !== "R") &&
                            <DialogActions className="dialog-actions justify-between pl-16">
                                {['new', 'customer'].indexOf(companyDialog.type) > -1 ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            addCompany(rec);
                                            //this.closeComposeDialog();
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var rec = this.state;
                                            updateCompany(rec);
                                        }}
                                        disabled={!this.canBeSubmitted()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </DialogActions>
                        }
                    </DraggableDialog>
                }
            </Media>
        );
    }
}
CompaniesDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeEditCompanyDialog: Actions.closeEditCompanyDialog,
        closeNewCompanyDialog: Actions.closeNewCompanyDialog,
        openEditCompanyDialog: Actions.openEditCompanyDialog,
        addCompany: Actions.addCompany,
        updateCompany: Actions.updateCompany,
        removeCompany: Actions.removeCompany,
        getFiles: FileActions.getFiles,
        setQBOLink,
        showMessage
    }, dispatch);
}

function mapStateToProps({ companiesApp, emailTemplateApp, fileManagerApp, spReducers }) {
    return {
        companyDialog: companiesApp.companies.companyDialog,
        Co: spReducers.companies.Co,
        qboLink: spReducers.companies.QBOLink,
        securables: spReducers.userProfiles.User.Data.Securables,
        qboCompany: spReducers.qboSettings.qboCompany,
        glAccounts: spReducers.glAccounts,
        user: spReducers.userProfiles.User
    }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CompaniesDialog));
