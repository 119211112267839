import * as Actions from 'store/actions/spConnection';

const initialState = {
    history: [],
}

const dialogHistory = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_DIALOG_HISTORY:
            {
                const { history } = action;
                return {
                    history
                }
            }
    }

    return state;
};

export default dialogHistory;