import axios from 'axios/index';

export const SET_WORK_ORDER_HISTORY = '[WORK ORDER HISTORY APP] SET WORK ORDER HISTORY';

export function getWorkOrderHistory(data) {
    return (dispatch, getState) => {
        if (data) {
            const request = axios.get(`${window["apiLocation"]}/api/WorkOrder?Co=${data.Co}&WorkOrder=${data.WorkOrder}`);

            return request.then((response) => {
                dispatch({
                    type: SET_WORK_ORDER_HISTORY,
                    payload: response.data,
                });
            }).catch((error) => {
                dispatch({
                    type: SET_WORK_ORDER_HISTORY,
                    payload: null,
                });
            });
        } else {
            return dispatch({
                type: SET_WORK_ORDER_HISTORY,
                payload: null
            })
        }
    }
}

export function setWorkOrderHistory(data) {
    return {
        type: SET_WORK_ORDER_HISTORY,
        payload: data
    }
}