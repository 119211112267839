import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Avatar, Button, Badge, FormControlLabel, Checkbox, Dialog, Card, CardHeader, CardContent, Divider, Fab, Icon, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import * as Actions from './store/actions';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FuseAnimate, FuseAnimateGroup } from '@fuse';
import _ from '@lodash';
import moment from 'moment';
import classNames from 'classnames';
import axios from 'axios';

const styles = theme => ({
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    doneAvatar: {
        backgroundColor: '#5f5f5f',
    },
    userAvatar: {
        backgroundColor: '#555555',
        fontSize: 16,
        color: '#fff',
        width: 32,
        height: 32,
        marginTop: -6,
        marginBottom: -6,
    },
    card: {
        marginBottom: 12,
        cursor: 'pointer',
        width: '100%',
    },
    cardHeader: {
        padding: 8,
    },
    cardContent: {
        borderTop: '1px solid lightgrey',
        padding: 8,
        paddingBottom: '8px !important',
    },
    actions: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
    },
    action: {
        marginTop: 0,
        alignSelf: 'auto',
    },
    content: {
        overflow: 'hidden',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
    },
    subheader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: '1rem'
    },
    badge: {
        top: 6,
        right: 4,
        minWidth: 16,
        minHeight: 16,
        height: 16,
        fontSize: 11,
    },
    blackButton: {
        background: '#3f3f3f',
        color: '#fff',
        '&:hover': {
            background: '#5f5f5f',
        },
        '&:active': {
            background: '#333',
        },
        '&:focus': {
            background: '#4f4f4f',
        },
    },
    folderBadge: {
        top: 12,
        right: 'unset',
        left: 'calc(108% - 12px)',
        minWidth: 16,
        minHeight: 16,
        height: 16,
        fontSize: 11,
    },
    mailboxAvatar: {
        backgroundColor: theme.palette.error[500],
        height: 18,
        minWidth: 18,
        fontSize: 13,
        borderRadius: 9,
        padding: 4,
        marginRight: 8,
        width: 'auto',
    },
});

class VoicemailList extends Component {

    state = {
        Co: null,
        search: '',
        results: [],
        user: null,
        expanded: [],
    }

    componentDidMount() {
        const { Co, results, user } = this.props;
        this.setState({ ...this.state, Co, results, user });
    }

    componentDidUpdate(prevProps, prevState) {
        const { Co, results, user } = this.props;
        if (!_.isEqual(Co, prevProps.Co) || !_.isEqual(results, prevProps.results) || !_.isEqual(user, prevProps.user)) {
            this.setState({ ...this.state, Co, results, user });
        }
    }
    handleChange = (event) => {
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    stop = (event) => {
        event.stopPropagation();
    }

    formatPhone(phone) {
        return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    }

    markHandled = () => {
        const { recording } = this.state;
        recording.Status = 1;
        recording.HandledBy = this.props.user;
        recording.Data = { ErrMsg: null };
        const request = axios.put(`${window["apiLocation"]}/api/Voicemail?Co=${encodeURIComponent(recording.Co)}&Message=${encodeURIComponent(recording.CallSid)}`,
            recording
        );
        request.then((response) => {
            if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg === "") {
                this.props.setRecording(null);
            }
        })
    }

    markUnHandled = () => {
        const { recording } = this.state;
        recording.Status = 0;
        recording.HandledBy = null;
        recording.Data = { ErrMsg: null };
        const request = axios.put(`${window["apiLocation"]}/api/Voicemail?Co=${encodeURIComponent(recording.Co)}&Message=${encodeURIComponent(recording.CallSid)}`,
            recording
        );
        request.then((response) => {
            if (!response.data.Data.ErrMsg || response.data.Data.ErrMsg === "") {
                this.props.setRecording(response.data);
            }
        })
    }

    toggleList = (e, name) => {
        e.stopPropagation();
        const { expanded } = this.state;
        const index = expanded.indexOf(name);
        if (index > -1) {
            expanded.splice(index, 1);
        } else {
            expanded.push(name);
        }
        this.setState({ ...this.state, expanded });
    }

    render() {
        const { classes, voiceMailboxes, voicemail, data, user, users } = this.props;
        const { Co, recording, expanded } = this.state;
        const userAvatar = '../../assets/images/avatars/profile.jpg';
        const mailboxes = _.filter(voiceMailboxes, (o) => _.findIndex(o.Data.Handlers, { UserName: user }) > -1);
        const messages = data ? _.filter(data, (o) => !o.HandledBy || o.Status === 0) : _.filter(voicemail, (o) => _.findIndex(mailboxes, { VoiceMailbox: o.VoiceMailbox }) > -1);
        const box = recording ? _.find(mailboxes, { VoiceMailbox: recording.VoiceMailbox }) : null;
        return (
            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <div className="p-12" onClick={this.stop}>
                    <Typography variant="h6" style={{ alignItems: 'center', marginBottom: 12 }} className="flex relative">{messages.length > 0 ? <Badge color="error" classes={{ badge: classes.badge, }} className="mr-12" badgeContent={messages.length}><Icon color="primary" className="text-32">voicemail</Icon></Badge> : <Icon color="primary" className="text-32 mr-12">voicemail</Icon>}{!data ? 'New ' : ''}Messages</Typography>
                    <Divider />
                    <FuseAnimateGroup enter={{ animation: "transition.slideUpBigIn" }}>
                        {!data && mailboxes.map((value, index) => {
                            const msgs = _.orderBy(_.filter(messages, { VoiceMailbox: value.VoiceMailbox }), ['ReceivedDate'], ['desc'])
                            return (
                                <div key={value.VoiceMailbox}>
                                    <div className="w-full">
                                        <Typography onClick={(e) => this.toggleList(e, `mailbox-${value.VoiceMailbox}`)} variant="h6" style={{ alignItems: 'center' }} className="flex text-16 mt-12 ml-8 cursor-pointer relative">
                                            <div className="truncate w-2/3">{value.Name}</div>
                                            {/* {(loading || _.sumBy(data, (o) => { return Number(o.Data.Trips.length < 1) }) > 0) && */}
                                            <FuseAnimate animation="transition.expandIn" delay={300}>
                                                {
                                                    <Avatar className={classNames(classes.mailboxAvatar, "pin-r pin-t mt-4 absolute")}>{msgs.length}</Avatar>
                                                }
                                            </FuseAnimate>
                                            <Icon className="mr-40 pin-r absolute" style={{ marginTop: 2, }}>{expanded.indexOf(`mailbox-${value.VoiceMailbox}`) > -1 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                                        </Typography>
                                    </div>
                                    {expanded.indexOf(`mailbox-${value.VoiceMailbox}`) > -1 &&
                                        <List className="p-0">
                                            <FuseAnimateGroup
                                                enter={{
                                                    animation: "transition.slideUpBigIn"
                                                }}
                                                leave={{
                                                    animation: "transition.slideLeftBigOut"
                                                }}
                                            >
                                                {
                                                    msgs.map((val, index) => {
                                                        const received = moment(val.ReceivedDate);
                                                        const thisBox = _.find(mailboxes, { VoiceMailbox: val.VoiceMailbox });
                                                        return (
                                                            (!val.HandledBy || val.HandledBy === user) ?
                                                                // <CallHandlerCallCard key={val.Sid} pageLayout={pageLayout} call={val} id={val.Sid} handler={val.HandledBy} />
                                                                <Card onClick={(e) => this.props.setRecording(val)} key={val.CallSid} className="w-full mt-12 relative">
                                                                    <CardHeader className="p-12 highlight"
                                                                        classes={{ subheader: "text-11", title: "font-bold" }}
                                                                        avatar={
                                                                            <Avatar onClick={(e) => { e.stopPropagation(); }} className={val.HandledBy && val.Status > 0 ? classes.doneAvatar : classes.avatar}>
                                                                                <Icon>voicemail</Icon>
                                                                            </Avatar>
                                                                        }
                                                                        title={`${this.formatPhone(val.From.replace('+1', ''))}`}
                                                                        subheader={`${received.format("h:mm A")} on ${received.format("MM/DD/YYYY")}`
                                                                        }
                                                                        action={
                                                                            <IconButton style={{ marginTop: 8, }} onClick={(e) => { e.stopPropagation(); }} aria-owns={undefined}>
                                                                                <Icon color="action">keyboard_arrow_right</Icon>
                                                                            </IconButton>
                                                                        }
                                                                    />
                                                                </Card>
                                                                : ''
                                                        )
                                                    })
                                                }
                                            </FuseAnimateGroup>
                                        </List>
                                    }
                                    <Divider className="mt-12" />
                                </div>
                            );
                        })
                        }
                        {data && _.orderBy(data, ['ReceivedDate'], ['desc']).map((value, index) => {
                            const received = moment(value.ReceivedDate);
                            const thisBox = _.find(mailboxes, { VoiceMailbox: value.VoiceMailbox });
                            return (
                                (!value.HandledBy || value.HandledBy === user) ?
                                    // <CallHandlerCallCard key={value.Sid} pageLayout={pageLayout} call={value} id={value.Sid} handler={value.HandledBy} />
                                    <Card onClick={(e) => this.props.setRecording(value)} key={value.CallSid} className="w-full mt-12 relative">
                                        <CardHeader className="p-12 highlight"
                                            classes={{ subheader: "text-11", title: "font-bold" }}
                                            avatar={
                                                <Avatar onClick={(e) => { e.stopPropagation(); }} className={value.HandledBy && value.Status > 0 ? classes.doneAvatar : classes.avatar}>
                                                    <Icon>voicemail</Icon>
                                                </Avatar>
                                            }
                                            title={`${this.formatPhone(value.From.replace('+1', ''))}`}
                                            subheader={`${received.format("h:mm A")} on ${received.format("MM/DD/YYYY")}`
                                            }
                                            action={
                                                <IconButton style={{ marginTop: 8, }} onClick={(e) => { e.stopPropagation(); }} aria-owns={undefined}>
                                                    <Icon color="action">keyboard_arrow_right</Icon>
                                                </IconButton>
                                            }
                                        />
                                    </Card>
                                    : ''
                            )
                        })
                        }
                    </FuseAnimateGroup>
                </div>
            </FuseAnimate >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setRecording: Actions.setRecording
    }, dispatch);
}

function mapStateToProps({ voiceMailboxesApp, usersApp, spReducers }) {
    return {
        user: spReducers.userProfiles.User.UserName,
        users: spReducers.userProfiles.Users,
        Co: spReducers.companies.Co,
        results: voiceMailboxesApp.voiceMailboxes.searchResults,
        voiceMailboxes: spReducers.voiceMailboxes,
        voicemail: spReducers.voicemail
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(VoicemailList)));
