import {combineReducers} from 'redux';
import textMessages from './text-messages.reducer';
import user from './user.reducer';

const textMessagesAppReducers = combineReducers({
    textMessages,
    user
});

export default textMessagesAppReducers;
