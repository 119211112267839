import axios from 'axios';
import { getUserData } from 'main/content/apps/dial-groups/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_DIAL_GROUPS = '[DIAL GROUPS APP] GET DIAL GROUPS';
export const SET_SEARCH_TEXT = '[DIAL GROUPS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_DIAL_GROUPS = '[DIAL GROUPS APP] TOGGLE IN SELECTED DIAL GROUPS';
export const SELECT_ALL_DIAL_GROUPS = '[DIAL GROUPS APP] SELECT ALL DIAL GROUPS';
export const DESELECT_ALL_DIAL_GROUPS = '[DIAL GROUPS APP] DESELECT ALL DIAL GROUPS';
export const OPEN_NEW_DIAL_GROUP_DIALOG = '[DIAL GROUPS APP] OPEN NEW DIAL GROUP DIALOG';
export const CLOSE_NEW_DIAL_GROUP_DIALOG = '[DIAL GROUPS APP] CLOSE NEW DIAL GROUP DIALOG';
export const OPEN_EDIT_DIAL_GROUP_DIALOG = '[DIAL GROUPS APP] OPEN EDIT DIAL GROUP DIALOG';
export const UPDATE_EDIT_DIAL_GROUP_DIALOG = '[DIAL GROUPS APP] UPDATE EDIT DIAL GROUP DIALOG';
export const CLOSE_EDIT_DIAL_GROUP_DIALOG = '[DIAL GROUPS APP] CLOSE EDIT DIAL GROUP DIALOG';
export const ADD_DIAL_GROUP = '[DIAL GROUPS APP] ADD DIAL GROUP';
export const UPDATE_DIAL_GROUP = '[DIAL GROUPS APP] UPDATE DIAL GROUP';
export const REMOVE_DIAL_GROUP = '[DIAL GROUPS APP] REMOVE DIAL GROUP';
export const REMOVE_DIAL_GROUPS = '[DIAL GROUPS APP] REMOVE DIAL GROUPS';
export const TOGGLE_STARRED_DIAL_GROUP = '[DIAL GROUPS APP] TOGGLE STARRED DIAL GROUP';
export const TOGGLE_STARRED_DIAL_GROUPS = '[DIAL GROUPS APP] TOGGLE STARRED DIAL GROUPS';
export const SET_DIAL_GROUPS_STARRED = '[DIAL GROUPS APP] SET DIAL GROUPS STARRED ';
export const SET_SEARCH_RESULTS = '[DIAL GROUPS APP] SET SEARCH RESULTS';

export function getDialGroups(routeParams) {
    const request = axios.get('/api/dial-groups-app/dial-groups', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_DIAL_GROUPS,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedDialGroups(dialGroupId) {
    return {
        type: TOGGLE_IN_SELECTED_DIAL_GROUPS,
        dialGroupId
    }
}


export function selectAllDialGroups() {
    return {
        type: SELECT_ALL_DIAL_GROUPS
    }
}

export function deSelectAllDialGroups() {
    return {
        type: DESELECT_ALL_DIAL_GROUPS
    }
}


export function openNewDialGroupDialog(data) {
    return {
        type: OPEN_NEW_DIAL_GROUP_DIALOG,
        data
    }
}

export function closeNewDialGroupDialog() {
    return {
        type: CLOSE_NEW_DIAL_GROUP_DIALOG
    }
}

export function openEditDialGroupDialog(data) {
    return {
        type: OPEN_EDIT_DIAL_GROUP_DIALOG,
        data
    }
}

export function closeEditDialGroupDialog() {
    return {
        type: CLOSE_EDIT_DIAL_GROUP_DIALOG
    }
}

export function addDialGroup(newDialGroup) {
    return (dispatch, getState) => {

        const request = axios.post(`${window["apiLocation"]}/api/DialGroup`,
            newDialGroup
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_DIAL_GROUP,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dial Group has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateDialGroup(dialGroup) {
    return (dispatch, getState) => {

        const request = axios.put(`${window["apiLocation"]}/api/DialGroup?Co=${encodeURIComponent(dialGroup.Co)}&GroupID=${encodeURIComponent(dialGroup.GroupID)}&ID=${encodeURIComponent(dialGroup.ID)}`,
            dialGroup
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_DIAL_GROUP,
                    data: response.data
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dial Group has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeDialGroup(dialGroup) {
    return (dispatch, getState) => {

        const request = axios.delete(`${window["apiLocation"]}/api/DialGroup?Co=${encodeURIComponent(dialGroup.Co)}&GroupID=${encodeURIComponent(dialGroup.GroupID)}&ID=${encodeURIComponent(dialGroup.ID)}`);

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DIAL_GROUP,
                    data: dialGroup
                })
            ]).then(() => {
                if (response.data && response.data.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Dial Group has been successfully deleted.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}


export function removeDialGroups(dialGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupsApp.dialGroups;

        const request = axios.post('/api/dial-groups-app/remove-dialGroups', {
            dialGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_DIAL_GROUPS
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUPS
                })
            ]).then(() => dispatch(getDialGroups(routeParams)))
        );
    };
}

export function toggleStarredDialGroup(dialGroupId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().dialGroupsApp.dialGroups;

        const request = axios.post('/api/dial-groups-app/toggle-starred-dialGroup', {
            dialGroupId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DIAL_GROUP
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDialGroups(routeParams)))
        );
    };
}

export function toggleStarredDialGroups(dialGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupsApp.dialGroups;

        const request = axios.post('/api/dial-groups-app/toggle-starred-dialGroups', {
            dialGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_DIAL_GROUPS
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDialGroups(routeParams)))
        );
    };
}

export function setDialGroupsStarred(dialGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupsApp.dialGroups;

        const request = axios.post('/api/dial-groups-app/set-dialGroups-starred', {
            dialGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DIAL_GROUPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUPS
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getDialGroups(routeParams)))
        );
    };
}

export function setDialGroupsUnstarred(dialGroupIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().dialGroupsApp.dialGroups;

        const request = axios.post('/api/dial-groups-app/set-dialGroups-unstarred', {
            dialGroupIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_DIAL_GROUPS_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_DIAL_GROUPS
                }),
                dispatch(getUserData())
            ])
        );
    };
}
