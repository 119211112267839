import {combineReducers} from 'redux';
import dialGroupMembers from './dial-group-members.reducer';
import user from './user.reducer';

const dialGroupMembersAppReducers = combineReducers({
    dialGroupMembers,
    user
});

export default dialGroupMembersAppReducers;
