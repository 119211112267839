import { FuseAnimate, FuseUtils } from '@fuse';
import { Fab, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    addButton: {
        float: 'right',
        width: 24,
        height: 24,
        minHeight: 0,
        marginRight: 8,
        boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, .5)',
        marginTop: 2,
        zIndex: 3,
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class MaterialCategoriesList extends Component {

    state = {
        selectedMaterialCategoriesMenu: null
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    openSelectedMaterialCategoryMenu = (event) => {
        this.setState({ selectedMaterialCategoriesMenu: event.currentTarget });
    };

    closeSelectedMaterialCategoriesMenu = () => {
        this.setState({ selectedMaterialCategoriesMenu: null });
    };

    formatDate(date) {

        const dt = new Date((date ? date : new Date().toLocaleDateString('en-US')));
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();
        const yyyy = dt.getFullYear();
        const formatted = (mm <= 9 ? '0' + mm : mm) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + yyyy;
        return formatted;
    }

    render() {
        const { classes, Co, materialCategories, user, searchText, openNewMaterialCategoryDialog, selectedMaterialCategoryIds, selectAllMaterialCategories, deSelectAllMaterialCategories, toggleInSelectedMaterialCategories, openEditMaterialCategoryDialog, removeMaterialCategories, removeMaterialCategory, toggleStarredMaterialCategory, setMaterialCategoriesUnstarred, setMaterialCategoriesStarred } = this.props;
        const data = this.getFilteredArray((this.props.selectedCategories || (!this.props.materialCategoryData ? [] : this.props.materialCategoryData)), searchText);
        const { selectedMaterialCategoriesMenu } = this.state;

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={() => {
                            openNewMaterialCategoryDialog({
                                Co,
                                ParentCategory: this.props.parentCategory
                            });
                        }}
                    >
                        <Icon>add</Icon>
                    </Fab>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">list</Icon>Material Categories</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditMaterialCategoryDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Category",
                                accessor: "Category",
                                className: "justify-center font-bold",
                                width: 128
                            },
                            {
                                Header: "Description",
                                accessor: "Description",
                                className: "justify-start",
                                width: 256
                            },
                            {
                                Header: "GL Account",
                                accessor: "GLAccount",
                                className: "justify-center",
                                width: 128
                            },
                            {
                                Header: "Created By",
                                accessor: "CreatedBy",
                                className: "justify-center",
                                width: 256
                            },
                            {
                                Header: "Created Date",
                                id: "createdDate",
                                className: "justify-center",
                                accessor: d => this.formatDate(d.CreatedDate),
                                width: 128
                            },

                        ]}
                        defaultPageSize={10}
                        noDataText="No Material Categories found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getMaterialCategories: Actions.getMaterialCategories,
        getUserData: Actions.getUserData,
        toggleInSelectedMaterialCategories: Actions.toggleInSelectedMaterialCategories,
        selectAllMaterialCategories: Actions.selectAllMaterialCategories,
        deSelectAllMaterialCategories: Actions.deSelectAllMaterialCategories,
        openEditMaterialCategoryDialog: Actions.openEditMaterialCategoryDialog,
        removeMaterialCategories: Actions.removeMaterialCategories,
        removeMaterialCategory: Actions.removeMaterialCategory,
        toggleStarredMaterialCategory: Actions.toggleStarredMaterialCategory,
        toggleStarredMaterialCategories: Actions.toggleStarredMaterialCategories,
        setMaterialCategoriesStarred: Actions.setMaterialCategoriesStarred,
        setMaterialCategoriesUnstarred: Actions.setMaterialCategoriesUnstarred,
        openNewMaterialCategoryDialog: Actions.openNewMaterialCategoryDialog
    }, dispatch);
}

function mapStateToProps({ materialCategoriesApp, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        materialCategories: materialCategoriesApp.materialCategories.entities,
        selectedMaterialCategoryIds: materialCategoriesApp.materialCategories.selectedMaterialCategoryIds,
        searchText: materialCategoriesApp.materialCategories.searchText,
        user: materialCategoriesApp.user,
        materialCategoryData: spReducers.materialCategories
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialCategoriesList)));
