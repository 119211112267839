import React, { Component } from 'react';
import { Avatar, Paper, Typography, withStyles, TextField, IconButton, Icon, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { FuseScrollbars } from '@fuse';
import moment from 'moment/moment';
import * as Actions from './store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from 'history.js';
import _ from '@lodash';
import getProfileImage from '../functions/getProfileImageUrl';

const styles = theme => ({
    messageRow: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: '0 16px 4px 16px',
        flex: '0 0 auto',
        maxWidth: 256,
        '&.contact': {
            '& $bubble': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                '& $time': {
                    marginLeft: 12
                }
            },
            '&.first-of-group': {
                '& $bubble': {
                    borderTopLeftRadius: 20
                }
            },
            '&.last-of-group': {
                '& $bubble': {
                    borderBottomLeftRadius: 20
                }
            }
        },
        '&.me': {
            paddingLeft: 40,

            '& $avatar': {
                order: 2,
                margin: '0 0 0 16px'
            },

            '& $bubble': {
                marginLeft: 'auto',
                backgroundColor: theme.palette.grey[800],
                color: theme.palette.getContrastText(theme.palette.grey[800]),
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                '& $time': {
                    justifyContent: 'flex-end',
                    right: 0,
                    marginRight: 12
                }
            },
            '&.first-of-group': {
                '& $bubble': {
                    borderTopRightRadius: 20
                }
            },

            '&.last-of-group': {
                '& $bubble': {
                    borderBottomRightRadius: 20
                }
            }
        },
        '&.contact + .me, &.me + .contact': {
            paddingTop: 20,
            marginTop: 20
        },
        '&.first-of-group': {
            '& $bubble': {
                borderTopLeftRadius: 20,
                paddingTop: 13
            }
        },
        '&.last-of-group': {
            '& $bubble': {
                borderBottomLeftRadius: 20,
                paddingBottom: 13,
                '& $time': {
                    display: 'flex'
                }
            }
        }
    },
    avatar: {
        position: 'absolute',
        left: -32,
        margin: 0
    },
    bubble: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        maxWidth: '100%'
    },
    message: {
        whiteSpace: 'pre-wrap',
        lineHeight: 1.2,
        overflow: 'hidden',
    },
    time: {
        position: 'absolute',
        display: 'none',
        width: '100%',
        fontSize: 11,
        marginTop: 8,
        top: '100%',
        left: 0,
        whiteSpace: 'nowrap'
    },
    bottom: {
        background: theme.palette.background.default,
        borderTop: '1px solid rgba(0, 0, 0, 0.13)'
    },
    inputWrapper: {
        borderRadius: 24
    },
    chat: {
        background: theme.palette.background.default,
        backgroundImage: 'url("assets/images/backgrounds/SP_Header_Dark.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    messageLink: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        cursor: 'pointer',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '&:hover': {
            textDecoration: 'underline'
        }
    }

});
class URLify extends React.Component {
    render() {
        let text = this.renderText()
        return (
            <div>{text}</div>
        )
    }
}

class Chat extends Component {
    state = {
        messageText: '',
        hasFocus: false,
    };

    componentDidUpdate(prevProps) {
        const { chat, user } = this.props;
        const { hasFocus } = this.state;
        if (chat && !_.isEqual(prevProps.chat, chat)) {
            if (chat && chat.Messages) {
                if (_.findIndex(chat.Messages, { To: user.UserName, Received: null }) > -1 && hasFocus) {
                    this.updateChat(chat, user);
                }
            }
            this.scrollToBottom();
        }
    }

    updateChat = _.debounce((chat, user) => {
        this.props.viewChat(chat, user);
    }, 1500);

    handleFocus = () => {
        this.setState({ ...this.state, hasFocus: true }, () => {
            const { chat, user } = this.props;
            if (chat && chat.Messages) {
                window["warn"](chat);
                if (_.findIndex(chat.Messages, { To: user.UserName, Received: null }) > -1) {
                    this.updateChat(chat, user);
                }
            }
        });
    }

    handleBlur = () => {
        this.setState({ ...this.state, hasFocus: false });
    }

    shouldShowContactAvatar = (item, i) => {
        return (
            item.From === this.props.selectedContactId &&
            ((this.props.chat.Messages[i + 1] && this.props.chat.Messages[i + 1].From !== this.props.selectedContactId) || !this.props.chat.Messages[i + 1])
        );
    };

    isFirstMessageOfGroup = (item, i) => {
        return (i === 0 || (this.props.chat.Messages[i - 1] && this.props.chat.Messages[i - 1].From !== item.From));
    };

    isLastMessageOfGroup = (item, i) => {
        return (i === this.props.chat.Messages.length - 1 || (this.props.chat.Messages[i + 1] && this.props.chat.Messages[i + 1].From !== item.From));
    };

    onInputChange = (ev) => {
        this.setState({ messageText: ev.target.value });
    };

    onMessageSubmit = (ev) => {
        ev.preventDefault();
        if (this.state.messageText === '') {
            return;
        }
        const message = {
            Type: 'P',
            From: this.props.user.UserName,
            To: this.props.selectedContactId,
            Message: this.state.messageText,
            Sent: new Date(),
            Data: {
                ErrMsg: null,
            }
        };
        this.props.sendMessage(message)
            .then(() => {
                this.setState({ messageText: '' });
                this.scrollToBottom();
            });
    };

    scrollToBottom = () => {
        this.chatScroll.scrollTop = this.chatScroll.scrollHeight;
    };

    getHostName = (url) => {
        var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
        if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
            return match[2];
        }
        else {
            return null;
        }
    }

    getDomain = (url) => {
        var hostName = this.getHostName(url);
        var domain = hostName;

        if (hostName != null) {
            var parts = hostName.split('.').reverse();

            if (parts != null && parts.length > 1) {
                domain = parts[1] + '.' + parts[0];

                if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
                    domain = parts[2] + '.' + domain;
                }
            }
        }

        return domain;
    }

    isExternal = (url) => {
        var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
        if (match != null && typeof match[1] === 'string' &&
            match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol)
            return true;
        if (match != null && typeof match[2] === 'string' &&
            match[2].length > 0 &&
            match[2].replace(new RegExp(':(' + { 'http:': 80, 'https:': 443 }[window.location.protocol] + ')?$'), '')
            !== window.location.host) {
            return true;
        }
        else {
            return false;
        }
    }

    urlify = (text) => {
        const { classes } = this.props;
        var getLocation = function (href) {
            var l = document.createElement("a");
            l.href = href;
            return l;
        };
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        let parts = text.split(urlRegex) // re is a matching regular expression
        for (let i = 1; i < parts.length; i += 2) {
            let url = parts[i];
            let loc = getLocation(url);
            let path = loc.pathname;
            parts[i] = this.isExternal(url) ?
                <Tooltip title={url} placement="top"><div className={classes.messageLink} onClick={() => { window.open(url); }}>{url}</div></Tooltip> :
                <Tooltip title={url} placement="top"><div className={classes.messageLink} onClick={() => { history.push(path); }}>{url}</div></Tooltip>;
        }
        return parts
    }
    render() {
        const { Co, classes, chat, contacts, user, className, history } = this.props;
        const { messageText } = this.state;
        return (
            <Paper elevation={3} className={classNames("flex flex-col", className)}>
                <FuseScrollbars
                    containerRef={(ref) => {
                        this.chatScroll = ref
                    }}
                    className={`flex flex-1 flex-col overflow-y-auto ${classes.chat}`}
                >
                    {!chat ?
                        (
                            <div className="flex flex-col flex-1 items-center justify-center p-24">
                                <Typography className="flex px-16 pb-24 mt-24 text-center" color="textSecondary">
                                    <Icon className="block text-32" color="disabled">chat</Icon>
                                    <span className="min-w-12 pt-20">
                                    </span>
                                    Select a contact to start a conversation.
                                </Typography>
                            </div>
                        ) :
                        (chat.Messages && chat.Messages.length > 0) ?
                            (
                                <div className="flex flex-col pt-16 pl-40 pb-40">
                                    {[...chat.Messages].map((item, i) => {
                                        const contact = item.From === user.UserName ? user : contacts.find(_contact => _contact.UserName === item.From);
                                        return (
                                            <div
                                                key={i}
                                                className={classNames(
                                                    classes.messageRow,
                                                    { 'me': item.From === user.UserName },
                                                    { 'contact': item.From !== user.UserName },
                                                    { 'first-of-group': this.isFirstMessageOfGroup(item, i) },
                                                    { 'last-of-group': this.isLastMessageOfGroup(item, i) }
                                                )}
                                            >
                                                {this.shouldShowContactAvatar(item, i) && (
                                                    <Avatar className={classes.avatar} src={(contact.Data && contact.Data.Avatar) && getProfileImage(`Co=${Co}&ID=${contact.Data.Avatar}`)}
                                                        alt={`${contact.FirstName} ${contact.LastName}`}
                                                    >
                                                        {!contact.Data || !contact.Data.Avatar || contact.Data.Avatar === '' ? contact.FirstName[0] : ''}
                                                    </Avatar>
                                                )}
                                                <div className={classes.bubble}>
                                                    <div className={classes.message}>{this.urlify(item.Message)}</div>
                                                    <Typography className={classes.time} color="textSecondary">{item.Received && item.To !== user.UserName && <Icon style={{ fontSize: 14 }} className="text-8 mr-4">check_circle</Icon>}{moment(moment(item.Sent)).local().format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div className="flex flex-col flex-1">
                                    <div className="flex flex-col flex-1 items-center justify-center">
                                        <Icon className="text-128" color="disabled">chat</Icon>
                                    </div>
                                    <Typography className="px-16 pb-24 text-center" color="textSecondary">
                                        Start a conversation by typing your message below.
                                    </Typography>
                                </div>
                            )
                    }

                </FuseScrollbars>
                {chat && (
                    <form onSubmit={this.onMessageSubmit} className={classNames(classes.bottom, "py-16 px-8")}>
                        <Paper className={classNames(classes.inputWrapper, "flex items-center relative")}>
                            <TextField
                                autoFocus={false}
                                id="message-input"
                                className="flex-1"
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        root: "flex flex-grow flex-no-shrink ml-16 mr-48 my-8",
                                        input: ""
                                    },
                                    placeholder: "Type your message"
                                }}
                                InputLabelProps={{
                                    shrink: false,
                                    className: classes.bootstrapFormLabel
                                }}
                                inputProps={{
                                    autoComplete: "off"
                                }}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                onChange={this.onInputChange}
                                value={messageText}
                            />
                            <IconButton className="absolute pin-r pin-t" type="submit">
                                <Icon className="text-24" color="action">send</Icon>
                            </IconButton>
                        </Paper>
                    </form>
                )}
            </Paper>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendMessage: Actions.sendMessage,
        viewChat: Actions.viewChat
    }, dispatch);
}

function mapStateToProps({ chatPanel, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        contacts: spReducers.userProfiles.Users,
        selectedContactId: chatPanel.contacts.selectedContactId,
        chat: chatPanel.chat,
        user: spReducers.userProfiles.User
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Chat));
