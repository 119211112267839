import axios from 'axios/index';
import { getUserData } from 'main/content/apps/tax-codes/store/actions/user.actions';
import { showMessage } from 'store/actions';

export const GET_TAX_CODES = '[TAX CODES APP] GET TAX CODES';
export const SET_SEARCH_TEXT = '[TAX CODES APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_TAX_CODES = '[TAX CODES APP] TOGGLE IN SELECTED TAX CODES';
export const SELECT_ALL_TAX_CODES = '[TAX CODES APP] SELECT ALL TAX CODES';
export const DESELECT_ALL_TAX_CODES = '[TAX CODES APP] DESELECT ALL TAX CODES';
export const OPEN_NEW_TAX_CODE_DIALOG = '[TAX CODES APP] OPEN NEW TAX CODE DIALOG';
export const CLOSE_NEW_TAX_CODE_DIALOG = '[TAX CODES APP] CLOSE NEW TAX CODE DIALOG';
export const OPEN_EDIT_TAX_CODE_DIALOG = '[TAX CODES APP] OPEN EDIT TAX CODE DIALOG';
export const CLOSE_EDIT_TAX_CODE_DIALOG = '[TAX CODES APP] CLOSE EDIT TAX CODE DIALOG';
export const ADD_TAX_CODE = '[TAX CODES APP] ADD TAX CODE';
export const UPDATE_TAX_CODE = '[TAX CODES APP] UPDATE TAX CODE';
export const REMOVE_TAX_CODE = '[TAX CODES APP] REMOVE TAX CODE';
export const REMOVE_TAX_CODES = '[TAX CODES APP] REMOVE TAX CODES';
export const TOGGLE_STARRED_TAX_CODE = '[TAX CODES APP] TOGGLE STARRED TAX CODE';
export const TOGGLE_STARRED_TAX_CODES = '[TAX CODES APP] TOGGLE STARRED TAX CODES';
export const SET_TAX_CODES_STARRED = '[TAX CODES APP] SET TAX CODES STARRED ';

export function getTaxCodes(routeParams) {
    const request = axios.get('/api/taxCodes-app/taxCodes', {
        params: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_TAX_CODES,
                payload: response.data,
                routeParams
            })
        );
}

export function setSearchText(event) {
    return {
        type: SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}

export function toggleInSelectedTaxCodes(taxCodeId) {
    return {
        type: TOGGLE_IN_SELECTED_TAX_CODES,
        taxCodeId
    }
}


export function selectAllTaxCodes() {
    return {
        type: SELECT_ALL_TAX_CODES
    }
}

export function deSelectAllTaxCodes() {
    return {
        type: DESELECT_ALL_TAX_CODES
    }
}


export function openNewTaxCodeDialog() {
    return {
        type: OPEN_NEW_TAX_CODE_DIALOG
    }
}

export function closeNewTaxCodeDialog() {
    return {
        type: CLOSE_NEW_TAX_CODE_DIALOG
    }
}

export function openEditTaxCodeDialog(data) {
    const request = axios.get(`${window["apiLocation"]}/api/TaxCode?Co=${data.Co}&TaxCode=${encodeURIComponent(data.TaxCode)}`);

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: OPEN_EDIT_TAX_CODE_DIALOG,
                data: response.data
            })
        );
}

export function closeEditTaxCodeDialog() {
    return {
        type: CLOSE_EDIT_TAX_CODE_DIALOG
    }
}

export function addTaxCode(newTaxCode) {
    return (dispatch, getState) => {

        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.post(`${window["apiLocation"]}/api/TaxCode`,
            newTaxCode
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_TAX_CODE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Tax Code ${newTaxCode.TaxCode} has been successfully created.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function updateTaxCode(taxCode) {
    return (dispatch, getState) => {

        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.put(`${window["apiLocation"]}/api/TaxCode?Co=${encodeURIComponent(taxCode.Co)}&TaxCode=${encodeURIComponent(taxCode.TaxCode)}`,
            taxCode
        );

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_TAX_CODE
                })
            ]).then(() => {
                if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
                    dispatch(showMessage({
                        message: `Error: ${response.data.Data.ErrMsg}`,
                        autoHideDuration: 30000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                } else {
                    dispatch(showMessage({
                        message: `Tax Code ${taxCode.TaxCode} has been successfully updated.`,
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'success'
                    }));
                }
            })
        );
    };
}

export function removeTaxCode(taxCodeId) {
    return (dispatch, getState) => {

        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.post('/api/taxCodes-app/remove-taxCode', {
            taxCodeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TAX_CODE
                })
            ]).then(() => dispatch(getTaxCodes(routeParams)))
        );
    };
}


export function removeTaxCodes(taxCodeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.post('/api/taxCodes-app/remove-taxCodes', {
            taxCodeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: REMOVE_TAX_CODES
                }),
                dispatch({
                    type: DESELECT_ALL_TAX_CODES
                })
            ]).then(() => dispatch(getTaxCodes(routeParams)))
        );
    };
}

export function toggleStarredTaxCode(taxCodeId) {
    return (dispatch, getState) => {
        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.post('/api/taxCodes-app/toggle-starred-taxCode', {
            taxCodeId
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TAX_CODE
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTaxCodes(routeParams)))
        );
    };
}

export function toggleStarredTaxCodes(taxCodeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.post('/api/taxCodes-app/toggle-starred-taxCodes', {
            taxCodeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: TOGGLE_STARRED_TAX_CODES
                }),
                dispatch({
                    type: DESELECT_ALL_TAX_CODES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTaxCodes(routeParams)))
        );
    };
}

export function setTaxCodesStarred(taxCodeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.post('/api/taxCodes-app/set-taxCodes-starred', {
            taxCodeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TAX_CODES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TAX_CODES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTaxCodes(routeParams)))
        );
    };
}

export function setTaxCodesUnstarred(taxCodeIds) {
    return (dispatch, getState) => {

        const { routeParams } = getState().taxCodesApp.taxCodes;

        const request = axios.post('/api/taxCodes-app/set-taxCodes-unstarred', {
            taxCodeIds
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: SET_TAX_CODES_STARRED
                }),
                dispatch({
                    type: DESELECT_ALL_TAX_CODES
                }),
                dispatch(getUserData())
            ]).then(() => dispatch(getTaxCodes(routeParams)))
        );
    };
}
