import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Capacitor } from '@capacitor/core'
import { withRouter } from 'react-router-dom'
import { matchRoutes } from 'react-router-config'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from 'store/actions'
// import { setOfflineStatus } from 'store/actions/spConnection/offline.actions';
import _ from '@lodash'
import FuseLayouts from './FuseLayouts'
import IdleTimer from 'react-idle-timer'
import axios from 'axios/index'
import classNames from 'classnames'

const platform = Capacitor.getPlatform()

const styles = theme => ({
  root: {
    // paddingTop: 'max(0px, calc(env(safe-area-inset-top) - 8px))',
    // paddingBottom: 'max(0px, calc(env(safe-area-inset-bottom) - 24px))',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    zIndex: 1,

    '& table.simple tbody tr td': {
      borderColor: theme.palette.divider,
    },
    '& table.simple thead tr th': {
      borderColor: theme.palette.divider,
    },
    '& a:not([role=button])': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& [class^="border-"]': {
      borderColor: theme.palette.divider,
    },
    '& [class*="border-"]': {
      borderColor: theme.palette.divider,
    },
  },
})

class FuseLayout extends Component {
  state = {
    Co: null,
    user: null,
    loggedIn: false,
    isScanning: false,
  }
  constructor(props) {
    super(props)
    this.routeSettingsCheck()
  }

  componentWillMount() {
    // window["warn"]('COMPONENT WILL MOUNT CALLED');
    const { tokenObj, version, device, user, vault, keepIntact } = this.props
    const os =
      device && (device.osName || device.os) && device.osVersion
        ? `${device.osName || device.os} ${device.osVersion}`
        : 'N/A'
    if (
      user &&
      user.UserName &&
      tokenObj &&
      tokenObj.refresh_token &&
      !keepIntact
    ) {
      // const token = tokenObj.access_token;
      // axios.defaults.withCredentials = true;
      // axios.defaults.headers.common['Authorization'] = token;
      this.props.refreshToken(false, version, os)
    } else {
    }
  }
  componentDidMount() {
    const {
      setOfflineStatus,
      setOnlineStatus,
      Co,
      user,
      loggedIn,
      isScanning,
    } = this.props
    this.setState({ ...this.state, user, Co, loggedIn, isScanning }, () =>
      this.props.setKeepIntact(false),
    )
    window.addEventListener('online', setOnlineStatus)
    window.addEventListener('offline', setOfflineStatus)
  }

  componentDidUpdate(prevProps) {
    const { user, Co, loggedIn, isScanning, vault } = this.props
    if (
      !_.isEqual(vault.token, prevProps.vault.token) &&
      vault.token &&
      !prevProps.vault.token
    ) {
    }
    if (
      !_.isEqual(Co, prevProps.Co) ||
      !_.isEqual(user, prevProps.user) ||
      !_.isEqual(loggedIn, prevProps.loggedIn) ||
      !_.isEqual(isScanning, prevProps.isScanning)
    ) {
      this.setState({ ...this.state, user, Co, loggedIn, isScanning })
    }
    if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
      this.routeSettingsCheck()
    }
  }

  componentWillUnmount = () => {
    const { user, setOfflineStatus, setOnlineStatus } = this.props
    // window.removeEventListener('online', setOnlineStatus);
    // window.removeEventListener('offline', setOfflineStatus);
    if (user && user.UserName) {
      const data = {
        UserName: user.UserName,
        Status: 0,
      }
      const request = axios.put(
        `${window['apiLocation']}/api/UserStatus?UserName=${encodeURIComponent(
          data.UserName,
        )}`,
        data,
      )

      request.then(response => {
        // console.warn(response);
      })
    }
  }

  routeSettingsCheck = () => {
    const matched = matchRoutes(
      this.props.routes,
      this.props.location.pathname,
    )[0]

    if (matched && matched.route.settings) {
      const routeSettings = _.merge(
        {},
        this.props.defaultSettings,
        matched.route.settings,
      )
      if (!_.isEqual(this.props.settings, routeSettings)) {
        this.props.setSettings(_.merge({}, routeSettings))
      }
    } else {
      if (!_.isEqual(this.props.settings, this.props.defaultSettings)) {
        this.props.resetSettings()
      }
    }
  }

  onIdle = e => {
    const { user } = this.props
    // console.warn("Idle Detected", user);
    if (user && user.UserName) {
      const data = {
        UserName: user.UserName,
        Status: 1,
      }
      window['warn']('User is idle!!!', data)
      if (data.UserName) {
        const request = axios.put(
          `${
            window['apiLocation']
          }/api/UserStatus?UserName=${encodeURIComponent(data.UserName)}`,
          data,
        )

        request.then(response => {
          this.props.updateUserPresence(data)
        })
      }
    }
  }

  onActive = e => {
    const { user } = this.props
    // console.warn("User is active", user);
    if (user && user.UserName) {
      const data = {
        UserName: user.UserName,
        Status: 2,
      }
      if (data.UserName) {
        const request = axios.put(
          `${
            window['apiLocation']
          }/api/UserStatus?UserName=${encodeURIComponent(data.UserName)}`,
          data,
        )

        request
          .then(response => {
            this.props.updateUserPresence(data)
          })
          .catch(e => {
            this.props.showMessage({
              message: `Unable to resume session.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: 'error',
            })
          })
      }
    }
  }

  onAction = e => {
    if (e.type && e.type === 'visibilitychange') {
      const { loggedIn, tokenObj, user } = this.props
      if (loggedIn) {
        const { visibilityState } = document
        if (visibilityState === 'hidden') {
          // this.onIdle();
        }
        if (visibilityState === 'visible') {
          const exp =
            tokenObj && tokenObj.expires_at
              ? new Date(tokenObj.expires_at)
              : null
          if (exp && exp <= new Date() && user && user.UserName) {
            const { version, device } = this.props
            const os =
              device && (device.osName || device.os) && device.osVersion
                ? `${device.osName || device.os} ${device.osVersion}`
                : 'N/A'
            this.props.refreshToken(false, version, os)
          } else {
            this.onActive()
          }
        }
        // window["warn"]("Window State: ", visibilityState);
      }
    }
  }

  render() {
    const { settings, classes, Roles } = this.props
    const { Co, user, loggedIn, isScanning } = this.state
    const Layout = FuseLayouts[settings.layout.style].component
    const co =
      user && user.Data && user.Data.Companies
        ? _.find(user.Data.Companies, { Co })
        : null
    if (
      !loggedIn ||
      !co ||
      !user ||
      !user.UserName ||
      !user.Data ||
      !co.Data ||
      !co.Data.Role ||
      !co.Data.Role.DefaultHomepage
    ) {
      return ''
    }
    if (platform !== 'web') {
      return (
        <Layout
          className={classNames(classes.root, isScanning ? 'body-scan' : '')}
          {...this.props}
        />
      )
    }
    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15}
        />
        <Layout
          className={classNames(classes.root, isScanning ? 'body-scan' : '')}
          {...this.props}
        />
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSettings: Actions.setSettings,
      setDefaultSettings: Actions.setDefaultSettings,
      resetSettings: Actions.resetSettings,
      navbarOpenFolded: Actions.navbarOpenFolded,
      navbarCloseFolded: Actions.navbarCloseFolded,
      navbarOpenMobile: Actions.navbarOpenMobile,
      navbarCloseMobile: Actions.navbarCloseMobile,
      setOfflineStatus: Actions.setOfflineStatus,
      setOnlineStatus: Actions.setOnlineStatus,
      showMessage: Actions.showMessage,
      refreshToken: Actions.refreshToken,
      updateUserPresence: Actions.updateUserPresence,
      setKeepIntact: Actions.keepIntact,
    },
    dispatch,
  )
}

function mapStateToProps({
  fuse,
  auth,
  spReducers,
  spAuth,
  version,
  utilities,
  support,
}) {
  return {
    tokenObj: spAuth.authentication,
    version: version.version,
    device: version.device,
    user: spReducers.userProfiles.User,
    defaultSettings: fuse.settings.defaults,
    settings: fuse.settings.current,
    navbar: fuse.navbar,
    loggedIn: auth.login.success,
    Co: spReducers.companies.Co,
    isScanning: utilities.isScanning,
    vault: spAuth.vault,
    keepIntact: support.keepIntact,
    // homepage: spReducers.userProfiles.User.Data.DefaultHomepage,
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseLayout)),
)
