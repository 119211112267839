import * as Actions from 'store/actions/spConnection';
import { omitDates } from '../../../main/functions';

const materialCategories = function (state = [], action) {
    switch (action.type) {
        case Actions.GET_MATERIAL_CATEGORY_DATA:
            {
                return omitDates(action.payload);
            }
        case Actions.SET_MATERIAL_CATEGORY_DATA:
            {
                return omitDates(action.payload);
            }
    }

    return state;
};

export default materialCategories;