import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FuseUtils, FuseAnimate } from '@fuse';
import { Avatar, Checkbox, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import withFixedColumns from "react-table-hoc-fixed-columns";
import _ from '@lodash';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = theme => ({
    mailList: {
        padding: 0
    },
    mailItem: {},
    avatar: {
        backgroundColor: theme.palette.primary[500]
    },
    labels: {}

});

class RevenueSplitsList extends Component {

    render() {
        const { classes, securables, openEditRevenueSplitDialog, laborTypes, data, costTypes } = this.props;
        const accessLevel = _.find(securables, { Securable: "work-orders" });

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div>
                    <Typography variant="h6" style={{ alignItems: 'center' }} className="flex truncate text-16 sm:text-20 mb-6 sm:mb-12"><Icon color="primary" className="text-32 mr-12">format_list_numbered</Icon>Split Revenue</Typography>
                    <ReactTableFixedColumns
                        className={classNames(classes.root, "-striped -highlight border-0")}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                className: "cursor-pointer",
                                onClick: (e, handleOriginal) => {
                                    if (rowInfo) {
                                        openEditRevenueSplitDialog(rowInfo.original);
                                    }
                                }
                            }
                        }}
                        data={data}
                        columns={[
                            {
                                Header: "Work Order",
                                accessor: "WorkOrder",
                                className: "font-bold justify-center",
                                width: 128,
                            },
                            {
                                Header: "Scope",
                                accessor: "Scope",
                                className: "font-bold justify-center",
                                width: 128,
                            },
                            {
                                Header: "Cost Type",
                                accessor: "CostType",
                                className: "font-bold justify-center",
                                Cell: row => {
                                    return (
                                        costTypes.map(value => {
                                            return value.CostType === row.original.CostType && `${value.CostType} - ${value.Name}`;
                                        })
                                    )
                                }
                            },
                            {
                                Header: "Split %",
                                accessor: "Split",
                                className: "justify-center",
                                Cell: row => {
                                    return `${(row.original.Split).toFixed(2)}%`
                                }
                            },
                            {
                                Header: "Override GL Account",
                                accessor: "OverrideGLAccount",
                                className: "justify-center",
                                width: 256,
                            },
                            {
                                Header: "Taxable?",
                                accessor: "TaxableYN",
                                className: "justify-center",
                                width: 128,
                            }
                        ]}
                        defaultPageSize={10}
                        noDataText="No Revenue Splits found"
                    />
                </div>
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openEditRevenueSplitDialog: Actions.openEditRevenueSplitDialog,
    }, dispatch);
}

function mapStateToProps({ rateTemplatesApp, spReducers }) {
    return {
        user: rateTemplatesApp.user,
        costTypes: spReducers.costTypes,
        securables: spReducers.userProfiles.User.Data.Securables,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(RevenueSplitsList)));
